import Select from "react-select";
import styles from "./TimeSelectInput.module.scss";
import { useMemo } from "react";

function TimeSelectInput({
  currentValue,
  setValue,
  disabled,
  error = "",
  range,
  step,
  label,
}: {
  currentValue: string;
  setValue: (value: string) => void;
  disabled: boolean;
  error: string;
  range: number;
  step: number;
  label: string;
}) {
  const options = useMemo(
    () =>
      Array.from({ length: Math.ceil(range / step) }, (_, index) => {
        const value = (index * step).toString().padStart(2, "0");
        return { label: value, value };
      }),
    [range, step]
  );

  return (
    <Select
      className={`${styles.selectBox} ${error ? styles.error : ""}`}
      classNames={{
        menu: () => styles.selectBoxMenu,
      }}
      isDisabled={disabled}
      options={options}
      onChange={(e) => setValue(e?.value ?? "")}
      value={{
        label: currentValue,
        value: currentValue,
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          border: 0,
          borderRadius: "2px",
          cursor: "pointer",
          minHeight: "50px",
          backgroundColor: "#f4f5f7",

          boxShadow: state.isFocused ? "0 0 0 2px #009fff" : "none",
          borderColor: state.isFocused ? "#009fff" : base.borderColor,
        }),
        indicatorsContainer: () => ({
          display: "none",
        }),
        valueContainer: (base) => ({
          ...base,
        }),
        singleValue: (base) => ({
          ...base,
          margin: 0,
        }),
        input: (base) => ({
          ...base,
          marginLeft: "10px",
        }),
      }}
    />
  );
}

export default TimeSelectInput;
