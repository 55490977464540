import { StateCreator } from "zustand";
import { TFormValue } from "../../types";
import FormValue from "../../types/creators/FormValue";
import { ValidateOrganizer } from "./utils";
import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";
/**
 * Organizer Interface
 */
export interface IOrganizer {
  comapny_name: TFormValue<string>;
  name: TFormValue<string>;
  website: TFormValue<string>;
  phone: TFormValue<string>;
  country_code: TFormValue<number>;
  email: TFormValue<string>;
}
/**
 * Organizer Slice Interface
 */
export interface IOrganizerSlice {
  /**
   * Organizer object
   */
  organizer: IOrganizer;
  /**
   * Organizer same as place
   *
   * Flag for checking if the organizer is the same as the place
   */
  organizer_same_as_place: boolean;
  /**
   * Organizer validation flag
   */
  organizer_valid: boolean;

  /**
   * setOrganizerSlice - Full setter
   *
   * @param partials
   *
   * @example
   * setOrganizerSlice({ organizer: { comapny_name: FormValue("Company name"), name: FormValue("Name"), website: FormValue("website"), phone: FormValue("phone"), country_code: FormValue(298), email: FormValue("email") }, organizer_same_as_place: true, organizer_valid: false })
   *
   * @returns void
   */
  setOrganizerSlice: (partials: Partial<IOrganizerSlice>) => void;

  /**
   * Takes partial organizer and sets it to the state
   *
   * @example
   * setOrganizer({ comapny_name: "Company name" });
   *
   * @example
   * setOrganizer({ comapny_name: "Company name", name: "Name" });
   *
   * @param organizer_partials
   * @returns
   */
  setOrganizer: (organizer_partials: Partial<IOrganizer>) => void;
  /**
   *
   * Sets the organizer_same_as_place to the desired state
   *
   * @param same
   * @returns
   */
  setOrganizerSameAsPlace: (same: boolean) => void;
  /**
   *
   * Validates the organizer
   *
   * @returns
   */
  validateOrganizer: () => boolean;
  /**
   * Reset the organizer slice
   */
  resetOrganizerSlice: () => void;
}
/**
 * Initial States
 */
const INITIAL_STATES: any = () => ({
  organizer: {
    comapny_name: FormValue(""),
    name: FormValue(""),
    website: FormValue(""),
    phone: FormValue(""),
    country_code: FormValue(298),
    email: FormValue(""),
  },
  organizer_same_as_place: true,
  organizer_valid: false,
});
/**
 * Create Organizer Slice
 */
export const createOrganizerSlice: StateCreator<IOrganizerSlice> = (set, get) => {
  return {
    ...INITIAL_STATES(),

    /**
     * Setters
     */
    setOrganizerSlice: (partials) => set({ ...partials }),

    setOrganizer: (organizer_partials) => {
      set((state) => {
        return {
          organizer: {
            ...state.organizer,
            ...{
              ...Object.fromEntries(
                Object.entries(organizer_partials as any).map((entry: any) => {
                  return [entry[0], FormValue(entry[1])];
                })
              ),
            },
          },
        };
      });
    },
    validateOrganizer: () => {
      const state = get();
      const { organizer, valid } = ValidateOrganizer(state.organizer, state.organizer_same_as_place);
      const { setError } = useWhatsonManager.getState().getStore().getState();
      setError("Organizer", valid);
      set(() => {
        return {
          organizer: { ...organizer },
          organizer_valid: valid,
        };
      });
      return valid;
    },
    setOrganizerSameAsPlace: (same) => {
      set({ organizer_same_as_place: same });
    },
    resetOrganizerSlice: () => {
      set({ ...INITIAL_STATES() });
    },
  };
};
