import { useEffect, useState } from "react";
import styles from "./Alert.module.scss";

interface Props {
  children: any;
  close: () => void;
}

export default function Alert({ children, close }: Props) {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className={`${styles.container} ${loaded && styles.loaded}`}>
      {/* JSX */}

      <div className={styles.content}>{children}</div>
    </div>
  );
}
