import ProductList from "./ProductList";
import EditProduct from "./EditProduct/EditProduct";
import { useState, useEffect } from "react";
import axios from "axios";
import env from "../../../environment.json";
import shopConfig from "./shopConfig";

export default function Products({ close, setLoading, siteSelected }) {
  // Product editing
  const [editProductID, setEditProductID] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  // All products in system
  const [allProducts, setAllProducts] = useState([]);
  // Products to display (built from allProducts, depending on filters, search etc.)
  const [displayProducts, setDisplayProducts] = useState([]);
  // Number of products to display
  const [numDisplayItems, setNumDisplayItems] = useState(25);
  // Search string to filter by if set
  const [search, setSearch] = useState("");
  // For reloading products
  const [reloadProducts, setReloadProducts] = useState(false);

  // Number to increment when pressing show more
  const displayItemsIncrement = 25;

  // Set default tyre image
  const defaultTyreImg = env.protocol + env.env + shopConfig.defaultTyreImgPath;

  // Call when allProducts are changed (fetched)
  useEffect(() => {
    // If there are products....
    if (allProducts.length > 0) {
      set_display_products();
    }
  }, [allProducts, numDisplayItems, search]);

  useEffect(() => {
    setLoading(true);
    // Get all products from the server
    axios
      .get(env.protocol + env.env + "/api/secured/shop/GetAdminProductList")
      .then((response) => {
        // Hydrate the products before setting them
        const products = hydrate_all_products(response.data);
        setAllProducts(products);
        setLoading(false);
      })
      .catch(function (err) {
        console.error(err);
        setLoading(false);
      });
  }, [reloadProducts]);

  /**
   * Reload the product list
   */
  function reload_products() {
    setReloadProducts(!reloadProducts);
  }

  /**
   * Hydrate the products coming from the server
   *
   * @param 		{array} 	products 												All products from server
   *
   * @returns 	{array} 																	Hydrated products
   *
   * @author 					Pætur Mortensen
   */
  function hydrate_all_products(products) {
    /**
     * Set the product's thumbnail
     *
     * @param 		{object} 	product 									Product to set thumbnail for
     *
     * @returns 	{string} 														The product thumbnail
     *
     * @author 					Pætur Mortensen
     */
    function set_product_thumbnail(product) {
      // Initialize the thumbnail (placeholder image if none set)
      let thumbnail = product.thumbnail ?? defaultTyreImg;
      // If there is {{local}} markdown, replace with server images location
      if (thumbnail.startsWith("{{local}}")) {
        thumbnail = thumbnail.replace("{{local}}", env.protocol + env.env + "/uploads/");
      }

      return thumbnail;
    }

    /**
     * Set the searchable string for the product
     *
     * @param 		{object} 	product 								Product to set searchable string for
     *
     * @returns 	{string} 													Searchable string for the product
     *
     * @author 					Pætur Mortensen
     */
    function set_product_searchable(product) {
      // Build the string
      let searchable = product.name + " " + product.brand;

      for (const SKU of product.SKUs) {
        searchable += " " + SKU;
      }
      // Set to lower case for case insensitive search
      searchable = searchable.toLowerCase();

      return searchable;
    }

    // For each product....
    for (const idx in products) {
      const product = products[idx];

      // Set the thumbnail for the product
      product.thumbnail = set_product_thumbnail(product);

      // Set the searchable string for the product
      product.searchable = set_product_searchable(product);

      // Insert the formatted product back into the product list
      products[idx] = product;
    }

    return products;
  }

  /**
   * Check whether the search matches the searchable string
   *
   * The search string is set in state, while the searchable string is supplied by the product item
   *
   * @param 		{string} 	searchable 													Product searchable string
   *
   * @returns 	{bool} 																				Whether the search string matches the
   * 																													searchable string
   *
   * @author 					Pætur Mortensen
   */
  function search_matches(searchable) {
    // Split the searchable string into array of words
    const searchableWords = searchable.trim().split(/\s+/);

    // Remove all non-word characters (but keep numbers)
    let searchString = search.replace(/[^\p{L}\p{N}]/gu, " ");
    // Truncate all spaces to single spaces
    searchString = searchString.replace(/\s+/g, " ");
    // Set to lower case for case-insensitive matching
    searchString = searchString.toLowerCase();
    // Split the string into words for matching
    const searchWords = searchString.trim().split(/\s+/);

    /**
     * Check if word partly matches
     *
     * Check if a single search word partly matches any of the searchable words
     *
     * @param 		{string} 	searchWord 										Search word (word to search for)
     * @param 		{array} 	searchableWords								Words that are searchable
     *
     * @returns 	{bool} 																	Whether there was a partly match
     *
     * @author 					Pætur Mortensen
     */
    const word_partly_matches = (searchWord, searchableWords) => {
      // Return whether any search word partly matches any searchable word
      return searchableWords.some((word) => word.includes(searchWord));
    };

    // Check whether ALL words in the searchString partly match ANY words in searchable words
    return searchWords.every((searchWord) => word_partly_matches(searchWord, searchableWords));
  }

  /**
   * Set the products to display
   *
   * Handles filtering, sorting etc before setting the display products array
   *
   * @author 					Pætur Mortensen
   */
  function set_display_products() {
    // Start by copying all products
    let newDisplayProducts = [...allProducts];

    // If there is a search string...
    if (search.length > 0) {
      // Return the products that are matched in the search string
      newDisplayProducts = newDisplayProducts.filter((product) => search_matches(product.searchable));
    }

    // Limit the number of products to display
    newDisplayProducts = newDisplayProducts.slice(0, numDisplayItems);

    setDisplayProducts(newDisplayProducts);
  }

  /**
   * Show more items
   *
   * Will increment the number of items to display
   *
   * @author 					Pætur Mortensen
   */
  function show_more() {
    setNumDisplayItems(numDisplayItems + displayItemsIncrement);
  }

  return (
    <>
      {editProductID !== null ? (
        <EditProduct
          productID={editProductID}
          setProductID={setEditProductID}
          setLoading={setLoading}
          close={() => {
            setEditProductID(null);
          }}
          siteId={siteSelected.siteId}
          isCreating={false}
          productData={editProduct}
          reload_products={reload_products}
        />
      ) : (
        <ProductList
          close={close}
          setEditProduct={setEditProduct}
          setEditProductID={setEditProductID}
          defaultTyreImg={defaultTyreImg}
          displayProducts={displayProducts}
          search={search}
          show_more={show_more}
          setSearch={setSearch}
          reload_products={reload_products}
        />
      )}
    </>
  );
}
