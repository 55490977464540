import "./EditProduct.scss";
import axios from "axios";
import env from "../../../../environment.json";
import { useEffect, useState } from "react";
import { faArrowLeftLong } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PanelsColumn from "./PanelsColumn/PanelsColumn";
import ProductTypePanel from "./PanelsColumn/ProductTypePanel";
import AvailablePanel from "./PanelsColumn/AvailablePanel";
import StockPanel from "./PanelsColumn/StockPanel";
import PricesPanel from "./PanelsColumn/PricesPanel";
import InfoColumn from "./InfoColumn/InfoColumn";
import GeneralInfo from "./InfoColumn/GeneralInfo";
import Media from "./InfoColumn/Media";
import Variants from "./InfoColumn/Variants/Variants";
import Modal from "src/Components/Modal";
import RecalculatingOverlay from "./RecalculatingOverlay";

/**
 * Edit a single product
 *
 * @author 					Pætur Mortensen
 */
export default function EditProduct(props) {
  const { productID, setProductID, setLoading, close, isCreating, productData, reload_products } = props;

  // Product information
  const [product, setProduct] = useState(null);
  // Product config information
  const [productConfig, setProductConfig] = useState(null);
  // Local loading (whether THIS component is currently loading)
  const [localLoading, setLocalLoading] = useState(true);
  // State used to reload the product
  const [reloadProduct, setReloadProduct] = useState(false);
  const [confirmDeleteProduct, setConfirmDeleteProduct] = useState(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [isRecalculating, setIsRecalculating] = useState(false);

  /**
   * Reload the product from server
   *
   * @author 					Pætur Mortensen
   */
  function reload_product() {
    setReloadProduct(!reloadProduct);
  }

  useEffect(() => {
    // Run before component unmounts
    return () => {
      setProductID(null);
    };
  }, []);

  // Called when product should be reloaded
  useEffect(() => {
    setLoading(true);
    if (!localLoading) {
      setLocalLoading(true);
    }

    axios
      .get(env.protocol + env.env + "/api/secured/shop/GetAdminEditProduct?productID=" + productID)
      .then((response) => {
        setProduct(response.data.product);
        setProductConfig({ ...response.data.config, isCreating });
        setLoading(false);
        setLocalLoading(false);
      })
      .catch(function (err) {
        console.error(err);
        setLoading(false);
      });
  }, [reloadProduct]);

  /**
   * Recalculate prices on the system
   *
   * After price changes, prices must be recalculated on the system and the product must be updated
   * This is the quickest consistent way to update prices without a lot of management
   *
   * @author 					Pætur Mortensen
   */
  function recalculate_prices() {
    setIsRecalculating(true);

    axios
      .get(env.protocol + env.env + "/api/secured/system/RecalculatePrices")
      .then(() => {
        setIsRecalculating(false);
        reload_product();
      })
      .catch((error) => {
        setIsRecalculating(false);
        console.error(error);
      });
  }

  /**
   * Delete current product
   *
   * @author 					Pætur Mortensen
   */
  function delete_product() {
    setIsDeletingProduct(true);

    axios
      .post(env.protocol + env.env + "/api/secured/shop/AdminDeleteProduct", {
        productID,
      })
      .then((response) => {
        setConfirmDeleteProduct(false);
        setIsDeletingProduct(false);
        close();
        reload_products();
      })
      .catch((error) => {
        console.error(error);
        setConfirmDeleteProduct(false);
        setIsDeletingProduct(false);
      });
  }

  /**
   * Render modal to confirm product deletion
   *
   * @author 					Pætur Mortensen
   */
  function render_confirm_delete_product() {
    return (
      <Modal
        title="Delete product"
        showPreloader={isDeletingProduct}
        type="confirmation"
        onCancel={() => {
          setConfirmDeleteProduct(false);
        }}
        close={() => {
          setConfirmDeleteProduct(false);
        }}
        onConfirm={delete_product}
        confirmButtonText="Yes"
      >
        <div>
          Are you sure you want to delete this product with all its data, including all variants?
          <br />
          <br />
          Number of variants that will be deleted with product: <b>{product.variants.length}</b>
        </div>
      </Modal>
    );
  }

  return (
    <div className={`${"product overlay"}`}>
      {confirmDeleteProduct && render_confirm_delete_product()}
      <div className="top">
        <div>
          <div className="title">
            <div
              onClick={() => {
                close();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </div>
            <h1>Product</h1>
          </div>
          <div className="cta">
            <div
              onClick={() => {
                setConfirmDeleteProduct(true);
              }}
              className="delete"
            >
              Delete
            </div>
          </div>
        </div>
      </div>
      <div className="max-width">
        <div className="content">
          <div className="wrap-column">
            {!localLoading && (
              <>
                <InfoColumn>
                  <GeneralInfo product={product} setProduct={setProduct} reload_products={reload_products} />
                  <Media product={product} setProduct={setProduct} />
                  <Variants
                    product={product}
                    setProduct={setProduct}
                    productConfig={productConfig}
                    reload_product={reload_product}
                    recalculate_prices={recalculate_prices}
                  />
                </InfoColumn>

                <PanelsColumn>
                  <ProductTypePanel product={product} setProduct={setProduct} productConfig={productConfig} />
                  <PricesPanel
                    product={product}
                    setProduct={setProduct}
                    productConfig={productConfig}
                    recalculate_prices={recalculate_prices}
                  />
                  <AvailablePanel product={product} setProduct={setProduct} productConfig={productConfig} />
                  <StockPanel product={product} />
                </PanelsColumn>
              </>
            )}
          </div>
        </div>
      </div>
      {isRecalculating && <RecalculatingOverlay />}
    </div>
  );
}
