import { Enum, EnumValues } from "./Enum";

// Mainly used for pages
export const LANGUAGES = Enum("en", "fo", "de", "dk");
export type TLangs = EnumValues<typeof LANGUAGES>;
export type TLangsPartialObj<T> = Partial<Record<TLangs, T>>;

// Used for whatson
export type WHATSON_LANGUAGE = "en" | "fo";
// export type TWhatsonLangs = EnumValues<typeof WHATSON_LANGUAGES>;
export type TWhatsonLangsObj<T> = Record<WHATSON_LANGUAGE, T>;
export type TWhatsonLangsPartialObj<T> = Partial<TWhatsonLangsObj<T>>;
