import VerifyMethods from "src/assets/VerifyMethods";
import { IInstagram, IInstagramElement } from "./MediaStore";

export function ValidateMedia(
  include_instagram: boolean,
  instagram: IInstagram,
  instagram_elements: IInstagramElement[]
): {
  valid: boolean;
  instagram: IInstagram;
  instagram_elements: IInstagramElement[];
} {
  let new_instagram = { ...instagram };
  let new_instagram_elements = [...instagram_elements];
  let valid = true;

  /** Instagram */
  if (include_instagram) {
    /** Check url */
    const url = VerifyMethods.isInstagramLink(instagram.url.value);
    if (url !== "") {
      new_instagram.url.error = {
        message: url,
      };
      valid = false;
    }

    /** Loop over elements */
    new_instagram_elements.forEach((element) => {
      /** Check url */
      if (element.url.value === "") {
        element.url.error.message = "This field is required";
        valid = false;
      }

      const error = VerifyMethods.isInstagramLink(element.url.value);
      if (error !== "") {
        element.url.error.message = error;
        valid = false;
      }

      if (element.image.value === null) {
        element.image.error.message = "This field is required";
        valid = false;
      } else if (!element.image.value?.id && !element.image.value?.file) {
        element.image.error.message = "Either `id` or `file` is required";
        valid = false;
      }
    });
  }

  return {
    valid: valid,
    instagram: new_instagram,
    instagram_elements: [...new_instagram_elements],
  };
}
