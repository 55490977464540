import { useState, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { Safe } from "src/types";
import { safe } from "src/utils";

interface AxiosState<T> {
  data?: T;
  isLoading: boolean;
  error?: string;
}

/**
 * useSafeAxios - A custom React hook for making safe Axios requests.
 *
 * This hook simplifies the process of making Axios requests by handling
 * loading and error states seamlessly. It also ensures that no state updates
 * occur on an unmounted component, eliminating potential warnings from React.
 *
 * @template T - The expected data type of the Axios response.
 *
 * @param {string} url - The URL endpoint for the Axios request.
 * @param {AxiosRequestConfig} [options] - Optional configuration for the Axios request.
 *
 * @returns {AxiosState<T>} - An object containing the data, loading status, and any error that occurred.
 *
 * @example
 * // Basic Usage: Fetching data from an API endpoint
 * const { data, isLoading, error } = useSafeAxios('https://api.example.com/data');
 * if (isLoading) return <p>Loading...</p>;
 * if (error) return <p>Error: {error}</p>;
 * return <div>Data: {JSON.stringify(data)}</div>;
 *
 * @example
 * // With Axios Configuration: Fetching user data with authentication headers
 * const config = {
 *     headers: {
 *         'Authorization': 'Bearer your_token_here'
 *     }
 * };
 * const { data, isLoading, error } = useSafeAxios('https://api.example.com/user', config);
 * if (isLoading) return <p>Loading...</p>;
 * if (error) return <p>Error: {error}</p>;
 * return <div>User Name: {data.name}</div>;
 *
 * @example
 * // POST Request: Submitting form data
 * const postData = {
 *     username: 'johndoe',
 *     password: 'securepassword'
 * };
 * const postConfig = {
 *     method: 'POST',
 *     data: postData
 * };
 * const { data, isLoading, error } = useSafeAxios('https://api.example.com/signup', postConfig);
 * if (isLoading) return <p>Submitting...</p>;
 * if (error) return <p>Error: {error}</p>;
 * return <div>Signup Successful for {data.username}!</div>;
 */
export function useSafeAxios<T>(url: string, options?: AxiosRequestConfig): AxiosState<T> {
  const [state, setState] = useState<AxiosState<T>>({
    isLoading: true,
    error: undefined,
    data: undefined,
  });

  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates on unmounted components

    (async () => {
      const response: Safe<T> = await safe(axios(url, options).then((res) => res.data));

      if (!isMounted) return;

      if (response.success) {
        setState({
          isLoading: false,
          data: response.data,
        });
      } else {
        setState({
          isLoading: false,
          error: extractErrorMessage(response.error),
        });
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [url, options]);

  return state;
}

/**
 * Utility function to extract the appropriate error message.
 */
function extractErrorMessage(error?: unknown): string {
  if (typeof error === "object" && error !== null) {
    const errObj = error as Record<string, unknown>;
    if (errObj.response && typeof errObj.response === "object" && errObj.response !== null) {
      const responseObj = errObj.response as Record<string, unknown>;
      if (responseObj.data && typeof responseObj.data === "object" && responseObj.data !== null) {
        const dataObj = responseObj.data as Record<string, unknown>;
        if (typeof dataObj.message === "string") {
          return dataObj.message;
        }
      }
    }
    if (typeof errObj.message === "string") {
      return errObj.message;
    }
  }
  return "An error occurred";
}
