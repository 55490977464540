import styles from "./MediaCenter.module.scss";
import Overlay from "../Overlay";
import MediaList from "./MediaList";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { MediaListOverlayType } from "./types";

/**
 * Overlay with media list
 *
 * @author 					Pætur Mortensen
 */
export default function MediaListOverlay({ show, setShow, onInsert }: MediaListOverlayType): JSX.Element {
  // If this overlay should not be shown, return empty jsx
  if (!show) return <></>;

  return (
    <Overlay>
      <div className={styles.mediaListOverlay}>
        <div
          className={styles.closeOverlayBtn}
          onClick={() => {
            setShow(false);
          }}
        >
          <FontAwesomeIcon icon={faClose as IconProp} />
        </div>
        <div className={styles.listOverlayInner}>
          <MediaList onInsert={onInsert} />
        </div>
      </div>
    </Overlay>
  );
}
