import SimpleCheckbox from "src/Components/ui/checkbox/SimpleCheckbox/SimpleCheckbox";
import Layout from "../Layout";
import { EWhatson } from "src/views/WhatsOn/types";
import styles from "./Organizer.module.scss";
import { useEffect, useState } from "react";
import Select from "react-select";
import countryCodes from "../../../../../assets/countryCodes.json";
import VfiInput from "src/assets/VfiInput/VfiInput";
import { IOrganizer } from "src/views/WhatsOn/stores/organizer-store/OrganizerStore";
import { getWhatsonStore, useWhatsonManager } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";
/**
 * Custom components for the react-select
 */
const customComponents = {
  IndicatorSeparator: () => null, // Render nothing for the IndicatorSeparator
};
/**
 * Country code map
 */
const COUNTRY_CODE_MAP = countryCodes.map((e: any) => {
  return {
    label: `+${e.dial_code} ${e.name}`,
    value: e.dial_code,
  };
});
/**
 * Organizer props
 */
interface IOrganizerProps {
  title: string;
  subtitle: string;
  whatsOnType: EWhatson | null;
}
/**
 * Form fields
 */
const FORM_FIELDS: {
  [key: string]: {
    component: any;
    name: string;
    placeholder: string;
    type_value?: string;
  };
} = {
  comapny_name: {
    component: OrganizerInput,
    name: "Organizer",
    placeholder: "Enter organizer name",
  },
  // name: {
  //   component: OrganizerInput,
  //   name: "Event manager",
  //   placeholder: "Enter full name",
  // },
  website: {
    component: OrganizerInput,
    name: "Website or social link",
    placeholder: "Url",
  },
  phone: {
    component: OrganizerPhoneInput,
    name: "Phone number",
    placeholder: "Enter phone number",
    type_value: "tel",
  },
  email: {
    component: OrganizerInput,
    name: "Email",
    placeholder: "Enter email",
  },
} as const;
/**
 * Main component - Organizer
 */
export default function Organizer({
  title = "Organizer",
  subtitle = "Provide details about the organizer.",
  whatsOnType = null,
}: IOrganizerProps) {
  /**
   * Store properties
   */
  const {
    organizer,
    organizer_same_as_place: same,
    setOrganizer,
    setOrganizerSameAsPlace,
    location,
    type,
    errors,
    validateOrganizer,
  } = getWhatsonStore();
  /**
   * Toggle error
   */
  const [toggle_error, setToggleError] = useState<boolean>(false);
  useEffect(() => {
    // return () => {
    //   /**
    //    * Validate the organizer on unmount
    //    */
    //   validateOrganizer();
    // };
  }, []);
  /**
   * Set the organizer same as place when the location changes, based on some conditions
   */
  useEffect(() => {
    if (location?.custom || !location.place) {
      setOrganizerSameAsPlace(false);
    }
  }, [location.custom]);
  /**
   * Render - If the whatsOnType is not found, return a message
   */
  if (!whatsOnType) return <div>What's On type not found</div>;
  /**
   * Render
   */
  return (
    <Layout title={title} subtitle={subtitle}>
      {/* Content */}
      <div className={styles.toggle}>
        {toggle_error && (
          <div className={styles.error}>No place selected, either select a registered place or fill out the form.</div>
        )}
        <SimpleCheckbox
          id="organizer_is_place"
          checked={same}
          label={`The people running the place are also organizing the ${whatsOnType}.`}
          onChange={() => {
            if (!location?.custom && location.place) {
              setOrganizerSameAsPlace(!same);
              setToggleError(false);
              if (!errors?.[type]?.Organizer?.valid) validateOrganizer();
            } else {
              console.error("Cannot set organizer same as place when location is custom");
              setToggleError(true);
            }
          }}
        />
      </div>
      {/* Render Form Fields */}
      <div className={`${styles.form} ${same ? styles.disabled : ""}`}>
        {Object.keys(FORM_FIELDS).map((key) => {
          const Component = FORM_FIELDS[key].component;
          return (
            <Component
              key={key}
              type={FORM_FIELDS[key].type_value}
              label={FORM_FIELDS[key].name}
              value={organizer[key as keyof IOrganizer].value}
              error={organizer[key as keyof IOrganizer].error.message}
              placeholder={FORM_FIELDS[key].placeholder}
              onChange={(e: any) => {
                if (FORM_FIELDS[key].type_value === "tel" && Number.isNaN(Number(e.target.value))) return;
                setOrganizer({ [key]: e.target.value });
                if (!errors?.[type]?.Organizer?.valid) validateOrganizer();
              }}
            />
          );
        })}
      </div>
    </Layout>
  );
}
/**
 * Organizer input props
 */
interface IOrganizerInputProps {
  label: string;
  value: string;
  error: string;
  placeholder: string;
  type?: string;
  onChange: (e: any) => void;
}
/**
 * Organizer Input component - minimal
 */
function SimpleOrganizerInput({ value, error, placeholder, onChange, type = "text" }: Partial<IOrganizerInputProps>) {
  /**
   * Render
   */
  return (
    <VfiInput
      value={value}
      error={error}
      type={type}
      placeholder={placeholder}
      containerClassName={styles.input_field}
      style={{
        backgroundColor: "#fafafa",
      }}
      onChange={onChange}
    />
  );
}
/**
 * Organizer Input component
 */
function OrganizerInput({ label, value, error, placeholder, onChange }: IOrganizerInputProps) {
  /**
   * Render
   */
  return (
    <div className={styles.input}>
      <div className={styles.label}>{label}</div>
      <SimpleOrganizerInput label={label} value={value} error={error} placeholder={placeholder} onChange={onChange} />
    </div>
  );
}
/**
 * Organizer Phone Input component
 */
function OrganizerPhoneInput({ value, error, placeholder, onChange }: IOrganizerInputProps) {
  /**
   * Store properties
   */
  const { organizer, setOrganizer } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <div className={styles.input}>
      <div className={styles.label}>Phone number</div>
      <div className={`${styles.phone_wrapper} ${styles.input_field}`}>
        <Select
          className={styles.country_code}
          value={
            organizer?.country_code
              ? { value: organizer?.country_code.value, label: `+${organizer?.country_code.value}` }
              : ({} as any)
          }
          placeholder={<div>Country code</div>}
          options={COUNTRY_CODE_MAP}
          onChange={(e: any) => {
            setOrganizer({ country_code: e?.value });
          }}
          components={customComponents}
          maxMenuHeight={200}
          styles={{
            container: (styles) => ({
              ...styles,
              width: "100%",
              height: "100%",
            }),
            control: (styles) => ({
              ...styles,
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              minHeight: "52px",
              maxHeight: "52px",
              color: "#009fff",
              border: "1px solid rgba(100, 100, 100, 0.1)",
              borderRadius: "2px",
            }),
          }}
        />
        <SimpleOrganizerInput
          label={"Phone number"}
          value={value}
          error={error}
          type={"tel"}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
