import React, { useEffect, useState } from "react";
import { faGears, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Pages(props) {
  const { mypages, setPageSelected, pageSelected } = props;
  const [pagesFetched, setPagesFetched] = useState(false);
  // const [mypages, setPages] = useState([{appearance:"Header"},{appearance:"Site menu"}, {appearance:"Footer"}]);

  useEffect(() => {
    // if (pageSelected === "") {
    //   props.editPage(pageSelected);
    // }
    setPagesFetched(true);
  }, [props.siteName, pageSelected]);

  return (
    <div className={"pages-list" + (pagesFetched ? " loaded" : "")}>
      {!pagesFetched ? (
        <div className="load-icon">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        ""
      )}

      <div className="the-list">
        <div className="pages-headline top">{props.siteName}</div> {/*props.name.toUpperCase() */}
        {mypages
          .filter((x) => !x.setting)
          .map((element, i) => {
            return (
              <div
                key={i}
                className={"page " + (element.show === props.pageSelected ? "selected " : "")}
                onClick={(e) => {
                  props.editPage(element);
                }}
              >
                <span className="title">{element.label}</span>
              </div>
            );
          })}
      </div>
      <div className="setting">
        {mypages
          .filter((x) => x.setting)
          .map((element, i) => {
            return (
              <div key={"setting" + i}>
                <div
                  onClick={(e) => {
                    props.editPage(element);
                  }}
                >
                  <FontAwesomeIcon icon={faGears} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
