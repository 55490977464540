import { StateCreator } from "zustand";
import FormValue from "../../types/creators/FormValue";
import { TFormValue } from "../../types";
import { ValidateLocation } from "./utils";
import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";

interface IPlace {
  id: number;
  headline: string;
  contact_zip: number;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  contact_company: string;
  contact_website: string;
  contact_social: string;
}

/**
 * Region Interface
 */
export interface IRegion {
  id: number | string;
  name: string;
}
/**
 * Zip Interface
 */
export interface IZip {
  code: string;
  city: string;
}

/**
 * Location Interface
 */
export interface ILocation {
  /**
   * Place object
   */
  place: IPlace | null;
  /**
   * Location address
   */
  address: string | null;
  /**
   * Location zip
   */
  zip: TFormValue<IZip | null>;
  /**
   * Location region
   */
  region: TFormValue<IRegion | null>;
  /**
   * Location - *Deprecated*
   */
  location?: string | null;
  /**
   * Location latitude
   */
  latitude: number | null;
  /**
   * Location longitude
   */
  longitude: number | null;
  /**
   * Name of location
   */
  nameOfLocation: string;
  /**
   * Custom location flag
   *
   * Tells us whether the location is custom or not
   */
  custom: boolean;
}
/**
 * Location Slice Interface
 */
export interface ILocationSlice {
  /**
   * Location object
   */
  location: ILocation;
  /**
   * Place list
   */
  place_list: any[];
  /**
   * Regions list
   */
  regions: IRegion[];
  /**
   * Location validation flag
   */
  location_valid: boolean;

  /**
   * setLocationSlice - Full setter
   *
   * @param partials
   *
   * @example
   * setLocationSlice({ location: { place: null, address: null, zip: FormValue(null), region: FormValue(null), location: null, latitude: null, longitude: null, custom: false }, place_list: [], regions: [] })
   *
   * @returns void
   */
  setLocationSlice: (partials: Partial<ILocationSlice>) => void;

  /**
   * Set the place list
   *
   * @param pacle_list
   *
   * @returns void
   */
  setPlaceList: (pacle_list: any[]) => void;

  /**
   * Set the location
   *
   * @param location_partials
   *
   * @example
   * setLocation({ address: "Test Address" })
   * setLocation({ zip: { code: "1000", city: "Test City" } })
   *
   * @returns void
   */
  setLocation: (location_partials: Partial<ILocation>) => void;
  /**
   * Set the regions
   *
   * @param regions
   *
   * @returns void
   */
  setRegions: (regions: IRegion[]) => void;
  /**
   * Validate the location section
   *
   * @returns boolean
   */
  validateLocation: () => boolean;
  /**
   * Reset the location slice
   */
  resetLocationSlice: () => void;
}
/**
 * Initial States
 */
const INITIAL_STATES: any = () => ({
  location: {
    place: null,
    address: null,
    zip: FormValue(null),
    region: FormValue(null),
    location: null,
    latitude: null,
    longitude: null,
    custom: false,
    nameOfLocation: "",
  },
  place_list: [],
  regions: [],
});
/**
 * Create Location Slice
 */
export const createLocationSlice: StateCreator<ILocationSlice> = (set, get) => ({
  ...INITIAL_STATES(),

  setLocationSlice: (partials) => set({ ...partials }),

  setLocation: (location_partials) => {
    set((state) => {
      return {
        location: {
          ...state.location,
          ...location_partials,
        },
      };
    });
  },

  setPlaceList: (place_list) => set({ place_list }),

  setRegions: (regions) => {
    set({ regions });
  },
  validateLocation: () => {
    const state = get();
    const whatson_store = useWhatsonManager.getState().getStore();
    const { valid, location } = ValidateLocation({
      ...state.location,
    });
    const { setError } = whatson_store.getState();
    setError("Location", valid);
    set({
      location_valid: valid,
      location: { ...location },
    });
    return valid;
  },
  resetLocationSlice: () => {
    set({ ...INITIAL_STATES() });
  },
});
