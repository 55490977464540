import { activityAction, activityType, typeActionInterface } from "./types/types";
import {
  IconLookup,
  faBell,
  faCalendarEdit,
  faCalendarPlus,
  faCalendarTimes,
  faFileCheck,
  faFileEdit,
  faFilePlus,
  faFileTimes,
  faMapMarkerEdit,
  faMapMarkerPlus,
  faMapMarkerTimes,
  faUserEdit,
  faUserPlus,
  faUserTimes,
} from "@fortawesome/pro-light-svg-icons";

export function activityIconShow(type: activityType, action: activityAction): IconLookup {
  const iconSelector: typeActionInterface<IconLookup> = {
    event: { created: faCalendarPlus, updated: faCalendarEdit, deleted: faCalendarTimes },
    page: { created: faFilePlus, updated: faFileEdit, deleted: faFileTimes, approved: faFileCheck },
    user: { created: faUserPlus, updated: faUserEdit, deleted: faUserTimes },
    place: { created: faMapMarkerPlus, updated: faMapMarkerEdit, deleted: faMapMarkerTimes },
  };
  return iconSelector[type]?.[action] ?? faBell;
}
