import { StoreApi, UseBoundStore, create } from "zustand";
import { WhatsOnStore, whatsonStoreCreator } from "../WhatsonStore";
import { v4 as uuidv4 } from "uuid";

export interface IWhatsonManagerStore {
  type: "event" | "place";

  internal: boolean;

  stores: Partial<
    Record<
      "event" | "place",
      {
        [key: string]: UseBoundStore<StoreApi<WhatsOnStore>>;
      }
    >
  >;

  current_id: string | null;

  /**
   * Set type
   * @param type
   * @returns void
   */
  setType: (type: "event" | "place") => void;

  /**
   * Set internal
   *
   * @param internal
   *
   * @returns void
   */
  setInternal: (internal: boolean) => void;

  /**
   * Get store
   * @param key
   * @returns any
   */
  getStore: (key?: "event" | "place" | null) => UseBoundStore<StoreApi<WhatsOnStore>>;

  /**
   * Set store
   */
  setStore: (key: "event" | "place") => {
    store_bucket: { [key: string]: UseBoundStore<StoreApi<WhatsOnStore>> };
    uuid: string;
  };

  /**
   * Remove store
   */
  removeStore: (key: "event" | "place") => void;

  /**
   * Remove all stores
   */
  removeAllStores: () => void;
}

const INITIAL_STATES = {
  type: "event" as "event" | "place",
  internal: false,
  stores: {},
  current_id: null,
};

/**
 * Create the navigation slice
 */
export const useWhatsonManager = create<IWhatsonManagerStore>((set, get) => ({
  ...INITIAL_STATES,

  setType: (type) => set({ type }),

  setInternal: (internal) => set({ internal }),

  getStore: (key = null) => {
    const { type, stores, setStore, current_id } = get();
    let uuid = current_id;
    let store_bucket = stores?.[key ?? type];
    if (!store_bucket || !uuid) {
      const properties = setStore(key ?? type);
      store_bucket = { ...properties.store_bucket };
      uuid = properties.uuid;
    }
    return store_bucket?.[uuid];
  },

  setStore(key) {
    const uuid = uuidv4();
    const store_bucket = { [uuid]: whatsonStoreCreator() };
    set((state) => ({ stores: { ...state.stores, [key]: { ...store_bucket } }, current_id: uuid }));
    return {
      store_bucket,
      uuid,
    };
  },

  removeStore: (key) => {
    set((state) => {
      const newStores = { ...state.stores };
      delete newStores[key];

      return {
        stores: { ...newStores },
      };
    });
  },

  removeAllStores: () => {
    set({ stores: {} });
  },
}));

export function getWhatsonStore(type: "event" | "place" | null = null) {
  return useWhatsonManager.getState().getStore(type)();
}
