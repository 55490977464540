import { useWhatsonManager } from "../../whatson-manager-store/WhatsonManagerStore";

export const EVENT_ERROR = () => {
  return {
    /**
     * Description
     */
    Description: {
      valid: true,
      validate: () => {
        const { validateDescription } = useWhatsonManager.getState().getStore().getState();
        return validateDescription();
      },
    },
    /**
     * Type
     */
    Type: {
      valid: true,
      validate: () => {
        const { validateTypes } = useWhatsonManager.getState().getStore().getState();
        return validateTypes();
      },
    },
    /**
     * Date
     */
    "Date(s)": {
      valid: true,
      validate: () => {
        const { validateDates } = useWhatsonManager.getState().getStore().getState();
        return validateDates();
      },
    },
    /**
     * Price
     */
    "Price(s)": {
      valid: true,
      validate: () => {
        const { validatePrice } = useWhatsonManager.getState().getStore().getState();
        return validatePrice();
      },
    },
    /**
     * Media
     */
    Media: {
      valid: true,
      validate: () => {
        const { validateMedia } = useWhatsonManager.getState().getStore().getState();
        return validateMedia();
      },
    },
    /**
     * Location
     */
    Location: {
      valid: true,
      validate: () => {
        const { validateLocation } = useWhatsonManager.getState().getStore().getState();
        return validateLocation();
      },
    },
    /**
     * Organizer
     */
    Organizer: {
      valid: true,
      validate: () => {
        const { validateOrganizer } = useWhatsonManager.getState().getStore().getState();
        return validateOrganizer();
      },
    },
    /**
     * Update
     */
    Update: {
      valid: true,
      validate: () => {
        return true;
      },
    },
  };
};
