import { faFontCase } from "@fortawesome/pro-duotone-svg-icons";
import { faText } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import languages from "../language.json";
const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";

export function moduleOptions(index, contentData, setContentData) {
  return [
    {
      is: "choice",
      icon: faText,
      text: languages[language].ck_editor_excerpt.m_options.turn_into_normal_text_section,
      onClick: () => {
        let newContentData = Object.assign([], contentData);
        newContentData[index].type = "ckeditor_5";
        setContentData(newContentData);
      },
    },
    {
      is: "choice",
      icon: faFontCase,
      text: "Make first letter big",
      onClick: () => {
        let newContentData = Object.assign([], contentData);
        // newContentData.splice(i, 1);
        newContentData[index].type = "first_letter_big";
        setContentData(newContentData);
      },
      // style: { color: "red" },
    },
  ];
}

export default function CKEditorExcerpt(props) {
  return (
    <div style={props.style} className="is-excerpt col-lg-12">
      <div className="row">
        <div className="col-lg-2" />
        <VfiCKeditor
          className="col-lg-7"
          value={props.element.languages.blocks}
          onBlur={(e) => {
            props.onBlur(e);
          }}
          toolbar={props.toolbar}
        />
      </div>
    </div>
  );
}
