import SimpleCheckbox from "src/Components/ui/checkbox/SimpleCheckbox/SimpleCheckbox";
import styles from "./Instagram.module.scss";
import VfiInput from "src/assets/VfiInput/VfiInput";
import Select from "react-select";
import InstagramElements from "./InstagramElements";
import FormValue from "src/views/WhatsOn/types/creators/FormValue";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";
/**
 * Main Component - Instagram
 */
export function Instagram() {
  /**
   * Store properties
   */
  const { instagram, setInstagram, include_instagram, setIncludeInstagram } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <div className={styles.container}>
      {/* Title and checbox */}
      <div className={styles.first_section}>
        <div className={styles.title}>Instagram</div>
        <SimpleCheckbox
          id="include-instagram-section"
          label="Include Instagram section"
          onChange={() => {
            setIncludeInstagram(!include_instagram);
          }}
          checked={include_instagram}
        />
      </div>
      {/* Content */}
      <div
        className={styles.content}
        style={{
          opacity: include_instagram ? 1 : 0,
        }}
      >
        {/* Description */}
        <div className={styles.information}>
          Images have to be uploaded manually. If you want the images linked to instagram, include image urls. You can
          aslo include a hashtag and an Instagram profile url.
        </div>
        {/* Profile links */}
        <div className={styles.profile_links}>
          <InstagramInput
            value={instagram?.hashtag?.value ?? ""}
            error={instagram?.hashtag?.error.message ?? ""}
            label="# hashtag"
            placeholder="hashtag"
            onChange={(e) => {
              setInstagram({
                hashtag: FormValue(e.target.value),
              });
            }}
          />
          <InstagramInput
            value={instagram?.url?.value ?? ""}
            error={instagram?.url?.error.message ?? ""}
            label="Instagram url"
            placeholder="url"
            onChange={(e) => {
              setInstagram({
                url: FormValue(e.target.value),
              });
            }}
          />
        </div>
        {/* Image display */}
        <div className={styles.image_display}>
          <div className={styles.display_title}>How many images do you want to display?</div>
          <InstagramImageAmount />
        </div>
        {/* Image elements */}
        <div className={styles.image_elements}>
          <InstagramElements />
        </div>
      </div>
    </div>
  );
}
/**
 * Instagram Input Props
 */
interface IInstagramInputProps {
  value: string;
  error: string;
  label: string;
  placeholder: string;
  onChange: (e: any) => void;
}
/**
 * Instagram Input
 */
function InstagramInput({ value, error, label, placeholder, onChange }: IInstagramInputProps) {
  return (
    <div className={styles.instagram_input}>
      <div className={styles.label}>{label}</div>
      <VfiInput
        value={value}
        error={error}
        placeholder={placeholder}
        containerClassName={styles.input_field}
        style={{
          backgroundColor: "#fafafa",
        }}
        onChange={onChange}
      />
    </div>
  );
}
/**
 * Instagram Image Amount
 */
const IMAGE_AMOUNT_OPTIONS = [1, 3, 5].map((amount) => ({
  value: amount,
  label: amount,
}));
/**
 * Custom components for react-select
 */
const customComponents = {
  IndicatorSeparator: () => null, // Render nothing for the IndicatorSeparator
};
/**
 * Instagram Image Amount
 */
function InstagramImageAmount() {
  /**
   * Store properties
   */
  const { instagram_elements, setInstagramElements } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <div className={styles.image_amount}>
      <Select
        className={styles.country_code}
        value={{
          value: instagram_elements.length,
          label: instagram_elements.length,
        }}
        placeholder={<div>Select amount</div>}
        options={IMAGE_AMOUNT_OPTIONS}
        onChange={(e: any) => {
          let array = new Array(e?.value).fill({
            url: "",
            image: null,
            lang: { en: "", fo: "" },
          });

          setInstagramElements([
            ...array.map((element, index) => {
              if (index > instagram_elements.length - 1)
                return {
                  url: FormValue(""),
                  image: FormValue(null),
                  lang: { en: "", fo: "" },
                };
              return instagram_elements[index];
            }),
          ]);
        }}
        components={customComponents}
        maxMenuHeight={200}
        styles={{
          container: (styles) => ({
            ...styles,
            width: "100%",
            height: "50px",
            textAlign: "left",
          }),
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            color: "#009fff",
            border: "1px solid rgba(100, 100, 100, 0.1)",
            borderRadius: "2px",
          }),
        }}
      />
    </div>
  );
}
