import { useEffect, useState } from "react";
import SubmitEntity from "src/assets/WhatsonEditComponents/SubmitEntity";
import { ValidateAll } from "src/views/WhatsOn/stores/error-store/utils";
import styles from "./Update.module.scss";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";
import { submitWhatson } from "src/views/WhatsOn/contracts/submit";

/**
 * Update component
 */
export default function Update() {
  /**
   * Loading - Tells whether the component is loading
   */
  const [loading, setLoading] = useState<boolean>(true);
  /**
   * Store properties
   */
  const { type, errors, internal, submitted, setAllValid, setSubmitted, goToPreviousStep, setCurrentStepIndex, id } =
    getWhatsonStore();

  useEffect(() => {
    /**
     * Validate all sections
     */
    setAllValid(ValidateAll());
    /**
     * Set loading to false on mount and after validate
     */
    setLoading(false);
  }, []);
  /**
   * Render - If the component is loading, return a loading message
   */
  if (loading) return <div>Loading...</div>;
  /**
   * Render
   */
  return (
    <div className={styles.container}>
      <SubmitEntity
        id={id}
        type={type}
        verifyCriterias={
          Object.entries(errors[type]).map((entry: any) => {
            return {
              label: entry[0],
              passed: entry[1].valid,
            };
          }) as any
        }
        indexChange={(add: number) => {}}
        isFront={!internal}
        saveDraftFunc={() => {
          return submitWhatson(false);
        }}
        enableEdit={() => {
          setSubmitted(false);
          setCurrentStepIndex(0);
        }}
        initial_submitted={submitted}
        hideNavigationButtons={true}
        onSubmitAction={() => true}
        customBackFunc={() => {
          goToPreviousStep();
        }}
      />
    </div>
  );
}
