import { StateCreator } from "zustand";
import { TFormValue } from "../../types";
import FormValue from "../../types/creators/FormValue";
import { TLangAsKeys, TLanguages } from "../../Steps";
import { ValidateMedia } from "./utils";
import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";

export interface IMedia {
  id?: number;
  file?: File;
  url: string | ArrayBuffer;
  text: TLangAsKeys<string>;
}

/**
 * Image Interface
 */
export interface IImage {
  id?: number;
  file?: File | null;
  url: string;
  name: string;
  alt: string;
}
/**
 * Instagram Interface
 */
export interface IInstagram {
  url: TFormValue<string>;
  hashtag: TFormValue<string>;
}
/**
 * Instagram Element Interface
 */
export interface IInstagramElement {
  id?: string | null;
  url: TFormValue<string>;
  name?: string;
  image: TFormValue<IImage | null>;
  lang: Record<TLanguages, string>;
}
/**
 * Media Slice Interface
 */
export interface IMediaSlice {
  media_elements: IMedia[];
  instagram_elements: IInstagramElement[];
  include_instagram: boolean;
  instagram: IInstagram;
  optional_media_content: string[];
  media_valid: boolean;

  /**
   * setMediaSlice - Full setter
   *
   * @param partials
   *
   * @example
   * setMediaSlice({ media_elements: [], instagram_elements: [{ id: null, url: FormValue(""), image: FormValue(null), lang: { en: "", fo: "" } }], include_instagram: false, instagram: { url: FormValue(""), hashtag: FormValue("") }, optional_media_content: [""] })
   *
   * @returns void
   */
  setMediaSlice: (partials: Partial<IMediaSlice>) => void;
  /**
   * Set media elements
   *
   * @param elements
   * @returns void
   */
  setMediaElements: (elements: any[]) => void;
  /**
   * Set instagram elements
   *
   * @param partial
   * @returns void
   */
  setInstagram: (partial: Partial<IInstagram>) => void;
  /**
   * Set instagram elements
   *
   * @param elements
   * @returns void
   */
  setInstagramElements: (elements: IInstagramElement[]) => void;
  /**
   *
   * @param value
   * @returns
   */
  setIncludeInstagram: (value: boolean) => void;
  /**
   * Set optional media content
   *
   * @param value
   * @returns void
   */
  setOptionalMediaContent: (value: string[]) => void;
  /**
   * Validate media
   *
   * @returns boolean
   */
  validateMedia: () => boolean;
  /**
   * Reset media slice
   *
   * @returns void
   */
  resetMediaSlice: () => void;

  setMediaOverlayUpload: (func: any) => void;
  mediaOverlayUpload: any;
}
/**
 * Initial States
 */
const INITIAL_STATES: any = () => ({
  media_elements: [],
  instagram_elements: [{ id: null, url: FormValue(""), image: FormValue(null), lang: { en: "", fo: "" } }],
  include_instagram: false,
  media: true,
  instagram: {
    url: FormValue(""),
    hashtag: FormValue(""),
  },
  optional_media_content: [""],
});
/**
 * Create Media Slice
 */
export const createMediaSlice: StateCreator<IMediaSlice> = (set, get) => ({
  ...INITIAL_STATES(),

  /**
   * Setters
   */
  setMediaSlice: (partials) => set({ ...partials }),

  setMediaElements: (elements: any[]) => {
    set({ media_elements: [...elements] });
  },
  setInstagram: (partial: Partial<IInstagram>) => {
    set((state) => {
      return { instagram: { ...state.instagram, ...partial } };
    });
  },
  setInstagramElements: (elements: any[]) => {
    set({ instagram_elements: [...elements] });
  },
  setIncludeInstagram: (value: boolean) => {
    set({ include_instagram: value });
  },
  setOptionalMediaContent: (optional_media_content: string[]) => {
    set({ optional_media_content: [...optional_media_content] });
  },
  validateMedia: () => {
    const state = get();
    const whatson_store = useWhatsonManager.getState().getStore();
    const { valid, instagram, instagram_elements } = ValidateMedia(
      state.include_instagram,
      state.instagram,
      state.instagram_elements
    );
    const { setError } = whatson_store.getState();
    setError("Media", valid);
    set({
      media_valid: valid,
      instagram,
      instagram_elements: [...instagram_elements],
    });
    return valid;
  },
  resetMediaSlice: () => {
    set({ ...INITIAL_STATES() });
  },
  setMediaOverlayUpload: (func: any) => {
    set({ mediaOverlayUpload: func });
  },
  mediaOverlayUpload: (values: any) => {},
});
