import styles from "./InstagramElements.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCamera } from "@fortawesome/pro-light-svg-icons";
import VfiInput from "src/assets/VfiInput/VfiInput";
import { SHORT_LANG_TO_LONG } from "src/views/WhatsOn/types/maps/LanguageMap";
import { TLanguages } from "../../../description/Description";
import { useRef } from "react";
import FormValue from "src/views/WhatsOn/types/creators/FormValue";
import { TFormValue } from "src/views/WhatsOn/types";
import { IImage } from "src/views/WhatsOn/stores/media-store/MediaStore";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";

/**
 * Main component - InstagramElement
 */
export default function InstagramElement() {
  /**
   * Store properties
   */
  const { type, errors, enabled, instagram_elements, validateMedia, setInstagramElements } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <div className={styles.container}>
      {/* Map Instagram Elements */}
      {instagram_elements.map((element, index) => {
        return (
          <div key={index} className={styles.image_element}>
            {/* Image */}
            <InstagramImage key={index} image={element.image} index={index} />
            {/* Url */}
            <InstagramImageTextInput
              value={element.url.value}
              error={element.url.error.message}
              style={{
                height: "40px",
              }}
              placeholder={"url"}
              onChange={(e) => {
                let newElements = [...instagram_elements];
                newElements[index].url = FormValue(e.target.value);
                setInstagramElements([...newElements]);
                if (!errors?.[type]?.valid) validateMedia();
              }}
            />
            {/* Map Language Inputs */}
            {Object.keys(enabled).map((key) => {
              return (
                <InstagramImageTextInput
                  value={element.lang[key as TLanguages]}
                  error={""}
                  disabled={!enabled[key as TLanguages]}
                  placeholder={`${SHORT_LANG_TO_LONG[key as TLanguages]} text`}
                  key={key}
                  onChange={(e) => {
                    let newElements = [...instagram_elements];
                    newElements[index].lang[key as TLanguages] = e.target.value;
                    setInstagramElements([...newElements]);
                    if (!errors?.[type]?.valid) validateMedia();
                  }}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

/**
 * Instagram Image Props
 */
interface InstagramImageProps {
  image: TFormValue<IImage | null>;
  index: number;
  file_input_ref?: React.RefObject<HTMLInputElement>;
}
/**
 * Instagram Image
 */
function InstagramImage({ image, index }: InstagramImageProps) {
  /**
   * Render empty if image is null
   */
  if (image.value === null) return <InstagramImageEmpty index={index} />;
  /**
   * Render
   */
  return (
    <UploadBox index={index}>
      <img src={image.value.url} />
    </UploadBox>
  );
}
/**
 * Instagram Image Empty
 */
function InstagramImageEmpty({ index }: Partial<InstagramImageProps>) {
  /**
   * Render
   */
  return (
    <UploadBox index={index as number}>
      <FontAwesomeIcon icon={faCamera as IconProp} />
      <div>Upload Image</div>
    </UploadBox>
  );
}
/**
 * Upload Box
 */
function UploadBox({ children, index }: { children: any; index: number }) {
  /**
   * File input ref
   */
  const file_input_ref = useRef<HTMLInputElement>(null);
  /**
   * Store properties
   */
  const { type, errors, internal, instagram_elements, mediaOverlayUpload, validateMedia, setInstagramElements } =
    getWhatsonStore();
  /**
   * Handle file input
   */
  const handleFileInput = (files: FileList) => {
    /**
     * Copy elements
     */
    const updatedElements = [...instagram_elements];
    /**
     * Determine current index
     */
    let currentIndex = index || 0; // Default to 0 if index is undefined
    /**
     * Loop through files
     */
    for (let i = 0; i < files.length; i++) {
      /**
       * Get file
       */
      const file = files[i];
      /**
       * Check if current index is less than updated elements length
       */
      if (currentIndex < updatedElements.length) {
        /**
         * Check if current index is not the same as the index
         */
        if (index !== currentIndex && updatedElements[currentIndex].image.value !== null) {
          /**
           * Increment idnex and continue
           */
          currentIndex++;
          continue;
        }
        /**
         * Update image
         */
        const updatedImage = {
          file: file,
          url: URL.createObjectURL(file),
          name: file.name,
          alt: file.name,
        };
        /**
         * Update elements
         */
        updatedElements[currentIndex] = { ...updatedElements[currentIndex], ...{ image: FormValue(updatedImage) } };
      } else {
        // If there are more files than elements, just push new elements
        break;
      }
      /**
       * Increment index
       */
      currentIndex++;
    }
    /**
     * Set elements
     */
    setInstagramElements(updatedElements);
    if (!errors?.[type]?.valid) validateMedia();
  };
  /**
   * Render
   */
  return (
    <div className={styles.image_container}>
      {/* File Upload Button/Container */}
      <button
        className={`${styles.image} ${styles.empty} ${
          !instagram_elements[index].image.error.message.length ? "" : styles.error
        }`}
        onClick={() => {
          /**
           * :/
           */
          if (internal) {
            mediaOverlayUpload((elements: any) => {
              const updated_elements = [...instagram_elements];
              for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (index + i < updated_elements.length) {
                  updated_elements[index + i] = {
                    ...updated_elements[index + i],
                    id: element.id,
                    image: FormValue({
                      id: element.id,
                      alt: element.alt,
                      url: element.medium.medium,
                      name: element.medium.medium,
                      file: null,
                    }),
                  };
                }
              }

              setInstagramElements(updated_elements);
              if (!errors?.[type]?.valid) validateMedia();
            });
            return;
          }
          file_input_ref?.current?.click();
        }}
      >
        {children}
      </button>
      {/* File Upload Input */}
      <input
        ref={file_input_ref}
        type="file"
        style={{ display: "none" }}
        multiple
        accept=".jpg,.jpeg,.png,.gif,.mp4"
        onChange={(e: any) => {
          e.preventDefault();
          if (e.target.files && e.target.files.length > 0) {
            handleFileInput(e.target.files);
          }
          e.target.value = "";
        }}
      />
    </div>
  );
}

/**
 * Instagram Image Text Input Props
 */
interface InstagramImageTextInputProps {
  value: string;
  error: string;
  placeholder: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
/**
 * Instagram Image Text Input
 */
function InstagramImageTextInput({
  value,
  error,
  placeholder,
  onChange,
  disabled = false,
  style = {},
}: InstagramImageTextInputProps) {
  /**
   * Render
   */
  return (
    <div
      style={
        disabled
          ? {
              opacity: 0.2,
              pointerEvents: "none",
            }
          : {}
      }
      className={styles.input_container}
    >
      <VfiInput
        value={value}
        error={error}
        placeholder={placeholder}
        containerClassName={styles.input_field}
        style={{
          backgroundColor: "#fafafa",
          ...style,
        }}
        onChange={(e) => {
          if (!disabled) onChange(e);
        }}
      />
    </div>
  );
}
