import { useEffect, useState } from "react";
import styles from "./PageFooterDisplay.module.scss";
import "./PageFooterDisplay.scss";
import { safe } from "src/utils";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import vfiLogo from "src/assets/images/vfi-logo.png";
import { IFooterData, props } from "./interfaces";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function PageFooterDisplay(props: props) {
  const { fromDomain, env, shortLang } = props;

  const [f_data, setF_data] = useState<undefined | IFooterData>(undefined);

  useEffect(() => {
    (async () => {
      const footerData = await safe(axios.get(`${env}/api/public/appearances/GetFooter.php?domain=${fromDomain}`));
      if (footerData.success) {
        setF_data(footerData.data?.data);
      } else {
      }
    })();
  }, [fromDomain, env]);

  if (f_data === undefined)
    return (
      <div className={`page-footer-display ${styles["page-footer-display"]}`}>
        <FontAwesomeIcon icon={faSpinner as IconProp} spin />
      </div>
    );

  return (
    <div className={`page-footer-display ${styles["page-footer-display"]}`}>
      <footer className={`footer ${styles["footer"]}`}>
        <div className={`footer-container ${styles["footer-container"]}`}>
          <div className={`upper-foot-wrap ${styles["upper-foot-wrap"]}`}>
            <div className={`footer-logo ${styles["footer-logo"]}`}>
              <a href={"https://" + fromDomain} className={styles["logo"]}>
                <div className={styles["sheephead"]}>
                  <img src={vfiLogo} />
                </div>

                <div className={styles["text"]}>{f_data.languages[shortLang]?.siteName}</div>
              </a>
            </div>
            <div className={`social-media ${styles["social-media"]}`}>
              <div className={`follow-us ${styles["follow-us"]}`}>
                {/* {{ attribute(translate.footer.followUs.headline, activeLang) }} */}
                Follow Us
              </div>
              {f_data.b_modules
                .filter((e) => e.type === "follow")
                .map((e) => (
                  <div key={e.id} dangerouslySetInnerHTML={{ __html: e.block ?? "" }}></div>
                ))}
            </div>
            <div className={`other-sites ${styles["other-sites"]}`}>
              <div className={`text ${styles["text"]}`}>
                {/* {{ attribute(translate.footer.otherSites.headline, activeLang) }} */}
                Other sites
              </div>

              {f_data.b_modules
                .filter((e) => e.type === "other_sites")
                .map((other) => (
                  <span
                    key={other.id}
                    dangerouslySetInnerHTML={{
                      __html: other.languages?.[shortLang]?.blocks ?? "",
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className={`lower-foot ${styles["lower-foot"]}`}>
          <div className={styles["footer-info"]}>
            <div className={styles["top-lang"]}>
              {/* {{ attribute(translate.footer.language.headline, activeLang) }} */}
              Language
            </div>
            <div x-data="{ open: false }" className={styles["lang-option-wrapper"]}>
              <div className={styles["lang-button"]}>
                English <i className="fas fa-caret-down fa-xs"></i>
              </div>
            </div>
          </div>
          {f_data.b_modules
            .filter((e) => e.type === "ckeditor_5")
            .map((footerInfo) => {
              return (
                <div key={footerInfo.id} className={styles["footer-info"]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: footerInfo.languages?.[shortLang]?.blocks ?? "",
                    }}
                  ></div>
                </div>
              );
            })}
        </div>
      </footer>
    </div>
  );
}
