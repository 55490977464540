import { TCheckedDays, TDateType } from "src/views/WhatsOn/stores/date-store/DateStore";
import Layout from "../../Common/Layout";

import styles from "./DateViewComponent.module.scss";
import OvalButton from "src/Components/ui/buttons/OvalButton/OvalButton";
import ToggleCheckbox from "src/Components/ui/checkbox/ToggleCheckbox/ToggleCheckbox";
import TimePicker from "src/Components/ui/TimePicker/TimePicker";
import DatePicker from "src/Components/ui/DatePicker/DatePicker";
import XmarkButton from "src/Components/ui/buttons/XmarkButton/XmarkButton";
import SimpleCheckbox from "src/Components/ui/checkbox/SimpleCheckbox/SimpleCheckbox";
import { useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

/**
 * Date view props
 */
interface DateViewProps {
  title: string;
  subtitle: string;
  whatsOnType: string | null;
}
/**
 * Main component - Date view
 */
export default function DateViewComponent({
  title = "Date(s)",
  subtitle = "Missing subtitle",
  whatsOnType = null,
}: DateViewProps) {
  /**
   * Store properties
   */
  const { dateType, setDateType, validateDates } = getWhatsonStore();
  /**
   * Render content
   */
  const renderContent = () => {
    const unsetDateTypeValues: TDateType[] = ["not_set", null];
    if (unsetDateTypeValues.includes(dateType)) return null;
    return baseLayout({ type: dateType });
  };
  useEffect(() => {
    return () => {
      /**
       * Validate the dates on unmount
       */
      // validateDates();
    };
  }, []);

  const ovalButtonsError = dateType === "not_set";
  /**
   * Render
   */

  return (
    <Layout
      title={title}
      subtitle={
        <div style={{ display: "flex", alignItems: "center", gap: "10px", height: "fit-content" }}>
          {subtitle}
          {/* <GlobalToolTipController
            solid
            title={""}
            className={styles.tooltipIcon}
            priority={"bottom,right,top,left"}
            toolTipElements={
              <div className={"tooltip-card"}>
                Single date: Your event occurs only once on a single date.
                <br />
                <br />
                Specific dates: Your event happens on specific dates on two or more occasions.
                <br />
                <br />
                Recurring days: Your event takes place on two or more recurring days (i.e. repeating event
                periodically).
              </div>
            }
            style={{ marginTop: "2px" }}
          >
            <FontAwesomeIcon icon={faQuestionCircle as IconProp} />
          </GlobalToolTipController> */}
        </div>
      }
    >
      {/* Content */}
      <div className={styles.content}>
        {/* Date Navigation */}
        <div className={styles.dateNav}>
          {/* Date Section Container - Single */}
          <div className={styles.dateSectionContainer}>
            <h2 className={styles.dateNavTitle}>On a Single Day</h2>
            <div className={styles.buttonContainer}>
              <OvalButton
                isActive={dateType === "single"}
                onClick={() => setDateType("single")}
                isError={ovalButtonsError}
              >
                Single
              </OvalButton>
            </div>
          </div>
          {/* Date Section Container - Multiple */}
          <div className={styles.dateSectionContainer}>
            <h2 className={styles.dateNavTitle}>On Multiple Days</h2>
            <div className={styles.buttonContainer}>
              {/* <OvalButton isActive={dateType === "dateRange"} onClick={() => setDateType("dateRange")}>
                Date range
              </OvalButton> */}
              {/* 
              // TODO: Decide if Date range should be in the project. Date range is the same as recurring with the difference being that Date range does not have day selection.
              */}
              <OvalButton
                isActive={dateType === "recurring"}
                onClick={() => setDateType("recurring")}
                isError={ovalButtonsError}
              >
                Recurring
              </OvalButton>
              <OvalButton
                isActive={dateType === "various"}
                onClick={() => setDateType("various")}
                isError={ovalButtonsError}
              >
                Various
              </OvalButton>
            </div>
          </div>
        </div>
        {ovalButtonsError && <div className={styles.invalid_error_message}>Please select one of the date types</div>}
        {renderContent()}
      </div>
    </Layout>
  );
}
/**
 * Base layout
 */
function baseLayout({ type }: { type: TDateType }) {
  /**
   * Render content
   */
  const renderContent = () => {
    switch (type) {
      case "single":
        return <Single />;
      case "various":
        return <Various />;
      case "dateRange":
        return <DateRange />;
      case "recurring":
        return <Recurring />;
      default:
        return null;
    }
  };
  /**
   * Render
   */
  return (
    <div className={styles.dateContent}>
      <div className={styles.dateRenderContent}>{renderContent()}</div>
      <DateWhenVisible />
    </div>
  );
}

/**
 * Date when visible
 */
function DateWhenVisible() {
  /**
   * Store properties
   */
  const { dateWhenVisible, setDateWhenVisible, isTablet } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <div className={styles.dateWhenVisibleContainer}>
      {/* Divider */}
      <div className={styles.divider}></div>
      {/* Date When Visible */}
      <div className={styles.dateWhenVisibleContentContainer}>
        <div className={styles.dateWhenVisibleTitle}>Date When Visible</div>
        <div className={styles.dateWhenVisibleInputContainerWrapper}>
          <DatePicker
            id="dateWhenVisible"
            label="Visible from"
            placeholder="Today"
            value={dateWhenVisible?.toISOString() ?? ""}
            onChange={(date: Date | null) => setDateWhenVisible(date)}
            minDate={new Date()}
            withPortal={isTablet()}
          />
          {dateWhenVisible && (
            <XmarkButton className={styles.datePickerClearButton} onClick={() => setDateWhenVisible(null)} />
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Date time selector
 */
function DateTimeSelector({ config }: { config: { showEndDate: boolean } }) {
  /**
   * Store properties
   */
  const { isTablet } = getWhatsonStore();
  /**
   * Store properties
   */
  const {
    allDay,
    setAllDay,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    type,
    errors,
    validateDates,
  } = getWhatsonStore();
  /**
   * Date time selector config
   */
  const { showEndDate } = config;

  /**
   * Render
   */
  return (
    <div className={styles.dateSelectContainer}>
      {/* Date Section Container Column */}
      <div className={styles.dateSectionContainerColumn}>
        <div className={`${styles.dateSelectTitle} ${styles.dateSelectTitleDate}`}>Date</div>
        <div className={styles.dateSelectDatePickerContainer}>
          <DatePicker
            id="startDate"
            label="Start date"
            placeholder="Select date"
            value={startDate?.toISOString() ?? ""}
            error={!(errors?.[type]?.["Date(s)"].valid || startDate?.toISOString()) ? "Please select a date" : ""}
            onChange={(date: Date | null) => {
              setStartDate(date);
              if (!errors?.[type]?.["Date(s)"].valid) validateDates();
            }}
            className={styles.datePickerCustom}
            minDate={new Date()}
            maxDate={endDate ?? undefined}
            withPortal={isTablet()}
          />
          {/* Show End Date */}
          {showEndDate && (
            <DatePicker
              id="endDate"
              label="End date"
              placeholder="Select date"
              value={endDate?.toISOString() ?? ""}
              error={!(errors?.[type]?.["Date(s)"].valid || endDate?.toISOString()) ? "Please select a date" : ""}
              onChange={(date: Date | null) => {
                setEndDate(date);
                if (!errors?.[type]?.["Date(s)"].valid) validateDates();
              }}
              className={styles.datePickerCustom}
              minDate={startDate ?? undefined}
              withPortal={isTablet()}
            />
          )}
        </div>
      </div>
      {/* Date Section Container Column */}
      <div className={styles.dateSectionContainerColumn}>
        <div className={`${styles.dateSelectTitle} ${styles.dateSelectTitleTime}`}>Time</div>
        <div className={styles.toggleAndInputContainer}>
          <div className={styles.toggleCheckboxContainer}>
            <ToggleCheckbox
              checked={allDay}
              onChange={() => {
                setAllDay(!allDay);
                if (!errors?.[type]?.["Date(s)"].valid) validateDates();
              }}
              id="allDay"
              label="All day"
              className={styles.checkbox}
            />
          </div>
          {!allDay && (
            <div className={styles.inputContainerWrapper}>
              <div className={styles.inputContainer}>
                <div className={styles.inputContainerTitle}>Starts</div>
                <TimePicker
                  time={startTime}
                  setTime={(time) => {
                    setStartTime(time);
                    if (!errors?.[type]?.["Date(s)"].valid) validateDates();
                  }}
                  errors={{
                    hour: !(errors?.[type]?.["Date(s)"].valid || startTime.hour) ? "Please select a time" : "",
                    minute: !(errors?.[type]?.["Date(s)"].valid || startTime.minute) ? "Please select a time" : "",
                  }}
                  disabled={allDay}
                />
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.inputContainerTitle}>Ends</div>
                <TimePicker
                  time={endTime}
                  setTime={(time) => {
                    setEndTime(time);
                    if (!errors?.[type]?.["Date(s)"].valid) validateDates();
                  }}
                  errors={{
                    hour: !(errors?.[type]?.["Date(s)"].valid || endTime.hour) ? "Please select a time" : "",
                    minute: !(errors?.[type]?.["Date(s)"].valid || endTime.minute) ? "Please select a time" : "",
                  }}
                  disabled={allDay}
                />
                {!(endTime.hour === "24" && endTime.minute === "00") && (
                  <button
                    className={styles.removeTime}
                    onClick={() => {
                      setEndTime({ hour: "24", minute: "00" });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes as IconProp} />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Single
 */
function Single() {
  return <DateTimeSelector config={{ showEndDate: false }} />;
}

/**
 * Various
 */
function Various() {
  return <DateTimeSelectorMultiple />;
}

/**
 * Date range
 */
function DateRange() {
  return <DateTimeSelector config={{ showEndDate: true }} />;
}

/**
 * Recurring
 */
function Recurring() {
  return (
    <div className={styles.recurringContainer}>
      <DateTimeSelector config={{ showEndDate: true }} />
      <OnWhichDays />
    </div>
  );
}

/**
 * On which days
 */
function OnWhichDays() {
  /**
   * Store properties
   */
  const { checkedDays, checkDay, uncheckDay, validateDates } = getWhatsonStore();
  /**
   * Days
   */
  const days = useMemo(() => {
    return [
      { id: "monday", label: "Monday" },
      { id: "tuesday", label: "Tuesday" },
      { id: "wednesday", label: "Wednesday" },
      { id: "thursday", label: "Thursday" },
      { id: "friday", label: "Friday" },
      { id: "saturday", label: "Saturday" },
      { id: "sunday", label: "Sunday" },
    ];
  }, []);
  /**
   * Handle checkbox change
   */
  const handleCheckboxChange = (dayId: TCheckedDays, isChecked: boolean) => {
    if (isChecked) {
      checkDay(dayId);
    } else {
      uncheckDay(dayId);
    }
  };
  /**
   * Render
   */
  return (
    <div className={styles.onWhichDaysContainer}>
      <div className={styles.onWhichDaysTitle}>ON WHICH DAYS?</div>
      <div>
        {days.map((day) => (
          <SimpleCheckbox
            labelClassName={styles.onWhichDaysCheckboxLabel}
            key={day.id}
            id={day.id}
            label={day.label}
            checked={checkedDays.includes(day.id as TCheckedDays)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleCheckboxChange(day.id as TCheckedDays, e.target.checked);
              validateDates();
            }}
          />
        ))}
      </div>
      {checkedDays.length === 0 && <div className={styles.invalid_error_message}>At least 1 day must be selected</div>}
    </div>
  );
}

function DateTimeSelectorMultiple() {
  /**
   * Store properties
   */
  const { isTablet } = getWhatsonStore();
  /**
   * Store properties
   */
  const { type, errors, various, setVarious, validateDates } = getWhatsonStore();
  /**
   * Date time selector config
   */
  // const { showEndDate } = config;
  /**
   * Render
   */
  return (
    <>
      {various.map((item, index) => {
        const toggleAllDay = () => {
          const newVarious = [...various];
          newVarious[index].allDay = !item.allDay;
          if (newVarious[index].allDay) {
            newVarious[index].time.start = { hour: "00", minute: "00" };
            newVarious[index].time.end = { hour: "24", minute: "00" };
          } else {
            newVarious[index].time.start = { hour: null, minute: null };
            newVarious[index].time.end = { hour: "24", minute: "00" };
          }
          setVarious([...newVarious]);
        };

        return (
          <div className={styles.dateSelectContainer}>
            {/* Date Section Container Column */}
            <div className={styles.dateSectionContainerColumn}>
              <div className={`${styles.dateSelectTitle} ${styles.dateSelectTitleDate}`}>Date</div>
              <div className={styles.dateSelectDatePickerContainer}>
                <DatePicker
                  id="startDate"
                  label="Start date"
                  placeholder="Select date"
                  error={!(errors?.[type]?.["Date(s)"].valid || item.date?.toISOString()) ? "Please select a date" : ""}
                  value={item.date?.toISOString() ?? ""}
                  onChange={(date: Date | null) => {
                    const newVarious = [...various];
                    newVarious[index].date = date;
                    setVarious([...newVarious]);
                    if (!errors?.[type]?.["Date(s)"].valid) validateDates();
                  }}
                  className={styles.datePickerCustom}
                  minDate={new Date()}
                  withPortal={isTablet()}
                />
              </div>
            </div>
            {/* Date Section Container Column */}
            <div className={styles.dateSectionContainerColumn}>
              <div className={`${styles.dateSelectTitle} ${styles.dateSelectTitleTime}`}>Time</div>
              <div className={styles.toggleAndInputContainer}>
                <div className={styles.toggleCheckboxContainer}>
                  <ToggleCheckbox
                    checked={item.allDay}
                    onChange={() => {
                      toggleAllDay();
                      if (!errors?.[type]?.["Date(s)"].valid) validateDates();
                    }}
                    id={`allDay-${index}`}
                    label="All day"
                    className={styles.checkbox}
                  />
                </div>
                {!item.allDay && (
                  <div className={styles.inputContainerWrapper}>
                    <div className={styles.inputContainer}>
                      <div className={styles.inputContainerTitle}>Starts</div>
                      <TimePicker
                        time={item.time.start}
                        setTime={(time) => {
                          const newVarious = [...various];
                          newVarious[index].time.start = time;
                          setVarious([...newVarious]);
                          if (!errors?.[type]?.["Date(s)"].valid) validateDates();
                        }}
                        errors={{
                          hour: !(errors?.[type]?.["Date(s)"].valid || item.time.start.hour)
                            ? "Please select a time"
                            : "",
                          minute: !(errors?.[type]?.["Date(s)"].valid || item.time.start.minute)
                            ? "Please select a time"
                            : "",
                        }}
                        disabled={item.allDay}
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <div className={styles.inputContainerTitle}>Ends</div>
                      <TimePicker
                        time={item.time.end}
                        setTime={(time) => {
                          const newVarious = [...various];
                          newVarious[index].time.end = time;
                          setVarious([...newVarious]);
                          if (!errors?.[type]?.["Date(s)"].valid) validateDates();
                        }}
                        errors={{
                          hour: !(errors?.[type]?.["Date(s)"].valid || item.time.end.hour)
                            ? "Please select a time"
                            : "",
                          minute: !(errors?.[type]?.["Date(s)"].valid || item.time.end.minute)
                            ? "Please select a time"
                            : "",
                        }}
                        disabled={item.allDay}
                      />
                      {!(item.time.end.hour === "24" && item.time.end.minute === "00") && (
                        <button
                          className={styles.removeTime}
                          onClick={() => {
                            const newVarious = [...various];
                            newVarious[index].time.end.hour = "24";
                            newVarious[index].time.end.minute = "00";
                            setVarious([...newVarious]);
                            if (!errors?.[type]?.["Date(s)"].valid) validateDates();
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes as IconProp} />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {various.length > 1 && (
              <XmarkButton
                className={styles.close_button}
                onClick={() => {
                  const newVarious = [...various];
                  newVarious.splice(index, 1);
                  setVarious([...newVarious]);
                  if (!errors?.[type]?.["Date(s)"].valid) validateDates();
                }}
              />
            )}
          </div>
        );
      })}
      <div className={styles.add_more_wrapper}>
        <button
          className={styles.add_more}
          onClick={() => {
            const newVarious = [...various];
            newVarious.push({
              date: null,
              allDay: false,
              time: { start: { hour: null, minute: null }, end: { hour: "24", minute: "00" } },
            });
            setVarious([...newVarious]);
          }}
        >
          <div className={styles.text}>Add more</div>
          <FontAwesomeIcon size="sm" icon={faPlus as IconProp} />
        </button>
      </div>
    </>
  );
}
