import React, { Component } from "react";
import env from "../../../environment.json";
import { faSortAlt, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import MediaContent from "./MediaContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlayCircle, faRoute } from "@fortawesome/pro-light-svg-icons";
import MediaSettings from "./MediaSettings";
import VfiCheckbox from "../../../assets/VfiCheckbox/VfiCheckbox";
import VideoThumbnail from "react-video-thumbnail";
import VerifyMethods from "../../../assets/VerifyMethods";
import { searchFilter } from "../../../assets/helperFunctions";
import { cloneDeep } from "lodash";
import ToolTip from "../../ToolTips/ToolTip";
import { GlobalToolTipController } from "../../ToolTips/GlobalToolTip";

class MediaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedBy: "date",
      reverse: false,
      showSettings: false,
      contentInSettings: {},
      downloadMenuShown: false,
      downloadSizeChosen: { label: "Original file", value: "." },
      editMedia: {},
      indexMediaSelected: 0,
      settingsRightMenu: "seo",
    };
    this.myInput = React.createRef();
    this.listRef = React.createRef();

    this.indexesRow = [];
    this.imageAmountLoaded = 0;

    this.axios = require("axios");
    this.expandSettings = this.expandSettings.bind(this);
    this.closeSettings = this.closeSettings.bind(this);
    this.nextMedia = this.nextMedia.bind(this);
    this.editSettings = this.editSettings.bind(this);
    this.changeSettingsRightMenu = this.changeSettingsRightMenu.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
  }

  resize() {
    if (this.props.display === "box") {
      let totalWidth = 1000;

      if (this.myInput.current !== null) {
        totalWidth = this.myInput.current.offsetWidth;
      }

      let mediaWidths = [];

      const mediaClasses = document.getElementsByClassName("media");

      for (var key in mediaClasses) {
        if (mediaClasses[key].clientWidth !== undefined) mediaWidths.push(mediaClasses[key].clientWidth);
      }

      // Calculate what row every element is in and store it in an array.

      let currentRow = 0;
      let widthAchieved = 0;
      this.indexesRow = [];
      mediaWidths.forEach((width) => {
        widthAchieved += width;
        if (widthAchieved > totalWidth) {
          currentRow++;
          widthAchieved = width;
        }

        this.indexesRow.push(currentRow);
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  componentDidUpdate(prevProps, prevStates) {
    if (
      prevProps.site !== this.props.site ||
      prevProps.display !== this.props.display ||
      prevProps.searchFilter !== this.props.searchFilter ||
      prevProps.checkboxFilter !== this.props.checkboxFilter
    ) {
      this.setState({
        showSettings: false,
        downloadMenuShown: false,
        downloadSizeChosen: { label: "Original file", value: "." },
      });
    }

    if (prevProps.site !== this.props.site) {
      this.setState({ sortedBy: "date" });
    }

    if (this.state.contentInSettings !== prevStates.contentInSettings && this.listRef.current) {
      const settingsElement = document.getElementById("media-settings");
      const yOffset = 0;
      const y = settingsElement.getBoundingClientRect().top + this.listRef.current.scrollTop + yOffset;
      if (settingsElement != null) {
        // settingsElement.scrollIntoView({behavior: "smooth", inline: "nearest", block: "nearest"});
        this.listRef.current.scrollTo({ top: y, behavior: "smooth" });
      }
    }

    if (
      this.props.showInSettings !== undefined &&
      this.props.showInSettings !== null &&
      this.props.showInSettings !== prevProps.showInSettings
    ) {
      this.expandSettings({ ...this.props.showInSettings, index: 0 });
    }
  }

  expandSettings(e) {
    this.resize();

    if (this.props.display === "list") {
      if (this.state.showSettings) {
        if (this.putSettingsAfterIndex > e.index) this.putSettingsAfterIndex = e.index + 1;
        else this.putSettingsAfterIndex = e.index;
      } else {
        this.putSettingsAfterIndex = e.index + 1;
      }
    }

    if (this.props.display === "box") {
      if (this.putSettingsAfterIndex < e.index) {
        this.putSettingsAfterIndex = 0;

        const currentRow = this.indexesRow[e.index - 1];

        this.putSettingsAfterIndex = e.index;
        for (let i = e.index; i < this.indexesRow.length; i++) {
          if (this.indexesRow[i] === currentRow) this.putSettingsAfterIndex++;
          else break;
        }
        e.index -= 1;
      } else {
        const currentRow = this.indexesRow[e.index];

        this.putSettingsAfterIndex = e.index + 1;
        for (let i = e.index; i < this.indexesRow.length; i++) {
          if (this.indexesRow[i + 1] === currentRow) this.putSettingsAfterIndex++;
          else break;
        }
      }
    }

    if (this.state.showSettings === true && this.state.contentInSettings.id === e.id) {
      this.setState({
        showSettings: false,
      });
    } else {
      this.setState({
        showSettings: true,
        contentInSettings: e,
        downloadMenuShown: false,
        downloadSizeChosen: { label: "Original file", value: "." },
        editMedia: Object.assign({}, e),
        currentIndexSelected: e.index,
      });
    }
  }

  closeSettings() {
    this.setState({ showSettings: false });
  }

  nextMedia(addition) {
    let returnList = searchFilter(this.props.mediaFiles, this.props.searchFilter);
    returnList = this.props.checkboxFilter(returnList);

    returnList.forEach((element, i) => {
      if (element.isSettings) {
        returnList.splice(i, 1);
      }
    });

    let done = false;
    returnList.forEach((element, i) => {
      if (!done && element.id === this.state.contentInSettings.id) {
        if (i + addition >= 0 && i + addition < returnList.length) {
          if (this.props.display === "list") {
            this.putSettingsAfterIndex += addition;
          }
          if (this.props.display === "box") {
            const settingsCurrentRow = this.indexesRow[this.state.currentIndexSelected];
            const settingsTargetRow = this.indexesRow[this.state.currentIndexSelected + addition];

            if (settingsCurrentRow !== settingsTargetRow) {
              this.putSettingsAfterIndex = 0;
              for (let i = 0; i < this.indexesRow.length; i++) {
                if (this.indexesRow[i] <= settingsTargetRow) {
                  this.putSettingsAfterIndex++;
                } else {
                  break;
                }
              }
            }
            this.setState({
              currentIndexSelected: this.state.currentIndexSelected + addition,
            });
          }

          this.setState({
            contentInSettings: returnList[i + addition],
            editMedia: Object.assign({}, returnList[i + addition]),
          });
        } else if (i + addition < 0) {
          if (this.props.display === "list") {
            this.putSettingsAfterIndex = returnList.length;
          }
          if (this.props.display === "box") {
            this.putSettingsAfterIndex = returnList.length;
            this.setState({ currentIndexSelected: this.indexesRow.length - 1 });
          }
          this.setState({
            contentInSettings: returnList[i + addition + returnList.length],
            editMedia: JSON.parse(JSON.stringify(returnList[i + addition + returnList.length])),
          });
        } else if (i + addition >= returnList.length) {
          if (this.props.display === "list") {
            this.putSettingsAfterIndex = 1;
          }
          if (this.props.display === "box") {
            this.putSettingsAfterIndex = 0;
            for (let i = 0; i < this.indexesRow.length; i++) {
              if (this.indexesRow[i] === 0) {
                this.putSettingsAfterIndex++;
              } else {
                break;
              }
            }
            this.setState({ currentIndexSelected: 0 });
          }
          this.setState({
            contentInSettings: returnList[i + addition - returnList.length],
            editMedia: JSON.parse(JSON.stringify(returnList[i + addition - returnList.length])),
          });
        } else {
        }
      }
    });
  }

  editSettings(key, value) {
    const keys = key.split(".");

    let object = cloneDeep(this.state.editMedia);
    object = this.addValueToLast(object, keys, value);
    this.setState({ editMedia: object });
  }

  addValueToLast(object, keys, value) {
    if (keys.length === 1) {
      object[keys[0]] = value;
      return object;
    } else {
      object[keys[0]] = this.addValueToLast(object[keys[0]], keys.slice(1, keys.length), value);
      return object;
    }
  }

  async deleteMedia(id) {
    const shouldDelete = window.confirm("Are you sure you want to delete this media?");
    if (shouldDelete) {
      await this.props.deleteMedia(id);
      this.setState({ showSettings: false });
    }
  }

  changeSettingsRightMenu(value) {
    this.setState({ settingsRightMenu: value });
  }

  render() {
    let returnList = this.props.mediaFiles;
    // let returnList = searchFilter(this.props.mediaFiles, this.props.searchFilter);

    // returnList = this.props.checkboxFilter(returnList);

    returnList.forEach((element, i) => {
      if (element.isSettings) {
        returnList.splice(i, 1);
      }
    });

    if (this.state.showSettings) {
      returnList.splice(this.putSettingsAfterIndex, 0, {
        ...this.state.contentInSettings,
        isSettings: true,
      });
    }

    if (this.props.display.toLowerCase() === "list")
      return (
        <div className="media-list" ref={this.listRef}>
          <table className="media-table">
            <thead>
              <tr>
                <th
                  className={this.props.sortedBy === "fileName" ? "name sorted-by" : "fileName"}
                  onClick={() => {
                    this.props.sortList("fileName");
                    let reverse = false;
                    if (this.props.sortedBy === "fileName" && this.props.reverse === false) reverse = true;
                    this.setState({ sortedBy: "fileName", reverse: reverse });
                  }}
                >
                  <ToolTip
                    offset={{ x: 20, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by filename</div>}
                  >
                    File
                    <FontAwesomeIcon
                      icon={this.props.sortedBy === "fileName" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.props.reverse ? {} : { rotate: 180 }}
                    />
                  </ToolTip>
                </th>
                <th
                  className={this.props.sortedBy === "author.fullName" ? "author sorted-by" : "author"}
                  onClick={() => {
                    this.props.sortList("author.fullName");
                    let reverse = false;
                    if (this.props.sortedBy === "author.fullName" && this.props.reverse === false) reverse = true;
                    this.setState({
                      sortedBy: "author.fullName",
                      reverse: reverse,
                    });
                  }}
                >
                  <ToolTip
                    offset={{ x: 20, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by author</div>}
                  >
                    Author
                    <FontAwesomeIcon
                      icon={this.props.sortedBy === "author.fullName" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.props.reverse ? {} : { rotate: 180 }}
                    />
                  </ToolTip>
                </th>
                <th
                  className={this.props.sortedBy === "locations.0.name" ? "locations sorted-by" : "locations"}
                  onClick={() => {
                    this.props.sortList("locations.0.name");
                    let reverse = false;
                    if (this.props.sortedBy === "locations.0.name" && this.props.reverse === false) reverse = true;
                    this.setState({
                      sortedBy: "locations.0.name",
                      reverse: reverse,
                    });
                  }}
                >
                  <ToolTip
                    offset={{ x: 20, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by location</div>}
                  >
                    location
                    <FontAwesomeIcon
                      icon={this.props.sortedBy === "locations.0.name" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.props.reverse ? {} : { rotate: 180 }}
                    />
                  </ToolTip>
                </th>
                <th
                  className={this.props.sortedBy === "fileSize" ? "file-size sorted-by" : "file-size"}
                  onClick={() => {
                    this.props.sortList("fileSize");
                    let reverse = false;
                    if (this.props.sortedBy === "fileSize" && this.props.reverse === false) reverse = true;
                    this.setState({ sortedBy: "fileSize", reverse: reverse });
                  }}
                >
                  <ToolTip
                    offset={{ x: 20, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by size</div>}
                  >
                    Size
                    <FontAwesomeIcon
                      icon={this.props.sortedBy === "fileSize" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.props.reverse ? {} : { rotate: 180 }}
                    />
                  </ToolTip>
                </th>
                <th
                  className={this.props.sortedBy === "date" ? "date sorted-by" : "date"}
                  onClick={() => {
                    this.props.sortList("date");
                    let reverse = false;
                    if (this.props.sortedBy === "date" && this.props.reverse === false) reverse = true;
                    this.setState({ sortedBy: "date", reverse: reverse });
                  }}
                >
                  <ToolTip
                    offset={{ x: 20, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by date</div>}
                  >
                    Date
                    <FontAwesomeIcon
                      icon={this.props.sortedBy === "date" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.props.reverse ? {} : { rotate: 180 }}
                    />
                  </ToolTip>
                </th>
                <th
                  className={this.props.sortedBy === "hearted" ? "hearted sorted-by" : "hearted"}
                  onClick={() => {
                    this.props.sortList("hearted");
                    this.setState({ sortedBy: "hearted", reverse: false });
                  }}
                >
                  <GlobalToolTipController
                    offset={{ x: 10, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by favorite</div>}
                  >
                    Favourite
                    <FontAwesomeIcon
                      icon={this.props.sortedBy === "hearted" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.props.reverse ? {} : { rotate: 180 }}
                    />
                  </GlobalToolTipController>
                </th>
                <th className="check-select">
                  {!this.props.singleMedia && (
                    <GlobalToolTipController
                      offset={{ x: 5, y: 30 }}
                      title=""
                      delay={600}
                      toolTipElements={<div>Select all</div>}
                    >
                      <VfiCheckbox
                        onChange={(e) => {
                          this.props.selectAll(e.target.checked);
                        }}
                      />
                    </GlobalToolTipController>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.filesInUpload.map((element, i) => {
                const newElement = element;
                newElement.fileName = newElement.name;

                return (
                  <MediaContent
                    key={"upload " + i}
                    isUploading={true}
                    uploadPercent={this.props.uploadPercent}
                    element={newElement}
                    insertClick={this.props.insertClick}
                    singleMedia={this.props.singleMedia}
                  />
                );
              })}
              {returnList.map((element, i) => {
                if (element.isSettings)
                  return (
                    <tr key="settings">
                      <td colSpan="7">
                        <MediaSettings
                          key={"m" + element.id}
                          media={element}
                          editMedia={this.state.editMedia}
                          editSettings={this.editSettings}
                          onComponentLoaded={() => {
                            this.imageAmountLoaded++;
                            if (this.imageAmountLoaded === this.indexesRow.length) {
                              this.imageAmountLoaded = 0;
                              this.resize();
                            }
                          }}
                          showDownloadMenu={(boolean) => {
                            this.setState({ downloadMenuShown: boolean });
                          }}
                          downloadSizeChosen={this.state.downloadSizeChosen}
                          changeDownloadType={(e) => this.setState({ downloadSizeChosen: e })}
                          closeSettings={this.closeSettings}
                          nextMedia={this.nextMedia}
                          openEdit={this.props.openEdit}
                          deleteMedia={this.deleteMedia}
                          rightMenu={this.state.settingsRightMenu}
                          changeRightMenu={this.changeSettingsRightMenu}
                          updateClick={this.props.updateClick}
                          insertClick={this.props.insertClick}
                          setSiteRef={this.props.setSiteRef}
                          site={this.props.site}
                          sites={this.props.sites}
                          updateMedia={this.props.updateMedia}
                          showInputInfo={this.props.showInputInfo}
                        />
                      </td>
                    </tr>
                  );

                if (i >= this.props.showAmount) {
                  return null;
                }

                return (
                  <MediaContent
                    key={element.id}
                    element={element}
                    selectById={this.props.selectById}
                    heartClicked={this.props.heartClicked}
                    expandSettings={this.expandSettings}
                    index={i}
                    insertClick={this.props.insertClick}
                    singleMedia={this.props.singleMedia}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      );
    if (this.props.display.toLowerCase() === "box") {
      return (
        <div ref={this.myInput} className="media-box">
          {this.props.filesInUpload.map((element, i) => {
            const newElement = element;
            newElement.fileName = newElement.name;
            return (
              <div className="media uploading" key={i}>
                <div className="uploading">
                  <div>{newElement.fileName}</div>
                  <div>
                    <progress value={this.props.uploadPercent} max="100" />
                  </div>
                </div>
              </div>
            );
          })}

          {returnList.map((element, i) => {
            const protocolAndHost = env.protocol + env.env;
            if (element.isSettings === false || element.isSettings === undefined) {
              if (i >= this.props.showAmount) {
                return "";
              }
              return (
                <div className="media" key={element.id}>
                  <div
                    className={
                      element.id === this.state.contentInSettings.id && this.state.showSettings
                        ? "image-container selected"
                        : "image-container"
                    }
                  >
                    {VerifyMethods.extensionIsVideo(element.fileExtension) ? (
                      <div className="video-icon">
                        <VideoThumbnail
                          videoUrl={protocolAndHost + "/uploads/" + element.file_name + "." + element.fileExtension}
                          snapshotAtTime={10}
                        />
                        <FontAwesomeIcon className="play-circle" icon={faCircle} />
                        <FontAwesomeIcon className="play-icon" icon={faPlayCircle} />
                      </div>
                    ) : element.fileExtension.toLowerCase() === "gpx" ? (
                      <div className="gpx-icon">
                        <FontAwesomeIcon icon={faRoute} />
                        <p>{element.fileName}</p>
                      </div>
                    ) : (
                      <img
                        src={`${protocolAndHost}/uploads/${element.file_name}${
                          element.fileExrension !== "gif" && element.fileExrension !== "svg" ? "." : "_medium."
                        }${element.fileExtension}`}
                        alt="box media"
                        onLoad={() => {
                          this.imageAmountLoaded++;
                          if (this.imageAmountLoaded === this.indexesRow.length) {
                            this.imageAmountLoaded = 0;
                            this.resize();
                          }
                        }}
                        draggable={false}
                      />
                    )}
                  </div>
                  <div
                    className={
                      element.id === this.state.contentInSettings.id && this.state.showSettings
                        ? "select-button selected"
                        : "select-button"
                    }
                    onClick={() => {
                      this.expandSettings({
                        ...element,
                        index: /*settingsBeen?i-1:*/ i,
                      });
                    }}
                  >
                    <span>
                      {element.id === this.state.contentInSettings.id && this.state.showSettings
                        ? "Selected"
                        : "Select"}
                    </span>
                  </div>
                  <div
                    className="trash-icon"
                    onClick={() => {
                      this.deleteMedia(element.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                  <div className="media-box-details">
                    {this.props.filterData.display.file_name && (
                      <div className="file-name">Name: {`${element.fileName}`}</div>
                    )}
                    {this.props.filterData.display.file_size && (
                      <div className="size-mb">
                        Size: {`${(parseInt(element.fileSize) / 1024 / 1024).toFixed(2)} MB`}
                      </div>
                    )}
                  </div>
                </div>
              );
            } else {
              return (
                <MediaSettings
                  key={"m" + element.id}
                  media={element}
                  editMedia={this.state.editMedia}
                  editSettings={this.editSettings}
                  onComponentLoaded={() => {
                    this.imageAmountLoaded++;
                    if (this.imageAmountLoaded === this.indexesRow.length) {
                      this.imageAmountLoaded = 0;
                      this.resize();
                    }
                  }}
                  showDownloadMenu={(boolean) => {
                    this.setState({ downloadMenuShown: boolean });
                  }}
                  downloadSizeChosen={this.state.downloadSizeChosen}
                  changeDownloadType={(e) => this.setState({ downloadSizeChosen: e })}
                  closeSettings={this.closeSettings}
                  nextMedia={this.nextMedia}
                  openEdit={this.props.openEdit}
                  deleteMedia={this.deleteMedia}
                  rightMenu={this.state.settingsRightMenu}
                  changeRightMenu={this.changeSettingsRightMenu}
                  updateClick={this.props.updateClick}
                  insertClick={this.props.insertClick}
                  setSiteRef={this.props.setSiteRef}
                  site={this.props.site}
                  sites={this.props.sites}
                  showInputInfo={this.props.showInputInfo}
                />
              );
            }
          })}
        </div>
      );
    }

    return "";
  }
}
export default MediaList;
