import { error } from "console";
import VerifyMethods from "src/assets/VerifyMethods";

interface IValidForm {
  name?: string;
  email?: string;
  password?: string;
}

const VALIDATION_MAP: any = {
  name: VerifyMethods.notEmpty,
  email: VerifyMethods.validEmail,
  terms: () => "",
};

export default function validateForm(params: IValidForm) {
  let valid = true;
  let errors = Object.fromEntries(
    Object.entries(params).map((param) => {
      let [key, value] = param;
      let msg = VALIDATION_MAP[key](value);
      if (msg !== "" && valid) valid = false;
      return [
        key,
        {
          message: msg,
          valid: msg === "",
        },
      ];
    })
  );
  return {
    errors: errors,
    valid: valid,
  };
}
