export default function C_WhatWhatson(props) {
  const { data, onChange, headline, types, loaded } = props;

  return (
    <div className="c-what-whatson">
      <div className={"the-list" + (loaded ? " loaded" : "")}>
        {headline && <div className="headline">{headline}</div>}
        <div className="top-margin" />
        {loaded &&
          types.map((e) => (
            <div
              key={e.id}
              className={"option " + e.name.toLowerCase() + (data.whatWhatson === e.id ? " active" : "")}
              onClick={() => {
                onChange({ ...data, whatWhatson: e.id, category_filters: [0] });
              }}
            >
              {e.name}
            </div>
          ))}
      </div>
    </div>
  );
}
