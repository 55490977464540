import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PaletteTemplates.scss";
import empty from "../../../assets/images/empty.jpg";
import { faArrowRight, faEllipsisV, faTimes } from "@fortawesome/pro-light-svg-icons";

export default function PaletteTemplates(props) {
  return (
    <div className="palette-options">
      <div>
        <div id="handle">
          <FontAwesomeIcon icon={faEllipsisV} />
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <div onClick={() => props.close()} className="close">
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="top">
        <div
          className={"top-choice "}
          // onClick={() => setScreen("pages")}
        >
          PALETTES
        </div>
      </div>
      <div className="content">
        <div className="palette">
          {/* <div
            onClick={() => {
              props.setPalette({
                type: "image",
                id: props.selectedPalettePage.id,
              });
            }}
            className="image"
          >
            <div>
              <img alt="empty" src={empty} />
            </div>
          </div> */}
          {/* Old image module */}
          <div
            onClick={() => {
              props.setPalette({
                type: "list",
                id: props.selectedPalettePage.id,
              });
            }}
            className="list"
          >
            <div>
              <div>Page</div>
              <div>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              props.setPalette({
                type: false,
                id: props.selectedPalettePage.id,
              });
            }}
            className="default"
          >
            DEFAULT
          </div>
        </div>
      </div>
    </div>
  );
}
