import { useEffect, useState } from "react";
import styles from "./SubmitPlace.module.scss";
import { useRouterStore } from "src/Components/Router/routerStore";
import Registration from "../../../register/Registration";

const views: any = {
  register: {
    component: Registration,
  },
};

export default function SubmitPlace() {
  const { route, get_parameters } = useRouterStore();

  const View = views?.[route[2]?.nav ?? ""]?.component ?? (() => <></>); // Get current view

  // useEffect(() => {

  //     if (get_parameters?.)

  // }, [route]);

  return (
    <div className={styles.container}>
      Place
      <View />
    </div>
  );
}
