import { useState } from "react";
import "../Shop.scss";
import styles from "../Payment.module.scss";
import { cloneDeep } from "lodash";

export default function UserInfo({ data: d, editable, giveChangedData = () => void 0 }) {
  const [data, setData] = useState(cloneDeep(d));

  return (
    <div className={`${styles["user-info-wrap"]} ${styles.box}`}>
      <div className={`${styles["user-info"]}`}>
        <div className={styles["split-evenly"]}>
          <div className={`${styles["text-input"]}`}>
            <label>First name</label>
            <input
              defaultValue={data.firstName}
              disabled={!editable}
              onChange={(e) => giveChangedData("firstName", e.target.value)}
            />
          </div>
          <div className={`${styles["text-input"]}`}>
            <label>Last name</label>
            <input
              defaultValue={data.lastName}
              disabled={!editable}
              onChange={(e) => giveChangedData("lastName", e.target.value)}
            />
          </div>
        </div>
        <div className={`${styles["text-input"]} ${styles["margin-top"]}`}>
          <label>Email</label>
          <input
            defaultValue={data.email}
            disabled={!editable}
            onChange={(e) => giveChangedData("email", e.target.value)}
          />
        </div>
        <div className={`${styles["text-input"]} ${styles["margin-top"]}`}>
          <label>Phone</label>
          <input
            defaultValue={data.phone}
            disabled={!editable}
            onChange={(e) => giveChangedData("phone", e.target.value)}
          />
        </div>
        <div className={`${styles["text-input"]} ${styles["margin-top"]} ${styles["margin-bottom"]}`}>
          <label>License plate</label>
          <input
            defaultValue={data.licensplate}
            disabled={!editable}
            onChange={(e) => giveChangedData("licensplate", e.target.value)}
          />
        </div>
        <span className={styles["small-label"]}>Address info</span>
        <div className={`${styles["text-input"]}`}>
          <label>Address</label>
          <input
            defaultValue={data.address}
            disabled={!editable}
            onChange={(e) => giveChangedData("address", e.target.value)}
          />
        </div>
        <div className={styles["split-evenly"]}>
          <div className={`${styles["text-input"]} ${styles["margin-top"]}`}>
            <label>City</label>
            <input
              defaultValue={data.city}
              disabled={!editable}
              onChange={(e) => giveChangedData("city", e.target.value)}
            />
          </div>
          <div className={`${styles["text-input"]} ${styles["margin-top"]} ${styles["margin-bottom"]}`}>
            <label>Zip</label>
            <input
              defaultValue={data.zip}
              disabled={!editable}
              onChange={(e) => giveChangedData("zip", e.target.value)}
            />
          </div>
        </div>
        <span className={styles["small-label"]}>Delivery Address info</span>
        <div className={`${styles["text-input"]}`}>
          <label>Address</label>
          <input
            defaultValue={data.deliveryAddress}
            disabled={!editable}
            onChange={(e) => giveChangedData("deliveryAddress", e.target.value)}
          />
        </div>
        <div className={styles["split-evenly"]}>
          <div className={`${styles["text-input"]} ${styles["margin-top"]}`}>
            <label>City</label>
            <input
              defaultValue={data.deliveryCity}
              disabled={!editable}
              onChange={(e) => giveChangedData("deliveryCity", e.target.value)}
            />
          </div>
          <div className={`${styles["text-input"]} ${styles["margin-top"]} ${styles["margin-bottom"]}`}>
            <label>Zip</label>
            <input
              defaultValue={data.deliveryZip}
              disabled={!editable}
              onChange={(e) => giveChangedData("deliveryZip", e.target.value)}
            />
          </div>
        </div>
        <div className={`${styles["text-input"]} ${styles["margin-top"]}`}>
          <label>Comments</label>
          <textarea
            defaultValue={data.comments}
            disabled={!editable}
            onChange={(e) => {
              giveChangedData("deliveryAddress", e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
