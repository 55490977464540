import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Loading.module.scss";

export default function Loading() {
  return (
    <div className={styles.loadingSpinner} role="status" aria-label="Loading content...">
      <FontAwesomeIcon icon={faSpinner as IconProp} spin />
    </div>
  );
}
