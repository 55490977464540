import { slugify } from "src/assets/helperFunctions";
import { WhatsOnStore } from "../../stores/WhatsonStore";
import { TCheckedDays } from "../../stores/date-store/DateStore";
import { IServerSubmitEventForm } from "./event-submit-interface";

/**
 *
 * Work in progress
 *
 */

const days: TCheckedDays[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

function dateTypeTranslate(name: string): string {
  const convertObj: { [key: string]: string } = {
    single: "single",
    recurring: "many",
    various: "specific",
    dateRange: "dateRange",
  };
  return convertObj[name] ?? "";
}

// TODO: Remove any as return type.
export function eventFormToBackendContract(store: WhatsOnStore, publish: boolean = false): IServerSubmitEventForm {
  const description = store.description;
  const descriptionEn = description.en;
  const descriptionFo = description.fo;
  const theReturn = {
    id: store.id,
    token: store.editToken,
    address: {
      ...store.location,
      zipCode: store.location.zip.value?.code,
      mapMarker: { lng: store.location.longitude, lat: store.location.latitude },
      name: store.location.address,
      region: { value: store.location.region.value?.id },
    },
    maxCapacity: 0,
    canCreatePlace: publish, // Can create place if we choose to publish
    instagram: {
      active: store.include_instagram,
      hashtag: store.instagram.hashtag.value,
      profile: store.instagram.url.value,
      images: store.include_instagram
        ? store.instagram_elements.map((instaElement) => ({
            instaLink: instaElement.url.value,
            media: {
              id: instaElement.image.value?.id,
              name: instaElement.image.value?.name ?? "",
              file: instaElement.image.value?.file ?? undefined,
            },
            text: { en: instaElement.lang.en, fo: instaElement.lang.fo },
          }))
        : [],
    },
    isFree: store.isFree,
    isPlace: !store.location.custom,
    place: { label: "", value: store.location.place?.id ?? null },
    placeIsHost: store.organizer_same_as_place,
    prices: {
      payUrl: store.bookingUrl,
      payUrlPerLang: store.bookingUrlPerLang,
      prices: store.prices.map((price) => ({
        price: price.price,
        enLabel: price.label.en,
        foLabel: price.label.fo,
      })),
    },
    textEn: {
      headline: descriptionEn.headlineTextInput.value,
      shortDescription: descriptionEn.shortSummaryTextInput.value,
      description: descriptionEn.descriptionTextInput.value,
      mapSummary: descriptionEn.mapSummaryTextInput.value,
      urlSlug: slugify(descriptionEn.headlineTextInput.value),
      showIt: store.enabled.en,
      payUrl: store.bookingUrls.en,
    },
    textFo: {
      headline: descriptionFo.headlineTextInput.value,
      shortDescription: descriptionFo.shortSummaryTextInput.value,
      description: descriptionFo.descriptionTextInput.value,
      mapSummary: descriptionFo.mapSummaryTextInput.value,
      urlSlug: slugify(descriptionFo.headlineTextInput.value),
      showIt: store.enabled.fo,
      payUrl: store.bookingUrls.fo,
    },
    urls: { extraVideoLinks: store.optional_media_content.map((omc, index) => ({ key: index + 1, url: omc })) },
    nonUser: store.nonUser,
    currentStatus: publish ? "published" : "draft",
    /* Categories - Are saved within the What's On Store. */
    categories: [
      ...Object.keys(store.selectedTypes).map((key: any) => {
        return { id_category: store.selectedTypes[key as number].id };
      }),
    ],
    /* Contact Details - Are saved within the What's On Store. */
    contactDetails: {
      company: store.organizer_same_as_place ? "" : (store.organizer.comapny_name.value ?? ""),
      countryCode: store.organizer_same_as_place
        ? (store.location.place?.contact_zip ?? 100)
        : store.organizer.country_code.value,
      email: store.organizer_same_as_place ? (store.location.place?.contact_email ?? "") : store.organizer.email.value,
      // name: store.organizer_same_as_place ? store.location.place?.contact_name ?? "" : store.organizer.name.value,
      name: "",
      phoneNumber: store.organizer_same_as_place
        ? (store.location.place?.contact_phone ?? "")
        : store.organizer.phone.value,
      /* ------------------------------------------------------------------ */
      /*                                                                    */
      /*      Is this right? Are website and social link the same now?      */
      /*                                                                    */
      website: store.organizer_same_as_place
        ? (store.location.place?.contact_website ?? "")
        : store.organizer.website.value,
      socialLink: store.organizer_same_as_place
        ? (store.location.place?.contact_social ?? "")
        : store.organizer.website.value,
      /*                                                                    */
      /*                                                                    */
      /*                                                                    */
      /* ------------------------------------------------------------------ */
    },
    dates: {
      dateVisible: store.dateWhenVisible?.toDateString() ?? "",
      // days: Object.fromEntries(days.map((day) => [day, store.checkedDays.includes(day)])),
      days: {
        monday: store.checkedDays.includes("monday"),
        tuesday: store.checkedDays.includes("tuesday"),
        wednesday: store.checkedDays.includes("wednesday"),
        thursday: store.checkedDays.includes("thursday"),
        friday: store.checkedDays.includes("friday"),
        saturday: store.checkedDays.includes("saturday"),
        sunday: store.checkedDays.includes("sunday"),
      },
      startDate: store.startDate?.toDateString() ?? "",
      startTime: `${store.startTime.hour ?? "00"}:${store.startTime.minute ?? "00"}`,
      endDate: store.endDate?.toDateString() ?? "",
      endTime: `${store.endTime.hour ?? "00"}:${store.endTime.minute ?? "00"}`,
      occurence: {
        value: dateTypeTranslate(store.dateType ?? "single") ?? "",
        type: store.dateType ?? "",
        label: store.dateType ?? "",
      },
      specificDates: store.various.map((date, i) => ({
        key: i,
        date: date.date?.toDateString() ?? "",
        startTime: `${date.time.start.hour}:${date.time.start.minute}`,
        endTime: `${date.time.end.hour}:${date.time.end.minute}`,
      })),
    },
    images: {
      mainImages: store.media_elements.map((element) => {
        return {
          element: element,
          elm: element.url,
          enText: element.text.en,
          foText: element.text.fo,
          id: element.id,
          key: element.id,
        };
      }),
    },
  };
  return theReturn;
}
