import "../../EditProduct.scss";
import { faChevronDown, faChevronUp, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import env from "../../../../../../environment.json";
import { useState, useEffect } from "react";
import VariantProperties from "./VariantProperties";
import MediaCenter from "src/Components/MediaCenter";
import VariantDetails from "./VariantDetails";
import Preloader from "src/assets/Preloader";
import Modal from "src/Components/Modal";
import { cloneDeep } from "lodash";

/**
 * Single variant
 *
 * @param 			{object} 		variant 									Variant information
 * @param 			{object} 		product 									Product state object
 * @param 			{function} 	setProduct 								Set product state
 * @param 			{object} 		productConfig 						Product config information
 * @param 			{function} 	recalculate_prices 				Recalculate all prices on system
 *
 * @returns 		{jsx} 																Variant card
 *
 * @author 					Pætur Mortensen
 */
export default function Variant({ variant, product, setProduct, productConfig, recalculate_prices }) {
  // Whether variant card is expanded
  const [expanded, setExpanded] = useState(false);
  // Variant media items
  const [localMedia, setlocalMedia] = useState([]);
  // External media for variant
  const [externalMedia, setExternalMedia] = useState([]);
  // Whether the variant is currently loading something
  const [mediaIsLoading, setMediaIsLoading] = useState(false);
  // Confirm deletion (if delete has been pressed)
  const [confirmDelete, setConfirmDelete] = useState(false);
  // Whether system is in the process of deleting
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  useEffect(() => {
    // Set the variant media
    set_variant_media();
  }, []);

  /**
   * Set variant media (separate external and local media items)
   *
   * @author 					Pætur Mortensen
   */
  function set_variant_media() {
    const newLocalMedia = [];
    const newExternalMedia = [];
    // For each media item for variant
    for (const mediaItem of variant.media) {
      // If the item has mediaID property, it's local
      if (typeof mediaItem.mediaID !== "undefined") {
        newLocalMedia.push(mediaItem);
      } else {
        // Item does not have mediaID property, so it's external

        // If the external url is truthy, we add it
        if (mediaItem.url) {
          newExternalMedia.push(mediaItem);
        }
      }
    }

    // Set local and external media separately
    setlocalMedia(newLocalMedia);
    setExternalMedia(newExternalMedia);
  }

  /**
   * Delete a variant
   *
   * @author 					Pætur Mortensen
   */
  function delete_variant() {
    setDeleteIsLoading(true);
    const variantID = variant.variantID;

    axios
      .post(env.protocol + env.env + "/api/secured/shop/DeleteVariant", {
        variantID,
      })
      .then(() => {
        // Remove the variant from the product
        const newProduct = cloneDeep(product);
        const variantIdx = newProduct.variants.findIndex((item) => item.variantID === variantID);
        newProduct.variants.splice(variantIdx, 1);
        setProduct(newProduct);
        setDeleteIsLoading(false);
        setConfirmDelete(false);
      })
      .catch((error) => {
        console.error(error);
        setDeleteIsLoading(false);
        setConfirmDelete(false);
      });
  }

  /**
   * Check whether media has changed since last setting it
   *
   * @param 	{array} 	newMedia 											New media element to check
   *
   * @return 	{boolean} 															Whether there have been changes
   *
   * @author 					Pætur Mortensen
   */
  function media_has_changed(newMedia) {
    // If old media is not the same size as new media, there have been changes
    if (localMedia.length !== newMedia.length) return true;

    // For each original media element...
    for (const idx in localMedia) {
      // If old and new medium mediaID are not the same, there have been changes
      if (localMedia[idx].mediaID !== newMedia[idx].mediaID) return true;
    }

    return false;
  }

  /**
   * Media has changed, handle the change (update in server if needed)
   *
   * @param 		{array} 	media 												New changed media
   *
   * @author 					Pætur Mortensen
   */
  function handle_media_change(media) {
    // If media has not changed, do nothing
    if (!media_has_changed(media)) return;

    setMediaIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/shop/UpdateVariantMedia", {
        media,
        variantID: variant.variantID,
      })
      .then((response) => {
        setMediaIsLoading(false);
        setlocalMedia(media);
      })
      .catch((error) => {
        setMediaIsLoading(false);
        console.error(error);
      });
  }

  /***************************************************************************************************
   *
   * 																				RENDER
   *
   **************************************************************************************************/

  /**
   * Render the variant card header
   *
   * @returns 	{jsx} 												Variant card header
   *
   * @author 					Pætur Mortensen
   */
  function render_variant_header() {
    return (
      <div
        className="variant-card-header"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <div>{variant.label}</div>
        <div>
          <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
        </div>
      </div>
    );
  }

  /**
   * Render the top control panel for the variant card
   *
   * @returns 	{jsx} 										Top control panel for variant
   *
   * @author 					Pætur Mortensen
   */
  function render_variant_cp() {
    return (
      <div className="variant-card-cp">
        {!product.synced && (
          <FontAwesomeIcon className="delete-button" onClick={() => setConfirmDelete(true)} icon={faTrashAlt} />
        )}
      </div>
    );
  }

  /**
   * Render external media items
   *
   * @returns 	{JSX} 											List of external media items
   *
   * @author 					Pætur Mortensen
   */
  function render_external_media() {
    /**
     * Render a single external media item
     *
     * @param 		{object} 	medium 									External media item
     * @param 		{number} 	index 									Array index (array of media items)
     *
     * @returns 	{JSX}															External media item
     *
     * @author 					Pætur Mortensen
     */
    function render_external_media_item(medium, index) {
      return (
        <div key={index} className="media-item">
          <img src={medium.url} alt="external image" />
        </div>
      );
    }

    return (
      <div className="external-media">
        <h3>External media</h3>
        <p>External media comes directly from the supplier and cannot be changed</p>
        <div className="items-container">
          {externalMedia.map((item, index) => render_external_media_item(item, index))}
        </div>
      </div>
    );
  }

  return (
    <div className="variant-card" key={variant.variantID}>
      {render_variant_header()}
      {expanded && (
        <div className="variant-card-content">
          {render_variant_cp()}
          <VariantDetails
            variant={variant}
            product={product}
            setProduct={setProduct}
            productConfig={productConfig}
            recalculate_prices={recalculate_prices}
          />
          <VariantProperties
            variant={variant}
            product={product}
            setProduct={setProduct}
            productConfig={productConfig}
          />
          <div className="variant-card-media">
            <div className="preloader-cont">
              <Preloader show={mediaIsLoading} />
            </div>
            {!product.synced && (
              <>
                <h3>Media</h3>
                <MediaCenter mediaItems={localMedia} onChange={handle_media_change} />
              </>
            )}
            {externalMedia.length > 0 && render_external_media()}
          </div>
        </div>
      )}
      {confirmDelete && (
        <Modal
          type="confirmation"
          close={() => setConfirmDelete(false)}
          title={"Delete '" + variant.label + "'"}
          onCancel={() => setConfirmDelete(false)}
          confirmButtonText="Delete variant"
          onConfirm={() => {
            delete_variant(variant.variantID);
          }}
        >
          Are you sure you want to delete this variant?
          <div className="delete-preloader-container">
            <Preloader show={deleteIsLoading} />
          </div>
        </Modal>
      )}
    </div>
  );
}
