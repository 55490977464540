import styles from "./PlaceList.module.scss";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import env from "src/environment.json";
import axios from "axios";
import Loading from "src/Components/ui/Loading/Loading";
import FormValue from "src/views/WhatsOn/types/creators/FormValue";

import ZIP_CODES from "src/assets/zipCodes.json";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";

/**
 * Zip to city map - A map of zip to city
 */
const ZIP_TO_CITY_MAP: any = Object.entries(ZIP_CODES).reduce((acc: any, [key, value]) => {
  acc[value.code] = value;
  return acc;
}, {});

/**
 * Custom components for the react-select
 */
const customComponents = {
  IndicatorSeparator: () => null, // Render nothing for the IndicatorSeparator
};

/**
 * Place list endpoint url
 */
const placeListEndpoint = `${env.protocol}${env.env}/api/whatson/places/GetPlacesList?ignore_author`;

/**
 * Main component
 */
export default function PlaceList() {
  /**
   * Store properties
   */
  const { location, setLocation, place_list, setPlaceList, type, errors, validateLocation } = getWhatsonStore();
  /**
   * Init - Tells whether everything is initialized
   */
  const [init, setInit] = useState<boolean>(false);
  /**
   * Fetching places - Tells whether the places are being fetched
   */
  const [fetching_places, setFetchingPlaces] = useState<boolean>(false);
  /**
   * Content ref - The reference to the content div
   */
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    /**
     * Set fetching places to true, so the component knows that the places are being fetched
     */
    setFetchingPlaces(true);
    /**
     * Fetch the places
     */
    axios
      .get(placeListEndpoint)
      .then((response) => {
        /**
         * Set the place list on success
         */
        setPlaceList(
          response.data.filter((place: any) => {
            return place?.approval_status === "approve" && place?.current_status === "published";
          })
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        /**
         * Set fetching places to false, so the component knows that the places are fetched
         */
        setFetchingPlaces(false);
      });
  }, []);

  /**
   * Effect to set the init to true when the content ref is mounted
   */
  useEffect(() => {
    if (contentRef?.current && !init) setInit(true);
  }, [contentRef]);

  /**
   * Render a loading component if the places are being fetched
   */
  if (fetching_places && !location.custom && place_list.length === 0) return <Loading />;

  /**
   * Render
   */
  return (
    <div
      style={{
        height: "fit-content",
        // height: !(location.custom && init) ? `${contentRef?.current?.offsetHeight}px` : '0px',
        opacity: !(location.custom && init) ? 1 : 0,
      }}
      className={`${styles.container} ${!location.custom ? styles.enabled : ""}`}
    >
      {/* Content */}
      <div ref={contentRef} className={`${styles.content}`}>
        <h3>CHOOSE PLACE</h3>
        <Select
          value={location?.place && { value: location?.place, label: location?.place?.headline }}
          placeholder={<div>Choose place</div>}
          onChange={(data: any) => {
            setLocation({
              place: data?.value,
              zip: FormValue({
                code: data?.value?.address_zip,
                city: ZIP_TO_CITY_MAP?.[data?.value?.address_zip]?.city ?? "",
              }),
              region: FormValue({
                id: data?.value?.region_id,
                name: data?.value?.region_name,
              }),
              address: data?.value?.address_name,
              latitude: Number(data?.value?.latitude),
              longitude: Number(data?.value?.longitude),
            });
            if (!errors?.[type]?.Location?.valid) validateLocation();
          }}
          components={customComponents}
          maxMenuHeight={200}
          options={place_list.map((place) => ({
            value: place,
            label: place?.headline,
          }))}
          styles={{
            container: (styles) => ({
              ...styles,
              textAlign: "left",
              width: "100%",
              height: "50px",
              ...(!(errors?.[type]?.Location?.valid || location?.place)
                ? {
                    outline: "2px solid red",
                  }
                : {}),
            }),
            singleValue: (styles) => ({
              ...styles,
              textAlign: "left",
            }),
            control: (styles) => ({
              ...styles,
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              color: "#009fff",
              border: "1px solid rgba(100, 100, 100, 0.1)",
              borderRadius: "2px",
            }),
            option: (styles, { isFocused, isSelected }) => {
              return {
                ...styles,
                textAlign: "left",
                backgroundColor: isSelected ? "#009fff" : isFocused ? "#aaaaaa" : "white",
                color: isFocused || isSelected ? "white" : "black",
              };
            },
          }}
        />
      </div>
    </div>
  );
}
