import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import styles from "./ForgotPassword.module.scss";
import VfiButton from "src/assets/VfiButton/VfiButton";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import VfiLogo from "../route-components/VfiLogo/VfiLogo";
import VerifyMethods from "src/assets/VerifyMethods";
import validateForm from "../Submit/forms/utils/form-validation";
import axios from "axios";

import env from "../../../../environment.json";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import { truncate } from "lodash";
import { useRouterStore } from "src/Components/Router/routerStore";

interface Props {
  back?: {
    text: string;
    call: () => void;
  };
}

export default function ForgotPassword({ back = { text: "Go back", call: () => {} } }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const [input, setInput] = useState<string>("");

  const [errors, setErrors] = useState<any>({
    email: {
      message: "",
      valid: true,
    },
    general: {
      message: "",
      valid: true,
    },
  });

  const [success, setSuccess] = useState<boolean>(false);

  const submit = () => {
    let validation = validateForm({ email: input });
    setErrors({
      ...errors,
      email: {
        ...validation.errors.email,
      },
      general: {
        message: "",
        valid: true,
      },
    });
    if (validation.valid) {
      // Send request
      setLoading(true);
      axios
        .post(env.protocol + env.env + "/api/public/ResetPasswordRequest", {
          email: input,
        })
        .then((response) => {
          setSuccess(true);
        })
        .catch((error) => {
          setErrors({
            ...errors,
            general: {
              message: error.message,
              valid: false,
            },
          });
          overlayStore.getState().addOverlay("side_popout_self_close", (close) => {
            return <div className={styles.error_popout}>Error: {error.request.status}</div>;
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <VfiLogo className={styles.logo} />

        {!success ? (
          <>
            <div className={styles.text}>
              <p>
                Please enter your username or email address and we'll send a link to create a new password to your
                email.
              </p>
            </div>
            <VfiInputText
              className={styles.input}
              value={input}
              errorCheckSequence={[VerifyMethods.notEmpty, VerifyMethods.validEmail]}
              hideError={errors.email.valid}
              placeholder={"username or email address"}
              onChange={(e: any) => {
                setInput(e.target.value);
                setErrors({
                  ...errors,
                  email: {
                    message: "",
                    valid: true,
                  },
                });
              }}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") submit();
              }}
            />

            <VfiButton
              disabled={loading}
              onClick={() => {
                submit();
              }}
            >
              {!loading ? (
                <>Create new password</>
              ) : (
                <FontAwesomeIcon className={styles.spinner} spin={true} icon={faSpinner as IconProp} />
              )}
            </VfiButton>
          </>
        ) : (
          // <p className={styles.link_sent}>
          //   A link has been sent to your email.
          // </p>
          <div>
            <h1 className={`${styles["password-title"]}`}>Check your email inbox</h1>
            <p className={`${styles.info} ${styles.center}`}>
              An email has been sent to{" "}
              <span className={styles.blue}>
                <a href={"mailto: " + input.toLowerCase()}>{input.toLowerCase()}</a>
              </span>
              <br />
              Please check your inbox.
            </p>
          </div>
        )}

        {success && (
          <button
            className={styles.no_mail}
            onClick={() => {
              setSuccess(false);
            }}
          >
            Didn't get any mail?
          </button>
        )}

        <div className={styles.bottom_wrapper}>
          <button
            className={styles.go_back}
            onClick={() => {
              back.call();
            }}
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft as IconProp} />
            <p>{back.text}</p>
          </button>
        </div>
      </div>
    </div>
  );
}

// Export view
export const ForgotPasswordView = {
  component: ForgotPassword,
  props: (additional_props: any) => ({
    ...additional_props,
    back: {
      text: "Back to login",
      call: () => {
        useRouterStore.getState().navByLink("login");
      },
    },
  }),
};
