import React, { useEffect, useRef } from "react";
import styles from "./Layout.module.scss";

/**
 * Layout component
 */
export default function Layout({
  title,
  subtitle,
  customSubtitleStyle,
  children,
}: {
  title?: string;
  subtitle?: React.ReactNode;
  customSubtitleStyle?: React.CSSProperties;
  children?: React.ReactNode;
}) {
  /**
   * Render
   */
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {/* Title */}
        {title && <h1 className={styles.title}>{title}</h1>}
        {/* Subtitle */}
        {subtitle && (
          <div className={styles.subtitle} style={customSubtitleStyle}>
            {subtitle}
          </div>
        )}
      </div>
      {/* Children/Content */}
      {children}
    </div>
  );
}
