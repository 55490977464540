import React, { Component } from "react";
import env from "../../../../environment.json";
import {
  faInfo,
  faCheck,
  faMapMarkedAlt,
  faBus,
  faImage,
  faTimes,
  faFilm,
  faPlus,
  faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import { faPen, faImage as faImage2 } from "@fortawesome/pro-solid-svg-icons";
import WhatsonNavigation from "../WhatsonNavigation";
import enFlag from "../../../../../src/assets/images/en.png";
import foFlag from "../../../../../src/assets/images/fo.png";
import "./NewHiking.scss";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
import VfiCKeditor from "../../../../assets/VfiCKeditor/VfiCKeditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import hikingDifficulty from "../../../../assets/hikingDifficulty.json";
import zipCodes from "../../../../assets/zipCodes.json";
import { SimpleMap, SearchBox } from "../../../../assets/GoogleMaps";
import { allObjTrue, capitalize, pushMedia, removeTags, slugify } from "../../../../assets/helperFunctions";
import Switch from "react-switch";
import Verify from "../../../../assets/VerifyMethods";
import { VfiCheckbox2 as VfiCheckbox } from "../../../../assets/VfiCheckbox";
import loadash from "lodash";
import language from "../language.json";
// import QuestionInfo from "../../../../assets/QuestionInfo/QuestionInfo";
import OneMedia from "../../../../assets/PageModules/OneMedia/OneMedia";
import VfiCustomSelect from "../../../../assets/VfiCustomSelect";
import Goose from "../../../../assets/images/Goose";
import InstagramInWhatson from "../../../../assets/InstagramInWhatson";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import UploadMedia from "../common/media/upload-media/UploadMedia";
import { useSitesStore } from "src/Components/ZustandStores/sitesStore";

function insertLanguage(value) {
  return {
    headline: value.headline || "",
    shortDescription: value.excerpt,
    description: value.description,
    summary: value.summary,
    difficultyDescription: value.difficulty_description,
    childrenFriendly: value.children_friendly,
    routeSurface: value.route_surface,
    busRoute: value.bus_route,
    specialComments: value.special_comments,
    mapDescription: value.map_description,
    pos1: {
      gettingThereHeadline: value.getting_there_headline_1,
      gettingThere: value.getting_there_1,
      startingPointHeadline: value.starting_point_headline_1,
      startingPoint: value.starting_point_1,
    },
    pos2: {
      gettingThereHeadline: value.getting_there_headline_2,
      gettingThere: value.getting_there_2,
      startingPointHeadline: value.starting_point_headline_2,
      startingPoint: value.starting_point_2,
    },
    urlSlug: value.url_slug,
    showIt: value.show_it === 1 ? true : false,
  };
}

const positionOptions = [
  { label: "Route A", value: 0 },
  { label: "Route B", value: 1 },
];

class NewHiking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      extraVideoLink: [],
      placeIsNotInList: false,
      mapMarker: { lat: 0, lng: 0 },
      showMarker: false,
      mapCenter: { lat: 62, lng: -6.9 },
      mapZoom: 8,
      uploadedFile: undefined,
      beenEdit: {
        textFo: {
          headline: false,
          description: false,
          shortDescription: false,
          summary: false,
        },
        textEn: {
          headline: false,
          description: false,
          shortDescription: false,
          summary: false,
        },
        distance: false,
        elevation: false,
        maxHeight: false,
      },
      gettingTherePosSelect: { label: "Route A", value: 0 },
      instagramImageAmount: 0,
    };

    this.dateChange = this.dateChange.bind(this);
    this.priceChange = this.priceChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.onMarkerChange = this.onMarkerChange.bind(this);
    this.onMarkerStreetChange = this.onMarkerStreetChange.bind(this);
    this.deleteGpx = this.deleteGpx.bind(this);
    this.saveDraftAndOpenSlug = this.saveDraftAndOpenSlug.bind(this);
    this.defaultDragStart = this.defaultDragStart.bind(this);

    this.headerRef = React.createRef();

    const { whatsonSiteId } = useSitesStore.getState();
    this.whatsonSiteId = whatsonSiteId;

    this.axios = require("axios");
    this.axios.post(env.protocol + env.env + "/api/public/checkLogin").then((response) => {
      var vfiUser = "";
      if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        vfiUser = sessionStorage.getItem("vfiUser");
      } else {
        vfiUser = response.data;
      }
      this.axios
        .post(env.protocol + env.env + "/api/secured/GetUserInfo", {
          vfiUser,
        })
        .then((response) => {
          const user = response.data;
          this.user = { firstname: user.firstname, lastname: user.lastname };
        });
    });
    this.regions = [];
    this.axios
      .get(env.protocol + env.env + "/api/public/GetRegions.php")
      .then((response) => {
        const regions = response.data;
        regions.forEach((element) => {
          this.regions.push({
            label: element.region_name,
            value: element.region_id,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    this.navBackward = false;
    this.navForward = true;
    this.finalPage = false;

    this.protocolAndHost = env.protocol + env.env;

    this.zipCodes = [];
    for (let element in zipCodes) {
      this.zipCodes.push({
        value: zipCodes[element].code,
        label: zipCodes[element].code + " " + zipCodes[element].city,
      });
    }

    this.hours = [];
    for (let i = 0; i < 24; i++) {
      if (i !== 1) this.hours.push({ label: i.toString() + " hours", value: i });
      else this.hours.push({ label: i.toString() + " hour", value: i });
    }

    this.minutes = [];
    for (let i = 0; i < 60; i += 5) {
      this.minutes.push({ label: i.toString() + " minutes", value: i });
    }

    this.language = language[this.props.language].hiking.new;

    this.navPreviewList = [
      {
        is: "choice",
        icon: faFile,
        text: "EN",
        onClick: () => {
          this.saveDraftAndOpenSlug(this.newHiking.textEn.urlSlug);
        },
      },
      {
        is: "choice",
        icon: faFile,
        text: "FO",
        onClick: () => {
          this.saveDraftAndOpenSlug(this.newHiking.textFo.urlSlug);
        },
      },
    ];

    const emptyLang = {
      show_it: 1,
      excerpt: "",
      description: "",
      summary: "",
      difficulty_description: "",
      children_friendly: "",
      route_surface: "",
      bus_route: "",
      special_comments: "",
      map_description: "",
      getting_there_headline_1: "",
      getting_there_1: "",
      starting_point_headline_1: "",
      starting_point_1: "",
      getting_there_headline_2: "",
      getting_there_2: "",
      starting_point_headline_2: "",
      starting_point_2: "",
    };

    this.newHiking = {
      textFo: insertLanguage(emptyLang),
      textEn: insertLanguage(emptyLang),
      urlSlug: "",
      categories: [],
      difficulty: hikingDifficulty[this.props.language][0],
      distance: "",
      elevation: "",
      maxHeight: "",
      duration: "",
      extraFilters: {
        familyFriendly: false,
        villagepaths: false,
        summits: false,
        viewPoints: false,
        roundTrip: false,
        connectingRoutes: false,
      },
      dates: { occurence: { value: "single", label: "Single Date" } },
      isFree: true,
      prices: { prices: [], payUrl: "" },
      images: {
        mainImages: [],
        pos1: {
          gettingThereImages: [],
          startingPointImages: [],
        },
        pos2: {
          gettingThereImages: [],
          startingPointImages: [],
        },
      },
      isPlace: true,
      place: {},
      urls: { extraVideoLinks: [] },
      address: {
        searchBox: "",
        mapMarker: { lat: 62, lng: -6.9 },
        showMarker: false,
        mapCenter: { lat: 62, lng: -6.9 },
        mapZoom: 8,
      },
      addressStreet: {
        pos1: {
          mapMarker: { lat: 0, lng: 0 },
          showMarker: false,
          mapCenter: { lat: 62, lng: -6.9 },
          mapZoom: 15,
        },
        pos2: {
          mapMarker: { lat: 0, lng: 0 },
          showMarker: false,
          mapCenter: { lat: 62, lng: -6.9 },
          mapZoom: 15,
        },
      },
      placeIsHost: true,
      contactDetails: {},
      gpxInfo: [],
      instagram: {
        active: false,
        profile: "",
        hashtag: "",
        images: [
          // {...instagramImage,key:0},
          // {...instagramImage,key:1},
          // {...instagramImage,key:2},
        ],
      },
      reliveMedium: {},
    };

    if (props.updateData !== undefined) {
      const data = this.props.updateData;

      let textEn = {};
      let textFo = {};

      Object.entries(data.text).forEach((entry) => {
        const [key, value] = entry;
        if (key === "english") {
          textEn = insertLanguage(value);
        }
        if (key === "faroese") {
          textFo = insertLanguage(value);
        }
      });

      const difficulty = hikingDifficulty.english.find(
        (element) => parseInt(element.value) === parseInt(data.data.difficulty)
      );
      const distance = data.data.distance;
      const elevation = data.data.elevation;
      const maxHeight = data.data.max_height;
      const duration = data.data.duration;

      this.state.beenEdit = allObjTrue(this.state.beenEdit);
      this.state = {
        ...this.state,
        hours: { ...this.hours[parseInt(duration / 60)] },
        minutes: { ...this.minutes[(duration % 60) / 5] },
      };

      const name = data.data.address_text;
      const mapMarker = { lat: data.data.latitude, lng: data.data.longitude };
      const mapCenter = {
        lat: parseFloat(data.data.latitude),
        lng: parseFloat(data.data.longitude),
      };
      const mapMarkerStreet1 = {
        lat: data.data.lat_street_1,
        lng: data.data.long_street_1,
      };
      const mapCenterStreet1 = {
        lat: parseFloat(data.data.lat_street_1),
        lng: parseFloat(data.data.long_street_1),
      };
      const mapMarkerStreet2 = {
        lat: data.data.lat_street_2,
        lng: data.data.long_street_2,
      };
      const mapCenterStreet2 = {
        lat: parseFloat(data.data.lat_street_2),
        lng: parseFloat(data.data.long_street_2),
      };
      const mapZoom = 15;
      const showMarker = true;
      const region = {
        label: capitalize(data.data.region_name),
        value: data.data.region,
      };

      const address = {
        searchBox: name,
        mapCenter,
        mapMarker,
        mapZoom,
        showMarker,
      };
      const addressStreet = {
        pos1: {
          mapCenter: mapCenterStreet1,
          mapMarker: mapMarkerStreet1,
          mapZoom,
          showMarker,
        },
        pos2: {
          mapCenter: mapCenterStreet2,
          mapMarker: mapMarkerStreet2,
          mapZoom,
          showMarker,
        },
      };

      if (mapCenter)
        this.state = {
          ...this.state,
          mapMarker,
          showMarker: true,
          mapCenter,
          mapZoom: 15,
        };

      let mainImages = [];
      let pos1 = {
        gettingThereImages: [],
        startingPointImages: [],
      };
      let pos2 = {
        gettingThereImages: [],
        startingPointImages: [],
      };

      Object.entries(data.images).forEach((entry) => {
        const [key, value] = entry;

        let imgKey = 0;
        if (key === "main") {
          value.forEach((e) => {
            mainImages.push({
              key: imgKey,
              id: e.id_media,
              alt: e.alt,
              file_name: e.file_name,
              file_extension: e.file_extension,
              is_feature: e.is_feature === 1 ? true : false,
              enText: e.english.text,
              foText: e.faroese.text,
            });
            imgKey++;
          });
        }
        if (key === "getting_there_1") {
          value.forEach((e) => {
            pos1.gettingThereImages.push({
              key: imgKey,
              id: e.id_media,
              alt: e.alt,
              file_name: e.file_name,
              file_extension: e.file_extension,
              enText: e.english.text,
              foText: e.faroese.text,
            });
            imgKey++;
          });
        }
        if (key === "starting_point_1") {
          value.forEach((e) => {
            pos1.startingPointImages.push({
              key: imgKey,
              id: e.id_media,
              alt: e.alt,
              file_name: e.file_name,
              file_extension: e.file_extension,
              enText: e.english.text,
              foText: e.faroese.text,
            });
            imgKey++;
          });
        }
        if (key === "getting_there_2") {
          value.forEach((e) => {
            pos2.gettingThereImages.push({
              key: imgKey,
              id: e.id_media,
              alt: e.alt,
              file_name: e.file_name,
              file_extension: e.file_extension,
              enText: e.english.text,
              foText: e.faroese.text,
            });
            imgKey++;
          });
        }
        if (key === "starting_point_2") {
          value.forEach((e) => {
            pos2.startingPointImages.push({
              key: imgKey,
              id: e.id_media,
              alt: e.alt,
              file_name: e.file_name,
              file_extension: e.file_extension,
              enText: e.english.text,
              foText: e.faroese.text,
            });
            imgKey++;
          });
        }
      });

      let gpxInfo = [];

      data.gpx.forEach((element, i) => {
        gpxInfo.push({
          key: i + 1,
          id: element.id_media,
          name: element.name,
          deleted: false,
        });
      });

      let instagram = {
        active: data.data.instagram_active ? true : false,
        hashtag: data.data.instagram_hashtag,
        profile: data.data.instagram_profile,
        // images: [],
        images: data.instagram.map((element, i) => {
          return {
            instaLink: element.url,
            media:
              element.id_media !== null
                ? {
                    id: element.id_media,
                    alt: element.alt,
                    file_name: element.file_name,
                    extension: element.extension,
                  }
                : {},
            key: i,
            text: { en: element.text.en, fo: element.text.fo },
          };
        }),
      };

      // data.instagram.forEach((element,i) => {
      //   instagram.images.push({
      //     instaLink: element.url,
      //     media: element.id_media !== null ? {
      //       id: element.id_media,
      //       alt: element.alt,
      //       file_name: element.file_name,
      //       extension: element.extension
      //     } : {},
      //     key:i
      //   });
      // });

      this.newHiking = {
        id: data.data.id,
        difficulty,
        distance,
        elevation,
        maxHeight,
        duration,
        extraFilters: {
          familyFriendly: data.data.family_friendly === 1 ? true : false,
          villagepaths: data.data.villagepaths === 1 ? true : false,
          summits: data.data.summits === 1 ? true : false,
          viewPoints: data.data.view_points === 1 ? true : false,
          roundTrip: data.data.round_trip === 1 ? true : false,
          connectingRoutes: data.data.connecting_routes === 1 ? true : false,
        },
        textFo,
        textEn,
        images: {
          mainImages,
          pos1,
          pos2,
        },
        isPlace: data.place_is_known === "1" ? true : false,
        place: {
          /* id and label populated in "componentDidMount" */
        },
        reliveMedium: {
          id: data.data.relive_medium,
          src: data.data.relive_url,
        },
        address,
        addressStreet,
        region,
        placeIsHost: data.place_is_host === "1" ? true : false,
        gpxInfo,
        instagram,
      };
    }
    this.newHikingSaved = loadash.cloneDeep(this.newHiking);
  }

  componentDidMount() {
    if (this.props.creatingANew) {
      this.saveInterval = setInterval(() => {
        if (!loadash.isEqual(this.newHikingSaved, this.newHiking)) {
          // newHiking and newHikingSaved are never saved
          // Do the auto-save
          const vfiUser = sessionStorage.getItem("vfiUser");

          let link;
          let isNewHike;

          if (this.newHiking.id !== undefined) {
            link = env.protocol + env.env + "/api/secured/whatson/hiking/AddOrUpdateHiking";
            isNewHike = false;
          } else {
            link = env.protocol + env.env + "/api/secured/whatson/hiking/AddOrUpdateHiking";
            isNewHike = true;
          }
          this.axios
            .post(link, {
              ...this.newHiking,
              currentStatus: "draft",
              vfiUser,
            })
            .then((response) => {
              let newValue = response.data;
              this.props.autoSave(newValue, isNewHike);
              this.newHiking.id = newValue.id;
              this.newHikingSaved = loadash.cloneDeep(this.newHiking);
            })
            .catch((error) => console.error(error));
        }
      }, 30000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addOne !== this.props.addOne) {
      const media = this.props.addOne;
      if (media.fileExtension === undefined) media.fileExtension = media.file_extension;

      let maxKey = 0;
      switch (this.mediaIs) {
        case "mainImage":
          this.newHiking.images.mainImages.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          let firstImages = this.newHiking.images.mainImages.length === 0 ? true : false;
          media.forEach((m, i) => {
            this.newHiking.images.mainImages.push({
              key: maxKey + 1,
              id: m.id,
              file_extension: m.fileExtension,
              is_feature: firstImages && i === 0 ? true : false,
              alt: m.alt,
              file_name: m.file_name,
            });
            maxKey++;
          });
          break;
        case "gpxFile":
          this.newHiking.gpxInfo.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          media.forEach((m) => {
            this.newHiking.gpxInfo.push({
              ...m,
              key: maxKey + 1,
              name: m.file_name,
              deleted: false,
              alt: m.alt,
              file_name: m.file_name,
            });
            maxKey++;
          });
          break;
        case "gettingThere1":
          this.newHiking.images.pos1.gettingThereImages.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          media.forEach((m) => {
            this.newHiking.images.pos1.gettingThereImages.push({
              key: maxKey + 1,
              id: m.id,
              file_extension: m.fileExtension,
              alt: m.alt,
              file_name: m.file_name,
            });
            maxKey++;
          });
          break;
        case "startingPoint1":
          this.newHiking.images.pos1.startingPointImages.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          media.forEach((m) => {
            this.newHiking.images.pos1.startingPointImages.push({
              key: maxKey + 1,
              id: m.id,
              file_extension: m.fileExtension,
              alt: m.alt,
              file_name: m.file_name,
            });
            maxKey++;
          });
          break;
        case "gettingThere2":
          this.newHiking.images.pos2.gettingThereImages.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          media.forEach((m) => {
            this.newHiking.images.pos2.gettingThereImages.push({
              key: maxKey + 1,
              id: m.id,
              file_extension: m.fileExtension,
              alt: m.alt,
              file_name: m.file_name,
            });
            maxKey++;
          });
          break;
        case "startingPoint2":
          this.newHiking.images.pos2.startingPointImages.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          media.forEach((m) => {
            this.newHiking.images.pos2.startingPointImages.push({
              key: maxKey + 1,
              id: m.id,
              file_extension: m.fileExtension,
              alt: m.alt,
              file_name: m.file_name,
            });
            maxKey++;
          });
          break;
        case "instagram":
          this.newHiking.instagram.images[this.instaIndex].media = {
            ...media,
            extension: media.fileExtension,
          };
          break;
        case "relive":
          this.newHiking.reliveMedium = {
            id: media.id,
            extension: media.fileExtension,
            src: media.medium.large,
          };
          break;
        default:
          break;
      }
      this.setState({});
    }
    if (prevProps.addContent !== this.props.addContent) {
      this.addContent(this.contentIs, this.props.addContent);
      this.setState({});
    }

    const headerElement = this.headerRef.current;
    const index = this.props.index;
    if (headerElement && index !== undefined) {
      headerElement.scroll({
        left: 73 * index + 51.5 - headerElement.parentNode.scrollWidth / 2,
        behavior: "smooth",
      });
    }
  }

  addContent(destination, value) {
    if (typeof destination === "string") destination = destination.split(".");

    destination.reverse().forEach((element, i) => {
      value = { [element]: value };
    });

    loadash.merge(this.newHiking, value);
  }

  dateChange(e) {
    this.newHiking.dates = e;
  }

  priceChange(e) {
    this.newHiking.prices = e;
  }

  uploadFile(e) {
    this.setState({ uploadFiles: e });

    var formData = new FormData();

    e.forEach((element) => {
      formData.append("fileToUpload[]", element, element.name);
    });
    formData.append("siteId", "0");
    formData.append("userId", sessionStorage.getItem("vfiUser"));

    this.axios
      .post(env.protocol + env.env + "/api/secured/UploadMediaFile", formData, this.config)
      .then((res) => {
        this.setState({ uploadedFile: pushMedia(res.data[0]) });
        this.newHiking.images.mainImage = res.data[0];
      })
      .catch(function (err) {
        console.error(err);
      });
  }

  onAddressChange(data) {
    const lat = data.geometry.location.lat(data.formatted_address);
    const lng = data.geometry.location.lng(data.formatted_address);
    if (data.address_components)
      this.regionAndZipPopulate({
        results: [{ address_components: data.address_components }],
      });
    this.newHiking.address = {
      ...this.newHiking.address,
      name: data.formatted_address,
      mapCenter: { lat: lat, lng: lng },
      mapZoom: 15,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
    };
    this.newHiking.addressStreet = {
      pos1: {
        ...this.newHiking.addressStreet,
        name: data.formatted_address,
        mapCenter: { lat: lat, lng: lng },
        mapZoom: 15,
        showMarker: true,
        mapMarker: { lat: lat, lng: lng },
      },
      pos2: {
        ...this.newHiking.addressStreet,
        name: data.formatted_address,
        mapCenter: { lat: lat, lng: lng },
        mapZoom: 15,
        showMarker: true,
        mapMarker: { lat: lat, lng: lng },
      },
    };
    this.newHiking.address.searchBox = data.formatted_address;
    this.setState({});
  }

  onMarkerChange(e) {
    if (e.geocode) {
      this.regionAndZipPopulate(e.geocode);
      this.newHiking.address.searchBox = e.geocode.results[0]
        ? e.geocode.results[0].formatted_address
        : this.newHiking.address.searchBox;
    }

    const eventClick = e.mapMouseEvent;

    this.newHiking.address = {
      ...this.newHiking.address,
      mapMarker: { lat: eventClick.latLng.lat(), lng: eventClick.latLng.lng() },
      mapCenter: { lat: eventClick.latLng.lat(), lng: eventClick.latLng.lng() },
      showMarker: true,
      mapZoom: 15,
    };

    this.setState({});
  }

  onMarkerStreetChange(e, pos) {
    const mouseEvent = e.mapMouseEvent;
    this.newHiking.addressStreet[pos] = {
      ...this.newHiking.addressStreet,
      mapMarker: { lat: mouseEvent.latLng.lat(), lng: mouseEvent.latLng.lng() },
      mapCenter: { lat: mouseEvent.latLng.lat(), lng: mouseEvent.latLng.lng() },
      showMarker: true,
    };

    this.setState({});
  }

  regionAndZipPopulate(data) {
    let zip = -1;
    let region = -1;
    const results = data.results;

    for (var i = 0; i < results.length; i++) {
      //found the address
      var result = results[i];

      for (var j = 0; j < result.address_components.length; j++) {
        var component = result.address_components[j];
        if (
          component.types.filter(function (x) {
            return x === "route";
          }).length > 0
        ) {
        } else if (
          component.types.filter(function (x) {
            return x === "street_number";
          }).length > 0
        ) {
        } else if (
          component.types.filter(function (x) {
            return x === "postal_code";
          }).length > 0
        ) {
          zip = component.long_name;
        } else if (
          component.types.filter(function (x) {
            return x === "administrative_area_level_1";
          }).length > 0
        ) {
          region = component.long_name;
        }
      }
      break;
    }

    if (region === "Northern Isles") {
      region = "Norðoyggjar";
    }
    if (["Suduroy", "Suðuroyar"].includes(region)) {
      region = "Suðuroy";
    }

    let found = false;
    this.regions.forEach((element) => {
      if (element.label === region) found = true;
    });
    if (!found) region = -1;

    if (region !== -1) {
      this.newHiking.region = this.regions.find((e) => e.label === region);
    }

    found = false;
    let zipLabel = "";
    Object.keys(zipCodes).forEach((k, v) => {
      if (zipCodes[k].code.toString() === zip) {
        zipLabel = zip + " " + zipCodes[k].city;
        found = true;
      }
    });
    if (!found) {
      zip = -1;
    }

    if (zip !== -1) {
      this.newHiking.address.zipCode = { label: zipLabel, value: zip };
    }
  }

  deleteGpx(index) {
    this.newHiking.gpxInfo[index].deleted = true;
    this.newHiking.gpxInfo.splice(index, 1);
    this.setState({});
  }

  addOrUpdateHiking = (isDraft = false) => {
    let link = "";
    if (this.newHiking.id !== undefined) {
      link = env.protocol + env.env + "/api/secured/whatson/hiking/AddOrUpdateHiking";
    } else {
      link = env.protocol + env.env + "/api/secured/whatson/hiking/AddOrUpdateHiking";
    }
    if (isDraft) {
      this.publish(true, link);
    } else {
      let edited = Object.assign({}, this.state.beenEdit);
      edited = allObjTrue(edited);

      this.setState({ beenEdit: edited }, () => {
        if (this.descriptionPassed && this.practicalInfoPassed) this.publish(false, link);
      });
    }
  };

  publish = (isDraft, link) => {
    this.props.blurScreen(true);
    const vfiUser = sessionStorage.getItem("vfiUser");

    this.axios
      .post(link, {
        ...this.newHiking,
        currentStatus: isDraft ? "draft" : "published",
        vfiUser,
      })
      .then((response) => {
        let newValue = response.data;

        if (this.newHiking.id !== undefined)
          this.props.updateOneAndGoToList(
            //this.props.updateData.data.id,
            this.newHiking.id,
            newValue,
            isDraft
          );
        else {
          this.props.pushOneAndGoToList(newValue, isDraft);
        }

        this.props.blurScreen(false);
        this.props.indexChange(1);
      })
      .catch((error) => console.error(error));
  };

  saveDraftAndOpenSlug(slug) {
    this.props.blurScreen(true);

    let link = "";
    if (this.newHiking.id !== undefined) {
      link = env.protocol + env.env + "/api/secured/whatson/hiking/AddOrUpdateHiking";
    } else {
      link = env.protocol + env.env + "/api/secured/whatson/hiking/AddOrUpdateHiking";
    }

    const vfiUser = sessionStorage.getItem("vfiUser");

    this.axios
      .post(link, {
        ...this.newHiking,
        currentStatus: "draft",
        vfiUser,
      })
      .then((response) => {
        this.props.blurScreen(false);
        window.open("http://demo.faroehiking.fo/hike/" + slug);
      })
      .catch((error) => console.error(error));
  }

  checkLocalSlug(localSlugs, value) {
    let addition = "";
    for (let i = 0; i < localSlugs.length; i++) {
      if (value + addition.toString() === localSlugs[i]) {
        if (typeof addition === "number") {
          addition++;
          i = 0;
        } else {
          addition = 0;
          i = 0;
        }
      }
    }
    return value + addition;
  }

  uniqueSlug(slug, assignDestination, localSlugs, language) {
    this.axios
      .post(env.protocol + env.env + "/api/secured/whatson/hiking/UniqueSlug", {
        slug,
        localSlugs,
        hikeId: this.newHiking.id,
        language,
      })
      .then((response) => {
        this.addContent(assignDestination, response.data);
        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });
  }

  defaultDragStart(e) {
    this.setState({ imageDragging: true });
  }
  defaultDragStop(e) {
    this.setState({ imageDragging: false });
  }

  defaultDrop(e) {}

  render() {
    const showEn = this.newHiking.textEn.showIt;
    const showFo = this.newHiking.textFo.showIt;

    let pos;

    if (this.state.gettingTherePosSelect.value === 0) {
      // Pos A
      pos = "pos1";
    } else {
      // Pos B
      pos = "pos2";
    }

    let content = "";

    switch (this.props.index) {
      case 0:
        content = (
          <div>
            <div className="new-content one">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={true}
                saveDraft={this.addOrUpdateHiking}
                previewListOptions={this.navPreviewList}
              />
              <h1>{this.language.description.header}</h1>
              <p>{this.language.description.sub_text}</p>

              <div className="fill-form row">
                <div className={"col-lg-6 language-box english" + (showEn ? " active" : "")}>
                  <img className="flag" src={enFlag} alt="en-flag" />
                  <VfiCheckbox
                    className="l-include"
                    checked={showEn}
                    onChange={(e) => {
                      this.newHiking.textEn.showIt = e.target.checked;
                      this.setState({});
                    }}
                  />
                  <div className="headline row">
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.description.headline_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={<div className={"tooltip-card"}>Keep it short and sweet.</div>}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiInputText
                      className="col-xl-9"
                      maxLength={50}
                      value={this.newHiking.textEn.headline}
                      defaultValue={this.newHiking.textEn.headline}
                      onChange={(e) => {
                        this.newHiking.textEn.headline = e.target.value;
                        this.newHiking.textEn.urlSlug = slugify(e.target.value);
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textEn: {
                              ...this.state.beenEdit.textEn,
                              headline: true,
                            },
                          },
                        });
                      }}
                      placeholder={this.language.description.headline_en}
                      errorCheckSequence={showEn ? [Verify.notEmpty, Verify.least3Char] : []}
                      hideError={!this.state.beenEdit.textEn.headline}
                      onBlur={(e) => {
                        this.uniqueSlug(
                          slugify(e.target.value),
                          "textEn.urlSlug",
                          [this.newHiking.textFo.urlSlug],
                          "english"
                        );
                      }}
                      disabled={!showEn}
                    />
                  </div>
                  {/* <div className="slug-url-wrap row">
                    <div className="text col-xl-3">
                      {this.language.description.slug_en}
                    </div>
                    <VfiInputText
                      className="col-xl-9"
                      value={this.newHiking.textEn.urlSlug}
                      onChange={(e) => {
                        this.newHiking.textEn.urlSlug = e.target.value;
                        this.setState({});
                      }}
                      inputType="slug"
                      disabled={true}
                    />
                  </div> */}
                  <div className="small-description row">
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.description.excerpt_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see this as the intro text on your hike page, as well as in the grid
                            overview, if displayed in a large block.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className="col-xl-9"
                      maxLength={80}
                      placeholder={this.language.description.excerpt_en}
                      onChange={(e) => {
                        this.newHiking.textEn.shortDescription = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textEn: {
                              ...this.state.beenEdit.textEn,
                              shortDescription: true,
                            },
                          },
                        });
                      }}
                      value={this.newHiking.textEn.shortDescription}
                      errorCheckSequence={showEn ? [Verify.notEmpty, Verify.least10Char] : []}
                      hideError={!this.state.beenEdit.textEn.shortDescription}
                      disabled={!showEn}
                    />
                  </div>
                  <div className="description row">
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.description.description_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the description on the hike page.
                            <br />
                            <br />
                            It's a good idea to start by explaining the route in general of the route, followed by
                            details. Keep the description light and engaging and try to think about your audience’s
                            perspective and what could affect their decision in trying this route.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className={"col-xl-9"}
                      maxLength={3000}
                      placeholder={this.language.description.description_en}
                      onChange={(e) => {
                        this.newHiking.textEn.description = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textEn: {
                              ...this.state.beenEdit.textEn,
                              description: true,
                            },
                          },
                        });
                      }}
                      value={this.newHiking.textEn.description}
                      errorCheckSequence={[Verify.notEmpty, Verify.least70Char]}
                      hideError={!this.state.beenEdit.textEn.description}
                      disabled={!showEn}
                    />
                  </div>
                  <div className="summary row">
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.description.summary_en + " "}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see this short summary when selecting the hike in the map overview.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className="col-xl-9"
                      maxLength={300}
                      placeholder={this.language.description.summary_en}
                      onChange={(e) => {
                        this.newHiking.textEn.summary = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textEn: {
                              ...this.state.beenEdit.textEn,
                              summary: true,
                            },
                          },
                        });
                      }}
                      value={this.newHiking.textEn.summary}
                      errorCheckSequence={showEn ? [Verify.notEmpty, Verify.least10Char] : []}
                      hideError={!this.state.beenEdit.textEn.summary}
                      disabled={!showEn}
                    />
                  </div>
                </div>
                <div className={"col-lg-6 language-box faroese" + (showFo ? " active" : "")}>
                  <img className="flag" src={foFlag} alt="fo-flag" />
                  <VfiCheckbox
                    className="l-include"
                    checked={showFo}
                    onChange={(e) => {
                      this.newHiking.textFo.showIt = e.target.checked;
                      this.setState({});
                    }}
                  />
                  <div className="headline row">
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.description.headline_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={<div className={"tooltip-card"}>Keep it short and sweet.</div>}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiInputText
                      className="col-xl-9"
                      maxLength={50}
                      value={this.newHiking.textFo.headline}
                      defaultValue={this.newHiking.textFo.headline}
                      onChange={(e) => {
                        this.newHiking.textFo.headline = e.target.value;
                        this.newHiking.textFo.urlSlug = slugify(e.target.value);
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textFo: {
                              ...this.state.beenEdit.textFo,
                              headline: true,
                            },
                          },
                        });
                      }}
                      placeholder={this.language.description.headline_fo}
                      errorCheckSequence={showFo ? [Verify.notEmpty, Verify.least3Char] : []}
                      hideError={!this.state.beenEdit.textFo.headline}
                      onBlur={(e) => {
                        this.uniqueSlug(
                          slugify(e.target.value),
                          "textFo.urlSlug",
                          [this.newHiking.textEn.urlSlug],
                          "faroese"
                        );
                      }}
                      disabled={!showFo}
                    />
                  </div>
                  {/* <div className="slug-url-wrap row">
                    <div className="text col-xl-3">
                      {this.language.description.slug_fo}
                    </div>
                    <VfiInputText
                      className="col-xl-9"
                      value={this.newHiking.textFo.urlSlug}
                      onChange={(e) => {
                        this.newHiking.textFo.urlSlug = e.target.value;
                        this.setState({});
                      }}
                      inputType="slug"
                      disabled={true}
                    />
                  </div> */}
                  <div className="small-description row">
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.description.excerpt_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see this as the intro text on your hike page, as well as in the grid
                            overview, if displayed in a large block.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className="col-xl-9"
                      maxLength={80}
                      placeholder={this.language.description.excerpt_fo}
                      onChange={(e) => {
                        this.newHiking.textFo.shortDescription = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textFo: {
                              ...this.state.beenEdit.textFo,
                              shortDescription: true,
                            },
                          },
                        });
                      }}
                      value={this.newHiking.textFo.shortDescription || ""}
                      errorCheckSequence={showFo ? [Verify.notEmpty, Verify.least10Char] : []}
                      hideError={!this.state.beenEdit.textFo.shortDescription}
                      disabled={!showFo}
                    />
                  </div>
                  <div className="description row">
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.description.description_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the description on the hike page.
                            <br />
                            <br />
                            It's a good idea to start by explaining the route in general of the route, followed by
                            details. Keep the description light and engaging and try to think about your audience’s
                            perspective and what could affect their decision in trying this route.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className={"col-xl-9"}
                      maxLength={3000}
                      placeholder={this.language.description.description_fo}
                      onChange={(e) => {
                        this.newHiking.textFo.description = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textFo: {
                              ...this.state.beenEdit.textFo,
                              description: true,
                            },
                          },
                        });
                      }}
                      value={this.newHiking.textFo.description}
                      errorCheckSequence={[Verify.notEmpty, Verify.least70Char]}
                      hideError={!this.state.beenEdit.textFo.description}
                      disabled={!showFo}
                    />
                  </div>
                  <div className="summary row">
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.description.summary_fo + " "}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see this short summary when selecting the hike in the map overview.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className="col-xl-9"
                      maxLength={300}
                      placeholder={this.language.description.summary_fo}
                      onChange={(e) => {
                        this.newHiking.textFo.summary = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textFo: {
                              ...this.state.beenEdit.textFo,
                              summary: true,
                            },
                          },
                        });
                      }}
                      value={this.newHiking.textFo.summary}
                      errorCheckSequence={showFo ? [Verify.notEmpty, Verify.least10Char] : []}
                      hideError={!this.state.beenEdit.textFo.summary}
                      disabled={!showFo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;

      case 1:
        content = (
          <div>
            <div className="new-content two">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateHiking}
                previewListOptions={this.navPreviewList}
              />
              <h1>{this.language.main_images.header}</h1>
              <div className="with-tooltip-2">
                {this.language.main_images.sub_text}
                <GlobalToolTipController
                  solid
                  title={""}
                  className={"tootlip-icon"}
                  priority={"bottom,right,top,left"}
                  toolTipElements={
                    <div className={"tooltip-card"}>
                      The first image will appear in the overview. Any additional images will appear on the hiking page.
                      You can drag them in the order you prefer.
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </GlobalToolTipController>
              </div>
              <div className={`upload-media-wrapper`}>
                <UploadMedia
                  default_elements={this.newHiking.images.mainImages.map((element) => {
                    return {
                      ...element,
                      // file: null,
                      url: element?.medium?.actual
                        ? element?.medium?.actual
                        : element?.elm
                          ? element.elm
                          : `${env.protocol}${env.env}/uploads/${element?.file_name}.${element?.file_extension}`,
                      text: {
                        en: element.enText,
                        fo: element.foText,
                      },
                    };
                  })}
                  auth={{
                    status: true,
                    call: (addMediaElements) => {
                      /* 
                      I don't have time to completely rewrite the state management
                      and the way we handle the media overlay, so we have to rely on these
                      callbacks for now :/
                  */
                      this.props.setSiteRef(this.whatsonSiteId);
                      this.props.openOverlay("mediaChoose", {
                        siteId: this.whatsonSiteId,
                        filter: { mediaType: ["images"] },
                        callbackArg: (new_media) => {
                          addMediaElements(
                            [...new_media].map((element) => {
                              return {
                                ...element,
                                file: null,
                                url: element?.medium?.actual ? element?.medium?.actual : element.elm,
                                text: {
                                  en: "",
                                  fo: "",
                                },
                              };
                            })
                          );
                        },
                      });
                      this.mediaIs = "mainImage";
                      /**
                       *
                       */
                    },
                  }}
                  onElementsChange={(elements) => {
                    /**
                     * This is not ideal either, but optimizing and cleaning this would require a bigger rewrite
                     */

                    // this.unuploadedMedia.mainImages = elements
                    //   .filter((e) => !e.id)
                    //   .map((e) => e.file);

                    this.newHiking.images.mainImages = elements.map((element, index) => {
                      const { file, rest, url, text, id } = element;
                      return {
                        ...rest,
                        is_feature: index === 0,
                        element: file,
                        key: index + 1,
                        name: file?.name,
                        elm: url,
                        id: id,
                        ...Object.fromEntries(
                          Object.entries(text).map((entry) => {
                            let [lang, text] = entry;
                            return [`${lang}Text`, text];
                          })
                        ),
                      };
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );
        break;

      case 2:
        content = (
          <div>
            <div className="new-content three">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateHiking}
                previewListOptions={this.navPreviewList}
              />
              <h1>{this.language.practical_info.header}</h1>
              <p>{this.language.practical_info.sub_text}</p>

              <div className="first-practical row">
                <div className="difficulty-wrap col-lg-6">
                  <p>{this.language.practical_info.difficulty}</p>
                  <VfiCustomSelect
                    className="select"
                    options={hikingDifficulty.english}
                    value={this.newHiking.difficulty}
                    onChange={(e) => {
                      this.newHiking.difficulty = e;
                      this.setState({});
                    }}
                  />
                </div>

                <div className="duration-wrap col-lg-6">
                  <p>{this.language.practical_info.duration}</p>
                  <div className="flex-distribution">
                    <VfiCustomSelect
                      className="hours"
                      options={this.hours}
                      value={this.state.hours}
                      onChange={(e) => {
                        this.setState({ hours: e });
                        this.newHiking.duration = e.value * 60 + (this.state.minutes ? this.state.minutes.value : 0);
                      }}
                      placeholder={this.language.practical_info.hours_placeholder}
                    />
                    <VfiCustomSelect
                      className="minutes"
                      options={this.minutes}
                      value={this.state.minutes}
                      onChange={(e) => {
                        this.setState({ minutes: e });
                        this.newHiking.duration = e.value + (this.state.hours ? this.state.hours.value : 0) * 60;
                      }}
                      placeholder={this.language.practical_info.minutes_placeholder}
                    />
                  </div>
                </div>

                <div className="distance-wrap col-lg-6">
                  <p>{this.language.practical_info.distance}</p>
                  <div className="flex-distribution">
                    <VfiInputText
                      defaultValue={this.newHiking.distance}
                      onChange={(e) => {
                        this.newHiking.distance = e.target.value;
                        this.setState({
                          beenEdit: { ...this.state.beenEdit, distance: true },
                        });
                      }}
                      placeholder="Meters"
                      errorCheckSequence={[Verify.notEmpty]}
                      inputType="int"
                      hideError={!this.state.beenEdit.distance}
                    />
                  </div>
                </div>

                <div className="elevation-wrap col-lg-6">
                  <p>{this.language.practical_info.elevation}</p>
                  <div className="flex-distribution">
                    <VfiInputText
                      defaultValue={this.newHiking.elevation}
                      onChange={(e) => {
                        this.newHiking.elevation = e.target.value;
                        this.setState({
                          beenEdit: { ...this.state.beenEdit, elevation: true },
                        });
                      }}
                      placeholder="Meters"
                      errorCheckSequence={[Verify.notEmpty]}
                      inputType="int"
                      hideError={!this.state.beenEdit.elevation}
                    />
                  </div>
                </div>

                <div className="max-height-wrap col-lg-6">
                  <p>{this.language.practical_info.max_height}</p>
                  <div className="flex-distribution">
                    <VfiInputText
                      defaultValue={this.newHiking.maxHeight}
                      onChange={(e) => {
                        this.newHiking.maxHeight = e.target.value;
                        this.setState({
                          beenEdit: { ...this.state.beenEdit, maxHeight: true },
                        });
                      }}
                      placeholder="Meters"
                      errorCheckSequence={[Verify.notEmpty]}
                      inputType="int"
                      hideError={!this.state.beenEdit.maxHeight}
                    />
                  </div>
                </div>

                <div className="extra-filters-wrap col-lg-6">
                  <p>{this.language.practical_info.extra_filters}</p>
                  <div className="flex-distribution many-lines">
                    <label className="extra-filter">
                      <VfiCheckbox
                        defaultChecked={this.newHiking.extraFilters.familyFriendly}
                        onChange={(e) => (this.newHiking.extraFilters.familyFriendly = e.target.checked)}
                      />{" "}
                      <div className="filter-text">Family friendly</div>
                    </label>

                    <label className="extra-filter">
                      <VfiCheckbox
                        defaultChecked={this.newHiking.extraFilters.villagepaths}
                        onChange={(e) => (this.newHiking.extraFilters.villagepaths = e.target.checked)}
                      />{" "}
                      <div className="filter-text">Villagepaths</div>
                    </label>

                    <label className="extra-filter">
                      <VfiCheckbox
                        defaultChecked={this.newHiking.extraFilters.summits}
                        onChange={(e) => (this.newHiking.extraFilters.summits = e.target.checked)}
                      />{" "}
                      <div className="filter-text">Summits</div>
                    </label>

                    <label className="extra-filter">
                      <VfiCheckbox
                        defaultChecked={this.newHiking.extraFilters.viewPoints}
                        onChange={(e) => (this.newHiking.extraFilters.viewPoints = e.target.checked)}
                      />{" "}
                      <div className="filter-text">View points</div>
                    </label>

                    <label className="extra-filter">
                      <VfiCheckbox
                        defaultChecked={this.newHiking.extraFilters.roundTrip}
                        onChange={(e) => (this.newHiking.extraFilters.roundTrip = e.target.checked)}
                      />{" "}
                      <div className="filter-text">Round trip</div>
                    </label>

                    <label className="extra-filter">
                      <VfiCheckbox
                        defaultChecked={this.newHiking.extraFilters.connectingRoutes}
                        onChange={(e) => (this.newHiking.extraFilters.connectingRoutes = e.target.checked)}
                      />{" "}
                      <div className="filter-text">Connecting routes</div>
                    </label>
                  </div>
                </div>
              </div>

              <hr />
              <div className="row">
                <div className={"english col-lg-6" + (showEn ? " active" : "")}>
                  <img className="flag" src={enFlag} alt="en-flag" />
                  <div className="difficulty-wrap">
                    <p className={!showEn ? "gray" : ""}>{this.language.practical_info.difficulty_description_en}</p>
                    <VfiTextarea
                      key="difficulty-d-en"
                      defaultValue={this.newHiking.textEn.difficultyDescription}
                      onChange={(e) => {
                        this.newHiking.textEn.difficultyDescription = e;
                      }}
                      disabled={!showEn}
                      // maxLength={150}
                    />
                  </div>

                  <div className="children-d-wrap">
                    <p className={!showEn ? "gray" : ""}>
                      {this.language.practical_info.children_friendly_description_en}
                    </p>
                    <VfiTextarea
                      key="children-d-en"
                      defaultValue={this.newHiking.textEn.childrenFriendly}
                      onChange={(e) => {
                        this.newHiking.textEn.childrenFriendly = e;
                      }}
                      disabled={!showEn}
                      // maxLength={150}
                    />
                  </div>

                  <div className="route-surface-wrap">
                    <p className={!showEn ? "gray" : ""}>{this.language.practical_info.surface_description_en}</p>
                    <VfiTextarea
                      key="route-surface-d-en"
                      defaultValue={this.newHiking.textEn.routeSurface}
                      onChange={(e) => {
                        this.newHiking.textEn.routeSurface = e;
                      }}
                      disabled={!showEn}
                      // maxLength={150}
                    />
                  </div>

                  {/* <div className="bus-route-wrap">
                    <p className={!showEn ? "gray" : ""}>{this.language.practical_info.bus_route_description_en}</p>
                    <VfiTextarea
                      key="bus-route-d-en"
                      value={this.newHiking.textEn.busRoute}
                      onChange={(e) => {
                        this.newHiking.textEn.busRoute = e;
                      }}
                      disabled={!showEn}
                    />
                  </div> */}
                  {/* Bus route was hidden 19 nov 2020 */}

                  <div className="special-comments-wrap ">
                    <p className={!showEn ? "gray" : ""}>{this.language.practical_info.special_comments_en}</p>
                    <VfiTextarea
                      key="special-comments-d-en"
                      defaultValue={this.newHiking.textEn.specialComments}
                      onChange={(e) => {
                        this.newHiking.textEn.specialComments = e;
                      }}
                      disabled={!showEn}
                      // maxLength={150}
                    />
                  </div>
                </div>

                <div className={"faroese col-lg-6" + (showFo ? " active" : "")}>
                  <img className="flag" src={foFlag} alt="fo-flag" />
                  <div className="difficulty-wrap">
                    <p className={!showFo ? "gray" : ""}>{this.language.practical_info.difficulty_description_fo}</p>
                    <VfiTextarea
                      key="difficulty-d-en"
                      defaultValue={this.newHiking.textFo.difficultyDescription}
                      onChange={(e) => {
                        this.newHiking.textFo.difficultyDescription = e;
                      }}
                      disabled={!showFo}
                      // maxLength={150}
                    />
                  </div>

                  <div className="children-d-wrap ">
                    <p className={!showFo ? "gray" : ""}>
                      {this.language.practical_info.children_friendly_description_fo}
                    </p>
                    <VfiTextarea
                      key="children-d-en"
                      defaultValue={this.newHiking.textFo.childrenFriendly}
                      onChange={(e) => {
                        this.newHiking.textFo.childrenFriendly = e;
                      }}
                      disabled={!showFo}
                      // maxLength={150}
                    />
                  </div>

                  <div className="route-surface-wrap ">
                    <p className={!showFo ? "gray" : ""}>{this.language.practical_info.surface_description_fo}</p>
                    <VfiTextarea
                      key="route-surface-d-en"
                      defaultValue={this.newHiking.textFo.routeSurface}
                      onChange={(e) => {
                        this.newHiking.textFo.routeSurface = e;
                      }}
                      disabled={!showFo}
                      // maxLength={150}
                    />
                  </div>

                  {/* <div className="bus-route-wrap ">
                    <p className={!showFo ? "gray" : ""}>{this.language.practical_info.bus_route_description_fo}</p>
                    <VfiTextarea
                      key="bus-route-d-en"
                      value={this.newHiking.textFo.busRoute}
                      onChange={(e) => {
                        this.newHiking.textFo.busRoute = e;
                      }}
                      disabled={!showFo}
                    />
                  </div> */}
                  {/* Bus route was hidden 19 nov 2020 VFI demanded it. */}

                  <div className="special-comments-wrap ">
                    <p className={!showFo ? "gray" : ""}>{this.language.practical_info.special_comments_fo}</p>
                    <VfiTextarea
                      key="special-comments-d-en"
                      defaultValue={this.newHiking.textFo.specialComments}
                      onChange={(e) => {
                        this.newHiking.textFo.specialComments = e;
                      }}
                      disabled={!showFo}
                      // maxLength={150}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;

      case 3:
        content = (
          <div>
            <div className="new-content five ">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateHiking}
                previewListOptions={this.navPreviewList}
              />
              <h1>{this.language.map.header}</h1>
              <p>{this.language.map.sub_text}</p>

              <div className="address row">
                <label className="col-lg-12">
                  <p>{this.language.location.address}</p>
                  <SearchBox
                    change={this.onAddressChange}
                    onChange={(e) => {
                      this.newHiking.address.searchBox = e;
                      this.setState({});
                    }}
                    value={this.newHiking.address.searchBox}
                  />
                </label>
                <div className="pin-map col-lg-12">
                  <p>{this.language.location.map_pin}</p>
                  <SimpleMap
                    marker={this.newHiking.address.mapCenter}
                    markerHidden={!this.newHiking.address.showMarker}
                    onClick={this.onMarkerChange}
                  />
                </div>
                <div className="region col-lg-12">
                  <p>{this.language.location.region}</p>
                  <VfiCustomSelect
                    onChange={(e) => {
                      this.newHiking.region = e;
                      this.setState({});
                    }}
                    value={this.newHiking.region}
                    options={this.regions}
                  />
                </div>
                {/* <div>Lat: {this.state.mapMarker.lat}</div>
              <div>Lng: {this.state.mapMarker.lng}</div> */}
              </div>

              <div className="row">
                <div className={"map-description-en language-box english col-lg-6" + (showEn ? " active" : "")}>
                  <img className="flag" src={enFlag} alt="en-flag" />
                  <div className={!showEn ? "text gray" : "text"}>{this.language.map.description_en}</div>
                  <VfiTextarea
                    key="en-map-d"
                    className="map-d"
                    defaultValue={this.newHiking.textEn.mapDescription}
                    onChange={(e) => {
                      this.newHiking.textEn.mapDescription = e;
                    }}
                    disabled={!showEn}
                  />
                </div>
                <div className={"map-description-fo language-box faroese col-lg-6" + (showFo ? " active" : "")}>
                  <img className="flag" src={foFlag} alt="fo-flag" />
                  <div className={!showFo ? "text gray" : "text"}>{this.language.map.description_fo}</div>
                  <VfiTextarea
                    key="fo-map-d"
                    className="map-d"
                    defaultValue={this.newHiking.textFo.mapDescription}
                    onChange={(e) => {
                      this.newHiking.textFo.mapDescription = e;
                    }}
                    disabled={!showFo}
                  />
                </div>
              </div>

              <div className="edit-contents">
                <div className="row">
                  <div className="relive-wrap col-lg-12">
                    <p>{this.language.map.relive_url}</p>
                    {/* <VfiInputText
                      defaultValue={this.newHiking.reliveUrl}
                      onChange={(e) => {
                        this.newHiking.reliveUrl = e.target.value;
                      }}
                    /> */}
                    {/* <div className={"upload-box"}>
                      <div className="image-container">
                        <FontAwesomeIcon className="pen" icon={faPen} />
                        <FontAwesomeIcon
                          className="before-image"
                          icon={faImage2}
                        />
                      </div>
                    </div> */}
                    <OneMedia
                      siteRef={this.whatsonSiteId}
                      setSiteRef={this.props.setSiteRef}
                      openOverlay={this.props.openOverlay}
                      whatMedia={["videos"]}
                      media={this.newHiking.reliveMedium}
                      onClick={() => {
                        this.mediaIs = "relive";
                      }}
                      faIcon={faFilm}
                      draggable={false}
                    />
                  </div>
                  <div className="gpx-wrap col-lg-12">
                    <p className="gpx-topper">{this.language.map.gpx_header}</p>
                    <div
                      className="image-upload-block"
                      onClick={() => {
                        this.props.setSiteRef(this.whatsonSiteId);
                        this.props.openOverlay("mediaChoose", {
                          siteId: this.whatsonSiteId,
                          filter: { mediaType: ["gpx"] },
                        });
                        this.mediaIs = "gpxFile";
                      }}
                    >
                      <div className={"upload-box"}>
                        <div className="image-container">
                          <FontAwesomeIcon className="pen" icon={faPen} />
                          <FontAwesomeIcon className="before-image" icon={faFile} />
                          <div className="gpx-label">GPX</div>
                        </div>
                      </div>
                    </div>

                    <ul>
                      {this.newHiking.gpxInfo.map((info, i) => {
                        return info.deleted === false ? (
                          <li key={info.key}>
                            <div className="gpx-content">
                              <p>{this.language.map.gpx_file_name}</p>
                              <VfiInputText
                                onChange={(e) => {
                                  this.newHiking.gpxInfo[i].name = e.target.value;
                                }}
                                defaultValue={info.name}
                              />
                              <span
                                className="remove-button"
                                onClick={(e) => {
                                  this.deleteGpx(i);
                                }}
                                value={i}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </span>
                            </div>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;

      case 4:
        content = (
          <div>
            <div className="new-content six">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateHiking}
                previewListOptions={this.navPreviewList}
              />
              <h1>{this.language.getting_there.header}</h1>
              <p>{this.language.getting_there.sub_text}</p>
              <div className="row">
                <VfiCustomSelect
                  className="select-position col-lg-3"
                  options={positionOptions}
                  value={this.state.gettingTherePosSelect}
                  onChange={(e) => this.setState({ gettingTherePosSelect: e })}
                />
              </div>
              <div className="row">
                <div className={"language-box english getting-there-en col-lg-6" + (showEn ? " active" : "")}>
                  <img className="flag" src={enFlag} alt="en-flag" />
                  <div className={!showEn ? "text gray" : "text"}>{this.language.getting_there.description_en}</div>
                  <p>{this.language.getting_there.headline}</p>
                  <VfiInputText
                    className="headline-gettingthere"
                    key="en-get-h"
                    value={this.newHiking.textEn[pos].gettingThereHeadline}
                    onChange={(e) => {
                      this.newHiking.textEn[pos].gettingThereHeadline = e.target.value;
                      this.setState({});
                    }}
                    disabled={!showEn}
                  />
                  <p>{this.language.getting_there.description}</p>
                  <VfiCKeditor
                    className="description-gettingthere"
                    key="en-get-d"
                    value={this.newHiking.textEn[pos].gettingThere}
                    onChange={(e) => {
                      this.newHiking.textEn[pos].gettingThere = e;
                      this.setState({});
                    }}
                    disabled={!showEn}
                    toolbar={["link"]}
                  />
                </div>
                <div className={"language-box faroese getting-there-fo col-lg-6" + (showFo ? " active" : "")}>
                  <img className="flag" src={foFlag} alt="fo-flag" />
                  <div className={!showFo ? "text gray" : "text"}>{this.language.getting_there.description_fo}</div>
                  <p>{this.language.getting_there.headline}</p>
                  <VfiInputText
                    className="headline-gettingthere"
                    key="en-get-h"
                    value={this.newHiking.textFo[pos].gettingThereHeadline}
                    onChange={(e) => {
                      this.newHiking.textFo[pos].gettingThereHeadline = e.target.value;
                      this.setState({});
                    }}
                    disabled={!showFo}
                  />
                  <p>{this.language.getting_there.description}</p>
                  <VfiCKeditor
                    className="description-gettingthere"
                    key="fo-get-d"
                    value={this.newHiking.textFo[pos].gettingThere}
                    onChange={(e) => {
                      this.newHiking.textFo[pos].gettingThere = e;
                      this.setState({});
                    }}
                    disabled={!showFo}
                    toolbar={["link"]}
                  />
                </div>
              </div>

              <p className="images-description-top-text">{this.language.getting_there.images_description}</p>
              <div className="uploaded-images">
                {this.newHiking.images[pos].gettingThereImages.map((element, i) => {
                  return (
                    <div
                      className={
                        "one-image" +
                        (this.state.indexImageDrag === i ? " dragging" : "") +
                        (this.state.indexImageDragOn === i ? " dragging-on" : "")
                      }
                      key={element.key}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDragEnter={(e) => {
                        setTimeout(() => {
                          this.setState({ indexImageDragOn: i });
                        }, 0);
                      }}
                      onDragLeave={(e) => {
                        this.setState({ indexImageDragOn: undefined });
                      }}
                      onDrop={(e) => {
                        let images = JSON.parse(JSON.stringify(this.newHiking.images[pos].gettingThereImages));
                        let image = images[this.state.indexImageDrag];
                        images.splice(this.state.indexImageDrag, 1);
                        images.splice(i, 0, image);
                        this.newHiking.images[pos].gettingThereImages = images;
                      }}
                      draggable="false"
                    >
                      <div className="media-wrap">
                        <img
                          src={
                            env.protocol +
                            env.env +
                            "/uploads/" +
                            element.file_name +
                            "_medium." +
                            element.file_extension
                          }
                          alt="upload"
                          draggable="false"
                        />
                      </div>
                      <div
                        className="drag-element"
                        draggable="true"
                        onDragStart={(e) => {
                          this.defaultDragStart(e);
                          this.setState({ indexImageDrag: i });

                          var img = document.createElement("img");
                          img.src =
                            env.protocol +
                            env.env +
                            "/uploads/" +
                            element.file_name +
                            "_thumbnail." +
                            element.file_extension;
                          e.dataTransfer.setDragImage(img, 0, 0);
                        }}
                        onDragEnd={(e) => {
                          this.setState({
                            indexImageDrag: undefined,
                            indexImageDragOn: undefined,
                          });
                        }}
                      />
                      <div
                        className="remove-image"
                        onClick={() => {
                          this.newHiking.images[pos].gettingThereImages.splice(i, 1);
                          this.setState({});
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                      {showEn ? (
                        <div className={element.focused ? "text-wrap text-en" : "text-wrap text-en hidden"}>
                          <img className="flag" src={enFlag} alt="en-flag" />
                          <VfiTextarea
                            onChange={(e) => {
                              this.newHiking.images[pos].gettingThereImages[i].enText = e;
                              this.setState({});
                            }}
                            value={this.newHiking.images[pos].gettingThereImages[i].enText}
                            onFocus={() => {
                              this.newHiking.images[pos].gettingThereImages[i].focused = true;
                              this.setState({});
                            }}
                            onBlur={() => {
                              this.newHiking.images[pos].gettingThereImages[i].focused = false;
                              this.setState({});
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {showFo ? (
                        <div className={element.focused ? "text-wrap text-fo" : "text-wrap text-fo hidden"}>
                          <img className="flag" src={foFlag} alt="fo-flag" />
                          <VfiTextarea
                            onChange={(e) => {
                              this.newHiking.images[pos].gettingThereImages[i].foText = e;
                              this.setState({});
                            }}
                            value={this.newHiking.images[pos].gettingThereImages[i].foText}
                            onFocus={() => {
                              this.newHiking.images[pos].gettingThereImages[i].focused = true;
                              this.setState({});
                            }}
                            onBlur={() => {
                              this.newHiking.images[pos].gettingThereImages[i].focused = false;
                              this.setState({});
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                <div
                  className="image-upload-block"
                  onClick={() => {
                    this.props.setSiteRef(this.whatsonSiteId);
                    this.props.openOverlay("mediaChoose", {
                      siteId: this.whatsonSiteId,
                      filter: { mediaType: ["images"] },
                    });
                    this.mediaIs = pos === "pos1" ? "gettingThere1" : "gettingThere2";
                  }}
                >
                  <div className={"upload-box"}>
                    <div className="image-container">
                      <FontAwesomeIcon className="pen" icon={faPlus} />
                      <FontAwesomeIcon className="before-image" icon={faImage2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case 5:
        content = (
          <div>
            <div className="new-content seven">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateHiking}
                previewListOptions={this.navPreviewList}
              />
              <h1>{this.language.starting_point.header}</h1>
              <p>{this.language.starting_point.sub_text}</p>
              <div className="row">
                <VfiCustomSelect
                  className="select-position col-lg-3"
                  options={positionOptions}
                  value={this.state.gettingTherePosSelect}
                  onChange={(e) => this.setState({ gettingTherePosSelect: e })}
                />
              </div>
              <div className="row">
                <div className={"language-box english starting-point-en col-lg-6" + (showEn ? " active" : "")}>
                  <img className="flag" src={enFlag} alt="en-flag" />
                  <div className={"text"}>{this.language.starting_point.description_en}</div>
                  <p>Headline</p>
                  <VfiInputText
                    className="headline-startingpoint"
                    key={"en-start-h"}
                    value={this.newHiking.textEn[pos].startingPointHeadline}
                    onChange={(e) => {
                      this.newHiking.textEn[pos].startingPointHeadline = e.target.value;
                      this.setState({});
                    }}
                    disabled={!showEn}
                  />
                  <p>Description</p>
                  <VfiTextarea
                    className="description-startingpoint"
                    key={"en-start-d"}
                    value={this.newHiking.textEn[pos].startingPoint}
                    onChange={(e) => {
                      this.newHiking.textEn[pos].startingPoint = e;
                      this.setState({});
                    }}
                    disabled={!showEn}
                  />
                </div>
                <div className={"language-box faroese starting-point-fo col-lg-6" + (showFo ? " active" : "")}>
                  <img className="flag" src={foFlag} alt="fo-flag" />
                  <div className={!showFo ? "text" : "text active"}>{this.language.starting_point.description_fo}</div>
                  <p>Headline</p>
                  <VfiInputText
                    className="headline-startingpoint"
                    key={"fo-start-h"}
                    value={this.newHiking.textFo[pos].startingPointHeadline}
                    onChange={(e) => {
                      this.newHiking.textFo[pos].startingPointHeadline = e.target.value;
                      this.setState({});
                    }}
                    disabled={!showFo}
                  />
                  <p>Description</p>
                  <VfiTextarea
                    className="description-startingpoint"
                    key={"fo-start-d"}
                    value={this.newHiking.textFo[pos].startingPoint}
                    onChange={(e) => {
                      this.newHiking.textFo[pos].startingPoint = e;
                      this.setState({});
                    }}
                    disabled={!showFo}
                  />
                </div>
              </div>

              <div className="street-map">
                <div className="street-top row">
                  <p className="col-lg-6">{this.language.location.street_view}</p>
                  <div className="toogle-street col-lg-6">
                    <label>
                      <p>{this.language.location.show_street_view}</p>
                      <Switch
                        className="the-switch"
                        checked={this.state.showStreetView}
                        onChange={() => {
                          this.setState({
                            showStreetView: !this.state.showStreetView,
                          });
                        }}
                        height={16}
                        width={32}
                      />
                    </label>
                  </div>
                </div>
                <SimpleMap
                  marker={this.newHiking.addressStreet[pos].mapCenter}
                  markerHidden={!this.newHiking.addressStreet[pos].showMarker}
                  onClick={(e) => this.onMarkerStreetChange(e, pos)}
                  showStreetView={this.state.showStreetView}
                />
              </div>

              <p className="uploaded-images-p">{this.language.starting_point.images_description}</p>
              <div className="uploaded-images">
                {this.newHiking.images[pos].startingPointImages.map((element, i) => {
                  return (
                    <div
                      className={
                        "one-image" +
                        (this.state.indexImageDrag === i ? " dragging" : "") +
                        (this.state.indexImageDragOn === i ? " dragging-on" : "")
                      }
                      key={element.key}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDragEnter={(e) => {
                        setTimeout(() => {
                          this.setState({ indexImageDragOn: i });
                        }, 0);
                      }}
                      onDragLeave={(e) => {
                        this.setState({ indexImageDragOn: undefined });
                      }}
                      onDrop={(e) => {
                        let images = JSON.parse(JSON.stringify(this.newHiking.images[pos].startingPointImages));
                        let image = images[this.state.indexImageDrag];
                        images.splice(this.state.indexImageDrag, 1);
                        images.splice(i, 0, image);
                        this.newHiking.images[pos].startingPointImages = images;
                        this.setState({});
                      }}
                    >
                      <div className="media-wrap">
                        <img
                          src={
                            env.protocol +
                            env.env +
                            "/uploads/" +
                            element.file_name +
                            "_medium." +
                            element.file_extension
                          }
                          alt="upload"
                          draggable="false"
                        />
                      </div>
                      <div
                        className="drag-element"
                        draggable="true"
                        onDragStart={(e) => {
                          this.defaultDragStart(e);
                          this.setState({ indexImageDrag: i });

                          var img = document.createElement("img");
                          img.src =
                            env.protocol +
                            env.env +
                            "/uploads/" +
                            element.file_name +
                            "_thumbnail." +
                            element.file_extension;
                          e.dataTransfer.setDragImage(img, 0, 0);
                        }}
                        onDragEnd={(e) => {
                          this.setState({
                            indexImageDrag: undefined,
                            indexImageDragOn: undefined,
                          });
                        }}
                      />
                      <div
                        className="remove-image"
                        onClick={() => {
                          this.newHiking.images[pos].startingPointImages.splice(i, 1);
                          this.setState({});
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                      {showEn ? (
                        <div className={element.focused ? "text-wrap text-en" : "text-wrap text-en hidden"}>
                          {/* <img className="flag" src={enFlag} alt="en-flag" /> */}
                          <VfiTextarea
                            onChange={(e) => {
                              this.newHiking.images[pos].startingPointImages[i].enText = e;
                              this.setState({});
                            }}
                            value={this.newHiking.images[pos].startingPointImages[i].enText}
                            onFocus={() => {
                              this.newHiking.images[pos].startingPointImages[i].focused = true;
                              this.setState({});
                            }}
                            onBlur={() => {
                              this.newHiking.images[pos].startingPointImages[i].focused = false;
                              this.setState({});
                            }}
                            placeholder="English description"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {showFo ? (
                        <div className={element.focused ? "text-wrap text-fo" : "text-wrap text-fo hidden"}>
                          {/* <img className="flag" src={foFlag} alt="fo-flag" /> */}
                          <VfiTextarea
                            onChange={(e) => {
                              this.newHiking.images[pos].startingPointImages[i].foText = e;
                              this.setState({});
                            }}
                            value={this.newHiking.images[pos].startingPointImages[i].foText}
                            onFocus={() => {
                              this.newHiking.images[pos].startingPointImages[i].focused = true;
                              this.setState({});
                            }}
                            onBlur={() => {
                              this.newHiking.images[pos].startingPointImages[i].focused = false;
                              this.setState({});
                            }}
                            placeholder="Faroese description"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                <div
                  className="image-upload-block"
                  onClick={() => {
                    this.props.setSiteRef(this.whatsonSiteId);
                    this.props.openOverlay("mediaChoose", {
                      siteId: this.whatsonSiteId,
                      filter: { mediaType: ["images"] },
                    });
                    this.mediaIs = pos === "pos1" ? "startingPoint1" : "startingPoint2";
                  }}
                >
                  <div className={"upload-box"}>
                    <div className="image-container">
                      <FontAwesomeIcon className="pen" icon={faPlus} />
                      <FontAwesomeIcon className="before-image" icon={faImage2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case 6:
        content = (
          <div>
            <div className="new-content eight">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateHiking}
                customForwardText={
                  this.props.updateData === undefined
                    ? this.language.register.navigation_submit
                    : this.language.register.navigation_update
                }
                previewListOptions={this.navPreviewList}
                customForwardFunc={this.addOrUpdateHiking}
              />
              <h1>{this.language.instagram.header}</h1>
              <label className="instagram-enabled">
                <VfiCheckbox
                  className="instagram-active"
                  checked={this.newHiking.instagram.active}
                  onChange={() => {
                    this.newHiking.instagram.active = !this.newHiking.instagram.active;
                    this.setState({});
                  }}
                />{" "}
                {this.language.instagram.instagram_enabled}
              </label>
              {this.newHiking.instagram.active && (
                <>
                  <p>{this.language.instagram.sub_text}</p>
                  <InstagramInWhatson
                    data={this.newHiking.instagram}
                    onChange={(e) => {
                      this.newHiking.instagram = e;
                      this.setState({});
                    }}
                    onMediaClick={(i) => {
                      this.mediaIs = "instagram";
                      this.instaIndex = i;
                    }}
                    setSiteRef={this.props.setSiteRef}
                    openOverlay={this.props.openOverlay}
                    defaultDragStart={this.defaultDragStart}
                    activeLangs={{ en: this.newHiking.textEn.showIt, fo: this.newHiking.textFo.showIt }}
                  />
                </>
              )}
            </div>
          </div>
        );
        break;
      case 7:
        content = (
          <div>
            <div className="new-content nine">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={true}
                // customForwardFunc={this.addOrUpdateHiking}
                saveDraft={this.addOrUpdateHiking}
                saveDraftDisabled={true}
                previewListOptions={this.navPreviewList}
                customForwardText={"To list"}
                customForwardFunc={this.props.goToList}
              />
              {/* {this.props.updateData === undefined ? (
                <div>
                  <h1>{this.language.register.header_register}</h1>
                  <div className="new-edit">
                    <div className="final-text">
                      <FontAwesomeIcon icon={faChild} className="wooh" />
                      <div className="wrap-final-text">
                        <p>
                          {this.user.firstname.toUpperCase() +
                            " " +
                            this.user.lastname.toUpperCase()}
                          ,
                        </p>
                        <p>{this.language.register.final_text_register}</p>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              ) : (
                <div>
                  <h1>{this.language.register.header_update}</h1>
                  <div className="new-edit">
                    <div className="final-text">
                      <FontAwesomeIcon icon={faChild} className="wooh" />
                      <div className="wrap-final-text">
                        <p>
                          {this.user.firstname.toUpperCase() +
                            " " +
                            this.user.lastname.toUpperCase()}
                          ,
                        </p>
                        <p>{this.language.register.final_text_update}</p>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              )} */}

              <h3>
                <b>Good old chap!</b>
              </h3>
              <p>Your hike is registered.</p>
              <div className="goose-image">
                <img src={Goose.thanksx2} />
              </div>
            </div>
          </div>
        );
        break;
      default:
        content = "Should not get here...";
    }

    this.descriptionPassed = false;

    let passingVerify = true;
    if (
      !this.state.beenEdit.textEn.headline &&
      !this.state.beenEdit.textEn.shortDescription &&
      !this.state.beenEdit.textEn.description &&
      !this.state.beenEdit.textFo.headline &&
      !this.state.beenEdit.textFo.shortDescription &&
      !this.state.beenEdit.textFo.description
    ) {
    } else {
      if (showEn) {
        if (
          Verify.notEmpty(this.newHiking.textEn.headline) === "" &&
          Verify.least3Char(this.newHiking.textEn.headline) === "" &&
          Verify.notEmpty(removeTags(this.newHiking.textEn.shortDescription)) === "" &&
          Verify.least10Char(removeTags(this.newHiking.textEn.shortDescription)) === "" &&
          Verify.notEmpty(removeTags(this.newHiking.textEn.description)) === "" &&
          Verify.least70Char(removeTags(this.newHiking.textEn.description)) === "" &&
          Verify.notEmpty(removeTags(this.newHiking.textEn.summary)) === "" &&
          Verify.least10Char(removeTags(this.newHiking.textEn.summary)) === ""
        ) {
        } else {
          passingVerify = false;
        }
      }
      if (showFo)
        if (
          Verify.notEmpty(this.newHiking.textFo.headline) === "" &&
          Verify.least3Char(this.newHiking.textFo.headline) === "" &&
          Verify.notEmpty(removeTags(this.newHiking.textFo.shortDescription)) === "" &&
          Verify.least10Char(removeTags(this.newHiking.textFo.shortDescription)) === "" &&
          Verify.notEmpty(removeTags(this.newHiking.textFo.description)) === "" &&
          Verify.least70Char(removeTags(this.newHiking.textFo.description)) === "" &&
          Verify.notEmpty(removeTags(this.newHiking.textFo.summary)) === "" &&
          Verify.least10Char(removeTags(this.newHiking.textFo.summary)) === ""
        ) {
        } else {
          passingVerify = false;
        }

      if (!showEn && !showFo) {
        passingVerify = false;
      }
    }
    this.descriptionPassed = passingVerify;

    this.practicalInfoPassed = false;

    if (!this.state.beenEdit.distance && !this.state.beenEdit.maxHeight) {
      this.practicalInfoPassed = true;
    } else {
      if (Verify.notEmpty(this.newHiking.distance) === "" && Verify.notEmpty(this.newHiking.maxHeight) === "")
        this.practicalInfoPassed = true;
    }

    return (
      <div className="new-hiking">
        <div className="close-hiking" onClick={this.props.closeHiking}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="header-display-wrap">
          <div className="header-display" ref={this.headerRef}>
            {this.props.stepBubble(
              faInfo,
              this.language.description.bubble_text,
              0,
              this.descriptionPassed,
              false,
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faImage,
              this.language.main_images.bubble_text,
              1,
              true,
              false,
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faImage,
              this.language.practical_info.bubble_text,
              2,
              this.practicalInfoPassed,
              false,
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faMapMarkedAlt,
              this.language.map.bubble_text,
              3,
              true,
              false,
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faBus,
              this.language.getting_there.bubble_text,
              4,
              true,
              false,
              this.props.index === 7
            )}
            {this.props.stepBubble(
              "START",
              this.language.starting_point.bubble_text,
              5,
              true,
              false,
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faInstagram,
              this.language.instagram.bubble_text,
              6,
              true,
              false,
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faCheck,
              this.props.updateData === undefined
                ? this.language.register.bubble_text_register
                : this.language.register.bubble_text_update,
              7,
              true,
              false,
              true
            )}
          </div>
        </div>
        <div className="w-content">{content}</div>
      </div>
    );
  }
}

export default NewHiking;
