import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "src/firebase";
import { modulesConfig } from "src/utils";

export async function requestNotificationPermission() {
  // If notifications are disabled, return null
  if (!modulesConfig.notifications.enabled) return null;

  let token;

  if (messaging) {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      token = await getToken(messaging);
    } else if (permission === "denied") {
      // console.log("Notification permission was denied.");
      // Handle this scenario appropriately
    }
  }
  return token;
}

// --- ON WEBSITE NOTIFICATION | FOREGROUND ---
// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   // Customize notification here
// });
