import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faRedo } from "@fortawesome/pro-regular-svg-icons";
import "./WhatsonNavigation.scss";
import language_x from "./language.json";
import OptionsList from "../../../assets/OptionsList/OptionsList";
import { TSystemLanguage } from "src/types/types/TSystemLanguage";
import { TOptions } from "src/assets/OptionsList/types/types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface props {
  language: TSystemLanguage;
  className: string;
  disablePreview?: boolean;
  showPreview?: () => void;
  previewListOptions?: TOptions;
  saveDraftDisabled?: boolean;
  isFront?: boolean;
  saveDraft?: (isDraft: boolean) => void;
  undoAndRedoDisabled?: boolean;
  backwardDisabled?: boolean;
  hideNavigationButtons?: boolean;
  customBackwardFunc?: () => void;
  customForwardFunc?: () => void;
  returnToSubmit?: () => void;
  indexChange: (index: number) => void;
  customBackwardText?: React.ReactNode;
  customForwardText?: React.ReactNode;
  forwardDisabled?: boolean;
  frontStyle?: string;
}
function WhatsonNavigation(props: props) {
  const language = language_x[props.language].navigation;

  const [listIsOpen, setListIsOpen] = useState(false);

  return (
    <div className={`whatson-navigation ${props.className ?? ""}`}>
      {!props.disablePreview && props?.showPreview && (
        <div className="save-draft-container" onClick={() => props.showPreview?.()}>
          <span className="save-draft">{language.preview}</span>
        </div>
      )}
      {props.previewListOptions && !props.isFront ? (
        <button
          className={`preview-container ${props.saveDraftDisabled ? "hidden" : ""}`}
          onClick={(e) => {
            setListIsOpen(true);
          }}
        >
          <span className="preview">{language.preview}</span>
          <OptionsList
            isOpen={listIsOpen}
            close={() => {
              setListIsOpen(false);
            }}
            options={props.previewListOptions}
          />
        </button>
      ) : (
        ""
      )}
      {!props.isFront ? (
        <div
          className={`save-draft-container ${props.saveDraftDisabled ? "hidden" : ""}`}
          onClick={() => props.saveDraft?.(true)}
        >
          <span className="save-draft">{language.save_draft}</span>
        </div>
      ) : (
        ""
      )}
      <div className={props.undoAndRedoDisabled === true ? "undo hidden" : "undo"} onClick={() => {}}>
        <FontAwesomeIcon icon={faUndo as IconProp} />
      </div>
      <div className={props.undoAndRedoDisabled === true ? "redo hidden" : "redo"} onClick={() => {}}>
        <FontAwesomeIcon icon={faRedo as IconProp} />
      </div>
      <div className={`${"controls"} ${!props?.hideNavigationButtons ? "" : "hidden"}`}>
        {!props?.hideNavigationButtons && (
          <>
            <button
              className={props.backwardDisabled === true ? "back disabled" : "back"}
              onClick={() => {
                if (props.customBackwardFunc) props.customBackwardFunc();
                else props.indexChange(-1);
              }}
              disabled={props.backwardDisabled}
            >
              <span>{props.customBackwardText ? props.customBackwardText : language.back}</span>
            </button>
            <button
              className={props.forwardDisabled === true ? "forward disabled" : "forward" + ` ${props.frontStyle}`}
              onClick={() => {
                if (props.customForwardFunc) props.customForwardFunc();
                else if (props.returnToSubmit) props.returnToSubmit();
                else props.indexChange(1);
              }}
              disabled={props.forwardDisabled}
            >
              <span>{props.customForwardText ? props.customForwardText : language.forward}</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
}
export default WhatsonNavigation;
