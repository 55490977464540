import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrashAlt,
  faSpinner,
  faEye,
  faPlus,
  faPen,
  faTrash,
  faLink,
} from "@fortawesome/pro-light-svg-icons";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn, faPinterest } from "@fortawesome/free-brands-svg-icons";
import "./editFooter.scss";
import axios from "axios";
import FooterFrontEnd from "./FooterFrontEnd";
import env from "../../../../../environment.json";
import { capitalize } from "../../../../../assets/helperFunctions";
import OptionsList from "../../../../../assets/OptionsList/OptionsList";
import VfiCKeditor from "../../../../../assets/VfiCKeditor/VfiCKeditor";
import vitaLogo from "../../../../../assets/images/vita-logo.png";
import vfiLogo from "../../../../../assets/images/vfi-logo.png";
import VfiInputText from "../../../../../assets/VfiInputText/VfiInputText";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-brands-svg-icons";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fab" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function pushBModule(e, i) {
  if (e.type === "follow") {
    return {
      id: e.id,
      cheatSheat: e.cheatSheat,
      text: e.text,
      block: e.block,
      url: e.block.match("href='(.*)'><i") ? e.block.match("href='(.*)'><i")[1] : "", // Get url
      type: "follow",
    };
  } else {
    return {
      id: e.id,
      languages: e.languages,
      ref: "col" + i,
      text: "Column " + i,
      type: e.type,
    };
  }
}

export default function EditFooter(props) {
  const [boxWidth, setBoxWidth] = useState(window.innerWidth - 250); // Some size
  const [contentEditEnabled, setContentEditEnabled] = useState(false);
  const [contentBeforeEdit, setContentBeforeEdit] = useState([]);
  const [selectedCol, setSelectedCol] = useState("col1");
  const [contentData, setContentData] = useState({
    ckeditor_5: [],
    other_sites: [],
    follow: [],
  });
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [addLanguageChoices, setAddLanguageChoices] = useState([]);
  const [addIconChoices, setAddIconChoices] = useState([]);
  const [listIsOpen, setListIsOpen] = useState(false);
  const [iconListIsOpen, setIconListIsOpen] = useState(false);
  const [editIconListIsOpen, setEditIconListIsOpen] = useState(false);
  const [optionsListOptions, setOptionsListOptions] = useState([]);
  const [iconOptionsList, setIconOptionsList] = useState([]);
  const [optionsListPos, setOptionsListPos] = useState({});
  const [iconOptionsListPos, setIconOptionsListPos] = useState({});
  const [editIconOptionsListPos, setEditIconOptionsListPos] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [unMappedLang, setUnMappedLang] = useState({});
  const [editMode, setEditMode] = useState(true);
  const [indexColumDrag, setIndexColumDrag] = useState();
  const [indexColumDragOn, setIndexColumDragOn] = useState();
  const [penIsShown, setPenIsShown] = useState("");
  const [editIconOptionsList, setEditIconOptionsList] = useState([]);

  const mytoolbar = [
    "heading",
    "bold",
    "italic",
    "underline",
    "|",
    "link",
    "|",
    "fontFamily",
    "|",
    "fontcolor",
    "|",
    "undo",
    "redo",
  ];

  useEffect(() => {
    const updateData = (response) => {
      var b_mod = { ckeditor_5: [], other_sites: [], follow: [] };
      response.data.b_modules.forEach((e, i) => {
        b_mod[e.type].push(pushBModule(e, i + 1));
      });
      setContentData(b_mod);

      // If there are any columns
      if (response.data.b_modules.length > 0) {
        setSelectedCol("col1");
      }
      //Save all language info
      setUnMappedLang(response.data.languages);

      // Key short languages and select first language
      let lang = Object.keys(response.data.languages);
      setLanguages(lang);
      setSelectedLanguage(lang[0]); // Set selected language to first

      setIsLoading(false); // No more loading
    };
    axios
      .post(env.protocol + env.env + "/api/public/appearances/GetFooter.php", {
        siteId: props.siteSelected.siteId,
      })
      .then((response) => {
        // If no languages, i.e. new site, we initialize a footer with english language
        if (response.data.languages.length === 0) {
          axios
            .post(env.protocol + env.env + "/api/secured/appearances/AddFooter", {
              siteId: props.siteSelected.siteId,
            })
            .then((response) => {
              updateData(response);
            })
            .catch((error) => console.error(error));
        } else {
          updateData(response);
        }
      })
      .catch((error) => console.error(error));
  }, [props.siteSelected.siteId]);

  useEffect(() => {
    const handleResize = () => {
      setBoxWidth(window.innerWidth - 250);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    axios
      .post(env.protocol + env.env + "/api/public/pages/GetAllAvailableLanguages.php")
      .then((response) => {
        let unAddedLanguages = response.data.filter((e) => {
          for (let i = 0; i < languages.length; i++) {
            if (e.short === languages[i]) return false;
          }
          return true;
        });
        unAddedLanguages.sort((a, b) => {
          return a.order_by > b.order_by;
        });

        unAddedLanguages.forEach((e, i) => {
          unAddedLanguages[i] = {
            is: "choice",
            // icon: faLanguage,
            text: capitalize(e.what_language),
            onClick: () => {
              axios
                .post(env.protocol + env.env + "/api/secured/appearances/AddLanguageToFooter", {
                  user: sessionStorage.getItem("vfiUser"),
                  siteId: props.siteSelected.siteId,
                  langId: e.id,
                  duplicateLang: languages[0],
                })
                .then((response) => {
                  setIsLoading(true);

                  // Set info about languages
                  setUnMappedLang(response.data.footer.languages);
                  let lang = Object.keys(response.data.footer.languages);
                  setLanguages(lang); // Set languages
                  setSelectedLanguage(response.data.newLanguage.short); // Select added column

                  // Set b_modules
                  var b_mod = { ckeditor_5: [], other_sites: [], follow: [] };
                  response.data.footer.b_modules.forEach((e, i) => {
                    b_mod[e.type].push(pushBModule(e, i + 1));
                  });
                  setContentData(b_mod);

                  setIsLoading(false); // Set not loading
                })
                .catch((error) => console.error(error));
            },
            style: {
              display: "flex",
              // justifyContent: "space-evenly",
            },
          };
        });
        setAddLanguageChoices(unAddedLanguages);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [languages, props.siteSelected.siteId]);

  useEffect(() => {
    const icons = [
      { icon: faFacebookF, text: "Facebook", cheatSheat: "facebook-f" },
      { icon: faInstagram, text: "Instagram", cheatSheat: "instagram" },
      { icon: faTwitter, text: "Twitter", cheatSheat: "twitter" },
      { icon: faLinkedinIn, text: "LinkedIn", cheatSheat: "linkedin-in" },
      { icon: faPinterest, text: "Pinterest", cheatSheat: "pinterest" },
    ];
    let unAddedIcons = icons;
    if (contentData.follow.length > 0) {
      unAddedIcons = icons.filter((e) => {
        for (let i = 0; i < contentData.follow.length; i++) {
          if (e.cheatSheat === contentData.follow[i].cheatSheat) return false;
        }
        return true;
      });
    }
    unAddedIcons.sort((a, b) => {
      return a.order_by > b.order_by;
    });

    unAddedIcons.forEach((e, i) => {
      unAddedIcons[i] = {
        is: "choice",
        icon: e.icon,
        text: e.text,
        type: "follow",
        onClick: () => {
          let newContentData = JSON.parse(JSON.stringify(contentData));

          let i = "<i class='brand fab fa-" + e.cheatSheat + "'></i>";
          newContentData["follow"].push({
            block: i,
            cheatSheat: e.cheatSheat,
            text: e.text,
            url: "",
            type: "follow",
          });
          setContentData(newContentData);
        },
        style: {
          display: "flex",
          // justifyContent: "space-evenly",
        },
      };
    });

    setAddIconChoices(unAddedIcons);
  }, [props.siteSelected.siteId, contentData]);

  const deleteColumn = (colId) => {
    let newContentData = JSON.parse(JSON.stringify(contentData));
    let index = newContentData.ckeditor_5.findIndex((x) => x.id === colId); // Get index of the column we want to remove
    newContentData.ckeditor_5.splice(index, 1);

    //Update column nr
    newContentData.ckeditor_5 = newContentData.ckeditor_5.map((el, i) => {
      let nr = i + 1;
      return { ...el, ref: "col" + nr, text: "Column " + nr };
    });

    if (colId === contentData.ckeditor_5[index].id && newContentData.ckeditor_5.length > 0) {
      // If we delete the one we are currently looking at and there are still columns left
      if (index === 0) {
        // If deleted colum is the first, then set the next selectd column as being the next
        setSelectedCol(newContentData.ckeditor_5[index].ref);
      } else {
        // If deleted colum is not the first, then set the next selectd column as being the previous column
        setSelectedCol(newContentData.ckeditor_5[index - 1].ref);
      }
    }
    setContentData(newContentData);
    setIsLoading(false);
  };

  const addColumn = () => {
    let newContentData = Object.assign({}, contentData);
    let length = newContentData.ckeditor_5.length + 1;
    let id = newContentData.ckeditor_5[length - 2].id + 1;

    // Create languages inside new column
    let lang = {};
    for (var i = 0; i < languages.length; i++) {
      lang[languages[i]] = { blocks: "" };
    }

    let newCol = {
      id: id,
      languages: lang,
      ref: "col" + length,
      text: "Column " + length,
      type: "ckeditor_5",
    };
    newContentData.ckeditor_5.push(newCol);
    setContentData(newContentData);
    setSelectedCol("col" + length);
  };

  const handleCKeditor5Change = (i, newValue, type) => {
    let newContentData = Object.assign({}, contentData);

    if (type === "ckeditor_5") {
      newContentData.ckeditor_5.find((x) => x.ref === selectedCol).languages[selectedLanguage].blocks = newValue; // STUPID WHEN I HAVE TO LOOK FOR THE DATA EVERY TIME
    }
    if (type === "other_sites") {
      newContentData.other_sites[0].languages[selectedLanguage].blocks = newValue; // STUPID WHEN I HAVE TO LOOK FOR THE DATA EVERY TIME
    }

    setContentData(newContentData);
  };

  const setOptionsList = (options, pos = undefined) => {
    setListIsOpen(true);
    setOptionsListOptions(options);
    if (pos !== undefined) setOptionsListPos(pos);
  };

  const setIconList = (options, pos = undefined) => {
    setIconListIsOpen(true);
    setIconOptionsList(options);
    if (pos !== undefined) setIconOptionsListPos(pos);
  };

  const setEditIconList = (options, pos = undefined) => {
    setEditIconListIsOpen(true);
    setEditIconOptionsList(options);
    if (pos !== undefined) setEditIconOptionsListPos(pos);
  };

  const saveContent = () => {
    props.setConfirmBeforeNavigate("");
    setIsLoading(true);

    let mergedData = [...contentData.ckeditor_5, ...contentData.other_sites, ...contentData.follow];

    axios
      .post(env.protocol + env.env + "/api/secured/appearances/SaveFooter", {
        data: mergedData,
        siteId: props.siteSelected.siteId,
      })
      .then((response) => {
        var b_mod = { ckeditor_5: [], other_sites: [], follow: [] };
        response.data.b_modules.forEach((e, i) => {
          b_mod[e.type].push(pushBModule(e, i + 1));
        });
        setContentData(b_mod);
        setContentEditEnabled(false);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const clickPosition = (e, offset = { x: 0, y: 0 }) => {
    return {
      x: e.pageX + (offset.x ? parseInt(offset.x) : 0),
      y: e.pageY + (offset.y ? parseInt(offset.y) : 0),
    };
  };

  const changeSelectedLang = () => {
    let indexSelectedLan = languages.indexOf(selectedLanguage);
    indexSelectedLan === 0
      ? setSelectedLanguage(languages[indexSelectedLan + 1])
      : setSelectedLanguage(languages[indexSelectedLan - 1]);
  };

  const deleteIcon = (e, i) => {
    if (
      window.confirm(
        "Are you sure you want to remove " + e.text.toUpperCase() + " from language list? This action cannot be undone."
      )
    ) {
      setIsLoading(true);

      let newContentData = JSON.parse(JSON.stringify(contentData));

      newContentData.follow.splice(i, 1);
      setContentData(newContentData);
      setIsLoading(false);
    }
  };

  const addUrl = (e, i) => {
    let element = (
      <div key={i} className="option-in-list">
        <div>
          <div className="URLtext">{"Link URL"}</div>
          <VfiInputText
            defaultValue={contentData.follow[i].url}
            placeholder="URL"
            onChange={(e) => {
              let newContentData = JSON.parse(JSON.stringify(contentData));
              newContentData.follow[i].url = e.target.value;

              let block = "<i class='brand fab fa-" + contentData.follow[i].cheatSheat + "'></i>";
              if (newContentData.follow[i].url.length > 0) {
                block =
                  "<a href='" +
                  newContentData.follow[i].url +
                  "'><i class='brand fab fa-" +
                  contentData.follow[i].cheatSheat +
                  "'></i></a>";
              }
              newContentData.follow[i].block = block;

              setContentData(newContentData);
            }}
          />
        </div>
      </div>
    );

    const list = [
      {
        is: "custom",
        icon: faTrash,
        text: "Delete",
        element: element,
      },
    ];
    setEditIconList(list);
  };

  return (
    <div className="edit-footer row">
      <OptionsList
        isOpen={listIsOpen}
        close={() => setListIsOpen(false)}
        options={optionsListOptions}
        position={optionsListPos}
      />
      <OptionsList
        isOpen={iconListIsOpen}
        close={() => setIconListIsOpen(false)}
        options={iconOptionsList}
        position={iconOptionsListPos}
      />
      <OptionsList
        isOpen={editIconListIsOpen}
        close={() => setEditIconListIsOpen(false)}
        options={editIconOptionsList}
        position={editIconOptionsListPos}
      />
      <div className="footer-section col-lg-9">
        <div className="footer-section-header">
          <div className="fixed">
            <span
              className="header-title"
              onClick={() => {
                if (contentEditEnabled) {
                  if (
                    window.confirm(
                      // languages[language].edit_page.re_navigate_warning
                      "Are you sure you want to leave? Unsaved data will be lost!"
                    )
                  )
                    props.close();
                } else {
                  props.close();
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Appearances
            </span>
            {languages.map((e, i) => (
              <div
                className={
                  "one-language" + (selectedLanguage === e ? " selected" : "") + (contentEditEnabled ? " disabled" : "")
                  // (chosenLanguages.length === 1 ? " single" : "")
                }
                key={i}
              >
                <div className="one-language-wrap">
                  <div
                    className="the-language"
                    onClick={() => {
                      setIsLoading(true);
                      setSelectedLanguage(e);
                      let c = Object.assign({}, contentData);

                      setContentData({
                        ckeditor_5: [],
                        other_sites: [],
                        follow: [],
                      });
                      setTimeout(() => {
                        setContentData(c);
                        setIsLoading(false);
                      }, 0);
                    }}
                  >
                    {e.toUpperCase()}
                  </div>
                  {languages.length > 1 && (
                    <div
                      className="trash"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to remove " +
                              e.toUpperCase() +
                              " from language list? This action cannot be undone."
                          )
                        ) {
                          setIsLoading(true);
                          axios
                            .post(env.protocol + env.env + "/api/secured/appearances/DeleteFooterLanguage", {
                              id: props.siteSelected.siteId,
                              language: e,
                            })
                            .then((response) => {
                              // If we are deleting the column we are currently looking at

                              setUnMappedLang(response.data.languages);
                              let lang = Object.keys(response.data.languages);
                              setLanguages(lang); // Set languages

                              // Set b_modules
                              var b_mod = {
                                ckeditor_5: [],
                                other_sites: [],
                                follow: [],
                              };
                              response.data.b_modules.forEach((e, i) => {
                                b_mod[e.type].push(pushBModule(e, i + 1));
                              });
                              setContentData(b_mod);

                              if (selectedLanguage === e) {
                                changeSelectedLang();
                              }
                              setIsLoading(false); // Set not loading
                            })
                            .catch((error) => console.error(error));
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  )}
                </div>
              </div>
            ))}
            {addLanguageChoices.length > 0 ? (
              <div
                className={"add-language" + (contentEditEnabled ? " disabled" : "")}
                onClick={(e) => {
                  setOptionsList(addLanguageChoices, clickPosition(e));
                }}
              >
                +
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={"footer-section-content"}
          style={{
            width: boxWidth + "px",
            // height: parseInt(boxHeight) + 50 + "px",
          }}
        >
          {isLoading ? (
            <div className="blur">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          ) : (
            ""
          )}
          <div className="footer-content-top">
            {contentEditEnabled ? (
              <div className="edit-section">
                <div
                  className="save-button"
                  onClick={() => {
                    saveContent();
                  }}
                >
                  Save
                </div>
                <div
                  className="discard-button"
                  onClick={() => {
                    setContentEditEnabled(false);
                    props.setConfirmBeforeNavigate("");
                    let oldContent = JSON.parse(JSON.stringify(contentBeforeEdit));
                    setTimeout(() => {
                      setContentData(oldContent);
                    }, 0);
                  }}
                >
                  Discard changes
                </div>
              </div>
            ) : (
              <div className="edit-section">
                <div
                  className="edit-button"
                  onClick={() => {
                    setContentEditEnabled(true);
                    props.setConfirmBeforeNavigate(
                      // languages[language].edit_page.re_navigate_warning
                      "Are you sure you want to leave? Unsaved data will be lost!"
                    );
                    setContentBeforeEdit(JSON.parse(JSON.stringify(contentData)));
                  }}
                >
                  Edit
                </div>
              </div>
            )}
            <div className="headline-text">
              {!isLoading ? "Footer of " + unMappedLang[selectedLanguage].siteName : ""}
            </div>
            <div
              className={"display-footer" + (!editMode ? " active" : "")}
              onClick={() => {
                setEditMode(!editMode);
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </div>
          {!isLoading ? (
            editMode ? (
              <div className={"footer-content" + (contentEditEnabled ? "" : " disabled")}>
                <div className="top_module">
                  <div className="cols">
                    <div className="Col1">
                      <div className="center">
                        {!env.env.includes("vita.fo") && (
                          <img src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo} alt="sheep" />
                        )}
                        <div className="text">{unMappedLang[selectedLanguage].siteName}</div>
                      </div>
                    </div>
                    <div className="Col2">
                      <div className="center">
                        <span className="Headtext"> Follow us: </span>
                        <span
                          className="socialIcons"
                          onMouseLeave={() => (contentEditEnabled ? setPenIsShown(false) : "")}
                        >
                          {contentData.follow.length > 0 &&
                            contentData.follow.map((e, i) => (
                              <span
                                key={"follow" + i}
                                onMouseEnter={() => (contentEditEnabled ? setPenIsShown(e.cheatSheat) : "")}
                              >
                                <span dangerouslySetInnerHTML={{ __html: e.block }} />
                                {/* <a href=""><i class={"brand fab fa-"+ e.cheatSheat}></i></a> */}
                                {/* <FontAwesomeIcon className="brand" icon={['fab', e.cheatSheat]} /> */}
                                {penIsShown === e.cheatSheat ? (
                                  // <i class={"fab fa-"+ e.cheatSheat}></i>
                                  <FontAwesomeIcon
                                    className="pen"
                                    icon={faPen}
                                    onClick={(clickE) => {
                                      // Make drop down so you can delete or add URL
                                      const list = [
                                        {
                                          is: "choice",
                                          icon: faTrash,
                                          text: "Delete",
                                          onClick: () => {
                                            deleteIcon(e, i);
                                          },
                                        },
                                        {
                                          is: "choice",
                                          icon: faLink,
                                          text: "Add URL",
                                          onClick: () => {
                                            addUrl(e, i);
                                          },
                                        },
                                      ];
                                      setEditIconList(list, clickPosition(clickE));
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            ))}
                        </span>
                        {addIconChoices.length > 0 && (
                          <span
                            className={"addIcon" + (contentEditEnabled ? "" : " disabled")}
                            onClick={(e) => {
                              setIconList(addIconChoices, clickPosition(e));
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="Col3">
                      <div className="center">
                        {/* <div className="row"> */}
                        <div className="Headtext">Other Sites:</div>
                        {contentData.other_sites.length > 0 && (
                          <VfiCKeditor
                            // className="col-lg-7"
                            value={contentData.other_sites[0].languages[selectedLanguage].blocks}
                            onBlur={(e) => {
                              handleCKeditor5Change([selectedCol], e, "other_sites");
                            }}
                            toolbar={mytoolbar}
                            heading={["paragraph", "Libre Baskerville"]}
                          />
                        )}
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className={"fixedColumns"}>
                  {contentData.ckeditor_5.map((e, i) => (
                    <div
                      className={
                        "one-column" +
                        (selectedCol === e.ref ? " selected" : "") +
                        (indexColumDrag === i ? " dragging" : "") +
                        (indexColumDragOn === i ? " dragging-on" : "")
                      }
                      key={i}
                      draggable={contentEditEnabled ? "true" : "false"}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDragStart={(e) => {
                        setIndexColumDrag(i);
                      }}
                      onDrop={(e) => {
                        setIsLoading(true);
                        setSelectedCol(contentData.ckeditor_5[i].ref);
                        let newContentData = JSON.parse(JSON.stringify(contentData));
                        let post = contentData.ckeditor_5[indexColumDrag];
                        contentData.ckeditor_5.splice(indexColumDrag, 1);
                        contentData.ckeditor_5.splice(i, 0, post);

                        //Update column names
                        newContentData.ckeditor_5 = newContentData.ckeditor_5.map((el, i) => {
                          let nr = i + 1;
                          return {
                            ...el,
                            ref: "col" + nr,
                            text: "Column " + nr,
                          };
                        });
                        setContentData(newContentData);
                        setTimeout(() => {
                          setIsLoading(false);
                        }, 0);
                      }}
                      onDragEnd={(e) => {
                        setIndexColumDrag(undefined);
                        setIndexColumDragOn(undefined);
                      }}
                      onDragEnter={(e) => {
                        setTimeout(() => {
                          setIndexColumDragOn(i);
                        }, 0);
                      }}
                      onDragLeave={(e) => {
                        setIndexColumDragOn(undefined);
                      }}
                    >
                      <div className="one-column-wrap">
                        <div
                          className="the-column"
                          onClick={() => {
                            setSelectedCol(e.ref); // Set current data in colum we are looking at
                          }}
                        >
                          {e.text.toUpperCase()}
                        </div>
                        {contentData.ckeditor_5.length > 1 && (
                          <div
                            className={"trash footerTrash" + (contentEditEnabled ? "" : " disabled")}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to remove " +
                                    e.text.toUpperCase() +
                                    " from column list? This action cannot be undone."
                                )
                              ) {
                                setIsLoading(true);
                                deleteColumn(e.id);
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}

                  <div
                    className={"add-column" + (contentEditEnabled ? "" : " disabled")}
                    onClick={() => {
                      // Add column
                      addColumn();
                    }}
                  >
                    +
                  </div>
                </div>
                {contentData.ckeditor_5.length > 0 && (
                  <div className="row module" key={contentData.ckeditor_5.find((x) => x.ref === selectedCol).id}>
                    <VfiCKeditor
                      className="col-lg-7"
                      value={
                        contentData.ckeditor_5.find((x) => x.ref === selectedCol).languages[selectedLanguage].blocks
                      }
                      onBlur={(e) => {
                        handleCKeditor5Change([selectedCol], e, "ckeditor_5");
                      }}
                      toolbar={props.toolbar}
                      heading={["paragraph", "heading3"]}
                    />
                  </div>
                )}
              </div>
            ) : (
              <FooterFrontEnd
                contentData={contentData}
                selectedLanguage={selectedLanguage}
                siteName={unMappedLang[selectedLanguage].siteName}
              />
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
