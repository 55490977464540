import { useState } from "react";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import Modal from "src/Components/Modal";
import styles from "./ChangeURLSlugModal.module.scss";
import axios from "axios";
import env from "../../../../../../environment.json";
import { VfiCheckbox2 } from "src/assets/VfiCheckbox";

/**
 * Modal to change the URL (redirect)
 *
 * @author 					Pætur Mortensen
 */
export default function ChangeURLSlugModal({ setEditSlug, slug, setNewSlug, pageLanguageID, close }) {
  // Value of the new slug input
  const [slugValue, setSlugValue] = useState(slug);
  // Validation error for form
  const [validationError, setValidationError] = useState("");
  // Whether modal is currently loading or sending request
  const [isLoading, setIsLoading] = useState(false);
  const [shouldMakeRedirect, setShouldMakeRedirect] = useState(true);
  console.log(validationError);

  /**
   * Save the slug
   *
   * Will call the server to create a redirect
   *
   * @author 					Pætur Mortensen
   */
  function save_slug() {
    // If slug has not changed, throw error
    if (slugValue === slug) {
      setValidationError("The new slug is the same as the old one");
      return;
    }

    // Set this form to loading
    setIsLoading(true);

    // Now call the API
    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/AddPageRedirect", {
        pageLanguageID,
        slug: slugValue,
        shouldMakeRedirect: shouldMakeRedirect,
      })
      .then((response) => {
        // Extract the data from the API response
        const data = response.data.data;

        // If this was not successful, show validation error
        if (!data.success) {
          setValidationError(data.validationMsg);
        } else {
          // Redirect added, close and call onUpdate
          setNewSlug(slugValue);
          onClose();
        }
      })
      .catch((error) => {
        setValidationError("Something went wrong. Try again or contact support if this keeps happening");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function onClose() {
    if (close) {
      close();
    }
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Change Slug</h2>
      <p>
        If you don't want to disrupt your SEO, we recommend that you use 301 redirects to route traffic from the old URL
        to this new URL
      </p>

      <div className={styles.formField}>
        <label>SLUG</label>
        <VfiInputText
          inputType="text"
          value={slugValue}
          onChange={(e) => {
            setSlugValue(e.target.value);
          }}
        />
        <label className={styles.formRow}>
          <VfiCheckbox2
            className="black-check"
            checked={shouldMakeRedirect}
            onChange={(e) => setShouldMakeRedirect(e.target.checked)}
          />
          <p>Permanently redirect the old URL to this new one</p>
        </label>
      </div>

      <p className={styles.errorLabel}>{validationError}</p>
      <div className={styles.buttonGroup}>
        <button
          className={styles.cancelButton}
          onClick={() => {
            close();
          }}
        >
          CANCEL
        </button>
        <button
          className={styles.editButton}
          onClick={() => {
            save_slug();
          }}
          disabled={isLoading}
        >
          {isLoading ? "SAVING..." : "SAVE AND CLOSE"}
        </button>
      </div>
    </div>
  );
}
