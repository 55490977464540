import styles from "./DeleteModule.module.scss";

interface Props {
  close: () => void;
  deleteModule: () => void;
}

export default function DeleteModule({ close, deleteModule }: Props) {
  return (
    <div className={styles.container}>
      <p>Are you sure you want to delete this module?</p>

      {/* Buttons */}

      <div className={styles.buttons}>
        {/* No */}

        <button
          className={styles.cancel}
          onClick={() => {
            close();
          }}
        >
          NO, CANCEL
        </button>

        {/* Yes */}

        <button
          className={styles.confirm}
          onClick={() => {
            deleteModule();
            close();
          }}
        >
          YES, DELETE
        </button>
      </div>
    </div>
  );
}
