import styles from "./ToggleCheckbox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ToggleCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: () => void;
  label?: string;
  className?: string;
}
function ToggleCheckbox({
  id,
  checked,
  defaultChecked,
  onChange,
  label,
  className,
  style,
  ...restProps
}: ToggleCheckboxProps) {
  const checkboxContainerClasses = `${styles.checkboxContainer} ${className || ""}`;

  const knobX = checked ? 18 : 2;

  return (
    <label htmlFor={id} className={checkboxContainerClasses} style={style}>
      <input
        type="checkbox"
        id={id}
        className={styles.checkboxInput}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        {...restProps}
      />
      <div className={styles.checkboxLabel}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="24" viewBox="0 0 40 24" fill="none">
          {/* Background rectangle */}
          <rect width="40" height="24" rx="12" fill={checked ? "black" : "#EBEDF2"} />
          {/* Group for the knob and checkmark to ensure they move together */}
          <g style={{ transition: "all 0.3s ease", transform: `translateX(${knobX}px)` }}>
            {/* Knob */}
            <rect x="0" y="2" width="20" height="20" rx="10" fill="white" />
            {/* Adjusted Checkmark, shifted to the left */}
            <path
              d="M5.8197 9.5936L9.2787 13.1018L15.1803 7.2002L17 9.01987L9.2787 16.692L5 12.4133L6.8197 10.5936Z"
              fill={checked ? "black" : "white"}
            />
          </g>
        </svg>
        {label && <span className={styles.checkboxLabelText}>{label}</span>}
      </div>
    </label>
  );
}

export default ToggleCheckbox;
