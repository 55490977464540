import { TSystemLanguage, TSystemLanguageShort, systemLanguages } from "src/types/types/TSystemLanguage";

// Type guard to check if a string is a key of systemLanguages
function isTSystemLanguage(key: string): key is TSystemLanguage {
  return key in systemLanguages;
}

export function getLocalStorageLanguage(): TSystemLanguage {
  const rawItem = localStorage.getItem("language");
  if (rawItem !== null && isTSystemLanguage(rawItem)) {
    return systemLanguages[rawItem];
  }
  return systemLanguages["english"];
}

type ILanguage2ShortOption = {
  [Key in TSystemLanguage]: TSystemLanguageShort;
};

export function language2short(language: TSystemLanguage): TSystemLanguageShort {
  const languages: ILanguage2ShortOption = {
    english: "en",
    // faroese: "fo",
  };

  return languages[language];
}
