import styles from "./MediaCenter.module.scss";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";
import MediaListOverlay from "./MediaListOverlay";
import { AddLocalMediaType, MediaItem } from "./types";

/**
 * Add media from locally stored media
 *
 * @author 					Pætur Mortensen
 */
export default function AddLocalMedia({ onInsert }: AddLocalMediaType): JSX.Element {
  const [showMediaList, setShowMediaList] = useState(false);

  /**
   * Insert media items and close the media list overlay
   *
   * @author 					Pætur Mortensen
   */
  function insert_media(media: Array<MediaItem>): void {
    setShowMediaList(false);
    onInsert(media);
  }

  return (
    <div className={styles.addLocalContainer}>
      <div
        className={styles.addLocalButton}
        onClick={() => {
          setShowMediaList(true);
        }}
      >
        <FontAwesomeIcon icon={faPlus as IconProp} />
      </div>
      {showMediaList && <MediaListOverlay show={showMediaList} setShow={setShowMediaList} onInsert={insert_media} />}
    </div>
  );
}
