import { faSyncAlt } from "@fortawesome/pro-light-svg-icons";
import { cloneDeep } from "lodash";
import React from "react";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import OneMediaWithSubtext from "../OneMedia/OneMediaWithSubtext";
import "./MediaWithSubtextAndText.scss";

export function moduleOptions(index, contentData, setContentData) {
  return [
    // {
    //   is: "choice",
    //   icon: faFrown,
    //   text: "None implemented yet...",
    //   onClick: () => {
    //     let newContentData = Object.assign([], contentData);
    //     // newContentData.splice(i, 1);
    //     // newContentData[index].type = "first_letter_big";
    //     setContentData(newContentData);
    //   },
    //   style: { color: "red" },
    // },
    {
      is: "choice",
      icon: faSyncAlt,
      text: "Swap",
      onClick: () => {
        let newContentData = cloneDeep(contentData);
        newContentData[index].swapped = !newContentData[index].swapped;
        setContentData(newContentData);
      },
    },
  ];
}

export default function MediaWithSubtextAndText(props) {
  return (
    <div
      style={props.style}
      className={
        "media-with-subtext-and-text " + (props.data.swapped && "swapped ") + (props.className ? props.className : "")
      }
    >
      <div className={"media-text-content"}>
        <VfiCKeditor
          className="text-content"
          value={props.rightTextValue}
          onBlur={props.onRightTextBlur}
          toolbar={props.toolbar}
        />
        <OneMediaWithSubtext
          siteRef={props.siteRef}
          setSiteRef={props.setSiteRef}
          openOverlay={props.openOverlay}
          whatMedia={["images", "videos"]}
          media={props.media}
          onClickMedia={props.onClickMedia}
          defaultSubText={props.defaultSubText}
          onBlurSubText={props.onBlurSubText}
          toolbar={props.toolbar}
        />
      </div>
    </div>
  );
}
