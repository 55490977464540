import { MediaGalleryType, MediaItem } from "./types";
import env from "../../environment.json";
import styles from "./MediaCenter.module.scss";
import { faTrashAlt, faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";
import Overlay from "../Overlay";

/**
 * Gallery for local media items
 *
 * @author 					Pætur Mortensen
 */
export default function MediaGallery({ media, onRemove = () => {} }: MediaGalleryType): JSX.Element {
  // Show preview null= don't show else mediaID of element to show
  const [showPreview, setShowPreview] = useState<number | null>(null);

  /***************************************************************************************************
   *
   * 																		RENDER
   *
   **************************************************************************************************/

  /**
   * Render an image preview
   *
   * Renders overlay with preview of selected preview image (state: showPreview)
   *
   * @author 					Pætur Mortensen
   */
  function render_preview(): JSX.Element {
    const medium = media.find((item) => item.mediaID === showPreview);

    // If medium is not found, return empty element
    if (!medium) return <></>;

    return (
      <Overlay>
        <div className={styles.previewContainer}>
          <div className={styles.previewCP}>
            <FontAwesomeIcon
              className={styles.closeButton}
              onClick={() => setShowPreview(null)}
              icon={faClose as IconProp}
            />
          </div>
          <div className={styles.previewImageContainer}>
            <img
              className={styles.previewImage}
              src={env.protocol + env.env + "/uploads/" + medium.fileName + "." + medium.extension}
            />
          </div>
        </div>
      </Overlay>
    );
  }

  /**
   * Render a single media item
   *
   * @author 					Pætur Mortensen
   */
  function render_medium(medium: MediaItem, index: number): JSX.Element {
    return (
      <div key={medium.mediaID + "_" + index} className={styles.galleryItemContainer}>
        <div className={styles.imageCP}>
          <FontAwesomeIcon
            icon={faTrashAlt as IconProp}
            className={styles.CPButton}
            onClick={() => {
              onRemove(medium.mediaID);
            }}
          />
        </div>
        <div className={styles.imageContainer} onClick={() => setShowPreview(medium.mediaID)}>
          <img src={env.protocol + env.env + "/uploads/" + medium.fileName + "_medium." + medium.extension} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.mediaGallery}>
      {media.map((medium, idx) => render_medium(medium, idx))}
      {showPreview !== null && render_preview()}
    </div>
  );
}
