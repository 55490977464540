import styles from "./TextInput.module.scss";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  inputType?: "text" | "password" | "email" | "number" | "tel" | "url";
  label?: string | React.ReactNode;
  error?: string;
  placeholder?: string;
  labelPosition?: "left" | "right" | "top" | "bottom";
  containerStyle?: string;
  labelStyle?: string;

  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function TextInput({
  inputType = "text",
  id,
  label,
  error = "",
  placeholder,
  labelPosition = "left",
  containerStyle,
  labelStyle,
  value,
  onChange,
  ...props
}: TextInputProps) {
  const containerPositionStyle = (() => {
    switch (labelPosition) {
      case "top":
      case "bottom":
        return styles.verticalContainer;
      default:
        return styles.horizontalContainer;
    }
  })();

  return (
    <div className={`${styles.container} ${containerPositionStyle} ${containerStyle}`}>
      {(labelPosition === "top" || labelPosition === "left") && label && (
        <label className={`${styles.label} ${styles[labelPosition]} ${labelStyle}`} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        type={inputType}
        className={`${styles.input} ${error ? styles.error : ""}`}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        {...props}
      />
      {(labelPosition === "bottom" || labelPosition === "right") && label && (
        <label className={`${styles.label} ${styles[labelPosition]} ${labelStyle}`} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
}

export default TextInput;
