import React, { Component } from "react";
import "./ListSidebar.scss";
import ListOption from "./ListOption";
import helperfunctions from "../../../assets/helperFunctions";

class ListSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sitesChecked: false,
    };
  }

  render() {
    return (
      <div className="list-sidebar">
        <h1>{helperfunctions.capitalize(this.props.contentshow)}</h1>
        <p className="small-gray">SITE</p>
        <div className="list-parent" onDrop={this.drop} onDragOver={this.dragOver}>
          {/* <ListOption
                    html={<div className="one-list-option">All sites</div>}
                    onClick={()=>{this.optionClicked({index:"0"})}}
                    selected={this.state.allSelected}
                    draggable={false}
                /> */}
          {this.props.listOptions
            .filter((x) => x.rootPage === 0)
            .map((element, i) => {
              if (!this.props.showAllParameter && element.index === "0") return "";

              return (
                <ListOption
                  key={element.index}
                  html={<div className="one-list-option">{element.name}</div>}
                  onClick={() => {
                    this.props.optionClicked({
                      ...element,
                      index: element.index,
                    });
                  }}
                  selected={element.selected}
                  draggable={false}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default ListSidebar;
