import "../Product.scss";
import ShippingPanel from "./ShippingPanel";
import ProductTypePanel from "./ProductTypePanel";
import ProductLinePanel from "./ProductLinePanel";
import AvailablePanel from "./AvailablePanel";
import WarehousePanel from "./WarehousePanel";
import RatingPanel from "./RatingPanel";

export default function PanelsColumn({ configuration, productState, setForceUpdate, slugify, props }) {
  return (
    <div className="second-column">
      <ProductTypePanel configuration={configuration} productState={productState} setForceUpdate={setForceUpdate} />
      <ProductLinePanel productState={productState} configuration={configuration} setForceUpdate={setForceUpdate} />
      <AvailablePanel productState={productState} setForceUpdate={setForceUpdate} slugify={slugify} props={props} />
      <WarehousePanel productState={productState} setForceUpdate={setForceUpdate} slugify={slugify} props={props} />
      <RatingPanel productState={productState} />
      <ShippingPanel productState={productState} />
    </div>
  );
}
