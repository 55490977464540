import { faArrowLeftLong, faPlus, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Select from "react-select";
import { v4 as uuid } from "uuid";
import "./Configuration.scss";
import Physical from "./ProductTypes/Physical/Physical";
import Prices from "./ProductTypes/Physical/Prices";
import Filter from "./ProductTypes/Physical/Filter";
import B2BEmailConfig from "./B2BEmailConfig";
import Settings from "./ProductTypes/Physical/ShopSettings";

export default function Configuration(props) {
  const { close, configuration, saveConfiguration, updateConf, siteId, filterOptions, disabledFilters } = props;

  const [, setForceUpdate] = useState(false);
  const [selectedProduct, setSelectProduct] = useState();

  const productTypes = [
    { label: "Any", value: "any" },
    { label: "Digital", value: "digital" },
    { label: "Physical", value: "physical" },
  ];

  const add = (type) => {
    if (type === "Product Type") {
      type = "productTypes";
    }
    return (
      <div className="wrap-add">
        <div
          onClick={() => {
            updateConf.productTypes.unshift({
              type,
              new: true,
              uuid: uuid(),
              label: "",
              values: { label: "", value: "" },
            });
            setForceUpdate((f) => !f);
          }}
          className="add"
        >
          <FontAwesomeIcon icon={faPlus} /> Add {type}
        </div>
      </div>
    );
  };
  return (
    <div className="configuration overlay">
      <div className="top">
        <div>
          <div className="title">
            <div
              onClick={() => {
                close();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </div>
            <h1>Configuration</h1>
          </div>
          <div
            onClick={() => {
              saveConfiguration(siteId, updateConf);
            }}
            className="save"
          >
            Save
          </div>
        </div>
      </div>
      <div className="content">
        <div className="product-type">
          <div className="wrap-top">
            <div>
              <h3>Product Type</h3>
              <p>Used for combining group of products. ex. Food, Tires, Clothes, Events etc.</p>
            </div>
          </div>

          <table className="types">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th>
                  CSS Pseudo (<i>Fontawesome</i>)
                </th>
              </tr>
              {[...new Set([...(configuration?.productTypes ?? []), ...updateConf.productTypes])]
                .filter((x) => !x.deleted)
                .map((element, j) => {
                  return (
                    <tr key={element.uuid + j} className="type">
                      <td>
                        <input
                          onChange={(e) => {
                            element.label = e.target.value;
                            if (!element.new) {
                              element.update = true;
                            }
                            let findex = updateConf.productTypes.findIndex((x) => x.uuid === element.uuid);
                            if (findex === -1) {
                              updateConf.productTypes.push(element);
                            } else {
                              updateConf.productTypes[findex] = element;
                            }
                          }}
                          defaultValue={element.label}
                        ></input>
                      </td>
                      <td>
                        <Select
                          isDisabled={element.values.value === "any"}
                          className={"select " + `${element.values.value === "any" && "disabled"}`}
                          defaultValue={element.values}
                          options={productTypes.filter((x) => x.value !== "any")}
                          onChange={(e) => {
                            element.values = e;
                            if (!element.new) {
                              element.update = true;
                            }
                            let findex = updateConf.productTypes.findIndex((x) => x.uuid === element.uuid);
                            if (findex === -1) {
                              updateConf.productTypes.push(element);
                            } else {
                              updateConf.productTypes[findex] = element;
                            }
                            setForceUpdate((f) => !f);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          defaultValue={element.pseudo}
                          onChange={(e) => {
                            element.pseudo = e.target.value;
                            if (!element.new) {
                              element.update = true;
                            }
                            let findex = updateConf.productTypes.findIndex((x) => x.uuid === element.uuid);
                            if (findex === -1) {
                              updateConf.productTypes.push(element);
                            } else {
                              updateConf.productTypes[findex] = element;
                            }
                            setForceUpdate((f) => !f);
                          }}
                        />
                      </td>
                      <td>
                        {element.label === "any" ? (
                          <div className="remove"></div>
                        ) : (
                          <div
                            className="remove"
                            onClick={() => {
                              element.deleted = true;
                              let findex = updateConf.productTypes.findIndex((x) => x.uuid === element.uuid);
                              if (findex === -1) {
                                updateConf.productTypes.push(element);
                              } else {
                                updateConf.productTypes[findex] = element;
                              }

                              setForceUpdate((f) => !f);
                            }}
                          >
                            <FontAwesomeIcon color="red" icon={faTrashAlt} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {add("Product Type")}
        </div>
        <div>
          <div className="wrap-top">
            <div>
              <h3>Select product type</h3>
              <p></p>
            </div>
          </div>
          <Select
            options={configuration.productTypes.filter((x) => x.value !== "any")}
            onChange={(e) => {
              setSelectProduct(e);
            }}
          />
        </div>
        {configuration.productTypes
          .filter((x) => !updateConf.productTypes.some((z) => z.uuid === x.uuid))
          .concat(updateConf.productTypes)
          .filter((x) => x.uuid !== 0)
          .map((element, index) => {
            if (element.values.value === "physical") {
              if (selectedProduct?.uuid === element.uuid) {
                let findex = configuration.physical.findIndex((x) => x.productTypeuuid === selectedProduct.uuid);
                if (findex === -1) {
                  configuration.physical[0].productTypeuuid = selectedProduct.uuid;
                  findex = 0;
                }
                return [
                  <div key={"physical" + index}>
                    <Physical
                      configuration={configuration}
                      productTypeuuid={selectedProduct?.uuid}
                      findex={findex}
                      updateConf={updateConf}
                      setForceUpdate={setForceUpdate}
                    />
                  </div>,

                  <div key={"settings" + index}>
                    <Prices
                      disabledFilters={disabledFilters}
                      updateConf={updateConf}
                      productTypeuuid={selectedProduct?.uuid}
                      configuration={configuration}
                      findex={findex}
                      setForceUpdate={setForceUpdate}
                    />
                  </div>,
                  <div key={"filter" + index}>
                    <Filter
                      disabledFilters={disabledFilters}
                      filterOptions={filterOptions}
                      findex={findex}
                      setForceUpdate={setForceUpdate}
                    />
                  </div>,
                ];
              }
            }

            return null;
          })}
        <div key={"prices"}>
          <Settings
            // disabledFilters={disabledFilters}
            updateConf={updateConf}
            productTypeuuid={selectedProduct?.uuid}
            configuration={configuration}
            // findex={findex}
            setForceUpdate={setForceUpdate}
          />
        </div>
        <B2BEmailConfig />
      </div>
    </div>
  );
}
