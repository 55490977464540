import { useEffect, useState } from "react";
import { DeleteRedirectType } from "../types";
import axios, { AxiosError } from "axios";
import env from "../../../../../../environment.json";
import styles from "../RedirectsDisplay.module.scss";

export default function DeleteRedirect({ selectedRedirects, close, onUpdate }: DeleteRedirectType): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log("Selected recieved", selectedRedirects);
  }, [selectedRedirects]);

  function delete_redirect() {
    console.log("Delete btn clicked");
    if (selectedRedirects.length === 0) {
      console.log("No redirects selected for deletion");
      return;
    }

    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/DeleteRedirect", { redirectIDs: selectedRedirects })
      .then((response) => {
        console.log("Delete response:", response.data);
        onUpdate();
        onClose();
      })
      .catch((error: AxiosError) => {
        console.error("Error deleting redirects:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onClose() {
    if (close) {
      close();
    }
  }

  return (
    <div className={styles.deleteOverlay}>
      <h2 className={styles.title}>Delete Redirect</h2>
      <p>Are you sure you want to delete this redirect? Any links to this slug will be dead</p>

      <div className={styles.buttonGroup}>
        <button className={styles.cancelButton} onClick={onClose}>
          Cancel
        </button>
        <button className={styles.deleteButton} onClick={delete_redirect} disabled={isLoading}>
          {isLoading ? "Deleting..." : "Delete and Close"}
        </button>
      </div>
    </div>
  );
}
