import React, { Component } from "react";
import VfiInputText from "../../VfiInputText/VfiInputText";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faTimes, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faPlusSquare } from "@fortawesome/pro-solid-svg-icons";
import language from "./language.json";
import { cloneDeep } from "lodash";
// import "./OpeningHours.scss";
import styles from "./OpeningHours.module.scss";
import "./TimeSelect.scss";
import VfiCustomSelect from "../../VfiCustomSelect";
import VerifyMethods from "../../VerifyMethods";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Select, { SingleValue } from "react-select";
import { VfiCheckbox2 } from "../../VfiCheckbox";

function moreZero(val: number) {
  return val < 10 ? "0" + val : val.toString();
}

interface LanguageDetails {
  day: string;
  from: string;
  to: string;
  closed: string;
  hour: string;
  minute: string;
  add: string;
  example_en: string;
  example_fo: string;
}
// Define the outer object with an index signature
interface LanguageDictionary {
  [key: string]: LanguageDetails; // Allow any string as a key, with LanguageDetails as the value type
}
interface ISelectOption {
  label: string;
  value: string;
}
interface days {
  en: string;
  fo: string;
}
interface time {
  hour: ISelectOption;
  minutes: ISelectOption;
}
interface IOpeningHoursValue {
  days: days;
  from: time;
  to: time;
  closed: boolean;
  key?: number;
}
interface props {
  defaultValue: IOpeningHoursValue[];
  language: string;
  onChange: (e: IOpeningHoursValue[]) => void;
  disabled: boolean;
  showErrors: boolean;
}
interface states {
  openingHours: IOpeningHoursValue[];
}

const OPENING_HOURS_SELECT_CONFIG: any = {
        control: (base: any, state: any) => ({
          ...base,
          border: 0,
          borderRadius: "2px",
          cursor: "pointer",
          minHeight: "50px",
          backgroundColor: "#f4f5f7",

          boxShadow: state.isFocused ? "0 0 0 2px #009fff" : "none",
          borderColor: state.isFocused ? "#009fff" : base.borderColor,
        }),
        indicatorsContainer: () => ({
          display: "none",
        }),
        valueContainer: (base: any) => ({
          ...base,
        }),
        singleValue: (base: any) => ({
          ...base,
          margin: 0,
          overflow: "visible",
        }),
        input: (base: any) => ({
          ...base,
          marginLeft: "10px",
        }),
      };

class OpeningHours extends Component<props, states> {
  hours: ISelectOption[] = [];
  minutes: ISelectOption[] = [];
  language: any = {};

  constructor(props: props) {
    super(props);

    let defaultValue = cloneDeep(props.defaultValue);

    let maxKey = Math.max(...defaultValue.map((e) => e.key ?? 0));

    defaultValue.forEach((e, i) => {
      if (e.key === undefined) {
        defaultValue[i].key = maxKey + 1;
        maxKey++;
      }
    });

    this.state = {
      openingHours: defaultValue,
    };

    this.addOpeningHours = this.addOpeningHours.bind(this);

    for (let i = 0; i < 24; i++) {
      this.hours.push({ label: moreZero(i), value: moreZero(i) });
    }

    for (let i = 0; i < 60; i += 5) {
      this.minutes.push({ label: moreZero(i).toString(), value: moreZero(i) });
    }

    const languageElement: LanguageDictionary = language;
    this.language = languageElement[this.props.language];
  }

  addOpeningHours() {
    const oh = cloneDeep(this.state.openingHours);
    let highestKey = 0;
    oh.forEach((element, i) => {
      if ((element.key ?? 0) > highestKey) highestKey = element.key ?? 0;
    });
    oh.push({
      key: highestKey + 1,
      days: { en: "", fo: "" },
      from: { hour: { label: "00", value: "00" }, minutes: { label: "00", value: "00" } },
      to: { hour: { label: "00", value: "00" }, minutes: { label: "00", value: "00" } },
      closed: false,
    });

    this.setState({ openingHours: oh });
    this.onChange(oh);
  }

  onChange(e: IOpeningHoursValue[]) {
    if (this.props.onChange !== undefined) {
      this.props.onChange(e);
    }
  }

  componentDidUpdate = (prevProps: props) => {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ openingHours: this.props.defaultValue });
    }
  };

  zeroes = { label: "00", value: "00" };

  render() {
    return (
      <div className={styles["opening-hours"]}>
        <div className={styles["opening-hours-wrap"]}>
          <div className={styles["title"]}>English title</div>
          <div className={`${styles["gap"]}`}></div>
          <div className={styles["title"]}>Faroese title</div>
          <div className={`${styles["gap"]}`}></div>
          <div className={styles["title"]}>TIME</div>
          <div className={`${styles["gap"]}`}></div>
          <div className={styles["title"]}>CLOSED</div>
          <div className={`${styles["gap"]}`}></div>
          <div></div>
          <div className={`${styles["underline"]} ${styles["inactive"]}`}></div>
          <div className={`${styles["no-underline"]}`}></div>
          {this.state.openingHours.map((element, i) => {
            return [
              <div
                key={element.key + "day_en"}
                className={`${styles["element"]} ${i === 0 ? styles["no-padding-top"] : ""} ${styles["day"]} ${
                  styles["en"]
                }`}
              >
                <VfiInputText
                  disabled={this.props.disabled}
                  name="day en"
                  value={element.days.en}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const openingHoursD = cloneDeep(this.state.openingHours);
                    openingHoursD[i].days.en = e.target.value;
                    this.setState({ openingHours: openingHoursD });
                    this.onChange(openingHoursD);
                  }}
                  placeholder={this.language.example_en}
                  errorCheckSequence={this.props.showErrors ? [VerifyMethods.notEmpty] : []}
                />
              </div>,
              <div className={`${styles["gap"]}`}></div>,
              <div
                key={element.key + "day_fo"}
                className={`${styles["element"]} ${i === 0 ? styles["no-padding-top"] : ""} ${styles["day"]} ${
                  styles["fo"]
                }`}
              >
                <VfiInputText
                  disabled={this.props.disabled}
                  name="day fo"
                  value={element.days.fo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const openingHoursD = cloneDeep(this.state.openingHours);
                    openingHoursD[i].days.fo = e.target.value;
                    this.setState({ openingHours: openingHoursD });
                    this.onChange(openingHoursD);
                  }}
                  placeholder={this.language.example_fo}
                  errorCheckSequence={this.props.showErrors ? [VerifyMethods.notEmpty] : []}
                />
              </div>,
              <div className={`${styles["gap"]}`}></div>,
              <div
                key={element.key + "time"}
                className={`${styles["element"]} ${i === 0 ? styles["no-padding-top"] : ""} ${styles["time-from-to"]} ${
                  styles["hidden"]
                }`}
              >
                <div className={`${styles["time-from-to-wrap"]} ${element.closed ? styles["hidden"] : ""}`}>
                  <div className={styles["from"]}>
                    <span className={styles["text"]}>{this.language.from}</span>
                    <Select
                      isDisabled={this.props.disabled || element.closed}
                      placeholder={this.language.hour}
                      options={this.hours}
                      value={element.from.hour}
                      onChange={(e: SingleValue<ISelectOption>) => {
                        const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                        openingHoursD[i].from.hour = e;
                        if (!openingHoursD[i].from.minutes.value) openingHoursD[i].from.minutes = this.zeroes;
                        this.setState({ openingHours: openingHoursD });
                        this.onChange(openingHoursD);
                      }}
                      // error={this.props.showErrors && !element.from.hour.value}
                      className="time-select"
                      classNamePrefix="time-select"
                      classNames={{
                        menu: () => styles.selectMenu,
                      }}
                      styles={OPENING_HOURS_SELECT_CONFIG}
                    />
                    <Select
                      isDisabled={this.props.disabled || element.closed}
                      placeholder={this.language.minute}
                      options={this.minutes}
                      value={element.from.minutes}
                      onChange={(e: SingleValue<ISelectOption>) => {
                        const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                        openingHoursD[i].from.minutes = e;
                        if (!openingHoursD[i].from.hour.value) openingHoursD[i].from.hour = this.zeroes;
                        this.setState({ openingHours: openingHoursD });
                        this.onChange(openingHoursD);
                      }}
                      // error={this.props.showErrors && !element.from.minutes.value}
                      className="time-select"
                      classNamePrefix="time-select"
                      classNames={{
                        menu: () => styles.selectMenu,
                      }}
                      styles={OPENING_HOURS_SELECT_CONFIG}
                    />
                  </div>
                  <div className={styles["to"]}>
                    <span className={styles["text"]}>{this.language.to}</span>
                    <Select
                      isDisabled={this.props.disabled || element.closed}
                      placeholder={this.language.hour}
                      options={this.hours}
                      value={element.to.hour}
                      onChange={(e: SingleValue<ISelectOption>) => {
                        const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                        openingHoursD[i].to.hour = e;
                        if (!openingHoursD[i].to.minutes.value) openingHoursD[i].to.minutes = this.zeroes;
                        this.setState({ openingHours: openingHoursD });
                        this.onChange(openingHoursD);
                      }}
                      // error={this.props.showErrors && !element.to.hour.value}
                      className="time-select"
                      classNamePrefix="time-select"
                      classNames={{
                        menu: () => styles.selectMenu,
                      }}
                      styles={OPENING_HOURS_SELECT_CONFIG}
                    />
                    <Select
                      isDisabled={this.props.disabled || element.closed}
                      placeholder={this.language.minute}
                      options={this.minutes}
                      value={element.to.minutes}
                      onChange={(e: SingleValue<ISelectOption>) => {
                        const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                        openingHoursD[i].to.minutes = e;
                        if (!openingHoursD[i].to.hour.value) openingHoursD[i].to.hour = this.zeroes;
                        this.setState({ openingHours: openingHoursD });
                        this.onChange(openingHoursD);
                      }}
                      // error={this.props.showErrors && !element.to.minutes.value}
                      className="time-select"
                      classNamePrefix="time-select"
                      classNames={{
                        menu: () => styles.selectMenu,
                      }}
                      styles={OPENING_HOURS_SELECT_CONFIG}
                    />
                  </div>
                </div>
              </div>,
              <div className={`${styles["gap"]}`}></div>,
              <div
                key={element.key + "closed"}
                className={`${styles["element"]} ${i === 0 ? styles["no-padding-top"] : ""} ${styles["closed"]}`}
              >
                <label className={`${styles["closed-label"]}`}>
                  <VfiCheckbox2
                    disabled={this.props.disabled}
                    checked={this.state.openingHours[i].closed}
                    onChange={() => {
                      const openingHours = JSON.parse(JSON.stringify(this.state.openingHours));
                      openingHours[i].closed = !openingHours[i].closed;
                      this.setState({ openingHours });
                      this.onChange(openingHours);
                    }}
                  />
                  <span className={`${styles["closed-text"]}`}>{this.language.closed}</span>
                </label>
              </div>,
              <div className={`${styles["gap"]}`}></div>,
              <div key={element.key + "remove"} className={`${styles["delete"]}`}>
                <button
                  onClick={() => {
                    if (!this.props.disabled) {
                      const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                      openingHoursD.splice(i, 1);
                      this.setState({ openingHours: openingHoursD });
                      this.onChange(openingHoursD);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTimes as IconProp} />
                </button>
              </div>,
              <div key={element.key + "underline"} className={`${styles["underline"]}`}></div>,
              <div key={element.key + "no-underline"} className={`${styles["no-underline"]}`}></div>,
            ];
          })}
        </div>
        <div className={styles["add-icon"]} onClick={!this.props.disabled ? this.addOpeningHours : () => {}}>
          <span>{this.language.add}</span>
          <FontAwesomeIcon icon={faPlus as IconProp} />
        </div>
      </div>
    );
  }
}

export default OpeningHours;
