import { routerStore, useRouterStore } from "src/Components/Router/routerStore";
import styles from "./SubmitEvent.module.scss";
import { useEffect, useRef, useState } from "react";
import validateForm from "../../forms/utils/form-validation";
import WhatsonCreateNew from "src/Components/Content/WhatsonDisplay/WhatsonCreateNew";
import AboutYou from "../../forms/about-you/AboutYou";
import WhatsOnViewManager from "src/views/WhatsOn/WhatsOnViewManager";

export interface IInformation {
  name: string;
  email: string;
  terms: boolean;
}

export default function SubmitEvent() {
  const { route, navByLink, get_parameters } = useRouterStore();

  const [information, setInformation] = useState<IInformation>({
    name: "",
    email: "",
    terms: false,
  });

  const [forward, setForward] = useState<boolean>(false);

  const container_ref = useRef<HTMLDivElement | null>(null);

  // TODO: Switch to the commented code below to enable the form validation, and remove the code below it
  // return (
  //   <div className={styles.container}>
  //     {!forward && <AboutYou information={information} setInformation={setInformation} setForward={setForward} />}
  //     {forward && route[2]?.nav === "create" && (
  //       <>
  //         <h1 className={styles.title}>Submit event</h1>
  //         <div className={styles.content}>
  //           <WhatsOnViewManager whatsOnType={"place"} />
  //         </div>
  //       </>
  //     )}
  //   </div>
  // );

  return (
    <div ref={container_ref} className={styles.container}>
      {!forward && <AboutYou information={information} setInformation={setInformation} setForward={setForward} />}
      {forward && route[2]?.nav === "create" && (
        <>
          <div className={styles.title_wrapper}>
            <h1 className={styles.title}>Submit event</h1>
          </div>
          <div className={styles.content}>
            <WhatsOnViewManager
              whatsOnType={"event"}
              onStepChange={() => {
                if (container_ref.current) {
                  // container_ref.current.scrollIntoView({
                  //   behavior: "smooth",
                  //   block: "start",
                  // });
                  container_ref.current.scrollTop = 0;
                }
              }}
              non_user={{
                name: information.name,
                email: information.email,
                terms: information.terms,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
