import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { Props as SelectProps } from "react-select";
import "./VfiCustomSelect.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface props {
  error?: boolean;
  className?: string;
}

export default function VfiCustomSelect(props: props & SelectProps) {
  return (
    <div className={"vfi-custom-select-wrap " + (props.error === true ? "error " : "") + (props.className ?? "")}>
      <Select {...props} className={"vfi-custom-select"} classNamePrefix={"vfi-custom-select"} />
      <FontAwesomeIcon className="chevron" icon={faChevronDown as IconDefinition} />
    </div>
  );
}
