//All sliders, including grids are in this EditModule

import { useEffect, useState } from "react";
import "./IndividualEdit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToTop } from "@fortawesome/pro-light-svg-icons";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { uploadMedia } from "../../helperFunctions";
import cloneDeep from "lodash.clonedeep";
import UploadOrFindImage from "../VfiCarousel/UploadOrFindImage";

export default function IndividualEdit(props) {
  const [values, setValues] = useState(props.values);
  const [screen, setScreen] = useState("image");

  const { change } = props;

  useEffect(() => {
    if (change) change(values);
  }, [values, change]);

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  const titleAndDescriptionOptions = [
    { label: "Light text", value: "light_text" },
    { label: "None", value: "none" },
  ];
  const linkOptions = [
    { label: "Button", value: "page" },
    { label: "None", value: "none" },
  ];

  // const[uploaded,setUploaded] = useState(100);
  // const[draggingOverUpload,setDraggingOverUpload] = useState(false);

  // const config = {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //   },
  //   onUploadProgress: (progressEvent) => {
  //       setUploaded((progressEvent.loaded * 100) / progressEvent.total)
  //   },
  // }
  // const uploadFiles = (e) => {
  //   uploadMedia(e, props.siteId, config, (res) => {

  //     let copyValues = cloneDeep(values);

  //     copyValues.media.id = res.data[0].id;
  //     copyValues.media.extension = res.data[0].file_extension;

  //     setValues(copyValues);
  //   });
  // };

  return (
    <div className="edit-module individual-edit">
      <div className="top">
        <div className={"top-choice " + (screen === "image" ? "active " : "")} onClick={() => setScreen("image")}>
          IMAGE
        </div>
      </div>
      <div className="content">
        {/* {uploaded === 100 ? (
            <Dropzone onDrop={(e)=>{uploadFiles(e); setDraggingOverUpload(false);}} onDragEnter={()=>{setDraggingOverUpload(true)}} onDragLeave={()=>{setDraggingOverUpload(false)}}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      accept=".jpg,.jpeg,.png,.gif,.mp4"
                      maxLength={1}
                    />
                    <div className="upload-or-find-image">
                      <div className={"big-box" + (draggingOverUpload ? " drag-over" : "")}>
                        <FontAwesomeIcon icon={faArrowToTop} />
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          ) : (
            <div className="upload-or-find-image">
              <div className="big-box">
                <div className="upload-bar">
                  <div
                    className="progress-bar"
                    style={{
                      width: uploaded + "%",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="upload-or-find-image choose-media" onClick={()=>{
            // props.setModuleKeyInEdit("medium");
            props.openOverlay("mediaChoose", {
              filter: { mediaType: ["images", "videos"] },
              singleMedia: true,
            },props.index,"medium");
            props.close();
          }}>
            <div className="text">UPLOAD OR FIND IMAGE</div>
          </div> */}
        <UploadOrFindImage
          index={props.index}
          siteId={props.siteId}
          close={props.close}
          openOverlay={props.openOverlay}
          afterUpload={(response) => {
            // let copyValues = cloneDeep(values);

            // copyValues.media.id = response.data[0].id;
            // copyValues.media.src = response.data[0].medium.large;

            // setValues(copyValues);

            props.openOverlay(
              "mediaChoose",
              {
                filter: { mediaType: ["images", "videos"] },
                singleMedia: true,
                selectedMedias: response.data.map((e) => e.id),
              },
              props.index,
              "medium"
            );
            props.close();
          }}
        />
        <div className="option">
          <div className="label">Title and description</div>
          <div className="value">
            <Select
              options={titleAndDescriptionOptions}
              value={titleAndDescriptionOptions.find((e) => e.value === values.title_and_description)}
              onChange={(e) => setValues({ ...values, title_and_description: e.value })}
            />
          </div>
        </div>
        <div className="option">
          <div className="label">Link</div>
          <div className="value">
            <Select
              options={linkOptions}
              value={linkOptions.find((e) => e.value === values.link_option)}
              onChange={(e) => setValues({ ...values, link_option: e.value })}
            />
          </div>
        </div>
        {/* <div className="option">
            <div className="label">Region</div>
            <div className="value">
              <Select
                options={regionOptions}
                value={regionOptions.find((e) => e.value === values.region)}
                onChange={(e) => setValues({ ...values, region: e.value })}
              />
            </div>
          </div> */}
      </div>
    </div>
  );
}
