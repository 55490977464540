import { useState } from "react";
import "../EditProduct.scss";
import Button from "src/Components/Buttons/Button";
import Preloader from "src/assets/Preloader";
import axios from "axios";
import env from "../../../../../environment.json";

/**
 * General info, such as name and description
 *
 * @param 		{object} 		product 										Product state
 * @param 		{function} 	setProduct 									Set product state
 * @param 		{function} 	reload_products 						Reload the products
 *
 * @returns 	{jsx} 																	General info form part
 *
 * @author 					Pætur Mortensen
 */
export default function GeneralInfo({ product, setProduct, reload_products }) {
  const [generalInfo, setGeneralInfo] = useState({
    name: product.name,
    description: product.description,
  });
  // Whether we are loading
  const [isLoading, setIsLoading] = useState(false);
  // Hold the description here, since the contenteditable field does not like state changes
  let description = "";

  // If input values differ from original product values, we can update
  const hasChanged = generalInfo.name !== product.name || generalInfo.description !== product.description;

  /**
   * Send request to server to update general info
   *
   * @author 					Pætur Mortensen
   */
  function update_data() {
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/shop/AdminSetProductGeneralInfo", {
        ...generalInfo,
        productID: product.productID,
      })
      .then((response) => {
        // Set productwith response from server
        setProduct({
          ...product,
          name: response.data.name,
          description: response.data.description,
        });
        setIsLoading(false);
        reload_products();
      })
      .catch(function (err) {
        console.error(err);
        setIsLoading(false);
      });
  }

  return (
    <div className="general-information">
      <h3>General information</h3>
      <div className="name">
        <label>Name</label>
        <input
          onChange={(e) => {
            setGeneralInfo({ ...generalInfo, name: e.target.value });
          }}
          value={generalInfo.name}
        />
      </div>
      <div className="description">
        <label>Description</label>
        <div
          onInput={(e) => {
            description = e.currentTarget.innerHTML;
          }}
          onBlur={() => {
            // Set the value on blur, since contenteditable does not like state changes
            setGeneralInfo({ ...generalInfo, description });
          }}
          suppressContentEditableWarning={true}
          className="div-input"
          contentEditable={true}
          dangerouslySetInnerHTML={{ __html: generalInfo.description }}
        ></div>
        <div className="general-info-cp">
          <Preloader show={isLoading} />
          {hasChanged && (
            <Button
              type="secondary"
              onClick={() => {
                // console.log(
                //   "setting:",
                //   product.name,
                //   " description: ",
                //   product.description
                // );
                setGeneralInfo({
                  name: product.name,
                  description: product.description,
                });
              }}
            >
              Reset
            </Button>
          )}
          <Button disabled={!hasChanged} onClick={update_data}>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
}
