import WhatsonSessionEditView from "src/Components/Content/WhatsonDisplay/WhatsonSessionEditView";
import { getWhatsonStore } from "../../stores/whatson-manager-store/WhatsonManagerStore";
import axios from "axios";
import { useRouterStore } from "src/Components/Router/routerStore";
import env from "src/environment.json";

export default function SessionTimerForStorage() {
  const { session, setSessionSlice, id, type } = getWhatsonStore();
  const { sessionId, timestamp, MAX_AGE } = session;
  const { setRouterStore } = useRouterStore();

  if (!sessionId || sessionId === -1) return <></>;

  return (
    <WhatsonSessionEditView
      sessionStarted={timestamp}
      sessionMaxAge={MAX_AGE}
      iNeedMoreTimeClick={() =>
        axios
          .post(env.protocol + env.env + "/api/secured/whatson/SessionResetForEdit.php", {
            id: id,
            type: type,
          })
          .then((response) => {
            const data = response.data;
            setSessionSlice({ session: { ...session, timestamp: data.timestamp, MAX_AGE: data.MAX_AGE } });
          })
          .catch(console.error)
      }
      onSessionTimeOut={() =>
        setRouterStore({ route_intercept: false }, () => useRouterStore.getState().navByLink("whatson/events"))
      }
      typeName={type}
    />
  );
}
