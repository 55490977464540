import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import Layout from "../Layout";
import styles from "./Media.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle, faX, faXmark } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import UploadMedia from "src/Components/Content/WhatsonDisplay/common/media/upload-media/UploadMedia";
import TextInput from "src/Components/ui/input/TextInput/TextInput";
import InstagramInWhatson from "src/assets/InstagramInWhatson";
import { Instagram } from "./components/instagram";
import { useEffect } from "react";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";

/**
 * Main component - Media
 */
export default function Media() {
  /**
   * Store properties
   */
  const { isTablet, validateMedia } = getWhatsonStore();

  useEffect(() => {
    // return () => {
    //   /**
    //    * Validate the media on unmount
    //    */
    //   validateMedia();
    // };
  }, []);

  /**
   * Render
   */
  return (
    <Layout
      title="Media"
      subtitle={
        <div style={{ display: "flex", gap: "10px", height: "fit-content" }}>
          <>
            Upload a high-quality photo (1920x1080px is a good size) without text or logos. <br />
            Tip: Choose darker photos to ensure clear visibility of event details.
          </>
          {/* <GlobalToolTipController
            click={isTablet()}
            solid
            title={""}
            className={styles.tooltipIcon}
            priority={"left"}
            toolTipElements={
              <div className={"tooltip-card"}>
                The first image will appear in the overview. Any additional images will appear on the event page. You
                can drag them in the order you prefer.
              </div>
            }
            style={{ height: "fit-content" }}
          >
            <FontAwesomeIcon icon={faQuestionCircle as IconProp} />
          </GlobalToolTipController> */}
        </div>
      }
      customSubtitleStyle={{ lineHeight: "27px" }}
    >
      {/* Content */}
      <div className={styles.content}>
        {/* Media Section */}
        <MediaSection />
        {/* Optional Content Section */}
        <OptionalContentSection />
        {/* Instagram Section */}
        <InstagramSection />
      </div>
    </Layout>
  );
}

/**
 * Media section
 */
function MediaSection() {
  /**
   * Store properties
   */
  const { internal, enabled, media_elements, setMediaElements, mediaOverlayUpload } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <>
      <div className={styles.mediaContainer}>
        {/* Upload Media */}
        <UploadMedia
          default_elements={media_elements}
          onElementsChange={(elements) => {
            setMediaElements(elements);
          }}
          // Auth handles wether we should open the media overlay or just a file explorer.
          auth={{
            status: internal,
            call: (setMediaElements) => {
              mediaOverlayUpload(setMediaElements);
            },
          }}
          languages_enabled={enabled}
        />
      </div>
      <div className={styles.seperator} />
    </>
  );
}

/**
 * Optional content section
 */
function OptionalContentSection() {
  /**
   * Store properties
   */
  const { type, optional_media_content, setOptionalMediaContent } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <>
      <div className={styles.optionalContent}>
        <div className={styles.title}>Optional content</div>
        <div className={styles.examples}>Youtube, vimeo or soundcloud</div>
        <div className={styles.text}>
          Additional media will appear together with your images and below the {type} description.
        </div>
        {/* Map Optional Media */}
        {optional_media_content.map((content, index) => {
          return (
            <div key={`${index}-optional-media`} className={styles.optionalContentInput}>
              <TextInput
                key={index}
                value={content}
                id="optional-url"
                containerStyle={styles.inputContainer}
                onChange={(e) => {
                  let newOptionalMediaContent = [...optional_media_content];
                  newOptionalMediaContent[index] = e.target.value;
                  setOptionalMediaContent([...newOptionalMediaContent]);
                }}
                placeholder="Put your link here"
              />
              {/* If Optional Media includes more than one element, render the remove buttons */}
              {optional_media_content.length > 1 && (
                <button
                  className={styles.removeButton}
                  onClick={() => {
                    let newOptionalMediaContent = [...optional_media_content];
                    newOptionalMediaContent.splice(index, 1);
                    setOptionalMediaContent([...newOptionalMediaContent]);
                  }}
                >
                  <FontAwesomeIcon size="sm" icon={faXmark as IconProp} />
                </button>
              )}
            </div>
          );
        })}
        {/* Add more button */}
        {optional_media_content.some((el) => {
          return el.length > 0;
        }) && (
          <button
            className={styles.addButton}
            onClick={() => {
              setOptionalMediaContent([...optional_media_content, ""]);
            }}
          >
            <div className={styles.text}>Add more</div>
            <FontAwesomeIcon size="sm" icon={faPlus as IconProp} />
          </button>
        )}
      </div>
      <div className={styles.seperator} />
    </>
  );
}

/**
 * Instagram section
 */
function InstagramSection() {
  /**
   * Render
   */
  return (
    <div className={styles.instagramContainer}>
      <Instagram />
    </div>
  );
}
