import { language2short } from "src/utils/systemLanguageUtils";
import { TLanguageObj } from "./TLanguage";
import languageObj from "./language.json";
import { TSystemLanguage } from "src/types/types/TSystemLanguage";
import { TWhatsonType } from "src/types/types/Whatson";
import Goose from "../../../assets/images/Goose";
import styles from "./SubmitEntity.module.scss";
import { useUserStore } from "src/Components/ZustandStores/userStore";
import { usePermCheckStore } from "src/Components/PermCheck/PermCheckStore";
import { useWhatsonStore } from "src/Components/ZustandStores/whatsonStore";
import OptionsList from "src/assets/OptionsList";
import { TWhatsonLangsPartialObj, WHATSON_LANGUAGE } from "src/types";
import { MouseEventHandler, useEffect, useState } from "react";
import { IPosition } from "src/assets/OptionsList/OptionsList";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import { useRouterStore } from "src/Components/Router/routerStore";
import { TOptions } from "src/assets/OptionsList/types/types";
import axios from "axios";
import env from "src/environment.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { previewSingleWhatson } from "./getSingleWhatson";

interface props {
  type: TWhatsonType;
  language: TSystemLanguage;
  internal?: boolean;
  whatsonReferId?: number;
  hasAutoApproval: boolean;
  hideNavigationButtons?: boolean;
  indexChange: (i: number) => void;
  enableEdit?: () => void;
  // previewSlugs?: TWhatsonLangsPartialObj<string>;
  id?: number;
}
export default function SuccessView({
  type,
  internal = false,
  language,
  whatsonReferId,
  hasAutoApproval,
  hideNavigationButtons = false,
  indexChange,
  enableEdit = () => {},
  id,
}: props) {
  const [previewLangListIsOpen, setPreviewLangListIsOpen] = useState(false);
  const [previewLangListPos, setPreviewLangListPos] = useState<IPosition>();
  const [previewSlugs, setPreviewSlugs] = useState<TWhatsonLangsPartialObj<string>>({ en: undefined, fo: undefined });
  const [previewIsLoading, setPreviewIsLoading] = useState<boolean>(true);

  const { getWhatsonSiteInfo } = useWhatsonStore();

  const languageText: TLanguageObj = languageObj;
  const shortLang = language2short(language);

  const { loggedInUser } = useUserStore();
  const { permCheckFunc } = usePermCheckStore();
  const isLoggedIn = loggedInUser.userId !== undefined;
  // const showEdit = permCheckFunc({
  //   type: whatsonType2PermissionType(type),
  //   minimumRole: "Publisher",
  //   id: whatsonReferId,
  // });

  useEffect(() => {
    const previewLinks = {
      event: "/api/public/whatson/events/GetEvent.php",
      place: "/api/public/whatson/places/GetPlace.php",
      tour: "/api/public/whatson/tours/GetTour.php",
      tour_provider: "/api/public/whatson/touroperators/GetTourOperator.php",
      hike: "/api/public/whatson/hiking/GetHiking.php",
    };

    axios.get(env.protocol + env.env + previewLinks[type] + "?id=" + id).then((response) => {
      const textObj = response.data.text;
      if (textObj) {
        const enObj = textObj.english;
        const foObj = textObj.faroese;
        const showEn = enObj.show_it || enObj.showIt;
        const showFo = foObj.show_it || foObj.showIt;
        setPreviewSlugs({
          en: showEn ? enObj.url_slug || enObj.urlSlug : undefined,
          fo: showFo ? foObj.url_slug || foObj.urlSlug : undefined,
        });
      }
      setPreviewIsLoading(false);
    });
  }, [type, id]);

  const previewClick = (lang: WHATSON_LANGUAGE) => {
    previewSingleWhatson({ type, lang, previewSlugs });
  };

  const openPreviewOptionsList: MouseEventHandler<HTMLButtonElement> = (event) => {
    setPreviewLangListIsOpen(true);
    setPreviewLangListPos({ x: event.clientX, y: event.clientY });
  };

  const optionsListOptions: TOptions = [];
  if (previewSlugs?.en) {
    optionsListOptions.push({ is: "choice", onClick: () => previewClick("en"), text: "EN", icon: faFile });
  }
  if (previewSlugs?.fo) {
    optionsListOptions.push({ is: "choice", onClick: () => previewClick("fo"), text: "FO", icon: faFile });
  }

  return (
    <div className={styles["success-view"]}>
      <h3>
        <b>{languageText[type].success_header[shortLang]}</b>
      </h3>
      <p className="register-sub-header">
        {hasAutoApproval
          ? whatsonReferId
            ? languageText[type].success_description_updated[shortLang]
            : languageText[type].success_description_published[shortLang]
          : languageText[type].success_description_awainting_approval[shortLang]}
      </p>
      <div className={styles["goose-image"]}>
        <img src={Goose.thanksx2} />
      </div>
      {!hideNavigationButtons && (
        <div className={styles["complete-buttons"]}>
          {isLoggedIn && internal ? (
            <>
              {hasAutoApproval && (
                <button
                  className={styles["edit"]}
                  onClick={() => {
                    enableEdit();
                    indexChange(-9999);
                  }}
                >
                  Edit
                </button>
              )}
              <button
                className={previewIsLoading ? styles["gray"] : styles["blue"]}
                onClick={openPreviewOptionsList}
                disabled={previewIsLoading}
              >
                {!previewIsLoading ? (
                  `Preview ${type.replaceAll("_", " ")}`
                ) : (
                  <FontAwesomeIcon icon={faSpinner as IconProp} spin />
                )}
              </button>
            </>
          ) : (
            <>
              <button
                className={styles["gray"]}
                onClick={() => {
                  const get_parameters = useRouterStore.getState().get_parameters;

                  if (get_parameters?.returnUrl) window.location.href = get_parameters.returnUrl;
                }}
              >
                Back to What's On
              </button>
              <button
                className={styles["blue"]}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Submit new {type.replaceAll("_", " ")}
              </button>
            </>
          )}
        </div>
      )}
      <OptionsList
        isOpen={previewLangListIsOpen}
        close={() => setPreviewLangListIsOpen(false)}
        options={optionsListOptions}
        position={previewLangListPos}
      />
    </div>
  );
}
