import { ILocation } from "./LocationStore";

export function ValidateLocation(location: ILocation): {
  valid: boolean;
  location: ILocation;
} {
  let new_location = { ...location };
  let valid = true;

  if (!location.custom) {
    if (location.place === null) {
      valid = false;
    }
  } else {
    if (location.address === null) {
      valid = false;
    }
    if (location.region === null) {
      valid = false;
    }
    if (location.latitude === null) {
      valid = false;
    }
    if (location.longitude === null) {
      valid = false;
    }
    if (location.zip.value === null) {
      valid = false;
    }
    if (location.region.value === null) {
      valid = false;
    }
    if (location.nameOfLocation === null || location.nameOfLocation === "") {
      valid = false;
    }
  }

  return {
    valid: valid,
    location: { ...new_location },
  };
}
