import React, { useState } from "react";
import { useEffect } from "react";

import "./infoscreenSidebar.scss";
import CircleBtn from "../../Buttons/CircleBtn";
import InfoscreenOptionsBtn from "./InfoscreenOptionsBtn";

import env from "../../../environment.json";
import axios from "axios";
import OptionsList from "../../../assets/OptionsList/OptionsList";
import { faPen, faTrashAlt, faLink, faFrame } from "@fortawesome/pro-light-svg-icons";

export default function InfoscreenSidebar({ infoscreenMenu, setInfoscreenMenu }) {
  const [infoscreens, setInfoscreens] = useState([]);
  // const [selectedInfoscreen, setSelectedInfoscreen] = useState(infoscreenMenu);

  const [listIsOpen, setListIsOpen] = useState(false);
  const [coord, setCoord] = useState({ x: 0, y: 0 });
  const [editScreenData, setEditScreenData] = useState(null);
  const [editNameView, setEditNameView] = useState(null);
  const [editName, setEditName] = useState("");

  // const optionsList = [
  //   {
  //     is: "choice",
  //     icon: faLink,
  //     text: 'Copy URL',
  //     onClick: () => {
  //       navigator.clipboard.writeText(`https://infoscreen-dzrms.ondigitalocean.app/?infoscreenID=${selectedInfoscreen}`);
  //     }
  //   },
  //   {
  //     is: "hr",
  //   },
  //   {
  //     is: "choice",
  //     icon: faPen,
  //     text: 'Edit name',
  //     onClick: () => {
  //       setEditName(editScreenData.name)
  //       setEditNameView(editScreenData.id)
  //     }
  //   },
  //   {
  //     is: "choice",
  //     icon: faFrame,
  //     text: 'Edit order',
  //     onClick: () => {
  //       const url = `https://infoscreen-dzrms.ondigitalocean.app/?infoscreenID=${selectedInfoscreen}&token=7fd5b5eb-a2a5-43e9-832d-f013e380c61f`;
  //       window.open(url, '_blank', 'noopener,noreferrer');
  //     }
  //   },
  //   {
  //     is: "hr",
  //   },
  //   {
  //     is: "choice",
  //     icon: faTrashAlt,
  //     text: "Delete screen",
  //     onClick: () => {
  //       deleteInfoscreen(editScreenData.id, editScreenData.name);
  //     },
  //     style: {
  //       color: "#F64E60",
  //     },
  //   }
  // ];

  // useEffect(() => {
  //   axios
  //     .get(env.protocol + env.env + '/api/public/infoscreen/GetInfoscreens.php')
  //     .then((response) => {
  //       setInfoscreens(response.data);
  //       setSelectedInfoscreen(response.data[0].id);
  //       // setInfoscreenMenu(response.data[0].id);
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  // useEffect(() => {
  //   if (!listIsOpen && !editNameView) setEditScreenData(null);
  // }, [listIsOpen, editNameView]);

  // useEffect(() => {
  //   setEditNameView(null)
  //   setEditName('')
  // }, [selectedInfoscreen]);

  const createInfoscreen = () => {
    axios
      .get(env.protocol + env.env + "/api/secured/infoscreen/createInfoscreen")
      .then((response) => {
        setInfoscreens((infoscreens) => [...infoscreens, response.data]);
      })
      .catch((error) => console.error(error));
  };

  const deleteInfoscreen = (id, name) => {
    axios
      .post(env.protocol + env.env + "/api/secured/infoscreen/deleteInfoscreen", {
        id: id,
        name: name,
      })
      .then((response) => {
        setInfoscreens(response.data);
      })
      .catch((error) => console.error(error));
  };

  const updateInfoscreen = (id, oldName, newName) => {
    axios
      .post(env.protocol + env.env + "/api/secured/infoscreen/updateInfoscreen", {
        id: id,
        oldName: oldName,
        newName: newName,
      })
      .then((response) => {
        setInfoscreens(response.data);
      })
      .catch((error) => console.error(error));
  };

  const handleChange = (event) => {
    setEditName(event.target.value);
  };

  const handleKeyPress = (event) => {
    const compare = editScreenData.name.localeCompare(editName);
    if (event.key === "Enter") {
      if (compare !== 0) {
        updateInfoscreen(editScreenData.id, editScreenData.name, editName);
      }
      setEditNameView(null);
      setEditName("");
    }
    if (event.key === "Escape") {
      setEditNameView(null);
      setEditName("");
    }
  };

  return (
    <div className="infoscreen-sidebar">
      <h1>Infoscreens</h1>
      <div className="list-parent">
        {/* {infoscreens && infoscreens.map((infoscreen) => {
          return (
            <div
              key={`infoscreen-${infoscreen.id}`}
              className={`list-option ${selectedInfoscreen === infoscreen.id ? 'selected' : ''
                }`}
              onClick={() => {
                setSelectedInfoscreen(infoscreen.id);
                setInfoscreenID(infoscreen.id);
              }}
            >
              {editNameView === infoscreen.id &&
                <input
                  type='text'
                  id='name'
                  onChange={handleChange}
                  onKeyUpCapture={handleKeyPress}
                  value={editName}
                >

                </input>}
              {editNameView !== infoscreen.id && infoscreen.name}
              <InfoscreenOptionsBtn
                onClick={(e) => {
                  setCoord({ x: e.pageX, y: e.pageY })
                  setListIsOpen(true);
                  setEditScreenData(infoscreen);
                }}
              />
            </div>
          );
        })} */}
        <div
          className={`list-option ${infoscreenMenu === "articles" ? "selected" : ""}`}
          onClick={() => {
            setInfoscreenMenu("articles");
          }}
        >
          Infoscreen Articles
          {/* <InfoscreenOptionsBtn
            onClick={(e) => {
              setCoord({ x: e.pageX, y: e.pageY })
              setListIsOpen(true);
              setEditScreenData(infoscreen);
            }}
          /> */}
        </div>
        <div
          className={`list-option ${infoscreenMenu === "screenManagement" ? "selected" : ""}`}
          onClick={() => {
            setInfoscreenMenu("screenManagement");
          }}
        >
          Screen Management
        </div>
      </div>
      {/* <OptionsList
        isOpen={listIsOpen}
        close={() => setListIsOpen(false)}
        options={optionsList}
        position={coord}
      />
      <CircleBtn onClick={() => createInfoscreen()} /> */}
    </div>
  );
}
