import { TFormValue } from "../types/TFromValue";

export default function FormValue<T>(value: T, label: string = ""): TFormValue<T> {
  return {
    value,
    label,
    error: {
      message: "",
    },
  };
}
