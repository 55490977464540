import { TLanguages } from "../../Steps";
import { getWhatsonStore, useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";
import { IPrice } from "./PriceStore";

/**
 * Validate Price
 */
export function ValidatePrice(
  prices: IPrice[],
  is_free: boolean
): {
  valid: boolean;
  prices: IPrice[];
  is_free: boolean;
} {
  /**
   * Initialise the new prices
   */
  let new_prices = [...prices];
  /**
   * Initialise the valid state
   */
  let valid = true;
  /**
   * Store properties
   */
  const enabled = useWhatsonManager.getState().getStore().getState().enabled;
  /**
   * If free
   */
  if (!is_free) {
    /**
     * Iterate through the prices
     */
    for (let i = 0; i < prices.length; i++) {
      /**
       * If the price is null or empty, set the valid state to false
       */
      if (prices[i].price === null || prices[i].price === "") {
        valid = false;
      }

      /* Validate labels */
      Object.keys(prices[i].label).forEach((key) => {
        /**
         * If the language is enabled and the label is null or empty, set the valid state to false
         */
        if (
          enabled[key as TLanguages] &&
          (prices[i].label[key as TLanguages] === null || prices[i].label[key as TLanguages]?.length === 0)
        ) {
          valid = false;
        }
      });
    }
  }
  /**
   * Return the validity flag and new prices
   */
  return {
    valid: valid,
    prices: [...new_prices],
    is_free: is_free,
  };
}
