import "../Product.scss";

export default function RatingPanel({ productState }) {
  return (
    <div className="rating">
      <h3>Rating</h3>
      <div>
        <label>Rating</label>
        <input
          defaultValue={productState.rating}
          onChange={(e) => {
            productState.rating = e.target.value;
          }}
        />
      </div>
    </div>
  );
}
