import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
import Verify from "../../../../assets/VerifyMethods";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

export default function MapSummaryRow({ parent, showLang, langKey }) {
  const submitChange = parent.props.submitChange === "true";
  const headerKey = langKey === "textEn" ? "mapSummary_en" : "mapSummary_fo";
  const tooltipContent = "Your audience will see this summary of your tour in the map overview.";

  return (
    <div className="map-summary row">
      {submitChange && <BtnSubmitChange parent={parent} langKey={langKey} />}
      <FieldHeader parent={parent} headerKey={headerKey} content={tooltipContent} />
      <MapSummaryInput parent={parent} langKey={langKey} showLang={showLang} />
    </div>
  );
}

/**
 * Input for the map summary
 *
 * @param 			{object} 		parent 										Parent object
 * @param 			{string} 		langKey 									Language key
 * @param 			{boolean} 	showLang 									Whether to show this language
 *
 * @returns 		{JSX.Element}
 */
function MapSummaryInput({ parent, langKey, showLang }) {
  const mapSummaryKey = langKey === "textEn" ? "mapSummary_en" : "mapSummary_fo";
  const submitChange = parent.props.submitChange === "true";

  return (
    <VfiTextarea
      className={"col-xl-9"}
      maxLength={300}
      placeholder={parent.language.info[mapSummaryKey]}
      onChange={(e) => {
        parent.newTour[langKey].mapSummary = e;
        parent.setState({
          beenEdit: {
            ...parent.state.beenEdit,
            [langKey]: {
              ...parent.state.beenEdit[langKey],
              mapSummary: true,
            },
          },
        });
      }}
      value={parent.newTour[langKey].mapSummary}
      errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
      hideError={!parent.state.beenEdit[langKey].mapSummary || !showLang}
      disabled={submitChange ? !parent.submit.includes(`${langKey}.mapSummary`) || !showLang : !showLang}
    />
  );
}

/**
 * Header for a field
 *
 * @param  		{object} 		parent 												Parent object
 * @param 		{string} 		headerKey 										Language key for header
 * @param 		{mixed} 		content 											String or JSX
 *
 * @returns 	{JSX.Element}
 */
function FieldHeader({ parent, headerKey, content }) {
  return (
    <div className="text col-xl-3 with-tooltip">
      {parent.language.info[headerKey]}
      <GlobalToolTipController
        solid
        title={""}
        className={"tootlip-icon"}
        priority={"bottom,right,top,left"}
        toolTipElements={<div className={"tooltip-card"}>{content}</div>}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </GlobalToolTipController>
    </div>
  );
}

/**
 * Submit change button
 *
 * @param 		{object} 		parent 												Parent object
 * @param 		{string} 		langKey 											Language key
 *
 * @returns 	{JSX.Element}
 */
function BtnSubmitChange({ parent, langKey }) {
  const submitChange = parent.props.submitChange === "true";

  return (
    <div
      className={"enable_editing " + (parent.submit.includes(`${langKey}.mapSummary`) && "enabled")}
      onClick={(e) => {
        if (submitChange && !parent.submit.includes(`${langKey}.mapSummary`)) {
          parent.submit.push(`${langKey}.mapSummary`);
        }
        parent.setState({});
      }}
    >
      Enable editing
      <FontAwesomeIcon icon={faPencil} />
    </div>
  );
}
