import { useEffect, useState } from "react";

export function useSessionStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  // Try to get the stored value from sessionStorage
  const storedValue = sessionStorage.getItem(key);

  // Use the stored value if it exists, otherwise use the provided initialValue
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = useState<T>(initial);

  useEffect(() => {
    // Save the current state to sessionStorage whenever it changes
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
