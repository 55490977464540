import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import "./InfoscreenStandardFilters.scss";
import env from "../../../../environment.json";
import { VfiCheckbox2 } from "../../../../assets/VfiCheckbox";
import { cloneDeep, parseInt } from "lodash";

function InfoscreenStandardFilters({ data: d, setData: sD = () => void 0, close = () => void 0 }) {
  const [data, setData] = useState(cloneDeep(d));

  const [regions, setRegions] = useState([]);
  const [eventCategories, setEventCategories] = useState([]);
  const [tourActivities, setTourActivities] = useState([]);
  const [placeCategories, setPlaceCategories] = useState([]);
  useEffect(() => {
    axios
      .get(env.protocol + env.env + "/api/public/GetRegions.php")
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(env.protocol + env.env + "/api/whatson/events/GetEventCategories")
      .then((response) => {
        setEventCategories(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(env.protocol + env.env + "/api/whatson/tours/GetTourActivities")
      .then((response) => {
        setTourActivities(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(env.protocol + env.env + "/api/whatson/places/GetPlaceCategories")
      .then((response) => {
        setPlaceCategories(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const filters = data.filters;

    const dRegions = filters.filter((r) => r.type === "region");
    if (
      regions.every((r) => {
        if (parseInt(r.region_id) === parseInt(data.info.region_id)) return true;
        return dRegions.find((dr) => parseInt(dr.value) === parseInt(r.region_id)) !== undefined;
      }) &&
      dRegions.every((dr) => !dr.disabled) &&
      regions.length !== 0
    ) {
      checkboxChangeAll("region", false);
    }

    const dEventCategories = filters.filter((c) => c.type === "event-category");
    if (
      eventCategories.every((ec) => {
        return dEventCategories.find((dec) => parseInt(dec.value) === parseInt(ec.id)) !== undefined;
      }) &&
      dEventCategories.every((dec) => !dec.disabled) &&
      eventCategories.length !== 0
    ) {
      checkboxChangeAll("event-category", false);
    }

    const dTourActivities = filters.filter((a) => a.type === "tour-activity");
    if (
      tourActivities.every((pc) => {
        return dTourActivities.find((dta) => parseInt(dta.value) === parseInt(pc.id)) !== undefined;
      }) &&
      dTourActivities.every((a) => !a.disabled) &&
      tourActivities.length !== 0
    ) {
      checkboxChangeAll("tour-activity", false);
    }

    const dPlaceCategories = filters.filter((c) => c.type === "place-category");
    if (
      placeCategories.every((pc) => {
        return dPlaceCategories.find((dr) => parseInt(dr.value) === parseInt(pc.id)) !== undefined;
      }) &&
      dPlaceCategories.every((dpc) => !dpc.disabled) &&
      placeCategories.length !== 0
    ) {
      checkboxChangeAll("place-category", false);
    }
  }, [data, regions, placeCategories]);

  const addAFilter = useCallback(
    (type, value) => {
      data.filters.push({ type, value, infoscreen_id: data.info.id });
    },
    [data]
  );
  const findAFilter = useCallback(
    (type, value) => {
      return data.filters.find((filter) => filter.type === type && parseInt(filter.value) === parseInt(value));
    },
    [data]
  );
  const checkboxChange = (type, value, checked) => {
    if (checked) {
      // ADD
      const filter = findAFilter(type, value);
      if (filter) {
        filter.disabled = false;
      } else {
        addAFilter(type, value);
      }
    } else {
      // REMOVE
      const filter = findAFilter(type, value);
      filter.disabled = true;
    }
    setData({ ...data });
  };

  const checkboxChangeAll = (type, checked) => {
    if (checked) {
      const list =
        type === "region"
          ? regions
          : type === "event-category"
          ? eventCategories
          : type === "tour-activity"
          ? tourActivities
          : type === "place-category"
          ? placeCategories
          : [];

      const valueKey =
        type === "region"
          ? "region_id"
          : type === "event-category"
          ? "id"
          : type === "tour-activity"
          ? "id"
          : type === "place-category"
          ? "id"
          : [];

      for (let i = 0; i < list.length; i++) {
        if (type === "region" && parseInt(data.info.region_id) === parseInt(list[i].region_id)) {
          continue;
        }

        const item = findAFilter(type, list[i][valueKey]);
        if (item !== undefined) {
          item.disabled = true;
        } else {
          // addAFilter(type, list[i][valueKey]);
          // Do nothing
        }
      }
    } else {
      for (let i = 0; i < data.filters.length; i++) {
        if (data.filters[i].type === type) {
          data.filters[i].disabled = !checked;
        }
      }
    }
    setData({ ...data });
  };

  const save = useCallback(() => {
    const saveData = {
      filters: data.filters,
      infoscreenID: data.info.id,
      user: sessionStorage.getItem("vfiUser"),
    };
    axios
      .post(env.protocol + env.env + "/api/secured/infoscreen/saveAllInfoscreenFilters", saveData)
      .then(() => {
        close(data);
      })
      .catch((error) => console.error(error));
  }, [data]);

  return (
    <div className="infoscreen-standard-filters">
      <header>
        <div
          className="close"
          onClick={() => {
            close();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="title">{data.info.name}</div>
        <div className="final-options">
          <button className="cancel" onClick={() => close()}>
            Cancel
          </button>
          <button className="save" onClick={() => save()}>
            Save
          </button>
        </div>
      </header>
      <div className="isf-content">
        <h2>Define standard filters</h2>
        <p className="details">Choose the categories that will be shown on the infoscreen</p>
        <div className="list-containers">
          <div className="list regions">
            <div className="list-title">REGIONS</div>
            <div className={"choice region select-all"}>
              <label>
                <VfiCheckbox2
                  className="choice-check"
                  onChange={(e) => {
                    checkboxChangeAll("region", e.target.checked);
                  }}
                  checked={
                    regions.every((region) => {
                      if (parseInt(data.info.region_id) === parseInt(region.region_id)) {
                        return true;
                      } else {
                        return data.filters.some(
                          (filter) =>
                            filter.type === "region" &&
                            parseInt(region.region_id) === parseInt(filter.value) &&
                            filter.disabled
                        );
                      }
                    }) || data.filters.filter((f) => f.type === "region").every((f) => f.disabled)
                  }
                />
                <span className="text">All regions</span>
              </label>
            </div>
            {regions.map((region) => {
              const isLocatedInRegion = data.info.region_id === parseInt(region.region_id);
              const filter = findAFilter("region", region.region_id);
              return (
                <div className={"choice region" + (isLocatedInRegion ? " disabled" : "")} key={region.id}>
                  <label>
                    <VfiCheckbox2
                      className="choice-check"
                      onChange={(e) => {
                        checkboxChange("region", region.region_id, e.target.checked);
                      }}
                      disabled={isLocatedInRegion}
                      checked={(filter !== undefined && !filter.disabled) || isLocatedInRegion}
                    />
                    <span className="text">{region.region_name}</span>
                    {isLocatedInRegion && (
                      <span className="region-located-here-info">Screen is located in this region</span>
                    )}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="list event-categories">
            <div className="list-title">EVENT CATEGORIES</div>
            <div className={"choice event-category select-all"}>
              <label>
                <VfiCheckbox2
                  className="choice-check"
                  onChange={(e) => {
                    checkboxChangeAll("event-category", e.target.checked);
                  }}
                  checked={
                    eventCategories.every((eventCategory) => {
                      return data.filters.some(
                        (filter) =>
                          filter.type === "event-category" &&
                          parseInt(eventCategory.id) === parseInt(filter.value) &&
                          filter.disabled
                      );
                    }) || data.filters.filter((f) => f.type === "event-category").every((f) => f.disabled)
                  }
                />
                <span className="text">Select all categories</span>
              </label>
            </div>
            {eventCategories.map((eventCategory) => {
              const filter = findAFilter("event-category", eventCategory.id);
              return (
                <div key={eventCategory.id} className={"choice event-category"}>
                  <label>
                    <VfiCheckbox2
                      className="choice-check"
                      onChange={(e) => {
                        checkboxChange("event-category", eventCategory.id, e.target.checked);
                      }}
                      checked={filter !== undefined && !filter.disabled}
                    />
                    <span className="text">{eventCategory.category_en}</span>
                  </label>
                </div>
              );
            })}
          </div>
          <div className="list tour-activites">
            <div className="list-title">TOUR ACTIVITES</div>
            <div className={"choice tour-activity select-all"}>
              <label>
                <VfiCheckbox2
                  className="choice-check"
                  onChange={(e) => {
                    checkboxChangeAll("tour-activity", e.target.checked);
                  }}
                  checked={
                    tourActivities.every((tourActivity) => {
                      return data.filters.some(
                        (filter) =>
                          filter.type === "tour-activity" &&
                          parseInt(tourActivity.id) === parseInt(filter.value) &&
                          filter.disabled
                      );
                    }) || data.filters.filter((f) => f.type === "tour-activity").every((f) => f.disabled)
                  }
                  // checked={true}
                />
                <span className="text">Select all tour activites</span>
              </label>
            </div>
            {tourActivities.map((tourActivity) => {
              const filter = findAFilter("tour-activity", tourActivity.id);
              return (
                <div key={tourActivity.id} className={"choice tour-activity"}>
                  <label>
                    <VfiCheckbox2
                      className="choice-check"
                      onChange={(e) => {
                        checkboxChange("tour-activity", tourActivity.id, e.target.checked);
                      }}
                      checked={filter !== undefined && !filter.disabled}
                    />
                    <span className="text">{tourActivity.english}</span>
                  </label>
                </div>
              );
            })}
          </div>
          <div className="list place-categories">
            <div className="list-title">PLACE CATEGORIES</div>
            <div className={"choice place-categories select-all"}>
              <label>
                <VfiCheckbox2
                  className="choice-check"
                  onChange={(e) => {
                    checkboxChangeAll("place-category", e.target.checked);
                  }}
                  checked={
                    placeCategories.every((placeCategory) => {
                      return data.filters.some(
                        (filter) =>
                          filter.type === "place-category" &&
                          parseInt(placeCategory.id) === parseInt(filter.value) &&
                          filter.disabled
                      );
                    }) || data.filters.filter((f) => f.type === "place-category").every((f) => f.disabled)
                  }
                />
                <span className="text">Select all places</span>
              </label>
            </div>
            {placeCategories.map((placeCategory) => {
              const filter = findAFilter("place-category", placeCategory.id);
              return (
                <div key={placeCategory.id} className={"choice place-category"}>
                  <label>
                    <VfiCheckbox2
                      className="choice-check"
                      onChange={(e) => {
                        checkboxChange("place-category", placeCategory.id, e.target.checked);
                      }}
                      checked={filter !== undefined && !filter.disabled}
                    />
                    <span className="text">{placeCategory.category_en}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoscreenStandardFilters;
