import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPlus, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Select from "react-select";
import "./Prices.scss";
import { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import PropertiesSelect from "./PropertiesSelect";
import axios from "axios";
import env from "../../../../../environment.json";

export default function Prices(props) {
  const { configuration, findex, setForceUpdate, updateConf, productTypeuuid, disabledFilters } = props;
  const [openPrice, setOpenPrice] = useState(false);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const rateType = [
    { label: "Fixed", value: "fixed" },
    { label: "Percent", value: "percent" },
  ];
  useEffect(() => {
    axios
      .post(env.protocol + env.env + "/api/public/shop/getPropertyOptions.php")
      .then((response) => {
        setPropertyOptions(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const brands = [];
  const products = [];
  const varients = [];
  const properties = [];
  configuration.physical[findex].brands
    .filter((x) => !x.deleted)
    .forEach((element) => {
      let branduuid = element.uuid;
      brands.push({
        label: element.label,
        value: element.uuid,
        parentuuid: "",
      });

      if (!element.products) {
        element.products = [];
      }

      element.products
        .filter((x) => !x.deleted)
        .forEach((element) => {
          let productuuid = element.uuid;
          products.push({
            label: element.label,
            value: productuuid,
            parentuuid: branduuid,
          });

          if (!element.varients) {
            element.varients = [];
          }

          element?.varients
            .filter((x) => !x.deleted)
            .forEach((element) => {
              let varientuuid = element.uuid;
              varients.push({
                label: element.label,
                value: varientuuid,
                parentuuid: productuuid,
              });

              if (!element.properties) {
                element.properties = [];
              }

              element.properties
                .filter((x) => !x.deleted)
                .forEach((element) => {
                  let propertyuuid = element.uuid;
                  if (!disabledFilters.some((x) => x === element.label)) {
                    properties.push({
                      label: element.label + "-" + element.value,
                      value: propertyuuid,
                      parentuuid: varientuuid,
                    });
                  }
                });
            });
        });
    });

  const add = (type, brandIndex, productIndex, varientIndex) => {
    return (
      <div className="wrap-add">
        <div
          onClick={() => {
            updateConf.prices.push({
              productTypeuuid: productTypeuuid,
              rate: "",
              uuid: uuid(),
              rateType: { label: "Fixed", value: "fixed" },
              label: "",
              new: true,
              brand: {},
              product: {},
              varient: {},
              property: {},
              order_value: -1,
            });
            setForceUpdate((f) => !f);
          }}
          className="add"
        >
          <FontAwesomeIcon icon={faPlus} />
          Add {type}
        </div>
      </div>
    );
  };

  const compare = (a, b) => {
    if (a.label < b.label) {
      return 1;
    }
    if (a.label > b.label) {
      return -1;
    }
    return 0;
  };

  /**
   * Returns a single price rule form
   *
   * @param 		{object}	element 						Pricing element (one price rule)
   *
   * @returns 	jsx 													Price rule form
   */
  const prices = (element) => {
    if (element.id === 19) {
    }

    let newBrands = cloneDeep(brands);
    let newProducts = cloneDeep(products);
    let newVarients = cloneDeep(varients);
    // TODO DEPRECATE
    // let newProperty = cloneDeep(properties);

    // TODO DEPRECATE
    // if (element.property.value) {
    // 	newVarients = [];
    // 	newProducts = [];
    // 	newBrands = [];
    // 	newVarients = varients.filter(
    // 		(x) => x.value === element.property.parentuuid
    // 	);
    // 	newVarients.forEach((elm) => {
    // 		newProducts = [
    // 			...newProducts,
    // 			...products.filter((x) => x.value === elm.parentuuid),
    // 		];
    // 		newProducts.forEach((elm) => {
    // 			newBrands = [
    // 				...newBrands,
    // 				...brands.filter((x) => x.value === elm.parentuuid),
    // 			];
    // 		});
    // 	});
    // }

    // If varient value has been set, we limit the products and brands options
    if (element.varient.value) {
      newProducts = [];
      newBrands = [];
      newProducts = [...newProducts, ...products.filter((x) => x.value === element.varient.parentuuid)];
      newProducts.forEach((elm) => {
        newBrands = [...newBrands, ...brands.filter((x) => x.value === elm.parentuuid)];
      });
    }

    // If product value has been set, we limit the options for the brand select
    if (element.product.value) {
      newBrands = [];
      newBrands = [...newBrands, ...brands.filter((x) => x.value === element.product.parentuuid)];
      newVarients = newVarients.filter((x) => x.parentuuid === element.product.value);
    }

    // If brand value has been set, we limit the number of product options
    if (element.brand.value) {
      // TODO DEPRECATE
      // newProperty = [];
      newProducts = newProducts.filter((x) => x.parentuuid === element.brand.value);
    }

    // TODO DEPRECATE
    // If product value has been set, we limit the number of variant options
    // if (element.product.value) {

    // TODO DEPRECATE
    // newVarients.forEach((elm) => {
    // 	newProperty = properties.filter((x) => x.parentuuid === elm.value);
    // });
    // }

    // TODO DEPRECATE
    // If variant value has been set, we limit the number of property values....
    // if (element.varient.value) {
    // 	newProperty = newProperty.filter(
    // 		(x) => x.parentuuid === element.varient.value
    // 	);
    // }

    // TODO DEPRECATE
    // newProperty = [
    // 	...new Map(newProperty.map((item) => [item["label"], item])).values(),
    // ];
    // newProperty.sort(compare);
    return [
      <div
        key="remove"
        className="remove"
        onClick={() => {
          element.deleted = true;
          let findex = updateConf.prices.findIndex((x) => x.uuid === element.uuid);
          if (findex === -1) {
            updateConf.prices.push(element);
          } else {
            updateConf.prices[findex] = element;
          }
          setForceUpdate((f) => !f);
        }}
      >
        <FontAwesomeIcon color="red" icon={faTrashAlt} />
      </div>,
      <div key={element.uuid}>
        <div>
          <label>Name</label>
          <input
            defaultValue={element.label}
            onChange={(e) => {
              element.update = true;
              element.label = e.target.value;
              let findex = updateConf.prices.findIndex((x) => x.uuid === element.uuid);

              if (findex === -1) {
                updateConf.prices.push(element);
              } else {
                updateConf.prices[findex] = element;
              }
            }}
          />
        </div>
        <div className="rate">
          <label>Rate</label>
          <input
            defaultValue={element.rate}
            onChange={(e) => {
              element.update = true;
              element.rate = e.target.value;
              let findex = updateConf.prices.findIndex((x) => x.uuid === element.uuid);
              if (findex === -1) {
                updateConf.prices.push(element);
              } else {
                updateConf.prices[findex] = element;
              }
            }}
          />
        </div>
        <div>
          <label>Rate Type</label>
          <Select
            className="select"
            defaultValue={element.rateType}
            options={rateType}
            onChange={(e) => {
              element.update = true;
              element.rateType = e;
              let findex = updateConf.prices.findIndex((x) => x.uuid === element.uuid);
              if (findex === -1) {
                updateConf.prices.push(element);
              } else {
                updateConf.prices[findex] = element;
              }
            }}
          />
        </div>
        <div className="w25">
          <label>
            Brands <i>Optional</i>
          </label>
          <Select
            className="select"
            isClearable={true}
            defaultValue={element.brand.value && element.brand}
            options={newBrands}
            onChange={(e) => {
              element.update = true;
              if (e === null) {
                element.brand = { label: "", value: "", uuid: "" };
                element.branduuid = null;
              } else {
                element.brand = e;
                element.branduuid = e.value;
              }
              let findex = updateConf.prices.findIndex((x) => x.uuid === element.uuid);
              if (findex === -1) {
                updateConf.prices.push(element);
              } else {
                updateConf.prices[findex] = element;
              }
              setForceUpdate((f) => !f);
            }}
          />
        </div>
        <div className="w25">
          <label>
            Products <i>Optional</i>
          </label>
          <Select
            className="select"
            isClearable={true}
            defaultValue={element.product.value && element.product}
            options={newProducts}
            onChange={(e) => {
              element.update = true;
              if (e === null) {
                element.product = { label: "", value: "", uuid: "" };
                element.productuuid = null;
              } else {
                element.product = e;
                element.productuuid = e.value;
              }
              let findex = updateConf.prices.findIndex((x) => x.uuid === element.uuid);
              if (findex === -1) {
                updateConf.prices.push(element);
              } else {
                updateConf.prices[findex] = element;
              }
              setForceUpdate((f) => !f);
            }}
          />
        </div>
        <div className="w25">
          <label>
            Variants <i>Optional</i>
          </label>
          <Select
            className="select"
            isClearable={true}
            defaultValue={element.varient.value && element.varient}
            options={newVarients}
            onChange={(e) => {
              element.update = true;
              if (e === null) {
                element.varient = { label: "", value: "", uuid: "" };
                element.varientuuid = null;
              } else {
                element.varient = e;
                element.varientuuid = e.value;
              }
              let findex = updateConf.prices.findIndex((x) => x.uuid === element.uuid);
              if (findex === -1) {
                updateConf.prices.push(element);
              } else {
                updateConf.prices[findex] = element;
              }
              setForceUpdate((f) => !f);
            }}
          />
        </div>
        <PropertiesSelect properties={propertyOptions} element={element} updateConf={updateConf} />
      </div>,
    ];
  };
  const setPriceOrder = (element, move) => {
    updateConf.prices = updateConf.prices
      .map((elm, index) => {
        if (elm.id === element.id) {
          if (move > 0) {
            updateConf.prices[index + 1].order_value -= 1;
            updateConf.prices[index + 1].update = true;
          }
          if (move < 0) {
            updateConf.prices[index - 1].order_value += 1;
            updateConf.prices[index - 1].update = true;
          }
          elm.order_value = elm.order_value + move;
          elm.update = true;
        }
        return elm;
      })
      .sort((a, b) => a.order_value - b.order_value);
    setForceUpdate((f) => !f);
  };

  return (
    <div className="confPrices">
      <div key={"prices" + findex} className={"box"}>
        <div className="wrap-top">
          <div>
            <h3>Prices</h3>
            <p>Set global price on product</p>
          </div>
          <div
            className="expandBrand"
            onClick={() => {
              setOpenPrice((f) => !f);
            }}
          >
            <FontAwesomeIcon icon={openPrice ? faChevronUp : faChevronDown}></FontAwesomeIcon>
          </div>
        </div>

        {openPrice && (
          <div>
            {add("price")}
            <div className="wrap-prices">
              {configuration.physical[findex].prices
                .filter((x) => !updateConf.prices.some((z) => z.uuid === x.uuid))
                .concat(updateConf.prices)
                .filter((x) => !x.deleted)
                .sort((a, b) => a.order_value - b.order_value)
                .map((element, index) => {
                  element.order_value = index;
                  element.update = true;
                  if (element.deleted) {
                    return <></>;
                  }
                  return (
                    <div>
                      <div key={index} className="table">
                        {prices(element)}
                      </div>
                      <div class="orderPrice">
                        <div
                          onClick={() => {
                            if (element.order_value > 0) {
                              setPriceOrder(element, -1);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
                        </div>
                        <div
                          onClick={() => {
                            setPriceOrder(element, 1);
                          }}
                        >
                          <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
