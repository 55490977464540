import { create } from "zustand";
import { setType } from "../ZustandStores/zustandTypes";
import { ComponentType } from "react";
import { emptyPermissions, useUserStore } from "../ZustandStores/userStore";
import { IDataAccess, PermCheckProps } from "./Interfaces";

interface PermCheckState {
  permCheckFunc: (props: PermCheckProps) => boolean;
}

const permCheckStore = (set: setType<PermCheckState>) => ({
  permCheckFunc: (props: PermCheckProps) => {
    const userStore = useUserStore.getState();
    const allProps: PermCheckProps = {
      permissions: userStore.permissionsCheck,
      roles: userStore.roles,
      ...props,
    };
    return permCheckFunc(allProps);
  },
});

export const usePermCheckStore = create<PermCheckState>(permCheckStore);

export const withSitesStore = (BaseComponent: ComponentType<any>) => (props: object) => {
  const store = usePermCheckStore();
  return <BaseComponent {...props} sitesStore={store} />;
};

function permCheckFunc(props: PermCheckProps): boolean {
  try {
    const userDataPermissions = props.permissions;
    const { roles, id: propId, minimumRole, type } = props;

    if (props.ignoreCheck || userDataPermissions?.superadmin || type === undefined) return true;

    const role = roles?.find((e) => e.permission === minimumRole);

    if (type === undefined) {
      return false;
    }
    if (Array.isArray(type) && propId !== undefined) {
      throw new TypeError("When using 'id' property, you cannot have array of types.");
    }
    if (userDataPermissions === undefined) {
      return false;
    }

    if (type === "superadmin") {
      return userDataPermissions.superadmin;
    }

    const types = Array.isArray(type) ? type : [type];

    if (propId === undefined && userDataPermissions) {
      // `id` is not defined, we check if user only has permission to any of defined types with minimum role
      for (let i = 0; i < types.length; i++) {
        // const permission = (userDataPermissions[types[i]] ?? {}) as iDataAccess;
        const permission = userDataPermissions[types[i]] ?? {};
        if (
          typeof permission !== "boolean" &&
          Object.keys(permission).find((id) => {
            const theId = parseInt(id);
            const thisRole = roles?.find((r) => {
              return r.id === permission[theId];
            });
            if (thisRole !== undefined) {
              return thisRole.permission_value >= (role?.permission_value ?? 0);
            }
            return false;
          })
          // permission?.find(
          //   (e) => e.permission_value >= (role?.permission_value ?? 0)
          // ) !== undefined
        ) {
          return true;
        }
      }
    } else {
      const type = types[0];
      const permissions = (userDataPermissions[type] ?? {}) as IDataAccess;
      // const ids = Object.keys(permissions);
      const theRole = roles?.find((role) => role.permission === minimumRole);
      const theRolePermissionValue = theRole?.permission_value ?? 0;
      const theIds = Array.isArray(propId) ? propId : [propId];
      if (
        theIds.find((id) => {
          if (
            id !== undefined &&
            permissions[id] &&
            (roles?.find((role) => role.id === permissions[id])?.permission_value ?? 0) >= theRolePermissionValue
          ) {
            return true;
          }
        }) !== undefined
      ) {
        return true;
      }
    }
  } catch (e) {
    let message: string = "An unknown error occured when checking permission.";
    if (typeof e === "string") {
      message = e; // works, `e` narrowed to string
    } else if (e instanceof Error || e instanceof TypeError) {
      message = e.message; // works, `e` narrowed to Error
    }
    console.error(message);
  }
  return false;
}

export function PermCheck(
  props: PermCheckProps = {
    minimumRole: "Collaborator",
    type: undefined,
    permissions: emptyPermissions,
  }
): React.ReactElement | null {
  const userStore = useUserStore();
  const hasAccess = permCheckFunc({
    ...props,
    permissions: props.permissions ?? userStore.permissionsCheck ?? emptyPermissions,
    roles: props.roles ?? userStore.roles,
  });

  if (props.children === undefined || !hasAccess) {
    return null;
  }

  return props.children ?? null;
}
