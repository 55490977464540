import VerifyMethods from "src/assets/VerifyMethods";
import { IOrganizer } from "./OrganizerStore";
import { required_fields } from "./config";

/**
 * Validate organizer
 */
export function ValidateOrganizer(
  organizer: IOrganizer,
  same_as_place: boolean
): {
  valid: boolean;
  organizer: IOrganizer;
} {
  /**
   * If the organizer is the same as the place, return true
   */
  if (same_as_place) return { valid: true, organizer };
  /* Copy organizer */
  const newOrganizer: any = { ...organizer };
  /* Initialize valid */
  let valid = true;
  /* Loop over required fields */
  required_fields.forEach((field) => {
    /**
     * If the field is empty, set the error message
     */
    if (!newOrganizer[field].value || newOrganizer[field].value === "") {
      newOrganizer[field].error = { message: "This field is required" };
      if (valid) valid = false;
    }
    // TODO: Move out of foreach as this is just a check for the email input
    if (field === "email" && newOrganizer[field].value) {
      const message = VerifyMethods.validEmail(newOrganizer[field].value);
      newOrganizer[field].error = { message };
      if (valid) valid = message === "";
    }
  });
  /* Return validity flag and new organizer */
  return {
    valid: valid,
    organizer: newOrganizer,
  };
}
