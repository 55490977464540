import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import VfiInputText from "./VfiInputText/VfiInputText";
import React, { useRef, useState } from "react";
import jsApiLoaderParams from "./GoogleMaps/jsApiLoaderParams";

interface searchBoxProps {
  change: (e: google.maps.places.PlaceResult | undefined) => void;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  name?: string;
  onChange?: React.ChangeEvent<HTMLInputElement>;
  onBlur?: React.ChangeEvent<HTMLInputElement>;
  disabled?: boolean;
}
const SearchBox = (props: searchBoxProps) => {
  const { isLoaded } = useJsApiLoader(jsApiLoaderParams);
  const searchBox = useRef<google.maps.places.SearchBox>();
  const handlePlacesChanged = () => {
    const places = searchBox.current?.getPlaces();
    if (props.change) {
      props.change(places?.[0] ?? undefined); // Call parent's callback with the first place.
    }
  };

  return isLoaded ? (
    <StandaloneSearchBox onLoad={(ref) => (searchBox.current = ref)} onPlacesChanged={handlePlacesChanged}>
      <VfiInputText
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
    </StandaloneSearchBox>
  ) : (
    <div>Loading...</div>
  );
};

type libraryType = "places";
// const libraries: libraryType[] = ["places"];

interface googleMapSearchBoxProps {
  className?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  name?: string;
  change: (e: google.maps.places.PlaceResult | undefined) => void;
  onChange?: React.ChangeEvent<HTMLInputElement>;
  onBlur?: React.ChangeEvent<HTMLInputElement>;
  disabled?: boolean;
  listResult?: boolean;
}
function GoogleMapSearchBox(props: googleMapSearchBoxProps) {
  const [places, setPlaces] = useState<google.maps.places.PlaceResult[]>([]);

  return (
    <div data-standalone-searchbox="" className={props.className}>
      <SearchBox
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        name={props.name}
        change={(change) => {
          if (change) {
            setPlaces([change]);
          }
          props.change(change);
        }}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
      {props.listResult === true ? (
        <ol>
          {places.map((place) => {
            const { place_id, formatted_address, geometry } = place;
            if (geometry) {
              const { location } = geometry;
              return (
                <li key={place_id}>
                  {formatted_address}
                  {" at "}({location?.lat() ?? "nowhere"}, {location?.lng() ?? "nowhere"})
                </li>
              );
            }
          })}
        </ol>
      ) : null}
    </div>
  );
}

export default GoogleMapSearchBox;
