import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./Orders.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faMagnifyingGlass, faRotateRight } from "@fortawesome/pro-regular-svg-icons";
import { OrderListHeadType } from "./types";
import Preloader from "src/assets/Preloader";

/**
 * Head section for the order list
 *
 * @author 					Pætur Mortensen
 */
export default function OrderListHead({
  reload_orders,
  search,
  setSearch,
  close,
  isLoading,
}: OrderListHeadType): JSX.Element {
  return (
    <div className={styles.top}>
      <div>
        <div className={styles.title}>
          <div onClick={close} className={styles.backBtn}>
            <FontAwesomeIcon icon={faArrowLeftLong as IconProp} />
          </div>
          <h1>Orders</h1>
        </div>
      </div>
      <div>
        <div className={styles.search}>
          <input
            type="text"
            placeholder="Search orders..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} />
        </div>
        <div className={styles.orderListCP}>
          <Preloader show={isLoading} />
          <div className={styles.button} onClick={reload_orders} title="REFRESH">
            <FontAwesomeIcon icon={faRotateRight as IconProp} />
          </div>
        </div>
      </div>
    </div>
  );
}
