// import PropTypes from 'prop-types';
import { useState } from "react";
import "./SaveTemplate.scss";

import languages from "../language.json";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import axios from "axios";
import env from "../../../../environment.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";

function SaveTemplate(props) {
  const [screen, setScreen] = useState("save_template");
  const [loading, setLoading] = useState(false);
  const lang = languages[language].save_template;
  const [templateNameFound, setTemplateNameFound] = useState(false);
  const [templateAdded, setTemplateAdded] = useState(false);

  const [templateName, setTemplateName] = useState("");

  const saveTemplate = () => {
    setLoading(true);
    setTemplateNameFound(false);
    setTemplateAdded(false);

    let content = [];

    props.content.forEach((e) => {
      let extra_data = {};
      if (e.is_hero !== undefined) {
        extra_data = { ...extra_data, is_hero: e.is_hero };
      }

      if (e.galleryType !== undefined) {
        extra_data = { ...extra_data, galleryType: e.galleryType };
      }

      if (e.grid_type !== undefined) {
        extra_data = { ...extra_data, grid_type: e.grid_type };
      }

      if (e.type === "download_module") {
        extra_data = {
          data: [1, 2, 3, 4].map((nr) => {
            return {
              key: nr,
              button_label: "BUTTON LABEL",
              link_option: "download",
              page_url: "",
              shared: false,
              subtitle: "Sub headline",
              title: "Download module headline",
            };
          }),
          download_type: e.download_type,
          shown_amount: e.shown_amount,
        };
      }

      content.push({ type: e.type, extra_data });
    });

    axios
      .post(env.protocol + env.env + "/api/secured/pages/SaveTemplate", {
        template_name: templateName,
        content,
      })
      .then((response) => {
        if (!response.data.success) {
          if (response.data.error === "name_found") setTemplateNameFound(true);
        } else {
          setTemplateAdded(true);
        }
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  let content = "Should not be shown.";
  switch (screen) {
    case "save_template":
      content = (
        <div className="save">
          <div className="save-name">
            <p className="label">{lang.template_name}</p>
            <VfiInputText
              className="the-input"
              defaultValue={templateName}
              onChange={(e) => {
                setTemplateName(e.target.value);
              }}
            />
          </div>
          <div className="save-button" onClick={saveTemplate}>
            Save
          </div>
          {templateAdded && <div className="status green">{lang.template_added}</div>}
          {templateNameFound && <div className="status red">{lang.template_name_already_used}</div>}
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="edit-module save-template">
      <div className="top">
        <div
          className={"top-choice " + (screen === "save_template" ? "active " : "")}
          onClick={() => setScreen("save_template")}
        >
          {lang.save_template}
        </div>
      </div>
      <div className="content">{content}</div>
      <div className={"blur" + (loading ? " active" : "")}>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    </div>
  );
}

// SaveTemplate.propTypes = {
//     saveClick: PropTypes.any.isRequired,
// }

export default SaveTemplate;
