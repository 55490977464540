import Login from "../Login/Login";
import { useRouterStore } from "../Router/routerStore";

import { useEffect } from "react";
import Submit from "./routes/Submit/Submit";

import styles from "./CmsOuterRoutes.module.scss";
// import ForgotPassword from "../Login/ForgotPassword";
import ForgotPassword, { ForgotPasswordView } from "./routes/ForgotPassword/ForgotPassword";
import { outer_routes } from "../Router/navigationElements";
import Change from "./routes/Change/Change";
import NewPassword from "./routes/ForgotPassword/NewPassword/NewPassword";
import Registration, { RegistrationView } from "./routes/register/Registration";

interface Props {
  onLogin: (status: boolean) => void;
}

// const views: any = {
//   forgot_password: ForgotPasswordView,
//   register: RegistrationView,
// };

export default function CmsOuterRoutes({ onLogin = (status: boolean) => {} }: Props) {
  const { route, navByLink } = useRouterStore();

  // const current_route = route_map?.[route[0]?.nav ?? '']

  // const View = views?.[route[0]?.nav ?? ""]?.component ?? (() => <></>); // Get current view
  // const Props = views?.[route[0]?.nav ?? ""]?.props ?? ((additional_props: any) => ({}))({
  //   onLogin: onLogin,
  // }); // Get current view

  return (
    <div className={styles.container}>
      {/* {Object.keys(outer_routes).map((r) => {
        return (
          <button
            onClick={() => {
              navByLink(r);
            }}
          >
            {r}
          </button>
        );
      })} */}

      {/* <button onClick={() => {onLogin(true)}}>Login</button> */}

      {/* <View {...Props} /> */}

      {(!Object.keys(outer_routes).includes(route[0]?.nav as any) || route[0].nav === "login") && (
        <Login
          loginSuccess={() => {
            onLogin(true);
          }}
        />
      )}

      {route[0].nav === "submit" && <Submit />}

      {route[0].nav === "forgot_password" && (
        <ForgotPassword
          back={{
            text: "Back to login",
            call: () => {
              navByLink(""); // Will go back to login page..
            },
          }}
        />
      )}

      {route[0].nav === "change" && <Change />}

      {route[0].nav === "newPassword" && <NewPassword />}

      {route[0].nav === "register" && <Registration />}
    </div>
  );
}
