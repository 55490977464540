import { CSSProperties, useEffect, useRef, useState } from "react";
import { Description, Type } from "./Steps/Common";
import styles from "./WhatsOnViewManager.module.scss";
import {
  faTag,
  faList,
  faImage,
  faCheck,
  faBuilding,
  faLocationDot,
  faCalendarDays,
} from "@fortawesome/pro-light-svg-icons";
import StepNavigation, { IStep } from "./components/navigation/StepNavigation";
import Date from "./Steps/SharedSteps/date/DateViewComponent";
import Price from "./Steps/SharedSteps/price/Price";
import Media from "./Steps/Common/media/Media";
import Location from "./Steps/Common/location/Location";
import Update from "./Steps/Common/update/Update";
import Organizer from "./Steps/Common/organizer/Organizer";
import { faInfo, faSpinner, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useRouterStore } from "src/Components/Router/routerStore";
import { getWhatsonStore, useWhatsonManager } from "./stores/whatson-manager-store/WhatsonManagerStore";
import { INonUser } from "./stores/GeneralViewManagerStore";
import { WhatsOnStore } from "./stores/WhatsonStore";
import { SetEventStoreStates } from "./contracts/event/event-contract-server2client";
import { submitWhatson } from "./contracts/submit";
import axios from "axios";

import env from "src/environment.json";
import SessionTimerForStorage from "./components/session/SessionTimerForStorage";

const AUTOSAVE_INTERVAL = 30000;

const VIEW_STATE_SETTERS = {
  event: SetEventStoreStates,
};

/**
 * Step configurations
 */
const stepConfigurations = {
  // Event
  event: [
    /**
     * Description
     */
    { component: Description, title: "Description", icon: faInfo },
    /**
     * Type
     */
    { component: Type, title: "Type", icon: faList },
    /**
     * Date
     */
    {
      component: Date,
      title: "Date(s)",
      subtitle: <div>When is the event happening?</div>,
      icon: faCalendarDays,
    },
    /**
     * Price
     */
    { component: Price, title: "Price(s)", icon: faTag },
    /**
     * Media
     */
    { component: Media, title: "Media", icon: faImage },
    /**
     * Location
     */
    {
      component: Location,
      title: "Location",
      subtitle: <div>Where is the event taking place?</div>,
      icon: faLocationDot,
    },
    /**
     * Organizer
     */
    {
      component: Organizer,
      title: "Organizer",
      subtitle: <div>Provide details about the organizer.</div>,
      icon: faBuilding,
    },
    /**
     * Update
     */
    { component: Update, title: "Update", icon: faCheck },
  ],
};
/**
 * Main component
 */
export default function WhatsOnViewManager({
  data,
  internal = false,
  non_user,
  editToken,
  whatsOnType,
  onStepChange = () => {},
  onMediaChange = (addMediaElements) => {},
}: {
  data?: Partial<WhatsOnStore>;
  internal?: boolean;
  non_user?: INonUser;
  editToken?: string;
  whatsOnType: "event";
  onStepChange?: () => void;
  onMediaChange?: (addMediaElements: any) => void;
}) {
  /**
   * Store properties
   */
  const {
    type,
    saving,
    submitted,
    currentStepIndex,
    setType,
    isMobile,
    isTablet,
    setNonUser,
    setInternal,
    setEditToken,
    setContentHeight,
    setContainerWidth,
    setMediaOverlayUpload,
  } = getWhatsonStore();

  /**
   * Router store properties
   */
  const { setRouter } = useRouterStore();
  /**
   *
   */
  const { removeStore, setType: setManagarType, current_id } = useWhatsonManager();
  /**
   * Container reference
   */
  const containerRef = useRef<HTMLDivElement>(null);

  const interval = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    // let interval: NodeJS.Timer | null = null;

    if (data && Object.keys(data).length > 0) {
      VIEW_STATE_SETTERS?.[whatsOnType]?.(data);
    } else if (internal) {
      interval.current = setInterval(() => {
        submitWhatson(false, true);
      }, AUTOSAVE_INTERVAL);
    }

    /**
     * Force reset on mount
     */
    // resetWhatsonStore();
    /**
     * Set intercept
     */
    if (internal)
      setRouter({
        route_intercept: true,
        onRouteAccept: () => {
          const id = useWhatsonManager.getState().getStore().getState().id;
          axios
            .post(env.protocol + env.env + "/api/secured/whatson/SessionEndForEdit.php", {
              id,
              type,
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              useRouterStore.getState().navByLink("whatson/events"); // TODO: Make dynamic if other flow uses the structure
              // close();
            });
        },
      });

    return () => {
      /**
       * Force reset on unmount
       */
      //  resetWhatsonStore();
      removeStore(whatsOnType);

      // Remove intercept
      setRouter({ route_intercept: false });

      // Clear interval
      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    /**
     * Set dependencies if new store is initialized
     */

    if (editToken) {
      setEditToken(editToken);
    }

    if (non_user) {
      setNonUser(non_user);
    }

    setMediaOverlayUpload(onMediaChange);
  }, [current_id]);

  useEffect(() => {
    onStepChange();
  }, [currentStepIndex]);

  useEffect(() => {
    // If submit is succesful, then we'll turn of autosave
    if (submitted && interval.current) clearInterval(interval.current);
  }, [submitted]);

  useEffect(() => {
    /**
     * If the type is not the same as the whatsOnType, set the type
     */
    if (whatsOnType !== type) {
      setType(whatsOnType);
      setManagarType(whatsOnType);
    }
    /**
     * If internal, set the flag
     */
    if (internal) setInternal(internal);
    /**
     * Update the container size
     */
    const updateContainerSize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
        setContentHeight(containerRef.current.offsetHeight);
      }
    };
    // Update width, height on mount
    updateContainerSize();
    // Add event listener for window resize
    window.addEventListener("resize", updateContainerSize);
    // Clean up event listener
    return () => {
      window.removeEventListener("resize", updateContainerSize);
    };
  }, [whatsOnType, setType, type, setContainerWidth, setContentHeight]);

  /**
   * Steps
   */
  const steps: IStep[] = stepConfigurations[whatsOnType] || [];
  /**
   * Current step component
   */
  const CurrentStepComponent = steps[currentStepIndex]?.component;
  /**
   * Title and subtitle
   */
  const { title, subtitle } = steps[currentStepIndex] || { title: "", subtitle: "" };
  /**
   * Content offset
   */
  const contentOffset = isMobile() ? "210px" : isTablet() ? "190px" : "120px";
  /**
   * Style
   */
  const style: CSSProperties = { "--content-offset": contentOffset } as React.CSSProperties;
  /**
   * Render
   */
  return (
    <div className={styles.container} ref={containerRef} style={style}>
      {/* Auto save */}
      {saving.auto && <AutoSave />}
      {/* Manual save */}
      {saving.submit && <ManualSave />}
      {/* Step Navigation */}
      <StepNavigation steps={steps} />
      {/* Content */}
      <div className={styles.content}>
        {CurrentStepComponent && <CurrentStepComponent {...{ title, subtitle, whatsOnType }} />}
      </div>
      <div className={styles.session_wrapper}>
        <SessionTimerForStorage />
      </div>
    </div>
  );
}

function AutoSave() {
  return (
    <div className={styles.auto_save}>
      <div>Auto saving...</div>
      <FontAwesomeIcon spin icon={faSpinner as IconProp} />
    </div>
  );
}

function ManualSave() {
  return (
    <div className={styles.auto_save}>
      <div>Submitting...</div>
      <FontAwesomeIcon spin icon={faSpinner as IconProp} />
    </div>
  );
}
