import styles from "./TermsOfService.module.scss";

interface Props {
  value?: boolean;
  error?: any;
  onChange?: (e: any) => void;
}

export default function TermsOfService({ value, error, onChange }: Props) {
  return (
    <div className={styles.container}>
      <input className={`${!error.valid ? styles.error : ""}`} type="checkbox" checked={value} onChange={onChange} />

      <p>
        I accept the <a href="">privacy policy</a> and <a href="">terms of condition</a>
      </p>
    </div>
  );
}
