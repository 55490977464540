/**
 * `Enum` utility function
 *
 * This function provides a flexible way to create a runtime object that mimics
 * the behavior of TypeScript's enum. Depending on the input provided, the function
 * can create an object either from an array of strings or from a predefined object.
 *
 * 1. If an array of strings is provided:
 *    Each string will become a key in the resulting object with the value set to the same string.
 *
 * 2. If an object is provided:
 *    The object is returned as is, but made immutable using `Object.freeze`.
 *
 * @example
 *
 * // Using an array of strings:
 * const COLORS = Enum("Red", "Blue");
 * console.log(COLORS.Red); // Outputs: "Red"
 *
 * // Using an object:
 * const BUTTONS = Enum({
 *     left: "LeftButton",
 *     right: "RightButton"
 * });
 * console.log(BUTTONS.left); // Outputs: "LeftButton"
 *
 */
export function Enum<T extends string[]>(...args: T): { readonly [K in T[number]]: K };
export function Enum<T extends Record<string, string>>(input: T): Readonly<T>;
export function Enum(...args: (string | Record<string, string>)[]) {
  if (typeof args[0] === "string") {
    const keysArray = args as string[];
    return Object.freeze(keysArray.reduce((acc, key) => ({ ...acc, [key]: key }), {} as Record<string, string>));
  } else {
    const inputObject = args[0] as Record<string, string>;
    return Object.freeze(inputObject);
  }
}

/**
 * `EnumValues` type
 *
 * This type extracts a union of all possible values (or properties) of a given object.
 * It's particularly useful to derive a type-safe union from the runtime object created by the `Enum` function.
 *
 * @example
 *
 * const COLORS = Enum("Red", "Blue");
 * type ColorType = EnumValues<typeof COLORS>;  // ColorType is "Red" | "Blue"
 *
 */
export type EnumValues<T> = T[keyof T];
