export type OrdersType = {
  setCurrentLocation: (location: string) => void;
};

export type OrderType = {
  close: () => void;
  orderID: number;
};

export type HeaderState = "editable" | "in-edit";

export type Bind = {
  id: string;
  price_pr: string;
  amount: number;
  product_id: number;
  service_id: number;
  product_info: {
    spP_label: string;
    spV_label: string;
  };
  service_info: {
    Title: string;
    Description: string;
  };
};

export type OrderPayment = {
  id: string;
  pdf_name: string;
  date: string;
};

export type Payment = {
  binds: Bind[];
  ID: string;
  orderStatus: string;
  paymentStatus: string;
  firstName: string;
  lastName: string;
  date: string;
};

// Order item in the orders list
export type OrderItem = {
  orderID: number;
  date: string;
  name: string;
  numProducts: number;
  numServices: number;
  priceTotal: number;
  status: string;
  address: string;
  city: string;
  comments: string;
  email: string;
  licensePlate: string;
  phone: string;
  zip: string;
};

export type OrderListHeadType = {
  reload_orders: () => void;
  search: string;
  setSearch: (search: string) => void;
  close: () => void;
  isLoading: boolean;
};

export type OrderListType = {
  reload_orders: () => void;
  orders: OrderItem[];
  setDisplayOrder: (orderID: number) => void;
  close: () => void;
  isLoading: boolean;
  filteredOrders: OrderItem[];
  setFilteredOrders: (orders: OrderItem[]) => void;
  search: string;
  setSearch: (search: string) => void;
};

// OrderHead component parameters
export type OrderHeadType = {
  data: OrderData;
  editedData: OrderData;
  close: () => void;
  headerState: HeaderState;
  setHeaderState: (state: HeaderState) => void;
  reload_order: () => void;
  isLoading: boolean;
  setIsLoading: (set: boolean) => void;
  discard_edit: () => void;
};

export type GiveChangedData = (keyString: string, value: string) => void;

export type OrderData = {
  ID: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  licensplate: string;
  address: string;
  city: string;
  zip: string;
  deliveryAddress: string;
  deliveryCity: string;
  deliveryZip: string;
  comments: string;
  orderStatus: string;
  paymentStatus: string;
  shippingStatus: string;
  binds: Bind[];
  payments: OrderPayment[];
};

const nullOrderData: OrderData = {
  ID: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  licensplate: "",
  address: "",
  city: "",
  zip: "",
  deliveryAddress: "",
  deliveryCity: "",
  deliveryZip: "",
  comments: "",
  orderStatus: "",
  paymentStatus: "",
  shippingStatus: "",
  binds: [],
  payments: [],
};

export type SetData = (data: OrderData) => void;

export type OrderDetailsType = {
  data: OrderData;
  setData: SetData;
  editable: boolean;
};

export type UserInfoType = {
  data: OrderData;
  setData: SetData;
  editable: boolean;
};

export type OrderLinesType = {
  data: OrderData;
  setData: SetData;
  editable: boolean;
};

// Status list component params
export type StatusListType = {
  data: OrderData;
};

// Order history component params
export type OrderHistoryType = {
  data: OrderData;
};

export { nullOrderData };
