import "../Shop.scss";
import styles from "./Orders.module.scss";
import { UserInfoType, OrderData } from "./types";

/**
 * User Info section of the order display
 *
 * @author 					Pætur Mortensen
 */
export default function UserInfo({ data, setData, editable }: UserInfoType): JSX.Element {
  /**
   * Render a text input for the user info form
   *
   * @author 					Pætur Mortensen
   */
  function render_text_input(
    // Input label
    label: string,
    // Data key (key in data object)
    dataKey: keyof OrderData,
    // Whether to add marginTop className
    addMarginTop: boolean = false,
    // Whether to add marginBottom className
    addMarginBottom: boolean = false
  ): JSX.Element {
    let className = styles.textInput;
    if (addMarginTop) className += " " + styles.marginTop;
    if (addMarginBottom) className += " " + styles.marginBottom;

    return (
      <div className={className}>
        <label>{label}</label>
        <input
          value={data[dataKey] as string}
          disabled={!editable}
          onChange={(e) => {
            setData({ ...data, [dataKey]: e.target.value });
          }}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.userInfoWrap} ${styles.box}`}>
      <div className={`${styles.userInfo}`}>
        <div className={styles.splitEvenly}>
          {render_text_input("First name", "firstName")}
          {render_text_input("Last name", "lastName")}
        </div>
        {render_text_input("Email", "email", true)}
        {render_text_input("Phone", "phone", true)}
        {render_text_input("License plate", "licensplate", true, true)}
        <span className={styles.smallLabel}>Address info</span>
        {render_text_input("Address", "address")}
        <div className={`${styles.splitEvenly} ${styles.marginBottom} ${styles.marginTop}`}>
          {render_text_input("City", "city")}
          {render_text_input("Zip", "zip")}
        </div>
        <span className={styles.smallLabel}>Delivery Address info</span>
        {render_text_input("Address", "deliveryAddress")}
        <div className={`${styles.splitEvenly} ${styles.marginTop}`}>
          {render_text_input("City", "deliveryCity")}
          {render_text_input("Zip", "deliveryZip")}
        </div>
        <div className={`${styles.textInput} ${styles.marginTop}`}>
          <label>Comments</label>
          <textarea
            value={data.comments}
            disabled={!editable}
            onChange={(e) => {
              setData({ ...data, comments: e.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}
