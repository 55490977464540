import Button from "src/Components/Buttons/Button";
import "../../EditProduct.scss";
import Variant from "./Variant";
import { useState } from "react";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddVariant from "./AddVariant";

/**
 * Show list of variants
 *
 * @param 			{object} 		product 									Product state
 * @param 			{function} 	setProduct								Set product state
 * @param 			{object} 		productConfig 						Product configuration
 * @param 			{function} 	reload_product 						Reload the entire product
 * @param 			{function} 	recalculate_prices				Recalculate all prices on system
 *
 * @returns 		{jsx} 																List of variants
 *
 * @author 					Pætur Mortensen
 */
export default function Variants({ product, setProduct, productConfig, reload_product, recalculate_prices }) {
  // Whether we should be showing the add variant form
  const [addVariant, setAddVariant] = useState(false);

  /***************************************************************************************************
   *
   * 															RENDER
   *
   **************************************************************************************************/

  function render_variants_cp() {
    return (
      <div className="variants-cp">
        <Button
          className="add-button"
          size="small"
          disabled={product.synced}
          onClick={() => {
            setAddVariant(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Add variant
        </Button>
        {addVariant && (
          <AddVariant
            onClose={() => {
              setAddVariant(false);
            }}
            productID={product.productID}
            onCreate={() => {
              setAddVariant(false);
              reload_product();
            }}
          />
        )}
      </div>
    );
  }
  return (
    <div className="select-varient">
      {render_variants_cp()}
      <h3>Variants</h3>
      <div className="wrap-varients">
        {product.variants.map((variant) => {
          return (
            <Variant
              key={variant.variantID}
              variant={variant}
              product={product}
              setProduct={setProduct}
              productConfig={productConfig}
              recalculate_prices={recalculate_prices}
            />
          );
        })}
      </div>
    </div>
  );
}
