import { ICategory } from "./TypesStore";

export function ValidateTypes(types: { [key: string]: ICategory }): {
  valid: boolean;
  types: { [key: string]: ICategory };
} {
  let newTypes = { ...types };

  let valid = true;

  if (Object.keys(newTypes).length === 0) {
    valid = false;
  }

  if (Object.keys(newTypes).length > 3) {
    valid = false;
  }

  return {
    valid: valid,
    types: newTypes,
  };
}
