import styles from "./PrivacyPolicy.module.scss";
import Button from "src/Components/Buttons/Button";
import { useEffect, useState } from "react";
import axios from "axios";
import env from "../../../environment.json";

/**
 * This is the privacy policy modal
 *
 * It will show up for all users that have not accepted the terms of the privacy policy
 *
 * @author 					Pætur Mortensen
 */
export default function PrivacyPolicy(): JSX.Element {
  // Whether to display the modal
  const [display, setDisplay] = useState(false);
  // Whether the "accept" checkbox has been checked
  const [checked, setChecked] = useState(false);
  // Whether to notify user that the checkbox has not been checked
  const [notifyNotAccepted, setNotifyNotAccepted] = useState(false);
  // Whether there has been an error
  const [error, setError] = useState(false);

  useEffect(() => {
    // Request server to see if user has accepted privacy policy
    axios
      .get(env.protocol + env.env + "/api/secured/user/PrivacyPolicyAccepted")
      .then((response) => {
        // Set whether terms have been accepted
        const termsAccepted = response.data.privacyPolicyAccepted ?? false;
        // Set display to true if terms not accepted
        setDisplay(!termsAccepted);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  }, []);

  // If we're not to display the modal, return empty element
  if (!display) return <></>;

  /**
   * User has declined the privacy policy, sign them out and refresh
   *
   * @author 					Pætur Mortensen
   */
  function onDecline() {
    axios.get(env.protocol + env.env + "/api/public/LogOut").finally(() => {
      window.location.reload();
    });
  }

  /**
   * User has accepted the privacy policy
   *
   * Validate input, request server to set policy to accepted and remove this modal
   *
   * @author 					Pætur Mortensen
   */
  function onAccept() {
    // If the policy has not been accepted, we notify the user to check the checkbox
    if (!checked) {
      setNotifyNotAccepted(true);
      return;
    }

    // Send request to server to set policy accepted
    axios
      .post(env.protocol + env.env + "/api/secured/user/AcceptPrivacyPolicy")
      .then((response) => {
        if (!response.data.success) {
          setError(true);
        } else {
          setDisplay(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  }

  return (
    <div className={styles.greyout}>
      <div className={styles.privacyPolicy}>
        <div className={styles.header}>Privacy Policy</div>
        <div className={styles.content}>
          Please accept our privacy policy to continue using the system.
          <br />
          <br />
          <a href="https://visitfaroeislands.com/en/about1/about0/privacy-policy" target="_blank" rel="noreferrer">
            Read our privacy policy here
          </a>
          <br />
          <br />
          {notifyNotAccepted && <div className={styles.acceptNotification}>* Must be checked before accepting</div>}
          <div className={styles.checkField}>
            <input
              type="checkbox"
              className={styles.checkbox}
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            />
            I accept the privacy policy
          </div>
          {error && (
            <div className={styles.errorNotification}>
              There was an error, please try again or contact system administration if this continues
            </div>
          )}
        </div>
        <div className={styles.cp}>
          <Button
            type="secondary"
            onClick={() => {
              onDecline();
            }}
          >
            Decline
          </Button>
          <Button
            onClick={() => {
              onAccept();
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
}
