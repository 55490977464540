import { faTrashAlt, faPlus, faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import "./B2BEmailConfig.scss";
import { validate_FQDN, validate_email } from "src/utils";
import { useState, useEffect } from "react";
import axios from "axios";
import env from "../../../environment.json";
import Preloader from "../../../assets/Preloader";
import Verify from "../../../assets/VerifyMethods";

export default function B2BEmailConfig() {
  // Field in the insert form row
  const [insertFields, setInsertFields] = useState({
    email: "",
    type: "",
    name: "",
  });
  // All email list rows
  const [emailRows, setEmailRows] = useState([]);
  // Whether component is loading
  const [isLoading, setIsLoading] = useState(true);
  // Validation status of all rows
  const [rowValidation, setRowValidation] = useState({
    insert: { email: true, type: true, customerName: true },
  });
  // Whether we are in the proces of submitting new email
  const [isSubmittingNew, setIsSubmittingNew] = useState(false);
  // Whether the list is expanded
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // Get the B2B Email list from server
    axios
      .get(env.protocol + env.env + "/api/secured/shop/GetB2BEmails")
      .then((response) => {
        const emailRows = response.data.data;

        // Build the email list
        build_email_list(emailRows);

        // Component is no longer loading
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  /**
   * Build the email list anew, reset all validation
   *
   * @param 	{array} 	list	 						List of emails on system
   *
   * @author 					Pætur Mortensen
   */
  function build_email_list(list) {
    // Clone row validation for manipulation
    const rowValClone = JSON.parse(JSON.stringify(rowValidation));
    // For each email row...
    list.map((row) => {
      // Assuming all rows are validated since we are getting from server
      rowValClone[row.emailID] = { email: true, type: true, name: true };
    });

    // Set the row validation
    setRowValidation(rowValClone);
    // Set the rows
    setEmailRows(list);
  }

  /**
   * Validate an input row
   *
   * @param 		{object} 			row 		Row to validate
   * @param 		{string|int}	rowID 	'insert' or emailID depending on which row is being validated
   *
   * @returns 	{bool} 												Whether validation passed
   *
   * @author 					Pætur Mortensen
   */
  function validate_row(row, rowID) {
    let valid = true;

    if (!row.email) {
      // Email is empty
      valid = false;
    }
    if (!row.name) {
      // Name is empty
      valid = false;
    }
    if (row.type !== "email" && row.type !== "host") {
      // Type is not valid

      // Clone row validation object and set it
      const rowValClone = JSON.parse(JSON.stringify(rowValidation));
      rowValClone[rowID].type = false;
      setRowValidation(rowValClone);

      valid = false;
    }

    return valid;
  }

  /**
   * Clear the insert form
   *
   * @author 					Pætur Mortensen
   */
  function clear_insert_form() {
    setInsertFields({ email: "", type: "", name: "" });
  }

  /**
   * Validates the insert form and inserts new email if validation passes.
   *
   * @returns 	{undefined|false} 								Returns undefined on success and false on fail
   *
   * @author 					Pætur Mortensen
   */
  function add_email() {
    setIsLoading(true);
    setIsSubmittingNew(true);

    // Validate the insert fields
    if (!validate_row(insertFields, "insert")) {
      // Insert fields not valid

      setIsLoading(false);
      return false;
    }

    // Fields are valid, insert the new email (call server)
    axios
      .post(env.protocol + env.env + "/api/secured/shop/AddB2BEmail", {
        userId: sessionStorage.getItem("vfiUser"),
        email: insertFields.email,
        type: insertFields.type,
        name: insertFields.name,
      })
      .then((response) => {
        build_email_list(response.data.data);
        clear_insert_form();
        setIsLoading(false);
        setIsSubmittingNew(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setIsSubmittingNew(false);
      });
  }

  /**
   * Deletes an email from the list
   *
   * @param 	{int} 	emailID 							ID of email to delete
   *
   * @author 					Pætur Mortensen
   */
  function delete_email(emailID) {
    setIsLoading(true);

    // Call server to delete the email
    axios
      .post(env.protocol + env.env + "/api/secured/shop/DeleteB2BEmail", {
        userId: sessionStorage.getItem("vfiUser"),
        emailID,
      })
      .then((response) => {
        // Rebuild the email list after deletion
        build_email_list(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  /**
   * Validate email input on the fly and update the type with validation result
   *
   * @param 	{string} 			input 									Email input string
   * @param 	{string|int} 	rowID 									ID of row being checked ('insert' or emailID)
   *
   * @author 					Pætur Mortensen
   */
  function handle_email_change(input, rowID) {
    // Init type as empty
    let type = "";

    if (validate_email(input)) {
      // Input is valid email

      type = "email";
    } else if (validate_FQDN(input)) {
      // Input is valid hostname

      type = "host";
    } else {
      // Input is not valid

      type = "";
    }

    // Set the type validation for the row (cast to bool)
    const rowValClone = JSON.parse(JSON.stringify(rowValidation));
    rowValClone[rowID].type = !!type;
    setRowValidation(rowValClone);

    // If we are changing email in the insert form...
    if (rowID === "insert") {
      // Set the insert field type value
      setInsertFields({ ...insertFields, email: input, type });
    } else {
      // Get the row index and clone emailRows
      const index = emailRows.findIndex((row) => row.emailID === rowID);
      const emailRowsClone = JSON.parse(JSON.stringify(emailRows));

      // Set the input and type data
      emailRowsClone[index].email = input;
      emailRowsClone[index].type = type;
      setEmailRows(emailRowsClone);
    }
  }

  /**
   * Handle onChange on a customer name field
   *
   * @param 	{string} 	input 								Name input value
   * @param 	{int} 		emailID								EmailID in row
   *
   * @author 					Pætur Mortensen
   */
  function handle_name_change(input, emailID) {
    // Get the row index and clone emailRows
    const index = emailRows.findIndex((row) => row.emailID === emailID);
    const emailRowsClone = JSON.parse(JSON.stringify(emailRows));

    // Set the input and type data
    emailRowsClone[index].customerName = input;
    setEmailRows(emailRowsClone);
  }

  /**
   * onBlur for email field. Validate and send update request to server if valid
   *
   * @param 		{int} 	emailID 										ID of email field (emailID)
   *
   * @returns 	{undefined|bool} 										Undefined if successful, false if fail
   *
   * @author 					Pætur Mortensen
   */
  function handle_email_blur(emailID) {
    setIsLoading(true);

    // Get the row index and clone emailRows
    const index = emailRows.findIndex((row) => row.emailID === emailID);
    const email = emailRows[index].email;
    const type = emailRows[index].type;

    if (!rowValidation[emailID].type) {
      // The email is not valid type

      setIsLoading(false);
      return false;
    }

    // Email is valid type, continue

    // Fields are valid, insert the new email (call server)
    axios
      .post(env.protocol + env.env + "/api/secured/shop/UpdateB2BEmail", {
        userId: sessionStorage.getItem("vfiUser"),
        email,
        type,
        emailID,
      })
      .then((response) => {
        // Rebuild the email list with new data from server
        build_email_list(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  /**
   * onBlur for customer name field. Send update request to server.
   *
   * @param 	{int} 	emailID 							ID of email row to update
   *
   * @author 					Pætur Mortensen
   */
  function handle_name_blur(emailID) {
    setIsLoading(true);

    // Get the row index and clone emailRows
    const index = emailRows.findIndex((row) => row.emailID === emailID);
    const name = emailRows[index].customerName;

    axios
      .post(env.protocol + env.env + "/api/secured/shop/UpdateB2BEmail", {
        userId: sessionStorage.getItem("vfiUser"),
        name,
        emailID,
      })
      .then((response) => {
        // Rebuild the email list with new data from server
        build_email_list(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  /**
   * Render the "Insert email" row for the list
   *
   * @returns jsx 													Insert email row
   *
   * @author 					Pætur Mortensen
   */
  function render_insert_row() {
    // Get string to use with row type
    const typeStr = rowValidation.insert.type ? insertFields.type : "Invalid";

    return (
      <tr>
        <td>
          <div>
            <VfiInputText
              placeholder="Email address or domain"
              value={insertFields.email}
              onChange={(e) => {
                handle_email_change(e.target.value, "insert");
              }}
              errorCheckSequence={[Verify.notEmpty]}
              hideError={!isSubmittingNew}
            />
          </div>
        </td>
        <td>{typeStr}</td>
        <td>
          <div>
            <VfiInputText
              placeholder="Customer name"
              value={insertFields.name}
              onBlur={(e) => {
                setInsertFields({ ...insertFields, name: e.target.value });
              }}
              onChange={(e) => {
                setInsertFields({ ...insertFields, name: e.target.value });
              }}
              errorCheckSequence={[Verify.notEmpty]}
              hideError={!isSubmittingNew}
            />
          </div>
        </td>
        <td>
          <div
            className={"insertBtn"}
            onClick={() => {
              add_email();
            }}
          >
            <FontAwesomeIcon color="green" icon={faPlus} />
          </div>
        </td>
      </tr>
    );
  }

  /**
   * Render the email list rows
   *
   * @returns 	jsx 										Table rows with the email list
   *
   * @author 					Pætur Mortensen
   */
  function render_mail_rows() {
    /**
     * Render an email/host input
     *
     * @param 		{string} 	email 							Email value for the field
     * @param 		{int} 		emailID 						ID of the email row
     *
     * @returns 	jsx 													Email input field
     *
     * @author 					Pætur Mortensen
     */
    function render_email_input(email, emailID) {
      return (
        <div>
          <VfiInputText
            defaultValue={email}
            onBlur={(e) => {
              handle_email_blur(emailID);
            }}
            onChange={(e) => {
              handle_email_change(e.target.value, emailID);
            }}
            errorCheckSequence={[Verify.notEmpty]}
          />
        </div>
      );
    }

    /**
     * Render the "type" input (actually just info, not input, but hey...)
     *
     * @param 		{string} 	type 									Type of the email input value
     *
     * @returns 	jsx 														Type container
     *
     * @author 					Pætur Mortensen
     */
    function render_type_input(type) {
      return <div>{type}</div>;
    }

    /**
     * Render the customer name input
     *
     * @param 		{string} 	name 							Customer name
     * @param 		{int} 		emailID 					ID of the email row
     *
     * @returns 	jsx 											Customer name input
     *
     * @author 					Pætur Mortensen
     */
    function render_name_input(name, emailID) {
      return (
        <div>
          <VfiInputText
            defaultValue={name}
            onChange={(e) => {
              handle_name_change(e.target.value, emailID);
            }}
            onBlur={(e) => {
              // Validate and send update request to server
              handle_name_blur(emailID);
            }}
            errorCheckSequence={[Verify.notEmpty]}
          />
        </div>
      );
    }

    return (
      <>
        {emailRows.map((row) => {
          // For each email row...
          return (
            <tr key={row.emailID}>
              <td>{render_email_input(row.email, row.emailID)}</td>
              <td>{render_type_input(row.type)}</td>
              <td>{render_name_input(row.customerName, row.emailID)}</td>
              <td>
                <div
                  className="remove"
                  onClick={() => {
                    delete_email(row.emailID);
                  }}
                >
                  <FontAwesomeIcon color="red" icon={faTrashAlt} />
                </div>
              </td>
            </tr>
          );
        })}
      </>
    );
  }
  return (
    <div className={"mainContainer"}>
      <div>
        <div className={"configHeader expand"}>
          <h3>B2B Emails</h3>
          <p>
            List of email-addresses and domains that will skip the checkout process and go straight to manual billing.
          </p>
          <div className={"expContrBtn"}>
            <FontAwesomeIcon
              icon={isExpanded ? faChevronUp : faChevronDown}
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            />
          </div>
        </div>
        <div className={"tableContainer" + (isExpanded ? "" : " hidden")}>
          <table>
            <thead>
              <tr>
                <th>Email or host</th>
                <th>Type</th>
                <th>Customer name</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {render_insert_row()}
              <tr>
                <td colSpan="4">
                  <Preloader show={isLoading} />
                </td>
              </tr>
              {render_mail_rows()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
