import SimpleCheckbox from "src/Components/ui/checkbox/SimpleCheckbox/SimpleCheckbox";
import Layout from "../../Common/Layout";
import styles from "./Price.module.scss";
import TextInput from "src/Components/ui/input/TextInput/TextInput";
import LabelButton from "src/Components/ui/buttons/LabelButton/LabelButton";
import XmarkButton from "src/Components/ui/buttons/XmarkButton/XmarkButton";
import { IPrice } from "src/views/WhatsOn/stores/price-store/PriceStore";
import { Fragment, useEffect } from "react";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";
import enFlag from "src/assets/images/en.png";
import foFlag from "src/assets/images/fo.png";

/**
 * Main component - Price
 */
export default function Price() {
  /**
   * Store properties
   */
  const {
    isFree,
    setIsFree,
    prices,
    addPriceObj,
    bookingUrl,
    setBookingUrl,
    bookingUrls,
    setBookingUrlEn,
    setBookingUrlFo,
    bookingUrlPerLang,
    setBookingUrlPerLang,
    validatePrice,
    type,
    errors,
  } = getWhatsonStore();
  useEffect(() => {
    return () => {
      /**
       * Validate the price on unmount
       */
      // validatePrice();
    };
  }, []);
  /**
   * Render
   */
  return (
    <Layout title="Price(s)" subtitle="How much is it going to cost?">
      {/* Content */}
      <div className={styles.content}>
        <SimpleCheckbox
          label="The event is free"
          checked={isFree}
          onChange={() => {
            setIsFree(!isFree);
            validatePrice();
          }}
          id={"free"}
          labelClassName={styles.label}
        />
        {!isFree && (
          <>
            {/* Not free */}
            <div className={styles.priceContent}>
              <div className={styles.headlineTitle}>Price</div>
              <div className={styles.priceInputContainerWrapper}>
                {prices.map((price, index) => (
                  <PriceInput key={index} price={price} index={index} />
                ))}
                <div className={styles.labelButtonContainer}>
                  <LabelButton label="Add price" onClick={() => addPriceObj()} />
                </div>
              </div>
            </div>
            <div className={styles.priceContent}>
              <div className={styles.headlineTitle}>Button (Optional)</div>
              <SimpleCheckbox
                label="Booking URL is not the same for EN and FO"
                id="pay-url-differen-lang"
                defaultChecked={bookingUrlPerLang}
                onChange={(e) => {
                  setBookingUrlPerLang(e.target.checked);
                }}
              />
              {!bookingUrlPerLang ? (
                // One url shown
                <div className={styles.bookingUrlContainer}>
                  <TextInput
                    id="booking_url"
                    label="Booking URL"
                    placeholder="optional"
                    labelPosition="top"
                    containerStyle={styles.buttonInput}
                    value={bookingUrl || ""}
                    onChange={(e) => setBookingUrl(e.target.value)}
                  />
                </div>
              ) : (
                // Two urls shown
                <div className={styles.twoPayUrls}>
                  <div className={styles.bookingUrlContainer}>
                    <TextInput
                      id="booking_url_en"
                      label={
                        <span>
                          <img className={styles.langFlag} src={enFlag} /> Booking URL
                        </span>
                      }
                      placeholder="optional"
                      labelPosition="top"
                      containerStyle={styles.buttonInput}
                      value={bookingUrls.en || ""}
                      onChange={(e) => setBookingUrlEn(e.target.value)}
                    />
                  </div>
                  <div className={styles.bookingUrlContainer}>
                    <TextInput
                      id="booking_url_fo"
                      label={
                        <span>
                          <img className={styles.langFlag} src={foFlag} /> Booking URL
                        </span>
                      }
                      placeholder="optional"
                      labelPosition="top"
                      containerStyle={styles.buttonInput}
                      value={bookingUrls.fo || ""}
                      onChange={(e) => setBookingUrlFo(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

/**
 * Price input
 */
function PriceInput({ price, index }: { price: IPrice; index: number }) {
  /**
   * Store properties
   */
  const { type, errors, prices, validatePrice, updatePriceObj, removePriceObj } = getWhatsonStore();
  /**
   * Store properties
   */
  const { enabled } = getWhatsonStore();
  /**
   * Handle price change
   */
  const handlePriceChange = (priceVal: string) => {
    updatePriceObj(index, { ...price, price: priceVal });
    if (!errors?.[type]?.["Price(s)"]?.valid) validatePrice();
  };
  /**
   * Handle english label change
   */
  const handleEnglishLabelChange = (label: string) => {
    updatePriceObj(index, { ...price, label: { ...price.label, en: label } });
    if (!errors?.[type]?.["Price(s)"]?.valid) validatePrice();
  };
  /**
   * Handle faroese label change
   */
  const handleFaroeseLabelChange = (label: string) => {
    updatePriceObj(index, { ...price, label: { ...price.label, fo: label } });
    if (!errors?.[type]?.["Price(s)"]?.valid) validatePrice();
  };
  /**
   * Render
   */
  return (
    <div className={styles.priceInputContainer}>
      {/* Prices X button */}
      {prices.length > 1 && (
        <div className={styles.removePriceInput}>
          <XmarkButton
            onClick={() => {
              removePriceObj(index);
              if (!errors?.[type]?.["Price(s)"]?.valid) validatePrice();
            }}
          />
        </div>
      )}
      {/* Price */}
      <div style={{ gridArea: "price" }}>
        <TextInput
          id={"price_" + index}
          inputMode="decimal"
          label="Price"
          error={!(errors?.[type]?.["Price(s)"].valid || price.price?.toString() || "") ? "Invalid input" : ""}
          placeholder="DKK"
          labelPosition="top"
          value={price.price?.toString() || ""}
          onChange={(e) => handlePriceChange(e.target.value)}
        />
      </div>
      {/* English */}
      <div
        style={{
          gridArea: "english",
          pointerEvents: enabled["en"] ? "auto" : "none",
          opacity: enabled["en"] ? 1 : 0.3,
        }}
      >
        <TextInput
          id={"en_label_" + index}
          label="In English"
          error={!(errors?.[type]?.["Price(s)"].valid || price.label.en) && enabled["en"] ? "Invalid input" : ""}
          placeholder="e.g. Adult"
          labelPosition="top"
          value={price.label.en || ""}
          onChange={(e) => handleEnglishLabelChange(e.target.value)}
        />
      </div>
      {/* Faroese */}
      <div
        style={{
          gridArea: "faroese",
          pointerEvents: enabled["fo"] ? "auto" : "none",
          opacity: enabled["fo"] ? 1 : 0.3,
        }}
      >
        <TextInput
          id={"fo_label_" + index}
          label="In Faroese"
          error={!(errors?.[type]?.["Price(s)"].valid || price.label.fo) && enabled["fo"] ? "Invalid input" : ""}
          placeholder="e.g. Vaksin"
          labelPosition="top"
          value={price.label.fo || ""}
          onChange={(e) => handleFaroeseLabelChange(e.target.value)}
        />
      </div>
    </div>
  );
}
