import "../Product.scss";
import Select from "react-select";

export default function InventoryPrice({ editInventory, productState, setForceUpdate }) {
  return (
    <div className="price">
      <div>
        <h3>Price</h3>
      </div>
      <div>
        <label>Netto Price</label>
        <input
          defaultValue={editInventory?.sellingPrice ?? 0}
          onChange={(e) => {
            if (editInventory) {
              productState.varient.map((varient) => {
                if (varient.varient_id === editInventory.varient_id) {
                  varient.nettoPrice = e.target.value;
                }
                return varient;
              });
              setForceUpdate((f) => !f);
            }
          }}
        />
      </div>
      <div className="extra-price">
        <div>
          <label>Extra Price</label>
          <input
            defaultValue={editInventory?.prices?.rate}
            onChange={(e) => {
              const value = JSON.parse(e.target.value === "" ? 0 : e.target.value);
              if (editInventory) {
                if (!editInventory.prices) {
                  editInventory.prices = {
                    rate: value,
                  };
                } else {
                  editInventory.prices.rate = value;
                }
                editInventory.prices.changeState = editInventory.prices.new ? "new" : "update";
                setForceUpdate((f) => !f);
              }
            }}
          />
        </div>
        <div className="select-container">
          <label>Rate Type</label>
          <Select
            className="select"
            options={[
              { label: "Percent", value: "percent" },
              { label: "Fixed", value: "fixed" },
            ]}
            defaultValue={editInventory?.prices?.rateType}
            onChange={(e) => {
              if (editInventory) {
                if (!editInventory.prices) {
                  editInventory.prices = { rateType: e };
                } else {
                  editInventory.prices.rateType = e;
                }
                editInventory.prices.changeState = editInventory.prices.new ? "new" : "update";
                setForceUpdate((f) => !f);
              }
            }}
          ></Select>
        </div>
      </div>
    </div>
  );
}
