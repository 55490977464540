// DescriptionSlice.ts
import { create } from "zustand";
import { NavigationSlice, createNavigationSlice } from "./NavigationStore";
import { DescriptionSlice, createDescriptionSlice } from "./description-store/DescriptionStore";
import { TypesSlice, createTypesSlice } from "./type-store/TypesStore";
import { DateSlice, createDateSlice } from "./date-store/DateStore";
import { PriceSlice, createPriceSlice } from "./price-store/PriceStore";
import { GeneralViewManagerSlice, createGeneralViewManagerSlice } from "./GeneralViewManagerStore";
import { ILocationSlice, createLocationSlice } from "./location-store/LocationStore";
import { IOrganizerSlice, createOrganizerSlice } from "./organizer-store/OrganizerStore";
import { IMediaSlice, createMediaSlice } from "./media-store/MediaStore";
import { IErrorSlice, createErrorSlice } from "./error-store/ErrorStore";
import { createSessionSlice, SessionSlice } from "./session-store/SessionStore";
/**
 * WhatsOnStore Interface
 */
export interface WhatsOnStore
  extends GeneralViewManagerSlice,
    NavigationSlice,
    DescriptionSlice,
    TypesSlice,
    DateSlice,
    PriceSlice,
    ILocationSlice,
    IOrganizerSlice,
    IMediaSlice,
    IErrorSlice,
    SessionSlice {}

export const whatsonStoreCreator = () =>
  create<WhatsOnStore>()((...a) => ({
    ...createGeneralViewManagerSlice(...a),
    ...createNavigationSlice(...a),
    ...createDescriptionSlice(...a),
    ...createTypesSlice(...a),
    ...createDateSlice(...a),
    ...createPriceSlice(...a),
    ...createLocationSlice(...a),
    ...createOrganizerSlice(...a),
    ...createMediaSlice(...a),
    ...createErrorSlice(...a),
    ...createSessionSlice(...a),
  }));

/**
 * Create WhatsOnStore
 */
// export const useWhatsOnStore = whatsonStoreCreator();
