import styles from "./MediaCenter.module.scss";
import MediaUploader from "./MediaUploader";
import MediaGallery from "./MediaGallery";
import AddLocalMedia from "./AddLocalMedia";
import { MediaCenterType, MediaItem } from "./types";

/**
 * Media center with local media and upload functionality
 *
 * @author 					Pætur Mortensen
 */
export default function MediaCenter({ mediaItems = [], onChange = () => {} }: MediaCenterType): JSX.Element {
  /**
   * Insert media items
   *
   * @author 					Pætur Mortensen
   */
  function insert_items(insertMedia: Array<MediaItem>): void {
    const newMediaItems = [...mediaItems];

    // For each inserted medium (add to mediaItems)
    for (const medium of insertMedia) {
      // If item does not exist in items, insert it
      if (!newMediaItems.some((item) => item.mediaID === medium.mediaID)) {
        newMediaItems.push(medium);
      }
    }

    onChange(newMediaItems);
  }

  /**
   * Remove a media item
   *
   * @author 					Pætur Mortensen
   */
  function remove_item(mediaID: number): void {
    const newMediaItems = [...mediaItems];
    newMediaItems.splice(
      newMediaItems.findIndex((item) => item.mediaID === mediaID),
      1
    );
    onChange(newMediaItems);
  }

  return (
    <div className={styles.mediaCenter}>
      <div className={styles.addMedia}>
        <div className={styles.uploadContainer}>
          <MediaUploader
            onUpload={(uploads) => {
              onChange([...mediaItems, ...(uploads as Array<MediaItem>)]);
            }}
          />
        </div>
        <AddLocalMedia onInsert={insert_items} />
      </div>
      <div className={styles.galleryContainer}>
        <MediaGallery media={mediaItems} onRemove={remove_item} />
      </div>
    </div>
  );
}
