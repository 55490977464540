import axios from "axios";
import env from "../../environment.json";
import { create } from "zustand";
import { setType } from "./zustandTypes";
import { ComponentType } from "react";
import { ERoleType, IDataAccess, IRoleParams, iDataPermissions } from "../PermCheck/Interfaces";

export const emptyPermissions: iDataPermissions = {
  articleTypes: {},
  events: {},
  places: {},
  regions: {},
  sites: {},
  specifics: {},
  tourOperators: {},
  tours: {},
  superadmin: false,
};

interface IUser {
  userId?: number;
  superAdmin?: number;
}

interface IUserShort {
  userId: number;
}

interface IGetLoggedInUserReturn {
  loggedInUser: IUser;
  permissions: any /* TODO: This is how `authenticator.php` is displayed in the front.
                    The front part does not use it, so it's probably not necessary to specifically define the type's key */;
  permissionsCheck: iDataPermissions;
}

interface UserState {
  loggedInUser: IUser;
  permissions: any /* TODO: Same value as the above todo. */;
  permissionsCheck: iDataPermissions;
  users: IUserShort[];
  roles: IRoleParams[];
  // roles: any;
  getLoggedInUser: () => Promise<IGetLoggedInUserReturn>;
  getUsers: (forceFromServer?: boolean) => IUserShort[] | Promise<unknown>;
  getRoles: () => void;
}

const userStore = (set: setType<UserState>, get: () => UserState) => ({
  loggedInUser: {},
  permissions: {},
  permissionsCheck: emptyPermissions,
  users: [],
  roles: [
    // { id: 1, permission: "Admin", permission_value: 200 },
    // { id: 2, permission: "Publisher", permission_value: 100 },
    // { id: 3, permission: "Collaborator", permission_value: 50 }, // Giving data for the reason that endpoint call doesn't work..
    { id: -1, permission: "not_a_role_name" as ERoleType, permission_value: 0 },
  ],
  getLoggedInUser: () => {
    return new Promise<IGetLoggedInUserReturn>((resolve) => {
      axios
        .get(`${env.protocol}${env.env}/api/secured/GetMyUser`)
        .then((response) => {
          // console.log("Logged In User:", response);
          const theReturn = {
            loggedInUser: response.data,
            permissions: response.data.permissions,
            permissionsCheck: response.data.permissions_check,
          };
          set(theReturn);
          resolve(theReturn);
        })
        .catch((error) => console.error(error));
    });
  },
  getUsers: (forceGetFromServer = false) => {
    const users = get().users;
    if (users.length === 0 || forceGetFromServer) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${env.protocol}${env.env}/api/secured/GetUsers`)
          .then((response) => {
            set({ users: response.data });
            resolve(response.data);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    } else {
      return users;
    }
  },
  getRoles: () => {
    axios
      .get(`${env.protocol}${env.env}/api/secured/GetUserRoles`)
      .then((response) => {
        set({ roles: response.data });
      })
      .catch((error) => console.error(error));
  },
  filterOutUser: (userId: number) => {
    const users = get().users;
    set({ users: users.filter((user) => user.userId !== userId) });
  },
});

export const useUserStore = create<UserState>(userStore);

export const withUserStore = (BaseComponent: ComponentType<any>) => (props: object) => {
  const store = useUserStore();
  return <BaseComponent {...props} userStore={store} />;
};
