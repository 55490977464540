export const sort: any = (a: any, b: any, sortBy: string, reverse: boolean) => {
  if (a[sortBy] === null || b[sortBy] === null) return -1;

  if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
    return reverse ? 1 : -1;
  }
  if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
    return reverse ? -1 : 1;
  }
  return 0;
};
