import { useState } from "react";
import env from "../../../../environment.json";
import Dropzone from "react-dropzone";
import { faTimes, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Render a media insert field for newTour itinerary
 * This component is currently limited for use inside itinerary, some work required to make more
 * dynamic
 *
 * @param 		{object} 		parent 								Superobject (must be NewTour object)
 * @param 		{media} 		media 								Currently filled in media in form
 * @param 		{string} 		fieldLang 						Language to use with field ('english' or 'faroese')
 * @param 		{int} 			index 								Itinerary index (parent.newTour.itinerary[index])
 * @param 		{callback} 	onChange 							Callback to use with change event
 *
 * @returns 	jsx 															Media insert component
 *
 * @author 					Pætur Mortensen
 */
function MediaInsert({ parent, media, fieldLang, index, onChange }) {
  // Whether there is media to display
  const hasMedia = media.length !== 0;
  // Whether front-end upload dropzone is expanded
  const [dropzoneExpanded, setDropzoneExpanded] = useState(false);
  const uploadHidden = dropzoneExpanded ? "" : "hidden";

  /**
   * Render all currently inserted media
   *
   * @returns 	jsx 										Inserted media elements
   *
   * @author 						Pætur Mortensen
   */
  function render_inserted_media() {
    return (
      <>
        {/* For each inserted image */}
        {media.map((element, i) => {
          return (
            <div
              className={
                "one-image" +
                (parent.state.indexImageDrag === i ? " dragging" : "") +
                (parent.state.indexImageDragOn === i ? " dragging-on" : "")
              }
              key={fieldLang + "_" + index}
              draggable="false"
            >
              <div className="media-wrap">
                {parent.props.isFront && element.elm !== undefined ? (
                  <img src={element.elm} alt="upload" draggable="false" />
                ) : (
                  <img
                    src={env.protocol + env.env + "/uploads/" + element.file_name + "_medium." + element.file_extension}
                    alt="upload"
                    draggable="false"
                  />
                )}
                {/* Remove image button and overlay */}
                <div className="remove-image-wrap">
                  <div
                    className="remove-image"
                    onClick={() => {
                      // Remove the media element
                      media.splice(i, 1);
                      parent.setState({});

                      onChange();
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  /**
   * Temporarily save media
   * Use when user is uploading media from the front end
   *
   * @param 	{fileReference} 	fileRef 					Reference to file to upload
   *
   * @author 					Pætur Mortensen
   */
  function temp_save_media(fileRef) {
    let maxKey = 0;
    media.forEach((element) => {
      if (element.key > maxKey) maxKey = element.key;
    });

    fileRef.forEach((element, i) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          media.push({
            key: maxKey + i + 1,
            elm: reader.result,
            element: element,
            name: element.name,
          });
        },
        false
      );
      reader.readAsDataURL(element);
      reader.addEventListener("loadend", () => parent.setState({}));
    });

    setDropzoneExpanded(false);
    parent.setState({});
  }

  /**
   * Render a backend media insert block
   *
   * @param 	{int} 		index 				Index of the itinerary part
   * @param 	{string} 	fieldLang 		Language for the field
   *
   * @returns 	jsx 									Media insert element
   *
   * @author 					Pætur Mortensen
   */
  function render_backend_insert_block(index, fieldLang) {
    return (
      <div
        className="media-insert-block"
        onClick={() => {
          parent.props.setSiteRef(1);
          parent.props.openOverlay("mediaChoose", {
            filter: { mediaType: ["images"] },
            callbackArg: { index, fieldLang, type: "itinerary-part-img" },
          });
        }}
      >
        <div className="upload-box">
          <div className="image-container">
            <FontAwesomeIcon className="before-image" icon={faUpload} />
            <div className="media-inserter-text">{parent.language.upload_insert_image}</div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render a block for insertion on the frontend
   *
   * @returns 	jsx 								Frontend insert block
   *
   * @author 					Pætur Mortensen
   */
  function render_frontend_insert_block() {
    return (
      <div className="add-new-media-content">
        <div
          className="add-new-media"
          onClick={() => {
            setDropzoneExpanded(!dropzoneExpanded);
          }}
        >
          <div className="text">{parent.language.add_new_media}</div>
        </div>
        <div className="upload-media-parent">
          <Dropzone onDrop={temp_save_media}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} accept=".jpg,.jpeg,.png,.gif,.mp4,.gpx" maxLength={1} />
                  <div className={"upload-box " + uploadHidden}>
                    <div className="text-content">
                      <div className="first">{parent.language.drop_files_or_click_to_upload}</div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }

  return (
    <div className="media-inserter vertical">
      {/* If there is media, show images, else show inserter (front or back) */}
      {hasMedia
        ? render_inserted_media()
        : !parent.props.isFront
        ? render_backend_insert_block(index, fieldLang)
        : render_frontend_insert_block()}
    </div>
  );
}

export default MediaInsert;
