import { create } from "zustand";

interface ScrollStore {
  scrollElements: HTMLElement[];
  addScrollElement: (element: HTMLElement) => void;
  scrollToTop: (scrollBehaviour?: ScrollBehavior) => void;
  garbageCollect: () => void;
}

const useScrollStore = create<ScrollStore>((set, get) => ({
  scrollElements: [],
  addScrollElement: (element) =>
    set((state) => {
      return { scrollElements: [...state.scrollElements, element].filter(Boolean) as HTMLElement[] };
    }),
  scrollToTop: (scrollBehaviour: ScrollBehavior = "smooth") => {
    const { scrollElements } = get();
    const lastElement = scrollElements.filter(Boolean).slice(-1)[0];
    if (lastElement) {
      lastElement.scrollTo({ top: 0, behavior: scrollBehaviour });
    }
  },
  garbageCollect: () =>
    set((state) => ({
      scrollElements: state.scrollElements.filter((el) => el && document.contains(el)) as HTMLElement[],
    })),
}));

// Set up a MutationObserver to watch for DOM changes and clean up removed elements
const observer = new MutationObserver(() => {
  const { garbageCollect } = useScrollStore.getState();
  garbageCollect();
});

// Start observing the document body
observer.observe(document.body, { childList: true, subtree: true });

export default useScrollStore;
