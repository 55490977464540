import { useEffect, useState } from "react";
import InfoscreenView from "../InfoscreenView/InfoscreenView";
import styles from "./AdminDisplay.module.scss";
import { adminSections } from "./sections";
import SitesDisplay from "./AdminSections/SitesDisplay/SitesDisplay";
import Leaflet from "../Leaflet/Leaflet";
import Appearances from "./AdminSections/Sites/appearances";
import AmenitiesDisplay from "./AdminSections/Amenities/AmenitiesDisplay";
import RedirectsDisplay from "./AdminSections/Redirects/RedirectsDisplay";
import { useRouterStore } from "src/Components/Router/routerStore";
import PermCheck from "src/Components/PermCheck";

const level = 1;

interface Props {
  language: string;
  adminMenu: string;
  setSiteRef: any;
  openOverlay: any;
  infoscreenMenu: any;
  setConfirmBeforeNavigate: any;
  addOne: any;
}

export default function AdminDisplay(props: any) {
  const { route, setRouter, navTo } = useRouterStore();

  const [selectedView, setSelectedView] = useState<string>("" /* props.adminMenu */);

  return (
    <div className={styles.container}>
      <Leaflet
        title={"Admin"}
        sections={adminSections}
        selectedView={{ name: route[level]?.nav, key: "" }}
        setSelectedView={(view) => {
          navTo(level, {
            nav: view.name,
            sidebar: "",
            displayName: view.name,
          });
          //   setRouter({
          //   route: [route[0], {
          //   nav: view,
          //   sidebar: '',
          //   displayName: view,
          // }] });
        }}
      />

      {/* <div>
        Test if user has permission to place with id 7 as a minimum role of
        Publisher.
      </div>
      <PermCheck type={"places"} minimumRole="Publisher" id={7}>
        <div style={{ color: "green" }}>Yes he has</div>
      </PermCheck> */}

      <div className={styles.content}>
        {route[level]?.nav === "sites" && (
          <>
            {/* <SitesDisplay /> */}
            <Appearances
              setSiteRef={props.setSiteRef}
              openOverlay={props.openOverlay}
              setConfirmBeforeNavigate={props.setConfirmBeforeNavigate}
              listOptions={[]}
            />
          </>
        )}

        {route[level]?.nav === "infoscreens" && (
          <div className="infoscreen-content">
            <span className="shadow-maker" />
            <InfoscreenView
              infoscreenMenu={"screenManagement"}
              openOverlay={props.openOverlay}
              setConfirmBeforeNavigate={props.setConfirmBeforeNavigate}
              setSiteRef={props.setSiteRef}
              newData={props.addOne}
            />
          </div>
        )}

        {route[level]?.nav === "amenities" && <AmenitiesDisplay />}
        {route[level]?.nav === "redirects" && <RedirectsDisplay />}
      </div>
    </div>
  );
}
