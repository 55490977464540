import React, { Component } from "react";
import env from "../../../../../environment.json";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons";
import { faSortAlt } from "@fortawesome/pro-solid-svg-icons";
import { faFilePlus } from "@fortawesome/pro-light-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import helperfunctions from "../../../../../assets/helperFunctions";
import VfiInputText from "../../../../../assets/VfiInputText/VfiInputText";
import empty from "../../../../../assets/images/empty.jpg";
import { GlobalToolTipController } from "../../../../ToolTips/GlobalToolTip";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedBy: "date",
      reverse: true,
      searchValue: "",
    };

    this.axios = require("axios");
    this.activity = [];
    this.sitesChecked = [];
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    this.search = { bool: true, searchValue: "" };
    this.filterContent = [
      {
        headline: "Date",
        value: [{ startDate: oneYearAgo, endDate: new Date() }],
        style: "date",
      },
      {
        headline: "Sites",
        value: [],
        style: "checkbox",
      },
    ];
    // this.openFilter = this.openFilter.bind(this);
  }

  componentDidMount() {
    this.axios
      .post(env.protocol + env.env + "/api/secured/getActivity", {
        vfiUser: sessionStorage.getItem("vfiUser"),
        clickedUser: this.props.userClicked,
      })
      .then((response) => {
        this.activity = [];

        if (response.data.length > 0) {
          response.data.forEach((e) => {
            this.activity.unshift({
              userId: e.userId,
              siteId: e.siteId,
              siteName: e.siteName,
              medium: e.medium,
              localImg: e.localImg,
              localImg_extension: e.localImg_extension,
              type: e.type,
              postName: e.posts,
              regdate: e.regdate,
              time_since_reg: e.time_since_reg,
              activity: e.activity,
            });
          });

          helperfunctions.sortList("regdate", this.activity, false);
          this.setState({});
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  sitesToMap() {
    const sites = [];
    this.props.listOptions.forEach((element) => {
      if (element.index !== "0") {
        sites.push({
          text: element.name,
          selected: this.sitesChecked.includes(element.name),
        });
      }
    });
    return sites;
  }

  checkboxFiltering(list, locateAttr) {
    //Filter the list when checkbox in filter changes
    const filter = this.sitesChecked;

    let tempList = [];
    if (filter.includes("all") || filter.length === 0) {
      //If show all or if we are filtering wrong data return all
      tempList = list;
    } else {
      filter.forEach((element) => {
        const filteredList = list.filter((q) =>
          q[locateAttr] !== undefined
            ? q.type === "User"
              ? q[locateAttr].includes(element)
              : q[locateAttr] === element
            : ""
        );
        tempList = tempList.concat(filteredList);
      });
      //   }
    }
    return tempList;
  }

  onDateChange = (whatdate, date) => {
    this.filterContent[0].value[0][whatdate] = date;
    this.props.SetState("filterdata.content", this.filterContent); //update the array in main
  };

  onCheckboxChange = (e) => {
    // When checking a checkbox we document it and update the array
    const index = this.sitesChecked.indexOf(e.target.name);
    if (index > -1) {
      this.sitesChecked.splice(index, 1);
    } else {
      this.sitesChecked.push(e.target.name);
    }

    this.filterContent[1].value = this.filterContent[1].value.map((element) => ({
      text: element.text,
      selected: this.sitesChecked.includes(element.text),
    }));

    this.props.SetState("filterdata.content", this.filterContent); //update the array in main
  };

  onSearchChange = (e) => {
    //Change search value
    this.search.searchValue = e.target.value;
    this.props.SetState("filterdata.search", this.search);

    // Change list depending on search
    this.filterContent[1].value = helperfunctions.searchFilter(this.sitesToMap(), this.search.searchValue);
    this.props.SetState("filterdata.content", this.filterContent);
  };

  render() {
    const overlayStyle = {
      margin: "20px 20px 20px -20px",
    };

    const filterHidden =
      this.props.addFilterDroppedDown[0] && this.props.addFilterDroppedDown[1] === "activity" ? "" : "hidden";
    let searchContent = helperfunctions.searchFilter(this.activity, this.state.searchValue);
    searchContent = this.checkboxFiltering(searchContent, "siteName");
    var date = this.filterContent[0].value[0];
    searchContent = helperfunctions.filterDateInterval(searchContent, date.startDate, date.endDate);

    return (
      <div className="activity">
        <div className="search-parent">
          <div className="search">
            <VfiInputText
              value={this.state.searchValue}
              placeholder="Search activity..."
              onChange={(e) => {
                this.setState({ searchValue: e.target.value });
              }}
              isSearch={true}
            />
          </div>
        </div>

        <div className="all-sort-and-filter">
          <div
            className={"filter-button " + filterHidden}
            onClick={() => {
              this.filterContent[1].value = this.sitesToMap();
              this.props.openCloseFilter([!this.props.addFilterDroppedDown[0], "activity"], {
                type: "activity",
                search: this.search,
                style: overlayStyle,
                content: this.filterContent,
                executeCheckbox: (e) => {
                  // What happens when elements are clicked
                  this.onCheckboxChange(e);
                },
                executeDate: (whatdate, date) => {
                  this.onDateChange(whatdate, date);
                },
                executeSearch: (e) => {
                  this.onSearchChange(e);
                },
              });
            }}
          >
            <span className="text">Filter</span>
            <FontAwesomeIcon className="slide-icon" icon={faSlidersH} flip={"vertical"} />
          </div>
        </div>

        <table className="activity-table">
          <thead>
            <tr>
              <th
                className={this.state.sortedBy === "post" ? "post sorted-by" : "post"}
                onClick={() => {
                  helperfunctions.sortList("postName", searchContent, this.state.sortedBy === "post");
                  let reverse = false;
                  if (this.state.sortedBy === "post" && this.state.reverse === false) reverse = true;
                  this.setState({ sortedBy: "post", reverse: reverse });
                }}
              >
                Page
                <FontAwesomeIcon
                  icon={this.state.sortedBy === "post" ? faSortAltSorted : faSortAlt}
                  flip={"vertical"}
                  transform={this.state.reverse ? {} : { rotate: 180 }}
                />
              </th>
              <th
                className={this.state.sortedBy === "site" ? "site sorted-by" : "site"}
                onClick={() => {
                  helperfunctions.sortList("siteName", searchContent, this.state.sortedBy === "site");
                  let reverse = false;
                  if (this.state.sortedBy === "site" && this.state.reverse === false) reverse = true;
                  this.setState({ sortedBy: "site", reverse: reverse });
                }}
              >
                Site
                <FontAwesomeIcon
                  icon={this.state.sortedBy === "site" ? faSortAltSorted : faSortAlt}
                  flip={"vertical"}
                  transform={this.state.reverse ? {} : { rotate: 180 }}
                />
              </th>
              <th
                className={this.state.sortedBy === "activity" ? "activity sorted-by" : "activity"}
                onClick={() => {
                  helperfunctions.sortList("activity", searchContent, this.state.sortedBy === "activity");
                  let reverse = false;
                  if (this.state.sortedBy === "activity" && this.state.reverse === false) reverse = true;
                  this.setState({ sortedBy: "activity", reverse: reverse });
                }}
              >
                Activity
                <FontAwesomeIcon
                  icon={this.state.sortedBy === "activity" ? faSortAltSorted : faSortAlt}
                  flip={"vertical"}
                  transform={this.state.reverse ? {} : { rotate: 180 }}
                />
              </th>

              <th
                className={this.state.sortedBy === "date" ? "date sorted-by" : "date"}
                onClick={() => {
                  helperfunctions.sortList("regdate", searchContent, this.state.sortedBy === "date");
                  let reverse = false;
                  if (this.state.sortedBy === "date" && this.state.reverse === false) reverse = true;
                  this.setState({ sortedBy: "date", reverse: reverse });
                }}
              >
                Date
                <FontAwesomeIcon
                  icon={this.state.sortedBy === "date" ? faSortAltSorted : faSortAlt}
                  flip={"vertical"}
                  transform={this.state.reverse ? {} : { rotate: 180 }}
                />
              </th>
            </tr>
          </thead>
          <tbody>{this.mapActivity(searchContent)}</tbody>
        </table>
      </div>
    );
  }

  mapActivity(searchContent) {
    const protocolAndHost = env.protocol + env.env;

    var activityList = searchContent.map((data, i) => {
      return (
        <tr key={i}>
          <td className="page">
            {data.localImg !== null ? (
              <img src={data.medium.thumbnail} alt={data.medium.info.alt} />
            ) : (
              <img src={empty} alt="user img" />
            )}
            {data.postName}
          </td>
          <td className="site">{data.siteName}</td>
          <td className="Activity">
            <FontAwesomeIcon icon={faFilePlus} />
            {data.activity}
          </td>
          <td className="Date">{data.time_since_reg}</td>
        </tr>
      );
    });

    return activityList;
  }
}
export default Activity;
