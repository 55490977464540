import { StateCreator } from "zustand";
import { ValidateTypes } from "./utils";
import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";

// Assuming ICategory is imported or defined in the same file
/**
 * Category Interface
 */
export interface ICategory {
  id: number;
  slug: string;
  category_en: string;
  category_fo: string;
}
/**
 * Types Slice Interface
 */
export interface TypesSlice {
  selectedTypes: { [key: number]: ICategory };
  types_valid: boolean;

  /**
   * setTypesSlice - Full setter
   *
   * @param partials
   *
   * @example
   * setTypesSlice({ selectedTypes: { 1: { id: 1, slug: "slug", category_en: "category_en", category_fo: "category_fo" } }, types_valid: true })
   *
   * @returns void
   */
  setTypesSlice: (partials: Partial<TypesSlice>) => void;

  /**
   * Toggle Selected Type
   *
   * @param category
   *
   * @returns void
   */
  toggleSelectedType: (category: ICategory) => void;
  /**
   * Validate Types
   *
   * @returns boolean
   */
  validateTypes: () => boolean;
  /**
   * Reset type slice
   */
  resetTypeSlice: () => void;
}

const INITIAL_STATES = () => ({
  selectedTypes: {},
  types_valid: false,
});

/**
 * Create Types Slice
 */
export const createTypesSlice: StateCreator<TypesSlice> = (set, get) => ({
  ...INITIAL_STATES(),

  /**
   * Setters / Actions
   */
  setTypesSlice: (partials) => set({ ...partials }),

  toggleSelectedType: (category) =>
    set((state) => ({
      selectedTypes: state.selectedTypes[category.id]
        ? Object.fromEntries(Object.entries(state.selectedTypes).filter(([key]) => Number(key) !== category.id)) // Remove if exists
        : { ...state.selectedTypes, [category.id]: category }, // Add if doesn't exist
    })),
  validateTypes: () => {
    const state = get();
    const whatson_store = useWhatsonManager.getState().getStore();
    const { valid, types } = ValidateTypes(state.selectedTypes);
    const { setError } = whatson_store.getState();
    setError("Type", valid);
    set(() => {
      return {
        types_valid: valid,
        selectedTypes: types,
      };
    });
    return valid;
  },
  resetTypeSlice: () => {
    set(() => ({ ...INITIAL_STATES() }));
  },
});
