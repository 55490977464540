import { useState, useEffect } from "react";
import axios from "axios";
import env from "../../../../environment.json";
import { IMedia } from "../types";

export const useMediaExtension = (media: IMedia, ext: string | null | undefined, src: string) => {
  const [foundExtension, setFoundExtension] = useState(undefined);

  useEffect(() => {
    if (media.id && !Boolean(ext) && !src) {
      axios
        .get(`${env.protocol}${env.env}/api/public/GetMedia.php?id=${media.id}`)
        .then((response) => {
          if (response.data?.file_extension) {
            setFoundExtension(response.data.file_extension);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [media, ext, src]);

  return foundExtension;
};
