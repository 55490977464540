import axios from "axios";
import env from "../../environment.json";
import { create } from "zustand";
// import { setType } from "./zustandTypes";
import { ComponentType } from "react";
import { articleType } from "../Content/ArticlesDisplay/types";
import { setType, zustandMiddlewares } from "./zustandTypes";

interface ArticlesState {
  articleTypes: articleType[];
  getArticleTypes: () => void;
}

const articlesStore = (set: setType<ArticlesState>) => ({
  articleTypes: [],
  getArticleTypes: () => {
    axios
      .get(`${env.protocol}${env.env}/api/public/infoscreen/infoscreenArticles/getArticleTypes`)
      .then((response) => {
        const articleTypes: articleType[] = response.data;
        set({ articleTypes: articleTypes });
      })
      .catch((error) => console.error(error));
  },
});

export const useArticlesStore = create<ArticlesState>(articlesStore);

export const withArticlesStore = (BaseComponent: ComponentType<any>) => (props: object) => {
  const store = useArticlesStore();
  return <BaseComponent {...props} articlesStore={store} />;
};
