import { useEffect, useState } from "react";
import styles from "./MobileWarning.module.scss";

export default function MobileWarning(): JSX.Element | null {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipod|android.*mobile|windows phone|blackberry/g.test(userAgent);

      if (isMobile && !sessionStorage.getItem("showMobileWarning")) {
        setShowWarning(true);
      }
    };

    handleDeviceDetection();
  }, []);

  const closeWarning = () => {
    setShowWarning(false);
    sessionStorage.setItem("showMobileWarning", "true");
  };

  if (!showWarning) {
    return null;
  }

  return (
    <div className={styles.warningContainer}>
      <div className={styles.warningContent}>
        <h2 className={styles.warningHeader}>Not Optimized for Mobile</h2>
        <p className={styles.warningText}>
          This site is designed for desktop devices and may not function properly on mobile devices. Please use a
          desktop for the best experience.
        </p>
        <button className={styles.warningButton} onClick={closeWarning}>
          Close
        </button>
      </div>
    </div>
  );
}
