import { group } from "console";
import styles from "../RedirectsDisplay.module.scss";
import { HeaderType } from "../types";

/**
 * Header section for redirects
 *
 * @author 					Pætur Mortensen
 */
export default function Header({
  setCreateNew,
  selectedCount,
  setDeleteRedirect,
  inEdit,
  setInEdit,
}: HeaderType): JSX.Element {
  return (
    <div className={styles.header}>
      <h1>301 Redirects</h1>
      <div className={styles.buttonGroup}>
        {selectedCount > 0 && (
          <button
            className={styles.deleteRedirect}
            onClick={() => {
              setDeleteRedirect(true);
            }}
          >
            Delete selected
          </button>
        )}
        {!inEdit ? (
          <button
            className={styles.headerButton}
            onClick={() => {
              setInEdit(true);
            }}
          >
            Edit
          </button>
        ) : (
          <button
            className={`${styles.headerButton} ${styles.endEdit}`}
            onClick={() => {
              setInEdit(false);
            }}
          >
            End Edit
          </button>
        )}
        <button
          className={`${styles.headerButton} ${styles.addRedirect}`}
          onClick={() => {
            setCreateNew(true);
          }}
        >
          Add new redirect
        </button>
      </div>
    </div>
  );
}
