import { useEffect, useState, useRef, createRef } from "react";
import CloseBtn from "./closeBtn";
import "./toolTip.scss";

// Documentation at bottom

export default function ToolTip({
  children,
  className = "",
  offset = { x: 0, y: 0 },
  toolTipElements = <></>,
  click = false,
  solid = false,
  priority = "right,bottom,left,top",
  delay = 0,
  opacity = 1,
  title = "Tooltip",
  onClick = () => {},
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [tooltipSize, setTooltipSize] = useState({ width: 0, height: 0 });
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });
  const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
  const [side, setSide] = useState("left");

  const ref = useRef(null);
  const tooltipRef = createRef(null);
  const exitBtnRef = createRef(null);

  function setTooltipPosition(elementOffset) {
    let order = priority.split(",");

    let x1 = elementOffset.x + elementSize.width / 2 - tooltipSize.width / 2;
    let x2 = x1 + tooltipSize.width;

    let y1 = elementOffset.y + elementSize.height / 2 - tooltipSize.height / 2;
    let y2 = y1 + tooltipSize.height;

    for (let i = 0; i < 4; i++) {
      switch (order[i]) {
        case "bottom":
          if (y2 + elementSize.height / 2 + tooltipSize.height / 2 + offset.y >= window.innerHeight) continue;

          setSide(order[i]);
          setTooltipPos({
            x: x1 + offset.x,
            y: y1 + elementSize.height / 2 + offset.y + tooltipSize.height / 2 + 10,
          });
          return;
          break;
        case "top":
          if (y1 - elementSize.height / 2 - tooltipSize.height / 2 - offset.y <= 0) continue;

          setSide(order[i]);
          setTooltipPos({
            x: x1 + offset.x,
            y: y1 - elementSize.height / 2 - offset.y - tooltipSize.height / 2 - 10,
          });
          return;
          break;
        case "left":
          if (x1 - elementSize.width / 2 - tooltipSize.width / 2 - offset.x <= 0) continue;

          setSide(order[i]);
          setTooltipPos({
            x: x1 - elementSize.width / 2 - offset.x - tooltipSize.width / 2 - 10,
            y: y1 + offset.y,
          });
          return;
          break;
        case "right":
          if (x2 + elementSize.width / 2 + tooltipSize.width / 2 + offset.x >= window.innerWidth) continue;

          setSide(order[i]);
          setTooltipPos({
            x: x1 + elementSize.width / 2 + offset.x + tooltipSize.width / 2 + 10,
            y: y1 + offset.y,
          });
          return;
          break;
        default:
          setTooltipPos({
            x: window.innerWidth / 2 - tooltipSize.width / 2,
            y: window.innerHeight / 2 - tooltipSize.height / 2,
          });
      }
    }
  }

  useEffect(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    let mouseInTooltip = false;

    const element = ref.current;
    setElementSize({
      width: element.offsetWidth,
      height: element.offsetHeight,
    });

    const tooltip = tooltipRef.current;
    const exitBtn = exitBtnRef.current;
    if (tooltipRef?.current)
      setTooltipSize({
        width: tooltip.offsetWidth,
        height: tooltip.offsetHeight,
      });

    const tooltipMouseEnter = (e) => {
      mouseInTooltip = true;
    };

    const tooltipMouseLeave = (e) => {
      mouseInTooltip = false;
    };

    const handleClick = (e) => {
      setIsOpen(!isOpen);

      let rect = element.getBoundingClientRect();

      if (solid) {
        setTooltipPosition(rect);
      } else {
        let x =
          e.clientX + offset.x + tooltipSize.width >= window.innerWidth
            ? e.clientX - offset.x - tooltipSize.width
            : e.clientX + offset.x <= 0
            ? 0
            : e.clientX + offset.x;

        let y =
          e.clientY + offset.y + tooltipSize.height >= window.innerHeight
            ? e.clientY - offset.y - tooltipSize.height
            : e.clientY + offset.y <= 0
            ? 0
            : e.clientY + offset.y;

        setTooltipPos({ x: x, y: y });
      }
    };

    const handleMouseenter = (e) => {
      setIsOpen(true);

      let rect = element.getBoundingClientRect();

      if (solid) setTooltipPosition(rect);
    };

    const handleMouseover = (e) => {
      let x =
        e.clientX + offset.x + tooltipSize.width >= window.innerWidth
          ? e.clientX - offset.x - tooltipSize.width
          : e.clientX + offset.x <= 0
          ? 0
          : e.clientX + offset.x;

      let y =
        e.clientY + offset.y + tooltipSize.height >= window.innerHeight - 10
          ? window.innerHeight - tooltipSize.height - 10
          : e.clientY + offset.y <= 0
          ? 0
          : e.clientY + offset.y;

      if (tooltip) {
        tooltip.style.left = x + "px";
        tooltip.style.top = y + "px";
      }
    };

    const handleMouseleave = (e) => {
      setIsOpen(false);

      element.addEventListener("mouseenter", handleMouseenter);
      element.removeEventListener("mousemove", handleMouseover);
    };

    const handleScroll = (e) => {
      if (!mouseInTooltip) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (e) => {
      let rect = element.getBoundingClientRect();
      let x1 = rect.left;
      let y1 = rect.top;
      let x2 = rect.right;
      let y2 = rect.bottom;

      if (!mouseInTooltip && !(e.clientX >= x1 && e.clientX <= x2 && e.clientY >= y1 && e.clientY <= y2))
        setIsOpen(false);
    };

    if (tooltipRef?.current) tooltip.addEventListener("mouseenter", tooltipMouseEnter);
    if (tooltipRef?.current) tooltip.addEventListener("mouseleave", tooltipMouseLeave);

    if (click) {
      element.addEventListener("click", handleClick);
      exitBtn?.addEventListener("click", handleClick);
      window.addEventListener("wheel", handleScroll, false);
      window.addEventListener("click", handleDocumentClick);
    } else {
      if (!solid) element.addEventListener("mousemove", handleMouseover);
      element.addEventListener("mouseenter", handleMouseenter);
      element.addEventListener("mouseleave", handleMouseleave);
    }

    return () => {
      element.removeEventListener("click", handleClick);
      exitBtn?.removeEventListener("click", handleClick);
      element.removeEventListener("mousemove", handleMouseover);
      element.removeEventListener("mouseenter", handleMouseenter);
      element.removeEventListener("mouseout", handleMouseleave);
      window.removeEventListener("wheel", handleScroll, false);
      tooltip?.removeEventListener("mouseenter", tooltipMouseEnter);
      tooltip?.removeEventListener("mouseleave", tooltipMouseLeave);
      window.removeEventListener("click", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <div ref={ref} className={`${className} ${"tooltip-click-element"}`} onClick={onClick}>
        {children}
      </div>

      {isOpen ? (
        <div style={{ position: "absolute", overflow: "visible" }}>
          <div
            ref={tooltipRef}
            style={{
              left: tooltipPos.x,
              top: tooltipPos.y,
              visibility: "hidden",
              animationDuration: `${click ? 0 : delay / 1000}s`,
              animationName: isOpen ? "show" : "",
              opacity: 1,
            }}
            className={`${"easy-tooltip"} ${click ? "click" : "hover"}`}
          >
            {title === "" ? (
              <></>
            ) : (
              <>
                <div className="top-container">
                  <h1 className={"title"}>{title}</h1>
                  {click ? (
                    <div ref={exitBtnRef} className={"close-btn"}>
                      <CloseBtn width={18} height={18} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}

            {solid ? (
              <div
                style={{
                  top:
                    side === "top"
                      ? tooltipPos.y + tooltipSize.height - 5
                      : side === "bottom"
                      ? tooltipPos.y - 5
                      : tooltipPos.y + tooltipSize.height / 2 - 5,
                  left:
                    side === "left"
                      ? tooltipPos.x + tooltipSize.width - 5
                      : side === "right"
                      ? tooltipPos.x - 5
                      : tooltipPos.x + tooltipSize.width / 2 - 5,
                }}
                className={"arrow"}
              ></div>
            ) : (
              <></>
            )}

            <div className={`${"tooltip-body"} ${click ? "click" : "hover"}`}>{toolTipElements}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

/*
    ToolTip wraps an element by replacing it's current div with ToolTop.

    old: <div className="className...">{children}</div>
    new: <ToolTip className="className...">{children}</ToolTip>

    ToolTip doesn't require any attributes, but it's recommended, that it
    wraps atleast 1 child element, has a classname and that you also include your
    tooltip elements

    The attributes can be set as follows:

        <ToolTip 
            solid  
                !- Tells the tooltip if it should position                  -!
                !- it self at a static position or based on mouse position  -!

            click 
                !- Tells the tooltip if it should display on hover or click -!

            title="title..." 
                !- Adds a title if the element is clickable, default is just "Tooltip" -!

            toolTipElements={< someJsxComponent />}

            other attrubutes are:
                offset, priority, opacity and delay

            className="className..."
            >
                {children}
            /ToolTip>


            // Component example (solid, clickable):
            <ToolTip
                click
                solid
                offset={{x: 100, y: 0}}
                priority={'right,bottom'}
                title="Title"
                delay={1000}
                toolTipElements={(
                <div>
                    <p>Here's some cool facts about a button</p>
                    <p className="awesome-thing"></p>
                    <button style={{ border: 'none', color: '#000', backgroundColor: '#fff', padding: 0, textDecoration: 'underline' }} onClick={(e) => {let el = document.querySelector('.awesome-thing'); el.innerHTML = el.innerHTML == ''? "More text" : ''; e.target.innerHTML = e.target.innerHTML = 'Show more'? 'Show less' : 'Show more'}}>Show more</button>
                </div>
            )}>
                {children}
            </ToolTip>


            // Component example (floating, hovered):
            <ToolTip
            offset={{x: 30, y: 30}}
            title=""
            delay={1000}
            toolTipElements={(<div>{element.text}</div>)}>
                {children}
            </ToolTip>
*/
