import styles from "./XmarkButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function XmarkButton({ onClick, className }: { onClick: () => void; className?: string }) {
  const classNames = [styles.datePickerClearButton, className].join(" ");

  return (
    <button className={classNames} onClick={onClick}>
      <FontAwesomeIcon icon={faXmark as IconProp} />
    </button>
  );
}

export default XmarkButton;
