class VerifyMethods {
  static notEmpty(e) {
    if (e === "") {
      return "Text is missing.";
    } else {
      return "";
    }
  }

  static requiredField(e) {
    if (e === "") {
      return "Required field missing.";
    } else {
      return "";
    }
  }

  static validEmail(e) {
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      return "";
    } else {
      return "Not valid email.";
    }
  }

  static confirmEmail(email1, email2) {
    if (email1 === email2) {
      return "";
    } else {
      return "Emails are not the same.";
    }
  }

  static validPassword(e) {
    const re = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
    if (re.test(e)) {
      return "";
    } else {
      return "Not valid password.";
    }
  }

  static noSpace(e) {
    if (e.search(" ") !== -1) {
      return "No space allowed.";
    } else {
      return "";
    }
  }

  static least3Char(e) {
    if (e.length < 3) {
      return "Enter at least 3 characters.";
    } else {
      return "";
    }
  }

  static least10Char(e) {
    if (e.length < 10) {
      return "Enter at least 10 characters.";
    } else {
      return "";
    }
  }

  static least70Char(e) {
    if (e.length < 70) {
      return "Enter at least 70 characters.";
    } else {
      return "";
    }
  }

  static least100Char(e) {
    if (e.length < 100) {
      return "Enter at least 100 characters.";
    } else {
      return "";
    }
  }

  static isInstagramLink(e) {
    let url;
    try {
      url = new URL(e);
    } catch (_) {
      return "The link is not a valid URL.";
    }

    if (!["instagram.com", "www.instagram.com"].includes(url.host)) {
      return "The link is not an instagram link.";
    }

    return "";
  }

  /**
   * Checks if the given string is a valid URL or is empty.
   *
   * @param {string} e - The string to validate as a URL.
   * @param {boolean} allowEmpty - Whether to allow an empty string.
   * @returns {string} - Returns an empty string if the URL is valid or empty, otherwise returns an error message.
   *
   * @author BJD
   */
  static isUrlValid(e, allowEmpty = false) {
    // Trim leading and trailing spaces
    const trimmed = e.trim();

    // Check if the string is allowed to be empty and if it is empty
    if (allowEmpty && trimmed === "") {
      return "";
    }

    // Regex to check if the string is a valid URL, allowing international characters
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-zA-Z0-9\u00a1-\uffff]([a-zA-Z0-9\u00a1-\uffff-]*[a-zA-Z0-9\u00a1-\uffff])*)\\.)+[a-zA-Z\u00a1-\uffff]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-zA-Z0-9\u00a1-\uffff%_.~+]*)*" + // port and path
        "(\\?[;&a-zA-Z0-9\u00a1-\uffff%_.~+=-]*)?" + // query string
        "(\\#[-a-zA-Z0-9\u00a1-\uffff_]*)?$", // fragment locator
      "i" // case insensitive
    );

    // Check if the string is a valid URL
    if (pattern.test(trimmed)) {
      return "";
    } else {
      return "Not a valid URL.";
    }
  }

  /**
   * Validate minimum characters
   *
   * @param 		{string} 	checkStr 							String to validate
   * @param 		{int} 		min 									Minimum number of characters
   *
   * @returns 	{bool}													Whether validation passed
   *
   * @author 					Pætur Mortensen
   */
  static minChars(checkStr, min) {
    return checkStr?.length < min ? "Enter at least " + min + " characters" : "";
  }

  /**
   * Validate maximum number of characters
   *
   * @param 		{string} 	checkStr 								String to check
   * @param 		{int} 		max 										Maximum number of characters
   *
   * @returns 	{bool} 														Whether validation passed
   *
   * @author 					Pætur Mortensen
   */
  static maxChars(checkStr, max) {
    return checkStr.length < max ? "Enter no more than " + max + " characters" : "";
  }

  static extensionIsVideo(extension) {
    if (!Boolean(extension)) return false;

    const value = extension.toLowerCase();

    if (value === "mp4" || value === "avi" || value === "wmv" || value === "webm") {
      return true;
    } else {
      return false;
    }
  }

  static extensionIsImage(extension) {
    if (!Boolean(extension)) return false;

    const value = extension.toLowerCase();

    if (value === "jpg" || value === "jpeg" || value === "png") {
      return true;
    } else {
      return false;
    }
  }
}
export default VerifyMethods;
