import React from "react";
import { modulesConfig } from "src/utils";
// import styles from "./Notification.module.scss";
// import { storeNotificationToken } from "./storeNotificationToken";

function NotificationProvider({ children }: { children: React.ReactNode }) {
  // If notifications are disabled, render the children without the notification provider
  if (!modulesConfig.notifications.enabled) return children;

  return (
    <>
      {children}
      {/* <div className={styles.testContainer}>
        <button
          type="button"
          onClick={() => {
            storeNotificationToken(230);
          }}
        >
          Store Token
        </button>
      </div> */}
    </>
  );
}

export default NotificationProvider;
