import VerifyMethods from "src/assets/VerifyMethods";
import { IRegistrationForm } from "./types";

export function validateForm(registration_form: IRegistrationForm) {
  let valid = true;
  let form = { ...registration_form };

  // Because each verification method is different we just check each key individually

  form.email.error = VerifyMethods.validEmail(form.email.value);
  if (form.email.error !== "") valid = false;
  form.confirm_email.error = form.email.value === form.confirm_email.value ? "" : "Emails do not match";
  if (form.confirm_email.error !== "") valid = false;
  form.password.error = VerifyMethods.validPassword(form.password.value);
  if (form.password.error !== "") valid = false;
  form.first_name.error = form.first_name.value.length > 0 ? "" : "Please enter a first name";
  if (form.first_name.error !== "") valid = false;
  form.last_name.error = form.last_name.value.length > 0 ? "" : "Please enter a last name";
  if (form.last_name.error !== "") valid = false;
  form.phone_number.error = form.phone_number.value !== "" ? "" : "Invalid phone number";
  if (form.phone_number.error !== "") valid = false;
  form.terms_and_conditions.error = form.terms_and_conditions.value ? "" : "Please accept the terms and conditions";
  if (form.terms_and_conditions.error !== "") valid = false;

  return {
    valid: valid,
    form: form,
  };
}

export function formatForm(registration_form: IRegistrationForm) {
  // This is just a function that maps the registration form object
  // to something the server understands

  return {
    email: registration_form.email.value,
    password: registration_form.password.value,
    first_name: registration_form.first_name.value,
    last_name: registration_form.last_name.value,
    phone_number: registration_form.phone_number.value,
    country_code: registration_form.country_code.value,
    company: registration_form.work.value,
    website: registration_form.website.value,
    social: registration_form.social_media.value,
    terms_and_conditions: registration_form.terms_and_conditions.value,
  };
}
