import { useState, useEffect } from "react";
import styles from "./MediaCenter.module.scss";
import { MediaSearchType } from "./types";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

/**
 * Media search
 *
 * @author 					Pætur Mortensen
 */
export default function MediaSearch({ allMedia, setFilteredMedia }: MediaSearchType): JSX.Element {
  // Search string
  const [search, setSearch] = useState<string>("");

  // Whenever the search string changes...
  useEffect(() => {
    handle_search();
  }, [search]);

  /**
   * Handle media search.
   *
   * Runs whenever the search string changes
   *
   * @author 					Pætur Mortensen
   */
  function handle_search(): void {
    /**
     * Check whether the search string matches the searchable string
     *
     * ALL words in search string must match or partly match ANY of the words in searchable string
     *
     * @author 					Pætur Mortensen
     */
    function search_matches(searchWords: Array<string>, searchable: string): boolean {
      // Split the searchable string into array of words
      const searchableWords = searchable.trim().split(/\s+/);

      /**
       * Check if word partly matches
       *
       * Check if a single search word partly matches any of the searchable words
       *
       * @author 					Pætur Mortensen
       */
      const word_partly_matches = (searchWord: string, searchableWords: Array<string>): boolean => {
        // Return whether any search word partly matches any searchable word
        return searchableWords.some((word) => word.includes(searchWord));
      };

      // Check whether ALL words in the searchString partly match ANY words in searchable words
      return searchWords.every((searchWord) => word_partly_matches(searchWord, searchableWords));
    }

    let searchString = search;
    // Remove all non-word characters (but keep numbers)
    searchString = searchString.replace(/[^\p{L}\p{N}]/gu, " ");
    // Truncate all spaces to single spaces
    searchString = searchString.replace(/\s+/g, " ");
    // Set to lower case for case-insensitive matching
    searchString = searchString.toLowerCase();
    // Split the string into words for matching
    const searchWords = searchString.trim().split(/\s+/);

    // Filter the media by items that pass the search
    const filteredMedia = allMedia.filter((medium) =>
      search_matches(searchWords, medium.searchable.toLocaleLowerCase())
    );

    // Set the filtered media
    setFilteredMedia(filteredMedia);
  }

  return (
    <div className={styles.search}>
      <input
        className={styles.searchInput}
        type="text"
        placeholder="Search media..."
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <FontAwesomeIcon icon={faSearch as IconProp} className={styles.searchIcon} />
    </div>
  );
}
