import { StateCreator } from "zustand";
import { WHATSON_TYPE } from "../../types/constants/whatson";
import { EVENT_ERROR } from "./error-partials/event";
import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";

/**
 * Error interface
 */
interface IError {
  valid: boolean;
  validate: () => boolean;
}
/**
 * Type Error interface
 */
interface ITypeErrors {
  [key: string]: IError;
}
/**
 * Error Slice Interface
 */
export interface IErrorSlice {
  errors: {
    [key: string]: ITypeErrors;
  };
  all_valid: boolean;
  /**
   * Set Error
   *
   * @param key
   * @param value
   *
   * @returns void
   */
  setError: (key: string, value: boolean) => void;
  /**
   * Set All Valid
   *
   * @param value
   *
   * @returns void
   */
  setAllValid: (value: boolean) => void;
  /**
   * Reset error slice
   */
  resetErrorSlice: () => void;
}
/**
 * Initial States
 */
const INITIAL_STATES = () => {
  return {
    errors: {
      [WHATSON_TYPE.EVENT]: { ...EVENT_ERROR() },
      [WHATSON_TYPE.HIKE]: {},
      [WHATSON_TYPE.PLACE]: {},
      [WHATSON_TYPE.TOUR]: {},
    },

    all_valid: false,
  };
};
/**
 * Create Error Slice
 */
export const createErrorSlice: StateCreator<IErrorSlice> = (set, get) => ({
  ...INITIAL_STATES(),
  setError: (key, value) => {
    /**
     * Get the What's On type
     */
    const { type } = useWhatsonManager.getState();
    /**
     * Set the error
     */
    set((state) => {
      /* If keys cannot be found, assume the What's On type doesn't support them */
      if (state.errors?.[type] === undefined || state.errors?.[type]?.[key] === undefined) {
        return {};
      }
      /**
       * Set
       */
      return {
        errors: {
          ...state.errors, // Keep the other types
          [type]: {
            // Update the current type
            ...state.errors[type], // Keep the other keys
            [key]: {
              // Update the current key
              ...state.errors[type][key], // Keep the other properties
              valid: value, // Update the value
            },
          },
        },
      };
    });
  },
  setAllValid: (value) => {
    set({ all_valid: value });
  },
  resetErrorSlice: () => {
    set({ ...INITIAL_STATES() });
  },
});
