import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faChevronDown,
  faChevronUp,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import React, { useState } from "react";

import "./Physical.scss";

export default function Physical(props) {
  const { configuration, findex, setForceUpdate, updateConf, productTypeuuid } = props;

  const boxes = [
    {
      label: "Brands",
      description: "Brands, Products, Varients and Properties",
      type: "brands",
    },
  ];
  const [expand, setExpand] = useState({ brand: -1, product: -1, varient: -1 });
  const [openBrand, setOpenBrand] = useState(false);
  // const remove = (type, brandIndex, productIndex, varientIndex, propertyIndex) => {
  //     if (type === "brand") {
  //         configuration.physical[findex].brands[brandIndex].deleted = true;
  //     }
  //     if (type === "product") {
  //         configuration.physical[findex].brands[brandIndex].products[productIndex].deleted = true;
  //     }
  //     if (type === "varient") {
  //         configuration.physical[findex].brands[brandIndex].products[productIndex].varients[varientIndex].deleted = true;;
  //     }
  //     if (type === "property") {
  //         configuration.physical[findex].brands[brandIndex].products[productIndex].varients[varientIndex].properties[propertyIndex].deleted = true;;
  //     }
  //     setForceUpdate((f) => !f)
  // }
  const compare = (a, b) => {
    if (a?.new !== b?.new) {
      return -1;
    }
    if (a?.new === b?.new) {
      return 1;
    }
    return 0;
  };
  const add = (type, parentuuid = null) => {
    return (
      <div className="wrap-add">
        <div
          onClick={() => {
            if (type === "brand") {
              updateConf.brands.push({
                label: "",
                uuid: uuid(),
                new: true,
                productTypeId: productTypeuuid,
                products: [],
                value: updateConf.brands.length + 1,
              });
              setForceUpdate((f) => !f);
              return;
            }
            if (type === "product") {
              updateConf.products.push({
                label: "",
                uuid: uuid(),
                parentuuid,
                new: true,
                productTypeId: productTypeuuid,
                varients: [],
              });
              setForceUpdate((f) => !f);
            }
            if (type === "varient") {
              updateConf.varients.push({
                label: "",
                uuid: uuid(),
                parentuuid,
                new: true,
                productTypeId: productTypeuuid,
                properties: [],
              });

              setForceUpdate((f) => !f);
            }
            if (type === "property") {
              updateConf.properties.push({
                label: "",
                value: "",
                uuid: uuid(),
                parentuuid,
                productTypeId: productTypeuuid,
                new: true,
              });
              setForceUpdate((f) => !f);
            }
          }}
          className="add"
        >
          <FontAwesomeIcon icon={faPlus} />
          Add {type}
        </div>
      </div>
    );
  };

  const products = (element, brandIndex, branduuid = null) => {
    return (
      <table className="level-brand">
        <tbody>
          <tr>
            <th>
              <div>
                <div>
                  <label>Brand</label>
                  <input
                    onChange={(e) => {
                      element.update = true;
                      element.label = e.target.value;
                      let findex = updateConf.brands.findIndex((x) => x.uuid === element.uuid);
                      if (findex === -1) {
                        updateConf.brands.push(element);
                      } else {
                        updateConf.brands[findex] = element;
                      }
                    }}
                    defaultValue={element.label}
                  />
                </div>
                <div
                  onClick={() => {
                    expand.brand = expand.brand === branduuid ? -1 : branduuid;
                    setForceUpdate((f) => !f);
                  }}
                  className="expand"
                >
                  {expand.brand === branduuid ? (
                    <FontAwesomeIcon icon={faAngleUp} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleDown} />
                  )}
                </div>
                <div
                  className="remove"
                  onClick={() => {
                    element.deleted = true;
                    let findex = updateConf.brands.findIndex((x) => x.uuid === element.uuid);
                    if (findex === -1) {
                      updateConf.brands.push(element);
                    } else {
                      updateConf.brands[findex] = element;
                    }
                    setForceUpdate((f) => !f);
                  }}
                >
                  <FontAwesomeIcon color="red" icon={faTrashAlt} />
                </div>
              </div>
            </th>
          </tr>
          <tr
            style={{
              display: branduuid === expand.brand ? "table-row" : "none",
            }}
            className="products"
          >
            <td>
              <table>
                <tbody>
                  <tr key={new Date().valueOf()}>
                    <td>{add("product", branduuid)}</td>
                  </tr>
                  {expand.brand !== -1 &&
                    element?.products
                      .concat(updateConf.products)
                      .filter(
                        (x) =>
                          x.parentuuid ===
                          configuration?.physical[findex]?.brands
                            .concat(updateConf.brands)
                            .find((x) => x.uuid === expand.brand)?.uuid
                      )
                      .sort(compare)
                      .map((product, productIndex) => {
                        if (product.deleted) {
                          return "";
                        }
                        return [
                          <tr key={"product_" + product.uuid} className="product-row">
                            <th>
                              <div>
                                <div>
                                  <label>Product</label>
                                  <input
                                    onChange={(e) => {
                                      product.update = true;
                                      product.label = e.target.value;
                                      let findex = updateConf.products.findIndex((x) => x.uuid === product.uuid);
                                      if (findex === -1) {
                                        updateConf.products.push(product);
                                      } else {
                                        updateConf.products[findex] = product;
                                      }
                                    }}
                                    defaultValue={product.label}
                                  />
                                  <div
                                    className="remove"
                                    onClick={() => {
                                      product.deleted = true;
                                      let findex = updateConf.products.findIndex((x) => x.uuid === product.uuid);
                                      if (findex === -1) {
                                        updateConf.products.push(product);
                                      } else {
                                        updateConf.products[findex] = product;
                                      }

                                      setForceUpdate((f) => !f);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </div>
                                </div>
                                <div
                                  onClick={() => {
                                    expand.product = expand.product === product.uuid ? -1 : product.uuid;
                                    setForceUpdate((f) => !f);
                                  }}
                                  className="expand"
                                >
                                  {expand.product === product.uuid ? (
                                    <FontAwesomeIcon icon={faAngleUp} />
                                  ) : (
                                    <FontAwesomeIcon icon={faAngleDown} />
                                  )}
                                </div>
                              </div>
                            </th>
                          </tr>,
                          <tr
                            key={"varient" + product.uuid}
                            style={{
                              display: product.uuid === expand.product ? "table-row" : "none",
                            }}
                            className="varients"
                          >
                            <td>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>{add("varient", product.uuid)}</td>
                                  </tr>
                                  {expand.product !== -1 &&
                                    product?.varients
                                      .concat(updateConf.varients)
                                      .filter(
                                        (x) =>
                                          x.parentuuid ===
                                          configuration.physical[findex]?.brands
                                            .concat(updateConf.brands)
                                            .find((x) => x.uuid === expand.brand)
                                            ?.products.concat(updateConf.products)
                                            .find((x) => x.uuid === expand.product)?.uuid
                                      )
                                      .sort(compare)
                                      .map((varient, varientIndex) => {
                                        if (varient.deleted) {
                                          return <></>;
                                        }
                                        return (
                                          <tr key={"varient" + varient.uuid}>
                                            <th>
                                              <div>
                                                <div>
                                                  <label>Varient</label>
                                                  <input
                                                    onChange={(e) => {
                                                      varient.update = true;
                                                      varient.label = e.target.value;
                                                      let findex = updateConf.varients.findIndex(
                                                        (x) => x.uuid === varient.uuid
                                                      );
                                                      if (findex === -1) {
                                                        updateConf.varients.push(varient);
                                                      } else {
                                                        updateConf.varients[findex] = varient;
                                                      }
                                                    }}
                                                    defaultValue={varient.label}
                                                  />
                                                  <div
                                                    className="remove"
                                                    onClick={() => {
                                                      varient.deleted = true;
                                                      let findex = updateConf.varients.findIndex(
                                                        (x) => x.uuid === varient.uuid
                                                      );
                                                      if (findex === -1) {
                                                        updateConf.varients.push(varient);
                                                      } else {
                                                        updateConf.varients[findex] = varient;
                                                      }
                                                      setForceUpdate((f) => !f);
                                                    }}
                                                  >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                  </div>
                                                </div>

                                                <div
                                                  onClick={() => {
                                                    expand.varient =
                                                      expand.varient === varient.uuid ? -1 : varient.uuid;
                                                    setForceUpdate((f) => !f);
                                                  }}
                                                  className="expand"
                                                >
                                                  {expand.varient === varient.uuid ? (
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                  ) : (
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: varient.uuid === expand.varient ? "flex" : "none",
                                                }}
                                                className="properties"
                                              >
                                                {add("property", varient.uuid)}
                                                <table>
                                                  <tbody>
                                                    <tr>
                                                      <th>Properties</th>
                                                      <th></th>
                                                      <th></th>
                                                    </tr>
                                                    {expand.varient !== -1 &&
                                                      varient?.properties
                                                        .concat(updateConf.properties)
                                                        .filter(
                                                          (x) =>
                                                            x.parentuuid ===
                                                            configuration.physical[findex]?.brands
                                                              .concat(updateConf.brands)
                                                              .find((x) => x.uuid === expand.brand)
                                                              ?.products.concat(updateConf.products)
                                                              .find((x) => x.uuid === expand.product)
                                                              .varients.concat(updateConf.varients)
                                                              .find((x) => x.uuid === expand.varient)?.uuid
                                                        )
                                                        .sort(compare)
                                                        .map((property, propertyIndex) => {
                                                          if (property.deleted) {
                                                            return <></>;
                                                          }
                                                          return (
                                                            <tr key={property.uuid}>
                                                              <td>
                                                                <label>Name</label>
                                                                <input
                                                                  onChange={(e) => {
                                                                    property.update = true;
                                                                    property.label = e.target.value;
                                                                    let findex = updateConf.properties.findIndex(
                                                                      (x) => x.uuid === property.uuid
                                                                    );
                                                                    if (findex === -1) {
                                                                      updateConf.properties.push(property);
                                                                    } else {
                                                                      updateConf.properties[findex] = property;
                                                                    }
                                                                  }}
                                                                  defaultValue={property.label}
                                                                />
                                                              </td>
                                                              <td>
                                                                <label>Value</label>
                                                                <input
                                                                  onChange={(e) => {
                                                                    property.update = true;
                                                                    property.value = e.target.value;
                                                                    let findex = updateConf.properties.findIndex(
                                                                      (x) => x.uuid === property.uuid
                                                                    );
                                                                    if (findex === -1) {
                                                                      updateConf.properties.push(property);
                                                                    } else {
                                                                      updateConf.properties[findex] = property;
                                                                    }
                                                                  }}
                                                                  defaultValue={property.value}
                                                                />
                                                              </td>
                                                              <td>
                                                                <div
                                                                  className="remove"
                                                                  onClick={() => {
                                                                    property.deleted = true;
                                                                    let findex = updateConf.properties.findIndex(
                                                                      (x) => x.uuid === property.uuid
                                                                    );
                                                                    if (findex === -1) {
                                                                      updateConf.properties.push(property);
                                                                    } else {
                                                                      updateConf.properties[findex] = property;
                                                                    }
                                                                    setForceUpdate((f) => !f);
                                                                  }}
                                                                >
                                                                  <FontAwesomeIcon icon={faTimes} />
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          );
                                                        })}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </th>
                                          </tr>
                                        );
                                      })}
                                </tbody>
                              </table>
                            </td>
                          </tr>,
                        ];
                      })}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div key={"physical" + findex} className="physical">
      {boxes.map((element, index) => {
        return (
          <div key={element + index} className={"box " + element.type}>
            <div className="wrap-top">
              <div>
                <h3>{element.label}</h3>
                <p>{element.description}</p>
              </div>
              <div
                className="expandBrand"
                onClick={() => {
                  setOpenBrand((f) => !f);
                }}
              >
                <FontAwesomeIcon icon={openBrand ? faChevronUp : faChevronDown}></FontAwesomeIcon>
              </div>
            </div>
            {openBrand && (
              <div>
                {add("brand")}
                <div className="wrap-products">
                  {configuration.physical[findex].brands
                    .filter((x) => !updateConf.brands.some((z) => z.uuid === x.uuid))
                    .concat(updateConf.brands)
                    .sort(compare)
                    .map((element, index) => {
                      if (element.deleted) {
                        return <></>;
                      }
                      return <div key={"products" + element.uuid}>{products(element, index, element.uuid)}</div>;
                    })}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
