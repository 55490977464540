import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Currency.scss";
import React, { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { faEllipsisV, faExchange, faTimes } from "@fortawesome/pro-light-svg-icons";
import Select from "react-select";
import axios from "axios";
import env from "../../../environment.json";

export default function Currency(props) {
  const [currency, setCurrency] = useState(
    props.selectedOptions.currency
      ? props.selectedOptions.currency
      : [
          { value: "", rate: { label: "DKK", value: "DKK" } },
          { value: "", rate: { label: "EUR", value: "EUR" } },
        ]
  );
  const [rates, setRates] = useState(false);
  const [selectOptions, setSelectOptions] = useState([{ label: "DKK", value: "DKK" }]);

  useEffect(() => {
    axios
      .get(env.protocol + env.env + "/api/public/getCurrency.php")
      .then((response) => {
        setRates(response.data.rates);
        var mapCurrencyToSelect = [];
        for (const [key, value] of Object.entries(response.data.rates)) {
          mapCurrencyToSelect.push({ label: key, value: key });
        }
        setSelectOptions(mapCurrencyToSelect);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="currency-options">
      <div>
        <div id="handle">
          <FontAwesomeIcon icon={faEllipsisV} />
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <div
          onClick={() => {
            props.close();
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="top">
        <div
          className={"top-choice "}
          // onClick={() => setScreen("pages")}
        >
          Options
        </div>
      </div>
      <div className="content">
        <div className="from">
          <Select
            key={new Date().valueOf()}
            placeholder="from"
            options={selectOptions}
            defaultValue={currency[0].rate}
            onChange={(e) => {
              let newCurrency = cloneDeep(currency);
              newCurrency[0].rate = e;
              props.selectedOptions.currency = newCurrency;
              props.setCurrencyOptions(props.selectedOptions);
              setCurrency(newCurrency);
            }}
          />
          <input
            defaultValue={currency[0].value}
            onChange={(e) => {
              let newCurrency = cloneDeep(currency);
              newCurrency[0].value = JSON.parse(e.target.value);
              props.selectedOptions.currency = newCurrency;
              props.setCurrencyOptions(props.selectedOptions);
              setCurrency(newCurrency);
            }}
            className="currencyInput"
          />
        </div>
        <FontAwesomeIcon icon={faExchange} />
        <div className="to">
          <Select
            key={new Date().valueOf()}
            placeholder="to"
            options={selectOptions}
            defaultValue={currency[1].rate}
            onChange={(e) => {
              let newCurrency = cloneDeep(currency);
              newCurrency[1].rate = e;
              props.selectedOptions.currency = newCurrency;
              props.setCurrencyOptions(props.selectedOptions);
              setCurrency(newCurrency);
            }}
          />
          <div className="currencyResult">
            {rates
              ? ((currency[0].value / rates[currency[0].rate.label]) * rates[currency[1].rate.label]).toFixed(2)
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}
