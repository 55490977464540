import React, { Component } from "react";
import Select from "react-select";
import "./Dates.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faTrash, //from "@fortawesome/pro-duotone-svg-icons";
} from "@fortawesome/free-solid-svg-icons";
import { VfiCheckbox2 as VfiCheckbox } from "../VfiCheckbox";
import language from "./language.json";
import { faChevronDown, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import VfiCustomSelect from "../VfiCustomSelect";
import DateTypeSelect from "./components/date-type-select/DateTypeSelect";

class Dates extends Component {
  constructor(props) {
    super(props);

    this.language = language[this.props.language];

    this.state = {
      dateOccurence: undefined,
      // {
      //   value: "single",
      //   label: this.language.occurrences.single_date,
      // }
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      dateVisible: "",
      specificDates: [{ key: 1, date: "", startTime: "", endTime: "" }],
    };

    this.dateOccurences = [
      {
        value: "single",
        type: "single",
        label: this.language.occurrences.single_date,
      },
      {
        value: "many",
        type: "multiple",
        label: this.language.occurrences.many_dates,
      },
      {
        value: "specific",
        type: "multiple",
        label: this.language.occurrences.specific_dates,
      },
    ];

    this.specificDates = [{}];

    if (this.props.defaultDateOccurence)
      this.state = {
        ...this.state,
        dateOccurence: JSON.parse(JSON.stringify(this.props.defaultDateOccurence)),
      };

    if (this.props.defaultStartDate)
      this.state = {
        ...this.state,
        startDate: new Date(this.props.defaultStartDate),
      };

    if (this.props.defaultEndDate)
      this.state = {
        ...this.state,
        endDate: new Date(this.props.defaultEndDate),
      };

    if (this.props.defaultStartTime) {
      this.state = {
        ...this.state,
        startTime: this.props.defaultStartTime,
      };

      this.startTimeHour = this.state.startTime.split(":")[0];
      this.startTimeMinute = this.state.startTime.split(":")[1];
    }

    if (this.props.defaultEndTime) {
      this.state = {
        ...this.state,
        endTime: this.props.defaultEndTime,
      };

      this.endTimeHour = this.state.endTime.split(":")[0];
      this.endTimeMinute = this.state.endTime.split(":")[1];
    }

    if (this.props.defaultDays)
      this.state = {
        ...this.state,
        days: Object.assign({}, this.props.defaultDays),
      };

    if (this.props.defaultSpecificDates) {
      const specificDates = JSON.parse(JSON.stringify(this.props.defaultSpecificDates));

      this.specificDates = specificDates;
      this.specificDates.forEach((e, i) => {
        this.specificDates[i].startTimeHour = e.startTime.split(":")[0];
        this.specificDates[i].startTimeMinute = e.startTime.split(":")[1];
        this.specificDates[i].endTimeHour = e.endTime.split(":")[0];
        this.specificDates[i].endTimeMinute = e.endTime.split(":")[1];
      });

      specificDates.forEach((element, i) => {
        if (element.date !== "") specificDates[i].date = new Date(element.date);
      });

      this.state = { ...this.state, specificDates: specificDates };
    }

    if (this.props.defaultDateVisible)
      this.state = {
        ...this.state,
        dateVisible: new Date(this.props.defaultDateVisible),
      };

    this.timeSelect = () => {
      const options = [];
      for (var i = 0; i < 24; i++) {
        for (var j = 0; j < 60; j += 5) {
          options.push({
            value: (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j),
            label: (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j),
          });
        }
      }
      return options;
    };

    this.hourSelect = () => {
      const options = [];
      for (var i = 0; i < 24; i++) {
        options.push({
          value: i < 10 ? "0" + i : i,
          label: i < 10 ? "0" + i : i,
        });
      }
      return options;
    };

    this.minuteSelect = () => {
      const options = [];
      for (var i = 0; i < 60; i += 5) {
        options.push({
          value: i < 10 ? "0" + i : i,
          label: i < 10 ? "0" + i : i,
        });
      }
      return options;
    };

    this.changeDateOccurence = this.changeDateOccurence.bind(this);
    this.addSpecificDate = this.addSpecificDate.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultDateOccurence !== prevProps.defaultDateOccurence) {
      if (this.props.defaultDateOccurence)
        this.setState({
          dateOccurence: JSON.parse(JSON.stringify(this.props.defaultDateOccurence)),
        });
    }

    if (this.props.defaultStartDate !== prevProps.defaultStartDate) {
      if (this.props.defaultStartDate) {
        this.setState({
          startDate: new Date(this.props.defaultStartDate),
        });
      } else {
        this.setState({ startDate: "" });
      }
    }

    if (this.props.defaultEndDate !== prevProps.defaultEndDate) {
      if (this.props.defaultEndDate)
        this.setState({
          endDate: new Date(this.props.defaultEndDate),
        });
      else this.setState({ endDate: "" });
    }

    if (this.props.defaultStartTime !== prevProps.defaultStartTime) {
      if (this.props.defaultStartTime) {
        this.setState({
          startTime: this.props.defaultStartTime,
        });
        this.startTimeHour = this.state.startTime.split(":")[0];
        this.startTimeMinute = this.state.startTime.split(":")[1];
      } else {
        this.setState({ startTime: "" });
        this.startTimeHour = "";
        this.startTimeMinute = "";
      }
    }

    if (this.props.defaultEndTime !== prevProps.defaultEndTime) {
      if (this.props.defaultStartTime) {
        this.setState({
          endTime: this.props.defaultEndTime,
        });
        this.endTimeHour = this.state.endTime.split(":")[0];
        this.endTimeMinute = this.state.endTime.split(":")[1];
      } else {
        this.setState({ endTime: "" });
        this.endTimeHour = "";
        this.endTimeMinute = "";
      }
    }
    if (this.props.defaultDays !== prevProps.defaultDays) {
      if (this.props.defaultDays) {
        this.setState({
          days: Object.assign({}, this.props.defaultDays),
        });
      } else {
        this.setState({
          days: Object.assign(
            {},
            {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false,
            }
          ),
        });
      }
    }

    if (this.props.defaultSpecificDates !== prevProps.defaultSpecificDates) {
      if (this.props.defaultSpecificDates) {
        const specificDates = JSON.parse(JSON.stringify(this.props.defaultSpecificDates));

        this.specificDates = specificDates;
        this.specificDates.forEach((e, i) => {
          this.specificDates[i].startTimeHour = e.startTime.split(":")[0];
          this.specificDates[i].startTimeMinute = e.startTime.split(":")[1];
          this.specificDates[i].endTimeHour = e.endTime.split(":")[0];
          this.specificDates[i].endTimeMinute = e.endTime.split(":")[1];
        });

        specificDates.forEach((element, i) => {
          if (element.date !== "") specificDates[i].date = new Date(element.date);
        });

        this.setState({ specificDates: specificDates });
      } else {
        this.setState({
          specificDates: [{ key: 1, date: "", startTime: "", endTime: "" }],
        });
      }
    }

    if (this.props.defaultDateVisible !== prevProps.defaultDateVisible) {
      if (this.props.defaultDateVisible) {
        this.setState({
          dateVisible: new Date(this.props.defaultDateVisible),
        });
      } else {
        this.setState({ dateVisible: "" });
      }
    }
  }

  changeDateOccurence(e) {
    this.setState({ dateOccurence: e }, () => {
      this.props.onChange(this.JsonValue());
    });
  }

  JsonValue() {
    return {
      occurence: this.state.dateOccurence,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      days: {
        monday: this.state.days.monday,
        tuesday: this.state.days.tuesday,
        wednesday: this.state.days.wednesday,
        thursday: this.state.days.thursday,
        friday: this.state.days.friday,
        saturday: this.state.days.saturday,
        sunday: this.state.days.sunday,
      },
      dateVisible: this.state.dateVisible,
      specificDates: this.state.specificDates,
    };
  }

  addSpecificDate() {
    var maxKey = 0;
    this.state.specificDates.forEach((element) => {
      if (element.key > maxKey) maxKey = element.key;
    });

    let currentDates = Object.assign([], this.state.specificDates);
    currentDates.forEach((element, i) => {
      if (element.date !== "") {
        element.date = new Date(element.date);
      }
    });
    this.specificDates.push({});
    currentDates.push({
      key: maxKey + 1,
      date: "",
      startTime: "",
      endTime: "",
    });
    this.setState({ specificDates: currentDates }, () => {
      this.props.onChange(this.JsonValue());
    });
  }

  deleteSpecificDate(index) {
    let currentDates = Object.assign([], this.state.specificDates);
    currentDates.forEach((element, i) => {
      if (element.date !== "") {
        element.date = new Date(element.date);
      }
    });
    currentDates.splice(index, 1);
    this.specificDates.splice(index, 1);
    this.setState({ specificDates: currentDates }, () => {
      this.props.onChange(this.JsonValue());
    });
  }

  dayChange = (e) => {
    this.setState(
      {
        days: {
          ...this.state.days,
          [e.target.name]: e.target.checked,
        },
      },
      () => {
        this.props.onChange(this.JsonValue());
      }
    );
  };

  render() {
    return (
      <div className="dates">
        {/* <VfiCustomSelect
          options={this.dateOccurences}
          value={this.state.dateOccurence}
          onChange={(e) => this.changeDateOccurence(e)}
          isDisabled={this.props.disabled}
          placeholder={this.language.occurrences.placeholder}
          error={
            this.props.showErrors
              ? this.state.dateOccurence === undefined
              : false
          }
        /> */}
        <DateTypeSelect
          options={this.dateOccurences}
          value={this.state.dateOccurence}
          // onChange={(e) => this.changeDateOccurence(e)}
          onClick={this.changeDateOccurence}
          isDisabled={this.props.disabled}
        />
        {/* <div className="date-type-wrap">
          <Select
            className="date-type"
            options={this.dateOccurences}
            value={this.state.dateOccurence}
            onChange={(e) => this.changeDateOccurence(e)}
            isDisabled={this.props.disabled}
          />
          <FontAwesomeIcon className="chevron" icon={faChevronDown} />
        </div> */}
        <div className={"dates-content " + (this.state.dateOccurence ? this.state.dateOccurence.value : "")}>
          {this.state.dateOccurence && this.state.dateOccurence.value === "single" ? (
            <div className="single-date left-side">
              <div className="left-wrap">
                <div className="top-text event">EVENT</div>
                <div className="in-content">
                  <p className="date-text">{this.language.single_date.date}</p>
                  <DatePicker
                    className={this.props.showErrors && !this.state.startDate ? "error" : ""}
                    selected={this.state.startDate}
                    disabled={this.props.disabled}
                    onChange={(date) => {
                      this.setState(
                        {
                          startDate: date,
                        },
                        () => {
                          this.props.onChange(this.JsonValue());
                        }
                      );
                    }}
                    dateFormat="dd MMMM yyyy"
                    placeholderText={this.language.single_date.date_placeholder}
                  />
                </div>
              </div>
              <div className="clock-times right-wrap">
                <div className="top-text time">TIME</div>
                <div className="start-time in-content">
                  <p className="time-text">{this.language.single_date.start_time}</p>
                  <Select
                    className={"time-select" + (!this.startTimeHour ? " error" : "")}
                    classNames={{
                      menu: (state) => "menu",
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#fafafa",
                      }),
                    }}
                    isDisabled={this.props.disabled}
                    options={this.hourSelect()}
                    classNames={{
                      menu: (state) => "menu",
                    }}
                    onChange={(e) => {
                      this.startTimeHour = e.value;
                      this.setState(
                        {
                          startTime: this.startTimeHour + ":" + (this.startTimeMinute ? this.startTimeMinute : "00"),
                        },
                        () => {
                          this.props.onChange(this.JsonValue());
                        }
                      );
                    }}
                    value={{
                      label: this.startTimeHour,
                      value: this.startTimeHour,
                    }}
                    placeholder={this.language.single_date.hour_placeholder}
                  />
                  <Select
                    className={"time-select" + (!this.startTimeHour ? " error" : "")}
                    classNames={{
                      menu: (state) => "menu",
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#fafafa",
                      }),
                    }}
                    isDisabled={this.props.disabled}
                    options={this.minuteSelect()}
                    onChange={(e) => {
                      this.startTimeMinute = e.value;
                      this.setState(
                        {
                          startTime: (this.startTimeHour ? this.startTimeHour : "00") + ":" + this.startTimeMinute,
                        },
                        () => {
                          this.props.onChange(this.JsonValue());
                        }
                      );
                    }}
                    value={{
                      label: this.startTimeMinute,
                      value: this.startTimeMinute,
                    }}
                    placeholder={this.language.single_date.minute_placeholder}
                  />
                </div>
                <div className="end-time">
                  <p className="time-text">{this.language.single_date.end_time}</p>
                  <Select
                    className={"time-select" + (!this.endTimeHour ? " error" : "")}
                    classNames={{
                      menu: (state) => "menu",
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#fafafa",
                      }),
                    }}
                    isDisabled={this.props.disabled}
                    options={this.hourSelect()}
                    onChange={(e) => {
                      this.endTimeHour = e.value;
                      this.setState(
                        {
                          endTime: this.endTimeHour + ":" + (this.endTimeMinute ? this.endTimeMinute : "00"),
                        },
                        () => {
                          this.props.onChange(this.JsonValue());
                        }
                      );
                    }}
                    value={{
                      label: this.endTimeHour,
                      value: this.endTimeHour,
                    }}
                    placeholder={this.language.single_date.hour_placeholder}
                  />
                  <Select
                    className={"time-select" + (!this.endTimeHour ? " error" : "")}
                    classNames={{
                      menu: (state) => "menu",
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#fafafa",
                      }),
                    }}
                    isDisabled={this.props.disabled}
                    options={this.minuteSelect()}
                    onChange={(e) => {
                      this.endTimeMinute = e.value;
                      this.setState(
                        {
                          endTime: (this.endTimeHour ? this.endTimeHour : "00") + ":" + this.endTimeMinute,
                        },
                        () => {
                          this.props.onChange(this.JsonValue());
                        }
                      );
                    }}
                    value={{
                      label: this.endTimeMinute,
                      value: this.endTimeMinute,
                    }}
                    placeholder={this.language.single_date.minute_placeholder}
                  />
                </div>
              </div>
            </div>
          ) : this.state.dateOccurence && this.state.dateOccurence.value === "many" ? (
            <div className="upper-wrap left-side">
              <div className="many-dates">
                <div className="start-end-dates left-wrap">
                  <div className="top-text event">EVENT</div>
                  <div className="start-date in-content">
                    <p className="date-text">{this.language.many_dates.start_date}</p>
                    <DatePicker
                      selected={this.state.startDate}
                      disabled={this.props.disabled}
                      onChange={(date) => {
                        this.setState(
                          {
                            startDate: date,
                          },
                          () => {
                            this.props.onChange(this.JsonValue());
                          }
                        );
                      }}
                      dateFormat="dd MMMM yyyy"
                      placeholderText={this.language.many_dates.start_date_placeholder}
                      className={this.props.showErrors && !this.state.startDate ? "error" : ""}
                    />
                  </div>
                  <div className="end-date in-content">
                    <p className="date-text">{this.language.many_dates.end_date}</p>
                    <DatePicker
                      disabled={this.props.disabled}
                      selected={this.state.endDate}
                      onChange={(date) => {
                        this.setState(
                          {
                            endDate: date,
                          },
                          () => {
                            this.props.onChange(this.JsonValue());
                          }
                        );
                      }}
                      dateFormat="dd MMMM yyyy"
                      placeholderText={this.language.many_dates.end_date_placeholder}
                      className={this.props.showErrors && !this.state.endDate ? "error" : ""}
                    />
                  </div>
                </div>

                <div className="clock-times right-wrap">
                  <div className="top-text time">TIME</div>
                  <div className="start-time in-content">
                    <p className="time-text">{this.language.many_dates.start_time}</p>
                    <Select
                      className={"time-select" + (!this.startTimeHour ? " error" : "")}
                      classNames={{
                        menu: (state) => "menu",
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#fafafa",
                        }),
                      }}
                      isDisabled={this.props.disabled}
                      options={this.hourSelect()}
                      onChange={(e) => {
                        this.startTimeHour = e.value;
                        this.setState(
                          {
                            startTime: this.startTimeHour + ":" + (this.startTimeMinute ? this.startTimeMinute : "00"),
                          },
                          () => {
                            this.props.onChange(this.JsonValue());
                          }
                        );
                      }}
                      value={{
                        label: this.startTimeHour,
                        value: this.startTimeHour,
                      }}
                      placeholder={this.language.many_dates.hour_placeholder}
                    />
                    <Select
                      className={"time-select" + (!this.startTimeHour ? " error" : "")}
                      classNames={{
                        menu: (state) => "menu",
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#fafafa",
                        }),
                      }}
                      isDisabled={this.props.disabled}
                      options={this.minuteSelect()}
                      onChange={(e) => {
                        this.startTimeMinute = e.value;
                        this.setState(
                          {
                            startTime: (this.startTimeHour ? this.startTimeHour : "00") + ":" + this.startTimeMinute,
                          },
                          () => {
                            this.props.onChange(this.JsonValue());
                          }
                        );
                      }}
                      value={{
                        label: this.startTimeMinute,
                        value: this.startTimeMinute,
                      }}
                      placeholder={this.language.many_dates.minute_placeholder}
                    />
                  </div>
                  <div className="end-time">
                    <p className="time-text">{this.language.many_dates.end_time}</p>
                    <Select
                      className={"time-select" + (!this.endTimeHour ? " error" : "")}
                      classNames={{
                        menu: (state) => "menu",
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#fafafa",
                        }),
                      }}
                      isDisabled={this.props.disabled}
                      options={this.hourSelect()}
                      onChange={(e) => {
                        this.endTimeHour = e.value;
                        this.setState(
                          {
                            endTime: this.endTimeHour + ":" + (this.endTimeMinute ? this.endTimeMinute : "00"),
                          },
                          () => {
                            this.props.onChange(this.JsonValue());
                          }
                        );
                      }}
                      value={{
                        label: this.endTimeHour,
                        value: this.endTimeHour,
                      }}
                      placeholder={this.language.many_dates.hour_placeholder}
                    />
                    <Select
                      className={"time-select" + (!this.endTimeHour ? " error" : "")}
                      classNames={{
                        menu: (state) => "menu",
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#fafafa",
                        }),
                      }}
                      isDisabled={this.props.disabled}
                      options={this.minuteSelect()}
                      onChange={(e) => {
                        this.endTimeMinute = e.value;
                        this.setState(
                          {
                            endTime: (this.endTimeHour ? this.endTimeHour : "00") + ":" + this.endTimeMinute,
                          },
                          () => {
                            this.props.onChange(this.JsonValue());
                          }
                        );
                      }}
                      value={{
                        label: this.endTimeMinute,
                        value: this.endTimeMinute,
                      }}
                      placeholder={this.language.many_dates.minute_placeholder}
                    />
                  </div>
                </div>
              </div>
              <div className="days">
                <p className="days-text">{this.language.many_dates.days}</p>
                <div>
                  <div className="day-wrap">
                    <label>
                      <VfiCheckbox
                        disabled={this.props.disabled}
                        name="monday"
                        checked={this.state.days.monday}
                        onChange={this.dayChange}
                      />
                      <div className="day">{this.language.many_dates.monday}</div>
                    </label>
                  </div>
                  <div className="day-wrap">
                    <label>
                      <VfiCheckbox
                        disabled={this.props.disabled}
                        name="tuesday"
                        checked={this.state.days.tuesday}
                        onChange={this.dayChange}
                      />
                      <div className="day">{this.language.many_dates.tuesday}</div>
                    </label>
                  </div>
                  <div className="day-wrap">
                    <label>
                      <VfiCheckbox
                        disabled={this.props.disabled}
                        name="wednesday"
                        checked={this.state.days.wednesday}
                        onChange={this.dayChange}
                      />
                      <div className="day">{this.language.many_dates.wednesday}</div>
                    </label>
                  </div>
                  <div className="day-wrap">
                    <label>
                      <VfiCheckbox
                        disabled={this.props.disabled}
                        name="thursday"
                        checked={this.state.days.thursday}
                        onChange={this.dayChange}
                      />
                      <div className="day">{this.language.many_dates.thursday}</div>
                    </label>
                  </div>
                  <div className="day-wrap">
                    <label>
                      <VfiCheckbox
                        disabled={this.props.disabled}
                        name="friday"
                        checked={this.state.days.friday}
                        onChange={this.dayChange}
                      />
                      <div className="day">{this.language.many_dates.friday}</div>
                    </label>
                  </div>
                  <div className="day-wrap">
                    <label>
                      <VfiCheckbox
                        disabled={this.props.disabled}
                        name="saturday"
                        checked={this.state.days.saturday}
                        onChange={this.dayChange}
                      />
                      <div className="day">{this.language.many_dates.saturday}</div>
                    </label>
                  </div>
                  <div className="day-wrap">
                    <label>
                      <VfiCheckbox
                        disabled={this.props.disabled}
                        name="sunday"
                        checked={this.state.days.sunday}
                        onChange={this.dayChange}
                      />
                      <div className="day">{this.language.many_dates.sunday}</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.dateOccurence && this.state.dateOccurence.value === "specific" ? (
            <div className="specific-dates left-side">
              <div className="dates-list">
                <div className="titles">
                  <div className="top-text event">EVENT</div>
                  <div className="top-text time">TIME</div>
                </div>
                {this.state.specificDates.map((item, i) => {
                  return (
                    <div className="specific-date left-side-specific" key={item.key}>
                      {this.state.specificDates.length > 1 && !this.props.disabled ? (
                        <span className={"trash-icon"}>
                          <FontAwesomeIcon icon={faTimes} onClick={() => this.deleteSpecificDate(i)} />
                        </span>
                      ) : (
                        ""
                      )}
                      <div className="left-wrap">
                        <div className="date in-content">
                          <p className="date-text">{this.language.specific_dates.date}</p>
                          <DatePicker
                            disabled={this.props.disabled}
                            selected={item.date}
                            //value={item.date}
                            onChange={(date) => {
                              const specificDatesD = JSON.parse(JSON.stringify(this.state.specificDates));
                              specificDatesD.forEach((element, i) => {
                                if (element.date !== "") {
                                  element.date = new Date(element.date);
                                }
                              });
                              specificDatesD[i].date = date;
                              this.setState({ specificDates: specificDatesD }, () => {
                                this.props.onChange(this.JsonValue());
                              });
                            }}
                            dateFormat="dd MMMM yyyy"
                            placeholderText={this.language.specific_dates.date_placeholder}
                            className={this.props.showErrors && !item.date ? "error" : ""}
                          />
                        </div>
                      </div>
                      <div className="right-wrap">
                        <div className="start-time in-content">
                          <p className="time-text">{this.language.specific_dates.start_time}</p>
                          <Select
                            className={"time-select" + (!this.specificDates[i].startTimeHour ? " error" : "")}
                            classNames={{
                              menu: (state) => "menu",
                            }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: "#fafafa",
                              }),
                            }}
                            isDisabled={this.props.disabled}
                            options={this.hourSelect()}
                            onChange={(e) => {
                              const specificDatesD = JSON.parse(JSON.stringify(this.state.specificDates));
                              specificDatesD.forEach((element, i) => {
                                if (element.date !== "") {
                                  element.date = new Date(element.date);
                                }
                              });
                              const hour = (this.specificDates[i].startTimeHour = e.value);
                              const minute = this.specificDates[i].startTimeMinute;
                              specificDatesD[i].startTime = hour + ":" + (minute ? minute : "00");
                              this.setState({ specificDates: specificDatesD }, () => {
                                this.props.onChange(this.JsonValue());
                              });
                            }}
                            value={{
                              label: this.specificDates[i].startTimeHour,
                              value: this.specificDates[i].startTimeHour,
                            }}
                            placeholder={this.language.specific_dates.hour_placeholder}
                          />
                          <Select
                            className={"time-select" + (!this.specificDates[i].startTimeHour ? " error" : "")}
                            classNames={{
                              menu: (state) => "menu",
                            }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: "#fafafa",
                              }),
                            }}
                            isDisabled={this.props.disabled}
                            options={this.minuteSelect()}
                            onChange={(e) => {
                              const specificDatesD = JSON.parse(JSON.stringify(this.state.specificDates));
                              specificDatesD.forEach((element, i) => {
                                if (element.date !== "") {
                                  element.date = new Date(element.date);
                                }
                              });
                              const hour = this.specificDates[i].startTimeHour;
                              const minute = (this.specificDates[i].startTimeMinute = e.value);
                              specificDatesD[i].startTime = (hour ? hour : "00") + ":" + minute;
                              this.setState({ specificDates: specificDatesD }, () => {
                                this.props.onChange(this.JsonValue());
                              });
                            }}
                            value={{
                              label: this.specificDates[i].startTimeMinute,
                              value: this.specificDates[i].startTimeMinute,
                            }}
                            placeholder={this.language.specific_dates.minute_placeholder}
                          />
                        </div>
                        <div className="end-time in-content">
                          <p className="time-text">{this.language.specific_dates.end_time}</p>
                          <Select
                            className={"time-select" + (!this.specificDates[i].endTimeHour ? " error" : "")}
                            classNames={{
                              menu: (state) => "menu",
                            }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: "#fafafa",
                              }),
                            }}
                            isDisabled={this.props.disabled}
                            options={this.hourSelect()}
                            onChange={(e) => {
                              const specificDatesD = JSON.parse(JSON.stringify(this.state.specificDates));
                              specificDatesD.forEach((element, i) => {
                                if (element.date !== "") {
                                  element.date = new Date(element.date);
                                }
                              });
                              const hour = (this.specificDates[i].endTimeHour = e.value);
                              const minute = this.specificDates[i].endTimeMinute;
                              specificDatesD[i].endTime = hour + ":" + (minute ? minute : "00");
                              this.setState({ specificDates: specificDatesD }, () => {
                                this.props.onChange(this.JsonValue());
                              });
                            }}
                            value={{
                              label: this.specificDates[i].endTimeHour,
                              value: this.specificDates[i].endTimeHour,
                            }}
                            placeholder={this.language.specific_dates.hour_placeholder}
                          />
                          <Select
                            className={"time-select" + (!this.specificDates[i].endTimeHour ? " error" : "")}
                            classNames={{
                              menu: (state) => "menu",
                            }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: "#fafafa",
                              }),
                            }}
                            isDisabled={this.props.disabled}
                            options={this.minuteSelect()}
                            onChange={(e) => {
                              const specificDatesD = JSON.parse(JSON.stringify(this.state.specificDates));
                              specificDatesD.forEach((element, i) => {
                                if (element.date !== "") {
                                  element.date = new Date(element.date);
                                }
                              });
                              const hour = this.specificDates[i].endTimeHour;
                              const minute = (this.specificDates[i].endTimeMinute = e.value);
                              specificDatesD[i].endTime = (hour ? hour : "00") + ":" + minute;
                              this.setState({ specificDates: specificDatesD }, () => {
                                this.props.onChange(this.JsonValue());
                              });
                            }}
                            value={{
                              label: this.specificDates[i].endTimeMinute,
                              value: this.specificDates[i].endTimeMinute,
                            }}
                            placeholder={this.language.specific_dates.minute_placeholder}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="add-button" onClick={this.addSpecificDate}>
                  <span className="text">{this.language.specific_dates.add_more} </span>
                  <span className="add-icon">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <p></p>
          )}
          {this.state.dateOccurence && (
            <div className="date-visible right-side">
              <p>{this.language.date_when_visible}</p>
              <div className="visible-date-wrap">
                <p>Date</p>
                <DatePicker
                  disabled={this.props.disabled}
                  selected={this.state.dateVisible}
                  onChange={(date) => {
                    this.setState(
                      {
                        dateVisible: date,
                      },
                      () => {
                        this.props.onChange(this.JsonValue());
                      }
                    );
                  }}
                  dateFormat="dd MMMM yyyy"
                  placeholderText={this.language.date_when_visible_placeholder}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Dates;
