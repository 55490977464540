import { useRouterStore } from "src/Components/Router/routerStore";
import styles from "./Submit.module.scss";
import CreateWhatson from "src/Components/Login/CreateWhatson";
import SubmitEvent from "./submit-types/submit-event/SubmitEvent";
import { useEffect, useState } from "react";
import SubmitPlace from "./submit-types/submit-place/SubmitPlace";

// const submit_types = [
//   "event",
//   "place",
//   "tour",
//   "hike",
//   "tour",
//   "tour-operator",
// ];

const SUBMIT_MAP: any = {
  event: {
    component: SubmitEvent,
  },
  place: {
    component: SubmitPlace,
  },
};

export default function Submit() {
  const { route, navByLink } = useRouterStore();
  const SubmitComponent = SUBMIT_MAP?.[route[1]?.nav ?? ""]?.component ?? (() => <></>);

  return (
    <div className={styles.container}>
      <SubmitComponent />
    </div>
  );
}
