import styles from "./DatePicker.module.scss";
import ReactDatePicker from "react-datepicker";

interface DatePickerProps {
  id: string;
  label?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  onChange: (date: Date | null) => void;
  placeholder?: string;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  withPortal?: boolean;
}

function DatePicker({
  id,
  label,
  value,
  error = "",
  minDate,
  maxDate,
  disabled,
  className,
  withPortal,
  placeholder,
  onChange,
}: DatePickerProps) {
  const classNames = className ? `${styles.datePickerContainer} ${className}` : styles.datePickerContainer;

  return (
    <div className={classNames}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <ReactDatePicker
        selected={value ? new Date(value) : null}
        onChange={onChange}
        withPortal={withPortal}
        dateFormat="dd MMMM yyyy"
        className={`${styles.input} ${error ? styles.error : ""}`}
        disabled={disabled}
        placeholderText={placeholder}
        popperPlacement="bottom"
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

export default DatePicker;
