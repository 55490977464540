/**
 * Required fields for organizer
 */
export const required_fields: string[] = [
  // "comapny_name",
  // "name", // This one is hidden
  // "website",
  // "phone",
  // "country_code",
  // "email"
]; // None should be required anymore.
