import styles from "./Orders.module.scss";
import { isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderHeadType } from "./types";
import { faArrowLeftLong } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import env from "../../../../environment.json";
import Preloader from "src/assets/Preloader";

/**
 * Head-section of the order view
 *
 * @author 					Pætur Mortensen
 */
export default function OrderHead({
  // Data from server
  data,
  // Edited data
  editedData,
  // Close the order view
  close,
  // header state ('in-edit' or 'editable')
  headerState,
  // Setter for header state
  setHeaderState,
  // Reload the order
  reload_order,
  // Order is loading
  isLoading,
  // Setter for isLoading
  setIsLoading,
  // Discard and reset edited data
  discard_edit,
}: OrderHeadType): JSX.Element {
  /***************************************************************************************************
   *
   * 																			API CALLS
   *
   **************************************************************************************************/

  /**
   * Resend order from latest data
   *
   *@author 					Pætur Mortensen
   */
  function send_latest_order(): void {
    setIsLoading(true);

    axios
      .get(env.protocol + env.env + "/api/secured/shop/sendLatestOrder?orderID=" + data.ID)
      .then((response) => {
        reload_order();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  /**
   * Call change order value
   *
   * @author 					Pætur Mortensen
   */
  function changeOrderValue(key: string, value: string): void {
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/shop/changeShopOrderValues", {
        id: data.ID,
        label: key,
        value,
      })
      .then((response) => {
        reload_order();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  /**
   * Make card transaction
   *
   * @author 					Pætur Mortensen
   */
  function makeTransaction(orderID: string): void {
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/quickpay/MakeTransaction", {
        orderId: orderID,
      })
      .then((response) => {
        reload_order();
      })
      .catch((error) => console.error(error));
  }

  /**
   * Set order payment status to done
   *
   * @author 					Pætur Mortensen
   */
  function mark_as_paid(orderId: string): void {
    // Show loader
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/shop/setOrderPaid", {
        orderId,
      })
      .then((response) => {
        reload_order();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  /**
   * Save changes to the order
   *
   * @author 					Pætur Mortensen
   */
  function saveOrder(): void {
    setIsLoading(true);

    // If there have been no changes, just discard
    if (isEqual(data, editedData)) {
      discard_edit();
      setIsLoading(false);
      return;
    }

    // There have been changes, save them..

    axios
      .post(env.protocol + env.env + "/api/secured/shop/AdminEditOrder.php", {
        editedData,
      })
      .then((response) => {
        setHeaderState("editable");
        reload_order();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  /***************************************************************************************************
   *
   * 																	RENDER
   *
   **************************************************************************************************/

  /**
   * Render the header control panel
   *
   * @author 					Pætur Mortensen
   */
  function render_header_cp(): JSX.Element {
    /**
     * Render the edit button
     *
     * @author 					Pætur Mortensen
     */
    function render_edit_button(): JSX.Element {
      return (
        <button
          key={"edit-button"}
          className={styles.editButton}
          disabled={isLoading}
          onClick={() => setHeaderState("in-edit")}
        >
          Edit
        </button>
      );
    }

    /**
     * Render the "Send latest order" button
     *
     * @author 					Pætur Mortensen
     */
    function render_latest_order_button(): JSX.Element {
      return (
        <button
          key="send-pdf"
          className={styles.sendPdf}
          disabled={isLoading}
          onClick={() => {
            send_latest_order();
          }}
        >
          Send Latest Order
        </button>
      );
    }

    /**
     * Render the "approve" button
     *
     * @author 					Pætur Mortensen
     */
    function render_approve_button(): JSX.Element {
      return (
        <button
          key="approve"
          className={styles.approve}
          onClick={() => {
            changeOrderValue("orderStatus", "approved");
          }}
          disabled={data.orderStatus === "approved" || isLoading}
        >
          Approve
        </button>
      );
    }

    /**
     * Render the "Deny" button
     *
     * @author 					Pætur Mortensen
     */
    function render_deny_button(): JSX.Element {
      return (
        <button
          key="deny"
          className={styles.deny}
          onClick={() => {
            changeOrderValue("orderStatus", "denied");
          }}
          disabled={data.orderStatus === "denied" || isLoading}
        >
          Deny
        </button>
      );
    }

    /**
     * Render the "Make transaction" button
     *
     * @author 					Pætur Mortensen
     */
    function render_make_transaction_button(): JSX.Element {
      return (
        <button
          key="send-payment-with-email"
          className={styles.sendPaymentWithEmail}
          onClick={() => {
            makeTransaction(data.ID);
          }}
          disabled={isLoading}
        >
          Make Transaction
        </button>
      );
    }

    /**
     * Render the "mark as paid" button
     *
     * @author 					Pætur Mortensen
     */
    function render_mark_as_paid_button(): JSX.Element {
      return (
        <button
          key="manual-payment-done"
          className={styles.sendPaymentWithEmail}
          onClick={() => {
            mark_as_paid(data.ID);
          }}
          disabled={isLoading}
        >
          Mark as paid
        </button>
      );
    }

    return (
      <>
        {data.paymentStatus !== "done" && render_edit_button()}
        {render_latest_order_button()}
        {render_approve_button()}
        {render_deny_button()}
        {data.paymentStatus === "ready" && render_make_transaction_button()}
        {data.paymentStatus === "manual" && data.orderStatus === "approved" && render_mark_as_paid_button()}
      </>
    );
  }

  /**
   * Render the header in-edit control panel
   *
   * @author 					Pætur Mortensen
   */
  function render_in_edit_cp(): JSX.Element {
    return (
      <>
        <button key={"discard-button"} className={styles.discardButton} onClick={discard_edit} disabled={isLoading}>
          Discard
        </button>
        <button key={"save-button"} className={styles.saveButton} onClick={saveOrder} disabled={isLoading}>
          Save
        </button>
      </>
    );
  }

  return (
    <div className={styles.top}>
      <div>
        <div className={styles.title}>
          <div onClick={close} className={styles.backBtn}>
            <FontAwesomeIcon icon={faArrowLeftLong as IconProp} />
          </div>
          <h1>Order</h1>
        </div>
        <div className={styles.rightSide}>
          <Preloader show={isLoading} />
          {headerState === "editable" ? render_header_cp() : render_in_edit_cp()}
        </div>
      </div>
    </div>
  );
}
