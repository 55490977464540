import { Libraries } from "@react-google-maps/api";

const libraries: Libraries = ["places", "marker"];

const jsApiLoaderParams = {
  id: "google-map-script",
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "MISSING_API_KEY",
  libraries,
};

export default jsApiLoaderParams;
