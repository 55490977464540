import React, { useEffect, useRef, useState } from "react";
import { faChevronLeft, faChevronRight, faLink, faQuestionCircle, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrashAlt, faCropAlt } from "@fortawesome/pro-regular-svg-icons";
import Select from "react-select";
// import QuestionInfo from "../../../assets/QuestionInfo/QuestionInfo";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import Verify from "../../../assets/VerifyMethods";
import ReactPlayer from "react-player";
import VfiTextarea from "../../../assets/VfiTextarea/VfiTextarea";
import env from "../../../environment.json";
import OptionsList from "../../../assets/OptionsList/OptionsList";
import { changeMediaLocation } from "./MediaDisplay";
import rooster from "../../../assets/images/rooster.png";
import pancake from "../../../assets/images/pancake.png";
import sunset from "../../../assets/images/sunset.jpg";
import CopyTextIcon from "../../../assets/CopyTextIcon";
import { capitalize, getOffsetToScrollableParent, getScrollParent } from "../../../assets/helperFunctions";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import ToolTip from "../../ToolTips/ToolTip";
const axios = require("axios");

function MediaSettings(props) {
  const { editMedia } = props;
  const bottomButtonsRef = useRef(null);
  const [saved, setSaved] = useState(false);
  const [seoChanged, setSeoChanged] = useState(false);
  const [downloadMenuOpened, setDownloadMenuOpened] = useState(false);
  const [downloadSize, setDownloadSize] = useState({
    label: "Original file",
    value: ".",
  });
  const [rightMenu, setRightMenu] = useState("seo");

  const [optionListIsOpen, setOptionListIsOpen] = useState(false);
  const [optionsListOptions, setOptionsListOptions] = useState([]);
  const [optionsListPos, setOptionsListPos] = useState({
    x: 0,
    y: 0,
  });

  const [pagesWithMedium, setPagesWithMedium] = useState([]);

  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    const source = axios.CancelToken.source(); // Create a cancel token

    axios
      .get(env.protocol + env.env + "/api/public/pages/GetAllAvailableLanguages.php", {
        cancelToken: source.token, // Pass the cancel token to the axios request
      })
      .then((response) => {
        let langs = response.data;
        setAvailableLanguages(
          langs.sort((a, b) => {
            return b.order_by < a.order_by;
          })
        );
      })
      .catch((error) => {
        // Check if the error is due to the request being cancelled
        if (axios.isCancel(error)) {
          // console.log("Request cancelled");
        } else {
          console.error(error);
        }
      });

    // setSelectedLanguage get smallest order_by
    let lowestOrderNumber, pSelectedLanguage;
    Object.keys(editMedia.languages).forEach((lang) => {
      if (lowestOrderNumber === undefined || lowestOrderNumber > editMedia.languages[lang].order_by) {
        pSelectedLanguage = lang;
        lowestOrderNumber = editMedia.languages[lang].order_by;
      }
    });
    setSelectedLanguage(pSelectedLanguage);

    // Clean up function
    return () => {
      source.cancel(); // Cancel the request if component unmounts
    };
  }, []);

  let downloadMenuClassName = "download-menu";
  downloadMenuClassName += downloadMenuOpened ? " opened" : "";

  const protocolAndHost = env.protocol + env.env;

  const theOptionsList = (
    <OptionsList
      isOpen={optionListIsOpen}
      close={() => setOptionListIsOpen(false)}
      options={optionsListOptions}
      position={optionsListPos}
    />
  );

  const GetPagesWhereMediaIsUsed = () => {
    axios
      .get(env.protocol + env.env + "/api/public/GetPagesWhereMediaIsUsed.php?id=" + props.media.id)
      .then((response) => {
        setPagesWithMedium(response.data);
      })
      .catch((error) => console.error(error));
  };

  const makeBottomButtonsVisible = () => {
    if (bottomButtonsRef.current) {
      const scrollParent = getScrollParent(bottomButtonsRef.current);
      if (scrollParent) {
        const { scrollTop, clientHeight } = scrollParent;
        if (scrollTop + clientHeight < getOffsetToScrollableParent(bottomButtonsRef.current) + 60) {
          bottomButtonsRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    }
  };

  return (
    <div id="media-settings">
      {theOptionsList}
      <div className="settings-header">
        {props.nextMedia && (
          <>
            <FontAwesomeIcon
              className="header-menu previous-media"
              icon={faChevronLeft}
              onClick={() => {
                props.nextMedia(-1);
              }}
            />
            <FontAwesomeIcon
              className="header-menu next-media"
              icon={faChevronRight}
              onClick={() => {
                props.nextMedia(1);
              }}
            />
          </>
        )}
        {props.hideCloseButton !== true ? (
          <FontAwesomeIcon
            className="header-menu close-setting"
            icon={faTimes}
            onClick={() => {
              props.closeSettings();
            }}
          />
        ) : null}
      </div>
      <div className="settings-body">
        <div className="image-info">
          <div className="image-container">
            {Verify.extensionIsVideo(props.media.fileExtension) ? (
              <ReactPlayer
                className="video-container"
                url={protocolAndHost + "/uploads/" + props.media.file_name + "." + props.media.fileExtension}
                controls={true}
              />
            ) : ["pdf"].includes(props.media.fileExtension.toLowerCase()) ? (
              <iframe src={props.media.medium.actual} />
            ) : (
              <img
                src={
                  protocolAndHost +
                  "/uploads/" +
                  props.media.file_name +
                  (props.media.fileExtension.toLowerCase() !== "gif" &&
                  props.media.fileExtension.toLowerCase() !== "svg"
                    ? "_large."
                    : ".") +
                  props.media.fileExtension
                }
                alt="box media"
                onLoad={() => props.onComponentLoaded && props.onComponentLoaded()}
                draggable={false}
              />
            )}
          </div>
          <div className="settings-footer">
            <div className="footer-image-text-info">
              <div className="image-info">
                <div className="name">{props.media.name}</div>
                <div className="date">
                  {/* 12.feb 2020 */}
                  {props.media.date}
                </div>
              </div>
              <div className="image-size">
                <div className="storage-size">{(parseInt(props.media.fileSize) / 1024 / 1024).toFixed(2)} MB</div>
                <div className="dimensions">{props.media.fileDimensions}</div>
              </div>
            </div>
            <div className="footer-buttons">
              <div className="download-element">
                <ToolTip
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Download media</div>}
                  className="button-element"
                  onClick={() => {
                    setDownloadMenuOpened(!downloadMenuOpened);
                  }}
                >
                  <div className="icon">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="text">DOWNLOAD</div>
                </ToolTip>
                <div className={downloadMenuClassName}>
                  <FontAwesomeIcon className="close-tab" icon={faTimes} onClick={() => setDownloadMenuOpened(false)} />
                  <div className="download-text">Download media</div>
                  <div className="download-sub">IMAGE FORMAT</div>
                  <Select
                    className="download-select"
                    options={
                      !Verify.extensionIsImage(props.media.fileExtension)
                        ? [{ label: "Original file", value: "." }]
                        : [
                            { label: "Original file", value: "." },
                            { label: "Medium", value: "_medium." },
                            { label: "Large", value: "_large." },
                            { label: "Full", value: "_full." },
                          ]
                    }
                    value={downloadSize}
                    onChange={setDownloadSize}
                  />
                  <button
                    className="download-button"
                    onClick={() => {
                      if (downloadSize !== "") {
                        let imageLocation =
                          env.protocol +
                          "//" +
                          env.env +
                          "/uploads/" +
                          props.media.file_name +
                          downloadSize.value +
                          props.media.fileExtension;

                        let fileName = props.media.fileName;

                        if (!fileName.toLowerCase().endsWith("." + props.media.fileExtension.toLowerCase())) {
                          fileName += "." + props.media.fileExtension;
                        }

                        var link = document.createElement("a");
                        link.href = imageLocation;
                        link.download = fileName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }
                    }}
                  >
                    DOWNLOAD
                  </button>
                  <div className="point-down"></div>
                </div>
              </div>
              {!Verify.extensionIsImage(props.media.fileExtension) ? (
                ""
              ) : props.openEdit !== undefined ? (
                <ToolTip
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Edit image</div>}
                  className="button-element"
                  onClick={() => {
                    props.setSiteRef(props.media.sites.length > 0 ? props.media.sites[0].siteId : "0");
                    props.openEdit("mediaedit", props.media);
                  }}
                >
                  <div className="icon">
                    <FontAwesomeIcon icon={faCropAlt} />
                  </div>
                  <div className="text">EDIT</div>
                </ToolTip>
              ) : (
                ""
              )}
              <ToolTip
                offset={{ x: 30, y: 30 }}
                priority={"right,bottom"}
                title=""
                delay={600}
                toolTipElements={<div>Delete media</div>}
                className="button-element"
                onClick={() => {
                  if (pagesWithMedium.length > 0) {
                    alert(
                      "You cannot delete media that's being used. \nMake sure you remove the media from every location it is being used before deleting!"
                    );
                  } else {
                    props.deleteMedia(props.media.id);
                  }
                  //
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
                <div className="text">DELETE</div>
              </ToolTip>
            </div>
          </div>
        </div>
        <div className="image-edit-labels">
          <table className="image-edit-labels-header">
            <thead>
              <tr>
                <th
                  className={rightMenu === "seo" ? "option selected" : "option"}
                  onClick={() => {
                    setRightMenu("seo");
                  }}
                >
                  SEO
                </th>
                <th
                  className={rightMenu === "category" ? "option selected" : "option"}
                  onClick={() => {
                    setRightMenu("category");
                  }}
                >
                  CATEGORY
                </th>
                <th
                  className={rightMenu === "location" ? "option selected" : "option"}
                  onClick={() => {
                    setRightMenu("location");
                    GetPagesWhereMediaIsUsed();
                  }}
                >
                  LOCATION
                </th>
                <th></th>
              </tr>
            </thead>
          </table>
          <div className="settings-right-content">
            {/* SEO MENU */}
            {rightMenu === "seo" ? (
              <div>
                <div className="media-langauges">
                  {Object.keys(editMedia.languages)
                    .sort((a, b) => {
                      return editMedia.languages[b].order_by < editMedia.languages[a].order_by;
                    })
                    .map((lang) => {
                      return (
                        <div
                          key={lang}
                          className={"one-lang" + (lang === selectedLanguage ? " active" : "")}
                          onClick={() => setSelectedLanguage(lang)}
                        >
                          {lang.toUpperCase()}
                        </div>
                      );
                    })}
                  <ToolTip
                    offset={{ x: 20, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={1000}
                    toolTipElements={<div>Add language</div>}
                    className={"add-lang"}
                    onClick={(e) => {
                      setOptionListIsOpen(true);
                      setOptionsListOptions(
                        availableLanguages
                          .filter(
                            // FILTER WORK: Don't show the already added languages
                            (lang) => Object.keys(editMedia.languages).find((l) => l === lang.short) === undefined
                          )
                          .map((lang) => {
                            const languageSelectedObj = editMedia.languages[selectedLanguage] ?? {
                              name: "",
                              headline: "",
                              alt: "",
                              comment: "",
                            };

                            return {
                              is: "choice",
                              text: capitalize(lang.what_language),
                              onClick: () => {
                                axios
                                  .post(env.protocol + env.env + "/api/secured/AddLanguageToMedia", {
                                    langId: lang.id,
                                    mediaId: editMedia.id,
                                    name: languageSelectedObj.name,
                                    headline: languageSelectedObj.headline,
                                    alt: languageSelectedObj.alt,
                                    comment: languageSelectedObj.comment,
                                  })
                                  .then((response) => {
                                    if (response.data.success) {
                                      const newMediaLanguage = response.data.newMediaLanguage;
                                      props.editSettings(`languages.${lang.short}`, newMediaLanguage);
                                    }

                                    // IF SUCCESS, ADD LANGUAGE TO MEDIA LANGUAGE LIST
                                  })
                                  .catch((error) => console.error(error));
                              },
                            };
                          })
                      );
                      setOptionsListPos({ x: e.pageX, y: e.pageY });
                    }}
                  >
                    +
                  </ToolTip>
                </div>
                <table className="seo-table">
                  <tbody>
                    <tr>
                      <td className="info">
                        <ToolTip
                          solid
                          click
                          title="File name"
                          priority="bottom,top,left"
                          toolTipElements={
                            <ul style={{ padding: 0, margin: 20, maxWidth: 450 }}>
                              <li>
                                Always keep your filenames simple, descriptive end use relevant keywords as per your
                                website’s content.
                              </li>
                              <li>
                                Make sure that you choose 4-5 keywords that should also be present in the title of your
                                page.
                              </li>
                              <li>Don’t forget to put hyphens and avoid using underscores.</li>
                              <li>
                                Try to reduce the size of your file as Google skips all the files with heavy and long
                                names.
                              </li>
                              <li>
                                Example:&nbsp;
                                <b>
                                  <i>Safe-Hiking-in-the-Faroe-Islands-brochure</i>
                                </b>
                              </li>
                            </ul>
                          }
                          className="question-info"
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </ToolTip>
                        <span className="label">File name</span>
                      </td>
                      <td className="file-name-td">
                        <VfiInputText
                          value={editMedia.fileName}
                          onChange={(e) => {
                            // props.editSettings("fileName", e.target.value);
                            props.editSettings("fileName", e.target.value);
                            setSeoChanged(true);
                          }}
                          errorCheckSequence={[Verify.notEmpty]}
                          onFocus={makeBottomButtonsVisible}
                        />
                        <ToolTip
                          offset={{ x: -60, y: 30 }}
                          priority={"right,bottom"}
                          title=""
                          delay={600}
                          toolTipElements={<div>Copy url</div>}
                        >
                          <CopyTextIcon
                            className="copy-image-url"
                            value={
                              env.protocol +
                              env.env +
                              "/uploads/" +
                              editMedia.file_name +
                              "." +
                              props.media.fileExtension
                            }
                          />
                        </ToolTip>
                        <div className="link-slug">
                          <FontAwesomeIcon icon={faLink} />/{`${editMedia.file_name}.${props.media.fileExtension}`}
                        </div>
                      </td>
                    </tr>
                    {selectedLanguage ? (
                      <tr>
                        <td className="info text-area-info">
                          <ToolTip
                            click
                            solid
                            priority="left,bottom,top"
                            title="Description"
                            toolTipElements={
                              <div
                                style={{
                                  position: "relative",
                                  top: 0,
                                  left: 0,
                                }}
                              >
                                <h3>Examples of Description / Alt text</h3>
                                <div>
                                  <img alt="pancake" src={pancake} />
                                  <br />
                                  <b>Okey:</b> “pancakes”
                                  <br />
                                  <br />
                                  <i>
                                    This alt text is only "okay" because it's not very descriptive. Yes, this is an
                                    image of a stack of pancakes. But, there's more to be said about this image
                                  </i>
                                  <br />
                                  <br />
                                  <b>Good:</b> “Stack of blueberry pancakes with powdered sugar”
                                  <br />
                                  <br />
                                  <i>
                                    This alt text is a better alternative because it is far more descriptive of what's
                                    in the image. This isn't just a stack of "pancakes" (as the first alt text example
                                    demonstrated); it's a stack of blueberry pancakes with a dusting of powdered sugar!
                                  </i>
                                  <br />
                                  <br />
                                </div>
                                <div>
                                  <img alt="rooster" src={rooster} />
                                  <br />
                                  <b>Okey: </b> <i>“Rooster”</i>
                                  <br />
                                  <b>Better: </b> <i>“Rooster crowing”</i>
                                  <br />
                                  <b>Best: </b>&nbsp;
                                  <i>“Red-crested rooster crowing”</i>
                                  <br />
                                  <br />
                                </div>
                                <div>
                                  <img alt="sunset" src={sunset} />
                                  <br />
                                  <b>Bad: </b>
                                  <i>“sunset”</i>
                                  <br />
                                  <b>Okey: </b>

                                  <i>“sunset over Klaksvik”</i>
                                  <br />
                                  <b>Better: </b>
                                  <i>
                                    “areal picture of sunset over Klaksvik, the second-largest village of the Faroe
                                    Islands”
                                  </i>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            }
                          >
                            <FontAwesomeIcon icon={faQuestionCircle} />
                          </ToolTip>
                          <span className="label">{`Description (${selectedLanguage.toUpperCase()})`}</span>
                          &nbsp;
                        </td>
                        <td>
                          <VfiTextarea
                            value={selectedLanguage ? editMedia.languages[selectedLanguage].alt : ""}
                            onChange={(e) => {
                              props.editSettings("languages." + selectedLanguage + ".alt", e);
                              setSeoChanged(true);
                            }}
                            onFocus={makeBottomButtonsVisible}
                          />
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td className="info text-area-info">
                        <ToolTip
                          click
                          solid
                          priority="left,bottom,top"
                          title=""
                          toolTipElements={
                            <div>
                              <ul
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  marginLeft: 20,
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <li>Only for internal use</li>
                              </ul>
                            </div>
                          }
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </ToolTip>
                        <span className="label">Comments</span>&nbsp;
                      </td>
                      <td>
                        <VfiTextarea
                          value={editMedia.comment}
                          onChange={(e) => {
                            props.editSettings("comment", e);
                            setSeoChanged(true);
                          }}
                          onFocus={makeBottomButtonsVisible}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="buttons" ref={bottomButtonsRef}>
                  {props.updateClick !== undefined ? (
                    <button
                      disabled={seoChanged === false}
                      className={"update-button" + (saved ? " saved" : "")}
                      onClick={() => {
                        setSeoChanged(false);
                        props.updateClick(editMedia);

                        const m = editMedia;

                        axios
                          .post(env.protocol + env.env + "/api/secured/updateMedia", {
                            media: {
                              id: m.id,
                              headline: m.name,
                              description: m.description,
                              comment: m.comment,
                              name: m.fileName,
                              author: m.author_info,
                              sites: m.locations,
                              alt: m.alt,
                              file_name: m.file_name,
                              languages: m.languages,
                            },
                            userId: sessionStorage.getItem("vfiUser"),
                          })
                          .then((response) => {
                            props.editSettings("file_name", response.data.file_name);

                            setSaved(true);
                            setTimeout(() => {
                              setSaved(false);
                            }, 5000);
                          })
                          .catch((error) => {
                            console.error(error);
                          });
                      }}
                    >
                      {!saved ? (
                        "Update"
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faCheck} />
                          <span>Changes saved</span>
                        </>
                      )}
                    </button>
                  ) : null}
                  {props.insertClick !== undefined ? (
                    <button
                      className="insert-button"
                      onClick={() => {
                        props.insertClick([props.media]);

                        if (!props.media.locations.find((e) => e.siteId === props.site))
                          changeMediaLocation(props.media.id, props.site);
                      }}
                    >
                      Insert
                    </button>
                  ) : null}
                </div>
              </div>
            ) : null}
            {/* CATEGORY MENU */}
            {rightMenu === "category" ? <div className="category"></div> : ""}
            {/* LOCATION MENU */}
            {rightMenu === "location" ? (
              <div className="location">
                <p className="text-over">This media is located in these pages:</p>
                {pagesWithMedium.map((element) => {
                  return (
                    <p key={element.id} className="site">
                      <a rel="noreferrer" href={element.url} target="_blank">
                        {element.title}
                      </a>
                      <span className="gray">&nbsp; ({element.short.toUpperCase()})</span>
                    </p>
                  );
                })}
                {pagesWithMedium.length === 0 ? <p className="site empty">empty</p> : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default MediaSettings;
