import styles from "./button.module.scss";

/**
 * Button component
 *
 * Get a button for use in the system
 *
 * @author 					Pætur Mortensen
 */
type Button = {
  children: React.ReactNode;
  type?: "primary" | "secondary" | "black";
  size?: "small" | "medium" | "large";
  block?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};
export default function Button({
  children,
  type = "primary",
  size = "medium",
  block = false,
  onClick = () => {},
  disabled = false,
}: Button): JSX.Element {
  // Init the button classes
  let buttonClasses = styles.button + " " + styles[type] + " " + styles[size];

  // If this is a block-level button, add this class
  if (block) {
    buttonClasses += " " + styles.block;
  }

  return (
    <button className={buttonClasses} onClick={() => onClick()} disabled={disabled}>
      {children}
    </button>
  );
}
