import React from "react";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import ExpandVertical from "../../ExpandVertical/ExpandVertical";
import "./TextAndExpand.scss";
import { faSyncAlt } from "@fortawesome/pro-light-svg-icons";
import { cloneDeep } from "lodash";

export function moduleOptions(index, contentData, setContentData) {
  return [
    // {
    //   is: "choice",
    //   icon: faFrown,
    //   text: "None implemented yet...",
    //   onClick: () => {
    //     let newContentData = Object.assign([], contentData);
    //     // newContentData.splice(i, 1);
    //     // newContentData[index].type = "first_letter_big";
    //     setContentData(newContentData);
    //   },
    //   style: { color: "red" },
    // },
    {
      is: "choice",
      icon: faSyncAlt,
      text: "Swap",
      onClick: () => {
        let newContentData = cloneDeep(contentData);
        newContentData[index].swapped = !newContentData[index].swapped;
        setContentData(newContentData);
      },
    },
  ];
}

export default function TextAndExpand(props) {
  return (
    <div
      style={props.style}
      className={"text-and-expand " + (props.data.swapped ? "swapped " : "") + (props.className ? props.className : "")}
    >
      <VfiCKeditor
        className="tae-second"
        value={props.value1}
        onBlur={(e) => {
          props.onBlur(e, 0);
        }}
        toolbar={props.toolbar}
      />
      <ExpandVertical className="tae-fourth">
        <VfiCKeditor
          value={props.value2}
          onBlur={(e) => {
            props.onBlur(e, 1);
          }}
          toolbar={props.toolbar}
        />
      </ExpandVertical>
    </div>
  );
}
