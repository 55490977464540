import { useState } from "react";
import Modal from "src/Components/Modal";
import "../../EditProduct.scss";
import Preloader from "src/assets/Preloader";
import axios from "axios";
import env from "../../../../../../environment.json";

type AddVariant = {
  onClose: () => void;
  productID: number;
  onCreate: () => void;
};

export default function AddVariant({ onClose, productID, onCreate }: AddVariant): JSX.Element {
  // Label input value
  const [label, setLabel] = useState<string>("");
  // Whether the component is currently loading something
  const [isLoading, setIsLoading] = useState(false);

  function save_variant() {
    setIsLoading(true);
    axios
      .post(env.protocol + env.env + "/api/secured/shop/AddVariant", {
        label,
        productID,
      })
      .then((response) => {
        // ID of new variant is sent back from server
        // const variantID = response.data.variantID;
        onCreate();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  return (
    <Modal
      title="Add variant"
      type="confirmation"
      close={onClose}
      onCancel={onClose}
      onConfirm={save_variant}
      confirmButtonText="Add variant"
    >
      <input
        placeholder="Variant label"
        value={label}
        onChange={(e) => {
          setLabel(e.target.value);
        }}
      />
      <div className="add-variant-preload-container">
        <Preloader show={isLoading} />
      </div>
    </Modal>
  );
}
