import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./ExpandVertical.scss";

export default function ExpandVertical(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={"expand-vertical " + (expanded ? "expanded " : "") + (props.className ? props.className : "")}
      style={props.style ? props.style : {}}
    >
      <div className="expand-vertical-content">
        {props.children}
        <div className="expand-vertical-shadow" />
      </div>
      <div
        className="expand-vertical-button"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
    </div>
  );
}
