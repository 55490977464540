import env from "../../../../../environment.json";
import vfiLogo from "../../../../../assets/images/vfi-logo-lighter.png";
import vitaLogo from "../../../../../assets/images/vita-logo.png";

import styles from "./VfiLogo.module.scss";

interface Props {
  className?: string;
}

export default function VfiLogo({ className = "" }: Props) {
  return (
    <div className={`${styles.container} ${className}`}>
      <img className={styles.vfi_logo} src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo} alt="vfi-logo" />
      <p>What's On</p>
    </div>
  );
}
