import React, { Component } from "react";
import env from "../../../../environment.json";
import "./MediaEdit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
//import localHost from "../../../../../src/assets/localHost";
//import {Image} from 'cloudinary-react';
import { Image, Transformation, CloudinaryContext } from "cloudinary-react";
import { pushMedia } from "../../../../assets/helperFunctions";

class MediaEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      crop: {
        // unit: "%",
        // width: 100,
        aspect: null,
      },
      zoom: 0,
      uploading: false,
      uploadPercent: 0,
    };

    this.croppedImages = [];

    this.axios = require("axios");

    this.config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        this.setState(
          {
            uploadPercent: percentCompleted,
          },
          () => {
            if (percentCompleted === 100) {
            }
          }
        );
      },
    };

    this.protocolAndHost = env.protocol + env.env;

    this.imageOriginal =
      this.protocolAndHost + "/uploads/" + this.props.data.file_name + "." + this.props.data.fileExtension;

    this.cropDisplay = React.createRef();
  }

  onImageLoaded = (image) => {
    this.imageRef = image;

    this.setState({ crop: this.centerAspect(1 / 1) });
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    //this.makeClientCrop(crop);
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  CloudinaryImage(data, format, isInfo = false) {
    return (
      <Image
        publicId={env.protocol + env.env + "/uploads/" + data.file_name + "_large." + data.fileExtension}
        format={data.fileExtension.toLowerCase() === "jpeg" ? "jpg" : data.fileExtension}
        type="fetch"
        onClick={(e) => {
          this.cloudinaryImagePress({ ...e, format });
        }}
      >
        {isInfo ? (
          <Transformation aspectRatio={format} crop="fill" gravity="auto" flags="getinfo" />
        ) : (
          <Transformation aspectRatio={format} crop="fill" gravity="auto" />
        )}
      </Image>
    );
  }

  saveClick() {
    this.setState({ uploading: true });

    const originalImage = document.createElement("img");
    originalImage.crossOrigin = "anonymous";
    originalImage.onload = (e) => {
      e.preventDefault();
      this.save(originalImage);
    };
    originalImage.src = this.imageOriginal;
  }

  async save(originalImage) {
    var formData = new FormData();

    // Make blob file of cropped image
    const blob = await this.blobbifyImage(originalImage);

    if (blob === null || blob === undefined) {
      this.setState({ uploading: false });
    } else {
      formData.append("fileToUpload[]", blob, blob.name);
      formData.append("siteId", this.props.site);
      formData.append("userId", sessionStorage.getItem("vfiUser"));

      this.axios
        .post(env.protocol + env.env + "/api/secured/UploadMediaFile", formData, this.config)
        .then((response) => {
          this.props.addOneMedia([pushMedia(response.data[0])]);
          this.props.close();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  blobbifyImage(originalImage) {
    const canvas = document.createElement("canvas");

    const scaleX = originalImage.naturalWidth / this.imageRef.width;
    const scaleY = originalImage.naturalHeight / this.imageRef.height;
    canvas.width = this.state.crop.width * scaleX;
    canvas.height = this.state.crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    if (canvas.width === 0 || canvas.height === 0) return null;

    ctx.drawImage(
      originalImage,
      this.state.crop.x * scaleX,
      this.state.crop.y * scaleY,
      this.state.crop.width * scaleX,
      this.state.crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            //reject(new Error('Canvas is empty'));
            resolve(null);
          }

          // var gcd = function (a, b) {
          //   if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

          //   return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
          // };
          const n = originalImage.src.lastIndexOf(".");
          blob.name =
            "copy_of_" +
            this.props.data.fileName +
            (this.props.data.fileName.toLowerCase().endsWith("." + originalImage.src.substring(n + 1).toLowerCase()) ===
            false
              ? "." + originalImage.src.substring(n + 1).toLowerCase()
              : "");
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(blob);
          // }, "image/" + this.props.data.fileExtension);
        },
        "image/jpeg",
        1
      );
    })
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fitAspect(aspect) {
    let json = {};
    json.aspect = aspect;
    json.x = this.state.crop.x;
    json.y = this.state.crop.y;
    json.height = this.state.crop.height;
    json.width = json.height * aspect;
    return json;
  }

  centerAspect = (aspect) => {
    let extraCrop = {};
    if (this.imageRef !== undefined) {
      if (this.imageRef.width / aspect > this.imageRef.height) {
        extraCrop = {
          ...extraCrop,
          x: (this.imageRef.width - this.imageRef.height * aspect) / 2,
          y: 0,
        };

        return {
          width: this.imageRef.height * aspect,
          height: this.imageRef.height,
          aspect,
          ...extraCrop,
        };
      } else if (this.imageRef.width / aspect < this.imageRef.height) {
        extraCrop = {
          ...extraCrop,
          y: (this.imageRef.height - this.imageRef.width / aspect) / 2,
          x: 0,
        };

        return {
          width: this.imageRef.width,
          height: this.imageRef.width / aspect,
          aspect,
          ...extraCrop,
        };
      } else {
        return {
          width: this.imageRef.width,
          height: this.imageRef.width / aspect,
          aspect,
          y: 0,
          x: 0,
        };
      }
    }

    return {};
  };

  cloudinaryImagePress(e) {
    this.axios(
      `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/fetch/ar_` +
        e.format.toString() +
        ",c_fill,fl_getinfo,g_auto/f_jpg/" +
        env.protocol +
        env.env +
        "/uploads/" +
        this.props.data.file_name +
        "_large." +
        this.props.data.fileExtension
    )
      .then((response) => {
        const inputImg = response.data.input;
        const outputImg = response.data.resize[0];

        this.setState({
          crop: {
            unit: "px",
            x: (outputImg.x / inputImg.width) * this.imageRef.width,
            y: (outputImg.y / inputImg.height) * this.imageRef.height,
            width: (outputImg.width / inputImg.width) * this.imageRef.width,
            height: (outputImg.height / inputImg.height) * this.imageRef.height,
            aspect: null,
          },
        });
      })
      .catch((error) => console.error(error));
  }

  render() {
    const imageFull =
      this.protocolAndHost + "/uploads/" + this.props.data.file_name + "_full." + this.props.data.fileExtension;

    let cropDisplayFormat = 1;
    if (this.cropDisplay.current !== null) {
      cropDisplayFormat = this.cropDisplay.current.offsetWidth / this.cropDisplay.current.offsetHeight;
    }

    const imgX = parseInt(this.props.data.fileDimensions.split("x")[0]);
    const imgY = parseInt(this.props.data.fileDimensions.split("x")[1]);

    let formatDiff = imgX / imgY / cropDisplayFormat;
    let width100 = 100;
    if (formatDiff > 1) width100 = 100;
    else width100 = width100 * formatDiff - 2;
    const currentZoom = width100 + this.state.zoom * 1.65;

    return (
      <div className="media-edit">
        <div className="header">
          <div
            className="close"
            onClick={() => {
              this.props.close();
            }}
          >
            <p className="icon">
              <FontAwesomeIcon icon={faTimes} />
            </p>
          </div>
          {/* <FontAwesomeIcon className="undo" icon={faUndo} />
          <FontAwesomeIcon className="redo" icon={faRedo} /> */}
          <div
            className="cancel"
            onClick={() => {
              this.props.close();
            }}
          >
            <div className="text">Cancel</div>
          </div>
          <div
            className="save"
            onClick={() => {
              this.saveClick();
            }}
          >
            <div className="text">Save</div>
          </div>
        </div>
        <div className="body">
          <div className="crop-top">
            <div className="crop-display" ref={this.cropDisplay}>
              <ReactCrop
                src={imageFull}
                //crossorigin="anonymous"
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                style={{ maxWidth: "1000%", width: currentZoom + "%" }}
                //imageStyle = {{maxWidth:'100%', maxHeight:'100%'}}
                minWidth={0}
                maxWidth={10000}
                minHeight={0}
                maxHeight={10000}
              />
            </div>
            <div className="crop-tweaks">
              <p>SCALE</p>
              <input
                type="range"
                min="0"
                max="100"
                value={this.state.zoom}
                onChange={(e) => {
                  this.setState({
                    zoom: e.target.value,
                  });
                }}
              />
              <p>ASPECT RATIO</p>
              <div
                className={this.state.crop.aspect === 1 / 1 ? "aspect-ratio one-one selected" : "aspect-ratio one-one"}
                onClick={() => this.setState({ crop: { ...this.centerAspect(1 / 1) } })}
              >
                <span>1:1</span>
              </div>
              <div
                className={this.state.crop.aspect === 1 / 2 ? "aspect-ratio one-two selected" : "aspect-ratio one-two"}
                onClick={() => this.setState({ crop: { ...this.centerAspect(1 / 2) } })}
              >
                <span>1:2</span>
              </div>
              <div
                className={
                  this.state.crop.aspect === 2 / 3 ? "aspect-ratio two-three selected" : "aspect-ratio two-three"
                }
                onClick={() => this.setState({ crop: { ...this.centerAspect(2 / 3) } })}
              >
                <span>2:3</span>
              </div>
              <div
                className={
                  this.state.crop.aspect === 3 / 2 ? "aspect-ratio three-two selected" : "aspect-ratio three-two"
                }
                onClick={() => this.setState({ crop: { ...this.centerAspect(3 / 2) } })}
              >
                <span>3:2</span>
              </div>
              <div
                className={
                  this.state.crop.aspect === 3 / 4 ? "aspect-ratio three-four selected" : "aspect-ratio three-four"
                }
                onClick={() => this.setState({ crop: { ...this.centerAspect(3 / 4) } })}
              >
                <span>3:4</span>
              </div>
              <div
                className={
                  this.state.crop.aspect === 4 / 3 ? "aspect-ratio four-three selected" : "aspect-ratio four-three"
                }
                onClick={() => this.setState({ crop: { ...this.centerAspect(4 / 3) } })}
              >
                <span>4:3</span>
              </div>
              <div
                className={
                  this.state.crop.aspect === 4 / 5 ? "aspect-ratio four-five selected" : "aspect-ratio four-five"
                }
                onClick={() => this.setState({ crop: { ...this.centerAspect(4 / 5) } })}
              >
                <span>4:5</span>
              </div>
              <div
                className={
                  this.state.crop.aspect === 9 / 16 ? "aspect-ratio nine-sixteen selected" : "aspect-ratio nine-sixteen"
                }
                onClick={() => this.setState({ crop: { ...this.centerAspect(9 / 16) } })}
              >
                <span>9:16</span>
              </div>
              <div
                className={
                  this.state.crop.aspect === 16 / 9 ? "aspect-ratio sixteen-nine selected" : "aspect-ratio sixteen-nine"
                }
                onClick={() => this.setState({ crop: { ...this.centerAspect(16 / 9) } })}
              >
                <span>16:9</span>
              </div>
              <div
                className={this.state.crop.aspect === null ? "aspect-ratio custom selected" : "aspect-ratio custom"}
                onClick={() => this.setState({ crop: { ...this.state.crop, aspect: null } })}
              >
                <span>Custom</span>
              </div>
            </div>
          </div>
          <div className="crop-bottom">
            <CloudinaryContext cloudName="hemmingtest" className="cloudinary-images">
              {/* <div className="c-image one-one">
                <Image
                  publicId={
                    env.protocol+env.env+"/uploads/" +
                    this.props.data.file_name +
                    "_large.jpg"
                  }
                  format={this.props.data.fileExtension}
                  type="fetch"
                  onClick={(e)=>{this.cloudinaryImagePress(e)}}
                >
                  <Transformation
                    aspectRatio={1 / 1}
                    crop="fill"
                    gravity="auto"
                  />
                </Image>
              </div>

              <div className="c-image one-two">
                <Image
                  publicId={
                    env.protocol+env.env+"/uploads/" +
                    this.props.data.file_name +
                    "_large.jpg"
                  }
                  format={this.props.data.fileExtension}
                  type="fetch"
                  onClick={(e)=>{this.cloudinaryImagePress(e)}}
                >
                  <Transformation
                    aspectRatio={1 / 2}
                    crop="fill"
                    gravity="auto"
                  />
                </Image>
              </div>

              <div className="c-image two-three">
                <Image
                  publicId={
                    env.protocol+env.env+"/uploads/" +
                    this.props.data.file_name +
                    "_large.jpg"
                  }
                  format={this.props.data.fileExtension}
                  type="fetch"
                  onClick={(e)=>{this.cloudinaryImagePress(e)}}
                >
                  <Transformation
                    aspectRatio={2 / 3}
                    crop="fill"
                    gravity="auto"
                  />
                </Image>
              </div>

              <div className="c-image three-two">
                <Image
                  publicId={
                    env.protocol+env.env+"/uploads/" +
                    this.props.data.file_name +
                    "_large.jpg"
                  }
                  format={this.props.data.fileExtension}
                  type="fetch"
                  onClick={(e)=>{this.cloudinaryImagePress(e)}}
                >
                  <Transformation
                    aspectRatio={3 / 2}
                    crop="fill"
                    gravity="auto"
                  />
                </Image>
              </div>

              <div className="c-image three-four">
                <Image
                  publicId={
                    env.protocol+env.env+"/uploads/" +
                    this.props.data.file_name +
                    "_large.jpg"
                  }
                  format={this.props.data.fileExtension}
                  type="fetch"
                  onClick={(e)=>{this.cloudinaryImagePress(e)}}
                >
                  <Transformation
                    aspectRatio={3 / 4}
                    crop="fill"
                    gravity="auto"
                  />
                </Image>
              </div>

              <div className="c-image four-three">
                <Image
                  publicId={
                    env.protocol+env.env+"/uploads/" +
                    this.props.data.file_name +
                    "_large.jpg"
                  }
                  format={this.props.data.fileExtension}
                  type="fetch"
                  onClick={(e)=>{this.cloudinaryImagePress(e)}}
                >
                  <Transformation
                    aspectRatio={4 / 3}
                    crop="fill"
                    gravity="auto"
                  />
                </Image>
              </div>

              <div className="c-image four-five">
                <Image
                  publicId={
                    env.protocol+env.env+"/uploads/" +
                    this.props.data.file_name +
                    "_large.jpg"
                  }
                  format={this.props.data.fileExtension}
                  type="fetch"
                  onClick={(e)=>{this.cloudinaryImagePress(e)}}
                >
                  <Transformation
                    aspectRatio={4 / 5}
                    crop="fill"
                    gravity="auto"
                  />
                </Image>
              </div>

              <div className="c-image nine-sixteen">
                <Image
                  publicId={
                    env.protocol+env.env+"/uploads/" +
                    this.props.data.file_name +
                    "_large.jpg"
                  }
                  format={this.props.data.fileExtension}
                  type="fetch"
                  onClick={(e)=>{this.cloudinaryImagePress(e)}}
                >
                  <Transformation
                    aspectRatio={9 / 16}
                    crop="fill"
                    gravity="auto"
                  />
                </Image>
              </div>

              <div className="c-image sixteen-nine">
                {this.CloudinaryImage(this.props.data, 16/9, false)}
              </div> */}

              <div className="cloudinary-suggestions">
                <div className="c-image one">{this.CloudinaryImage(this.props.data, 409 / 330, false)}</div>
                <div className="c-image seven">{this.CloudinaryImage(this.props.data, 157 / 330, false)}</div>
                <div className="c-image two">{this.CloudinaryImage(this.props.data, 238 / 159, false)}</div>
                <div className="c-image three">{this.CloudinaryImage(this.props.data, 498 / 159, false)}</div>
                <div className="c-image four">{this.CloudinaryImage(this.props.data, 409 / 159, false)}</div>
                <div className="c-image five">{this.CloudinaryImage(this.props.data, 123 / 159, false)}</div>
                <div className="c-image six">{this.CloudinaryImage(this.props.data, 192 / 159, false)}</div>
              </div>
            </CloudinaryContext>

            {this.croppedImages.map((element, i) => {
              var canvasData = element.toDataURL();
              return <img alt={i} src={canvasData} key={i} />;
            })}
          </div>
        </div>
        {this.state.uploading ? (
          <div className="loading-blur">
            <progress value={this.state.uploadPercent} max="100" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default MediaEdit;
