import "../EditProduct.scss";

/**
 * Variants and stock status
 *
 * @param 		{object} 		product 								Product state
 *
 * @returns 	{jsx} 															Stock panel
 *
 * @author 					Pætur Mortensen
 */
export default function StockPanel({ product }) {
  const numVariants = product.variants.length;
  // Get number of total items in stock
  const numItemsInStock = product.variants.reduce((accumulator, variant) => {
    // Data types may be bad, try to fix
    const stock = !isNaN(variant.quantity) ? parseInt(variant.quantity) : 0;
    return accumulator + stock;
  }, 0);
  // Get number of items in remote stock
  const numItemsInRemoteStock = product.variants.reduce((accumulator, variant) => {
    // Data types may be bad, try to fix
    const remoteStock = !isNaN(variant.remoteQuantity) ? parseInt(variant.remoteQuantity) : 0;
    return accumulator + remoteStock;
  }, 0);

  return (
    <div className="warehouse">
      <h3>Stock</h3>
      <div className="storage">
        <table>
          <tbody>
            <tr>
              <td>Variants:</td>
              <td>{numVariants}</td>
            </tr>
            <tr>
              <td>In stock:</td>
              <td>{numItemsInStock}</td>
            </tr>
            <tr>
              <td>In remote stock:</td>
              <td>{numItemsInRemoteStock}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
