import { useRouterStore } from "src/Components/Router/routerStore";
import styles from "./Change.module.scss";
import ChangeEvent from "./change-types/change-event/ChangeEvent";

const submit_types = ["event", "place", "tour", "hike", "tour", "tour-operator"];

export default function Change() {
  const { route, navByLink } = useRouterStore();

  const { get_parameters } = useRouterStore();

  if (!get_parameters?.edit_token) return <div>No token</div>;

  return <div className={styles.container}>{route[1]?.nav === "event" && <ChangeEvent />}</div>;
}
