import languageText from "./language.json";
import { getLocalStorageLanguage, language2short } from "src/utils/systemLanguageUtils";
import { TSystemKeyLanguagesShort, TSystemLanguage } from "src/types/types/TSystemLanguage";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import styles from "./VideoLinks.module.scss";

interface ILanuguageText {
  video_link_text: TSystemKeyLanguagesShort<string>;
  video_link_subtext: TSystemKeyLanguagesShort<string>;
  video_link_button: TSystemKeyLanguagesShort<string>;
  link_placeholder: TSystemKeyLanguagesShort<string>;
}
interface IVideoLink {
  key: number;
  url: string;
}
interface props {
  videoLinks: IVideoLink[];
  isSuggested?: boolean;
  change: (videoLinks: IVideoLink[]) => void;
}
export default function VideoLinks({ videoLinks: propVideoLinks, isSuggested = false, change }: props) {
  const language: ILanuguageText = languageText;
  const selectedLanguage = getLocalStorageLanguage();
  const shortLang = language2short(selectedLanguage);
  const [videoLinks, setVideoLinks] = useState(cloneDeep(propVideoLinks));
  const [videoLinksLength, setVideoLinksLength] = useState(videoLinks.length);
  const videoLinksRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    change(videoLinks);
  }, [videoLinks]);

  useEffect(() => {
    /**
     * TODO: The ref feature should be able to be changed to reference the react dom of the last input.
     * To be able to do this, VfiInputText component must be typescript based.
     *
     * @author Hemming Petersen
     * */
    if (videoLinksLength < videoLinks.length) {
      const nodes = videoLinksRef.current?.querySelectorAll(`.${styles["url-input"]} input`);
      const last = nodes ? (nodes[nodes?.length - 1] as HTMLInputElement) : undefined;

      if (last !== undefined) {
        last.focus();
      }
    }

    setVideoLinksLength(videoLinks.length);
  }, [videoLinks.length]);

  const trashButtonShown = videoLinks.length > 1 || ["", undefined].includes(videoLinks[0]?.url) === false;
  const addUrlActive =
    ["", undefined].includes(videoLinks[videoLinks.length - 1]?.url) === false || videoLinks.length === 0;

  return (
    <>
      <p className={styles["extra-video-link-text"]}>{language.video_link_text[shortLang]}</p>
      <p>{language.video_link_subtext[shortLang]}</p>
      <div ref={videoLinksRef} className={styles["video-links"]}>
        {videoLinks.map((element, i) => {
          return (
            <div key={element.key} className={styles["video-link"]}>
              <VfiInputText
                className={styles["url-input"]}
                value={element.url}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const nextValue = e.target.value;
                  setVideoLinks((currentVideoLinks) =>
                    currentVideoLinks.map((linkObj, index) => (index === i ? { ...linkObj, url: nextValue } : linkObj))
                  );
                }}
                placeholder={language.link_placeholder[shortLang]}
              />
              {trashButtonShown && (
                <button
                  className={styles["trash-icon"]}
                  onClick={() => {
                    setVideoLinks((currentVideoLinks) => {
                      if (currentVideoLinks.length > 1) {
                        return currentVideoLinks.filter((linkObj, index) => (index === i ? false : true));
                      } else {
                        return currentVideoLinks.map((videoLink) => ({ ...videoLink, url: "" }));
                      }
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTimes as IconProp} />
                </button>
              )}
            </div>
          );
        })}
        {addUrlActive && (
          <button
            className={`${styles["add-extra-video-link"]}`}
            // disabled={true}
            onClick={() => {
              let maxKey = 0;
              videoLinks.forEach((element) => {
                if (element.key > maxKey) maxKey = element.key;
              });
              setVideoLinks((currentVideoLinks) => [...currentVideoLinks, { url: "", key: maxKey + 1 }]);
            }}
          >
            <div className={styles["add-extra-video-link-wrap"]}>
              <span className={styles["text"]}>{language.video_link_button[shortLang]}</span>
              <FontAwesomeIcon icon={faPlus as IconProp} />
            </div>
          </button>
        )}
      </div>
    </>
  );
}
