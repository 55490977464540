import axios from "axios";
import env from "../../../../../../environment.json";

const api_map: any = {
  event: env.protocol + env.env + "/api/whatson/events/GetEvent",
  // place: env.protocol + env.env + "/api/whatson/places/GetPlace",
  // tour: env.protocol + env.env + "/api/whatson/tours/GetTour",
};

export const getContent = (nav: string, id: number, callback: any) => {
  const type: string = nav.toLowerCase();

  if (api_map?.[type]) {
    axios
      .post(api_map?.[type], { id: id })
      .then((response) => {
        if (
          !(response?.data?.data === undefined || response?.data?.data === null || response?.data?.data.length === 0)
        ) {
          callback(response.data);
        } else {
          callback(undefined);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
};
