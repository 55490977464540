import styles from "./VfiButton.module.scss";

interface Props {
  children: any;
  disabled?: boolean;
  className?: string;
  onClick?: (e: any) => void;
}

export default function VfiButton({ children, className = "", disabled = false, onClick = (e) => {} }: Props) {
  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${className} ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
