import "../Product.scss";

export default function GeneralInfo({ productState }) {
  return (
    <div className="general-information">
      <h3>General information</h3>
      <div className="name">
        <label>Name</label>
        <input
          onChange={(e) => {
            productState.name = e.target.value;
          }}
          defaultValue={productState.name}
        />
      </div>
      <div className="description">
        <label>Description</label>
        <div
          onInput={(e) => {
            productState.description = e.currentTarget.innerHTML;
          }}
          suppressContentEditableWarning={true}
          className="div-input"
          contentEditable={true}
          dangerouslySetInnerHTML={{ __html: productState.description }}
        ></div>
      </div>
    </div>
  );
}
