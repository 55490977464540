import { useSitesStore } from "./sitesStore";
import { useUserStore } from "./userStore";
import { useWhatsonStore } from "./whatsonStore";
import { useArticlesStore } from "./articlesStore";
import { useRouterStore } from "../Router/routerStore";
import React, { ComponentType } from "react";
import { usePermCheckStore } from "../PermCheck/PermCheckStore";
import { overlayStore as useOverlayStore } from "../OverlayElements/OverlayStore";
import { pagesStore as usePagesStore } from "./pages-store/pagesStore";

// const useAllOfStore = create((...a) => ({
//   ...sitesStore(...a),
//   ...userStore(...a),
//   ...whatsonStore(...a),
//   ...articlesStore(...a),
//   ...routerStore(...a),
//   permCheckFunc: (props) => {
//     return permCheckFunc({
//       roles: useAllOfStore.getState().roles,
//       permissions: useAllOfStore.getState().permissionsCheck,
//       ...props,
//     });
//   },
// }));

export const withAllOfStore = (BaseComponent: ComponentType<any>) => {
  const WithAllOfStore = React.forwardRef((props, ref) => {
    const pagesStore = usePagesStore();
    const sitesStore = useSitesStore();
    const userStore = useUserStore();
    const whatsonStore = useWhatsonStore();
    const articlesStore = useArticlesStore();
    const routerStore = useRouterStore();
    const permCheckStore = usePermCheckStore();
    const overlayStore = useOverlayStore();

    const store = {
      pagesStore,
      sitesStore,
      userStore,
      whatsonStore,
      articlesStore,
      routerStore,
      permCheckStore,
      overlayStore,
    };
    return <BaseComponent ref={ref} {...props} store={store} />;
  });

  // This line is important, forwardRef will not work without it
  WithAllOfStore.displayName = `WithAllOfStore(${BaseComponent.name})`;

  return WithAllOfStore;
};
