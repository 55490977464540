import { StateCreator } from "zustand";

export interface ISession {
  sessionId: number;
  timestamp: number;
  MAX_AGE: number;
  userId: number;
  userName: string;
}

/**
 * Description Slice Interface
 */
export interface SessionSlice {
  session: ISession;

  /**
   * setSessionSlice - Full setter
   *
   * @param partials
   *
   * @example
   * setSessionSlice({ en: { headlineTextInput: { value: "headline", error: "", characterCount: 8 }, shortSummaryTextInput: { value: "short summary", error: "", characterCount: 12 }, descriptionTextInput: { value: "description", error: "", characterCount: 10 }, mapSummaryTextInput: { value: "map summary", error: "", characterCount: 11 } }, fo: { headlineTextInput: { value: "headline", error: "", characterCount: 8 }, shortSummaryTextInput: { value: "short summary", error: "", characterCount: 12 }, descriptionTextInput: { value: "description", error: "", characterCount: 10 }, mapSummaryTextInput: { value: "map summary", error: "", characterCount: 11 } })
   *
   * @returns void
   */
  setSessionSlice: (partials: Partial<SessionSlice>) => void;
}

const INITIAL_STATES = () => ({
  session: {
    sessionId: -1,
    timestamp: -1,
    MAX_AGE: 3600,
    userId: -1,
    userName: "John Doe",
  },
});

// Use the correct StateCreator type
export const createSessionSlice: StateCreator<SessionSlice> = (set, _get) => ({
  ...INITIAL_STATES(),

  /**
   * Setters
   */
  setSessionSlice: (partials) => set({ ...partials }),
});
