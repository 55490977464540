import React, { useEffect, useState } from "react";
import styles from "../RedirectsDisplay.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { RedirectTableType, PageRedirectType, RedirectTableRowType } from "../types";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import VfiCheckbox from "src/assets/VfiCheckbox/VfiCheckbox";
import ChangeURLSlugModal from "src/Components/Content/Pages/EditPage/components/ChangeURLSlugModal";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";

export default function RedirectTable({
  redirectPages,
  fetchAllPageRedirects,
  setIsLoading,
  setSelectedCount,
  selectedRedirects,
  setSelectedRedirects,
  inEdit,
  refresh,
}: RedirectTableType): JSX.Element {
  const [confirmDeleteRedirect, setConfirmDeleteRedirect] = useState<Number>(0);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      const selectedIDs = redirectPages.map((page) => page.redirects.map((redirect) => redirect.redirectID)).flat();
      setSelectedRedirects(selectedIDs);
      setSelectedCount(selectedIDs.length);
    } else {
      setSelectedRedirects([]);
      setSelectedCount(0);
    }
  };

  const handleCheckboxChange = (redirectID: Number) => {
    setSelectedRedirects((prevSelected: Number[]) =>
      prevSelected.includes(redirectID) ? prevSelected.filter((id) => id !== redirectID) : [...prevSelected, redirectID]
    );
  };

  useEffect(() => {
    setSelectedCount(selectedRedirects.length);
  }, [selectedRedirects, setSelectedCount]);

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th>Page Title</th>
            <th>Site</th>
            <th>
              {inEdit && (
                <>
                  <VfiCheckbox
                    className={styles.checkbox}
                    checked={selectAll}
                    defaultChecked={false}
                    onChange={handleSelectAllChange}
                    name="select-all-checkbox"
                    disabled={false}
                  >
                    <span></span>
                  </VfiCheckbox>{" "}
                </>
              )}
              Old path
            </th>
            <th>Redirect to path</th>
            {/* <th className="check-select">
              <VfiCheckbox
                className="vfi-checkbox"
                checked={selectAll}
                defaultChecked={false}
                onChange={handleSelectAllChange}
                name="select-all-checkbox"
                disabled={false}
              >
                <span></span>
              </VfiCheckbox>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {redirectPages.map((page) => {
            return (
              <RedirectTableRow
                page={page}
                key={page.pageLanguageID.toString()}
                confirmDeleteRedirect={(redirectID: Number) => {
                  setConfirmDeleteRedirect(redirectID);
                }}
                selectedRedirects={selectedRedirects}
                handleCheckboxChange={handleCheckboxChange}
                fetchAllPageRedirects={fetchAllPageRedirects}
                setIsLoading={setIsLoading}
                inEdit={inEdit}
                refresh={refresh}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

/**
 * Render a redirect table row
 */
function RedirectTableRow({
  page,
  confirmDeleteRedirect,
  fetchAllPageRedirects,
  setIsLoading,
  selectedRedirects,
  handleCheckboxChange,
  inEdit,
  refresh,
}: RedirectTableRowType): JSX.Element {
  const [editing, setEditing] = useState<boolean>(false);
  const [slug, setSlug] = useState<string>(page.slug);
  const [pageLanguageID] = useState<Number>(page.pageLanguageID);
  const [addRedirectSlug, setAddRedirectSlug] = useState<string>("");
  const [addRedirectValidationError, setAddRedirectValidationError] = useState<string>("");

  const { addOverlay } = overlayStore((state) => state);

  return (
    <tr>
      {/* Page Title */}
      <td>{page.title}</td>
      {/* Site name */}
      <td>{page.siteName}</td>
      {/* Old path */}
      <td>
        {page.redirects.length > 0 ? (
          <div className={styles.oldPath}>
            <div className={styles.oldPathEntities}>
              {page.redirects.map((r) => (
                <label key={r.redirectID.toString()}>
                  {inEdit && (
                    <>
                      <VfiCheckbox
                        className={styles.checkbox}
                        checked={selectedRedirects.includes(r.redirectID)}
                        defaultChecked={false}
                        onChange={() => handleCheckboxChange(r.redirectID)}
                        name={`redirect-checkbox-${r.redirectID}`}
                        disabled={false}
                      >
                        <span></span>
                      </VfiCheckbox>{" "}
                    </>
                  )}
                  <span>
                    /{page.shortLang}/{r.slug}
                  </span>
                </label>
              ))}
            </div>
            <FontAwesomeIcon icon={faArrowRight as IconProp} className={styles.arrowIcon} />
          </div>
        ) : (
          <div>No Old Redirect</div>
        )}
      </td>
      {/* Redirect to */}
      <td>
        <div style={{ width: "fit-content" }}>
          /{page.shortLang}/{slug}{" "}
          {inEdit && (
            <button
              className={styles.edit}
              onClick={() => {
                console.log("Adding overlay for editing slug");
                addOverlay("card", (close) => (
                  <ChangeURLSlugModal
                    close={() => {
                      close();
                      refresh();
                    }}
                    slug={slug}
                    setNewSlug={setSlug}
                    pageLanguageID={pageLanguageID}
                    setEditSlug={undefined}
                  />
                ));
              }}
            >
              <FontAwesomeIcon icon={faPencil as IconProp} />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
}
