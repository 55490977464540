import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";
import axios from "axios";
import { browserName, browserVersion } from "react-device-detect";
import env from "../environment.json";
import { modulesConfig } from "src/utils";

const storeNotificationToken = async () => {
  // If notifications are disabled or userId is missing, return early
  if (!modulesConfig.notifications.enabled) return;

  try {
    const token = await getToken(messaging);
    const response = await axios.post(
      `${env.protocol}${env.env}/api/secured/notifications/add_user_push_token`,
      {
        token,
        token_type: "FCM",
        platform: browserName,
        version: browserVersion,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.data.success) {
      throw new Error(response.data.message);
    } else {
      // console.log("Success: " + response.data.message);
    }
  } catch (error) {
    if (error.response) {
      console.error("Error from server:", error.response.data);
    } else if (error.request) {
      console.error("No response was received:", error.request);
    } else {
      console.error("Error setting up the request:", error.message);
    }
  }
};

export { storeNotificationToken };
