/**
 * Get a formatted string from a number
 *
 * e.g. 1100000 to "1.1MB"
 *
 * @param 	{number} 		bytes 							Number of bytes to format
 * @param 	{boolean} 	si 									true: use metric, false: use binary (1024)
 * @param 	{number} 		decimals 						How many decimals to use
 *
 * @author 					Pætur Mortensen
 */
export function get_bytes_string(bytes: number, si: boolean = true, decimals: number = 1): string {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " Bytes";
  }

  // Units to use
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  // Initialize unit (key for units array)
  let u = -1;
  // Divisor to use with decimals
  const r = 10 ** decimals;

  // Do at least once
  do {
    // Divide bytes by threshold and increment units key (each increment moves on up the units)
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  // Return the formatted bytes string
  return bytes.toFixed(decimals) + " " + units[u];
}

/**
 * Get short date format (faroese style)
 *
 * @author 					Pætur Mortensen
 */
export function get_short_date_format(date: string, includeTime: boolean = true): string {
  const dateObj = new Date(date);

  // Build date part strings
  const dayNumber = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear() % 100;

  // Build the date string
  let dateString = `${dayNumber}/${month}-${year}`;

  // If date should include time of day, we add it to the string
  if (includeTime) {
    // Build time of day string and add it to the dateString
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    dateString += ` - ${hours}:${minutes}`;
  }

  return dateString;
}

/**
 * Get a relative time string
 *
 * Returns a relative description between the time passed in parameters and now.
 * examples: "5 minutes ago", "1 year ago", "in 2 days"....
 *
 * Uses simplified and rough time difference, but should be good enough for human usage
 *
 * @author 					Pætur Mortensen
 */
export function get_relative_date_format(UNIX: number): string {
  // Time unit ranges (min number of seconds in given range - rough estimate)
  type TimeRanges = { [key: string]: number };
  const timeRanges: TimeRanges = {
    second: 1,
    minute: 60,
    hour: 60 * 60,
    day: 60 * 60 * 24,
    week: 60 * 60 * 24 * 7,
    month: 60 * 60 * 24 * 30,
    year: 60 * 60 * 24 * 365,
  };

  // Singular and plural names for time units
  type TimeUnitNumbers = { [key: string]: { [key: string]: string } };
  const timeUnitNumbers: TimeUnitNumbers = {
    second: { singular: "second", plural: "seconds" },
    minute: { singular: "minute", plural: "minutes" },
    hour: { singular: "hour", plural: "hours" },
    day: { singular: "day", plural: "days" },
    week: { singular: "week", plural: "weeks" },
    month: { singular: "month", plural: "months" },
    year: { singular: "year", plural: "years" },
  };

  /**
   * Get rough time unit
   *
   * This is quite rough, and does not take into account different month lengths, year length etc,
   * but is a simple but decent approximation
   *
   * @author 					Pætur Mortensen
   */
  function get_time_unit(seconds: number): string {
    if (seconds > timeRanges.year) return "year";
    if (seconds > timeRanges.month) return "month";
    if (seconds > timeRanges.week) return "week";
    if (seconds > timeRanges.day) return "day";
    if (seconds > timeRanges.hour) return "hour";
    if (seconds > timeRanges.minute) return "minute";
    return "second";
  }

  // Get current UNIX time for calculations
  const currentTime = Math.floor(Date.now() / 1000);
  // Get difference between now and date in seconds
  let difference = currentTime - UNIX;
  // Whether this is in the past (true:past, false:future)
  const past = difference > 0;
  // Ensure time difference is positive number
  difference = Math.abs(difference);
  // Get time unit, or range type (seconds, minutes, hours...)
  const timeUnit = get_time_unit(difference);
  // Get the number of time units there are in difference
  const numDifference = Math.floor(difference / timeRanges[timeUnit]);
  // Get correct timeUnit wording (singular or plural)
  const numberKey = numDifference === 1 ? "singular" : "plural";
  const timeUnitName = timeUnitNumbers[timeUnit][numberKey];

  // Build the relative time return string depending on whether it is in the future or past
  const relativeTime = past ? numDifference + " " + timeUnitName + " ago" : "in " + numDifference + " " + timeUnitName;

  return relativeTime;
}
