import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import { faAngleRight, faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import BetweenBranch from "./BetweenBranch";

class TreeBranch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      index: this.props.index,
      extended: false,
    };
  }

  onDragStart = (e) => {
    //e.preventDefault();

    const target = e.target;
    //e.dataTransfer.setData('list_id', target.id);
    this.setState({ originalDisplay: target.style.display });

    setTimeout(() => {
      target.style.display = "none";
    }, 0);

    this.props.startDrag(this.state.index);
  };

  onDragEnter = (e) => {
    if (e.target.getAttribute("index") || e.target.getAttribute("isbetween")) {
      e.target.style = "background-color: lightgray";
    }
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragLeave = (e) => {
    e.target.style = "";
  };

  onDragEnd = (e) => {
    e.target.style.display = this.state.originalDisplay;

    this.props.endDrag(e);
  };

  onDrop = (e) => {
    e.preventDefault();
    e.target.style = "";

    if (e.target.getAttribute("isBetween")) {
      this.props.droppedInBetween(e);
    } else {
      if (e.target.getAttribute("index") !== null) this.props.droppedInElement(e);
    }
  };

  render() {
    let childrenCount = 0;
    this.props.allBranches.forEach((element) => {
      if (element.childOf === this.state.index) childrenCount++;
    });

    const noChildren = childrenCount === 0 ? "no-children " : "";
    const isRoot = this.props.isRoot ? "is-root " : "";
    const selected = this.props.selected === this.state.index ? "selected " : "";

    const sorted = [];
    this.props.allBranches.forEach((element) => {
      if (element.childOf === this.state.index) {
        sorted.push(element);
      }
    });
    sorted.sort((a, b) => {
      return a.order - b.order;
    });

    return (
      <div
        className={"tree-branch " + noChildren + isRoot + selected}
        draggable="true"
        onDragStart={this.onDragStart}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDragEnd={this.onDragEnd}
        onDrop={this.onDrop}
        onClick={() => {
          this.props.onClick(this.state.index);
        }}
      >
        {this.props.firstChild ? (
          <BetweenBranch childOf={this.props.childOfValue} orderValue={1} onDrop={this.onDrop} lastRoot={false} />
        ) : (
          ""
        )}
        <div className="tree-title" index={this.props.index}>
          {childrenCount > 0 ? (
            <div className="arrow-icon" onClick={() => this.setState({ extended: !this.state.extended })}>
              <FontAwesomeIcon icon={this.state.extended ? faAngleDown : faAngleRight} />
            </div>
          ) : (
            ""
          )}
          <FontAwesomeIcon className="file-icon" icon={faFile} />
          <span className="title">{this.state.title}</span>
        </div>

        {this.state.extended
          ? sorted.map((element, i) => {
              return (
                <TreeBranch
                  key={element.name}
                  title={element.name}
                  index={element.index}
                  childOfValue={element.childOf}
                  order={element.order}
                  allBranches={this.props.allBranches}
                  allPages={this.props.allPages}
                  startDrag={this.props.startDrag}
                  droppedInElement={this.props.droppedInElement}
                  droppedInBetween={this.props.droppedInBetween}
                  endDrag={this.props.endDrag}
                  isRoot={false}
                  firstChild={i === 0}
                  onClick={this.props.onClick}
                  selected={this.props.selected}
                />
              );
            })
          : ""}
        <BetweenBranch
          childOf={this.props.childOfValue}
          orderValue={this.props.order + 1}
          onDrop={this.onDrop}
          lastRoot={this.props.isRoot ? (this.props.lastElement ? true : false) : false}
        />
      </div>
    );
  }
}
export default TreeBranch;
