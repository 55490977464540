import styles from "./MediaCenter.module.scss";
import Button from "../Buttons/Button";
import MediaListTable from "./MediaListTable";
import { useEffect, useState } from "react";
import axios from "axios";
import env from "../../environment.json";
import { get_bytes_string, get_short_date_format, get_relative_date_format } from "src/utils/StringHelpers";
import { MediaListItem, ResponseData, CheckedItems, MediaListType, MediaItem } from "./types";
import Modal from "../Modal";
import Preloader from "src/assets/Preloader";
import MediaSearch from "./MediaSearch";
import MediaUploader from "./MediaUploader";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function MediaList({ onInsert }: MediaListType): JSX.Element {
  // Whether we are currently loading media
  const [mediaIsLoading, setMediaIsLoading] = useState<boolean>(false);
  // Media items in the list
  const [mediaItems, setMediaItems] = useState<Array<MediaListItem>>([]);
  // Filtered media (searche, filtered etc, used for display)
  const [filteredMedia, setFilteredMedia] = useState<Array<MediaListItem>>([]);
  // Items that have been checked in checkbox
  const [checkedItems, setCheckedItems] = useState<CheckedItems>([]);
  // Whether user is to confirm deletion of checked items
  const [confirmDeleteChecked, setConfirmDeleteChecked] = useState<boolean>(false);
  // Whether we are in the process of deleting items
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  // Whether user is to confirm insertion of checked items
  const [confirmInsertChecked, setConfirmInsertChecked] = useState<boolean>(false);
  // Whether we are in the process of inserting items
  const [insertIsLoading, setInsertIsLoading] = useState<boolean>(false);
  // Whether we should show the add media section
  const [addMedia, setAddMedia] = useState<boolean>(false);

  // Initial render
  useEffect(() => {
    load_media();
  }, []);

  /**
   * Build the media items and set their state
   *
   * @author 					Pætur Mortensen
   */
  function build_media_items(data: ResponseData): void {
    /**
     * Build and return a searchable string for search filtering
     *
     * @author 					Pætur Mortensen
     */
    function get_searchable_string(mediaItem: MediaListItem): string {
      return (
        mediaItem.author +
        " " +
        mediaItem.description +
        " " +
        mediaItem.fileExtension +
        " " +
        mediaItem.fileName +
        " " +
        mediaItem.locations +
        " " +
        mediaItem.name
      );
    }

    // Build media items for list
    const newMediaItems = [];
    for (const medium of data.media) {
      const mediaItem = {
        fileName: medium.fileName,
        fileExtension: medium.fileExtension,
        author: ((): string => {
          const author = data.authors.find((author) => author.userID === medium.authorID);
          return author?.firstName + " " + author?.lastName || "";
        })(),
        fileSize: get_bytes_string(medium.fileSize),
        fileSizeInt: medium.fileSize,
        locations: ((): string => {
          let locationsString = "";
          for (const idx in medium.locations) {
            const locationID = medium.locations[idx];
            locationsString += ", " + data.sites.find((site) => site.siteID == locationID)?.name;
          }

          return locationsString.substring(2) || "";
        })(),
        date: medium.date,
        dateUNIX: medium.dateUNIX,
        mediaID: medium.mediaID,
        width: medium.width,
        height: medium.height,
        name: medium.name,
        description: medium.description,
        dateShort: get_short_date_format(medium.date),
        dateRelative: get_relative_date_format(medium.dateUNIX),
        searchable: "",
      };

      // Add searchable string to the medium
      mediaItem.searchable = get_searchable_string(mediaItem);

      newMediaItems.push(mediaItem);
    }

    setMediaItems(newMediaItems);
    setFilteredMedia(newMediaItems);
    setMediaIsLoading(false);
  }

  /**
   * Load media from server
   *
   * Load media from the server and build the items list for use in system
   *
   * @author 					Pætur Mortensen
   */
  function load_media(): void {
    setMediaIsLoading(true);

    axios
      .get(env.protocol + env.env + "/api/secured/media/GetMedia")
      .then((response) => {
        build_media_items(response.data);
      })
      .catch((error) => {
        console.error(error);
        setMediaIsLoading(false);
      });
  }

  /**
   * Reload the media.
   *
   * Reload all media items from server and reset some states, like checked items
   *
   * @author 					Pætur Mortensen
   */
  function reload_media(): void {
    load_media();
    setCheckedItems([]);
  }

  /**
   * Delete all the checked items
   *
   * @author 					Pætur Mortensen
   */
  function delete_all_checked_items(): void {
    setDeleteIsLoading(true);
    axios
      .post(env.protocol + env.env + "/api/secured/media/DeleteMediaItems", {
        items: checkedItems,
      })
      .then(() => {
        setDeleteIsLoading(false);
        setConfirmDeleteChecked(false);
        reload_media();
      })
      .catch((error) => {
        console.error(error);
        setDeleteIsLoading(false);
        setConfirmDeleteChecked(false);
      });
  }

  /**
   * Get media item formatted for onInsert
   *
   * @author 					Pætur Mortensen
   */
  function get_insert_media(mediaID: number): MediaItem | null {
    const mediaItem = mediaItems.find((item) => item.mediaID === mediaID);

    // Ensure the media item was found
    if (typeof mediaItem !== "undefined") {
      const insertItem = {
        mediaID,
        fileName: mediaItem.fileName,
        name: mediaItem.name,
        extension: mediaItem.fileExtension,
      };

      // Call onInsert with this media item
      return insertItem;
    } else {
      console.error("could not find the media item with ID: ", mediaID);
      return null;
    }
  }

  /**
   * Insert a media element
   *
   * @author 					Pætur Mortensen
   */
  function insert_media(mediaID: number): void {
    const insertItem = get_insert_media(mediaID);

    if (insertItem !== null) {
      onInsert([insertItem]);
    }
  }

  /**
   * Insert all the checked items
   *
   * @author 					Pætur Mortensen
   */
  function insert_all_checked_items(): void {
    setInsertIsLoading(true);

    const insertItems = [];
    // For each checked media row...
    for (const idx in checkedItems) {
      // Get the media ID
      const mediaID = checkedItems[idx];

      // Get media insert item from mediaID
      const insertItem = get_insert_media(mediaID);

      // Add to insert items if it was found
      if (insertItem !== null) {
        insertItems.push(insertItem);
      }
    }

    // If there are any items to insert, call onInsert with the items
    if (insertItems.length > 0) {
      onInsert(insertItems);
    }

    setInsertIsLoading(false);
    setConfirmInsertChecked(false);
  }

  /**
   * Handle uploaded media
   *
   * Reload media and close the uploader
   *
   * @author 					Pætur Mortensen
   */
  function handle_media_upload(): void {
    reload_media();
    setAddMedia(false);
  }

  /***************************************************************************************************
   *
   * 																			RENDER
   *
   **************************************************************************************************/

  /**
   * Render the buttons with actions for checked items
   *
   * @author 					Pætur Mortensen
   */
  function render_checked_cp_section(): JSX.Element {
    return (
      <div className={styles.checkedCPSection}>
        With checked items:
        <Button
          size="small"
          onClick={() => {
            setConfirmDeleteChecked(true);
          }}
        >
          Delete
        </Button>
        <Button
          type="black"
          size="small"
          onClick={() => {
            setConfirmInsertChecked(true);
          }}
        >
          Insert
        </Button>
      </div>
    );
  }

  /**
   * Render the media uploader
   *
   * @author 					Pætur Mortensen
   */
  function render_media_upload(): JSX.Element {
    return (
      <div className={styles.uploadSection}>
        <div className={styles.uploadTopCP}>
          <FontAwesomeIcon
            icon={faClose as IconProp}
            className={styles.closeUploadBtn}
            onClick={() => {
              setAddMedia(false);
            }}
          />
        </div>
        <MediaUploader onUpload={handle_media_upload} />
      </div>
    );
  }

  return (
    <div className={styles.mediaContainer}>
      <div className={styles.header}>
        <h2 className={styles.headline}>Media</h2>
        <Button
          onClick={() => {
            setAddMedia(true);
          }}
        >
          Add media
        </Button>
      </div>
      {addMedia && render_media_upload()}
      <div className={styles.controlPanel}>
        <MediaSearch allMedia={mediaItems} setFilteredMedia={setFilteredMedia} />
        <div className={styles.cpButtons}>{checkedItems.length > 0 && render_checked_cp_section()}</div>
      </div>

      <div className={styles.list}>
        {mediaIsLoading ? (
          <Preloader show />
        ) : (
          <MediaListTable
            media={filteredMedia}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            reload_media={reload_media}
            insert_media={insert_media}
          />
        )}
      </div>
      {confirmDeleteChecked && (
        <Modal
          title="Delete all checked items"
          close={() => {
            setConfirmDeleteChecked(false);
          }}
          type="confirmation"
          onCancel={() => {
            setConfirmDeleteChecked(false);
          }}
          onConfirm={delete_all_checked_items}
          cancelButtonText="No"
          confirmButtonText="Yes"
        >
          Do you want to delete all checked items?
          <br />
          Items to delete: <b>{checkedItems.length}</b>
          <br />
          <Preloader show={deleteIsLoading} />
        </Modal>
      )}
      {confirmInsertChecked && (
        <Modal
          title="Insert all checked items"
          close={() => {
            setConfirmInsertChecked(false);
          }}
          type="confirmation"
          onCancel={() => {
            setConfirmInsertChecked(false);
          }}
          onConfirm={insert_all_checked_items}
          cancelButtonText="No"
          confirmButtonText="Yes"
        >
          Do you want to insert all checked items?
          <br />
          Items to insert: <b>{checkedItems.length}</b>
          <br />
          <Preloader show={insertIsLoading} />
        </Modal>
      )}
    </div>
  );
}
