import { StateCreator } from "zustand";
import { TLangAsKeys, TLanguages } from "../../Steps";
import { ValidateDescription } from "./utils";
import { get } from "lodash";
import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";

/**
 * Description Languages
 */
const DESCRIPTION_LANGUAGES: TLanguages[] = ["en", "fo"];

/**
 * Text Input State Interface
 */
export interface TextInputState {
  value: string;
  error?: string;
  characterCount: number;
}
/**
 * Description State Interface
 */
export interface DescriptionState {
  headlineTextInput: TextInputState;
  shortSummaryTextInput: TextInputState;
  descriptionTextInput: TextInputState;
  mapSummaryTextInput: TextInputState;
}

export type DescriptionStateKeys = keyof DescriptionState;
/**
 * Description Slice Interface
 */
export interface DescriptionSlice {
  enabled: Record<TLanguages, boolean>;
  description: Record<TLanguages, DescriptionState>;
  // description: TLangAsKeys<DescriptionState>;
  descritpion_valid: boolean;

  /**
   * setDescriptionSlice - Full setter
   *
   * @param partials
   *
   * @example
   * setDescriptionSlice({ en: { headlineTextInput: { value: "headline", error: "", characterCount: 8 }, shortSummaryTextInput: { value: "short summary", error: "", characterCount: 12 }, descriptionTextInput: { value: "description", error: "", characterCount: 10 }, mapSummaryTextInput: { value: "map summary", error: "", characterCount: 11 } }, fo: { headlineTextInput: { value: "headline", error: "", characterCount: 8 }, shortSummaryTextInput: { value: "short summary", error: "", characterCount: 12 }, descriptionTextInput: { value: "description", error: "", characterCount: 10 }, mapSummaryTextInput: { value: "map summary", error: "", characterCount: 11 } })
   *
   * @returns void
   */
  setDescriptionSlice: (partials: Partial<DescriptionSlice>) => void;
  /**
   * updateDescription
   *
   * Updates the description
   *
   * @param language
   * @param key
   * @param value
   *
   * @returns void
   */
  updateDescription: (language: TLanguages, key: keyof DescriptionState, value: Partial<TextInputState>) => void;
  /**
   * setEnabled
   *
   * Sets the enabled status
   *
   * @param language
   * @param isEnabled
   *
   * @returns void
   */
  setEnabled: (language: TLanguages, isEnabled: boolean) => void;
  /**
   * validateDescription
   *
   * Validates the description
   *
   * @returns boolean
   */
  validateDescription: () => boolean;
  /**
   * Reset description slice
   */
  resetDescriptionSlice: () => void;
}

const INITIAL_STATES = () => ({
  enabled: {
    en: true,
    fo: true,
  },
  description: {
    ...(Object.fromEntries(
      DESCRIPTION_LANGUAGES.map((language) => [
        language,
        {
          headlineTextInput: { value: "", error: "", characterCount: 0 },
          shortSummaryTextInput: { value: "", error: "", characterCount: 0 },
          descriptionTextInput: { value: "", error: "", characterCount: 0 },
          mapSummaryTextInput: { value: "", error: "", characterCount: 0 },
        },
      ])
    ) as Record<TLanguages, DescriptionState>),
  },
  descritpion_valid: false,
});

// Use the correct StateCreator type
export const createDescriptionSlice: StateCreator<DescriptionSlice> = (set, get) => ({
  ...INITIAL_STATES(),

  /**
   * Setters
   */

  setDescriptionSlice: (partials) => set({ ...partials }),

  updateDescription: (language, key, partialValue) =>
    set((state: DescriptionSlice) => {
      // Create a new TextInputState with updated value and character count
      const updatedTextInput: TextInputState = {
        ...state.description[language][key],
        ...partialValue,
        // Ensure characterCount is correctly updated based on the new value if it's provided
        characterCount: partialValue.value
          ? partialValue.value.length
          : state.description[language][key].characterCount,
      };

      return {
        description: {
          ...state.description,
          [language]: {
            ...state.description[language],
            [key]: updatedTextInput,
          },
        },
      };
    }),
  setEnabled: (language, isEnabled) =>
    set((state: DescriptionSlice) => {
      return {
        enabled: {
          ...state.enabled,
          [language]: isEnabled,
        },
      };
    }),
  validateDescription: () => {
    const state = get();
    const whatson_store = useWhatsonManager.getState().getStore();
    const { valid, description } = ValidateDescription(state.description, state.enabled);
    const { setError } = whatson_store.getState();
    setError("Description", valid);
    set(() => {
      return {
        description: { ...description },
        descritpion_valid: valid,
      };
    });
    return valid;
  },
  resetDescriptionSlice: () => {
    set(() => ({
      ...INITIAL_STATES(),
      description: {
        ...(Object.fromEntries(
          DESCRIPTION_LANGUAGES.map((language) => [
            language,
            {
              headlineTextInput: { value: "", error: "", characterCount: 0 },
              shortSummaryTextInput: { value: "", error: "", characterCount: 0 },
              descriptionTextInput: { value: "", error: "", characterCount: 0 },
              mapSummaryTextInput: { value: "", error: "", characterCount: 0 },
            },
          ])
        ) as Record<TLanguages, DescriptionState>),
      },
    }));
  },
});
