//All sliders, including grids are in this EditModule

import { useEffect, useState } from "react";
import "../VfiCarousel/EditModule.scss";
import "./EditModule.scss";
import Select from "react-select";

export default function EditModule(props) {
  const { values, change } = props;
  const [innerValues, setInnerValues] = useState(values);
  const [screen, setScreen] = useState("media");

  const setValues = (v) => {
    change(v);
    setInnerValues(v);
  };

  useEffect(() => {
    setInnerValues(values);
  }, [values]);

  function setDownloadAmountOptions(type) {
    let amount;
    if (type === "grid") {
      amount = 4;
    }
    if (type === "list") {
      amount = 3;
    }
    return downloadAmount(amount);
  }

  function downloadAmount(amount) {
    let downloadAmountArray = [];
    for (let index = 1; index <= amount; index++) {
      const obj = { label: `${index}`, value: index };
      downloadAmountArray.push(obj);
    }
    return downloadAmountArray;
  }

  let content = <p>Shouldn't be shown..</p>;
  switch (screen) {
    case "media":
      const downloadTypeArr = [
        { label: "Grid", value: "grid" },
        { label: "List", value: "list" },
      ];

      let downloadType = innerValues.download_type;
      let downloadAmountOptions = setDownloadAmountOptions(downloadType);
      content = (
        <div className="media">
          <div className="option">
            <div className="label">Download amount</div>
            <div className="value">
              <Select
                options={downloadAmountOptions}
                value={downloadAmountOptions.find((e) => e.value === parseInt(innerValues.shown_amount))}
                onChange={(e) => {
                  setValues({ ...innerValues, shown_amount: e.value });
                }}
              />
            </div>
          </div>
          <div className="option">
            <div className="label">Download type</div>
            <div className="value">
              <Select
                options={downloadTypeArr}
                value={downloadTypeArr.find((e) => e.value === innerValues.download_type)}
                onChange={(e) => {
                  setValues({ ...innerValues, download_type: e.value });
                }}
              />
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="download-module edit-module">
      <div className="top">
        <div className={"top-choice " + (screen === "media" ? "active " : "")} onClick={() => setScreen("media")}>
          OPTIONS
        </div>
        {/* <div
          className={"top-choice " + (screen === "design" ? "active " : "")}
          onClick={() => setScreen("design")}
        >
          DESIGN
        </div> */}
      </div>
      <div className="content">{content}</div>
    </div>
  );
}
