import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import env from "../../../../../environment.json";
import "./editHeader.scss";
import vfiLogo from "../../../../../assets/images/vfi-logo.png";
import vitaLogo from "../../../../../assets/images/vita-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Draggable from "react-draggable";
import {
  faArrowRight,
  faEyeSlash,
  faPalette,
  faPencil,
  faPlus,
  faTimes,
  faUndo,
  faArrowsAlt,
  faArrowLeft,
  faClone,
} from "@fortawesome/pro-light-svg-icons";
import { Carousel } from "react-bootstrap";
import OptionsList from "../../../../../assets/OptionsList/OptionsList";
import MenuTemplates from "../../../../../assets/Menu/MenuTemplates/MenuTemplates";
import PaletteTemplates from "../../../../../assets/Menu/MenuTemplates/PaletteTemplates";
import SliderOptions from "../../../../../assets/Menu/MenuTemplates/SliderOptions";
import Currency from "../../../../../assets/Menu/MenuTemplates/Currency";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import cloneDeep from "lodash.clonedeep";
import VfiCKeditor from "../../../../../assets/VfiCKeditor/VfiCKeditor";
import { uniqueId } from "lodash";
import StaticPalette from "./StaticPalette";
import { staticPaletteObj } from "./StaticPalette/StaticPalette";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";

var workingTemplate = [];
var workingTitle = [];
var logoTitle = "";
var keyRender = new Date().valueOf();
export default function EditHeader(props) {
  const tagRef = useRef({ offsetWidth: 0, offsetHeight: 0 });
  logoTitle = props.siteSelected.name;
  var [subPages, setSubPages] = useState([]);

  const [moduleDrag, setModuleDrag] = useState(false);

  const [allPages, setAllPages] = useState([]);
  const [subPageId, setSubPageId] = useState(false);
  const [subPageShared, setSubPageShared] = useState(false);
  const [resetPageId, setPageId] = useState(false);

  const [pageHierarchy, setPageHierarchy] = useState([]);
  const [showHiddenPages, setShowHiddenPages] = useState(false);
  const [showHiddenInTitle, setShowHiddenInTitle] = useState(false);
  const [fakeState, setFakeState] = useState(false);

  const [template, setTemplate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({
    type: 0,
    revers: false,
  });

  const [, setDragOver] = useState(-1);

  const [listIsOpen, setListIsOpen] = useState(false);
  const [optionsListOptions, setOptionsListOptions] = useState([]);
  const [optionsListPos, setOptionsListPos] = useState({});
  const [optionsMenuPos, setOptionsMenuPos] = useState();
  const [paletteIsOpen, setPaletteIsOpen] = useState(false);
  const [optionsPaletteOptions, setOptionsPaletteOptions] = useState([]);
  const [optionsPalettePos, setOptionsPalettePos] = useState({});
  const [optionsMenuPalettePos, setOptionsMenuPalettePos] = useState();
  const [palette, setPalette] = useState({ type: undefined, id: undefined });
  const [sliderOptionsIsOpen, setSliderOptionsIsOpen] = useState(false);
  const [currencyOptionsIsOpen, setCurrencyOptionsIsOpen] = useState(false);
  const [staticPaletteOptionsIsOpen, setStaticPaletteOptionsIsOpen] = useState(false);
  const [weatherOption, setWeatherOptions] = useState({});
  const [currencyOptions, setCurrencyOptions] = useState({});
  const [sliderOptions, setSliderOptions] = useState({
    splide: { split: false, length: [0] },
    menu_module: [],
  });
  const [optionsCurrencyOptionsPos, setOptionsCurrencyOptionsPos] = useState({});
  const [optionsStaticPaletteOptionsPos, setOptionsStaticPaletteOptionsPos] = useState({});
  const [optionsSliderOptionsPos, setOptionsSliderOptionsPos] = useState({});
  const [optionsCurrencyOptions, setOptionsCurrencyOptions] = useState([]);
  const [optionsStaticPaletteOptions, setOptionsStaticPaletteOptions] = useState([]);
  const [optionsSliderOptions, setOptionsSliderOptions] = useState([]);
  const [optionsMenuCurrencyOptionsPos, setOptionsMenuCurrencyOptionsPos] = useState();
  const [optionsMenuStaticPaletteOptionsPos, setOptionsMenuStaticPaletteOptionsPos] = useState();
  const [optionsMenuSliderOptionsPos, setOptionsMenuSliderOptionsPos] = useState();

  const [editMode, setEditMode] = useState(false);

  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [lang, setSelectedLanguage] = useState({
    label: "EN",
    value: "en",
    id: 1,
  });

  const slider = useCallback(
    (elm) => {
      return (
        <SliderOptions
          selectedOptions={elm}
          options={sliderOptions}
          weatherOption={weatherOption}
          setWeatherOptions={setWeatherOptions}
          setSliderOptions={setSliderOptions}
          setFakeState={setFakeState((f) => (f ? false : true))}
          close={() => {
            setSliderOptionsIsOpen(false);
          }}
          deleteMenuAttribute={deleteMenuAttribute}
          langId={lang.id}
        />
      );
    },
    [sliderOptions, lang.id]
  );
  const currency = useCallback(
    (elm) => {
      return (
        <Currency
          selectedOptions={elm}
          currencyOptions={currencyOptions}
          setCurrencyOptions={setCurrencyOptions}
          setFakeState={setFakeState((f) => (f ? false : true))}
          close={() => {
            setCurrencyOptionsIsOpen(false);
          }}
          langId={lang.id}
        />
      );
    },
    [sliderOptions, lang.id]
  );

  useEffect(() => {
    workingTemplate = workingTemplate.map((element) => {
      element.map((elm) => {
        if ((sliderOptions.id && elm.id === sliderOptions.id) || (elm.tempId && elm.tempId === sliderOptions.tempId)) {
          elm.menu_module = sliderOptions.menu_module;
          elm.splide = sliderOptions.splide;
        }
        return elm;
      });
      return element;
    });

    setOptionsSlider(slider(sliderOptions));
    setFakeState((f) => (f ? false : true));
  }, [sliderOptions, slider]);
  useEffect(() => {
    workingTemplate = workingTemplate.map((element) => {
      element.map((elm) => {
        if (elm.id === palette.id) {
          elm.palette = palette.type;
        }
        return elm;
      });
      return element;
    });
    keyRender = new Date().valueOf();
    setFakeState((f) => (f ? false : true));
  }, [palette]);
  useEffect(() => {
    axios
      .get(env.protocol + env.env + "/api/public/pages/GetAllAvailableLanguages.php")
      .then((response) => {
        setAvailableLanguages(
          [...response.data]
            .sort((a, b) => {
              return a.order_by > b.order_by;
            })
            .map((e) => {
              return { label: e.short.toUpperCase(), value: e.short };
            })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (selectedTemplate.type === 0) {
      setTemplate(false);
      return;
    }
    var newTemplate = [];
    if (selectedTemplate.type === 1) {
      newTemplate = [[1], [1, 2], [1, 2], [1, 2], [1]];
    }
    if (selectedTemplate.type === 2 && !selectedTemplate.reverse) {
      newTemplate = [[1, 2], [1], [1, 2], [1]];
    }
    if (selectedTemplate.type === 2 && selectedTemplate.reverse) {
      newTemplate = [[1], [1, 2], [1], [1, 2]];
    }
    if (selectedTemplate.type === 3 && !selectedTemplate.reverse) {
      newTemplate = [[1, 2], [1, 2], [1], [1]];
    }
    if (selectedTemplate.type === 3 && selectedTemplate.reverse) {
      newTemplate = [[1], [1], [1, 2], [1, 2]];
    }
    var listWorkingTemplate = [];
    workingTemplate.forEach((element) => {
      if (element.length > 1) {
        element.forEach((elm) => {
          listWorkingTemplate.push([elm]);
        });
      } else {
        listWorkingTemplate.push(element);
      }
    });

    newTemplate = newTemplate.map((element, i) => {
      if (element.length > 1) {
        element = element.map((elm, j) => {
          elm = listWorkingTemplate[0]
            ? listWorkingTemplate[0][0]
            : {
                pl_title: "",
                children: [],
                current_status: "published",
                new: true,
              };
          listWorkingTemplate.splice(0, 1);
          return elm;
        });
      } else {
        element = listWorkingTemplate[0] ?? [
          {
            pl_title: "",
            children: [],
            current_status: "published",
            new: true,
          },
        ];
        listWorkingTemplate.splice(0, 1);
      }

      return element;
    });

    workingTemplate = newTemplate;
    setFakeState((f) => (f ? false : true));
  }, [selectedTemplate]);
  useEffect(() => {
    if (!template) {
      setFakeState((f) => (f ? false : true));
    }
  }, [template]);
  useEffect(() => {
    setOptionsMenuPalettePos({ x: 0, y: 0 });
  }, []);

  useEffect(() => {
    axios
      .get(
        env.protocol +
          env.env +
          "/api/public/pages/GetAllPagesInSite.php?site=" +
          (props.siteSelected.actual_site_id ?? props.siteSelected.siteId) +
          "&language=" +
          lang.value +
          "&list&users&all" +
          (props.siteSelected.rootPage ? `&rootPage=${props.siteSelected.id}` : "")
      )
      .then((response) => {
        var allPages = response.data.allPages.filter((x) => {
          var filter = true;
          if (x.page_name === "index") {
            filter = false;
          }
          if (subPageId === x.id) {
            filter = false;
          }
          subPages.forEach((e) => {
            if (e.id === x.id) {
              filter = false;
            }
          });
          return filter;
        });

        setAllPages(allPages);
      });
  }, [showHiddenPages, props.siteSelected.siteId, subPages, lang]);

  useEffect(() => {
    axios
      .get(
        env.protocol +
          env.env +
          "/api/public/pages/GetAllPagesInSite.php?site=" +
          (props.siteSelected.actual_site_id ?? props.siteSelected.siteId) +
          "&all&deleted&language=" +
          lang.value +
          (props.siteSelected.rootPage ? `&rootPage=${props.siteSelected.id}` : "")
      )
      .then((response) => {
        workingTitle = response.data.hierarchy.children[0].children;
        setPageHierarchy(response.data.hierarchy.children);
      });
  }, [props.siteSelected.siteId, resetPageId, lang]);

  useEffect(() => {
    if (subPageId) {
      axios
        .get(
          env.protocol +
            env.env +
            "/api/public/pages/GetAllPagesInSite.php?site=" +
            (props.siteSelected.actual_site_id ?? props.siteSelected.siteId) +
            "&all&language=" +
            lang.value +
            (props.siteSelected.rootPage ? `&rootPage=${props.siteSelected.id}` : "")
        )
        .then((response) => {
          const find = pageChild(response.data.hierarchy.children[0].children, "id", subPageId);

          workingTemplate = [];
          find.children.forEach((element, i) => {
            if (pageChild(workingTemplate, "id", element.id)) {
              // workingTemplate.push([
              //   { pl_title: "", children: [], current_status: "published" },
              // ]);
            } else {
              let secondLevel = element.template_indexing[0];

              if (element.template_indexing === "") {
                secondLevel = i;
              }

              let arrayLenght = workingTemplate.length;

              for (let i = 0; i <= secondLevel - arrayLenght; i++) {
                workingTemplate.push([]);
              }

              workingTemplate[secondLevel].push(element);
              workingTemplate[secondLevel].sort(compare);
            }
          });
          workingTemplate = workingTemplate.filter((x) => x.length > 0);
          if (workingTemplate.length === 0) {
            workingTemplate.push([
              {
                pl_title: "",
                children: [],
                feature_media: "",
                current_status: "published",
                new: true,
              },
            ]);
          }

          setSubPages(find ? find.children : []);
        });
    }
  }, [subPageId, props.siteSelected.siteId, resetPageId, lang]);
  const compare = (a, b) => {
    if (a.template_indexing < b.template_indexing) {
      return -1;
    }
    if (a.template_indexing > b.template_indexing) {
      return 1;
    }
    return 0;
  };
  const setOptionsList = (options, pos = undefined, isCustom = false) => {
    setListIsOpen(true);
    setOptionsListOptions(options);
    if (pos !== undefined) setOptionsListPos(pos);
  };
  const setOptionsPalette = (options, pos = undefined, isCustom = false) => {
    setPaletteIsOpen(true);
    setOptionsPaletteOptions(options);

    if (pos !== undefined) setOptionsPalettePos(pos);
  };
  const setOptionsSlider = (options, pos = undefined, isCustom = false) => {
    setOptionsSliderOptions(options);
    if (pos !== undefined) {
      setSliderOptionsIsOpen(true);
      setOptionsSliderOptionsPos(pos);
    }
  };
  const setOptionsCurrency = (options, pos = undefined, isCustom = false) => {
    setOptionsCurrencyOptions(options);
    if (pos !== undefined) {
      setCurrencyOptionsIsOpen(true);
      setOptionsCurrencyOptionsPos(pos);
    }
  };
  const setOptionsStaticPalette = (options, pos = undefined, isCustom = false) => {
    setOptionsStaticPaletteOptions(options);
    if (pos !== undefined) {
      setStaticPaletteOptionsIsOpen(true);
      setOptionsStaticPaletteOptionsPos(pos);
    }
  };
  const clickPosition = (e, offset = { x: 0, y: 0 }) => {
    return {
      x: e.pageX + (offset.x ? parseInt(offset.x) : 0),
      y: e.pageY + (offset.y ? parseInt(offset.y) : 0),
    };
  };
  const filterWorking = (pageId, parentId, id, page) => {
    workingTitle = workingTitle.filter((x) => x.id !== pageId);

    workingTemplate = workingTemplate.map((element) => {
      element = element
        .filter((x) => {
          x.children = x.children.filter((x) => x.id !== pageId);
          return x.id !== pageId;
        })
        .map((elm) => {
          elm = elm.length > 0 ? elm[0] : elm;
          if (parentId) {
            if (elm.id === JSON.parse(parentId)) {
              element[0]["new"] = true;
              elm.children = elm.children.filter((x) => x.id !== pageId);
              var findIndex = elm.children.findIndex((x) => x.id === JSON.parse(id)) + 1;
              elm.children = insert(elm.children, findIndex, page);
            }
          } else {
            elm.children = elm.children.filter((x) => {
              return x.id !== pageId;
            });
          }
          return elm;
        });

      return element;
    });
  };
  const pageChild = (entireObj, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind && nestedValue.site_id) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };
  const modules = (moduleType) => {
    switch (moduleType) {
      case "weather":
        return '<table><tbody><tr><td><h4 class="paragraph_libre" style="text-align:center;"><i>Weather</i></h4></td></tr></tbody></table><table><tbody><tr><td  ><h1 style="text-align:right;">🌧️</h1></td><td><h2 class="h2_libre">8°C</h2><p>3-5 m/s</p></td></tr><tr><td colspan="2"><p style="text-align:center;">Today in city</p></td></tr></tbody></table>';
      case "blank":
        return "";
      case "number_and_services":
        return '<table><tbody><tr><td><h4 class="paragraph_libre">Useful numbers &amp; services</h4></td></tr></tbody></table><table><tbody><tr><td><h2 class="h2_libre">+298</h2><p><span style="color:#A2A2A2;">Country Code</span></p></td><td  ><h2 class="h2_libre">112</h2><p><span style="color:#A2A2A2;">Emergency services</span></p></td></tr><tr><td  ><h2 class="h2_libre">1870</h2><p><span style="color:#A2A2A2;">Medical assistance</span></p></td><td  ><h2 class="h2_libre">118</h2><p><span style="color:#A2A2A2;">Number infromation</span></p></td></tr></tbody></table>';
      case "good_to_know":
        return '<table><tbody><tr><td  ><h3>GOOD TO KNOW</h3></td></tr></tbody></table><table><tbody><tr><td><h4 class="paragraph_libre"><br data-cke-filler="true"></h4><h4 class="paragraph_libre"><i>Faroese</i></h4><p><br data-cke-filler="true"></p></td></tr><tr><td  ><h2 class="h2_libre">Góðan dag</h2><p><span style="color:#A2A2A2;">Good day</span></p></td></tr></tbody></table>';
      case "currency":
        return '<table><tbody><tr><td ><h4 class="paragraph_libre"><i>Currency</i></h4><p><i><br data-cke-filler="true"></i></p></td></tr></tbody></table><table><tbody><tr><td><h2 class="h2_libre">DKK 100</h2><p><span style="color:#A2A2A2;">Faroese króna</span></p></td></tr><tr><td  ><h2 class="h2_libre">€13.4</h2><p><span style="color:#A2A2A2;">Euro</span></p></td></tr></tbody></table>';
      case "static_palette":
        return staticPaletteObj();
      default:
        break;
    }
  };
  const onDragStartModule = (e, moduleType, id, tempId = -1) => {
    setModuleDrag(true);
    e.dataTransfer.setData("module", moduleType);
    e.dataTransfer.setData("id", id);
    e.dataTransfer.setData("tempId", tempId);
  };
  const onDragStart = (e, page, i) => {
    e.dataTransfer.setData("pageId", page);
    e.dataTransfer.setData("index", i);
  };
  const dropTitle = (e, i) => {
    if (e.target.classList.contains("first-title")) {
      e.target.style.transform = "scale(1)";
      e.target.style.padding = "0";
      e.target.style.backgroundColor = "unset";
    }
    const id = e.dataTransfer.getData("pageId");
    if (e.target.id === id) {
      return;
    }
    let page;
    let newPageHierarchy = cloneDeep(pageHierarchy);
    newPageHierarchy.forEach((e) => {
      page = pageChild(e, "id", JSON.parse(id));
    });
    filterWorking(page.id);
    if (workingTitle.some((x) => x.id !== page.id)) i++;

    workingTitle = insert(workingTitle, i, page);

    setFakeState(fakeState ? false : true);
  };
  const dropSub = (e) => {
    const parentid = e.target.getAttribute("parentid");
    const pageId = e.dataTransfer.getData("pageId");
    const id = e.target.getAttribute("id");
    if (id === pageId) {
      return;
    }
    let page;
    let newPageHierarchy = cloneDeep(pageHierarchy);
    newPageHierarchy.forEach((elm) => {
      page = pageChild(elm, "id", JSON.parse(e.dataTransfer.getData("pageId")));
    });

    filterWorking(page.id, parentid, id, page);
    var findIndex = allPages.findIndex((x) => x.id === page.id);
    allPages.splice(findIndex, 1);
    e.target.style.backgroundColor = "unset";

    keyRender = new Date().valueOf();
    setAllPages(allPages);

    setFakeState(fakeState ? false : true);
  };

  const dropNewModule = (e, i, j) => {
    setModuleDrag(false);
    const moduleType = e.dataTransfer.getData("module");
    const id = e.dataTransfer.getData("id");
    const tempId = e.dataTransfer.getData("tempId");

    if (id !== "undefined") {
      let page;
      let newPageHierarchy = cloneDeep(pageHierarchy);
      newPageHierarchy.forEach((elm) => {
        page = pageChild([elm], "id", JSON.parse(id));
      });
      workingTemplate = workingTemplate.map((elm) => {
        elm = elm.filter((x) => x.id !== JSON.parse(id));
        return elm;
      });
      if (j !== -1) {
        workingTemplate[i][j] = page;
        workingTemplate[i][j].new = false;
      } else {
        workingTemplate = insert(workingTemplate, i, [page]);
      }
      keyRender = new Date().valueOf();
      setFakeState(fakeState ? false : true);
      return;
    }
    if (tempId !== "-1") {
      let page;
      workingTemplate.forEach((elm) => {
        page = elm.find((x) => x.tempId === JSON.parse(tempId)) ?? page;
      });

      workingTemplate = workingTemplate.map((elm) => {
        elm = elm.filter((x) => x.tempId !== JSON.parse(tempId));
        return elm;
      });
      if (j !== -1) {
        workingTemplate[i][j] = page;
        workingTemplate[i][j].new = false;
      } else {
        workingTemplate = insert(workingTemplate, i, [page]);
      }
      keyRender = new Date().valueOf();
      setFakeState(fakeState ? false : true);
      return;
    }
    let menuAttribute = {
      a_label: moduleType,
      a_value: "",
      language_id: lang.id,
      position: 0,
      slider_index: { moduleIndex: 0, splideIndex: 0 },
    };

    let moduleObject = {
      children: [],
      feature_media: "",
      current_status: "published",
      new: true,
      menu_module: [],
      splide: { split: false, length: [0] },
      tempId: JSON.parse(uniqueId()),
      moduleType,
      type: "module",
    };

    menuAttribute.a_value = modules(moduleType);
    moduleObject.menu_module.push(menuAttribute);

    if (j !== -1) {
      if (workingTemplate[i][j].new) {
        workingTemplate[i][j] = moduleObject;
        workingTemplate[i][j].new = false;
      } else {
        workingTemplate[i][j].menu_module.push(moduleObject.menu_module[0]);
      }
    } else {
      workingTemplate = insert(workingTemplate, i, [moduleObject]);
    }

    keyRender = new Date().valueOf();
    setFakeState(fakeState ? false : true);
  };

  const dropModule = (e, i, j, el, z) => {
    if (el === undefined) {
      return;
    }
    setModuleDrag(false);

    const moduleType = e.dataTransfer.getData("module");

    let menuAttribute = cloneDeep(el);

    menuAttribute.a_label = moduleType;
    menuAttribute.a_value = modules(moduleType);

    let findIndex = workingTemplate[i][j].menu_module.findIndex((x) => x.valueOf === el.valueOf);

    if (j !== -1) {
      if (workingTemplate[i][j].menu_module[findIndex].new) {
        workingTemplate[i][j].menu_module[findIndex] = menuAttribute;
        workingTemplate[i][j].menu_module[findIndex].new = false;
      } else {
        workingTemplate[i][j].menu_module.push(menuAttribute);
      }
    } else {
      workingTemplate = insert(workingTemplate, i, [menuAttribute]);
    }
    keyRender = new Date().valueOf();
    setFakeState(fakeState ? false : true);
  };

  const drop = (e, i, j) => {
    if (e.target.classList.contains("dropSub")) {
      return;
    }
    const pageId = e.dataTransfer.getData("pageId");
    const id = e.target.getAttribute("id");

    if (e.target.classList.contains("title")) {
      e.target.style.backgroundColor = "unset";
    }
    if (id === pageId) {
      return;
    }
    let page;
    let newPageHierarchy = cloneDeep(pageHierarchy);
    newPageHierarchy.forEach((elm) => {
      page = pageChild([elm], "id", JSON.parse(pageId));
    });
    page["new"] = true;
    var findIndex = allPages.findIndex((x) => x.id === page.id);

    if (findIndex !== -1) {
      allPages.splice(findIndex, 1);
    }

    setAllPages(allPages);
    if (e.target.classList.contains("empty-td")) {
      e.target.style.width = "5px";
    }
    if (e.target.classList.contains("second-level")) {
      e.target.style.backgroundColor = "rgba(162, 162, 162, 0.1)";
    } else {
      e.target.style.backgroundColor = "unset";
    }
    keyRender = new Date().valueOf();

    filterWorking(page.id);

    if (j === -1) {
      workingTemplate = insert(workingTemplate, i, [page]);
    } else {
      workingTemplate[i][j] = page;
    }

    setFakeState(fakeState ? false : true);
    // }
  };

  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  const allowDrop = (e, allow, dropOn) => {
    if (moduleDrag && !allow) {
      return false;
    }
    if (e.target.classList.contains("empty-td")) {
      e.target.style.width = "30px";
    }
    if (e.target.classList.contains("first-title")) {
      e.target.style.transform = "scale(1.4)";
      e.target.style.padding = "0 10px";
    }

    e.target.style.backgroundColor = "rgba(162, 162, 162, 0.6)";

    e.preventDefault();
  };
  const dragLeave = (e) => {
    if (e.target.classList.contains("empty-td")) {
      e.target.style.width = "5px";
    }
    if (e.target.classList.contains("first-title")) {
      e.target.style.transform = "scale(1)";
      e.target.style.padding = "0";
    }
    if (e.target.classList.contains("second-level")) {
      e.target.style.backgroundColor = "rgba(162, 162, 162, 0.1)";
    } else {
      e.target.style.backgroundColor = "unset";
    }
  };

  const mt = useCallback(() => {
    return (
      <MenuTemplates
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        setDragOver={setDragOver}
        onDragStart={onDragStart}
        onDragStartModule={onDragStartModule}
        showHiddenPages={showHiddenPages}
        pages={allPages}
        close={() => setListIsOpen(false)}
      />
    );
  }, [selectedTemplate, showHiddenPages, allPages]);

  const pt = useCallback((elm) => {
    return <PaletteTemplates selectedPalettePage={elm} setPalette={setPalette} close={() => setPaletteIsOpen(false)} />;
  }, []);

  useEffect(() => {
    setOptionsListOptions(mt);
  }, [allPages, mt]);

  const defaultModuleOptions = [
    {
      icon: faPencil,
      onClick: (e) => {
        setOptionsList(mt, clickPosition(e, { x: -420, y: 0 }), true);
      },
    },
    {
      icon: faUndo,
      onClick: (e) => {
        setPageId(resetPageId ? false : true);
      },
    },
  ];
  const deleteModuleMenu = (id, menu_module_id, i, j) => {
    axios
      .post(env.protocol + env.env + "/api/secured/appearances/DeleteMenu", {
        menu_module_id,
        id,
      })
      .then((response) => {
        workingTemplate[i].splice(j, 1);
        workingTemplate = workingTemplate.filter((x) => x.length > 0);
        setFakeState(fakeState ? false : true);
      })
      .catch((error) => {});
  };
  const deleteMenuAttribute = (id) => {
    axios
      .post(env.protocol + env.env + "/api/secured/appearances/DeleteMenuAttribute", {
        id,
      })
      .then((response) => {})
      .catch((error) => {});
  };
  const weatherOptions = [
    {
      icon: faPencil,
      class: "option",
      onClick: (e, el) => {
        el.selectedCity = el.selectedCity ? el.selectedCity : {};
        setOptionsSlider(slider(el), clickPosition(e, { x: 0, y: 0 }), true);
      },
    },
  ];
  const currencyOpt = [
    {
      icon: faPencil,
      class: "option",
      onClick: (e, el) => {
        el.selectedCity = el.selectedCity ? el.selectedCity : {};
        setOptionsCurrency(currency(el), clickPosition(e, { x: 0, y: 0 }), true);
      },
    },
  ];
  const menuOptions = [
    {
      icon: faArrowsAlt,
      class: "option",
      text: "Move palette",
    },
    {
      icon: faPlus,
      class: "option",
      text: "Add palette",
      onClick: (e, elm, i, j) => {
        workingTemplate[i] = insert(workingTemplate[i], j + 1, {
          pl_title: "",
          children: [],
          feature_media: "",
          current_status: "published",
          new: true,
        });
        setFakeState(fakeState ? false : true);
      },
    },
    {
      icon: faPalette,
      class: "option",
      text: "Style palette",
      onClick: (e, elm) => {
        setOptionsPalette(pt(elm), clickPosition(e, { x: 0, y: 0 }), true);
      },
    },
    {
      eye: true,
      icon: faTimes,
      class: "option delete",
      text: "Delete palette",
      onClick: (e, elm, i, j) => {
        keyRender = new Date().valueOf();
        if (elm.menu_module) {
          if (elm.menu_module_id) {
            deleteModuleMenu(elm.id, elm.menu_module_id, i, j);
          } else {
            workingTemplate[i].splice(j, 1);
            workingTemplate = workingTemplate.filter((x) => x.length > 0);
            setFakeState(fakeState ? false : true);
          }

          return;
        }
        let page = pageChild(workingTemplate, "id", elm.id);
        var status = "hidden";
        var newAllpages = cloneDeep(allPages);

        if (elm.current_status === "hidden" && elm.pl_title !== "") {
          status = "published";
          newAllpages = newAllpages.filter((x) => x.id !== page.id);
        } else {
          if (elm.pl_title !== "") {
            status = "hidden";
            newAllpages.unshift(page);
          }
        }
        if (elm.pl_title === "" && elm.new) {
          workingTemplate[i].splice(j, 1);
        } else {
          workingTemplate[i][j].current_status = status;
        }

        setAllPages(newAllpages);
      },
    },
  ];
  const titleOptions = [
    {
      eye: true,
      shown: true,
      icon: showHiddenInTitle || showHiddenPages ? faEye : faEyeSlash,
      class: "option",
      onClick: (elm) => {
        keyRender = new Date().valueOf();
        setShowHiddenInTitle(showHiddenInTitle ? false : elm.id);
      },
    },
    {
      icon: faClone,
      class: "",
      shown: true,
      disabled: (elm) => !elm.shared,
    },
  ];
  const subMenuOptions = [
    {
      eye: true,
      icon: faTimes,
      onClick: (elm, parentid) => {
        // Toggle shown/hide status of page in menu

        // Find ID of parent element
        let findParent = -1;
        let itemIdx = -1;
        for (const index in workingTemplate) {
          const itemArr = workingTemplate[index];
          // Find element with same ID as the item for deletion
          const idx = itemArr.findIndex((item) => item.id === parentid);

          // If the item was found....
          if (idx !== -1) {
            findParent = index;
            itemIdx = idx;
            break;
          }
        }

        let page = pageChild(workingTemplate, "id", elm.id);
        var status = "hidden";
        var newAllpages = cloneDeep(allPages);
        if (elm.current_status === "hidden") {
          status = "published";
          newAllpages = newAllpages.filter((x) => x.id !== page.id);
        } else {
          status = "hidden";

          newAllpages.unshift(page);
        }

        workingTemplate[findParent][itemIdx].children = workingTemplate[findParent][itemIdx].children.map((x) => {
          if (x.id === JSON.parse(elm.id)) {
            x.current_status = status;
          }
          return x;
        });
        keyRender = new Date().valueOf();
        setAllPages(newAllpages);
      },
    },
  ];

  const save = () => {
    let listWorkingTemplate = [];
    workingTemplate = workingTemplate.filter((x) => x.length > 0);
    workingTemplate = workingTemplate.filter((x) => x[0].pl_title !== "");
    workingTemplate.forEach((temp, i) => {
      temp = temp.filter((x) => x.pl_title !== "");
      temp.forEach((second, j) => {
        second["templateIndexing"] = JSON.stringify([i, j]);
        second.child_of = subPageId;
        second.site_id = props.siteSelected.actual_site_id ?? props.siteSelected.siteId;
        second.langId = lang.id;
        listWorkingTemplate.push(second);
        second.children.forEach((third, j) => {
          third.child_of = second.id;
          listWorkingTemplate.push(third);
        });
      });
    });
    workingTitle.map((x) => (x.child_of = JSON.parse(props.siteSelected.page_id)));
    let savedMenuTemplates = listWorkingTemplate.concat(workingTitle);
    axios
      .post(env.protocol + env.env + "/api/secured/updateSite", {
        name: logoTitle,
        site_id: JSON.parse(props.siteSelected.actual_site_id ?? props.siteSelected.siteId),
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });

    axios
      .post(env.protocol + env.env + "/api/secured/appearances/SaveMenu", {
        savedMenuTemplates,
        rootPage: props.siteSelected.rootPage ? props.siteSelected.id : "",
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });

    setListIsOpen(false);
    setEditMode(false);
  };
  const DraggableComp = (optionPos, open, options, pos, type) => {
    const nodeRef = React.useRef(null);
    if (Object.keys(pos).length === 0) {
      return;
    }
    return (
      <Draggable
        nodeRef={nodeRef}
        handle="#handle"
        position={optionPos}
        onStop={(e, data) => {
          if (type === "staticPalette") setOptionsMenuStaticPaletteOptionsPos({ x: data.x, y: data.y });
          if (type === "currency") setOptionsMenuCurrencyOptionsPos({ x: data.x, y: data.y });
          if (type === "slider") setOptionsMenuSliderOptionsPos({ x: data.x, y: data.y });
          if (type === "palette") setOptionsMenuPalettePos({ x: data.x, y: data.y });
          if (type === "list") setOptionsMenuPos({ x: data.x, y: data.y });
        }}
      >
        <div ref={nodeRef}>
          <OptionsList
            menu={true}
            isOpen={open}
            options={options}
            position={pos}
            deep={true}
            customElements={customElements}
            ignoreKeepInScreen={true}
          />
        </div>
      </Draggable>
    );
  };
  return [
    <div key={"page-sectoin-header"} className="page-section-header">
      <div
        onClick={() => {
          if (editMode) {
            if (window.confirm("Are you sure you want to leave? Unsaved data will be lost!")) props.close();
          } else {
            props.close();
          }
        }}
        className="back"
      >
        <FontAwesomeIcon icon={faArrowLeft} /> Appearances
      </div>
      {availableLanguages.map((e, i) => (
        <div
          className={"one-language" + (lang.value === e.value ? " selected" : "") + (editMode ? " disabled" : "")}
          key={i}
          onClick={() => {
            if (!editMode) {
              setSelectedLanguage(e);
            }
          }}
        >
          <div className="one-language-wrap">
            <div className="the-language">{e.label.toUpperCase()}</div>
          </div>
        </div>
      ))}
    </div>,

    <div ref={tagRef} key={"edit-menu " + lang.value} className="edit-menu">
      <div className="cta">
        {editMode ? (
          <div className="left-cta">
            <div
              onClick={() => {
                save();
              }}
              className="save"
            >
              Save
            </div>
            <div
              onClick={() => {
                setListIsOpen(false);
                setEditMode(false);
                setPageId(resetPageId ? false : true);
              }}
              className="discard"
            >
              Discard changes
            </div>
          </div>
        ) : (
          <div className="left-cta">
            <div
              onClick={() => {
                setEditMode(true);
              }}
              className="save"
            >
              Edit
            </div>
          </div>
        )}
        <div className="right-cta">
          {showHiddenPages ? (
            <div
              onClick={() => {
                setShowHiddenPages(false);
              }}
              className="show-hide-pages"
            >
              Hide hidden pages
            </div>
          ) : (
            <div
              onClick={() => {
                setShowHiddenPages(true);
              }}
              className="show-hide-pages"
            >
              Show hidden pages
            </div>
          )}
        </div>
      </div>
      <div className="header-menu">
        <div className="logo">
          <div>
            {!env.env.includes("vita.fo") && (
              <div className="image">
                <img alt="edit logo" src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo} />
              </div>
            )}
            <div className="text">
              <div
                onInput={(e) => {
                  logoTitle = e.target.textContent;
                }}
                className={editMode ? "active" : ""}
                contentEditable={editMode ? "true" : "false"}
                suppressContentEditableWarning={true}
              >
                {logoTitle}
              </div>
            </div>
          </div>
        </div>

        {workingTitle.map((element, i) => {
          if (element.current_status === "published" || showHiddenPages) {
            return [
              <div
                key={"each-page" + i}
                className="each-page"
                onClick={() => {
                  setSubPageId(element.id);
                  setSubPageShared(element.shared);
                }}
              >
                {/* {JSON.stringify(element)} */}
                <div className="first-level">
                  <div className={"menu-options"}>
                    {titleOptions
                      .filter((option) => {
                        if (option.disabled) return !option.disabled(element);
                        else return true;
                      })
                      .map((option, t) => {
                        return (
                          <div
                            key={"option" + t}
                            onClick={(e) => {
                              if (option.onClick) option.onClick(element);
                            }}
                            className={
                              ((showHiddenInTitle === element.id || showHiddenPages) && option.shown
                                ? "option show"
                                : "option") + (option.onClick ? " clickable" : "")
                            }
                          >
                            {option.eye ? (
                              <FontAwesomeIcon
                                icon={
                                  showHiddenInTitle === element.id || showHiddenPages || showHiddenPages
                                    ? faEye
                                    : faEyeSlash
                                }
                              />
                            ) : (
                              <FontAwesomeIcon icon={option.icon} />
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <div
                    draggable="true"
                    className="first-title"
                    id={element.id}
                    onDragOver={(e) => {
                      allowDrop(e);
                    }}
                    onDragLeave={(e) => {
                      dragLeave(e);
                    }}
                    onDrop={(e) => {
                      dropTitle(e, i);
                    }}
                    onDragStart={(e) => {
                      onDragStart(e, element.id, i);
                    }}
                  >
                    {element.pl_title}
                  </div>
                </div>
                {subPageId === element.id && subPageShared === element.shared ? <div className="indicator"></div> : ""}
              </div>,
            ];
          }
          return "";
        })}
      </div>
      <div
        style={{
          display: subPageId ? "block" : "none",
        }}
        onClick={() => {
          setSubPageId(false);
        }}
        className="close"
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div
        key={subPageId + "-" + keyRender}
        style={{
          display: subPageId ? "flex" : "none",
          pointerEvents: editMode ? "" : "none",
        }}
        className="menu-template"
      >
        {DraggableComp(optionsMenuPos, listIsOpen, optionsListOptions, optionsListPos, "list")}
        {DraggableComp(optionsMenuPalettePos, paletteIsOpen, optionsPaletteOptions, optionsPalettePos, "palette")}
        {DraggableComp(
          optionsMenuSliderOptionsPos,
          sliderOptionsIsOpen,
          optionsSliderOptions,
          optionsSliderOptionsPos,
          "slider"
        )}
        {DraggableComp(
          optionsMenuCurrencyOptionsPos,
          currencyOptionsIsOpen,
          optionsCurrencyOptions,
          optionsCurrencyOptionsPos,
          "currency"
        )}
        {DraggableComp(
          optionsMenuStaticPaletteOptionsPos,
          staticPaletteOptionsIsOpen,
          optionsStaticPaletteOptions,
          optionsStaticPaletteOptionsPos,
          "staticPalette"
        )}

        <div className={editMode ? "menu-options" : "menu-options disable"}>
          {defaultModuleOptions.map((options, i) => {
            return (
              <div
                key={"option" + i}
                onClick={(e) => {
                  options.onClick(e);
                }}
                className="option"
              >
                <FontAwesomeIcon icon={options.icon} />
              </div>
            );
          })}
        </div>
        {subPages.length === 0 &&
          subPages.push({
            pl_title: "",
            children: [],
            current_status: "published",
            new: true,
          })}

        <div className="wrap-table">
          <table>
            <tbody>
              <tr key={"menues-" + keyRender} className="menues">
                {workingTemplate.map((element, i) => {
                  // const hasPalette = false;
                  const hasModule = element.some((x) => x?.menu_module);
                  return [
                    i === 0 && (
                      <td
                        key={"first" + i}
                        id={-1}
                        onDragOver={(e) => {
                          allowDrop(e, true);
                        }}
                        onDragLeave={(e) => {
                          dragLeave(e);
                        }}
                        onDrop={(e) => {
                          if (moduleDrag) {
                            dropNewModule(e, i, -1);
                          } else {
                            drop(e, i, -1);
                          }
                        }}
                        className="empty-td"
                      ></td>
                    ),
                    <td
                      key={"center" + i + keyRender}
                      className={`${hasModule ? "has-module each-page" : "each-page"}`}
                    >
                      {element.map((elm, j) => {
                        let newElm = cloneDeep(elm);
                        if (!showHiddenPages && !showHiddenInTitle) {
                          newElm.children = elm.children.filter(
                            (x) =>
                              x.current_status === "published" ||
                              (new Date(x.date_to_publish).valueOf() <= new Date().valueOf() &&
                                x.current_status === "scheduled")
                          );
                        }
                        if (!showHiddenPages && elm.current_status !== "published") {
                          return "";
                        }
                        return (
                          <div
                            key={"template" + i + "." + j}
                            onDragOver={(e) => {
                              allowDrop(e, newElm.new);
                            }}
                            onDragLeave={(e) => {
                              dragLeave(e);
                            }}
                            id={newElm.id}
                            onDrop={(e) => {
                              if (moduleDrag) {
                                if (!elm.splide) {
                                  dropNewModule(e, i, j);
                                }
                              } else {
                                drop(e, i, j);
                              }
                            }}
                            className={`
                              ${elm.menu_module ? "ckeditor" : ""}   
                              ${editMode ? "edit-mode second-level" : "second-level"}   
                              ${elm.palette && elm.palette !== "default" ? elm.palette + "-palette palette" : ""}
                            `}
                          >
                            {elm.menu_module ? (
                              <div className="carousel">
                                {elm.splide.length.map((index) => {
                                  return (
                                    <Carousel wrap={false} interval={null} key={index}>
                                      {elm.menu_module
                                        .filter((x) => x.slider_index.splideIndex === index)
                                        .map((el, z) => {
                                          if (el.a_label === "static_palette" && !el.value) {
                                            el.value = { image: "" };
                                          }
                                          el.slider_index = {
                                            splideIndex: index,
                                            moduleIndex: z,
                                          };

                                          const reactComponentTypes = ["static_palette"];

                                          return (
                                            <Carousel.Item key={el.a_label}>
                                              {el.a_label === "weather" && editMode
                                                ? weatherOptions.map((option, w) => {
                                                    return (
                                                      <div
                                                        key={"weatherOption_" + w}
                                                        onClick={(e) => {
                                                          if (option.onClick) {
                                                            option.onClick(e, el);
                                                          }
                                                        }}
                                                        className={option.class}
                                                      >
                                                        <FontAwesomeIcon icon={option.icon} />
                                                      </div>
                                                    );
                                                  })
                                                : ""}
                                              {el.a_label === "currency" && editMode
                                                ? currencyOpt.map((option, w) => {
                                                    return (
                                                      <div
                                                        key={"currencyOption_" + w}
                                                        onClick={(e) => {
                                                          if (option.onClick) {
                                                            option.onClick(e, el);
                                                          }
                                                        }}
                                                        className={option.class}
                                                      >
                                                        <FontAwesomeIcon icon={option.icon} />
                                                      </div>
                                                    );
                                                  })
                                                : ""}
                                              {el.a_label === "static_palette" && (
                                                <div
                                                  onDragOver={(e) => {
                                                    allowDrop(e, true, "splitSlide");
                                                  }}
                                                  onDragLeave={(e) => {
                                                    dragLeave(e);
                                                  }}
                                                  onDrop={(e) => {
                                                    dropModule(e, i, j, el, z);
                                                  }}
                                                >
                                                  <StaticPalette
                                                    openOverlay={props.openOverlay}
                                                    object={el.a_value}
                                                    change={(key, value) => {
                                                      const keys = key.split(".");
                                                      let element = el.a_value;
                                                      // console.log(
                                                      //   "Before:",
                                                      //   element
                                                      // );
                                                      for (let i = 0; i < keys.length; i++) {
                                                        if (i !== keys.length - 1) {
                                                          // GO DEEPER
                                                          element = element[keys[i]];
                                                        } else {
                                                          // ASSIGN
                                                          element[keys[i]] = value;
                                                        }
                                                      }
                                                      // console.log(
                                                      //   "After:",
                                                      //   element
                                                      // );
                                                    }}
                                                    setOptionsList={(options, pos, isCustom) => {
                                                      setOptionsStaticPalette(options, pos, isCustom);
                                                    }}
                                                    close={() => setStaticPaletteOptionsIsOpen(false)}
                                                    language={lang.value}
                                                  />
                                                </div>
                                              )}
                                              {el.a_label !== "" ? (
                                                <VfiCKeditor
                                                  value={el.a_value}
                                                  label={el.a_label}
                                                  // onBlur={setOnBlur}
                                                  onChange={(data) => {
                                                    el.a_value = data;
                                                  }}
                                                />
                                              ) : (
                                                <div
                                                  className="empty-slider"
                                                  onDragOver={(e) => {
                                                    allowDrop(e, true, "splitSlide");
                                                  }}
                                                  onDragLeave={(e) => {
                                                    dragLeave(e);
                                                  }}
                                                  onDrop={(e) => {
                                                    dropModule(e, i, j, el, z);
                                                  }}
                                                ></div>
                                              )}
                                            </Carousel.Item>
                                          );
                                        })}
                                    </Carousel>
                                  );
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              style={{
                                display: elm.palette === "image" ? "flex" : "none",
                              }}
                              className={elm.palette}
                            >
                              <img alt="palette" src={elm.feature_media ? elm.feature_image_dir?.large : ""} />
                            </div>
                            <div className={editMode ? "menu-options" : "menu-options disable"}>
                              {menuOptions.map((option, k) => {
                                if (elm.menu_module) {
                                  if (option.icon.iconName === "palette") {
                                    return <></>;
                                  }
                                }

                                return (
                                  <GlobalToolTipController
                                    solid
                                    opacity={0.9}
                                    title=""
                                    priority="top,bottom,right,left"
                                    // offset={{ x: 20, y: 10 }}
                                    toolTipElements={<>{option.text}</>}
                                    key={option.text}
                                  >
                                    <div
                                      draggable="true"
                                      onDragStart={(e) => {
                                        if (elm.menu_module) {
                                          onDragStartModule(e, elm.moduleType, elm.id, elm.tempId);
                                        } else {
                                          onDragStart(e, newElm.id, i);
                                        }
                                      }}
                                      key={"menuOption_" + k}
                                      onClick={(e) => {
                                        if (option.onClick) {
                                          option.onClick(e, newElm, i, j);
                                        }
                                      }}
                                      className={option.class}
                                    >
                                      {option.eye ? (
                                        <FontAwesomeIcon
                                          icon={newElm.current_status === "hidden" ? faEye : option.icon}
                                        />
                                      ) : (
                                        <FontAwesomeIcon icon={option.icon} />
                                      )}
                                    </div>
                                  </GlobalToolTipController>
                                );
                              })}
                            </div>
                            <div id={newElm.id} className={newElm.current_status === "draft" ? "title draft" : "title"}>
                              {newElm.pl_title}
                              {newElm.current_status === "hidden" ? (
                                <div className="menu-hidden">
                                  <FontAwesomeIcon icon={faEyeSlash} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              style={{
                                display: elm.palette === "list" ? "flex" : "none",
                              }}
                              className={elm.palette}
                            >
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                            <div
                              key={"second" + j}
                              onDragOver={(e) => {
                                e.preventDefault();
                              }}
                              className="third-level"
                            >
                              {newElm.children.map((e, k) => {
                                return [
                                  <div
                                    key={"drop" + k}
                                    className={e.current_status === "draft" ? "dropSub draft" : "dropSub"}
                                    parentid={newElm.id}
                                    id={e.id}
                                    onDrop={(e) => {
                                      dropSub(e);
                                    }}
                                  >
                                    <div className="arrow">
                                      <FontAwesomeIcon icon={faArrowRight} />
                                    </div>
                                    <div>{e.pl_title}</div>
                                    {e.current_status === "hidden" ? (
                                      <div className="menu-hidden">
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="subMenuOptions menu-options">
                                      {subMenuOptions.map((option, i) => {
                                        return (
                                          <div
                                            key={"option" + i}
                                            onClick={() => {
                                              option.onClick(e, newElm.id);
                                            }}
                                            className="option"
                                          >
                                            {option.eye ? (
                                              <FontAwesomeIcon
                                                icon={e.current_status === "hidden" ? faEye : option.icon}
                                              />
                                            ) : (
                                              <FontAwesomeIcon icon={option.icon} />
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>,
                                ];
                              })}

                              {newElm.children.length === 0 && !elm.menu_module ? (
                                newElm.pl_title === "" ? (
                                  <div style={{ opacity: "0.5" }}>No menu</div>
                                ) : (
                                  <div
                                    className="dropSub"
                                    parentid={newElm.id}
                                    id={-1}
                                    onDrop={(e) => {
                                      dropSub(e);
                                    }}
                                  >
                                    <div style={{ opacity: "0.5" }}>
                                      {" "}
                                      {showHiddenInTitle ? "No sub menu" : "No visible menu"}
                                    </div>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </td>,
                    <td
                      key={"last" + i}
                      id={-1}
                      onDragOver={(e) => {
                        allowDrop(e, true);
                      }}
                      onDragLeave={(e) => {
                        dragLeave(e);
                      }}
                      onDrop={(e) => {
                        if (moduleDrag) {
                          dropNewModule(e, i + 1, -1);
                        } else {
                          drop(e, i + 1, -1);
                        }
                      }}
                      className="empty-td"
                    ></td>,
                  ];
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>,
  ];
}
