import { useState, useEffect } from "react";
import env from "../../../../environment.json";
import { IMedia } from "../types";

export const useInitialImageSource = (
  media: IMedia,
  src: string,
  extension: string,
  imgFormat: string | null | undefined
) => {
  const initialSrc = media.medium
    ? media.medium.large
    : (src ??
      `${env.protocol}${env.env}/uploads/${media.file_name}${
        imgFormat ? `_${imgFormat}.` : ["gif", "svg"].includes(media.extension) ? "." : "_large."
      }${extension}`);
  const [imgSrc, setImgSrc] = useState<string | null>(initialSrc);

  useEffect(() => {
    setImgSrc(initialSrc);
  }, [media, src, extension, imgFormat]);

  return [imgSrc, setImgSrc];
};
