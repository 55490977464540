import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import MainGridSelect from "./MainGridSelect";
import MainWhatson from "./MainWhatson";
import env from "../../../environment.json";
import "./WhatsonChoose.scss";
import { usePrevious } from "../../helperFunctions";
// import WhatsonChooseType from "./WhatsonChooseType";

export default function WhatsonChoose({ data, apply, cancel, close, steps }) {
  const [newData, setNewData] = useState(data);
  const [allWhatson, setAllWhatson] = useState([]);
  const [whatsonTypes, setWhatsonTypes] = useState([]);
  const [canContinue, setCanContinue] = useState(false);
  const [step, setStep] = useState(steps ? steps[0] : 0);
  const prevStep = usePrevious(step);
  const lastStep = steps ? steps[steps.length - 1] : 1;

  useEffect(() => {
    const whatsonLangs = ["en", "fo"];
    let lang = newData.lang;
    if (lang) {
      if (!whatsonLangs.includes(lang)) {
        // Throw back to en if langugae is unknown to whatson environment.
        lang = "en";
      }
    }

    axios
      .get(env.protocol + env.env + "/api/whatson/GetAllWhatsonList?" + (lang ? `language=${lang}&` : ""))
      .then((response) => {
        setAllWhatson(response.data);
      })
      .catch((error) => console.error(error));

    axios
      .get(env.protocol + env.env + "/api/whatson/GetWhatsonNames")
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          return a.order_value > b.order_value;
        });
        // TODO: .filter should be removed when more whatson element will be implemented
        setWhatsonTypes(sortedData.filter((e) => e.name === "Places"));
        if (!newData.whatWhatson)
          setNewData({
            ...newData,
            whatWhatson: sortedData.filter((e) => e.name === "Places")[0].id,
            category_filters: [0],
          });
      })
      .catch((error) => console.error(error));
  }, [newData]);

  useEffect(() => {
    switch (step) {
      case 0:
        if (newData.whatson_chosen_ids.length > 0) {
          setCanContinue(true);
        } else {
          setCanContinue(false);
        }
        break;
      case 1:
        if (prevStep === 0)
          setNewData({
            ...newData,
            amount_per_grid: Math.min(newData.whatson_chosen_ids.length, 8),
          });
        break;
      default:
        break;
    }
  }, [step, prevStep, newData]);

  const applyToPage = (inData) => {
    let whatsons = [];
    if (inData.whatWhatson !== undefined) {
      const sayWhat = whatsonTypes.find((e) => e.id === inData.whatWhatson);
      if (sayWhat !== undefined) {
        if (sayWhat.name === "Events") {
        }
        if (sayWhat.name === "Tours") {
        }
        if (sayWhat.name === "Hiking") {
        }
        if (sayWhat.name === "Places") {
          whatsons = allWhatson.filter((e) => e.type === "place");
        }
      }
    } else {
      return;
    }

    let outData = [];
    if (inData.whatson_design_type === "grid") {
      let swapped = false;

      for (let i = 0; i < inData.whatson_chosen_ids.length; i += inData.amount_per_grid) {
        let whatson_list = [];

        if (i + inData.amount_per_grid <= inData.whatson_chosen_ids.length) {
          for (let j = 0; j < inData.amount_per_grid; j++) {
            whatson_list.push(whatsons.find((e) => e.id === inData.whatson_chosen_ids[i + j]));
          }
          outData.push({
            type: "whatson_grid",
            preset_number: inData.grid_preset,
            whatson_list,
            swapped,
          });
          swapped = !swapped;
        } else {
          for (let j = 0; j < inData.whatson_chosen_ids.length % inData.amount_per_grid; j++) {
            whatson_list.push(whatsons.find((e) => e.id === inData.whatson_chosen_ids[i + j]));
          }
          outData.push({
            type: "whatson_grid",
            new: true,
            preset_number: 1,
            whatson_list,
          });
        }
      }

      return apply(outData);
    }

    if (inData.whatson_design_type === "list") {
      let whatson_list = [];
      whatson_list = whatsons.filter((item) => inData.whatson_chosen_ids.includes(item.id));

      outData.push({
        type: "whatson_list",
        new: true,
        whatson_list,
      });

      return apply(outData);
    }

    if (inData.whatson_design_type === "card") {
      let whatson_list = [];
      whatson_list = whatsons.filter((item) => inData.whatson_chosen_ids.includes(item.id));

      outData.push({
        type: "whatson_card",
        new: true,
        whatson_list,
      });

      return apply(outData);
    }
  };
  return (
    <div className="whatson-choose">
      <div className="close" onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>

      {step === 0 && (
        <MainWhatson
          data={newData}
          onChange={(e) => {
            setNewData(e);
          }}
          allWhatson={allWhatson}
          whatsonTypes={whatsonTypes}
        />
      )}
      {step === 1 && (
        <MainGridSelect
          data={newData}
          onChange={(e) => {
            setNewData(e);
          }}
          allWhatson={allWhatson}
          whatsonTypes={whatsonTypes}
        />
      )}

      <div className="bottom-buttons">
        <div className="amount-text">{newData.whatson_chosen_ids.length} chosen</div>
        {step > 0 && (
          <div className="previous" onClick={() => setStep(step - 1)}>
            Previous
          </div>
        )}
        {step !== lastStep && (
          <div className={"next" + (canContinue ? "" : " disabled")} onClick={() => setStep(step + 1)}>
            Next
          </div>
        )}
        {step === lastStep && (
          <div
            className={"apply" + (canContinue ? "" : " disabled")}
            onClick={() => {
              applyToPage(newData);
            }}
          >
            Apply
          </div>
        )}
        {cancel && (
          <div className="cancel" onClick={() => cancel()}>
            Cancel
          </div>
        )}
      </div>
    </div>
  );
}
