import styles from "./MapDropdown.module.scss";
import { useEffect, useRef, useState } from "react";
import GoogleMapSearchBox from "src/assets/GoogleMapSearchBox";
import { SimpleMap } from "src/assets/GoogleMaps";
import VfiInput from "src/assets/VfiInput/VfiInput";
import torshavnRegions from "src/assets/TorshavnRegions.json";

import Select from "react-select";

import env from "src/environment.json";

import ZIP_CODES from "src/assets/zipCodes.json";
import FormValue from "src/views/WhatsOn/types/creators/FormValue";
import axios from "axios";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";
import { IZip } from "src/views/WhatsOn/stores/location-store/LocationStore";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import VerifyMethods from "src/assets/VerifyMethods";
import { findRegionAndZip } from "src/assets/helperFunctions_ts";

/**
 * Zip to city map - A map of zip to city
 */
const ZIP_TO_CITY_MAP = Object.entries(ZIP_CODES).reduce((acc: any, [key, value]) => {
  acc[value.code] = value;
  return acc;
}, {});

/**
 * Custom components for the react-select
 */
const customComponents = {
  IndicatorSeparator: () => null, // Render nothing for the IndicatorSeparator
};

// const REGION_MAP: Partial<Record<string, string>> = {
//   Eysutoyar: "Eysturoy",
//   "Northern Isles": "Norðoyggjar",
//   Streymoyar: "Streymoy",
//   Suduroy: "Suðuroy",
//   Suðuroyar: "Suðuroy",
// };

// interface IFindRegionAndZip {
//   region: undefined | string;
//   zip: undefined | IZip;
// }

// const findRegionAndZip = (geocodeData: google.maps.GeocoderResult[]): IFindRegionAndZip => {
//   let region: string | undefined = undefined;
//   let zipValue: number | undefined = undefined;

//   for (let i = 0; i < geocodeData.length; i++) {
//     geocodeData[i].address_components.forEach((element) => {
//       element.types.forEach((type) => {
//         if (type === "postal_code" && zipValue === undefined) {
//           zipValue = parseInt(element.long_name);
//         }
//         if (type === "administrative_area_level_1" && region === undefined) {
//           region = element.long_name;
//         }
//       });
//     });
//   }
//   region = REGION_MAP[region ?? ""] ?? region;
//   if (torshavnRegions.includes(zipValue ?? -1)) {
//     region = "Tórshavn";
//   }
//   let zip = undefined;
//   if (zipValue) {
//     for (const [key, value] of Object.entries(ZIP_CODES)) {
//       if (zipValue === value.code) {
//         zip = { code: value.code.toString(), city: value.city };
//       }
//     }
//   }
//   return { region, zip };
// };

/**
 * Regions endpoint url
 */
const regionsEndpoint = `${env.protocol}${env.env}/api/public/GetRegions`;

/**
 * Main component
 */
export default function MapDropdown() {
  /**
   * Store properties
   */
  const { location, setLocation, regions, setRegions, type, errors, validateLocation } = getWhatsonStore();
  /**
   * Init - Tells whether everything is initialized
   */
  const [init, setInit] = useState<boolean>(false);
  /**
   * Map loaded - Tells whether the map is loaded
   */
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  /**
   * Address input - The input value of the address
   */
  const [address_input, setAddressInput] = useState<string>("");
  /**
   * Content ref - The reference to the content div
   */
  const contentRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (contentRef?.current && !init) {
      /**
       * Fetch regions
       */
      axios
        .get(regionsEndpoint)
        .then((respsone) => {
          /**
           * Set regions - On success, set the regions
           */
          setRegions(
            respsone.data.map((region: any) => {
              return {
                id: region?.region_id,
                name: region?.region_name,
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
      /**
       * Set init - Set the init to ture when mounted
       */
      setInit(true);
    }
  }, [contentRef]);

  /**
   * Set the address input to the location address whenever the location changes
   */
  useEffect(() => {
    setAddressInput(location.address ?? "");
  }, [location]);

  /**
   * Render
   */
  return (
    <div
      style={{
        height: location.custom && init && mapLoaded ? `${contentRef?.current?.offsetHeight}px` : "0px",
        opacity: location.custom && init && mapLoaded ? 1 : 0,
        userSelect: location.custom && init && mapLoaded ? "auto" : "none",
        pointerEvents: location.custom && init && mapLoaded ? "auto" : "none",
      }}
      className={`${styles.container}`}
    >
      {/* Content */}
      <div ref={contentRef} className={styles.content}>
        {/* Name of location */}
        <div className={`${styles.map_input_wrapper}`}>
          Name of location
          <VfiInputText
            defaultValue={location.nameOfLocation}
            onChange={(e: any) => {
              setLocation({ ...location, nameOfLocation: e.target.value });
              if (!errors?.[type]?.valid) validateLocation();
            }}
            errorCheckSequence={[VerifyMethods.notEmpty]}
          />
        </div>
        {/* Map Input */}
        <div className={`${styles.map_input_wrapper} ${!errors?.[type]?.valid && !address_input ? styles.error : ""}`}>
          Address
          <GoogleMapSearchBox
            value={address_input ?? ""}
            placeholder={"Search for address"}
            onChange={((e: any) => setAddressInput(e.target.value)) as any}
            change={(data) => {
              setLocation({
                place: null,
                address: data?.formatted_address,
                latitude: data?.geometry?.location?.lat(),
                longitude: data?.geometry?.location?.lng(),
                ...(() => {
                  const regionAndZipFound = findRegionAndZip([data as any]);
                  return {
                    region: regionAndZipFound.region
                      ? {
                          label: "",
                          error: { message: "" },
                          value: {
                            id: regions.find((r) => r.name === regionAndZipFound.region)?.id ?? 0,
                            name: regionAndZipFound.region,
                          },
                        }
                      : location.region,
                    zip: regionAndZipFound.zip
                      ? { label: "", error: { message: "" }, value: regionAndZipFound.zip }
                      : location.zip,
                  };
                })(),
              });
              if (!errors?.[type]?.valid) validateLocation();
            }}
          />
        </div>
        {/* Map */}
        <div className={styles.map_wrapper}>
          <SimpleMap
            marker={
              location.latitude && location.longitude ? { lat: location.latitude, lng: location.longitude } : undefined
            }
            onClick={(data) => {
              setLocation({
                place: null,
                address: data.address,
                latitude: data.mapMouseEvent.latLng?.lat(),
                longitude: data.mapMouseEvent.latLng?.lng(),
                ...(data.geocode
                  ? (() => {
                      const regionAndZipFound = findRegionAndZip(data.geocode.results);
                      return {
                        region: regionAndZipFound.region
                          ? {
                              label: "",
                              error: { message: "" },
                              value: {
                                id: regions.find((r) => r.name === regionAndZipFound.region)?.id ?? 0,
                                name: regionAndZipFound.region,
                              },
                            }
                          : location.region,
                        zip: regionAndZipFound.zip
                          ? { label: "", error: { message: "" }, value: regionAndZipFound.zip }
                          : location.zip,
                      };
                    })()
                  : {}),
              });
              if (!errors?.[type]?.valid) validateLocation();
            }}
            onLoad={() => {
              setMapLoaded(true);
            }}
          />
        </div>
        {/* Location details */}
        <div className={styles.location_details}>
          {/* Region */}
          <div className={styles.region}>
            <div className={styles.detail_title}>Region</div>
            <SelectInput
              options={regions.map((region) => ({ value: region, label: region.name }))}
              value={location.region?.value?.name ?? undefined}
              error={!errors?.[type]?.valid && !location.region?.value ? "Invalid input" : ""}
              placeholder={"Select region"}
              onChange={(data: any) => {
                setLocation({
                  region: FormValue(data?.value),
                });
                if (!errors?.[type]?.valid) validateLocation();
              }}
            />
          </div>

          {/* Zip */}
          <div className={styles.zip}>
            <div className={styles.detail_title}>Zip</div>
            <SelectInput
              options={Object.entries(ZIP_CODES).map((entry) => ({
                value: entry[1],
                label: `${entry[1].code} ${entry[1]?.city}`,
              }))}
              error={!errors?.[type]?.valid && !location?.zip.value ? "Invalid input" : ""}
              value={location.zip?.value ? `${location.zip?.value?.code} ${location.zip?.value?.city}` : undefined}
              placeholder={"Select zip"}
              onChange={(data: any) => {
                setLocation({
                  zip: FormValue(data?.value),
                });
                if (!errors?.[type]?.valid) validateLocation();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * SelectInput - A custom select input
 *
 * @returns JSX.Element
 */
function SelectInput({ options, value, error = "", placeholder, onChange }: any) {
  return (
    <Select
      value={value ? { value: value, label: value } : undefined}
      options={options}
      placeholder={placeholder}
      components={customComponents}
      onChange={onChange}
      maxMenuHeight={200}
      styles={{
        container: (styles) => ({
          ...styles,
          width: "100%",
          height: "50px",
          textAlign: "left",
          ...(error ? { outline: "2px solid red" } : {}),
        }),
        singleValue: (styles) => ({
          ...styles,
          textAlign: "left",
        }),
        control: (styles) => ({
          ...styles,
          width: "100%",
          height: "100%",
          color: "#009fff",
          backgroundColor: "#fafafa",
          border: "none",
        }),
      }}
    />
  );
}
