import React, { useState } from "react";
import MediaSettings from "./MediaSettings";
import env from "../../../environment.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoute, faTimes } from "@fortawesome/pro-light-svg-icons";
import VideoThumbnail from "react-video-thumbnail";
import VerifyMethods from "../../../assets/VerifyMethods";
import noImage from "../../../assets/images/empty.jpg";

function addValueToLast(object, keys, value) {
  if (keys.length === 1) {
    object[keys[0]] = value;
    return object;
  } else {
    object[keys[0]] = addValueToLast(object[keys[0]], keys.slice(1, keys.length), value);
    return object;
  }
}

function MediaEditMany(props) {
  const [imageChosen, setImageChosen] = useState(props.images[0]);

  const editSettings = (key, value) => {
    const keys = key.split(".");

    let object = Object.assign({}, imageChosen);
    object = addValueToLast(object, keys, value);

    setImageChosen(object);
  };

  const nextImage = (addition) => {
    let newIndex = 0;
    props.images.forEach((element, i) => {
      if (element.id === imageChosen.id) {
        if (i === 0 && addition < 0) {
          newIndex = props.images.length - 1;
        } else if (i === props.images.length - 1 && addition > 0) {
          newIndex = 0;
        } else {
          newIndex = i + addition;
        }
      }
    });
    setImageChosen(props.images[newIndex]);
  };

  return (
    <div className="media-edit-many">
      <div
        className="close-edit-all"
        onClick={() => {
          props.close();
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="selected-images-wrap">
        {props.images.map((element, i) => {
          return (
            <div key={i} className="one-image">
              <div className="image-choose" onClick={() => setImageChosen(element)} />
              <div
                className="close-image"
                onClick={() => {
                  props.closeSpecificImage(element.id);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
              {VerifyMethods.extensionIsVideo(element.fileExtension) ? (
                <div
                  className={"image" + (imageChosen !== null ? (element.id === imageChosen.id ? " chosen" : "") : "")}
                >
                  <VideoThumbnail
                    videoUrl={env.protocol + env.env + "/uploads/" + element.file_name + "." + element.fileExtension}
                    snapshotAtTime={10}
                  />
                </div>
              ) : element.fileExtension.toLowerCase() === "gpx" ? (
                <div className="gpx-icon">
                  <div className="inside-gpx">
                    <FontAwesomeIcon icon={faRoute} />
                    <p>GPX</p>
                  </div>
                </div>
              ) : (
                <img
                  className={"image" + (imageChosen !== null ? (element.id === imageChosen.id ? " chosen" : "") : "")}
                  src={
                    element.fileExtension.toLowerCase() === "jpg" ||
                    element.fileExtension.toLowerCase() === "jpeg" ||
                    element.fileExtension.toLowerCase() === "png" ||
                    element.fileExtension.toLowerCase() === "svg" ||
                    element.fileExtension.toLowerCase() === "gif"
                      ? env.protocol +
                        env.env +
                        "/uploads/" +
                        element.file_name +
                        (element.fileExtension.toLowerCase() !== "gif" && element.fileExtension.toLowerCase() !== "svg"
                          ? "_medium."
                          : ".") +
                        element.fileExtension
                      : noImage
                  }
                  alt={element.description}
                />
              )}
            </div>
          );
        })}
      </div>
      {/* <div className="add-new-media-parent">            
        {props.insertClick ? (
          <div
            className="insert-selected"
            onClick={() => {
              props.insertClick(
                props.images
              );
            }}
          >
            <div className="text">Insert selected</div>
          </div>
        ) : (
          ""
        )}
      </div> */}
      {imageChosen !== null ? (
        <MediaSettings
          key={"m" + imageChosen.id}
          media={imageChosen}
          editMedia={imageChosen}
          editSettings={editSettings}
          onComponentLoaded={() => {
            // this.imageAmountLoaded++;
            // if (this.imageAmountLoaded === this.indexesRow.length) {
            //   this.imageAmountLoaded = 0;
            //   this.resize();
            // }
          }}
          closeSettings={() => {
            setImageChosen(null);
          }}
          nextMedia={nextImage}
          refresh={() => {
            this.setState({ showSettings: false });
            this.props.refresh();
          }}
          openEdit={props.openEdit}
          deleteMedia={(id) => {
            if (window.confirm("Are you sure you want to delete this media?")) {
              props.deleteMedia(id);
              setImageChosen(null);
            }
          }}
          updateClick={props.changeMedia}
          setSiteRef={props.setSiteRef}
          hideCloseButton={true}
          sites={props.sites}
          showInputInfo={props.showInputInfo}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default MediaEditMany;
