import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VfiInputText from "../VfiInputText/VfiInputText";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import language from "./language.json";
import "./Prices.scss";
import VerifyMethods from "../VerifyMethods";

class Prices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prices: [],
      payUrl: "",
    };

    if (props.defaultValue && props.defaultValue.prices) {
      const defaultValue = { ...props.defaultValue };

      this.state.prices = defaultValue.prices.map((price) => ({
        ...price,
        price: price.price || "",
        foLabel: price.foLabel || "",
        enLabel: price.enLabel || "",
      }));

      this.state.payUrl = defaultValue.payUrl || "";
    }

    // If this.props.language is undefined, or doesn't exist in language.json,
    // use a default language key (for example, 'en').
    this.language = language[this.props.language || "en"];
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      if (this.props.defaultValue) {
        const defaultValue = { ...this.props.defaultValue };
        this.setState({
          prices: defaultValue.prices.map((price) => ({
            ...price,
            price: price.price || "",
            foLabel: price.foLabel || "",
            enLabel: price.enLabel || "",
          })),
          payUrl: defaultValue.payUrl || "",
        });
      }
    }
  }

  addPrice = (valueToAdd) => {
    if (!this.props.disabled) {
      const newPrices = Object.assign([], this.state.prices);
      var key = 0;
      newPrices.forEach((element) => {
        if (element.key > key) {
          key = element.key;
        }
      });

      newPrices.push({ key: key + 1, price: "", foLabel: "", enLabel: "" });

      this.setState(
        {
          prices: newPrices,
        },
        () => {
          this.props.onChange(this.JsonValue());
        }
      );
    }
  };

  JsonValue() {
    return {
      prices: this.state.prices,
      payUrl: this.state.payUrl,
    };
  }

  // deleteClick = () => {
  //   if(this.props.disabled){
  //     const pricesD = JSON.parse(
  //      JSON.stringify(this.state.prices)
  //    );
  //    pricesD.splice(i, 1);
  //    this.setState({ prices: pricesD }, () => {
  //      this.props.onChange(this.JsonValue());
  //    });
  //   }
  // }

  render() {
    return (
      <div className={"prices " + this.props.className}>
        <div className="table-wrap">
          <table>
            {this.state.prices.length > 0 ? (
              <thead>
                <tr>
                  <th>{this.language.table_head.price}</th>
                  <th>{this.language.table_head.faroese_label}</th>
                  <th>{this.language.table_head.english_label}</th>
                  <th></th>
                </tr>
              </thead>
            ) : (
              <thead></thead>
            )}
            <tbody>
              {this.state.prices.map((element, i) => {
                return (
                  <tr key={i}>
                    <td className="price">
                      <VfiInputText
                        disabled={this.props.disabled}
                        value={element.price}
                        placeholder={this.language.placeholder.dkk}
                        onChange={(e) => {
                          const pricesD = JSON.parse(JSON.stringify(this.state.prices));
                          pricesD[i].price = e.target.value;
                          this.setState({ prices: pricesD }, () => {
                            this.props.onChange(this.JsonValue());
                          });
                        }}
                        inputType="2digits"
                        errorCheckSequence={[VerifyMethods.notEmpty]}
                      />
                    </td>
                    <td className="from">
                      <VfiInputText
                        disabled={this.props.disabled}
                        value={element.foLabel}
                        placeholder={this.language.placeholder.vaksin}
                        onChange={(e) => {
                          const pricesD = JSON.parse(JSON.stringify(this.state.prices));
                          pricesD[i].foLabel = e.target.value;
                          this.setState({ prices: pricesD }, () => {
                            this.props.onChange(this.JsonValue());
                          });
                        }}
                        errorCheckSequence={[VerifyMethods.notEmpty]}
                      />
                    </td>
                    <td className="to">
                      <VfiInputText
                        disabled={this.props.disabled}
                        value={element.enLabel}
                        placeholder={this.language.placeholder.adult}
                        onChange={(e) => {
                          const pricesD = JSON.parse(JSON.stringify(this.state.prices));
                          pricesD[i].enLabel = e.target.value;
                          this.setState({ prices: pricesD }, () => {
                            this.props.onChange(this.JsonValue());
                          });
                        }}
                        errorCheckSequence={[VerifyMethods.notEmpty]}
                      />
                    </td>
                    <td className="delete">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                          if (!this.props.disabled) {
                            const pricesD = JSON.parse(JSON.stringify(this.state.prices));
                            pricesD.splice(i, 1);
                            this.setState({ prices: pricesD }, () => {
                              this.props.onChange(this.JsonValue());
                            });
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="add-row-wrap">
            <span className="add-row" onClick={this.addPrice}>
              <span className="add-price-text">{this.language.add_price}</span>
              <span className="add-icon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </span>
          </div>
        </div>
        <p>{this.language.url}</p>
        <VfiInputText
          disabled={this.props.disabled}
          value={this.state.payUrl}
          placeholder="optional"
          onChange={(e) => {
            this.setState({ payUrl: e.target.value }, () => {
              this.props.onChange(this.JsonValue());
            });
          }}
        />
      </div>
    );
  }
}

export default Prices;
