import OrderHead from "./OrderHead";
import { OrderType, HeaderState, OrderData, nullOrderData } from "./types";
import OrderDetails from "./OrderDetails";
import { useEffect, useState } from "react";
import axios from "axios";
import env from "../../../../environment.json";
import { cloneDeep } from "lodash";

/**
 * Order view
 *
 * @author 					Pætur Mortensen
 */
export default function Order({ close, orderID }: OrderType): JSX.Element {
  // Order data
  const [data, setData] = useState<OrderData>(nullOrderData);
  // Edited order data
  const [editedData, setEditedData] = useState<OrderData>(nullOrderData);
  // State for the header (whether we are in editable or in-edit mode)
  const [headerState, setHeaderState] = useState<HeaderState>("editable");
  // Whether the component is loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Whether order details are editable
  const editable = headerState === "in-edit";

  // console.log(data);

  useEffect(() => {
    // Load the order
    load_order();
  }, []);

  /**
   * Reload the order
   *
   * @author 					Pætur Mortensen
   */
  function reload_order(): void {
    load_order();
  }

  /**
   * Load the order
   *
   * @author 					Pætur Mortensen
   */
  function load_order(): void {
    setIsLoading(true);

    axios
      .get(env.protocol + env.env + "/api/secured/shop/GetAdminOrder?orderID=" + orderID)
      .then((response) => {
        setData(response.data);
        setEditedData(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }

  /**
   * Discard edited data
   *
   * @author 					Pætur Mortensen
   */
  function discard_edit() {
    setEditedData(cloneDeep(data));
    setHeaderState("editable");
  }

  return (
    <>
      <OrderHead
        data={data}
        editedData={editedData}
        close={close}
        headerState={headerState}
        setHeaderState={setHeaderState}
        reload_order={reload_order}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        discard_edit={discard_edit}
      />
      <OrderDetails data={editedData} setData={setEditedData} editable={editable} />
    </>
  );
}
