import "../Product.scss";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Inventory({ productState, prices, uuid, siteId, setEditInventory }) {
  return (
    <div className="inventory">
      <h3>Inventory</h3>
      <table>
        <tbody>
          <tr>
            <th>Name</th>
            {/* <th>Selling Price</th> */}
            <th>Netto Price</th>
            <th>Quantity</th>
            <th>Remote Quantity</th>
            <th>Unit</th>
            <th>Available</th>
            <th></th>
          </tr>
          {productState.varient
            ?.filter((x) => x.checked)
            .map((varientElm) => {
              varientElm.branduuid = productState.brand.uuid;
              varientElm.productuuid = productState.product.uuid;
              varientElm.varientuuid = varientElm.uuid;
              varientElm.sellingPrice = Math.round(parseFloat(varientElm.nettoPrice ?? 0) * 100) / 100;
              // varientElm.costPrice = 0
              // varientElm.sellingPriceTotal = parseFloat( varientElm.sellingPrice);

              return (
                <tr key={varientElm.id}>
                  <td>{varientElm.label}</td>
                  {/* <td>{varientElm.sellingPriceTotal}</td> */}
                  <td>{varientElm.nettoPrice}</td>
                  <td> {varientElm.quantity}</td>
                  <td> {varientElm.remoteQuantity}</td>
                  <td> {varientElm.unit.label}</td>
                  <td> {varientElm.quantity - varientElm.allocated}</td>
                  <td>
                    <div
                      className="edit-price"
                      onClick={() => {
                        const findPrice = prices.find((x) => x.varient?.value === varientElm?.uuid);
                        if (findPrice) {
                          findPrice.order_value = 999999999;
                          varientElm.prices = findPrice;
                        } else {
                          varientElm.prices = {
                            uuid: uuid(),
                            siteId: siteId,
                            productTypeuuid: varientElm.productTypeId,
                            label: "Varient price",
                            rate: 0,
                            rateType: {
                              label: "Percent",
                              value: "percent",
                            },
                            order_value: 9999999999,
                            new: true,
                            varient: { value: varientElm.uuid },
                          };
                          prices.push(varientElm.prices);
                        }

                        setEditInventory(varientElm);
                      }}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
