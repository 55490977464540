import { TPemissionKey } from "src/Components/PermCheck/Interfaces";
import { TWhatsonType } from "src/types/types/Whatson";

const whatsonType2PermissionTypeObj = {
  event: "events",
  place: "places",
  tour: "tours",
  tour_provider: "tourOperators",
} as const;

export function whatsonType2PermissionType(type: TWhatsonType): TPemissionKey | undefined {
  const obj = whatsonType2PermissionTypeObj;

  // if (type === "hike") {
  if (type === "hike") {
    console.error(`The whatson type '${type}' cannot be used this way.`);
    return undefined;
  }

  return obj[type];
}
