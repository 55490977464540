import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import { WhatsOnStore } from "../stores/WhatsonStore";
import { useWhatsonManager } from "../stores/whatson-manager-store/WhatsonManagerStore";
import { EWhatson } from "../types";
import { eventFormToBackendContract } from "./event/event-contract-client2server";
import { eventFormToClientContract } from "./event/event-contract-server2client";
import { IServerGetEventForm } from "./event/event-from-server-interface";
import axios, { AxiosResponse } from "axios";

import { v4 as uuidv4 } from "uuid";

import env from "src/environment.json";
import { IServerSubmitEventForm } from "./event/event-submit-interface";
import { useRouterStore } from "src/Components/Router/routerStore";
import { isEqual } from "lodash";
import { useState } from "react";

/**
 * Converts endpoint path to full url based on configured environment
 *
 * @param path - endpoint path
 *
 * @example
 * const ednpoint = fullEndpoint("/api/secured/whatson/events/AddEvent")
 *
 * console.log(endpoint) // https://api.cms.fo/api/secured/whatson/events/AddEvent
 *
 * @returns full url
 */
const fullEndpoint = (path: string) => {
  return env.protocol + env.env + path;
};

/**
 * Error message component
 */
const ErrorMessage = ({ message = "Error" }: { message?: string }) => {
  return (
    <div
      style={{
        padding: "16px 32px 16px 16px",
        minWidth: "90px",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 0, 0, 0.8)",
        color: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        fontSize: "13px",
        fontWeight: 500,
      }}
    >
      {message}
    </div>
  );
};

/**
 * Default message component
 */
const DefaultMessage = ({ message = "" }: { message?: string }) => {
  return (
    <div
      style={{
        padding: "16px 32px 16px 16px",
        minWidth: "90px",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 100, 255, 0.8)",
        color: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        fontSize: "13px",
        fontWeight: 500,
      }}
    >
      {message}
    </div>
  );
};

/**
 * Contracts for submitting whatson data
 *
 * Stores contract properties and configurations
 */
export const contracts: Partial<
  Record<
    EWhatson,
    {
      server2client: (eventData: IServerGetEventForm | undefined, publish?: boolean) => Partial<WhatsOnStore>;
      client2server: (store: WhatsOnStore, publish?: boolean) => IServerSubmitEventForm;
      endpoint: {
        internal: {
          url: string;
        };
        external: {
          url: string;
        };
        change: {
          url: string;
        };
      };
    }
  >
> = {
  /**
   * Event contract properties
   */
  event: {
    server2client: eventFormToClientContract,
    client2server: eventFormToBackendContract,
    endpoint: {
      internal: {
        url: fullEndpoint("/api/secured/whatson/events/AddEvent"),
      },
      external: {
        url: fullEndpoint("/api/public/whatson/events/AddEventNonUser"),
      },
      change: {
        url: fullEndpoint("/api/public/whatson/events/UpdateEventWithToken"),
      },
    },
  },
};

let savedData = {};
/**
 * Submit whatson data
 *
 * @param publish - publish flag
 * @param autosave - autosave flag
 *
 * @returns promise
 */
export function submitWhatson(publish: boolean = false, autosave: boolean = false): Promise<boolean> {
  return new Promise((resolve, reject) => {
    // Get form store
    const store = useWhatsonManager.getState().getStore().getState();
    const { saving, submitted, setId, setSaving, setSubmitted } = store;

    // Get addOverlay function
    const { addOverlay } = overlayStore.getState();

    // If submitted return true
    if (submitted) {
      // Resolve promise
      resolve(true);
      return;
    }

    // Check if whatson form is already saving
    if (saving.auto || saving.submit) {
      addOverlay("side_popout_self_close", (close) => {
        return <DefaultMessage message="Saving in progress" />;
      });

      // Resolve promise
      resolve(false);
      return;
    }

    // Get relevant contract
    const contract = contracts?.[store.type];

    // Response object
    const response = {
      success: (response: AxiosResponse<any>) => {
        if (response.data.id) {
          if (publish) setSubmitted(true);
          setId(response.data.id);
        }

        resolve(true);
      },
      error: (error: any) => {
        // Add error message
        addOverlay("side_popout_self_close", (close) => {
          return <ErrorMessage />;
        });
        // If something goes wrong, make it able to save the event once more.
        savedData = {};
        reject(false);
      },
      finally: () => {
        // Reset saving flags
        setSaving({
          auto: false,
          submit: false,
        });
      },
    };

    // Check if contract exists
    if (contract) {
      // Convert store to data
      const data = { ...contract.client2server(store, publish) };

      if (isEqual(data, savedData)) {
        // No changes needed
        resolve(true);
        return;
      }
      savedData = data;

      // Set saving flags
      setSaving({
        auto: autosave,
        submit: !autosave,
      });

      const { route } = useRouterStore.getState();

      // Get relevant endpoint
      // TODO: Please fix or refactor, this is a quick fix because we have more than 2 different endpoints for submitting.
      const endpoint =
        route[0]?.nav === "change"
          ? contract.endpoint.change.url
          : store.internal
            ? contract.endpoint.internal.url
            : contract.endpoint.external.url;

      // Make axios request
      if (store.internal) {
        axios
          .post(endpoint, {
            ...data,
            currentStatus: publish ? "published" : "draft",
          })
          .then(response.success)
          .catch(response.error)
          .finally(response.finally);
      } else {
        // Initialize FormData instance.
        const formData = new FormData();
        /**
         * Remove uneccessary data from images
         *
         * and add name to image element
         */
        data.instagram.images
          .filter((image) => image.media.file)
          .forEach((image, index) => {
            data.instagram.images[index].media.uuid = uuidv4();
          });
        data.images.mainImages
          .filter((image) => image?.element?.file)
          .forEach((image, index) => {
            data.images.mainImages[index].element.url = "";
            data.images.mainImages[index].elm = "";
            data.images.mainImages[index].name = (image.element.file as File).name;
            data.images.mainImages[index].uuid = uuidv4();
          });
        // Taken from the old event submit flow (might need to be re-evaluated when we include other What's On types).
        /**
         * Main data
         */
        formData.append(
          "main_data",
          JSON.stringify({
            ...data,
            currentStatus: publish ? "published" : "draft",
          })
          /**
           * Main images
           */
        );
        // Set main images
        formData.set("main_images[]", [] as any);
        data.images.mainImages
          .filter((image) => image?.element?.file)
          .forEach((image, index) => {
            formData.append(
              "main_images[]",
              image.element.file as File,
              `${image.uuid}.${image.element.file?.name.split(".").pop()}`
            );
          });
        // Set instagram images
        formData.set("insta_images[]", [] as any);
        data.instagram.images
          .filter((image) => image.media.file)
          .forEach((image, index) => {
            formData.append(
              "insta_images[]",
              image.media.file as File,
              `${image.media.uuid}.${image.media.file?.name.split(".").pop()}`
            );
          });
        // Make axios request
        axios
          .post(endpoint, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(response.success)
          .catch(response.error)
          .finally(response.finally);
      }
    } else {
      // Alert user if noe contract is found
      addOverlay("self_popout_self_close", (close) => {
        return (
          <div>
            <h1>Contract not found</h1>
            <button onClick={close}>Close</button>
          </div>
        );
      });
      // Reject promise
      reject(false);
    }
  });
}
