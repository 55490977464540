import { ReactNode } from "react";
import styles from "./overlay.module.scss";

type Overlay = {
  // Overlay content
  children: ReactNode;
  // Whether to greyout the background
  greyout?: boolean;
  // Z-index for the overlay
  zIndex?: number;
  // Whether to display the overlay
  display?: boolean;
};

/**
 * Overlay layout element
 *
 * @author 					Pætur Mortensen
 */
export default function Overlay({ children, greyout = true, zIndex = 11, display = true }: Overlay): JSX.Element {
  // Init classnames for the overlay
  let classNames = styles.container;

  // If the overlay should have a greyout background, add its class
  if (greyout) {
    classNames += " " + styles.greyout;
  }

  // Init the inline styles for the overlay
  type InlineStyle = { zIndex: number; display?: string };
  const inlineStyle: InlineStyle = { zIndex };

  // If the overlay should be hidden, add the style to hide
  if (!display) {
    inlineStyle.display = "none";
  }

  return (
    <div className={classNames} style={inlineStyle}>
      {children}
    </div>
  );
}
