import WhatsonNavigation from "../WhatsonNavigation";
import enFlag from "../../../../../src/assets/images/en.png";
import foFlag from "../../../../../src/assets/images/fo.png";
import { VfiCheckbox2 as VfiCheckbox } from "../../../../assets/VfiCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import { slugify } from "../../../../assets/helperFunctions";
import Verify from "../../../../assets/VerifyMethods";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";

/**
 * Description part of the create place form
 *
 * @param 		{object} 		parent 												Parent object
 *
 * @returns 	{JSX.Element}
 */
export default function Description({ parent }) {
  return (
    <div className="new-content one">
      <WhatsonNavigation
        language={parent.props.language}
        indexChange={parent.props.indexChange}
        isFront={parent.props.isFront}
        undoAndRedoDisabled={true}
        forwardDisabled={false}
        backwardDisabled={true}
        saveDraft={parent.addOrUpdatePlace}
      />
      <h1>{parent.language.info.header}</h1>
      <p>{parent.language.info.sub_text}</p>
      {parent.props.isFront && parent.props.submitChange === "true" && (
        <i>
          <p dangerouslySetInnerHTML={{ __html: parent.submitDescription }} />
        </i>
      )}
      <div className="fill-form">
        <LanguageBlock language="english" parent={parent} />
        <div className="space-between">
          <div className="space-between-line" />
        </div>
        <LanguageBlock language="faroese" parent={parent} />
      </div>
    </div>
  );
}

/**
 * Render a language block
 *
 * @param 		{string} 		language 									Language to render
 * @param 		{object} 		parent 										Parent object
 *
 * @returns 	{JSX.Element}
 */
function LanguageBlock({ language, parent }) {
  const langKey = language === "english" ? "textEn" : "textFo";
  const showLang = parent.newPlace[langKey].showIt;

  return (
    <div className={`language-box ${language}` + (showLang ? " active" : "")}>
      {parent.changes.title.includes(`${showLang}.showIt`) && (
        <BtnCurrentSubmitted parent={parent} langKey={langKey} type="showIt" />
      )}
      <img
        className="flag"
        src={language === "english" ? enFlag : foFlag}
        alt={language === "english" ? "en-flag" : "fo-flag"}
      />
      <VfiCheckbox
        className="l-include"
        checked={showLang}
        onChange={(e) => {
          parent.newPlace[langKey].showIt = e.target.checked;
          parent.setState({});
        }}
      />
      <HeadlineRow parent={parent} showLang={showLang} langKey={langKey} />
      <ExcerptRow parent={parent} showLang={showLang} langKey={langKey} />
      <DescriptionRow parent={parent} showLang={showLang} langKey={langKey} />
      <MapSummaryRow parent={parent} showLang={showLang} langKey={langKey} />
      <CardSummaryRow parent={parent} showLang={showLang} langKey={langKey} />
    </div>
  );
}

/**
 * Render the headline row
 *
 * @param 			{object} 		parent 												Parent object
 * @param 			{boolean} 	showLang 											Whether to show the language
 * @param 			{string} 		langKey 											Language key
 *
 * @returns 		{JSX.Element}
 */
function HeadlineRow({ parent, showLang, langKey }) {
  const submitChange = parent.props.submitChange === "true";
  const changes = parent.changes;
  const tooltipContent = "Keep it short and sweet.";
  const currentSuggested = parent.changes.currentOrSuggested[parent.changes.title.indexOf(`${langKey}.headline`)];
  const headerKey = langKey === "textEn" ? "headline_en" : "headline_fo";

  return (
    <div className="headline">
      {submitChange && <BtnSubmitChange parent={parent} langKey={langKey} type="headline" />}
      {parent.changes.title.includes(`${langKey}.headline`) && (
        <BtnCurrentSubmitted parent={parent} langKey={langKey} type="headline" />
      )}
      <FieldHeader parent={parent} headerKey={headerKey} content={tooltipContent} />
      <HeadlineInput parent={parent} type={currentSuggested} langKey={langKey} showLang={showLang} />
    </div>
  );
}

/**
 * Render the excerpt row
 *
 * @param 		{object} 		parent 										Parent object
 * @param 		{boolean} 	showLang 									Whether to show this language
 * @param 		{string} 		langKey 									Language key
 *
 * @returns 	{JSX.Element}
 */
function ExcerptRow({ parent, showLang, langKey }) {
  const submitChange = parent.props.submitChange === "true";
  const tooltipContent = (
    <>
      Your audience will see the introduction preceding the description on the place page.
      <br />
      <br />
      Give a short introduction with an overview that naturally flows into the description field below.
    </>
  );
  const currentSuggested = parent.changes.currentOrSuggested[parent.changes.title.indexOf("textEn.shortDescription")];
  const headerKey = langKey === "textEn" ? "excerpt_en" : "excerpt_fo";

  return (
    <div className="small-description">
      {submitChange && <BtnSubmitChange parent={parent} langKey={langKey} type="shortDescription" />}
      {parent.changes.title.includes(`${langKey}.shortDescription`) && (
        <BtnCurrentSubmitted parent={parent} langKey={langKey} type="shortDescription" />
      )}
      <FieldHeader parent={parent} headerKey={headerKey} content={tooltipContent} />
      <ExcerptInput parent={parent} type={currentSuggested} langKey={langKey} showLang={showLang} />
    </div>
  );
}

/**
 * Description input row
 *
 * @param 			{object} 		parent 											Parent object
 * @param 			{boolean} 	showLang 										Whether to show this language
 * @param 			{string} 		langKey  										Language key
 *
 * @returns 		{JSX.Element}
 */
function DescriptionRow({ parent, showLang, langKey }) {
  const submitChange = parent.props.submitChange === "true";
  const tooltipContent = (
    <>
      Your audience will see the description on the place page.
      <br />
      <br />
      It's a good idea to start with a general overview of your place, followed by details. Keep the description light
      and engaging, and try to think about your audience's perspective.
    </>
  );
  const headerKey = langKey === "textEn" ? "description_en" : "description_fo";
  const currentSuggested = parent.changes.currentOrSuggested[parent.changes.title.indexOf(`${langKey}.description`)];

  return (
    <div className="description">
      {submitChange && <BtnSubmitChange parent={parent} langKey={langKey} type="description" />}
      {parent.changes.title.includes(`${langKey}.description`) && (
        <BtnCurrentSubmitted parent={parent} langKey={langKey} type="description" />
      )}
      <FieldHeader parent={parent} headerKey={headerKey} content={tooltipContent} />
      <DescriptionInput parent={parent} type={currentSuggested} langKey={langKey} showLang={showLang} />
    </div>
  );
}

/**
 * Map summary input row
 *
 * @param 		{object} 		parent 												Parent object
 * @param 		{boolean} 	showLang 											Whether to show the language
 * @param 		{string} 		langKey 											Language key
 *
 * @returns 	{JSX.Element}
 */
function MapSummaryRow({ parent, showLang, langKey }) {
  const submitChange = parent.props.submitChange === "true";
  const headerKey = langKey === "textEn" ? "mapSummary_en" : "mapSummary_fo";
  const tooltipContent = "Your audience will see this summary of the place in the map overview.";
  const currentSuggested = parent.changes.currentOrSuggested[parent.changes.title.indexOf(`${langKey}.mapSummary`)];

  return (
    <div className="map-summary">
      {submitChange && <BtnSubmitChange parent={parent} langKey={langKey} type="mapSummary" />}
      {parent.changes.title.includes(`${langKey}.mapSummary`) && (
        <BtnCurrentSubmitted parent={parent} langKey={langKey} type="mapSummary" />
      )}
      <FieldHeader parent={parent} headerKey={headerKey} content={tooltipContent} />
      <MapSummaryInput parent={parent} type={currentSuggested} langKey={langKey} showLang={showLang} />
    </div>
  );
}

/**
 * Car summary input row
 *
 * @param 			{object} 		parent 											Parent object
 * @param 			{boolean}		showLang 										Whether to show the language
 * @param 			{string} 		langKey 										Language key
 *
 * @returns  		{JSX.Element}
 */
function CardSummaryRow({ parent, showLang, langKey }) {
  const submitChange = parent.props.submitChange === "true";
  const headerKey = langKey === "textEn" ? "cardSummary_en" : "cardSummary_fo";
  const tooltipContent =
    "This text will only be shown on the place module in Articles that " + "appear on the website and on info screens.";
  const currentSuggested = parent.changes.currentOrSuggested[parent.changes.title.indexOf(`${langKey}.cardSummary`)];

  return (
    <div className="card-summary">
      {submitChange && <BtnSubmitChange parent={parent} langKey={langKey} type="cardSummary" />}
      {parent.changes.title.includes(`${langKey}.cardSummary`) && (
        <BtnCurrentSubmitted parent={parent} langKey={langKey} type="cardSummary" />
      )}
      <FieldHeader parent={parent} headerKey={headerKey} content={tooltipContent} />
      <CardSummaryInput parent={parent} type={currentSuggested} langKey={langKey} showLang={showLang} />
    </div>
  );
}

/**
 * Input for the card summary field
 *
 * @param 		{object} 		parent 												Parent object
 * @param 		{string} 		type 													"suggested" or "current"
 * @param 		{string} 		langKey 											Language key
 * @param 		{boolean} 	showLang 											Whether to show the language
 *
 * @returns 	{JSX.Element}
 */
function CardSummaryInput({ parent, type, langKey, showLang }) {
  const cardSummaryKey = langKey === "textEn" ? "cardSummary_en" : "cardSummary_fo";
  const changes = parent.changes;
  const submitChange = parent.props.submitChange === "true";

  return (
    <VfiTextarea
      maxLength={80}
      placeholder={parent.language.info[cardSummaryKey]}
      onChange={(e) => {
        if (type === "suggested") {
          changes.newValues[changes.title.indexOf(`${langKey}.cardSummary`)] = e;
        } else {
          parent.newPlace[langKey].cardSummary = e;
        }
        parent.setState({
          beenEdit: {
            ...parent.state.beenEdit,
            [langKey]: {
              ...parent.state.beenEdit[langKey],
              cardSummary: true,
            },
          },
        });
      }}
      value={
        type === "suggested"
          ? changes.newValues[changes.title.indexOf(`${langKey}.cardSummary`)]
          : parent.newPlace[langKey].cardSummary
      }
      errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
      hideError={!parent.state.beenEdit[langKey].cardSummary || !showLang}
      disabled={submitChange ? !parent.submit.includes(`${langKey}.cardSummary`) || !showLang : !showLang}
    />
  );
}

/**
 * Input for the map summary field
 *
 * @param 		{object} 		parent 												Parent object
 * @param 		{string} 		type 													"suggested" or "current"
 * @param 		{string} 		langKey 											Language key
 * @param 		{boolean} 	showLang 											Whether to show the language
 *
 * @returns 	{JSX.Element}
 */
function MapSummaryInput({ parent, type, langKey, showLang }) {
  const mapSummaryKey = langKey === "textEn" ? "mapSummary_en" : "mapSummary_fo";
  const changes = parent.changes;
  const submitChange = parent.props.submitChange === "true";

  return (
    <VfiTextarea
      maxLength={300}
      placeholder={parent.language.info[mapSummaryKey]}
      onChange={(e) => {
        if (type === "suggested") {
          changes.newValues[changes.title.indexOf(`${langKey}.mapSummary`)] = e;
        } else {
          parent.newPlace[langKey].mapSummary = e;
        }
        parent.setState({
          beenEdit: {
            ...parent.state.beenEdit,
            [langKey]: {
              ...parent.state.beenEdit[langKey],
              mapSummary: true,
            },
          },
        });
      }}
      value={
        type === "suggested"
          ? changes.newValues[changes.title.indexOf(`${langKey}.mapSummary`)]
          : parent.newPlace[langKey].mapSummary
      }
      errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
      hideError={!parent.state.beenEdit[langKey].mapSummary || !showLang}
      disabled={submitChange ? !parent.submit.includes(`${langKey}.mapSummary`) || !showLang : !showLang}
    />
  );
}

/**
 * Description input
 *
 * @param 			{object} 		parent 									Parent object
 * @param 			{string} 		type 										"suggested" or "current"
 * @param 			{string} 		langKey 								Language key
 * @param 			{boolean} 	showLang 								Whether to show the language
 *
 * @returns 		{JSX.Element}
 */
function DescriptionInput({ parent, type, langKey, showLang }) {
  const descriptionKey = langKey === "textEn" ? "description_en" : "description_fo";
  const changes = parent.changes;
  const submitChange = parent.props.submitChange === "true";

  return (
    <VfiTextarea
      maxLength={3000}
      placeholder={parent.language.info[descriptionKey]}
      onChange={(e) => {
        if (type === "suggested") {
          changes.newValues[changes.title.indexOf(`${langKey}.description`)] = e;
        } else {
          parent.newPlace[langKey].description = e;
        }
        parent.setState({
          beenEdit: {
            ...parent.state.beenEdit,
            [langKey]: {
              ...parent.state.beenEdit[langKey],
              description: true,
            },
          },
        });
      }}
      value={
        type === "suggested"
          ? changes.newValues[changes.title.indexOf(`${langKey}.description`)]
          : parent.newPlace[langKey].description
      }
      errorCheckSequence={[Verify.notEmpty, Verify.least70Char]}
      hideError={!parent.state.beenEdit[langKey].description || !showLang}
      disabled={submitChange ? !parent.submit.includes(`${langKey}.description`) || !showLang : !showLang}
    />
  );
}

/**
 * Excerpt input field
 *
 * @param 		{object} 			parent 									Parent object
 * @param 		{string} 			type 										"suggested" or "current"
 * @param 		{string} 			langKey 								Language key
 * @param 		{boolean} 		showLang 								Whether to show this language
 *
 * @returns 	{JSX.Element}
 */
function ExcerptInput({ parent, type, langKey, showLang }) {
  const excerptKey = langKey === "textEn" ? "excerpt_en" : "excerpt_fo";
  const changes = parent.changes;
  const submitChange = parent.props.submitChange === "true";

  return (
    <VfiTextarea
      maxLength={80}
      placeholder={parent.language.info[excerptKey]}
      onChange={(e) => {
        if (type === "suggested") {
          changes.newValues[changes.title.indexOf(`${langKey}.shortDescription`)] = e;
        } else {
          parent.newPlace[langKey].shortDescription = e;
        }
        parent.setState({
          beenEdit: {
            ...parent.state.beenEdit,
            [langKey]: {
              ...parent.state.beenEdit[langKey],
              shortDescription: true,
            },
          },
        });
      }}
      value={
        type === "suggested"
          ? changes.newValues[changes.title.indexOf(`${langKey}.shortDescription`)]
          : parent.newPlace[langKey].shortDescription
      }
      errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
      hideError={!parent.state.beenEdit[langKey].shortDescription || !showLang}
      disabled={submitChange ? !parent.submit.includes(`${langKey}.shortDescription`) || !showLang : !showLang}
    />
  );
}

/**
 * The input for the headline
 *
 * @param 			{object} 		parent 										Parent object
 * @param 			{string} 		type 											"suggested" or "current"
 * @param 			{string} 		langKey 									Language key for field
 * @param 			{bool} 			showLang 									Whether to show this language
 *
 * @returns 		{JSX.Element}
 */
function HeadlineInput({ parent, type, langKey, showLang }) {
  const changes = parent.changes;
  const headlineKey = langKey === "textEn" ? "headline_en" : "headline_fo";
  const submitChange = parent.props.submitChange === "true";

  return (
    <VfiInputText
      maxLength={50}
      value={
        type === "suggested"
          ? changes.newValues[changes.title.indexOf(`${langKey}.headline`)]
          : parent.newPlace[langKey].headline
      }
      onChange={(e) => {
        if (type === "suggested") {
          changes.newValues[changes.title.indexOf(`${langKey}.headline`)] = e.target.value;
          changes.newValues[changes.title.indexOf(`${langKey}.urlSlug`)] = slugify(e.target.value);
        } else {
          parent.newPlace[langKey].headline = e.target.value;
          parent.newPlace[langKey].urlSlug = slugify(e.target.value);
        }
        parent.setState({
          beenEdit: {
            ...parent.state.beenEdit,
            [langKey]: {
              ...parent.state.beenEdit[langKey],
              headline: true,
            },
          },
        });
      }}
      placeholder={parent.language.info[headlineKey]}
      errorCheckSequence={[Verify.notEmpty, Verify.least3Char]}
      hideError={!parent.state.beenEdit[langKey].headline || !showLang}
      disabled={submitChange ? !parent.submit.includes(`${langKey}.headline`) || !showLang : !showLang}
      onBlur={(e) => {
        const urlSlug =
          type === "suggested"
            ? [changes.newValues[changes.title.indexOf(`${langKey}.urlSlug`)]]
            : [parent.newPlace[langKey].urlSlug];

        parent.uniqueSlug(
          slugify(e.target.value),
          `${langKey}.urlSlug`,
          urlSlug,
          langKey === "textEn" ? "english" : "faroese"
        );
      }}
    />
  );
}

/**
 * Header for a field
 *
 * @param  		{object} 		parent 												Parent object
 * @param 		{string} 		headerKey 										Language key for header
 * @param 		{mixed} 		content 											String or JSX
 *
 * @returns 	{JSX.Element}
 */
function FieldHeader({ parent, headerKey, content }) {
  return (
    <div className="text with-tooltip">
      {parent.language.info[headerKey]}
      <GlobalToolTipController
        solid
        title={""}
        className={"tootlip-icon"}
        priority={"bottom,right,top,left"}
        toolTipElements={<div className={"tooltip-card"}>{content}</div>}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </GlobalToolTipController>
    </div>
  );
}

/**
 * Submit change button
 *
 * @param 		{object} 		parent 												Parent object
 * @param 		{string} 		langKey 											Language key
 * @param 		{string} 		type
 *
 * @returns 	{JSX.Element}
 */
function BtnSubmitChange({ parent, langKey, type }) {
  const submitChange = parent.props.submitChange === "true";

  return (
    <div
      className={"enable_editing " + (parent.submit.includes(`${langKey}.${type}`) && "enabled")}
      onClick={(e) => {
        if (submitChange && !parent.submit.includes(`${langKey}.${type}`)) {
          parent.submit.push(`${langKey}.${type}`);
          if (type === "headline") {
            parent.submit.push(`${langKey}.urlSlug`);
          }
        }
        parent.setState({});
      }}
    >
      Enable editing
      <FontAwesomeIcon icon={faPencil} />
    </div>
  );
}

/**
 * Current and submitted buttons
 *
 * @param 		{parent} 		object  											Parent object
 * @param 		{string} 		langKey 											Language key
 * @param 		{string} 		type													"current" or "suggested"
 *
 * @returns 	{JSX.Element}
 */
function BtnCurrentSubmitted({ parent, langKey, type }) {
  const changes = parent.changes;

  return (
    <div className="btn_current_submitted">
      <span
        className={
          changes.currentOrSuggested[changes.title.indexOf(`${langKey}.${type}`)] === "current" ? "choosen btn" : "btn"
        }
        onClick={() => {
          changes.currentOrSuggested[changes.title.indexOf(`${langKey}.${type}`)] = "current";
          if (type === "headline") {
            changes.currentOrSuggested[changes.title.indexOf(`${langKey}.urlSlug`)] = "current";
          }
          parent.updateEditedFields(`${langKey}.${type}`);
          if (type === "headline") {
            parent.updateEditedFields(`${langKey}.urlSlug`);
          }
          parent.setState({});
        }}
      >
        Current
      </span>
      <span
        className={
          changes.currentOrSuggested[changes.title.indexOf(`${langKey}.${type}`)] === "suggested"
            ? "choosen btn"
            : "btn"
        }
        onClick={() => {
          changes.currentOrSuggested[changes.title.indexOf(`${langKey}.${type}`)] = "suggested";
          if (type === "headline") {
            changes.currentOrSuggested[changes.title.indexOf(`${langKey}.urlSlug`)] = "suggested";
          }
          parent.updateEditedFields(`${langKey}.${type}`);
          if (type === "headline") {
            parent.updateEditedFields(`${langKey}.urlSlug`);
          }
          parent.setState({});
        }}
      >
        Suggested changes
      </span>
    </div>
  );
}
