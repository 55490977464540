import { useState } from "react";
import "../Shop.scss";
import { twoDigits } from "../../../../assets/helperFunctions";
import styles from "../Payment.module.scss";
import { cloneDeep } from "lodash";

export default function OrderLines({ data: d, editable, giveChangedData = () => void 0 }) {
  const [data, setData] = useState(cloneDeep(d));

  return (
    <div className={styles["right-side-of-user-info"]}>
      <div className={`${styles.orders} ${styles.box}`}>
        <div className={`${styles["orders-head"]} ${styles["order-id"]}`}>ID</div>
        <div className={`${styles["orders-head"]} ${styles["order-amount"]}`}>Amount</div>
        <div className={`${styles["orders-head"]} ${styles["order-name"]}`}>Name</div>
        <div className={`${styles["orders-head"]} ${styles["order-price-per"]}`}>Price per</div>
        <div className={`${styles["orders-head"]} ${styles["order-price"]}`}>Price excl MVG</div>
        {data.binds.map((orderBind, i) => {
          const { product_id, service_id, price_pr, amount, product_info, service_info } = orderBind;

          const orderType = product_id ? "product" : service_id ? "service" : undefined;

          const name =
            orderType === "product"
              ? `${product_info.spP_label} - ${product_info.spV_label}`
              : orderType === "service"
              ? `${service_info.Title} - ${service_info.Description}`
              : undefined;

          return [
            <div key={`${orderBind.id}id`} className={`${styles[orderType]} ${styles["order-id"]}`}>
              {orderBind.id}
            </div>,
            <div key={`${orderBind.id}amount`} className={`${styles[orderType]} ${styles["order-name"]}`}>
              <div className={styles["text-input"]}>
                <input
                  className={"no-label"}
                  defaultValue={orderBind.amount}
                  type="number"
                  disabled={!editable}
                  onChange={(e) => {
                    data.binds[i].amount = e.target.value;
                    setData({ ...data });
                    giveChangedData(`binds.${i}.amount`, e.target.value);
                  }}
                />
              </div>
            </div>,
            <div key={`${orderBind.id}name`} className={`${styles[orderType]} ${styles["order-name"]}`}>
              {name}
            </div>,
            <div key={`${orderBind.id}price-pr`} className={`${styles[orderType]} ${styles["order-price-pr"]}`}>
              <div className={`${styles["text-input"]} ${styles["margin-right"]}`}>
                <input
                  className={`no-label`}
                  defaultValue={parseFloat(price_pr)}
                  disabled={!editable}
                  onChange={(e) => {
                    e.target.value = twoDigits(e);
                    data.binds[i].price_pr = e.target.value;
                    setData({ ...data });
                    giveChangedData(`binds.${i}.price_pr`, e.target.value);
                  }}
                />
              </div>
              <div className={styles.valuta}>kr</div>
            </div>,
            <div key={`${orderBind.id}price`} className={`${styles[orderType]} ${styles["order-price"]}`}>
              <div className={styles.amount}>{(parseFloat(price_pr) * amount * 0.8).toFixed(2)}</div>
              <div className={styles.valuta}>kr</div>
            </div>,
          ];
        })}
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]} ${styles["order-total"]}`}>Total excl MVG</div>
        <div className={`${styles["orders-foot"]} ${styles["order-total"]}`}>
          {data.binds
            .reduce((acc, cur) => {
              return acc + cur.amount * parseFloat(cur.price_pr) * 0.8;
            }, 0)
            .toFixed(2)}{" "}
          kr
        </div>
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]} ${styles["order-total"]}`}>MVG 25%</div>
        <div className={`${styles["orders-foot"]} ${styles["order-total"]}`}>
          {data.binds
            .reduce((acc, cur) => {
              return acc + cur.amount * parseFloat(cur.price_pr) * 0.2;
            }, 0)
            .toFixed(2)}{" "}
          kr
        </div>
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]}`} />
        <div className={`${styles["orders-foot"]} ${styles["order-total"]}`}>Total</div>
        <div className={`${styles["orders-foot"]} ${styles["order-total-result"]}`}>
          <div className={styles.amount}>
            {data.binds
              .reduce((acc, cur) => {
                return acc + cur.amount * parseFloat(cur.price_pr);
              }, 0)
              .toFixed(2)}
          </div>
          <div className={styles.valuta}>kr</div>
        </div>
      </div>
    </div>
  );
}
