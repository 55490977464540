import type { articleType, section } from "./types";

export function getArticlesSections(articleTypes: articleType[]): section[] {
  return [
    // {
    //   title: "Inspiration",
    //   views: [
    //     "Articles",
    //     "Podcasts"
    //   ],
    // },

    {
      title: "Infoscreens",
      views: [
        {
          name: "See & Do",
          key: "infoscreens1",
          checkType: "articleTypes",
          checkId: articleTypes.find((articleType) => articleType.key === "infoscreens")?.id,
        },
        {
          name: "Eat & Drink",
          key: "infoscreens2",
          checkType: "articleTypes",
          checkId: articleTypes.find((articleType) => articleType.key === "infoscreens")?.id,
        },
        {
          name: "Transport",
          key: "infoscreens3",
          checkType: "articleTypes",
          checkId: articleTypes.find((articleType) => articleType.key === "infoscreens")?.id,
        },
      ],
    },
    // {
    //     title: "Other",
    //     views: [
    //       {name:"Inspiration",key:"inspiration"},
    //       {name:"Podcasts",key:"podcasts"},
    //     ],
    //   },
  ];
}
