import axios from "axios";
import { useWhatsonStore } from "src/Components/ZustandStores/whatsonStore";
import env from "src/environment.json";
import { TWhatsonLangsPartialObj, WHATSON_LANGUAGE } from "src/types";
import { TKeyWhatsonTypes, TWhatsonType } from "src/types/types/Whatson";
interface getSingleWhatsonProps {
  type: TWhatsonType;
  id: number;
}
export default function getSingleWhatson({ type, id }: getSingleWhatsonProps): Promise<any> {
  const endpoints: TKeyWhatsonTypes<string> = {
    event: "/api/public/whatson/events/GetEvent",
    place: "/api/public/whatson/places/GetPlace",
    tour: "/api/public/whatson/tours/GetTour.php",
    tour_provider: "/api/public/whatson/touroperators/GetTourOperator.php",
    hike: "/api/public/whatson/hiking/GetHiking.php",
  };
  const endpoint = endpoints[type];

  return new Promise((resolve, reject) => {
    axios
      .get(`${env.protocol}${env.env}${endpoint}?id=${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

interface previewSingleWhatsonProps {
  type: TWhatsonType;
  previewSlugs: TWhatsonLangsPartialObj<string>;
  lang: WHATSON_LANGUAGE;
}
export function previewSingleWhatson({ type, previewSlugs, lang }: previewSingleWhatsonProps) {
  useWhatsonStore
    .getState()
    .getWhatsonSiteInfo()
    .then((response) => {
      const website = (response?.protocol ?? "") + (response?.domain_name ?? "");
      const extraInPaths = {
        event: { middle: "/events/event/", params: "" },
        place: { middle: "/places/place/", params: "" },
        tour: { middle: "/tours/tour/", params: "" },
        // tour_provider: "/tour_providers/provider/",
        tour_provider: { middle: "/places/place/", params: "?tour_provider" },
        hike: { middle: "/hiking/hike/", params: "" },
      };
      const middlePath = extraInPaths[type].middle;
      const params = extraInPaths[type].params;
      const slug = previewSlugs?.[lang];
      window.open(website + middlePath + slug + params, "_blank")?.focus();
    });
}
