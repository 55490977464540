import { useState } from "react";
import styles from "./DateTypeSelect.module.scss";

interface Props {
  options: {
    value: string;
    type: string;
    label: string;
  }[];
  value: any;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  isDisabled: boolean;
}

const title_map: any = {
  single: "On a single day",
  multiple: "On multiple days",
};

export default function DateTypeSelect({
  options,
  value,
  onChange = (e) => {},
  onClick = (e) => {},
  isDisabled,
}: Props) {
  return (
    <div className={styles.container}>
      {Object.keys(title_map).map((type) => {
        let elements = options.filter((option) => option.type === type);
        return (
          <div key={type} className={`${styles.wrapper} ${elements.length <= 1 ? styles.single : ""}`}>
            <div className={styles.title}>{title_map[type]}</div>
            <div className={`${styles.elements}`}>
              {(elements as any).map((element: any) => {
                return (
                  <button
                    key={element.label}
                    className={`${styles.element} ${element.value === value?.value ? styles.selected : ""}`}
                    onClick={() => {
                      onClick(element);
                    }}
                  >
                    {element.label}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
