import React, { Component } from "react";
import env from "../../../../../environment.json";
import "./Panel.scss";
import globalObject from "../../../../../assets/globalVariables";
import ConfirmModal from "../../../../Modals/ConfirmModal";
import axios from "axios";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import { modulesConfig } from "src/utils";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeletePopup: false,
      currentNotificationSettings: {},
      updatedNotificationSettingsStatus: {},
    };
  }

  async updateNotificationSettings() {
    try {
      // Send the currentNotificationSettings to the server for updating
      const response = await axios.post(
        env.protocol + env.env + "/api/secured/notifications/update_notification_settings",
        {
          params: {
            userId: this.props.data.userId,
            notificationSettings: this.state.currentNotificationSettings,
          },
        }
      );

      const data = response.data;

      switch (response.status) {
        case 200:
          // Update the updatedNotificationSettings state with the response data
          this.setState({ updatedNotificationSettingsStatus: data });
          break;

        case 400:
          console.error("Invalid parameters supplied:", data.error);
          break;

        case 500:
          console.error("Server error:", data.error);
          break;

        default:
          console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Failed to update notification settings:", error);
    }
  }

  componentDidMount() {
    // If notifications are enabled, call the updateNotificationSettings function when the user clicks the update button
    if (modulesConfig.notifications.enabled) {
      this.props.onReadyToUpdate(() => this.updateNotificationSettings());
    }
  }

  render() {
    return (
      <div className="profile-content">
        <div className="profile-content-container">
          <div>
            <ul>
              <li className="settings-header">Settings</li>
            </ul>
            {parseInt(globalObject.vfiUser.superAdmin) && (
              <div className="revoke-access-setting">
                <div className="revoke-access-title">Revoke access</div>
                <div
                  className="revoke-access-btn"
                  onClick={() => {
                    this.setState({ showDeletePopup: true });
                  }}
                >
                  Delete user
                </div>
                {this.state.showDeletePopup && (
                  <ConfirmModal
                    close={() => {
                      this.setState({ showDeletePopup: false });
                    }}
                    data={this.props.data}
                    onConfirm={() => {
                      axios
                        .post(env.protocol + env.env + "/api/secured/deleteUser", {
                          userId: this.props.data.userId,
                        })
                        .then((res) => {
                          if (res.status === 200) {
                            this.props.setAccountStatus("accountStatus", "deleted");
                          }
                        })
                        .catch((error) => console.error(error));
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {modulesConfig.notifications.enabled && (
            <div className="notification-settings-container">
              <NotificationSettings
                onUpdateNotificationSettings={(value) => {
                  this.setState({ currentNotificationSettings: value });
                }}
                onCheckmarkChange={() => this.props.showUpdateBtn()}
                userId={this.props.data.userId}
              />
            </div>
          )}
          {globalObject.vfiUser.superAdmin === "1" && !env.env.includes("vita.fo") && (
            <div
              className="show-view"
              onClick={() => {
                sessionStorage.setItem("preview_user_id", this.props.data.id);
                sessionStorage.setItem("preview_user_profession", this.props.data.profession);

                this.props.close();
                this.props.SetState("lastUpdate", new Date().getTime());
              }}
            >
              Show user view
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Settings;
