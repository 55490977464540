import env from "src/environment.json";
import { safe } from "src/utils";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import styles from "./NewsletterUnsubscribe.module.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface props {
  token: string;
}

export default function NewsletterUnsubscribe(props: props) {
  const [clientError, setClientError] = useState("");
  const { token } = props;
  const [response, setResponse] = useState<AxiosResponse | undefined>(undefined);
  useEffect(() => {
    if (token === "") {
      setResponse(undefined);
      setClientError("Token missing");
    } else {
      safe(axios(`${env.protocol}${env.env}/api/public/newsletter/UnsubscribeAll.php?token=${token}`)).then(
        (response) => {
          if (response.success) {
            setResponse(response.data);
            setClientError("");
          } else {
            setResponse(undefined);
            setClientError(response.error?.message ?? "An unidedntified error happened");
          }
        }
      );
    }
  }, [token]);

  if (response === undefined) <FontAwesomeIcon icon={faSpinner as IconProp} spin />;

  return (
    <div className={styles["newsletter-unsubscribe"]}>
      {clientError !== "" ? <p className={styles.error}>{clientError}</p> : <></>}
      {response?.data.image ? <img src={response?.data.image} /> : <></>}
      <p dangerouslySetInnerHTML={{ __html: response?.data.message }}></p>
    </div>
  );
}
