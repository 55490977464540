import styles from "./PasswordTextGuide.module.scss";
export function PasswordTextGuide() {
  return (
    <div className={styles["password-text-guide"]}>
      <div>
        <ul>
          <li>8 characters minimum</li>
          <li>One lowercase character</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>One uppercase character</li>
          <li>One number and one special character</li>
        </ul>
      </div>
    </div>
  );
}
