import styles from "./OvalButton.module.scss";

function OvalButton({
  children,
  onClick,
  isActive,
  isError,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  isActive: boolean;
  isError?: boolean;
}) {
  const buttonClass = `${styles.button} ${isActive ? styles.active : ""} ${isError ? styles.error : ""}`;
  return (
    <button onClick={onClick} className={buttonClass}>
      {children}
    </button>
  );
}

export default OvalButton;
