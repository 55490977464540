import React from "react";
import env from "../../../../environment.json";
import styles from "./DomainOverlay.module.scss";
import { useSafeAxios, useSessionStorage } from "src/hooks";

function DomainOverlay() {
  const [isCollapsed, setIsCollapsed] = useSessionStorage<boolean>("domainBannerCollapsed", false);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const hasSubdomain = (url: string) => {
    // localhost/backend/src/
    if (url.includes("localhost")) return true;

    let parts = url.split(".");
    return parts.length > 2 && parts[0] !== "www";
  };

  const shouldShow = hasSubdomain(env.env);

  const {
    data: serverSubdomain,
    isLoading,
    error,
  } = useSafeAxios<string>(env.protocol + env.env + "/api/public/getServerSubdomain.php");

  if (isLoading) return <div>Loading...</div>;
  if (error) {
    console.error("An error occurred while fetching the subdomain:", error);
  }

  return (
    <>
      {shouldShow && !isLoading && serverSubdomain && (
        <div className={`${styles.domainBanner} ${isCollapsed ? styles.collapsed : ""}`} onClick={handleToggle}>
          <div className={styles.wrapper}>
            Server: <span className={styles.spanBold}>{serverSubdomain}</span> | Client:{" "}
            <span className={styles.spanBold}>{env.env}</span>
          </div>
        </div>
      )}
    </>
  );
}

export default DomainOverlay;
