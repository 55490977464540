import "../Product.scss";
import lodash, { isNull } from "lodash";

export default function InventoryCalculations({
  editInventory,
  setEditInventory,
  productState,
  props,
  saveProduct,
  prices,
}) {
  const vat = props.configuration.settings.find((x) => x.label === "VAT");

  const compare_numeric_prop = (calc, value) => {
    // Depending on the operator used for comparison
    switch (calc.operator) {
      case "eq":
        // Equals
        return calc.value == value;
      case "lt":
        // Less than
        return value < calc.value;
      case "gt":
        // Greater than
        return value > calc.value;
      case "lte":
        // Less than or equal to
        return value <= calc.value;
      case "gte":
        // Greater than or equal to
        return value >= calc.value;
      default:
        return false;
    }
  };

  const property_applies = (calc, varient) => {
    // Get property value from varient if set
    const value = varient[calc.property] ? varient[calc.property] : null;

    if (!value) {
      // Property is not set in varient, it doesn't apply, return FALSE
      return false;
    }

    // Depending on what type of property value we are working on...
    switch (calc.type) {
      case "numeric":
        // Numeric property value, compare to see if it applies
        return compare_numeric_prop(calc, value);
      case "boolean":
        const calcBool = calc.value === "true";
        const valBool = value === "true" || value == 1;
        return calcBool === valBool;
      default:
        // Any other property type applies if the values are equal
        return calc.value === value;
    }
  };

  const calc_applies_to_varient = (calc, varient) => {
    if (!lodash.isEmpty(calc.brand) && calc.brand.value !== varient["branduuid"]) {
      // Calculation branduuid is set and does NOT match varient, return FALSE

      return false;
    }

    if (!lodash.isEmpty(calc.product) && calc.product.value !== varient["productuuid"]) {
      // Calculation productuuid is set and does NOT match varient, return FALSE

      return false;
    }

    if (!lodash.isEmpty(calc.varient) && calc.varient.value !== varient["varientuuid"]) {
      // Calculation varientuuid is set and does NOT match varient, return FALSE

      return false;
    }

    // If calc has a property to compare...
    if (!lodash.isEmpty(calc.property)) {
      // Return whether the property applies to this varient
      return property_applies(calc.property, varient["properties"]);
    }

    // No unmatched values, calculation applies to varient, return TRUE
    return true;
  };

  const calcVAT = (sellingPrice) => {
    sellingPrice = Math.round(parseFloat(sellingPrice + (sellingPrice * vat.value) / 100) * 100) / 100;
    return sellingPrice;
  };

  return (
    <div className="right-content">
      <div className="cta">
        <div
          className="save"
          onClick={() => {
            saveProduct(productState);
            setEditInventory(null);
          }}
        >
          Save
        </div>
        <div
          className="close"
          onClick={() => {
            setEditInventory(null);
          }}
        >
          close
        </div>
      </div>
      <div className="wrap-price">
        <table>
          <tbody>
            <tr key="head-row">
              <th>Name</th>
              <th>Amount</th>
              <th></th>
              <th>Rate</th>
              <th>Total</th>
            </tr>
            {!isNull(editInventory) &&
              prices.map((price) => {
                editInventory.sellingPrice = Math.round(parseFloat(editInventory.sellingPrice) * 100) / 100;

                let isPrice = calc_applies_to_varient(price, editInventory);

                if (!isPrice) {
                  return "";
                }
                if (price.rateType.value === "fixed") {
                  let fixed = (
                    <tr key={"row" + price.id}>
                      <td key="label_fixed">{price.label}</td>
                      <td key="selling_fixed">
                        <div className="line"></div>
                        {editInventory.sellingPrice}{" "}
                      </td>
                      <td key="line1_fixed">
                        <div className="line"></div>+
                      </td>
                      <td key="line2_fixed">
                        <div className="line"></div>
                        {price.rate}{" "}
                      </td>
                      <td key="total_fixed">
                        <div className="line"></div>
                        <b>{Math.round(parseFloat(price.rate) * 100) / 100 + editInventory.sellingPrice}</b>
                      </td>
                    </tr>
                  );
                  editInventory.sellingPrice += price.rate;
                  return fixed;
                }
                if (price.rateType.value === "percent") {
                  let perc = (
                    <tr key={"row_" + price.id}>
                      <td>{price.label}</td>
                      <td key="selling_perc">
                        <div className="line"></div>
                        {editInventory.sellingPrice}{" "}
                      </td>
                      <td key="line1_perc">
                        <div className="line"></div>x
                      </td>
                      <td key="percent_perc">
                        <div className="line"></div>
                        {price.rate}%
                      </td>
                      <td key="total_perc">
                        <div className="line"></div>
                        <b>
                          {Math.round(
                            parseFloat(editInventory.sellingPrice + (editInventory.sellingPrice * price.rate) / 100) *
                              100
                          ) / 100}
                        </b>
                      </td>
                    </tr>
                  );

                  editInventory.sellingPrice += (price.rate / 100) * editInventory.sellingPrice;
                  return perc;
                }
              })}

            {!isNull(editInventory) && (editInventory.costPrice = Math.round(editInventory?.sellingPrice / 10) * 10)}

            {vat && (
              <tr>
                <td>{vat.label}</td>
                <td key="selling_perc">
                  <div className="line"></div>
                  {/* {editInventory?.sellingPrice} */}
                  {Math.round(parseFloat(editInventory?.sellingPrice) * 100) / 100}
                </td>
                <td key="line1_perc">
                  <div className="line"></div>x
                </td>
                <td key="percent_perc">
                  <div className="line"></div>
                  {vat.value}%
                </td>
                <td key="total_perc">
                  <div className="line"></div>
                  {!isNull(editInventory) && (editInventory.sellingPrice = calcVAT(editInventory?.sellingPrice))}
                </td>
              </tr>
            )}
            <tr key="rounding-row">
              <td key="title_round">Rounding to 10 </td>
              {[
                <td key="round_selling">
                  <div className="line"></div>
                  {Math.round(parseFloat(editInventory?.sellingPrice) * 100) / 100}
                </td>,
                <td key="line1_round">
                  <div className="line"></div>
                  <br />
                </td>,
                <td key="line2_round">
                  <div className="line"></div>
                  <br />
                </td>,
                <td key="total_round">
                  <div className="line"></div>
                  <b>{Math.round(editInventory?.sellingPrice / 10) * 10}</b>
                </td>,
              ]}
            </tr>
          </tbody>
        </table>

        <div>
          <h2>
            Selling Price <b>{Math.round(editInventory?.sellingPrice / 10) * 10} DKK</b>
          </h2>
        </div>
      </div>
    </div>
  );
}
