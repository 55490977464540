import "../EditProduct.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import Preloader from "src/assets/Preloader";
import Button from "src/Components/Buttons/Button";
import axios from "axios";
import env from "../../../../../environment.json";
import Select from "react-select";
import shopConfig from "../../shopConfig";

/**
 * Availability panel
 *
 * Set published state of product
 *
 * @param 		{object} 		product 											Product state
 * @param 		{function} 	setProduct 										Set product state
 * @param 		{object} 		productConfig 								Product config object
 * @param 		{function} 	recalculate_prices						Recalculate all prices
 *
 * @returns 	{jsx} 																		Available panel
 *
 * @author 					Pætur Mortensen
 */
export default function PricesPanel({ product, setProduct, productConfig, recalculate_prices }) {
  const emptyRuleForm = { rate: "", rateType: "percent", label: "" };
  const [isLoading, setIsLoading] = useState(false);
  const [priceRules, setPriceRules] = useState([]);
  const [addingRule, setAddingRule] = useState(false);
  const [ruleForm, setRuleForm] = useState(emptyRuleForm);

  // Options for the rate type select
  const rateTypeOptions = [
    { label: "Fixed", value: "fixed" },
    { label: "Percent", value: "percent" },
  ];

  useEffect(() => {
    const newRules = productConfig.priceRules.filter((priceRule) => priceRule.productuuid === product.productuuid);

    setPriceRules(newRules);
  }, []);

  /**
   * Add a price rule
   *
   * @author 					Pætur Mortensen
   */
  function add_price_rule() {
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/shop/AddProductPriceRule", {
        ...ruleForm,
        productuuid: product.productuuid,
        siteID: shopConfig.siteID,
      })
      .then((response) => {
        recalculate_prices();
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function delete_price_rule(uuid) {
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/shop/DeleteProductPriceRule", { uuid })
      .then((response) => {
        recalculate_prices();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  /***************************************************************************************************
   *
   * 																		RENDER
   *
   **************************************************************************************************/

  /**
   * Render the button to add a rule
   *
   * @author 					Pætur Mortensen
   */
  function render_add_rule_button() {
    return (
      <Button
        block
        onClick={() => {
          setAddingRule(true);
        }}
      >
        Add rule
      </Button>
    );
  }

  /**
   * Render the form to add a rule
   *
   * @author 					Pætur Mortensen
   */
  function render_add_rule_form() {
    return (
      <div>
        <input
          className="price-rule-label-input"
          value={ruleForm.label}
          placeholder="Rule name"
          onChange={(e) => setRuleForm({ ...ruleForm, label: e.target.value })}
        />
        <div className="form-input-container">
          <input
            className="rate-input"
            placeholder="rate"
            value={ruleForm.rate}
            onChange={(e) => setRuleForm({ ...ruleForm, rate: e.target.value })}
          />
          <Select
            options={rateTypeOptions}
            value={rateTypeOptions.find((option) => option.value === ruleForm.rateType)}
            onChange={(e) => {
              setRuleForm({ ...ruleForm, rateType: e.value });
            }}
          />
        </div>
        <div className="add-cp-btn-container">
          <Button
            type="secondary"
            onClick={() => {
              setAddingRule(false);
              setRuleForm(emptyRuleForm);
            }}
          >
            Cancel
          </Button>
          <Button onClick={add_price_rule}>Add</Button>
        </div>
      </div>
    );
  }

  /**
   * Render the proice rules for this product
   *
   * @returns 	{JSX} 												Price rules table
   *
   * @author 					Pætur Mortensen
   */
  function render_price_rules() {
    /**
     * Render a single price rule
     *
     * @param 		{object} 	rule 						Rule to render
     *
     * @returns 	{JSX} 										Price rule row
     *
     * @author 					Pætur Mortensen
     */
    function render_rule(rule) {
      // Extract and format rule data
      const rate = Math.abs(rule.rate);
      const rateSign = rule.rate >= 0 ? "+" : "-";
      const rateTypeSign = rule.rateType.value === "percent" ? "%" : ",-";

      return (
        <tr key={rule.uuid}>
          <td>{rule.label}</td>
          <td>
            {rateSign}
            {rate}
            {rateTypeSign}
          </td>
          <td>
            <FontAwesomeIcon className="delete-btn" icon={faTrashAlt} onClick={() => delete_price_rule(rule.uuid)} />
          </td>
        </tr>
      );
    }

    return (
      <table>
        <tbody>
          <tr>
            <th>Rule</th>
            <th>Rate</th>
            <th></th>
          </tr>
          {priceRules.map((rule) => {
            return render_rule(rule);
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div className="product-prices">
      <div>
        <h3>Product prices</h3>
        <p>Price adjustments for this product and all its variants</p>
      </div>
      <div className="product-price-rules">
        {priceRules.length === 0 ? "There are no price rules for this product" : render_price_rules()}
      </div>
      <div className="load-container">
        <Preloader show={isLoading} />
      </div>
      {addingRule ? render_add_rule_form() : render_add_rule_button()}
    </div>
  );
}
