import { StateCreator } from "zustand";
import { debounce } from "../utils/debounce";
import { IStep } from "../components/navigation/StepNavigation";
import { submitWhatson } from "../contracts/submit";

export interface NavigationSlice {
  type: "event" | "place";
  currentStepIndex: number;
  steps: IStep[];
  /**
   * Set the type
   *
   * @param type
   *
   * @returns void
   */
  setType: (type: "event" | "place") => void;
  /**
   * Set the current step index
   *
   * @param index
   *
   * @returns void
   */
  setCurrentStepIndex: (index: number) => void;
  /**
   * Go to the next step
   *
   * @returns void
   */
  goToNextStep: () => void;
  /**
   * Go to the previous step
   *
   * @returns void
   */
  goToPreviousStep: () => void;
  /**
   * Set the steps
   *
   * @param steps
   *
   * @returns void
   */
  setSteps: (steps: IStep[]) => void;
  /**
   * Reset the navigation slice
   */
  resetNavigationSlice: () => void;
}

const INITIAL_STATES = {
  type: "event" as "event" | "place",
  currentStepIndex: 0,
  steps: [],
};

/**
 * Create the navigation slice
 */
export const createNavigationSlice: StateCreator<NavigationSlice> = (set, get) => ({
  ...INITIAL_STATES,
  setType: (type) => set({ type }),
  setCurrentStepIndex: (index) => {
    // (async () => {ValidateAll()})();
    set({ currentStepIndex: index });
  },
  // Debounce the navigation actions to prevent rapid clicking
  goToNextStep: debounce(() => {
    const { currentStepIndex, steps } = get();
    // (async () => ValidateAll())();
    if (currentStepIndex >= steps.length - 1) {
      /** Call submit here !!! */
      submitWhatson(true);
    } else {
      set((state) => ({ currentStepIndex: state.currentStepIndex + 1 }));
    }
  }, 100),
  goToPreviousStep: debounce(() => {
    set((state) => {
      // (async () => {ValidateAll()})();
      return { currentStepIndex: state.currentStepIndex - 1 };
    });
  }, 100),
  setSteps: (steps) => set({ steps }),
  resetNavigationSlice: () => {
    set((state) => {
      // (async () => {ValidateAll()})();
      return {
        currentStepIndex: 0,
      };
    });
  },
});
