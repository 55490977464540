import { useState } from "react";
import PropTypes from "prop-types";
import ReactMapGl, { Marker } from "react-map-gl";
import mapMarkers from "../images/map-markers/map-markers";

export const defaultViewportValue = {
  latitude: 61.8926,
  longitude: -6.9118,
  zoom: 8,
  transitionDuration: 500,
};

export const getAddress = async (latitude, longitude) => {
  const response = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
  );
  const data = await response.json();
  if (data.features.length) {
    const address = data.features[0].place_name;
    return address;
  }
  return undefined;
};

function ReactMapGlOpen({
  defaultViewport = defaultViewportValue,
  width,
  height,
  onClick = () => void 0,
  children,
  className,
}) {
  const [viewport, setViewport] = useState(defaultViewport);

  return (
    <ReactMapGl
      {...viewport}
      className={className}
      mapStyle={"mapbox://styles/mapbox/light-v10"}
      width={isNaN(width) ? width : width + "px"}
      height={isNaN(height) ? height : height + "px"}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
      onViewStateChange={setViewport}
      onClick={onClick}
    >
      {children}
    </ReactMapGl>
  );
}

ReactMapGlOpen.propTypes = {
  defaultViewport: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
};

export default ReactMapGlOpen;
