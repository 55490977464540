import PropType from "prop-types";
import styles from "./OverlayElement.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties, ReactNode } from "react";

interface props {
  isOpen: boolean;
  onSave: () => void;
  onCancel?: () => void;
  children?: ReactNode;
  close?: () => void;
  saveText?: string;
  saveButtonStyle?: CSSProperties;
  cancelText?: string;
  cancelButtonStyle?: CSSProperties;
  boxWidth?: number;
}

function OverlayElement({
  onSave,
  onCancel,
  isOpen,
  children,
  close,
  saveText,
  saveButtonStyle,
  cancelText,
  cancelButtonStyle,
  boxWidth,
}: props) {
  if (!isOpen) return "";
  return (
    <div className={styles.overlayElement} onClick={close}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.overlayElementInside}
        style={{ width: boxWidth ? boxWidth + "px" : undefined }}
      >
        <button className={styles.closeButton} onClick={close}>
          <FontAwesomeIcon icon={faTimes as IconProp} />
        </button>
        {children}
        <div className={styles.lowerButtons}>
          <button className={styles.cancelButton} style={cancelButtonStyle} onClick={onCancel}>
            {cancelText ?? "CANCEL"}
          </button>
          <button className={styles.saveButton} style={saveButtonStyle} onClick={onSave}>
            {saveText ?? "SAVE"}
          </button>
        </div>
      </div>
    </div>
  );
}

// OverlayElement.propTypes = {
//   isOpen: PropType.bool.isRequired,
//   onSave: PropType.func,
//   onCancel: PropType.func,
//   close: PropType.func,
//   children: PropType.element,
//   saveText: PropType.string,
//   cancelText: PropType.string,
//   boxWidth: PropType.number,
// };

export default OverlayElement;
