import { faPlus } from "@fortawesome/pro-light-svg-icons";
import styles from "./LabelButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function LabelButton({ label, onClick, className }: { label: string; onClick: () => void; className?: string }) {
  const classNames = [styles.datePickerClearButton, className].join(" ");

  return (
    <button className={classNames} onClick={onClick}>
      <span className={styles.label}>{label}</span>
      <span className={styles.icon}>
        <FontAwesomeIcon icon={faPlus as IconProp} />
      </span>
    </button>
  );
}

export default LabelButton;
