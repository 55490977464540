import { StateCreator } from "zustand";
import { useWhatsonManager } from "./whatson-manager-store/WhatsonManagerStore";

export interface INonUser {
  name: string;
  email: string;
  terms: boolean;
}

export interface SaveTypes {
  auto: boolean;
  submit: boolean;
}

/**
 * General View Manager Slice
 */
export interface GeneralViewManagerSlice {
  containerWidth: number | null;
  containerHeight: number | null;

  saving: SaveTypes;

  submitted: boolean;

  /**
   * Internal
   *
   * Tells us wether the view is internally used in the CMS.
   */
  internal: boolean;
  nonUser?: INonUser;
  id?: number;
  editToken?: string;
  /**
   * Set the container width
   *
   * @param width
   *
   * @returns void
   */
  setContainerWidth: (width: number | null) => void;
  /**
   * Set non user
   *
   * @param non_user
   *
   * @returns void
   */
  setNonUser: (non_user: INonUser) => void;
  /**
   * Set saving flags
   *
   * @param flags
   *
   * @returns void
   */
  setSaving: (flags: Partial<SaveTypes>) => void;
  /**
   * Set submitted
   *
   * @param submitted
   *
   * @returns void
   */
  setSubmitted: (submitted: boolean) => void;
  /**
   * Set the content height
   *
   * @param height
   *
   * @returns void
   */
  setContentHeight: (height: number | null) => void;
  /**
   * Check if the view is a tablet
   *
   * @returns boolean
   */
  isTablet: () => boolean;
  /**
   * Check if the view is a mobile
   *
   * @returns boolean
   */
  isMobile: () => boolean;
  /**
   * Set edit token
   *
   * @param token
   *
   * @returns void
   */
  setEditToken: (token: string) => void;
  /**
   * Set internal
   *
   * @param internal
   *
   * @returns void
   */
  setInternal: (internal: boolean) => void;
  /**
   * Set Whatson id
   *
   * @param id
   *
   * @returns void
   */
  setId: (id: number) => void;
  /**
   * Reset Whatson Store
   */
  resetWhatsonStore: () => void;
}
/**
 * Tablet Breakpoint
 */
const TABLET_BREAKPOINT = 1279;
/**
 * Mobile Breakpoint
 */
const MOBILE_BREAKPOINT = 767;
/**
 * Create General View Manager Slice
 */
export const createGeneralViewManagerSlice: StateCreator<GeneralViewManagerSlice> = (set, get) => ({
  containerWidth: null,
  containerHeight: null,
  saving: {
    auto: false,
    submit: false,
  },
  submitted: false,
  // nonUser: null,
  internal: false,
  setEditToken: (editToken: string) => set({ editToken }),
  setContainerWidth: (width: number | null) => set({ containerWidth: width }),
  setContentHeight: (height: number | null) => set({ containerHeight: height }),
  setNonUser: (nonUser: INonUser) => set({ nonUser }),
  setSaving: (flags) => set((state) => ({ saving: { ...state.saving, ...flags } })),
  setSubmitted: (submitted) => set({ submitted }),
  isTablet: () => {
    const width = get().containerWidth;
    return width !== null && width <= TABLET_BREAKPOINT;
    // return width !== null && width <= TABLET_BREAKPOINT && width > MOBILE_BREAKPOINT;
  },
  isMobile: () => {
    const width = get().containerWidth;
    return width !== null && width <= MOBILE_BREAKPOINT;
  },
  setInternal: (internal) => set({ internal }),
  setId: (id) => set({ id }),
  resetWhatsonStore: () => {
    // Reset the store
    const {
      resetDateSlice,
      resetDescriptionSlice,
      resetLocationSlice,
      resetNavigationSlice,
      resetOrganizerSlice,
      resetMediaSlice,
      resetPriceSlice,
      resetTypeSlice,
      resetErrorSlice,
    } = useWhatsonManager.getState().getStore().getState();
    resetDateSlice();
    resetDescriptionSlice();
    resetLocationSlice();
    resetNavigationSlice();
    resetOrganizerSlice();
    resetMediaSlice();
    resetPriceSlice();
    resetTypeSlice();
    resetErrorSlice();
  },
});
