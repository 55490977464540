import { OrderLinesType, Bind } from "./types";
import styles from "./Orders.module.scss";
import { cloneDeep } from "lodash";

/**
 * Order lines pane
 *
 * @author 					Pætur Mortensen
 */
export default function OrderLines({ data, setData, editable }: OrderLinesType): JSX.Element {
  /***************************************************************************************************
   *
   * 																			RENDER
   *
   **************************************************************************************************/

  /**
   * Render the header for order binds
   */
  function render_binds_header(): JSX.Element {
    return (
      <>
        <div className={`${styles.ordersHead}`}>ID</div>
        <div className={`${styles.ordersHead}`}>Amount</div>
        <div className={`${styles.ordersHead}`}>Name</div>
        <div className={`${styles.ordersHead} ${styles.orderPricePer}`}>Price per</div>
        <div className={`${styles.ordersHead} ${styles.orderPrice}`}>Price excl MVG</div>
      </>
    );
  }

  /**
   * Render a single bind row
   */
  function render_bind_row(orderBind: Bind, i: number): JSX.Element {
    // Extract properties form the order bind
    const { id, product_id, service_id, price_pr, amount, product_info, service_info } = orderBind;

    // Set the order type
    const orderType = product_id ? "product" : service_id ? "service" : "N/A";

    // Set the order product/service name
    const name =
      orderType === "product"
        ? `${product_info.spP_label} - ${product_info.spV_label}`
        : orderType === "service"
        ? `${service_info.Title} - ${service_info.Description}`
        : "N/A";

    // Clone the bind for saving changes
    const newBinds = cloneDeep(data.binds);

    return (
      <>
        <div key={`${id}id`} className={`${styles[orderType]}`}>
          {id}
        </div>
        <div key={`${id}amount`} className={`${styles[orderType]}`}>
          <div className={styles.textInput}>
            <input
              className={styles.noLabel}
              value={orderBind.amount}
              type="number"
              disabled={!editable}
              onChange={(e) => {
                const value = Number(e.target.value);
                newBinds[i].amount = value;
                setData({ ...data, binds: newBinds });
              }}
            />
          </div>
        </div>
        <div key={`${id}name`} className={`${styles[orderType]}`}>
          {name}
        </div>
        <div key={`${id}price-pr`} className={`${styles[orderType]} ${styles.orderPricePr}`}>
          <div className={`${styles.textInput} ${styles.marginRight}`}>
            <input
              className={styles.noLabel}
              value={parseFloat(price_pr)}
              disabled={!editable}
              onChange={(e) => {
                newBinds[i].price_pr = e.target.value;
                setData({ ...data, binds: newBinds });
              }}
            />
          </div>
          <div className={styles.valuta}>kr</div>
        </div>
        <div key={`${id}price`} className={`${styles[orderType]} ${styles.orderPrice}`}>
          <div className={styles.amount}>{(parseFloat(price_pr) * amount * 0.8).toFixed(2)}</div>
          <div className={styles.valuta}>kr</div>
        </div>
      </>
    );
  }

  /**
   * Render the binds footer
   */
  function render_bind_footer(): JSX.Element {
    return (
      <>
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot} ${styles.orderTotal}`}>Total excl MVG</div>
        <div className={`${styles.orderFoot} ${styles.orderTotal}`}>
          {data.binds
            .reduce((acc, cur) => {
              return acc + cur.amount * parseFloat(cur.price_pr) * 0.8;
            }, 0)
            .toFixed(2)}
          &nbsp; kr
        </div>
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot} ${styles.orderTotal}`}>MVG 25%</div>
        <div className={`${styles.orderFoot} ${styles.orderTotal}`}>
          {data.binds
            .reduce((acc, cur) => {
              return acc + cur.amount * parseFloat(cur.price_pr) * 0.2;
            }, 0)
            .toFixed(2)}
          &nbsp; kr
        </div>
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot}`} />
        <div className={`${styles.orderFoot} ${styles.orderTotal}`}>Total</div>
        <div className={`${styles.orderFoot} ${styles.orderTotalResult}`}>
          <div className={styles.amount}>
            {data.binds
              .reduce((acc, cur) => {
                return acc + cur.amount * parseFloat(cur.price_pr);
              }, 0)
              .toFixed(2)}
          </div>
          <div className={styles.valuta}>kr</div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.rightSideOfUserInfo}>
      <div className={`${styles.orders} ${styles.box}`}>
        {render_binds_header()}

        {data.binds.map((orderBind, i) => render_bind_row(orderBind, i))}

        {render_bind_footer()}
      </div>
    </div>
  );
}
