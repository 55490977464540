import styles from "./SimpleCheckbox.module.scss";

interface SimpleCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  labelClassName?: string;
}

export default function SimpleCheckbox({
  id,
  checked,
  defaultChecked,
  onChange,
  label,
  className,
  labelClassName,
  style,
  ...restProps
}: SimpleCheckboxProps) {
  const checkboxContainerClasses = `${styles.checkboxContainer} ${className || ""}`;

  return (
    <div className={checkboxContainerClasses} style={style}>
      <input
        type="checkbox"
        id={id}
        className={styles.checkboxInput}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        {...restProps}
      />
      <label htmlFor={id} className={styles.checkboxLabel}>
        <div className={styles.checkbox}></div>
        {/* {label && <div className={styles.checkboxLabelText}>{label}</div>} */}
        {label && <div className={`${styles.checkboxLabelText} ${labelClassName}`}>{label}</div>}
      </label>
    </div>
  );
}
