import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import {
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faCopy,
  faEye,
  faHandPointer,
  faSquare,
  faTrashAlt,
  faEyeSlash,
  faClone,
} from "@fortawesome/pro-light-svg-icons";
import languages from "./language.json";
import cloneDeep from "lodash.clonedeep";
import { defaultSliderParams } from "../Overlay/PageModuleChoose/PageModuleChoose";
import { orderPage, array_move, forceCheckLanguage } from "./../../../assets/helperFunctions";
import { getPageId } from "./utils";
import { pagesStore } from "src/Components/ZustandStores/pages-store/pagesStore";

export function buildTemplate(templateListObj) {
  return {
    languages: ["en"],
    modules: templateListObj.types.map((f, i) => {
      switch (f) {
        case "visit_faroe_islands_top": {
          return {
            type: "visit_faroe_islands_top",
            data: [
              {
                bottom_icon: "2",
                media: {},
                title_and_description: "light_text",
                link_option: "page",
                key: 1,
              },
            ],
            languages: [{}],
            is_hero: templateListObj.extra_data[i].is_hero,
            show_icons: true,
          };
        }
        case "social": {
          return {
            type: "social",
            data: {},
            languages,
          };
        }
        case "ckeditor_5":
        case "ckeditor_5_excerpt":
        case "first_letter_big": {
          return {
            type: f, // Can be ckeditor_5, ckeditor_5_excerpt or first_letter_big.
            languages: {
              blocks:
                "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
            },
            moduleOptions: [{ is: "hr" }],
          };
        }
        case "related_and_text": {
          return {
            pageRelatedId: undefined,
            type: "related_and_text",
            languages: {
              blocks:
                "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
            },
            moduleOptions: [{ is: "hr" }],
          };
        }

        case "text_and_expand": {
          return {
            type: "text_and_expand",
            languages: [
              {
                blocks:
                  "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
              },
              {
                blocks:
                  "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
              },
            ],
            moduleOptions: [{ is: "hr" }],
          };
        }

        case "read_also": {
          let languages = {};
          return { type: "read_also", languages, pageId: undefined };
        }

        case "media_with_subtext_and_text": {
          return {
            type: "media_with_subtext_and_text",
            languages: [
              {
                blocks:
                  "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
              },
              {
                blocks:
                  "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
              },
            ],
          };
        }

        case "media": {
          return {
            type: "media",
            size: "small",
            isVideoUrl: false,
            videoUrl: "",
            videoUrlParams: {},
            caption: "none",
            captionText: "",
            languages: {},
          };
        }

        case "newsletter": {
          return {
            type: "newsletter",
            selectedTypes: [],
            languages: {
              headline: "GET THE NEWEST ARTICLES ABOUT FAROE ISLANDS",
            },
          };
        }

        case "carousel": {
          return {
            ...defaultSliderParams(),
            is_hero: templateListObj.extra_data[i].is_hero,
            galleryType: templateListObj.extra_data[i].galleryType,
          };
        }
        case "media_grid": {
          return {
            type: "media_grid",
            languages: [],
            media: [],
            preset_number: 1,
            grid_type: templateListObj.extra_data[i].grid_type,
          };
        }

        case "whatson_module": {
          return {
            type: "whatson_module",
            languages: {},
            what_is_on: "events",
            regions: [],
          };
        }

        case "whatson_card":
        case "whatson_list":
          return {
            type: f,
            whatson_list: [],
          };
        case "download_module":
          return {
            type: "download_module",
            data: [1, 2, 3, 4].map((nr) => {
              return {
                key: nr,
                button_label: "BUTTON LABEL",
                link_option: "download",
                page_url: "",
                shared: false,
                subtitle: "Sub headline",
                title: "Download module headline",
              };
            }),
            download_type: "grid",
            shown_amount: 1,
            ...templateListObj.extra_data[i], // possibly replaces 'data','download_type' and 'shown_amount'
          };
        default:
          // eslint-disable-next-line
          return;
      }
    }),
  };
}

export default function Page(props) {
  const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";
  const lang = languages[language].pages_list;

  const { view_interact_type } = pagesStore();

  // const{canMoveUp, canMoveDown} = props;
  const { selectedLanguage, templateList } = props;
  let finalTemplateList = cloneDeep(templateList) ?? [];
  finalTemplateList.forEach((e, i) => {
    finalTemplateList[i].onClick = () => {
      const values = buildTemplate(e);
      props.newTemplatePage(props.values.site_id, props.values.id, values);
    };
  });

  var title = props.values.page_name;
  const pageLanguages = props.values.languages;

  if (pageLanguages) {
    if (pageLanguages[language]) {
      if (pageLanguages[language].title !== "") {
        title = pageLanguages[language].title;
      } else {
        const alternate = forceCheckLanguage(pageLanguages).title;
        title = alternate ? alternate : title;
      }
    } else {
      const alternate = forceCheckLanguage(pageLanguages).title;
      title = alternate ? alternate : title;
    }
  }

  if (selectedLanguage) {
    const foundSelectedLanguage = Object.keys(props.values.languages).find((e) => e === selectedLanguage.value);

    if (foundSelectedLanguage) {
      title = props.values.languages[foundSelectedLanguage].title;
    }
  }
  var clear;
  const clickPosition = (e) => {
    return { x: e.pageX, y: e.pageY };
  };

  const pageClickToMark = () => {
    const { id, shared, languages, page_name } = props.values;
    props.setPageSelected(id, shared);
    if (!shared) {
      props.pageSelect(
        id,
        languages
          ? languages[language]
            ? languages[language].title
            : forceCheckLanguage(languages)
            ? forceCheckLanguage(languages).title
            : page_name
          : page_name,
        props.pageId,
        props.siteId
      );
    }
  };

  return (
    <div
      key={props.values.id}
      className={
        "page" +
        (props.values.id === props.pageSelected && !!props.values.shared === !!props.pageSelectedShared
          ? " selected"
          : "") +
        (props.optionsListPageSelected === props.values.id ? " page-menu-open" : "")
      }
    >
      {selectedLanguage ? (
        Object.keys(props.values.languages).find((e) => e === selectedLanguage.value) ? (
          <div className="short-lang">{selectedLanguage.label}</div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <span className={"title" + (props.values.current_status === "draft" ? " is-draft" : "")}>{title}</span>

      <div
        className="default-button"
        onDoubleClick={() => {
          if (props.selectPage) {
            props.selectPage(props.values);
          } else {
            const id = getPageId(props.values);
            props.editPage(id, !!props.values.shared);
          }
        }}
        onClick={(e) => {
          const click = e.detail;

          if (click === 1) {
            clear = setTimeout(function () {
              pageClickToMark();
            }, 300);
          }
          if (click === 2) {
            clearTimeout(clear);
          }
        }}
      />

      <div
        className="options-button"
        onClick={(e) => {
          /**
           * If we're relating a page to an article we want to handle this differently.
           */
          if (view_interact_type === "relate_to") {
            if (props.selectPage) {
              props.selectPage(props.values);
            } else {
              const id = getPageId(props.values);
              props.editPage(id, !!props.values.shared);
            }

            return;
          }

          pageClickToMark();
          let menuChoices;
          if (props.selectPage) {
            menuChoices = [
              {
                is: "choice",
                icon: faHandPointer,
                text: "Select page",
                onClick: () => {
                  props.selectPage(props.values);
                },
              },
            ];
          } else if (props.values.shared) {
            const activated = props.values.current_status === "published";
            menuChoices = [
              {
                is: "choice",
                // icon: faEye,
                text: activated ? "Deactivate" : "Activate",
                onClick: () => {
                  props.changePageValue("current_status", activated ? "draft" : "published");
                },
                style: { color: activated ? "#f64e60" : "#009fff" },
              },
              { is: "hr" },
              {
                is: "choice",
                icon: faEye,
                text: "View page",
                onClick: () => {
                  const id = getPageId(props.values);
                  props.editPage(id, !!props.values.shared);
                },
              },
            ];
            if (activated)
              menuChoices.push(
                {
                  is: "choice",
                  icon: faArrowRight,
                  text: "Move page",
                  onClick: () => {
                    props.openOverlay("pageChoose", {
                      pageId: props.values.id,
                      headline: lang.moving_page + ' "' + title + '"',
                      pageMode: "movePage",
                      pageDetails: props.values,
                      disableSharedPages: true,
                      afterFunc: (e) => {
                        props.movePage({
                          id: props.values.id,
                          toSite: e.site_id,
                          toPage: e.id,
                          shared: true,
                        });
                      },
                    });
                  },
                },
                {
                  is: "choice",
                  icon: faArrowUp,
                  text: "Move up",
                  onClick: () => {
                    const findIndex = props.pages.findIndex((x) => x.id === props.values.id);
                    if (findIndex !== 0) {
                      array_move(props.pages, findIndex, findIndex - 1);
                      orderPage({
                        pages: props.pages,
                        id: props.pageId,
                        siteId: props.siteId,
                      });
                    }
                  },
                  dontCloseAfter: true,
                  // appear: canMoveUp,
                },
                {
                  is: "choice",
                  icon: faArrowDown,
                  text: "Move down",
                  onClick: () => {
                    const findIndex = props.pages.findIndex((x) => x.id === props.values.id);
                    if (findIndex !== props.pages.length - 1) {
                      array_move(props.pages, findIndex, findIndex + 1);
                      orderPage({
                        pages: props.pages,
                        id: props.pageId,
                        siteId: props.siteId,
                      });
                    }
                  },
                  dontCloseAfter: true,
                  // appear: canMoveDown,
                }
              );
          } else {
            menuChoices = [
              {
                is: "choice_list",
                // icon: faPlusSquare,
                // icon: null,
                text: "New page from template",
                // onClick: () =>
                //   props.newPage(props.siteId, props.values.id, {
                //     languages: ["en"],
                //     modules: [
                //       {
                //         type: "visit_faroe_islands_top",
                //         data: [
                //           {
                //             bottom_icon: "2",
                //             media: {},
                //             title_and_description: "light_text",
                //             link_option: "page",
                //             key: 1,
                //           },
                //         ],
                //         languages: { en: [{}] },
                //         // is_hero: false,
                //         // show_icons: true,
                //       },
                //       { type: "social" },
                //       {
                //         type: "ckeditor_5",
                //         languages: {
                //           en: {
                //             blocks:
                //               "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
                //           },
                //         },
                //       },
                //     ],
                //   }),
                list: finalTemplateList,
                dontCloseAfter: true,
              },
              { is: "hr" },
              {
                is: "choice",
                icon: faSquare,
                text: "New blank page",
                onClick: () => props.newPage(props.values.id),
              },
              {
                is: "choice",
                icon: faEye,
                text: "View page",
                onClick: () => {
                  props.editPage(props.values.id, !!props.values.shared);
                },
              },
              {
                is: "choice",
                icon: faArrowRight,
                text: "Move page",
                onClick: () => {
                  props.openOverlay("pageChoose", {
                    pageId: props.values.id,
                    headline: lang.moving_page + ' "' + title + '"',
                    pageMode: "movePage",
                    pageDetails: props.values,
                    disableSharedPages: true,
                    afterFunc: (e) => {
                      props.movePage({
                        id: props.values.id,
                        toSite: e.site_id,
                        toPage: e.id,
                        shared: false,
                      });
                    },
                  });
                },
              },
              {
                is: "choice",
                icon: faArrowUp,
                text: "Move up",
                onClick: () => {
                  const findIndex = props.pages.findIndex((x) => x.id === props.values.id);
                  if (findIndex !== 0) {
                    array_move(props.pages, findIndex, findIndex - 1);
                    orderPage({
                      pages: props.pages,
                      id: props.pageId,
                      siteId: props.siteId,
                    });
                  }
                },
                dontCloseAfter: true,
                // appear: canMoveUp,
              },
              {
                is: "choice",
                icon: faArrowDown,
                text: "Move down",
                onClick: () => {
                  const findIndex = props.pages.findIndex((x) => x.id === props.values.id);
                  if (findIndex !== props.pages.length - 1) {
                    array_move(props.pages, findIndex, findIndex + 1);
                    orderPage({
                      pages: props.pages,
                      id: props.pageId,
                      siteId: props.siteId,
                    });
                  }
                },
                dontCloseAfter: true,
                // appear: canMoveDown,
              },
              { is: "hr" },
              { is: "title", text: "DUPLICATE PAGE" },
              {
                is: "choice",
                icon: faCopy,
                text: "here",
                onClick: () => {
                  props.duplicatePage(props.values.id, props.pageId);
                },
                // style: { color: "lightgray" },
              },
              {
                is: "choice",
                icon: faCopy,
                text: "to another website",
                onClick: () => {
                  props.openOverlay("pageChoose", {
                    pageId: props.values.id,
                    headline: lang.duplicating_page + ' "' + title + '" ' + lang.to_where,
                    afterFunc: (e) => {
                      props.duplicatePage(props.values.id, e.id, true);
                    },
                  });
                },
                // style: { color: "lightgray" },
              },
              // {
              //   is: "choice",
              //   icon: faCopy,
              //   text: "Duplicate page to new website",
              //   onClick: () => {
              //     console.log("Not implemented..");
              //   },
              //   style: { color: "lightgray" },
              // },
              {
                is: "hr",
              },
              {
                is: "choice",
                icon: faTrashAlt,
                text: "Delete page",
                onClick: () => {
                  props.deletePage(props.values.id, (e) => {
                    props.markDeleted(e.data.id);
                  });
                },
                style: {
                  color: "#F64E60",
                },
              },
            ];
          }
          props.setOptionsList(props.values.id, menuChoices, clickPosition(e));
        }}
      >
        {view_interact_type === "relate_to" ? (
          <FontAwesomeIcon icon={faHandPointer} />
        ) : (
          <FontAwesomeIcon icon={faEllipsisV} />
        )}
      </div>
      <div className="status">
        {props.values.current_status === "hidden" ? (
          <div className="stat">
            <FontAwesomeIcon icon={faEyeSlash} />
          </div>
        ) : (
          ""
        )}
        {props.values.visibility === 0 ? <div className="stat">{lang.private}</div> : ""}
        {props.values.deleted ? <div className="stat">{lang.deleted}</div> : ""}
        {props.values.shared ? (
          <div className="stat">
            <FontAwesomeIcon icon={faClone} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
