import styles from "./Orders.module.scss";
import OrderListHead from "./OrderListHead";
import { useEffect } from "react";
import { OrderListType, OrderItem } from "./types";
import { get_relative_date_format } from "src/utils/StringHelpers";
import { search_filter } from "src/utils/search_filter";

/**
 * List of orders on the system
 *
 * @author 					Pætur Mortensen
 */
export default function OrderList({
  reload_orders,
  orders,
  setDisplayOrder,
  close,
  isLoading,
  filteredOrders,
  setFilteredOrders,
  search,
  setSearch,
}: OrderListType): JSX.Element {
  useEffect(() => {
    filter_orders();
  }, [search, orders]);

  /**
   * Filter the orders
   *
   * @author 					Pætur Mortensen
   */
  function filter_orders(): void {
    const searchFiltered = search_filter(search, orders, [
      "orderID",
      "name",
      "status",
      "address",
      "city",
      "comments",
      "email",
      "licensePlate",
      "phone",
      "zip",
    ]);

    setFilteredOrders(searchFiltered as OrderItem[]);
  }

  /***************************************************************************************************
   *
   * 																					RENDER
   *
   **************************************************************************************************/

  /**
   * Render a single order row
   *
   * @author 					Pætur Mortensen
   */
  function render_order_row(order: OrderItem): JSX.Element {
    const orderID = order.orderID;

    // Number formatter for price
    const formatter = new Intl.NumberFormat("da-DK", {
      style: "currency",
      currency: "DKK",
    });

    // Get formatted time for date cell
    const date = get_relative_date_format(Math.floor(new Date(order.date).getTime() / 1000));

    return (
      <tr
        onClick={() => {
          setDisplayOrder(orderID);
        }}
        key={orderID}
      >
        <td> {orderID} </td>
        <td> {order.name} </td>
        <td> Order </td>
        <td>
          products: {order.numProducts}
          services: {order.numServices}
        </td>
        <td> {formatter.format(order.priceTotal)} </td>
        <td>
          <span className={styles.status + " " + styles[order.status]}>{order.status.toUpperCase()}</span>
        </td>
        <td> {date} </td>
      </tr>
    );
  }

  return (
    <>
      <OrderListHead
        reload_orders={reload_orders}
        search={search}
        setSearch={setSearch}
        close={close}
        isLoading={isLoading}
      />
      <div className={styles.orderList}>
        <table>
          <tbody>
            <tr>
              <th>Order Id</th>
              <th>Name</th>
              <th>Type</th>
              <th>Products</th>
              <th>Price</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
            {filteredOrders.map((order) => render_order_row(order))}
          </tbody>
        </table>
      </div>
    </>
  );
}
