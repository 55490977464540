import "../../EditProduct.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import env from "../../../../../../environment.json";
import { v4 as uuid } from "uuid";
import Preloader from "src/assets/Preloader";
import Select from "react-select";
import Button from "src/Components/Buttons/Button";
import RecalculatingOverlay from "../../RecalculatingOverlay";

/**
 * Variant price options
 *
 * @param 		{object} 		variant 									Local variant state
 * @param 		{function} 	setVariant 								Set local variant state
 *
 * @returns 	{jsx} 																Variant inventory price fields
 *
 * @author 					Pætur Mortensen
 */
function InventoryPrice({ variant, setVariant }) {
  // Set the rate type options for use with select
  const rateTypeOptions = [
    { label: "Percent", value: "percent" },
    { label: "Fixed", value: "fixed" },
  ];

  return (
    <div className="price">
      <div>
        <h3>Price</h3>
      </div>
      <div>
        <label>Cost Price</label>
        <input
          value={variant.netPrice}
          onChange={(e) => {
            setVariant({ ...variant, netPrice: e.target.value });
          }}
        />
      </div>
      <div className="extra-price">
        <div>
          <label>Add Price</label>
          <input
            type="number"
            value={variant.addPrice ?? 0}
            onChange={(e) => {
              setVariant({ ...variant, addPrice: e.target.value });
            }}
          />
        </div>
        <div className="select-container">
          <label>Rate Type</label>
          <Select
            className="select"
            options={rateTypeOptions}
            value={variant.addPriceRateType ?? null}
            onChange={(e) => {
              setVariant({ ...variant, addPriceRateType: e });
            }}
          ></Select>
        </div>
      </div>
    </div>
  );
}

/**
 * Set inventory stock
 *
 * @param 		{object} 		variant 									Currently processed variant
 * @param 		{function} 	setVariant 								Set variant state
 * @param 		{object} 		productConfig 						Product configuration object
 *
 * @returns 	{jsx} 																Inventory stock element
 *
 * @author 					Pætur Mortensen
 */
function InventoryStock({ variant, setVariant, productConfig }) {
  return (
    <div className="stock">
      <div>
        <h3>Stock</h3>
      </div>
      <div>
        <div>
          <label>Stock</label>
          <input value={variant.quantity} disabled />
        </div>
        <div>
          <label>Remote Stock</label>
          <input defaultValue={variant.remoteQuantity} disabled />
        </div>
        <div className="select-container">
          <label>Unit</label>
          <Select
            className="select"
            options={productConfig.unitTypes}
            value={productConfig.unitTypes.find((unitType) => unitType.slug === variant.unitType)}
            onChange={(e) => {
              setVariant({ ...variant, unitType: e.slug });
            }}
          />
        </div>
      </div>
    </div>
  );
}

/**
 * Inventory calculations
 *
 * @param 			{object} 		product 									Product state
 * @param 			{object} 		variant 									Local variant state (edit inventory variant)
 * @param 			{array} 		priceRules 								Price rules for the system
 * @param 			{object} 		shopProperties 						General properties for the shop
 *
 * @returns 		{jsx}
 *
 * @author 					Pætur Mortensen
 */
function InventoryCalculations({ product, variant, priceRules, shopProperties }) {
  // Rows with calculations
  const [calculationRows, setCalculationRows] = useState([]);
  // Selling price (calculation result)
  const [sellingPrice, setSellingPrice] = useState(variant.costPrice);

  // Recalculate anytime price rules or variant change
  useEffect(() => {
    calculate();
  }, [variant, priceRules]);

  /***************************************************************************************************
   *
   * 																CALCULATIONS
   *
   **************************************************************************************************/

  /**
   * Check whether rule applies to this variant
   *
   * @param 		{object} 	rule 										Price rule to check
   *
   * @returns 	{bool}														Whether price rule applies to current variant
   *
   * @author 					Pætur Mortensen
   */
  function price_rule_applies(rule) {
    // Rule does not apply to this product type id
    if (rule.productTypeuuid !== product.productTypeID) return false;

    // Rule applies to product type, but is not specific for brand, product, variant or property,
    // meaning it is a global price and applies
    if (!rule.branduuid && !rule.productuuid && !rule.varientuuid && !rule.propertyuuid) return true;

    // If we reach this point, the price rule applies to the product type, but is not global.
    // Figure out if its specific type applies to this variant

    // If rule applies to a brand, but the variant does not belong to the brand, rule does not apply
    if (rule.branduuid && rule.branduuid !== product.branduuid) return false;

    // If rule applies to product, but variant does not belong to the product, rule does not apply
    if (rule.productuuid && rule.productuuid !== product.productuuid) return false;

    // If rule applies to a variant, but not this variant, rule does not apply
    if (rule.varientuuid && rule.varientuuid !== variant.variantuuid) return false;

    // Property price rules don't work at the moment, skip until further notice
    if (Object.keys(rule.property).length > 0) return false;

    // If we reach this point, the rule applies
    return true;
  }

  /**
   * Calculate a single price rule
   *
   * @param 		{float} 	rate 										Rate to calculate
   * @param 		{string} 	rateType 								Rate type (fixed, percent..)
   * @param 		{float} 	price 									Price to apply calculation to
   *
   * @returns 	{float} 													Resulting price
   *
   * @author 					Pætur Mortensen
   */
  function calculate_price_rule(rate, rateType, price) {
    // Cast to float
    let calc = parseFloat(price);
    rate = parseFloat(rate);

    // Depending on rate type...
    switch (rateType) {
      case "fixed":
        calc += rate;
        break;
      case "percent":
        calc += calc * (rate / 100);
        break;
      default:
        console.error("calculation rate type is not supported: ", rateType);
    }

    // Round to two decimals
    calc = Math.round(calc * 100) / 100;

    return calc;
  }

  /**
   * Get uniformly formatted calculation row
   *
   * @param 		{string} 				name 											Price rule name
   * @param 		{float|string} 	previousPrice 						Previous price (OR empty string)
   * @param 		{float|string} 	rate 											Calculation rate (OR empty string)
   * @param 		{string} 				rateType 									Calculation rate type (OR empty string)
   * @param 		{float} 				total 										Total result
   * @param 		{int} 					ruleID 										ID of price rule
   *
   * @returns 	{object} 																	Formatted object
   *
   * @author 					Pætur Mortensen
   */
  function get_calculation_row(name, previousPrice, rate, rateType, total, ruleID) {
    return { name, previousPrice, rate, rateType, total, ruleID };
  }

  /**
   * Calculate the prices
   *
   * Perform all calculations for the prices.
   *
   * @author 					Pætur Mortensen
   */
  function calculate() {
    // Init the accumulated price
    let accumulatedPrice = !isNaN(parseFloat(variant.netPrice)) ? parseFloat(variant.netPrice) : 0;
    // Init new calculation rows as empty
    const newCalculationRows = [get_calculation_row("Cost price", "", "", "", accumulatedPrice, "costPrice")];

    // For each price rule...
    for (const idx in priceRules) {
      const priceRule = priceRules[idx];

      // If the rules does not apply to this variant, skip this iteration
      if (!price_rule_applies(priceRule)) continue;

      // Rate type (fixed or percent)
      const rateType = priceRule.rateType.value;
      // Cast rate to float
      const rate = parseFloat(priceRule.rate);
      const previousPrice = accumulatedPrice;

      // Calculate and accumulate the price
      accumulatedPrice = calculate_price_rule(rate, rateType, accumulatedPrice);

      // Add the row to the calculation rows
      newCalculationRows.push(
        get_calculation_row(priceRule.label, previousPrice, rate, rateType, accumulatedPrice, priceRule.id)
      );
    }

    // If VAT applies to the shop...
    if (shopProperties.VATApplies) {
      // Save previous price
      const previousPrice = accumulatedPrice;
      const VATRate = shopProperties.VATPercentage;
      const rateType = "percent";

      // Add VAT calculation
      accumulatedPrice = calculate_price_rule(VATRate, rateType, accumulatedPrice);

      // Add the VAT row
      newCalculationRows.push(
        get_calculation_row("VAT", previousPrice, VATRate, rateType, accumulatedPrice, "VATRule")
      );
    }

    // If we are to round to ten in the shop..
    if (shopProperties.roundToTen) {
      // Save previous price
      const previousPrice = accumulatedPrice;

      // Round price to ten
      accumulatedPrice = Math.round(accumulatedPrice / 10) * 10;

      // Add the price to the calculation rows as formatted
      newCalculationRows.push(
        get_calculation_row("Round to ten", previousPrice, "", "", accumulatedPrice, "roundToTenRule")
      );
    }

    // Update calculation rows state
    setCalculationRows(newCalculationRows);
    setSellingPrice(accumulatedPrice);
  }

  /***************************************************************************************************
   *
   * 																RENDER
   *
   **************************************************************************************************/

  /**
   * Render the calculations table
   *
   * @returns 	{jsx}
   *
   * @author 					Pætur Mortensen
   */
  function render_calc_table() {
    /**
     * Render the table head row
     *
     * @returns 	{jsx} 									Table head row
     *
     * @author 					Pætur Mortensen
     */
    function render_table_head() {
      return (
        <tr key="head-row">
          <th>Name</th>
          <th>Amount</th>
          <th></th>
          <th>Rate</th>
          <th>Total</th>
        </tr>
      );
    }

    /**
     * Render a single calculation row
     *
     * @param 		{object} 		row 										Calculation data for row
     *
     * @returns 	{jsx} 															Calculation row
     *
     * @author 					Pætur Mortensen
     */
    function render_calculation_row(row) {
      /**
       * Get operator for rate (plus, minus or filler if not numeric)
       *
       * @param 		{float|string} 	rate  									Number or empty string
       *
       * @returns 	{string} 																Operator to use with rate if any
       *
       * @author 					Pætur Mortensen
       */
      function get_operator(rate) {
        if (!isNaN(parseFloat(rate))) return rate >= 0 ? "+" : "-";

        return "--";
      }

      /**
       *
       * @param 		{string} 		rateType 								Rate type (fixed, percent or '')
       *
       * @returns 	{string} 														Rate type symbol if any
       *
       * @author 					Pætur Mortensen
       */
      function get_rate_type_symbol(rateType) {
        switch (rateType) {
          case "fixed":
            return ",-";
          case "percent":
            return "%";
          default:
            return "--";
        }
      }

      return (
        <tr key={row.ruleID}>
          <td className="calc-name">{row.name}</td>
          <td key="selling_perc">
            <div className="line"></div>
            {row.previousPrice}&nbsp;
          </td>
          <td key="line1_perc">
            <div className="line"></div>
            {get_operator(row.rate)}
          </td>
          <td key="percent_perc">
            <div className="line"></div>
            {!isNaN(parseFloat(row.rate)) && Math.abs(row.rate)}
            {get_rate_type_symbol(row.rateType)}
          </td>
          <td key="total_perc">
            <div className="line"></div>
            <b>{row.total}</b>
          </td>
        </tr>
      );
    }

    return (
      <table>
        <tbody>
          {render_table_head()}
          {calculationRows.map((row) => render_calculation_row(row))}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <div className="wrap-price">
        {render_calc_table()}
        <div>
          <h3>
            Selling Price &nbsp;
            <b>{sellingPrice} DKK</b>
          </h3>
        </div>
      </div>
    </>
  );
}

/**
 * Build a price rule
 *
 * Add the needed parameters as needed, the rest will be filled in with default values
 *
 * @param 		{object} 		params 													Values to set for the price rule
 *
 * @returns 	{object} 																		Price rule formatted for insertion
 *
 * @author 					Pætur Mortensen
 */
function build_price_rule(params) {
  // Initialize an empty price rule with default values
  const emptyRule = {
    brand: {},
    branduuid: null,
    id: null,
    label: null,
    order_value: 999999,
    product: {},
    productTypeuuid: null,
    productuuid: null,
    property: {},
    propertyValue: "",
    propertyuuid: null,
    rate: null,
    rateType: null,
    reg_date: null,
    siteId: 1,
    uuid: uuid(),
    varient: {},
    varientuuid: null,
  };

  // Return price rule with default values overwritten by params where set
  return { ...emptyRule, ...params };
}

/**
 * Edit inventory
 *
 * Allows for editing price, stock and units for the variant
 *
 * @param 			{object} 		product 										Product state
 * @param 			{object} 		productConfig 							Product configuration options
 * @param 			{object} 		variant 										Variant state
 * @param 			{function} 	close 											Close the inventory component
 * @returns 		{jsx} 																	Edit inventory element
 *
 * @author 					Pætur Mortensen
 */
export default function EditInventory({ product, productConfig, variant, close, recalculate_prices }) {
  // Whether component is loading
  const [isLoading, setIsLoading] = useState(false);
  // Set a local variant to edit and save only when done
  const [localVariant, setLocalVariant] = useState(JSON.parse(JSON.stringify(variant)));
  // Price rules, copy from productConfig for editing and saving later.
  const [priceRules, setPriceRules] = useState(JSON.parse(JSON.stringify(productConfig.priceRules)));
  // Whether we are recalculating
  const [isRecalculating, setIsRecalculating] = useState(false);

  /**
   * Handle the variant add price
   *
   * The add price is a variant-specific way to add a single price rule to the variant
   * This function checks whether to set add price from the price rule, set the price rule from
   * the add price input value or create new entries.
   *
   * @author 					Pætur Mortensen
   */
  function handle_variant_add_price() {
    // Try to find add price rule for this variant
    // Add price rule is specific to the "add price" field
    const variantRuleIdx = priceRules.findIndex(
      (rule) => rule.label === "Add price" && rule.varientuuid === localVariant.variantuuid
    );

    // If this variant has an add price rule...
    if (variantRuleIdx !== -1) {
      const newPriceRules = [...priceRules];
      const priceRule = newPriceRules[variantRuleIdx];

      // If add price has not been set for variant, we set it from the rule
      if (typeof localVariant.addPrice === "undefined") {
        setLocalVariant({
          ...localVariant,
          addPrice: parseFloat(priceRule.rate),
          addPriceRateType: priceRule.rateType,
        });
      } else {
        // The add price has been set for the variant. Update the price rule with the add price

        priceRule.rate = !isNaN(parseFloat(localVariant.addPrice)) ? parseFloat(localVariant.addPrice) : 0;
        priceRule.rateType = localVariant.addPriceRateType;
        newPriceRules[variantRuleIdx] = priceRule;
        setPriceRules(newPriceRules);
      }
    } else {
      // This variant does not have an add price rule, check if it has been set now

      // If add price and add price rate type have been set...
      if (typeof localVariant.addPrice !== "undefined" && typeof localVariant.addPriceRateType !== "undefined") {
        // We will add the add price to the price rules here

        // Clean field input
        const rate = !isNaN(parseFloat(localVariant.addPrice)) ? parseFloat(localVariant.addPrice) : 0;

        // If the add price rate is not 0...
        if (rate !== 0) {
          // Config for building new price rule
          const priceRule = build_price_rule({
            id: "addPrice",
            label: "Add price",
            rate,
            rateType: localVariant.addPriceRateType,
            productTypeuuid: product.productTypeID,
            varient: {
              value: localVariant.variantuuid,
              label: localVariant.label,
              parentuuid: product.productuuid,
            },
            varientuuid: localVariant.variantuuid,
          });
          // Add the add price to the price rules
          setPriceRules([...priceRules, priceRule]);
        }
      }
    }
  }

  // Called whenever local variant changes
  useEffect(() => {
    // Handle the add price rule and input for the variant
    handle_variant_add_price();
  }, [localVariant]);

  /**
   * Check whether changes need saving and save if required
   *
   * @author 					Pætur Mortensen
   */
  function save_changes() {
    // Whether to update anything
    let doUpdate = false;
    setIsLoading(true);

    /**
     * Get edited variant (if it has been edited)
     *
     * @returns 	{object|null} 									Local variant if it has been edited, else null
     *
     * @author 					Pætur Mortensen
     */
    function get_edited_variant() {
      // Get the original variant
      const originalVariant = product.variants.find((item) => item.variantID === localVariant.variantID);

      // If net price has been updated
      if (originalVariant.netPrice !== localVariant.netPrice) return localVariant;

      // If unit type has been updated
      if (originalVariant.unitType !== localVariant.unitType) return localVariant;

      // No changes to variant
      return null;
    }

    /**
     * Get the add price rule
     *
     * Finds the add price rule, either from the productConfig or the local priceRules
     *
     * @param 		{string} 	type 										Price rule type ('original' or 'local')
     *
     * @returns 	{object|null}											Price rule OR NULL if not found
     *
     * @author 					Pætur Mortensen
     */
    function get_rule(type) {
      const ruleArr = type === "original" ? productConfig.priceRules : priceRules;

      const rule = ruleArr.find((item) => item.label === "Add price" && item.varientuuid === localVariant.variantuuid);

      return typeof rule !== "undefined" ? rule : null;
    }

    /**
     * Get price rule to add to prices (if any)
     *
     * @param 		{object|null} 		originalRule 							Original price rule if there is one
     * @param 		{object|null} 		priceRule 								Current price rule if there is one
     *
     * @returns 	{object|null} 															Price rule to add OR NULL if it
     * 																												should not be added
     *
     * @author 					Pætur Mortensen
     */
    function get_add_price_rule(originalRule, priceRule) {
      // If there was no add price rule originally, but now there is...
      if (originalRule === null && priceRule !== null) {
        // Check the rate before adding the rule
        const rate = !isNaN(parseFloat(priceRule.rate)) ? parseFloat(priceRule.rate) : 0;

        // If the new rule rate is not 0, add the new price rule
        if (rate !== 0) return priceRule;
      }

      return null;
    }

    /**
     * Get price rule for updating (if applies)
     *
     * @param 		{object|null} 	originalRule 					Original add price rule OR null if not set
     * @param 		{object|null} 	priceRule 						Current price rule OR null if not set
     *
     * @returns 	{object|null} 												Current price rule OR null if no update
     *
     * @author 					Pætur Mortensen
     */
    function get_update_price_rule(originalRule, priceRule) {
      // If there was originally an add price rule...
      if (originalRule !== null) {
        const originalRate = parseFloat(originalRule.rate);
        const originalRateType = JSON.stringify(originalRule.rateType);
        const newRate = !isNaN(parseFloat(priceRule.rate)) ? parseFloat(priceRule.rate) : 0;
        const newRateType = JSON.stringify(priceRule.rateType);

        // If the new rate OR rateType has changed...
        if (originalRate !== newRate || originalRateType !== newRateType) {
          // Update the price rule (0 value will be deleted in backend)
          return priceRule;
        }
      }

      return null;
    }

    // Get edited variant (null if no changes)
    const editedVariant = get_edited_variant();
    if (editedVariant !== null) doUpdate = true;

    // Get original and current price rules for comparison
    const originalRule = get_rule("original");
    const priceRule = get_rule("local");

    // Get price rule to add (if applies) and flag for update if add rule is set
    const addPriceRule = get_add_price_rule(originalRule, priceRule);
    if (addPriceRule !== null) doUpdate = true;

    // Get price rule to update (if applies) and flag for update if the rule is set
    const updatePriceRule = get_update_price_rule(originalRule, priceRule);
    if (updatePriceRule !== null) doUpdate = true;

    // If there are updates to perform...
    if (doUpdate) {
      axios
        .post(env.protocol + env.env + "/api/secured/shop/AdminEditInventory", {
          editedVariant,
          addPriceRule,
          updatePriceRule,
        })
        .then((response) => {
          setIsLoading(false);
          recalculate_prices();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // No updates, just close
      setIsLoading(false);
      close();
    }
  }

  /***************************************************************************************************
   *
   * 																		RENDER
   *
   **************************************************************************************************/

  /**
   * Render the control buttons (save and close)
   *
   * @returns 	{jsx} 														Control buttons
   *
   * @author 					Pætur Mortensen
   */
  function render_control_buttons() {
    return (
      <div className="cta">
        <Preloader show={isLoading} />
        <Button
          onClick={() => {
            save_changes();
          }}
        >
          Save
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            // console.log(
            //   "closing: ",
            //   productConfig.priceRules.find(
            //     (item) => item.varientuuid === variant.variantuuid
            //   )
            // );
            setPriceRules(productConfig.priceRules);
            close();
          }}
        >
          close
        </Button>
      </div>
    );
  }

  return (
    <div className="editInventoryComponent">
      <div className="left-content">
        <InventoryPrice variant={localVariant} setVariant={setLocalVariant} />
        <InventoryStock variant={localVariant} setVariant={setLocalVariant} productConfig={productConfig} />
      </div>
      <div className="right-content">
        {render_control_buttons()}
        <InventoryCalculations
          product={product}
          priceRules={priceRules}
          shopProperties={productConfig.shopProperties}
          variant={localVariant}
        />
      </div>
    </div>
  );
}
