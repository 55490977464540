import env from "../environment.json";

/**
 * URL to "No image" image
 *
 * @author 					Pætur Mortensen
 */
export function getNoImageURL() {
  return env.protocol + env.env + "/assets/images/empty.jpg";
}

export function handle_image_error(e: React.SyntheticEvent<HTMLImageElement, Event>) {
  e.preventDefault();
  e.currentTarget.src = getNoImageURL();
}
