import { useEffect, useState } from "react";
import styles from "./Leaflet.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import PermCheck from "../../../Components/PermCheck";
import { section, sectionView } from "../ArticlesDisplay/types";
import { useArticlesStore } from "src/Components/ZustandStores/articlesStore";
import { usePermCheckStore } from "src/Components/PermCheck/PermCheckStore";

interface Props {
  title: string;
  sections: any[];
  selectedView: sectionView;
  setSelectedView: (data: sectionView) => void;
  add?: {
    call: () => any;
    active: boolean;
  };
  customCall?: (view: any) => void;
  level?: number;
}

export default function Leaflet({
  title,
  sections,
  selectedView,
  setSelectedView,
  add = {
    call: () => {},
    active: false,
  },
  customCall = () => {},
  level = 1,
}: Props) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // For initializing our laod animation
    setLoading(false);
  }, []);

  const articleStore = useArticlesStore();
  const articleTypes = articleStore.articleTypes;

  const permCheckStore = usePermCheckStore();

  return (
    <div className={`${styles.navigation} ${loading ? "" : styles.loaded}`}>
      {/* Leaflet title */}

      <div className={styles.nnw}>
        <h1>{title}</h1>
      </div>

      {/* Map our sections */}

      {sections
        .filter(
          (section: section) =>
            section.views.find((view) => {
              return permCheckStore.permCheckFunc({
                type: view.checkType,
                minimumRole: view.checkMinimumRole,
                id: view.checkId,
              });
            }) !== undefined
        )
        .map((section: section, index: number) => {
          const keys = section.views.map((at) => at.key);
          const ids = articleTypes.map((at: sectionView) => at.checkId as number);
          return (
            <PermCheck
              key={`Leaflet-outer-${section.title}-${index}}`}
              minimumRole="Publisher"
              type="articleTypes"
              id={ids}
              ignoreCheck={true}
            >
              <>
                <div className={styles.nnw} key={index}>
                  <p>{section.title}</p>
                </div>

                {/* For each section map its views */}

                {section.views.map((view: sectionView, index: number) => {
                  const { name, key, checkId, checkMinimumRole, checkType } = view;
                  // const articleType = articleTypes.find(
                  //   (a: sectionView) => a.key === key
                  // );
                  // const id: number = articleType?.id;
                  return (
                    <PermCheck
                      minimumRole={checkMinimumRole}
                      id={checkId}
                      type={checkType}
                      key={`Leaflet-inner-${view.key}-${index}}`}
                      // ignoreCheck={true}
                    >
                      <button
                        className={`${styles.view} ${
                          selectedView?.name === name
                            ? // && (key === "" || key === selectedView?.key)
                              styles.selected
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedView({
                            name,
                            key,
                            checkId,
                            checkMinimumRole,
                            checkType,
                          });
                          customCall(name);
                        }}
                      >
                        {name}
                      </button>
                    </PermCheck>
                  );
                })}

                <br />
              </>
            </PermCheck>
          );
        })}

      {/* Add button that takes a custom add function */}

      {add.active && (
        <div className={styles.add}>
          <FontAwesomeIcon icon={faPlus as IconProp} />
        </div>
      )}
    </div>
  );
}
