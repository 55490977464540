import { useEffect, useRef, useState } from "react";
import styles from "./AmenitiesDisplay.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sort } from "./sort-functions";
import { faArrowDownArrowUp, faSortAlt, faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import Card from "src/Components/OverlayElements/Elements/Card/Card";
import AddAmenity from "./AmenityModules/AddAmenity/AddAmenity";
import axios from "axios";

import env from "../../../../../environment.json";
import { faLoader } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useRouterStore } from "src/Components/Router/routerStore";
import { formatTime } from "./helper-functions";

const level = 2;

// TODO: Create amenity type
type amenity = {};

// Array of keys(columns) we use in our table
const amenityKeys = [
  {
    key: "amNameEn",
    label: "English title",
  },
  {
    key: "amNameFo",
    label: "Faroese title",
  },
  {
    key: "Date",
    label: "Date",
  },
];

export default function AmenitiesDisplay() {
  /* STATES */

  const { addOverlay } = overlayStore((state: any) => state);
  const { route, setRouter, navTo, back } = useRouterStore();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [amenities, setAmenities] = useState<any[]>([]);
  const [amenitiesLoaded, setAmenitiesLoaded] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<{ key: string; reverse: boolean }>({
    key: "Date",
    reverse: false,
  });

  /* FUNCTIONS */

  const addAmenity: () => void = () => {
    navTo(level, {
      nav: "add",
      sidebar: "",
      displayName: "add",
    });

    addOverlay(
      "card",
      (close: () => void) => {
        return (
          <AddAmenity
            width={900}
            height={680}
            close={() => {
              close();
            }}
            success={() => {
              fetchAmenities();
            }}
          />
        );
      },
      () => {
        back();
      }
    );
  };

  const editAmenity: (index: number, data?: any[] | null) => void = (index: number, data: any[] | null = null) => {
    navTo(level, {
      nav: "edit-" + (index + 1),
      sidebar: "",
      displayName: "edit-" + (index + 1),
    });

    let retData = (data ? data : amenities)
      .filter((amenity: any) =>
        (amenity.amNameFo + amenity.amNameEn) /*  + amenity.Date */
          .toLowerCase()
          .includes(search.toLowerCase())
      )
      .map((a: any) => {
        return {
          amenityID: a.amenityID,
          title: {
            en: a.amNameEn,
            fo: a.amNameFo,
          },
          backend: {
            en: a.tooltip,
            fo: a.tooltipFo,
          },
          frontend: {
            en: a.tooltipFrontendEn,
            fo: a.tooltipFrontendFo,
          },
        };
      });

    addOverlay(
      "card",
      (close: () => void) => {
        return (
          <AddAmenity
            width={900}
            height={680}
            close={() => {
              close();
            }}
            success={() => {
              fetchAmenities();
            }}
            forms={retData}
            idx={index}
            edit={true}
          />
        );
      },
      () => {
        back();
      }
    );
  };

  const fetchAmenities: () => void = () => {
    axios
      .post(env.protocol + env.env + "/api/public/whatson/places/GetPlaceAmenities")
      .then((response: any) => {
        let data = response.data.sort((a: any, b: any) => sort(a, b, "Date", !sortBy.reverse));
        setAmenities(data);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        setAmenitiesLoaded(true);
      });
  };

  /* USE-EFFECTS */

  useEffect(() => {
    fetchAmenities();

    setLoaded(true);
  }, []);

  useEffect(() => {
    if (route.length > 2 && amenities.length > 0) {
      if (route[2].nav === "add") {
        addAmenity();
      } else if (route[2]?.nav?.split("-")[0] === "edit") {
        editAmenity(Number(route[2]?.nav.split("-")[1]) - 1, amenities);
      }
      setRouter({ route: [route[0], route[1]] });
    }
  }, [amenities]);

  /* RETURN */

  return (
    <div className={`${styles.container} ${loaded ? styles.loaded : ""}`}>
      {/* Content */}

      <div className={styles.content}>
        {/* Header */}

        <div className={styles.header}>
          <h1>Amenities (Place)</h1>
          <button
            className={styles.addAmenity}
            onClick={() => {
              addAmenity();
            }}
          >
            Add new amenity
          </button>
        </div>

        {/* Search */}

        <div className={styles.searchSection}>
          <div className={styles.searchInputContainer}>
            <input
              className={styles.searchInput}
              placeholder="Search amenities..."
              type="text"
              onFocus={(e) => {
                e.target.placeholder = "";
              }}
              onBlur={(e) => {
                e.target.placeholder = "Search amenities...";
              }}
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
            />
            <FontAwesomeIcon className={styles.searchIcon} icon={faMagnifyingGlass as IconProp} />
          </div>
        </div>

        {/* Table */}

        <div className={styles.tableContainer} onScroll={(e: any) => {}}>
          <table>
            {/* Table header */}

            <thead>
              <tr>
                {amenityKeys.map((amenity: any, index: number) => {
                  const handleClick: any = () => {
                    setAmenities([
                      ...amenities.sort((a: any, b: any) =>
                        sort(a, b, amenity.key, amenity.key === "Date" ? !sortBy.reverse : sortBy.reverse)
                      ),
                    ]);
                    setSortBy({
                      key: amenity.key,
                      reverse: !sortBy.reverse,
                    });
                  };

                  return (
                    <th
                      key={`${amenity.key}-${index}`}
                      tabIndex={0}
                      className={`
                        ${index + 1 === amenityKeys.length ? styles.last : ""}
                        `}
                      onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                          handleClick();
                        }
                      }}
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      <div className={styles.tableHeaderContent}>
                        <p>{amenity.label}</p>
                        <FontAwesomeIcon
                          icon={sortBy.key === amenity.key ? (faSortAltSorted as IconProp) : (faSortAlt as IconProp)}
                          flip={"vertical"}
                          transform={!sortBy.reverse ? {} : { rotate: 180 }}
                        />
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            {/* Table data */}

            <tbody>
              {amenitiesLoaded ? (
                // FIX DUPLICATE FILTERING PLZZZ!!!!!!!!!
                amenities
                  .filter((amenity: any) =>
                    (amenity.amNameFo + amenity.amNameEn) /*  + amenity.Date */
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((amenity: any, index: number) => {
                    const handleClick = () => {
                      editAmenity(index);
                    };

                    return (
                      <tr
                        key={`amenity-${amenity.amenityID}`}
                        className={`${styles.amenity} ${true && styles.active}`}
                        role={"button"}
                        tabIndex={0}
                        onKeyUp={(e: any) => {
                          if (e.keyCode === 13) {
                            handleClick();
                          }
                        }}
                        onClick={handleClick}
                      >
                        <td>{amenity.amNameEn}</td>
                        <td>{amenity.amNameFo}</td>
                        <td className={styles.last}>{formatTime(amenity.Date ?? "0000-00-00 00:00:00")}</td>
                      </tr>
                    );
                  })
              ) : (
                <>
                  {/* In case amenities are loading */}

                  <tr>
                    <td>
                      <div className={styles.amenitiesLoading}>
                        <div className={styles.loader}>
                          <FontAwesomeIcon icon={faLoader as IconProp} />
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
