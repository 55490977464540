import { TLangs } from "src/types";
import { IPageExtendedInfo } from "../types/interfaces/IPageExtendedInfo";
import styles from "./AutoRelated.module.scss";
import "./AutoRelated.scss";
import { useEffect, useState } from "react";
import { testImage } from "../../../../assets/helperFunctions";

const colors = ["#c12759", "#006b74", "#009fff", "#217d3d", "#ae2c64"];

interface props {
  data: IPageExtendedInfo;
  shortLang: TLangs;
}

/**
 * We wrap each related element in a new component that can hold a state for its random color so it doesn't repeatetly update when doing stuff.
 */
function RelatedElement({
  sibling,
  shortLang,
  featureImagesFound,
  i,
}: {
  sibling: any;
  shortLang: string;
  featureImagesFound: any;
  i: number;
}) {
  const [color, setColor] = useState<number>(Math.floor(Math.random() * colors.length));

  // Languages may be undefined or the shortlang might not be set, return empty
  if (typeof sibling.languages === "undefined" || typeof sibling.languages[shortLang] === "undefined") return <></>;

  const languageObj = sibling.languages[shortLang];
  return (
    <span>
      <div
        className={styles["auto-related-item"]}
        style={{
          backgroundColor: colors[color],
        }}
      >
        <h3>{languageObj && languageObj.title}</h3>
        {featureImagesFound[i] && <img src={sibling.feature_media_dir.medium} />}
        <div className={styles["bottom-shadow"]} />
      </div>
    </span>
  );
}

export function AutoRelated(props: props) {
  const { data, shortLang } = props;
  const [featureImagesFound, setFeatureImagesFound] = useState<Array<boolean>>([]);
  useEffect(() => {
    setFeatureImagesFound(new Array(data.siblings.length));

    const checkImage = async (file: string) => {
      const result = await testImage(file);
      return result;
    };

    Promise.all(data.siblings.map((s) => checkImage(s.feature_media_dir.medium))).then((values) => {
      setFeatureImagesFound(values);
    });
  }, [data.siblings]);

  return (
    <div className={`auto-related ${styles["auto-related"]}`}>
      <div className={styles["auto-related-title"]}>
        <h2>Related articles to {data.languages[shortLang]?.title}</h2>
      </div>
      <div className={`auto-related-content ${styles["auto-related-content"]}`}>
        {data.siblings.map((sibling, i) => {
          return (
            <RelatedElement
              sibling={sibling}
              key={sibling.id}
              shortLang={shortLang}
              featureImagesFound={featureImagesFound}
              i={i}
            />
          );
        })}
      </div>
    </div>
  );
}
