import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";

/**
 * Validate All
 *
 * Validates all the submit sections
 *
 * @returns boolean
 */
export function ValidateAll() {
  /**
   * Initialises the valid state
   */
  let valid = true;
  /**
   * Store properties
   */
  const { type, errors } = useWhatsonManager.getState().getStore().getState();
  /**
   * Iterate through the errors
   */
  Object.entries(errors[type]).forEach((entry: any) => {
    /**
     * Get the validate function
     */
    const { validate } = entry[1];
    /**
     * Validate the section
     */
    const res = validate();
    /**
     * If the section is not valid, set the valid state to false
     */
    if (valid) {
      valid = res;
    }
  });
  /**
   * Return the valid state
   */
  return valid;
}
