import "../Product.scss";
import Select from "react-select";

export default function InventoryStock({ editInventory, setForceUpdate, props }) {
  const units = props.units;

  return (
    <div className="stock">
      <div>
        <h3>Stock</h3>
      </div>
      <div>
        <div>
          <label>Quantity</label>
          <input
            defaultValue={editInventory?.quantity}
            disabled
            onChange={(e) => {
              if (editInventory && e.target.value.length > 0) {
                editInventory.quantity = JSON.parse(e.target.value);
                setForceUpdate((f) => !f);
              }
            }}
          />
        </div>
        <div>
          <label>Remote Quantity</label>
          <input
            defaultValue={editInventory?.remoteQuantity}
            disabled
            onChange={(e) => {
              if (editInventory && e.target.value.length > 0) {
                editInventory.remoteQuantity = JSON.parse(e.target.value);
                setForceUpdate((f) => !f);
              }
            }}
          />
        </div>
        <div className="select-container">
          <label>Unit</label>
          <Select
            className="select"
            options={units}
            defaultValue={editInventory?.unit}
            onChange={(e) => {
              if (editInventory) {
                editInventory.unit = e;
                setForceUpdate((f) => !f);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
