import { useState } from "react";
import "./VfiDivInput.scss";

function createMarkup(defaultValue) {
  return { __html: defaultValue };
}

export default function VfiDivInput({
  defaultValue = "",
  onChange = () => {},
  className = "",
  onBlur = () => {},
  active = true,
}) {
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={`vfi-div-input ${focused ? "focus" : ""} ${active ? "" : "disabled"} ${className ?? ""}`}
      // dangerouslySetInnerHTML={{__html: value}}
      dangerouslySetInnerHTML={createMarkup(defaultValue)}
      onInput={(e) => onChange(e)}
      onBlur={(e) => {
        setFocused(false);
        onBlur(e);
      }}
      contentEditable={active}
      onFocus={() => {
        setFocused(true);
      }}
    />
  );
}
