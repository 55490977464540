import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";

function FormGroup({ children, header }) {
  return (
    <div className="single-form-group-container">
      <hr />
      <div className="with-tooltip-2">
        {header}
        <GlobalToolTipController
          solid
          title={""}
          className={"tootlip-icon"}
          priority={"bottom,right,top,left"}
          toolTipElements={
            <div className={"tooltip-card"}>
              Give your guests an overview of the tour! Consider dividing the program into sections like days or parts
              of a day to clarify things. Images help, too, if you have them.
            </div>
          }
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </GlobalToolTipController>
      </div>
      {children}
    </div>
  );
}

export default FormGroup;
