import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrashAlt, faSpinner, faEye } from "@fortawesome/pro-light-svg-icons";
import "./EditSitesMenu.scss";
import env from "../../../../../environment.json";
import axios from "axios";
import VfiCKeditor from "../../../../../assets/VfiCKeditor/VfiCKeditor";
import SitesMenuFrontEnd from "./SitesMenuFrontEnd";
import { capitalize } from "../../../../../assets/helperFunctions";
import OptionsList from "../../../../../assets/OptionsList/OptionsList";

function pushBModule(e) {
  return {
    id: e.id,
    cheatSheat: e.cheatSheat,
    text: e.text,
    block: e.block,
    url: e.block.match("href='(.*)'><i") ? e.block.match("href='(.*)'><i")[1] : "", // Get url
    type: "follow",
  };
}

export default function EditSitesMenu(props) {
  const [contentEditEnabled, setContentEditEnabled] = useState(false);
  // const [boxWidth, setBoxWidth] = useState(window.innerWidth - 500); // Some size
  const [isLoading, setIsLoading] = useState(true);
  const [contentBeforeEdit, setContentBeforeEdit] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [editMode, setEditMode] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [unMappedLang, setUnMappedLang] = useState({});
  const [addLanguageChoices, setAddLanguageChoices] = useState([]);
  const [optionsListPos, setOptionsListPos] = useState({});
  const [listIsOpen, setListIsOpen] = useState(false);
  const [optionsListOptions, setOptionsListOptions] = useState([]);

  // const contentData = { "Tourism sites": ["English", "Dansk", "Deutsch"], "Professional sites": ["some"] }

  useEffect(() => {
    axios
      .post(env.protocol + env.env + "/api/public/appearances/GetSitesMenu.php", {
        siteId: props.siteSelected.siteId,
      })
      .then((response) => {
        // If no languages, i.e. new site, we initialize a footer with english language
        if (response.data.languages.length === 0) {
          axios
            .post(env.protocol + env.env + "/api/secured/appearances/AddSitesMenu", {
              siteId: props.siteSelected.siteId,
            })
            .then((response) => {
              updateData(response);
            })
            .catch((error) => console.error(error));
        } else {
          updateData(response);
        }
      })
      .catch((error) => console.error(error));
  }, [props.siteSelected.siteId]);

  const updateData = (response) => {
    var b_mod = { ckeditor_5: [], follow: [] };
    // var b_mod = { id: response.data.b_modules[0].id, languages: response.data.b_modules[0].languages }

    response.data.b_modules.forEach((e, i) => {
      if (e.type === "follow") {
        b_mod[e.type].push(pushBModule(e));
      } else {
        b_mod["ckeditor_5"] = {
          id: response.data.b_modules[0].id,
          languages: response.data.b_modules[0].languages,
        };
      }
    });
    setContentData(b_mod);

    //Save all language info
    setUnMappedLang(response.data.languages);

    // Key short languages and select first language
    let lang = Object.keys(response.data.languages);
    setLanguages(lang);
    setSelectedLanguage(lang[0]); // Set selected language to first

    setIsLoading(false); // No more loading
  };

  useEffect(() => {
    axios
      .post(env.protocol + env.env + "/api/public/pages/GetAllAvailableLanguages.php")
      .then((response) => {
        let unAddedLanguages = response.data.filter((e) => {
          for (let i = 0; i < languages.length; i++) {
            if (e.short === languages[i]) return false;
          }
          return true;
        });
        unAddedLanguages.sort((a, b) => {
          return a.order_by > b.order_by;
        });

        unAddedLanguages.forEach((e, i) => {
          unAddedLanguages[i] = {
            is: "choice",
            // icon: faLanguage,
            text: capitalize(e.what_language),
            onClick: () => {
              axios
                .post(env.protocol + env.env + "/api/secured/appearances/AddLanguageToSitesMenu", {
                  user: sessionStorage.getItem("vfiUser"),
                  siteId: props.siteSelected.siteId,
                  langId: e.id,
                  duplicateLang: languages[0],
                })
                .then((response) => {
                  setIsLoading(true);

                  // Set info about languages
                  setUnMappedLang(response.data.sitesMenu.languages);
                  let lang = Object.keys(response.data.sitesMenu.languages);
                  setLanguages(lang); // Set languages
                  setSelectedLanguage(response.data.newLanguage.short); // Select added column

                  // Set b_modules
                  var b_mod = { ckeditor_5: [], follow: [] };
                  response.data.sitesMenu.b_modules.forEach((e, i) => {
                    if (e.type === "follow") {
                      b_mod[e.type].push(pushBModule(e));
                    } else {
                      b_mod["ckeditor_5"] = {
                        id: response.data.sitesMenu.b_modules[0].id,
                        languages: response.data.sitesMenu.b_modules[0].languages,
                      };
                    }
                  });

                  setContentData(b_mod);

                  setIsLoading(false); // Set not loading
                })
                .catch((error) => console.error(error));
            },
            style: {
              display: "flex",
              // justifyContent: "space-evenly",
            },
          };
        });
        setAddLanguageChoices(unAddedLanguages);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [languages, props.siteSelected.siteId]);

  // useEffect(() => {
  //     const handleResize = () => {
  //         setBoxWidth(window.innerWidth - 500)
  //     }
  //     window.addEventListener("resize", handleResize);
  //     return () => {
  //         window.removeEventListener("resize", handleResize);
  //     };

  // });
  const clickPosition = (e, offset = { x: 0, y: 0 }) => {
    return {
      x: e.pageX + (offset.x ? parseInt(offset.x) : 0),
      y: e.pageY + (offset.y ? parseInt(offset.y) : 0),
    };
  };

  const setOptionsList = (options, pos = undefined) => {
    setListIsOpen(true);
    setOptionsListOptions(options);
    if (pos !== undefined) setOptionsListPos(pos);
  };

  const changeSelectedLang = () => {
    let indexSelectedLan = languages.indexOf(selectedLanguage);
    indexSelectedLan === 0
      ? setSelectedLanguage(languages[indexSelectedLan + 1])
      : setSelectedLanguage(languages[indexSelectedLan - 1]);
  };

  const saveContent = () => {
    props.setConfirmBeforeNavigate("");
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/appearances/SaveSitesMenu", {
        data: contentData.ckeditor_5,
        siteId: props.siteSelected.siteId,
      })
      .then((response) => {
        let newContentData = Object.assign({}, contentData);
        newContentData.ckeditor_5 = {
          id: response.data.b_modules[0].id,
          languages: response.data.b_modules[0].languages,
        };

        setContentData(newContentData);
        setContentEditEnabled(false);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const handleCKeditor5Change = (newValue) => {
    let newContentData = Object.assign({}, contentData);
    newContentData.ckeditor_5.languages[selectedLanguage].blocks = newValue;

    setContentData(newContentData);
  };

  return (
    <div className="edit-sitesMenu row">
      <OptionsList
        isOpen={listIsOpen}
        close={() => setListIsOpen(false)}
        options={optionsListOptions}
        position={optionsListPos}
      />
      <div className="sitesMenu-section col-lg-9">
        <div className="sitesMenu-section-header">
          <div className="fixed">
            <span
              className="header-title"
              onClick={() => {
                if (contentEditEnabled) {
                  if (
                    window.confirm(
                      // languages[language].edit_page.re_navigate_warning
                      "Are you sure you want to leave? Unsaved data will be lost!"
                    )
                  )
                    props.close();
                } else {
                  props.close();
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Sites Menu
            </span>
            {languages.map((e, i) => (
              <div
                className={
                  "one-language" + (selectedLanguage === e ? " selected" : "") + (contentEditEnabled ? " disabled" : "")
                  // (chosenLanguages.length === 1 ? " single" : "")
                }
                key={i}
              >
                <div className="one-language-wrap">
                  <div
                    className="the-language"
                    onClick={() => {
                      setIsLoading(true);
                      setSelectedLanguage(e);
                      let c = Object.assign({}, contentData);

                      setContentData({ ckeditor_5: [], follow: [] });
                      setTimeout(() => {
                        setContentData(c);
                        setIsLoading(false);
                      }, 0);
                    }}
                  >
                    {e.toUpperCase()}
                  </div>
                  {languages.length > 1 && (
                    <div
                      className="trash"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to remove " +
                              e.toUpperCase() +
                              " from language list? This action cannot be undone."
                          )
                        ) {
                          setIsLoading(true);
                          axios
                            .post(env.protocol + env.env + "/api/secured/appearances/DeleteSitesMenuLanguage", {
                              id: props.siteSelected.siteId,
                              language: e,
                              user: sessionStorage.getItem("vfiUser"),
                            })
                            .then((response) => {
                              // If we are deleting the column we are currently looking at

                              setUnMappedLang(response.data.languages);
                              let lang = Object.keys(response.data.languages);
                              setLanguages(lang); // Set languages

                              // Set b_modules
                              var b_mod = { ckeditor_5: [], follow: [] };
                              response.data.b_modules.forEach((e) => {
                                if (e.type === "follow") {
                                  b_mod[e.type].push(pushBModule(e));
                                } else {
                                  b_mod["ckeditor_5"] = {
                                    id: response.data.b_modules[0].id,
                                    languages: response.data.b_modules[0].languages,
                                  };
                                }
                              });
                              setContentData(b_mod);

                              if (selectedLanguage === e) {
                                changeSelectedLang();
                              }
                              setIsLoading(false); // Set not loading
                            })
                            .catch((error) => console.error(error));
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  )}
                </div>
              </div>
            ))}
            {addLanguageChoices.length > 0 ? (
              <div
                className={"add-language" + (contentEditEnabled ? " disabled" : "")}
                onClick={(e) => {
                  setOptionsList(addLanguageChoices, clickPosition(e));
                }}
              >
                +
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={"sitesMenu-section-content"}
            // style={{ width: boxWidth + "px" }}
          ></div>
        </div>

        <div
          className={"sitesMenu-section-content"}
          style={
            {
              // width: boxWidth + "px",
              // height: parseInt(boxHeight) + 50 + "px",
            }
          }
        >
          {isLoading ? (
            <div className="blur">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          ) : (
            ""
          )}
          <div className="sitesMenu-content-top">
            {contentEditEnabled ? (
              <div className="edit-section">
                <div
                  className="save-button"
                  onClick={() => {
                    saveContent();
                  }}
                >
                  Save
                </div>
                <div
                  className="discard-button"
                  onClick={() => {
                    setContentEditEnabled(false);
                    props.setConfirmBeforeNavigate("");
                    let oldContent = JSON.parse(JSON.stringify(contentBeforeEdit));
                    setTimeout(() => {
                      setContentData(oldContent);
                    }, 0);
                  }}
                >
                  Discard changes
                </div>
              </div>
            ) : (
              <div className="edit-section">
                <div
                  className="edit-button"
                  onClick={() => {
                    setContentEditEnabled(true);
                    props.setConfirmBeforeNavigate(
                      // languages[language].edit_page.re_navigate_warning
                      "Are you sure you want to leave? Unsaved data will be lost!"
                    );
                    setContentBeforeEdit(JSON.parse(JSON.stringify(contentData)));
                  }}
                >
                  Edit
                </div>
              </div>
            )}
            <div className="headline-text">
              {/* {!isLoading ? "Sites of " + unMappedLang[selectedLanguage].siteName : ""} */}
              {!isLoading ? "Sites Menu of " + unMappedLang[selectedLanguage].siteName : ""}
            </div>
            <div
              className={"display-sitesMenu" + (!editMode ? " active" : "")}
              onClick={() => {
                setEditMode(!editMode);
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </div>
          {!isLoading && (
            <div className={"footer-content" + (contentEditEnabled ? "" : " disabled")}>
              {Object.keys(contentData.ckeditor_5.languages).length > 0 && (
                // <div className="row module" key={contentData.ckeditor_5.find(x => x.ref === selectedCol).id}>
                <div className="row module">
                  <VfiCKeditor
                    className="col-lg-7"
                    value={contentData.ckeditor_5.languages[selectedLanguage].blocks}
                    onBlur={(e) => {
                      handleCKeditor5Change(e);
                    }}
                    toolbar={props.toolbar}
                    heading={["paragraph", "heading3"]}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!editMode &&
        (!isLoading ? (
          <SitesMenuFrontEnd
            contentData={contentData}
            selectedLanguage={selectedLanguage}
            // siteName={unMappedLang[selectedLanguage].siteName}
          />
        ) : (
          <SitesMenuFrontEnd
            contentData={{ ckeditor_5: [], follow: [] }}
            selectedLanguage={selectedLanguage}
            // siteName={unMappedLang[selectedLanguage].siteName}
          />
        ))}
    </div>
  );
}
