import axios from "axios";
import env from "../../environment.json";
import { create } from "zustand";
import { setType } from "./zustandTypes";
import { ComponentType } from "react";
import { resolve } from "path";

interface whatsonSiteInfo {
  domain_name: string;
  name: string;
  order_value: number;
  other_sites: number | null;
  protocol: string;
  reg_date: string;
  siteId: number;
  slug: string;
  theme: number | null;
  type_of: string;
  viewId: string | null;
}

interface whatsonListElement {
  type: string;
  id: number;
}

interface IWhatsonDefaultShort {
  id: number;
  headline: string;
  excerpt: string | null;
}

interface IPlace extends IWhatsonDefaultShort {
  region_id: number;
}
interface IEventShort extends IWhatsonDefaultShort {}
interface ITourShort extends IWhatsonDefaultShort {}
interface IHikingShort extends IWhatsonDefaultShort {}
interface ITourOperatorShort extends IWhatsonDefaultShort {}

interface WhatsonState {
  places: IPlace[];
  events: IEventShort[];
  tours: ITourShort[];
  hiking: IHikingShort[];
  tourOperators: ITourOperatorShort[];
  whatsonSiteInfo: undefined | whatsonSiteInfo;
  getAllWhatson: () => Promise<unknown>;
  getTourOperators: () => void;
  getWhatsonSiteInfo: () => Promise<whatsonSiteInfo | undefined>;
}

export const useWhatsonStore = create<WhatsonState>((set, get) => ({
  places: [],
  events: [],
  tours: [],
  hiking: [],
  tourOperators: [],
  whatsonSiteInfo: undefined,
  getAllWhatson: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${env.protocol}${env.env}/api/whatson/GetAllWhatsonList?all&ignore_today_and_later`)
        .then((response) => {
          const data = response.data;
          const places = data.filter((f: whatsonListElement) => f.type === "place");
          const events = data.filter((f: whatsonListElement) => f.type === "event");
          const tours = data.filter((f: whatsonListElement) => f.type === "tour");
          const hiking = data.filter((f: whatsonListElement) => f.type === "hike");
          const result = { places, events, tours, hiking };
          set(result);
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getTourOperators: () => {
    axios
      .get(`${env.protocol}${env.env}/api/secured/whatson/touroperators/GetTourOperatorsList`)
      .then((response) => {
        set({ tourOperators: response.data });
      })
      .catch((error) => console.error(error));
  },
  getWhatsonSiteInfo: async () => {
    let whatsonSiteInfo = get().whatsonSiteInfo;
    if (whatsonSiteInfo === undefined) {
      whatsonSiteInfo = await fetchWhatsonSiteInfo();
      set({ whatsonSiteInfo });
    }
    return whatsonSiteInfo;
  },
}));

async function fetchWhatsonSiteInfo(): Promise<whatsonSiteInfo | undefined> {
  try {
    const response = await axios.get<whatsonSiteInfo>(`${env.protocol}${env.env}/api/whatson/GetWhatsonSite`);
    return response.data;
  } catch (error) {
    console.error("Error fetching whatson site info:", error);
    return undefined;
  }
}

export const withWhatsonStore = (BaseComponent: ComponentType<any>) => (props: object) => {
  const store = useWhatsonStore();
  return <BaseComponent {...props} whatsonStore={store} />;
};
