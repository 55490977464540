import React, { useEffect, useState } from "react";
import env from "../../../environment.json";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeart } from "@fortawesome/pro-regular-svg-icons";
// import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons";
import VfiCheckbox from "../../../assets/VfiCheckbox/VfiCheckbox";
import axios from "axios";
import { showDate, capitalize } from "../../../assets/helperFunctions";
import noImage from "../../../assets/images/empty.jpg";
import language from "./language.json";
import "./WhatsonApprovalStatusButtons.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import VfiCustomSelect from "../../../assets/VfiCustomSelect";
import VfiCKeditor from "../../../assets/VfiCKeditor/VfiCKeditor";
import { usePermCheckStore } from "src/Components/PermCheck/PermCheckStore";

export function approvalStatusChange(whatWhatson, id, value, afterFunc = () => void 0, mailText) {
  axios
    .post(env.protocol + env.env + "/api/secured/whatson/ApprovalStatusChange", {
      whatWhatson,
      id,
      value,
      userId: sessionStorage.getItem("vfiUser"),
      mailText,
    })
    .then((response) => {
      if (response.data.success) afterFunc(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

function showApproval(element) {
  let status = "";
  if (["changes required"].includes(element.approvalStatus)) status = element.approvalStatus;
  else if (["draft"].includes(element.currentStatus)) status = element.currentStatus;
  else if (element.approvalStatus === "approve") status = "approved";
  else status = element.approvalStatus;
  return capitalize(status);
}

const labelReplace = (label) => {
  switch (label) {
    case "deny":
      return "delete";
    default:
      return label;
  }
};

export function ChangeApprovalScreen({
  defaultInfo,
  updateApprovalStatus = () => void 0,
  close = () => void 0,
  title,
  subText,
  messageHeadline,
  messagePlaceholder,
  saveButtonText,
  saveButtonStyle,
  infoChange = () => void 0,
}) {
  const [info, setInfo] = useState(defaultInfo);
  const [mailText, setMailText] = useState("");
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const sendMailDecision = ["changes required", "deny"].includes(info.value);
  useEffect(() => {
    infoChange(info);
  }, [info]);

  return (
    <div className="over-all">
      <div className="over-all-content">
        <div className="oa-head">
          <div>{title ?? "Change decision"}</div>
          <div
            onClick={() => {
              close();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        {subText && <p className="oa-subtext">{subText}</p>}
        {info.state === "index" && (
          <>
            <div className="oa-approval">APPROVAL</div>
            <div className="oa-select">
              <VfiCustomSelect
                options={[
                  { label: "Approve", value: "approve" },
                  {
                    label: "Changes required",
                    value: "changes required",
                  },
                  { label: "Delete", value: "deny" },
                ]}
                defaultValue={{
                  label: capitalize(labelReplace(info.value)),
                  value: info.value,
                }}
                onChange={(e) => {
                  setInfo({ ...info, value: e.value });
                }}
              />
            </div>
          </>
        )}
        {info.state === "mail" && (
          <>
            <div className="oa-approval">{messageHeadline ?? "REASON"}</div>
            <div className="oa-mail-text">
              <VfiCKeditor
                placeholder={messagePlaceholder ?? "Reason for " + info.value}
                onChange={(e) => {
                  setMailText(e);
                }}
                defaultValue={mailText}
              />
            </div>
          </>
        )}
        <div className="oa-buttons">
          <button disabled={buttonsDisabled} className="cancel-button" onClick={() => close()}>
            CANCEL
          </button>
          <button
            disabled={buttonsDisabled}
            className="save-button"
            onClick={() => {
              if (sendMailDecision && info.state !== "mail") {
                // Go to mail thingy.
                setInfo({ ...info, state: "mail" });
                setMailText("");
              } else {
                setButtonsDisabled(true);
                // axios post and save.
                approvalStatusChange(
                  info.type,
                  info.id,
                  info.value,
                  () => {
                    updateApprovalStatus(info.type, info.id, info.value);
                    close();
                  },
                  mailText
                );
              }
            }}
            style={info.state === "mail" ? saveButtonStyle : undefined}
          >
            {sendMailDecision && info.state !== "mail" ? "NEXT" : saveButtonText ?? "SAVE"}
          </button>
        </div>
      </div>
    </div>
  );
}

export function WhatsonApprovalStatusButtons({ element, whatWhatson, onChange = () => void 0, clickFunc }) {
  const [status, setStatus] = useState(element.approvalStatus);
  const lang = localStorage.getItem("language");
  const sendFunc = clickFunc
    ? (s) => {
        clickFunc(s);
      }
    : (s) => {
        approvalStatusChange(whatWhatson.toLowerCase(), element.id, s, () => {
          setStatus(s);
          onChange({ ...element, approval_status: s });
        });
      };

  return (
    <div className="approval-wrap">
      <div
        className={status === "approved" ? "approve chosen" : "approve"}
        onClick={() => {
          sendFunc("approved");
          // approvalStatusChange(element.id, "approve");
        }}
      >
        {language[lang].table_body.approval.approve}
      </div>
      <div
        className={status === "changes required" ? "changes-required chosen" : "changes-required"}
        onClick={() => {
          sendFunc("changes required");
          // approvalStatusChange(element.id, "changes required");
        }}
      >
        {language[lang].table_body.approval.changes_required}
      </div>
      <div
        className={status === "deny" ? "deny chosen" : "deny"}
        onClick={() => {
          sendFunc("deny");
          // approvalStatusChange(element.id, "deny");
        }}
      >
        {language[lang].table_body.approval.deny}
      </div>
    </div>
  );
}

function WhatsonContent(props) {
  const { permCheckFunc } = usePermCheckStore();
  const protocolAndHost = env.protocol + env.env;
  const entityId = props.element.id;

  let approvalState = props.element.approval_status;
  const approvalStateRenames = { approve: "approved", deny: "denied" };
  if (approvalStateRenames[approvalState]) {
    approvalState = approvalStateRenames[approvalState];
  }
  const typeForPermisson = {
    Event: "events",
    Place: "places",
    Tour: "tours",
    "Tour provider": "tourOperators",
  };
  const isDeniedToEdit =
    props.element.currentStatus === "published" &&
    permCheckFunc({ type: typeForPermisson[props.whatWhatson], id: entityId }) === false;

  return (
    <tr className={isDeniedToEdit ? "transparent" : ""}>
      <td
        className="file"
        onClick={() => {
          if (props.whatWhatson.toLowerCase() === "event") {
            axios
              .post(env.protocol + env.env + "/api/whatson/events/GetEvent?for-edit", {
                id: entityId,
              })
              .then((response) => {
                props.editContent(response.data);
              })
              .catch((error) => console.error(error));
          }

          if (props.whatWhatson.toLowerCase() === "place") {
            axios
              .post(env.protocol + env.env + "/api/whatson/places/GetPlace?for-edit", {
                id: entityId,
              })
              .then((response) => {
                props.editContent(response.data);
              })
              .catch((error) => console.error(error));
          }

          if (props.whatWhatson.toLowerCase() === "hiking") {
            axios
              .post(env.protocol + env.env + "/api/whatson/hiking/GetHiking?for-edit", {
                id: entityId,
              })
              .then((response) => {
                props.editContent(response.data);
              })
              .catch((error) => console.error(error));
          }

          if (props.whatWhatson.toLowerCase() === "tour") {
            axios
              .post(env.protocol + env.env + "/api/whatson/tours/GetTour?for-edit", {
                id: entityId,
              })
              .then((response) => {
                props.editContent(response.data);
              })
              .catch((error) => console.error(error));
          }

          if (props.whatWhatson.toLowerCase() === "tour provider") {
            axios
              .post(env.protocol + env.env + "/api/secured/whatson/touroperators/GetTourOperator?for-edit", {
                id: entityId,
              })
              .then((response) => {
                const tourOperator = response.data.tourOperator;
                tourOperator.data = { id: tourOperator.id };
                props.editContent(tourOperator);
              })
              .catch((error) => console.error(error));
          }
        }}
      >
        {props.element.submittedChanges ? <div className="changesIcon">1</div> : ""}
        <img
          src={
            props.element.mainImage.id !== null &&
            props.element.mainImage.id !== undefined &&
            props.element.mainImage.id !== 0 &&
            props.element.mainImage.extension !== null
              ? protocolAndHost +
                "/uploads/" +
                props.element.mainImage.file_name +
                "_thumbnail." +
                props.element.mainImage.extension
              : noImage
          }
          alt="media view"
        />
        <div className="file-info">
          <div className="name">{props.element.title}</div>
        </div>
      </td>
      <td className="author">
        <div className="author-name">{props.element.author.firstname + " " + props.element.author.lastname}</div>
      </td>
      <td className="region">
        <div className="what-region">{props.element.region ? props.element.region : ""}</div>
      </td>
      <td className="location">
        <div className="location-sites">{props.element.location ? props.element.location : ""}</div>
      </td>
      <td className="approval">{showApproval(props.element)}</td>
      <td className="date">
        <div className="the-date">{showDate(props.element.lastUpdate)}</div>
      </td>
      <td className="check-select">
        <VfiCheckbox checked={props.element.selected ?? false} onChange={() => props.selectById(entityId)} />
      </td>
    </tr>
  );
}

export default WhatsonContent;
