import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOption } from "./types/types";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Fragment, ReactNode } from "react";

interface INewElementPosition {
  scrollHeight: number;
  scrollWidth: number;
}
interface props {
  element: IOption;
  i: number;
  close?: (dontCloseAfter: boolean) => void;
  setExtraListShown: (list: IOption[], newE: INewElementPosition) => void;
}
export default function OptionsInList(props: props) {
  const { element, i, close, setExtraListShown } = props;

  const defaultChoiceClickEffect = (dontCloseAfter: boolean) => {
    close?.(dontCloseAfter);
  };

  if (element.appear !== undefined)
    if (element.appear() === false) {
      return <Fragment />;
    }

  if (element.element) return element.element;

  switch (element.is.toLowerCase()) {
    case "choice":
      {
        const icon = element.icon;
        const text = element.text;
        const onClick = element.onClick;
        const style = element.style;
        const rightIcon = element.rightIcon;
        const rightIconClick = element.rightIconClick;
        const rightIconStyle = element.rightIconStyle;

        return (
          <div key={i} className="option-in-list" style={style}>
            <div
              className="clickable-area"
              onClick={() => {
                defaultChoiceClickEffect(element.dontCloseAfter ?? false);
                if (onClick !== undefined) {
                  onClick(element);
                }
              }}
            >
              {icon !== undefined ? (
                <div className="icon">
                  <FontAwesomeIcon icon={icon as IconProp} />
                </div>
              ) : (
                ""
              )}
              {text !== undefined ? <div className="text">{text}</div> : ""}
              {rightIcon && <div className="right-icon-roomer" />}
            </div>
            {rightIcon && (
              <div className="right-icon" style={rightIconStyle} onClick={() => rightIconClick?.(element)}>
                <FontAwesomeIcon icon={rightIcon} />
              </div>
            )}
          </div>
        );
      }
      break;
    case "choice_list":
      {
        const icon = element.icon;
        const text = element.text;
        const onClick = element.onClick;
        const list = element.list;
        const style = element.style;

        return (
          <div
            key={i}
            className="option-in-list"
            onMouseEnter={(e) => {
              if (list !== undefined && e.target instanceof Element) {
                const newE = {
                  scrollHeight: e.target.scrollHeight,
                  scrollWidth: e.target.scrollWidth,
                };
                setExtraListShown(list, newE);
              }
            }}
            onMouseLeave={() => {}}
            onClick={() => {
              onClick?.(element);
            }}
            style={style}
          >
            <div className="clickable-area">
              {icon !== undefined ? (
                <div className="icon">
                  <FontAwesomeIcon icon={icon as IconProp} />
                </div>
              ) : (
                ""
              )}
              {text !== undefined ? <div className="text">{text}</div> : ""}
              <FontAwesomeIcon className="point-right" icon={faChevronRight as IconDefinition} />
            </div>
          </div>
        );
      }
      break;
    case "title":
      {
        const style = element.style;
        return (
          <div className="title" key={i} style={style}>
            {element.text}
          </div>
        );
      }
      break;
    case "custom":
      return element.element ?? <Fragment />;
      break;
    case "break":
    case "hr":
    case "br":
      return <hr key={i} />;
      break;
    case "blank":
    case "empty":
      break;
    default:
      break;
  }

  return <Fragment />;
}
