import { ChangeEvent, useEffect, useState } from "react";
import { CreateRedirectFormType, PageOption, AddRedirectResponse, SelectSiteType, PageItem } from "../types";
import ComboSelect from "../../../../../Inputs/ComboSelect/index";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import axios, { AxiosError } from "axios";
import env from "../../../../../../environment.json";
import styles from "../RedirectsDisplay.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import Search from "./Search";
import PageChoose from "src/Components/Content/Overlay/PageChoose/PageChoose";
import VfiCustomSelect from "src/assets/VfiCustomSelect";
import { tOption } from "src/assets/VfiCustomSelect/types";
import { IPageSimplified } from "src/Components/Content/Pages/types";

export default function CreateRedirectForm({
  pageOptions,
  allPages,
  createNew,
  setCreateNew,
  onUpdate,
  close,
}: CreateRedirectFormType): JSX.Element {
  // Current slug for page
  const [slug, setSlug] = useState("");
  // Redirect slug to use
  const [redirect, setRedirect] = useState("");
  // Whether the form is loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Page language ID to redirect
  const [pageLanguageID, setPageLanguageID] = useState<string>("");
  // Validation error for redirect
  const [validationError, setValidationError] = useState<string>("");
  const [selectedSiteName, setSelectedSiteName] = useState<string>("Select Page");
  const [pageInfo, setPageInfo] = useState<IPageSimplified | undefined>(undefined);
  const [availableLanguages, setAvailableLanguages] = useState<tOption[] | undefined>(undefined);
  const [selectedLanguage, setSelectedLanguage] = useState<tOption | undefined>(undefined);
  // Overlay for selecting a page
  const { addOverlay } = overlayStore();

  useEffect(() => {
    if (pageInfo && selectedLanguage) {
      setSlug(pageInfo.languages[selectedLanguage.value].url_slug);
      setPageLanguageID(pageInfo.languages[selectedLanguage.value].id.toString());
    }
  }, [selectedLanguage]);

  const selectOverlay: () => void = () => {
    // addOverlay("pageChoose", (close: () => void) => {
    //   return (
    //     <SelectSite
    //       options={pageOptions}
    //       onSelect={(option: PageOption) => {
    //         const newPageLanguageID = option.value;
    //         const newSlug = allPages.find((page) => page.pageLanguageID.toString() === newPageLanguageID)?.slug;
    //         if (newSlug) {
    //           setSlug(newSlug);
    //           setPageLanguageID(newPageLanguageID);
    //           setSelectedSiteName(option.label);
    //         }
    //         close();
    //       }}
    //       close={close}
    //     />
    //   );
    // });

    addOverlay("plain_card", (close) => (
      <PageChoose
        style={{ width: "90vw", height: "90vh" }}
        language={localStorage.getItem("language") ? localStorage.getItem("language") : "english"}
        data={{}}
        selectPage={(page: IPageSimplified) => {
          const cAvailableLanguages = Object.keys(page.languages).map((lang) => ({ label: lang, value: lang }));
          setPageInfo(page);
          setAvailableLanguages(cAvailableLanguages);
          setSelectedLanguage(cAvailableLanguages[0]);
        }}
        close={() => {
          close();
        }}
      />
    ));
  };

  /**
   * onChange for the page select
   *
   * @author 					Pætur Mortensen
   */
  function pageSelectChange(option: PageOption) {
    const newPageLanguageID = option.value;
    const newSlug = allPages.find((page) => page.pageLanguageID.toString() === newPageLanguageID)?.slug;

    // If newSlug is not set, exit this function
    if (!newSlug) return;

    // Set slug and pageLanguageID for this redirect
    setSlug(newSlug);
    setPageLanguageID(newPageLanguageID);
  }

  /**
   * Submit the create redirect form
   *
   * @author 					Pætur Mortensen
   */
  function submit() {
    // If no page has been selected, throw error and return
    if (!pageLanguageID) {
      setValidationError("You must select a page to redirect");
      return;
    }

    // If redirect slug is empty, throw error and return
    if (!redirect) {
      setValidationError("You must set a redirect slug");
      return;
    }

    // Set this form to loading
    setIsLoading(true);

    // Now call the API
    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/AddPageRedirect", { pageLanguageID, slug: redirect })
      .then((response: AddRedirectResponse) => {
        // Extract the data from the API response
        const data = response.data.data;

        // If this was not successful, show validation error
        if (!data.success) {
          setValidationError(data.validationMsg);
        } else {
          // Redirect added, close and call onUpdate
          onUpdate();
          onClose();
        }
      })
      .catch((error: AxiosError) => {
        setValidationError("Something went wrong. Try again or contact support if this keeps happening");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * Reset the form to initial state
   *
   * @author 					Pætur Mortensen
   */
  function reset_form() {
    setSlug("");
    setRedirect("");
    setIsLoading(false);
    setPageLanguageID("");
    setValidationError("");
    setSelectedSiteName("Select Page");
  }

  /**
   * onClose event for the form
   *
   * @author 					Pætur Mortensen
   */
  function onClose() {
    if (close) {
      close();
    } else {
      // Used to close the form
      setCreateNew(false);
    }
    // Reset the form and all values
    reset_form();
  }

  // Return empty if we are not creating new redirect

  return (
    <div className={styles.createForm}>
      <h2 className={styles.titleForm}>Redirect</h2>
      <p>
        Use 301 redirects to route traffic from an old URL. Do this manually here or on the specific page you want to
        move.
      </p>
      <div className={styles.comboContainerWrapper}>
        <div className={styles.comboContainer}>
          <label>SITE</label>
          <button className={styles.selectButton} onClick={selectOverlay}>
            {selectedSiteName}
          </button>
        </div>
        {pageInfo && <div className={styles.pathText}>{pageInfo.languages[selectedLanguage?.value ?? "en"].title}</div>}
      </div>
      {availableLanguages?.length && (
        <div className={styles.comboContainer}>
          <label>LANGUAGE</label>
          <VfiCustomSelect
            options={availableLanguages}
            value={selectedLanguage}
            onChange={(v) => {
              if (v !== undefined) {
                setSelectedLanguage(v as tOption);
              }
            }}
          />
        </div>
      )}
      <div className={styles.formRow}>
        <div className={styles.formField}>
          <label>OLD PATH</label>
          <VfiInputText disabled={true} value={slug} />
        </div>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon icon={faArrowRight as IconProp} className={styles.arrowIcon} />
        </div>
        <div className={styles.formField}>
          <label>REDIRECT TO PATH</label>
          <VfiInputText
            placeholder="Redirect..."
            value={redirect}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setRedirect(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.validationError}>{validationError}</div>
      <div className={styles.buttonGroup}>
        <button className={styles.cancelButton} onClick={onClose}>
          CANCEL
        </button>
        <button className={styles.addRedirect} onClick={submit}>
          SAVE AND CLOSE
        </button>
      </div>
    </div>
  );
}

function SelectSite({ options, onSelect, close }: SelectSiteType): JSX.Element {
  const [redirectPages, setRedirectPages] = useState<PageItem[]>([]);
  const [tablePages, setTablePages] = useState<PageItem[]>([]);

  return (
    <div className={styles.selectSite}>
      <h3>Select a Site</h3>
      <Search allPages={redirectPages} setTablePages={setTablePages} />
      <ul className={styles.siteList}>
        {options.map((option) => (
          <li
            key={option.value}
            onClick={() => {
              onSelect(option);
              close();
            }}
            className={styles.siteItem}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
}
