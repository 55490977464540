import { Fragment } from "react";

interface breakTextWithBrProps {
  text: string;
}

/**
 * This component will split up text where it finds the keyword '{br}' and show them with a `<br />` tag between them.
 *
 * @author Hemming Petersen
 */
export function BreakTextWithBr({ text }: breakTextWithBrProps) {
  const textParts = text.split("{br}");
  return (
    <Fragment>
      {textParts.map((part, index) => (
        <Fragment key={part + index}>
          {part}
          {index < textParts.length - 1 && <br />}
        </Fragment>
      ))}
    </Fragment>
  );
}
