import React, { Component, createRef } from "react";
import "./Overlay.scss";
import MediaEdit from "./MediaEdit/MediaEdit";
import UserOverlay from "./UserOverlay/UserOverlay";
import MediaChoose from "./MediaChoose/MediaChoose";
import AdvancedTextArea from "./AdvancedTextArea/AdvancedTextArea";
import PageChoose from "./PageChoose/PageChoose";
import SvgChoose from "./SvgChoose/SvgChoose";
import PageModuleChoose from "./PageModuleChoose/PageModuleChoose";
import GridTemplateChoose from "./GridTemplateChoose/GridTemplateChoose";
import WhatsonChoose from "../../../assets/PageModules/WhatsonFilterShow/WhatsonChoose";
import { faExclamationCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageLocation from "./PageLocation";
import InfoscreenStandardFilters from "./InfoscreenStandardFilters/InfoscreenStandardFilters";
import useScrollStore from "src/Components/ZustandStores/scrollStore";

class Overlay extends Component {
  constructor(props) {
    super(props);

    this.scrollRef = createRef();
    this.state = {
      extraClassName: "",
      addOne: null,
      latestChosenDisplay: "",
      isBottom: false,
    };
    this.show = [];
    this.oldDisplay = "";
  }

  componentDidUpdate() {
    if (this.props.display.toLowerCase() !== this.state.latestChosenDisplay) {
      if (this.props.display !== "") {
        this.show.push({
          display: this.props.display.toLowerCase(),
          data: JSON.parse(JSON.stringify(this.props.data)),
        });
      }
      this.setState({ latestChosenDisplay: this.props.display.toLowerCase() });
    }
    const newDisplay = this.show[this.show.length - 1] ? this.show[this.show.length - 1].display.toLowerCase() : "";
    if (this.scrollRef.current) {
      if (newDisplay !== this.oldDisplay) {
        this.oldDisplay = newDisplay;
        useScrollStore.getState().addScrollElement(this.scrollRef.current);
      }
    }
  }

  closeLatest(closeCall) {
    this.show.splice(this.show.length - 1, 1);
    this.props.closeOverlay(closeCall);
    this.setState({});
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight;
    if (bottom && this.state.isBottom === false) {
      this.setState({ isBottom: true });
    }
    if (!bottom && this.state.isBottom === true) {
      this.setState({ isBottom: false });
    }
  };

  render() {
    let whatOverlay;
    const whatDisplay = this.show[this.show.length - 1] ? this.show[this.show.length - 1].display.toLowerCase() : "";
    const data = this.props.data;
    switch (whatDisplay) {
      case "mediaedit":
        whatOverlay = (
          <MediaEdit
            data={this.show[this.show.length - 1].data}
            close={(e) => {
              this.closeLatest(e);
            }}
            site={this.props.siteRef}
            addOneMedia={(e) => {
              if (this.show.length <= 1) this.props.addOne(e);
            }}
          />
        );
        break;
      case "mediachoose":
        whatOverlay = (
          <MediaChoose
            data={this.show[this.show.length - 1].data}
            close={(e) => {
              this.closeLatest(e);
            }}
            returnValue={(e) => {
              this.setState({ addOne: e });
              this.props.addOne(e);
            }}
            addOne={this.state.addOne}
            openOverlay={this.props.openOverlay}
            setSiteRef={this.props.setSiteRef}
            site={this.props.siteRef}
            showMore={this.state.isBottom}
            setBottom={(e) => this.setState({ isBottom: e })}
            callbackArg={this.props.data.callbackArg || null}
          />
        );
        break;
      case "svgiconchoose":
        whatOverlay = (
          <SvgChoose
            data={this.show[this.show.length - 1].data}
            close={(e) => {
              this.closeLatest(e);
            }}
            returnValue={(e) => {
              this.setState({ addOne: { id: e, time: new Date().getTime() } });
              this.props.addOne({ id: e, time: new Date().getTime() });
            }}
            openOverlay={this.props.openOverlay}
            setSiteRef={this.props.setSiteRef}
            site={this.props.siteRef}
          />
        );
        break;
      case "pagemodulechoose":
        whatOverlay = (
          <PageModuleChoose
            data={this.show[this.show.length - 1].data}
            close={(e) => {
              this.closeLatest(e);
            }}
            returnValue={(e) => {
              this.setState({ addOne: e });
              this.props.addOne(e);
            }}
          />
        );
        break;
      case "pagelocation":
        whatOverlay = <PageLocation data={this.show[this.show.length - 1].data} close={(e) => this.closeLatest(e)} />;
        break;
      case "advancedtextarea":
        whatOverlay = (
          <AdvancedTextArea
            data={this.show[this.show.length - 1].data}
            close={(e) => {
              this.closeLatest(e);
            }}
            addContent={this.props.addContent}
          />
        );
        break;
      case "pagechoose":
        whatOverlay = (
          <PageChoose
            language={this.props.language}
            data={this.show[this.show.length - 1].data}
            showInSidebar={this.props.showInSidebar}
            selectPage={(e) => {
              this.setState({ addOne: { ...e, time: new Date().getTime() } });
              this.props.addOne({ ...e, time: new Date().getTime() });
              if (this.props.data.afterFunc) this.props.data.afterFunc(e);
            }}
            close={(e) => {
              this.closeLatest(e);
            }}
          />
        );
        break;
      case "gridtemplatechoose":
        whatOverlay = (
          <GridTemplateChoose
            data={this.show[this.show.length - 1].data}
            close={(e) => {
              this.closeLatest(e);
            }}
            returnV={(e) => {
              this.setState({ addOne: e });
              this.props.addOne(e);
            }}
          />
        );
        break;
      case "whatsonchoose":
        whatOverlay = (
          <WhatsonChoose
            close={(e) => {
              this.closeLatest(e);
            }}
            data={this.show[this.show.length - 1].data}
            apply={(newData) => {
              this.props.addOne(newData);
              this.setState({ addOne: newData });
              this.closeLatest();
            }}
            cancel={() => {
              this.closeLatest();
            }}
          />
        );
        break;
      case "whatsonpageschoose":
        whatOverlay = (
          // <WhatsonPagesChoose close={(e)=>{
          //   this.closeLatest(e);
          // }}
          // data={this.show[this.show.length - 1].data}
          // apply={(newData)=>{
          //   this.props.addOne(newData);
          //   this.setState({ addOne: newData });
          //   // if(this.props.data.afterFunc)
          //   //   this.props.data.afterFunc(newData);
          //   this.closeLatest();
          // }}
          // cancel={()=>{
          //   this.closeLatest();
          // }}
          // />
          <WhatsonChoose
            close={(e) => {
              this.closeLatest(e);
            }}
            data={this.show[this.show.length - 1].data}
            apply={(newData) => {
              this.props.addOne(newData);
              this.setState({ addOne: newData });
              this.closeLatest();
            }}
            cancel={() => {
              this.closeLatest();
            }}
            steps={[0]}
          />
        );
        break;
      case "useredit":
      case "newuser":
        // whatOverlay = (
        //   <UserOverlay
        //     extra={"newUser"}
        //     close={(e) => {
        //       this.closeLatest(e);
        //     }}
        //     // data={this.show[this.show.length - 1].data}
        //     data={this.props.data}
        //     // onChange={this.props.onChange}
        //     // saveBtn={this.props.saveBtn}
        //     handleOptionChange={this.props.handleOptionChange}
        //     clearAllOptionChange={this.props.clearAllOptionChange}
        //     SetState={this.props.SetState}
        //     panel={this.props.panel}
        //     checkboxFiltering={this.props.checkboxFiltering}
        //     setResponse={this.props.setResponse}
        //     response={this.props.response}
        //     openOverlay={this.props.openOverlay}
        //   />
        // );

        console.log(this.props.data);
        console.log(whatDisplay);

        whatOverlay = (
          <UserOverlay
            extra={whatDisplay === "newuser" ? "newUser" : "editUser"}
            close={(e) => {
              this.closeLatest(e);
            }}
            // data={this.show[this.show.length - 1].data}
            data={this.props.data}
            // onChange={this.props.onChange}
            // saveBtn={this.props.saveBtn}
            handleOptionChange={this.props.handleOptionChange}
            clearAllOptionChange={this.props.clearAllOptionChange}
            listOptions={this.props.listOptions}
            checkboxFiltering={this.props.checkboxFiltering}
            SetFilterData={this.props.SetFilterData}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            SetState={this.props.SetState}
            panel={this.props.panel}
            addFilterDroppedDown={this.props.addFilterDroppedDown}
            openCloseFilter={this.props.openCloseFilter}
            response={this.props.response}
            setResponse={this.props.setResponse}
            openOverlay={this.props.openOverlay}
          />
        );

        break;
      case "popupselect":
        whatOverlay = (
          <div className="popup-select">
            <div className="pop-title">{data?.title.toLocaleUpperCase()}</div>
            <div className="pop-description">
              <FontAwesomeIcon icon={faExclamationCircle} />
              {data?.description}
            </div>
            <div className="pop-buttons">
              <button
                className="cancel-button"
                onClick={() => {
                  this.closeLatest(false);
                }}
              >
                Cancel
              </button>
              <button
                className="submit-button"
                onClick={() => {
                  this.closeLatest(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
        break;
      case "infoscreenstandardfilters":
        whatOverlay = (
          <InfoscreenStandardFilters
            data={data.data}
            close={(e) => {
              this.closeLatest(e);
            }}
          />
        );
        break;
      default:
        whatOverlay = "";
        if (this.show[this.show.length - 1]) break;
    }

    return whatOverlay === "" ? (
      ""
    ) : (
      <div
        className={"overlayParent" + (whatDisplay === "popupselect" ? " popupselect" : "")}
        onScroll={this.handleScroll}
      >
        <div className="overlay" ref={this.scrollRef}>
          {whatOverlay}
        </div>
        <div className="overlay-blur" style={this.props?.data?.overlayBlurStyle} />
      </div>
    );
  }
}
export default Overlay;
