let oneHour = 2000 * 60 * 60;

export function formatTime(dateStr: string): string {
  let now: Date = new Date(); // Some how dynamically set timezone to server timezone
  let date: Date = new Date(dateStr.replace(" ", "T") + "Z");
  if (!date.getTime()) return "--/-- ----";
  let diff: number = Math.floor((now.getTime() + oneHour - date.getTime()) / 1000);

  if (diff < 0) return "0 minutes ago";
  if (diff < 3600) return Math.floor(diff / 60) + " minutes ago";
  if (diff < 86400) return Math.floor(diff / 3600) + " hours ago";

  let d: any[] = date.toISOString().split("T")[0].split("-");

  return (
    d[2] + "/" + d[1] + " " + d[0]
    // + ' '
    //     + date.toTimeString().split(' ')[0];
  );
}
