import { useEffect, useState } from "react";
import Layout from "../Layout";
import styles from "./Location.module.scss";
import MapDropdown from "./components/map-dropdown/MapDropdown";
import PlaceList from "./components/place-list/PlaceList";
import SimpleCheckbox from "src/Components/ui/checkbox/SimpleCheckbox/SimpleCheckbox";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";

/**
 * Location props
 */
interface ILocationProps {
  title: string;
  subtitle: string;
}

/**
 * Main component - Location
 */
export default function Location({ title = "Location", subtitle = "Please enter the location." }: ILocationProps) {
  /**
   * Store properties
   */
  const { location, setLocation, validateLocation } = getWhatsonStore();

  useEffect(() => {
    // return () => {
    //   /**
    //    * Validate the location on unmount
    //    */
    //   validateLocation();
    // };
  }, []);

  /**
   * Render
   */
  return (
    <Layout title={title} subtitle={subtitle}>
      {/* Toggle */}
      <div className={styles.toggle}>
        <SimpleCheckbox
          id="enabled"
          checked={location.custom}
          label="I can't find it in the drop-down list"
          onChange={() => setLocation({ custom: !location.custom })}
        />
      </div>
      {/* Map dropdown */}
      <MapDropdown />
      {/* Place list */}
      <PlaceList />
    </Layout>
  );
}
