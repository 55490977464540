import axios from "axios";
import env from "../../../environment.json";
import { create } from "zustand";
// import { setType } from "./zustandTypes";
import { ComponentType } from "react";
import { setType } from "../zustandTypes";

/**
 * This store holds information as states about the pages available in the system.
 * It also allows us to fetch pages from the server.
 *
 */

interface IPages {
  [page_id: string | number]: {
    page_id: number;
    slug: string;
    children?: IPages;
  };
}

interface ISites {
  [site_id: string | number]: IPages;
}

interface IPagesStore {
  sites: ISites;
  view: "leaflet" | "list";
  view_interact_type: "open" | "insert" | "relate_to";
  getPages: (site_id: number | null) => void;
  getSites: () => void;
  setPagesStore: (states: { [key: string]: Partial<IPagesStore> }) => void;
  init: () => void;
}

const inital_states: any = {
  sites: {},
  view: "leaflet",
  view_interact_type: "open",
};

export const pagesStore: any = create((set: setType<IPagesStore>, get: () => IPagesStore) => ({
  ...inital_states,

  getPages: (site_id = null) => {
    // Fetch pages and set state based on data

    set((state) => {
      return {
        sites: {
          ...state.sites,
          [site_id ?? 0]: {}, // Figure out requirenments later !!!
        },
      };
    });
  },

  getSites: () => {
    set({
      sites: {},
    });
  },

  setPagesStore: (states: any) => {
    set({
      ...states,
    });
  },

  init: () => {},
}));
