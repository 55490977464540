import styles from "../RedirectsDisplay.module.scss";
import { SearchType } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";

/**
 * Search section for redirects
 *
 * @author 					Pætur Mortensen
 */
export default function Search({ allPages, setTablePages }: SearchType): JSX.Element {
  // Search string (current value of the search input)
  const [search, setSearch] = useState<string>("");

  function onSearchInputChange(search: string) {
    // Lowercase search string for case-insensitive search
    const searchString = search.toLowerCase();
    // Split the search string by spaces into words
    const searchArr = searchString.split(" ");
    // Initialize new options array as empty
    const newPages = [];

    /**
     * Check whether search string is found in searchable string
     *
     * This function checks each word in the search string but not necessarily in order
     *
     * @author 					Pætur Mortensen
     */
    const stringFound = (searchable: string) => {
      // For each word in the search array
      for (const word of searchArr) {
        // If the word is not in the searchable string, just retur false
        if (!searchable.includes(word)) return false;
      }

      // All words have been found in the searchable string, return true
      return true;
    };

    // For each option...
    for (const index in allPages) {
      const page = allPages[index];

      // Create a searchable string from the label and any added searchable strings
      if (!page.searchable) continue;
      const searchable = page.searchable.toLowerCase();
      // Add the option if the searchable string contains all words from the search string
      if (stringFound(searchable)) newPages.push(page);
    }

    // Set the filtered results and update search input value
    setTablePages(newPages);
    setSearch(search);
  }

  return (
    <div className={styles.searchSection}>
      <div className={styles.searchInputContainer}>
        <input
          className={styles.searchInput}
          placeholder="Search redirects..."
          type="text"
          value={search}
          onChange={(e) => {
            onSearchInputChange(e.target.value);
          }}
          onFocus={(e) => {
            e.target.placeholder = "";
          }}
          onBlur={(e) => {
            e.target.placeholder = "Search redirects...";
          }}
        />
        <FontAwesomeIcon className={styles.searchIcon} icon={faMagnifyingGlass as IconProp} />
      </div>
    </div>
  );
}
