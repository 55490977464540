import React from "react";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import OneMedia from "./OneMedia";
import "./OneMediaWithSubtext.scss";
import languages from "../language.json";
const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";

export default function OneMediaWithSubtext(props) {
  const theLanguage = languages[language].one_media_with_subtext;

  return (
    <div className={"one-media-with-subtext " + (props.className ? props.className : "")}>
      <div>
        <OneMedia
          siteRef={props.siteRef}
          setSiteRef={props.setSiteRef}
          openOverlay={props.openOverlay}
          whatMedia={["images", "videos"]}
          media={props.media}
          onClick={props.onClickMedia}
        />
        <VfiCKeditor
          value={props.defaultSubText}
          onBlur={props.onBlurSubText}
          toolbar={props.toolbar}
          placeholder={theLanguage.under_media_placeholder}
        />
      </div>
      {/* <VfiTextarea
          className="one-media-subtext"
          defaultValue={props.defaultSubText}
          onChange={props.onChangeSubText}
        /> */}
    </div>
  );
}
