//All sliders, including grids are in this EditModule

import { useEffect, useState } from "react";
import "./ImageSettings.scss";
import { VfiCheckbox2 } from "../../VfiCheckbox";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import VfiInputText from "../../VfiInputText/VfiInputText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import Dropzone from "react-dropzone";
import { uploadMedia } from "../../helperFunctions";
import { cloneDeep } from "lodash";
import { faArrowToTop } from "@fortawesome/pro-light-svg-icons";
import UploadOrFindImage from "./UploadOrFindImage";

export default function ImageSettings(props) {
  const [values, setValues] = useState(props.values);
  const [screen, setScreen] = useState("image");
  const { change, openInfo, isArticle } = props;

  useEffect(() => {
    if (values) {
      if (change) change(values);
    }
  }, [values, change]);

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  return (
    <div className="edit-module image-settings">
      <div className="top">
        <div className={"top-choice " + (screen === "image" ? "active " : "")} onClick={() => setScreen("image")}>
          IMAGE
        </div>
        <div
          className="close"
          onClick={() => {
            props.close();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="content">
        <UploadOrFindImage
          index={props.index}
          siteId={props.siteRef}
          values={values}
          openOverlay={props.openOverlay}
          setValues={setValues}
          afterUpload={(response) => {
            // // const newMedium = response.data[0];
            // // setValues({...newMedium,src:newMedium.medium.large});
            // props.setModuleKeyInEdit("media_add");
            props.openOverlay(
              "mediaChoose",
              {
                filter: { mediaType: ["images", "videos"] },
                singleMedia: true,
                selectedMedias: response.data.map((e) => e.id),
              },
              props.index,
              "medium"
            );
            props.close();
          }}
        />

        {!isArticle && (
          <>
            <label className="option whole-click">
              <div className="label">Show link</div>
              <div className="value">
                <VfiCheckbox2
                  style={{ pointerEvents: "none" }}
                  checked={values.show_link}
                  isRadio={true}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      show_link: e.target.checked,
                    });
                  }}
                />
              </div>
            </label>
            <div className="title-2">Fullscreen image text Info</div>
            <label className="option description">
              <div
                className="info"
                onClick={() => {
                  if (openInfo) {
                    openInfo(
                      <div>
                        <h1>Fullscreen image text info</h1>
                        <p>Something about it being in the image slider, and can be used to link to instagram.</p>
                      </div>
                    );
                  }
                }}
              >
                i
              </div>
              <div className="label">Description</div>
              <VfiCKeditor
                key={values.side_description}
                value={values.side_description}
                onBlur={(e) => {
                  setValues({
                    ...values,
                    side_description: e,
                  });
                }}
              />
            </label>
            <label className="option link">
              <div className="label">Link</div>
              <div className="value">
                <VfiInputText
                  value={values.side_url ?? ""}
                  onChange={(e) => {
                    setValues({ ...values, side_url: e.target.value });
                  }}
                />
              </div>
            </label>
          </>
        )}
      </div>
    </div>
  );
}
