import React, { Component } from "react";
import env from "../../../environment.json";
import "./TreeSidebar.scss";
import TreeBranch from "./TreeBranch";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faCaretDown } from "@fortawesome/pro-solid-svg-icons";

class TreeSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagesChecked: false,
      sitesChecked: false,
      error: false,
      errorMessage: "",
      sites: [{ label: "Tourism EN", value: "1" }],
      draggedElement: {},
      siteSelected: { label: "Select Site", value: "0" },
      pageSelected: -1,
    };

    this.sites = [];

    this.pages = [
      // {index:1,name:"See & Do",childOf:-1,order:100},
      // {index:2,name:"Activities",childOf:1,order:100},
      // {index:3,name:"Plan your stay",childOf:-1,order:101},
      // {index:4,name:"What's On",childOf:-1,order:102},
      // {index:5,name:"About",childOf:-1,order:103},
      // {index:6,name:"Hiking",childOf:2,order:100},
      // {index:7,name:"Dining",childOf:2,order:101},
      // {index:8,name:"Birdwatching",childOf:2,order:102},
      // {index:9,name:"Bicycling",childOf:2,order:103},
      // {index:10,name:"Diving",childOf:2,order:104},
      // {index:11,name:"Fishing & Angling",childOf:2,order:105},
      // {index:12,name:"Shopping",childOf:2,order:106},
      // {index:13,name:"Sailing",childOf:2,order:107},
      // {index:14,name:"Surfing",childOf:2,order:108},
      // {index:15,name:"Horse Riding",childOf:2,order:109},
      // {index:16,name:"Adventure Sports",childOf:2,order:110},
      // {index:17,name:"Family Friendly",childOf:2,order:111},
    ];

    this.dragging = false;
    this.dragComplete = true;

    this.pageChosenDone = false;

    this.axios = require("axios");
    this.siteChosen = this.siteChosen.bind(this);
    this.pageChosen = this.pageChosen.bind(this);
    this.optionDraggedElement = this.optionDraggedElement.bind(this);
    this.optionDropInElement = this.optionDropInElement.bind(this);
    this.optionDropInBetween = this.optionDropInBetween.bind(this);
    this.optionDragEnded = this.optionDragEnded.bind(this);
  }

  componentDidMount() {
    this.axios
      .post(env.protocol + env.env + "/api/public/GetSites.php", {
        allElement: true,
      })
      .then((response) => {
        response.data = response.data !== "" ? response.data : [];
        response.data.forEach((element) => {
          this.sites.push({
            label: element.name,
            value: element.id,
          });
        });

        this.setState({ sitesChecked: true });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          treeChecked: true,
          error: true,
          errorMessage: error.message,
        });
      });
  }

  siteChosen(e) {
    this.setState({ siteSelected: e, pagesChecked: false });

    this.axios
      .post(env.protocol + env.env + "/api/public/GetPages.php", {
        site: e.value,
      })
      .then((response) => {
        this.pages = [];

        response.data.forEach((element) => {
          this.pages.push({
            index: parseInt(element.id),
            name: element.page_name,
            childOf: parseInt(element.child_of),
            order: parseInt(element.order_value),
          });
        });

        this.setState({ pagesChecked: true });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          pagesChecked: true,
          error: true,
          errorMessage: error.message,
        });
      });
  }

  pageChosen(e) {
    if (!this.pageChosenDone) {
      this.pageChosenDone = true;
      this.setState({ pageSelected: e }, () => {
        this.pageChosenDone = false;
      });
    }

    //Axios call here to get page.
  }

  optionDraggedElement(e) {
    if (!this.dragging) {
      this.setState({ draggedElement: e });
      this.dragging = true;
      this.dragComplete = false;
    }
  }

  optionDropInElement(e) {
    if (!this.dragComplete) {
      this.dragComplete = true;
      this.dragging = false;

      const childof = parseInt(e.target.getAttribute("index"));
      var orderValue = 0;
      this.pages.forEach((element) => {
        if (element.childOf === childof) {
          orderValue = Math.max(orderValue, element.orderValue);
        }
      });
      orderValue++;

      this.pages.forEach((element, i) => {
        if (this.state.draggedElement === element.index) {
          this.pages[i].childOf = childof;
          this.pages[i].order = orderValue;
        }
      });
      this.setState({});
    }
  }

  optionDropInBetween(e) {
    if (!this.dragComplete) {
      this.dragComplete = true;
      this.dragging = false;
      const orderValue = parseInt(e.target.getAttribute("ordervalue"));
      const childof = parseInt(e.target.getAttribute("childof"));

      this.pages.forEach((element, i) => {
        if (this.state.draggedElement === element.index) {
          this.pages[i].childOf = childof;
          this.pages[i].order = orderValue;
        }

        if (element.order >= orderValue && this.state.draggedElement !== element.index && childof === element.childOf) {
          this.pages[i].order++;
        }
      });
      this.setState({});
    }
  }

  optionDragEnded(e) {
    this.dragging = false;
    this.dragComplete = true;
  }

  render() {
    const sorted = [];
    this.pages.forEach((element) => {
      if (element.childOf === -1) {
        sorted.push(element);
      }
    });
    sorted.sort((a, b) => {
      return a.order - b.order;
    });

    return (
      <div className="tree-sidebar">
        <h1>Pages</h1>
        <p className="small-gray">SITES</p>
        <div className="sites-select-parent">
          <Select
            className="sites-select"
            options={this.sites}
            value={this.state.siteSelected}
            onChange={this.siteChosen}
          />
          <div className="folder-icon">
            <FontAwesomeIcon icon={faFolder} />
          </div>
          <div className="caret-down-icon">
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
        <p className="small-gray">PAGES</p>
        <div className="pages-parent">
          {sorted.map((element, i) => {
            return element.childOf === -1 ? (
              <TreeBranch
                key={element.name}
                title={element.name}
                index={element.index}
                childOfValue={element.childOf}
                order={element.order}
                allBranches={this.pages}
                startDrag={this.optionDraggedElement}
                droppedInElement={this.optionDropInElement}
                droppedInBetween={this.optionDropInBetween}
                endDrag={this.optionDragEnded}
                isRoot={true}
                lastElement={i === sorted.length - 1}
                firstChild={i === 0}
                onClick={this.pageChosen}
                selected={this.state.pageSelected}
              />
            ) : (
              ""
            );
          })}
        </div>
      </div>
    );
  }
}

export default TreeSidebar;
