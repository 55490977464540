import { useEffect, useState } from "react";
import styles from "./VfiInput.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  label?: string | number;
  error?: string | number;
  confirmed?: boolean;
  type?: string;
  maxLength?: number;
  containerClassName?: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  onFocus?: (e: any) => void;
}

export default function VfiInput({
  value = "",
  placeholder = "",
  containerClassName = "",
  className = "",
  label = "",
  error = "",
  maxLength = 1000,
  confirmed = false,
  type = "text",
  onChange = (e: any) => {},
  onClick = (e: any) => {},
  onFocus = (e: any) => {},
  ...rest
}: Props) {
  return (
    <div className={`${styles.container} ${containerClassName}`}>
      {label !== "" && (
        <div className={styles.label}>
          <p>{label}</p>
        </div>
      )}
      <div className={styles.input_wrapper}>
        <input
          maxLength={maxLength}
          className={`${className} ${error !== "" ? styles.error : ""} ${confirmed ? styles.confirmed : ""}`}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          {...rest}
        />
        {error !== "" && <FontAwesomeIcon className={styles.exclamation} icon={faExclamation as IconProp} />}
        {error !== "" && <p className={styles.error_message}>{error}</p>}
      </div>
    </div>
  );
}
